import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { GraphService, UserService } from '@verde/core';
import { ThemeService } from '@verde/theme';
import { combineLatest, debounceTime, distinctUntilChanged, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'verde-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss'],
})
export class MainHeaderComponent implements OnDestroy {
  private onDestroy$ = new Subject<boolean>();

  @Output() logout: EventEmitter<boolean> = new EventEmitter();
  userId: string;

  constructor(private themeService: ThemeService, private graphService: GraphService, private userService: UserService) {
    combineLatest([this.userService.proxyUser$, this.graphService.loaded$, this.graphService.supported$])
      .pipe(debounceTime(500), distinctUntilChanged(), takeUntil(this.onDestroy$))
      .subscribe(async (data) => {
        if (data[0] && data[0]?.email && data[1] && data[2]) {
          const user = await this.graphService.getPerson(data[0]?.email);
          this.userId = user?.value[0]?.id;

          setTimeout(() => {
            const person = document.getElementById('person');
            if (person) {
              // Add a mutation observer to detect when the mgt-person-card is added
              const observer = new MutationObserver(() => {
                this.setupLogoutButton();
              });

              observer.observe(person, { childList: true, subtree: true });
            }
          });
        }
      });
  }

  setupLogoutButton(): void {
    const button = document.getElementById('logoutButton');
    if (button) {
      button.addEventListener('click', () => this.logoutUser());
    }
  }

  setDarkModeTheme() {
    this.themeService.setDarkMode();
  }

  setLightModeTheme() {
    this.themeService.setLightMode();
  }

  setSystemModeTheme() {
    this.themeService.setSystemMode();
  }

  logoutUser(): void {
    this.logout.emit(true);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
