<ngx-spinner [disableAnimation]="true"
             name="entityTransfer"
             bdColor="rgb(255, 255, 255)"
             size="default"
             color="#6264a7"
             type="ball-clip-rotate"
             [fullScreen]="false">
</ngx-spinner>
<form #fleetEntityTransferForm="ngForm"
      novalidate
      autocomplete="off">
  <div class="modal_grid">
    <div class="form-group">
      <label for="currentLegalEntity">Current Legal Entity:</label>
      <kendo-textbox [readonly]="true"
                     id="currentLegalEntity"
                     name="currentLegalEntity"
                     [ngModel]="selectedFleetItem?.odataLegalEntity">
      </kendo-textbox>
    </div>

    <div class="form-group">
      <label for="toLegalEntity">To Legal Entity:</label>
      <kendo-dropdownlist [data]="availableFleetAccess"
                          textField="odataLocationLegalEntity"
                          valueField="_bt_locationlegalentity_value"
                          id="toLegalEntity"
                          name="toLegalEntity"
                          required
                          [(ngModel)]="selectedFleetAccess"
                          #varToLegalEntity="ngModel">
      </kendo-dropdownlist>

      <div class="alert alert-danger"
           *ngIf="varToLegalEntity.touched && !varToLegalEntity.valid">
        <div *ngIf="varToLegalEntity.errors?.required">Field is required!</div>
      </div>
    </div>

    <div class="form-group">
      <label for="currentLocation">Current Location:</label>
      <kendo-textbox [readonly]="true"
                     id="currentLocation"
                     name="currentLocation"
                     [ngModel]="selectedFleetItem?.odataLocation">
      </kendo-textbox>
    </div>

    <div class="form-group">
      <label for="cancellationtype">To Location:</label>
      <kendo-textbox [readonly]="true"
                     id="cancellationtype"
                     name="cancellationtype"
                     [ngModel]="selectedFleetAccess?.odataLocation">
      </kendo-textbox>
    </div>

    <div class="form-group wide">
      <label for="transferReason">Reason for Transfer:</label>
      <kendo-textarea maxlength="300"
                      id="transferReason"
                      name="transferReason"
                      required
                      #varTransferReason="ngModel"
                      resizable="vertical"
                      [ngModel]>
      </kendo-textarea>

      <div class="alert alert-danger"
           *ngIf="varTransferReason.touched && !varTransferReason.valid">
        <div *ngIf="varTransferReason.errors?.required">Field is required!</div>
        <div *ngIf="varTransferReason.errors?.minlength">Enter a detailed reason.</div>
      </div>
    </div>
  </div>


</form>
<button style="position: absolute; bottom: 20px; right: 20px"
        kendoButton
        themeColor="primary"
        (click)="showCheckModal = true"
        [disabled]="fleetEntityTransferForm.invalid || isButtonDisabled">
  Submit
</button>

<!-- #region Confirmation Modal -->
<kendo-dialog *ngIf="showCheckModal"
              title="Confirmation"
              [width]="500">
  <div style="height: 60px">
    <p>
      Are you sure you want to submit this entity transfer for {{selectedFleetItem?.bthr_licensenumber}}?
      <br>
      From {{selectedFleetItem?.odataLegalEntity}} to {{selectedFleetAccess?.odataLocationLegalEntity}}.
    </p>
  </div>
  <div class="confirmation-modal-footer">
    <button kendoButton
            themeColor="primary"
            (click)="showCheckModal = false">
      Cancel
    </button>
    <button kendoButton
            themeColor="primary"
            style="margin-left: auto"
            (click)="postEntityTransfer(fleetEntityTransferForm)">
      Confirm
    </button>
  </div>
</kendo-dialog>
<kendo-dialog *ngIf="showConfirmationModal"
              title="Confirmation"
              [width]="500">
  <div style="height: 60px">
    <p>
      The entity transfer has been submitted.
    </p>
  </div>
  <div class="confirmation-modal-footer">
    <button kendoButton
            themeColor="primary"
            style="margin-left: auto"
            (click)="closeConfirmationModal()">
      Continue
    </button>
  </div>
</kendo-dialog>
<!-- #endregion Confirmation Modal -->