import { createEntityAdapter, EntityState, EntityAdapter } from '@ngrx/entity';
import { EducationalInstitutionActions, EducationalInstitutionActionTypes } from '../actions/educational-institution.actions';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromProfileRelatedReducer from './profile-related.reducer';

// Models
import { QualificationsBthrEducationalInstitution } from '@verde/shared';

// Helpers
import { keyHandler } from '@verde/core';

export type EducationalInstitutionState = EntityState<QualificationsBthrEducationalInstitution>;

export interface EducationalInstitutionRelatedState {
  educationalInstitutionState: EducationalInstitutionState;
  selectedIds: string[];
  loading: boolean;
  error: any;
}

export const educationalInstitutionKeys = ['bthr_EducationalInstitutionid'];
export const educationalInstitutionStateAdapter: EntityAdapter<QualificationsBthrEducationalInstitution> =
  createEntityAdapter<QualificationsBthrEducationalInstitution>({
    selectId: (data) => keyHandler(data, educationalInstitutionKeys),
  });

export const initialEducationalInstitutionState: EducationalInstitutionState = educationalInstitutionStateAdapter.getInitialState({});

export const initialEducationalInstitutionRelatedState = {
  educationalInstitutionState: initialEducationalInstitutionState,
  selectedIds: [],
  loading: false,
  error: {},
};

export function EducationalInstitutionRelatedReducer(
  state = initialEducationalInstitutionRelatedState,
  action: EducationalInstitutionActions,
): EducationalInstitutionRelatedState {
  switch (action.type) {
    // Get EducationalInstitution
    case EducationalInstitutionActionTypes.EDUCATIONALINSTITUTION_GET_ALL:
      return { ...state, loading: true };
    case EducationalInstitutionActionTypes.EDUCATIONALINSTITUTION_GET_ALL_SUCCESS: {
      return {
        ...state,
        loading: false,
        educationalInstitutionState: educationalInstitutionStateAdapter.setAll(action.payload, state.educationalInstitutionState),
      };
    }
    case EducationalInstitutionActionTypes.EDUCATIONALINSTITUTION_GET_ALL_ERROR:
      return { ...state, loading: false, error: action.payload };

    // Get Reduced EducationalInstitution
    case EducationalInstitutionActionTypes.EDUCATIONALINSTITUTION_GET_REDUCED:
      return { ...state, loading: true };
    case EducationalInstitutionActionTypes.EDUCATIONALINSTITUTION_GET_REDUCED_SUCCESS: {
      return {
        ...state,
        loading: false,
        educationalInstitutionState: educationalInstitutionStateAdapter.setAll(action.payload, state.educationalInstitutionState),
      };
    }
    case EducationalInstitutionActionTypes.EDUCATIONALINSTITUTION_GET_ALL_ERROR:
      return { ...state, loading: false, error: action.payload };

    // Upload
    case EducationalInstitutionActionTypes.EDUCATIONALINSTITUTION_UPLOAD_SUCCESS: {
      return {
        ...state,
      };
    }
    case EducationalInstitutionActionTypes.EDUCATIONALINSTITUTION_UPLOAD_ERROR:
      return { ...state, loading: false, error: action.payload };

    // Clear
    case EducationalInstitutionActionTypes.EDUCATIONALINSTITUTION_CLEAR:
      return { ...initialEducationalInstitutionRelatedState };
    default:
      return state;
  }
}

export const getProfileRelatedState = createFeatureSelector<fromProfileRelatedReducer.ProfileRelatedState>('profileRelatedReducer');

export const getEducationalInstitutionRelatedState = createSelector(
  getProfileRelatedState,
  (state: fromProfileRelatedReducer.ProfileRelatedState) => state.educationalInstitutionState,
);

// Current EducationalInstitution

export const getEducationalInstitutionState = createSelector(getEducationalInstitutionRelatedState, (state) => state.educationalInstitutionState);
export const { selectAll: getEducationalInstitution } = educationalInstitutionStateAdapter.getSelectors(getEducationalInstitutionState);

// Selected

export const getSelectedEducationalInstitutionIds = createSelector(getEducationalInstitutionRelatedState, (state) => state.selectedIds);

export const getSelectedProfiles = createSelector(
  getEducationalInstitution,
  getSelectedEducationalInstitutionIds,
  (EducationalInstitution, selectedIds: string[]) =>
    EducationalInstitution.filter((m) => selectedIds.indexOf(keyHandler(m, educationalInstitutionKeys)) !== -1),
);

// Loading

export const isEducationalInstitutionLoading = createSelector(getEducationalInstitutionRelatedState, (state) => state.loading);

// Error

export const getProfileError = createSelector(getEducationalInstitutionRelatedState, (state) => state.error);
