/* tslint:disable */
/* eslint-disable */
import { UserVerdeErrorType } from './user-verde-error-type';
export interface UserEnvironmentConfigDto {
  adobeEmbedKey?: null | string;
  allowModelDrivenEmbedding?: null | boolean;
  apiManagement?: null | string;
  dataverseUrl?: null | string;
  enableSplashScreen?: null | boolean;
  splashScreenURL?: null | string;
  subscriptionKey?: null | string;
  teamsAppOnline?: boolean;
  verdeError?: boolean;
  verdeErrorDetail?: null | string;
  verdeErrorMessage?: null | string;
  verdeErrorType?: UserVerdeErrorType;
  verdeOnline?: boolean;
}
