/* tslint:disable */
/* eslint-disable */
export interface DvRiskManagementPost {
  bt_accepteddate?: null | string;
  bt_attachmentfilename?: null | string;
  bt_employeeriskmanagementid?: null | string;
  bt_highriskposition?: null | boolean;
  bt_name?: null | string;
  bt_reasonfornotaccepting?: null | string;
  bt_vaccinationdate?: null | string;
  bt_vaccinationrequestaccepted?: null | boolean;
  bt_vaccinationrequired?: null | boolean;
  bt_vaccinationstatus?: null | number;
  bt_vulnerable?: null | boolean;
  bt_workflowstatus?: null | number;
  odataEmployee?: null | string;
  odataLegalEntity?: null | string;
  odataObjectionReasonForPatch?: null | string;
}
