/* tslint:disable */
/* eslint-disable */
export interface DvLanguageProfeciencyPost {
  bthr_employeelanguageproficiencyid?: null | string;
  bthr_proficiencyread?: null | number;
  bthr_proficiencyspeak?: null | number;
  bthr_proficiencywrite?: null | number;
  bthr_recordstatus?: null | number;
  languageID?: null | string;
  odataEmployee?: null | string;
  odataLanguage?: null | string;
}
