/* tslint:disable */
/* eslint-disable */
import { BtApprovalDefinition } from './bt-approval-definition';
export interface VerdeApprovalLogs {
  '_bt_approvaldefinition_value'?: null | string;
  '_bt_approver_value'?: null | string;
  '_bt_approverlegalentity_value'?: null | string;
  '_bt_legalentity_value'?: null | string;
  '_bt_loggroup_value'?: null | string;
  '_bt_proxy_value'?: null | string;
  '_bt_proxyentity_value'?: null | string;
  '_bt_taskno_value'?: null | string;
  bt_ApprovalDefinition?: BtApprovalDefinition;
  bt_approvaldate?: null | string;
  bt_approvalstatus?: number;
  bt_approvaltype?: null | string;
  bt_comment?: null | string;
  bt_logno?: null | string;
  bt_proxyapproval?: boolean;
  bt_recordguid?: null | string;
  bt_sequence?: number;
  bt_tabletechnicalcolumn?: null | string;
  bt_tabletechnicalname?: null | string;
  bt_verdeapprovallogsid?: null | string;
  odataApprovalDefinition?: null | string;
  odataApprovalStatus?: null | string;
  odataApprover?: null | string;
  odataApproverLegalEntity?: null | string;
  odataLegalEntity?: null | string;
  odataLogGroup?: null | string;
  odataPostApprover?: null | string;
  odataPostApproverLegalEntity?: null | string;
  odataPostProxy?: null | string;
  odataPostProxyEntity?: null | string;
  odataProxy?: null | string;
  odataProxyEntity?: null | string;
  odataTaskNo?: null | string;
}
