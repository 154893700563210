/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GiftsApiConfiguration, GiftsApiConfigurationParams } from './gifts-api-configuration';

import { GiftsApiGiftsService } from './services/gifts-api-gifts.service';
import { GiftsApiVersionService } from './services/gifts-api-version.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    GiftsApiGiftsService,
    GiftsApiVersionService,
    GiftsApiConfiguration
  ],
})
export class GiftsApiModule {
  static forRoot(params: GiftsApiConfigurationParams): ModuleWithProviders<GiftsApiModule> {
    return {
      ngModule: GiftsApiModule,
      providers: [
        {
          provide: GiftsApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: GiftsApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('GiftsApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
