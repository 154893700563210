/* tslint:disable */
/* eslint-disable */
import { UserBtDriversLicenseStatus } from './user-bt-drivers-license-status';
import { UserBtEmployeeType } from './user-bt-employee-type';
import { UserBthrGender } from './user-bthr-gender';
import { UserBthrJobSkill } from './user-bthr-job-skill';
import { UserBthrOccupationalLevel } from './user-bthr-occupational-level';
import { UserBthrPartnershipStatus } from './user-bthr-partnership-status';
import { UserBthrTerminationreason } from './user-bthr-terminationreason';
export interface UserUserDto {
  azureObjectId?: null | string;
  costCentreName?: null | string;
  countryName?: null | string;
  countryOfResidenceId?: string;
  countryOfResidenceName?: null | string;
  dateOfBirth?: null | string;
  departmentId?: string;
  departmentName?: null | string;
  districtName?: null | string;
  divisionName?: null | string;
  driversLicense?: null | boolean;
  driversLicenseStatus?: UserBtDriversLicenseStatus;
  email?: null | string;
  employeeClassificationName?: null | string;
  employeeId?: null | string;
  employeeNumber?: null | string;
  employeeType?: UserBtEmployeeType;
  enableforEmployee?: null | boolean;
  firstName?: null | string;
  fullName?: null | string;
  fullNameWithEmpId?: null | string;
  gender?: UserBthrGender;
  gradeName?: null | string;
  groupLocationId?: string;
  hasSubordinates?: null | boolean;
  homeTelephone?: null | string;
  idNumber?: null | string;
  isManager?: null | boolean;
  jobSkill?: UserBthrJobSkill;
  lastName?: null | string;
  lastWorkDay?: null | string;
  legalEntityId?: string;
  legalEntityJoinDate?: null | string;
  legalEntityName?: null | string;
  managerEmail?: null | string;
  managerEmployeeFullname?: null | string;
  managerEmployeeId?: string;
  managerLegalEntityId?: string;
  middleNames?: null | string;
  mobileTelephone?: null | string;
  newOrganizationJoinDate?: null | string;
  noticeDays?: null | number;
  occupationalLevel?: UserBthrOccupationalLevel;
  officeId?: string;
  officeName?: null | string;
  overrideManagerPayslipAccess?: null | boolean;
  partnership?: UserBthrPartnershipStatus;
  paymentMethodName?: null | string;
  paypointName?: null | string;
  payrollNumber?: null | string;
  personalEmail?: null | string;
  photo?: null | string;
  positionSeatId?: null | string;
  preferredName?: null | string;
  primaryLocationId?: string;
  primaryLocationName?: null | string;
  probationEndDate?: null | string;
  probationMonths?: null | number;
  probationTypeName?: null | string;
  provinceName?: null | string;
  resignationDate?: null | string;
  seatId?: string;
  seatName?: null | string;
  shiftTypeName?: null | string;
  skillsClassificationLookupName?: null | string;
  startDate?: null | string;
  status?: null | string;
  subOfficeId?: string;
  subOfficeName?: null | string;
  subordinates?: null | number;
  succession?: null | boolean;
  taxNumber?: null | string;
  tenantId?: null | string;
  terminationNoticeDate?: null | string;
  terminationOptions?: null | Array<UserBthrTerminationreason>;
  terminationReason?: UserBthrTerminationreason;
  title?: null | string;
  workplaceName?: null | string;
  yearsAtEntity?: null | number;
}
