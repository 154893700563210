/* tslint:disable */
/* eslint-disable */
export interface DvBusinessCycleTypes {
  bt_businesscycletypeunique?: null | string;
  bt_noofcycles?: null | number;
  bt_periodcycles?: null | number;
  bthr_businesscycletypeid?: null | string;
  bthr_cycletypename?: null | string;
  odataLegalEntity?: null | string;
}
