<section class="main-review-component">
  <div class="review-window">
    <div class="review-header">
      <span>{{ currentQuestionDisplay + 1 }} / {{ totalQuestions }}</span>
      <div class="progress-bar-outline">
        <div class="progress-bar-inner"
             [style.width]="progressBarStatus"></div>
      </div>
    </div>

    <div class="questions-container">
      <ng-container *ngFor="let review of reviewQuestions; let i = index">
        <div class="question"
             *ngIf="i === currentQuestion && !transition"
             [@enter]
             [@leave]>
          <div>
            <div class="sub-headings">
              <div class="group">
                <span>{{ review.group.odata }}</span>
              </div>
              <div *ngIf="review.bt_displaysubgroup"
                   class="sub-group">
                <span>{{ review.subGroup.odata }}</span>
              </div>
            </div>
            <!--<mat-divider></mat-divider>-->
            <div class="question-header">
              <h3>Question {{ currentQuestion + 1 }}</h3>
              <h4>{{ review.measure.odata }}</h4>
            </div>
          </div>
          <div class="question-content">
            <!-- Rating Question -->
            <verde-rating-option *ngIf="review.bt_responsetype === 'rating'"
                                 [setReviewOption]="review"
                                 (answerSelected)="setQuestionAnswer(i, $event)">
            </verde-rating-option>

            <!-- Response Question -->
            <verde-response-option *ngIf="review.bt_responsetype === 'freeText'"
                                   [setReviewOption]="review"> </verde-response-option>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="button-section">
      <button kendoButton
              themeColor="primary"
              [disabled]="!(currentQuestionDisplay > 0)"
              (click)="previousQuestion()">Previous</button>

      <button kendoButton
              themeColor="primary"
              *ngIf="currentQuestionDisplay < totalQuestions - 1"
              [disabled]="questionNotAnswered"
              (click)="nextQuestion()">
        Next
      </button>

      <button class="btn btn-success"
              *ngIf="!(currentQuestionDisplay < totalQuestions - 1)"
              [disabled]="questionNotAnswered"
              (click)="submit()">Submit</button>
    </div>
  </div>
</section>