import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-date-input',
  template: `
    <kendo-formfield dir="ltr" class="k-form-field">
      <label class="k-label ng-star-inserted k-form-field" *ngIf="props.label">{{ props.label }}</label>

      <kendo-datepicker format="yyyy/MM/dd" [formControl]="formControl" [formlyAttributes]="field" style=""> </kendo-datepicker>
    </kendo-formfield>
  `,
})
export class FormlyDateInputComponentKendo extends FieldType {}
