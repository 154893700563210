/* tslint:disable */
/* eslint-disable */
import { User } from './user';
export interface BookingAvailability {
  bt_Employee?: User;
  bt_bookingno?: null | string;
  bt_fromdate?: null | string;
  bt_todate?: null | string;
  odataEmployee?: null | string;
  statecode?: null | string;
}
