/* tslint:disable */
/* eslint-disable */
import { QualificationsGuidExtrapolate } from './qualifications-guid-extrapolate';
import { QualificationsBtValidationType } from './qualifications-bt-validation-type';
import { QualificationsBthrQualificationType } from './qualifications-bthr-qualification-type';
export interface QualificationsBtQualficationInstitutionLink {
  bt_QualficationInstitutionLinkid?: string;
  bt_institution?: null | string;
  bt_qualficationinstitutionlinkno?: null | string;
  bt_qualification?: null | string;
  bt_qualificationtype?: QualificationsBthrQualificationType;
  bt_validated?: null | boolean;
  bt_validatedby?: null | string;
  bt_validatedbyentity?: null | string;
  bt_validationtype?: QualificationsBtValidationType;
  institution?: QualificationsGuidExtrapolate;
  qualification?: QualificationsGuidExtrapolate;
  validatedBy?: QualificationsGuidExtrapolate;
  validatedByEntity?: QualificationsGuidExtrapolate;
}
