import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { StructureBtStructureMovementType, StructureBtStructureType, ModalService, VerdeApprovalService } from '@verde/shared';
import { HttpCancelService } from '@verde/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MenuActionStructureType } from 'apps/verde/src/app/features/structure-management/config/menu-action-structure-type.config';
import { StructureFunctions } from 'apps/verde/src/app/features/structure-management/enums/structure-permissions.enum';
import { PositionService } from 'apps/verde/src/app/features/structure-management/services/position.service';
import { StructureService } from 'apps/verde/src/app/features/structure-management/services/structure.service';
import { StructureFullExtended } from 'apps/verde/src/app/features/structure-management/store/models/structure-full-extended.model';
import { StructurePositionExtended } from 'apps/verde/src/app/features/structure-management/store/models/structure-position-extended.model';

@Component({
  selector: 'verde-position-change-request',
  templateUrl: './position-change-request.component.html',
  styleUrls: ['./position-change-request.component.scss'],
})
export class PositionChangeRequestComponent implements OnDestroy {
  @Input() miniStructure: StructureFullExtended[];
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter();

  private onDestroy$ = new Subject<boolean>();
  fullStructures: StructureFullExtended[];
  parentLegalEntity: StructureFullExtended;
  parentDivision: StructureFullExtended;
  parentDepartment: StructureFullExtended;
  positions: StructurePositionExtended[];
  fb: FormBuilder;
  fg: FormGroup;
  structureTypes: Array<MenuActionStructureType> = [
    {
      ParentStructureType: StructureBtStructureType.LegalEntity,
      MovementType: StructureBtStructureMovementType.New,
      StructureType: StructureBtStructureType.Position,
      StructureFunction: StructureFunctions.STRUCTURE_NEW_POSITION,
    },
  ];
  display: string = 'positionList';
  gridColWidth = '45%';

  constructor(
    private modalService: ModalService,
    private positionService: PositionService,
    private structureService: StructureService,
    private httpCancelService: HttpCancelService,
    private sidebarService: VerdeApprovalService,
  ) {
    this.positionService.allEntityPositions$.pipe(takeUntil(this.onDestroy$)).subscribe((positions: StructurePositionExtended[]) => {
      this.positions = positions;
    });

    this.structureService.fullStructure$.pipe(takeUntil(this.onDestroy$)).subscribe((fullStructure: StructureFullExtended[]) => {
      this.fullStructures = fullStructure;
    });
    this.structureService.parentLegalEntity$.pipe(takeUntil(this.onDestroy$)).subscribe((parentLegalEntity: StructureFullExtended) => {
      this.parentLegalEntity = parentLegalEntity;
      if (parentLegalEntity) {
        this.positionService.getAllPositions(this.parentLegalEntity.recordID);
      }
    });
    this.structureService.parentDivision$.pipe(takeUntil(this.onDestroy$)).subscribe((parentDivision: StructureFullExtended) => {
      this.parentDivision = parentDivision;
    });
    this.structureService.parentDepartment$.pipe(takeUntil(this.onDestroy$)).subscribe((parentDepartment: StructureFullExtended) => {
      this.parentDepartment = parentDepartment;
    });

    this.fb = new FormBuilder();
    this.resetForm();
  }

  resetForm() {
    this.fg = this.fb.group({
      structureType: [this.structureTypes[0]],
      _bthr_legalentity_value: [],
      bthr_description: [],
      motivation: [],
      effectiveDate: [new Date()],
    });
  }

  isDisabled() {
    return this.fg ? this.fg.invalid : true;
  }

  viewModal(name: string): void {
    this.resetForm();

    if (name === 'positionListModal') {
      this.modalService.close('positionChangeRequestModal');
    } else {
      this.modalService.close('positionListModal');
    }

    this.modalService.open(name);
  }

  async add() {
    const position: StructurePositionExtended = {
      _bthr_legalentity_value: this.parentLegalEntity?.recordID,
      _bthr_division_value: this.parentDivision?.recordID,
      _bthr_department_value: this.parentDepartment?.recordID,
      bthr_description: this.fg.value.bthr_description,
      $motivation: this.fg.value.motivation,
      $effectiveDate: this.fg.value.effectiveDate,
    };
    this.positionService.addPositions([position]);

    this.sidebarService.setShowSidebar(false);
  }

  isExpanded = (dataItem: any): boolean => {
    return true;
  };

  rowClass({ dataItem }): any {
    return {
      'background-row-new': dataItem.$new,
      'background-row-disposed': dataItem.$dispose,
    };
  }

  close() {
    this.closeModal.emit();
  }

  ngOnDestroy(): void {
    this.httpCancelService.cancelPendingRequests();

    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
