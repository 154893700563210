/* tslint:disable */
/* eslint-disable */
export interface DvTimeOffSummary {
  bt_colour?: null | string;
  bt_cycleaccrual?: null | number;
  bt_cycleend?: null | string;
  bt_cycleendbalance?: null | number;
  bt_cycleendlocal?: null | string;
  bt_cycleno?: null | number;
  bt_cyclestart?: null | string;
  bt_cycletaken?: null | number;
  bt_cycletodateaccrual?: null | number;
  bt_cycletodatebalance?: null | number;
  bt_cycletodatepending?: null | number;
  bt_cycletodatetaken?: null | number;
  bt_summary?: null | string;
  bt_timeoffsummaryid?: null | string;
  bthr_cycletodatepending?: null | number;
  bthr_triggerdate?: null | string;
  bthr_updaterequired?: null | boolean;
  odataTimeOffSubType?: null | string;
  odataTimeOffType?: null | string;
  odataUpdateRequired?: null | string;
}
