export enum TaskSource {
  ReviewGroup = 692360000,
  FleetItem = 692360001,
  Request = 692360002,
  TimeOffApproval = 692360003,
  HrTimeOffApproval = 692360004,
  Role = 692360005,
  IssueReported = 692360006,
  Policy = 692360007,
  Booking = 692360008,
  Equipment = 692360009,
  Checklist = 692360010,
  Manual = 692360012,
}
