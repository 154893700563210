/* tslint:disable */
/* eslint-disable */
export interface DvVaccinationSummary {
  '_bt_vaccination_value'?: null | string;
  bt_booster?: null | boolean;
  bt_covid?: null | boolean;
  bt_employeedoses?: null | number;
  bt_employeevaccinationsummaryid?: null | string;
  bt_flu?: null | boolean;
  bt_noofdoses?: null | number;
  bt_remainingdoses?: null | number;
  bt_sequenceno?: null | number;
  odataCovid?: null | string;
  odataEmployee?: null | string;
  odataFlu?: null | string;
  odataVaccine?: null | string;
}
