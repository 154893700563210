import { QualificationsGetEmployeeEducationalQualificationQuery } from '@verde/shared';
import { Action } from '@ngrx/store';

export enum EmployeeEducationalQualificationActionTypes {
  // Get All VaccinationTypes
  EMPLOYEEEDUCATIONALQUALIFICATION_GET_ALL = '[EmployeeEducationalQualification] Get All',
  EMPLOYEEEDUCATIONALQUALIFICATION_GET_ALL_SUCCESS = '[EmployeeEducationalQualification] Get All Success',
  EMPLOYEEEDUCATIONALQUALIFICATION_GET_ALL_ERROR = '[EmployeeEducationalQualification] Get All Error',

  // UPDATE EmployeeEducationalQualification
  EMPLOYEEEDUCATIONALQUALIFICATION_UPDATE = '[EmployeeEducationalQualification] UPDATE',
  EMPLOYEEEDUCATIONALQUALIFICATION_UPDATE_SUCCESS = '[EmployeeEducationalQualification] UPDATE Success',
  EMPLOYEEEDUCATIONALQUALIFICATION_UPDATE_ERROR = '[EmployeeEducationalQualification] UPDATE Error',

  // Add EmployeeEducationalQualification
  EMPLOYEEEDUCATIONALQUALIFICATION_UPLOAD = '[EmployeeEducationalQualification] Upload',
  EMPLOYEEEDUCATIONALQUALIFICATION_UPLOAD_SUCCESS = '[EmployeeEducationalQualification] Upload Success',
  EMPLOYEEEDUCATIONALQUALIFICATION_UPLOAD_ERROR = '[EmployeeEducationalQualification] Upload Error',

  // Clear All VaccinationTypes
  EMPLOYEEEDUCATIONALQUALIFICATION_CLEAR = '[EmployeeEducationalQualification] Clear',
}

//Get All EmployeeEducationalQualification
export class EmployeeEducationalQualificationGetAll implements Action {
  readonly type = EmployeeEducationalQualificationActionTypes.EMPLOYEEEDUCATIONALQUALIFICATION_GET_ALL;
  constructor(public body: QualificationsGetEmployeeEducationalQualificationQuery) {}
}

export class EmployeeEducationalQualificationGetAllSuccess implements Action {
  readonly type = EmployeeEducationalQualificationActionTypes.EMPLOYEEEDUCATIONALQUALIFICATION_GET_ALL_SUCCESS;
  constructor(public payload: any) {}
}

export class EmployeeEducationalQualificationGetAllError implements Action {
  readonly type = EmployeeEducationalQualificationActionTypes.EMPLOYEEEDUCATIONALQUALIFICATION_GET_ALL_ERROR;
  constructor(public payload: any) {}
}

// UPDATE EMPLOYEEEDUCATIONALQUALIFICATION
export class EmployeeEducationalQualificationUpdate implements Action {
  readonly type = EmployeeEducationalQualificationActionTypes.EMPLOYEEEDUCATIONALQUALIFICATION_UPDATE;
  constructor(public payload: any) {}
}

export class EmployeeEducationalQualificationUpdateSuccess implements Action {
  readonly type = EmployeeEducationalQualificationActionTypes.EMPLOYEEEDUCATIONALQUALIFICATION_UPDATE_SUCCESS;
  constructor(public payload: any) {}
}

export class EmployeeEducationalQualificationUpdateError implements Action {
  readonly type = EmployeeEducationalQualificationActionTypes.EMPLOYEEEDUCATIONALQUALIFICATION_UPDATE_ERROR;
  constructor(public payload: any) {}
}

// UPLOAD MEASURE
export class EmployeeEducationalQualificationsUpload implements Action {
  readonly type = EmployeeEducationalQualificationActionTypes.EMPLOYEEEDUCATIONALQUALIFICATION_UPLOAD;
  constructor(public payload: any) {}
}

export class EmployeeEducationalQualificationsUploadSuccess implements Action {
  readonly type = EmployeeEducationalQualificationActionTypes.EMPLOYEEEDUCATIONALQUALIFICATION_UPLOAD_SUCCESS;
  constructor(public payload: any) {}
}

export class EmployeeEducationalQualificationsUploadError implements Action {
  readonly type = EmployeeEducationalQualificationActionTypes.EMPLOYEEEDUCATIONALQUALIFICATION_UPLOAD_ERROR;
  constructor(public payload: any) {}
}

// Clear All
export class EmployeeEducationalQualificationClear implements Action {
  readonly type = EmployeeEducationalQualificationActionTypes.EMPLOYEEEDUCATIONALQUALIFICATION_CLEAR;
  constructor(public payload: any) {}
}

export type EmployeeEducationalQualificationActions =
  // Get All EmployeeEducationalQualification
  | EmployeeEducationalQualificationGetAll
  | EmployeeEducationalQualificationGetAllSuccess
  | EmployeeEducationalQualificationGetAllError
  // Set All Structure Change Requests
  | EmployeeEducationalQualificationUpdate
  | EmployeeEducationalQualificationUpdateSuccess
  | EmployeeEducationalQualificationUpdateError
  // Structure Change Request Upload
  | EmployeeEducationalQualificationsUpload
  | EmployeeEducationalQualificationsUploadSuccess
  | EmployeeEducationalQualificationsUploadError
  // Clear All EmployeeEducationalQualification
  | EmployeeEducationalQualificationClear;
