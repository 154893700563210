/* tslint:disable */
/* eslint-disable */
export interface UserOffBoarding {
  '_bt_approvalgroup_value'?: null | string;
  '_bt_employee_value'?: null | string;
  '_bt_legalentity_value'?: null | string;
  '_bt_manager_value'?: null | string;
  '_bt_managerentity_value'?: null | string;
  '_bt_taskgroup_value'?: null | string;
  bt_nextstep?: null | string;
  bt_resignationattachment?: null | string;
  bt_resignationattachment_name?: null | string;
  bt_resignationdate?: string;
  bt_resignationnotice?: null | string;
  bt_verdeoffboarding?: null | string;
  bt_verdeoffboardingid?: null | string;
  odataEmployee?: null | string;
  odataLegalEntity?: null | string;
  odataManager?: null | string;
  odataManagerEntity?: null | string;
  odataPostEmployee?: null | string;
  odataPostLegalEntity?: null | string;
  odataPostManager?: null | string;
  odataPostManagerEntity?: null | string;
  odataTaskgroup?: null | string;
}
