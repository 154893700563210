<kendo-dialog *ngIf="opened" (close)="close('cancel')" [minWidth]="700" [maxWidth]="1100" [maxheight]="" class="payslip-dialog" id="payslipMain">
  <div class="payslip-inner-container" id="payslipTop">
    <kendo-pdf-export #pdf paperSize="A4" margin="0.5cm" [scale]="0.7" style="height: 100%; display: block">
      <img style="height: 132px; width: 836px" src="{{ HeaderContentURL }}" />
      <div class="payroll-Main-Info">
        <label>EMP Code:</label>
        <label>{{ selectedItem?.bt_employeeidtext }}</label>
        <label>Department: </label>
        <label>{{ selectedItem?.bt_department }}</label>
        <label>Period End Date: </label>
        <label>{{ formatted_periodenddate | date : 'yyyy-MM-dd' }}</label>
        <label>Emp Name: </label>
        <label>{{ selectedItem?.bt_employeefullname }}</label>
        <label>Paypoint: </label>
        <label>{{ selectedItem?.bt_paypoint }}</label>
        <label>Date Engaged: </label>
        <label>{{ selectedItem?.bt_dateengaged | date : 'yyyy-MM-dd' }}</label>
        <label></label>
        <label></label>
        <label>Job Title: </label>
        <label>{{ selectedItem?.bt_jobtitle }}</label>
        <label>ID Number: </label>
        <label>{{ selectedItem?.bt_idnumber }}</label>
        <label>Rate/Hour: </label>
        <label>{{ selectedItem?.bt_rateperhour }}</label>
        <label>Pay Method: </label>
        <label>{{ selectedItem?.bt_paymentmethod }}</label>
      </div>

      <div class="payroll-main">
        <div class="payroll-Earnings">
          <kendo-grid
            [rowClass]="rowCallback"
            [kendoGridBinding]="EarningsGrid"
            [kendoGridSelectBy]="skeletonSelectedCallback"
            [selectedKeys]="skeletonSelection"
            [sortable]="false"
            scrollable="none"
            style="line-height: 15px; border: 1px solid #bcbcbc"
          >
            <kendo-grid-column field="bt_docsalaryadvicedetail" title="Earnings" [width]="100">
              <ng-template kendoGridCellTemplate let-dataItem>
                {{ capitalizeFirstLetter(dataItem.bt_docsalaryadvicedetail) }}
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="bt_unit" title="Hours/Units" [width]="100">
              <ng-template kendoGridCellTemplate let-ListTable>
                <div *ngIf="ListTable.bt_unit != 0" style="text-align: right; padding-right: 3px">
                  {{ ListTable?.bt_unit }}
                </div>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="bt_amount" title="Amount" [width]="100">
              <ng-template kendoGridCellTemplate let-ListTable>
                <div style="display: flex; justify-content: space-between; align-items: center">
                  <div style="text-align: center; padding-right: 3px; margin-right: 3px">R</div>
                  <div style="text-align: right">{{ ListTable?.bt_amount | number : '1.2-2' }}</div>
                </div>
              </ng-template>
            </kendo-grid-column>
          </kendo-grid>
        </div>

        <div class="payroll-Deductions">
          <kendo-grid
            [rowClass]="rowCallback"
            [kendoGridBinding]="DeductionsGrid"
            [kendoGridSelectBy]="skeletonSelectedCallback"
            [selectedKeys]="skeletonSelection"
            [sortable]="false"
            scrollable="none"
            style="line-height: 15px; border: 1px solid #bcbcbc"
          >
            <kendo-grid-column field="bt_docsalaryadvicedetail" title="Deductions" [width]="100">
              <ng-template kendoGridCellTemplate let-dataItem>
                {{ capitalizeFirstLetter(dataItem.bt_docsalaryadvicedetail) }}
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="bt_unit" title="Hours/Units" [width]="100">
              <ng-template kendoGridCellTemplate let-ListTable>
                <div *ngIf="ListTable.bt_unit != 0" style="text-align: right; padding-right: 3px">
                  {{ ListTable?.bt_unit }}
                </div>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="bt_amount" title="Amount" [width]="100">
              <ng-template kendoGridCellTemplate let-ListTable>
                <div style="display: flex; justify-content: space-between; align-items: center">
                  <div style="text-align: center; padding-right: 3px; margin-right: 3px">R</div>
                  <div style="text-align: right">{{ ListTable?.bt_amount | number : '1.2-2' }}</div>
                </div>
              </ng-template>
            </kendo-grid-column>
          </kendo-grid>
        </div>
      </div>

      <div class="payroll-main2">
        <div class="Earning-Totals-Parent">
          <div class="Earning-Totals">
            <label class="label-grid" style="font-weight: bold">Total Earnings</label>
            <label class="label-grid" style="text-align: right">R </label>
            <label class="label-grid" style="text-align: end"> {{ selectedItem?.bt_totalearnings | number : '1.2-2' }}</label>
          </div>
        </div>
        <div class="Earning-Totals-Parent">
          <div class="Earning-Totals">
            <label class="label-grid" style="font-weight: bold">Total Deductions</label>
            <label class="label-grid" style="text-align: right">R </label>
            <label class="label-grid" style="text-align: end"> {{ selectedItem?.bt_totaldeductions | number : '1.2-2' }} </label>
            <label class="label-grid" style="font-weight: bold">Net Salary </label>
            <label class="label-grid" style="text-align: right">R </label>
            <label class="label-grid" style="text-align: end"> {{ selectedItem?.bt_netsalary | number : '1.2-2' }} </label>
          </div>
        </div>
        <div class="Earning-Totals-Parent">
          <div class="Earning-Totals">
            <label class="label-grid" style="font-weight: bold">Current Period Perks</label>
            <label class="label-grid" style="font-weight: bold"></label>
            <label class="label-grid" style="font-weight: bold"></label>
            <label class="label-grid">Perks: </label>
            <label class="label-grid" style="text-align: right">R </label>
            <label class="label-grid" style="text-align: end"> {{ currentPerks?.bt_amount | number : '1.2-2' }}</label>
            <label class="label-grid">Company Contributions: </label>
            <label class="label-grid" style="text-align: right">R </label>
            <label class="label-grid" style="text-align: end"> {{ selectedItem?.bt_totalcompanycontributions | number : '1.2-2' }} </label>
          </div>
        </div>
        <div class="Earning-Totals-Parent">
          <div class="payroll-CTC">
            <label class="label-grid" style="font-weight: bold; grid-column: 1">Company Contributions</label>
            <label class="label-grid" style="font-weight: bold; grid-column: 2"></label>
            <label class="label-grid" style="font-weight: bold; grid-column: 3"></label>

            <ng-container *ngFor="let loc of CompanyContributionsDifferent; index as i">
              <label class="label-grid" style="grid-column: 1">{{ capitalizeFirstLetter(loc?.bt_docsalaryadvicedetail) }}:</label>
              <label class="label-grid" style="text-align: right; grid-column: 2">R </label>
              <label class="label-grid" style="text-align: right; grid-column: 3">{{ loc?.bt_amount | number : '1.2-2' }}</label>
            </ng-container>
          </div>
        </div>
        <div class="payroll-CTC-Parent">
          <div class="payroll-CTC">
            <label class="label-grid" style="font-weight: bold; grid-column: 1">Cost To Company</label>
            <label class="label-grid" style="font-weight: bold; grid-column: 2"></label>
            <label class="label-grid" style="font-weight: bold; grid-column: 3"></label>
            <ng-container *ngFor="let loc of CompanyContributionsGrid; index as i">
              <label class="label-grid" style="grid-column: 1">{{ capitalizeFirstLetter(loc?.bt_docsalaryadvicedetail) }}:</label>
              <label class="label-grid" style="text-align: right; grid-column: 2">R </label>
              <label class="label-grid" style="text-align: right; grid-column: 3">{{ loc?.bt_amount | number : '1.2-2' }}</label>
            </ng-container>

            <label style="grid-column: 1">CTC Cash:</label>
            <label class="label-grid" style="text-align: right; grid-column: 2">R </label>
            <label style="text-align: right; grid-column: 3"> {{ selectedItem?.bt_ctccash | number : '1.2-2' }} </label>

            <label style="grid-column: 1">Cost To Company:</label>
            <label class="label-grid" style="text-align: right; grid-column: 2">R </label>
            <label style="text-align: right; grid-column: 3"> {{ selectedItem?.bt_totalcc | number : '1.2-2' }} </label>
          </div>
        </div>
        <div class="payroll-YTD-Parent">
          <div class="payroll-YTD">
            <label class="label-grid" style="font-weight: bold">Year To Date Totals</label>
            <label class="label-grid"></label>
            <label class="label-grid"></label>
            <label class="label-grid" style="grid-column: 1">Perks:</label>
            <label class="label-grid" style="text-align: right; grid-column: 2">R </label>
            <label class="label-grid" style="text-align: right; grid-column: 3">{{ selectedItem?.bt_totalperks | number : '1.2-2' }}</label>
            <label class="label-grid" style="grid-column: 1">Tax:</label>
            <label class="label-grid" style="text-align: right; grid-column: 2">R </label>
            <label class="label-grid" style="text-align: right; grid-column: 3">{{ selectedItem?.bt_ytdtax | number : '1.2-2' }}</label>
            <label class="label-grid" style="grid-column: 1">Taxable Earnings:</label>
            <label class="label-grid" style="text-align: right; grid-column: 2">R </label>
            <label class="label-grid" style="text-align: right; grid-column: 3">{{ selectedItem?.bt_ytdearnings | number : '1.2-2' }}</label>
          </div>
        </div>
      </div>
    </kendo-pdf-export>
  </div>
  <br />
  <kendo-dialog-actions style="margin-top: 12px">
    <button kendoButton (click)="close('cancel')">Close</button>
    <button kendoButton themeColor="primary" (click)="SaveThePdf(pdf)" style="float: right">Save As PDF...</button>
  </kendo-dialog-actions>
</kendo-dialog>

<verde-modal id="navToWebApp" modalTitle="Please Note" submitButton="Continue" (actionButton)="goToWeb()">
  <p>This functionality is temporarily only available on the web version of Verde.</p>
  <p>Please select Continue to be navigated to the web version else, Cancel.</p>
</verde-modal>
