/* tslint:disable */
/* eslint-disable */
import { ProcessesLookupExtrapolate } from './processes-lookup-extrapolate';
import { ProcessesProcessesLegalEntity } from './processes-processes-legal-entity';
import { ProcessesVerdeAnnotations } from './processes-verde-annotations';
import { ProcessesBtProcessStatus } from './processes-bt-process-status';
import { ProcessesBthrEmployeeResignationsState } from './processes-bthr-employee-resignations-state';
import { ProcessesBthrTerminationreason } from './processes-bthr-terminationreason';
export interface ProcessesEmployeeResignation {
  annotations?: null | Array<ProcessesVerdeAnnotations>;
  bt_ProcessStatus?: ProcessesBtProcessStatus;
  bt_employee?: ProcessesLookupExtrapolate;
  bt_employeeresignationsid?: string;
  bt_fullname?: null | string;
  bt_legalentity?: ProcessesProcessesLegalEntity;
  bt_processLegalEntity?: string;
  bt_processedBy?: string;
  bt_statecode?: ProcessesBthrEmployeeResignationsState;
  bthr_lastworkday?: null | string;
  bthr_resignationdate?: null | string;
  bthr_terminationcomment?: null | string;
  bthr_terminationnoticedate?: null | string;
  bthr_terminationreason?: ProcessesBthrTerminationreason;
  employeeID?: string;
  filesToUpload?: null | Array<Blob>;
  legalEntityID?: string;
}
