<br>
<div style="overflow-x: hidden; ">

  <!-- Opportunities -->
  <div>
    <form #opportunityCustomerForm="ngForm"
          novalidate
          autocomplete="off">

      <div class="block-one-grid">
        <div class="inline-option-grid">
          <kendo-label>Product:</kendo-label>
          <kendo-dropdownlist [data]="allVerdeProducts"
                              textField="verde_product"
                              valueField="verde_productid"
                              [(ngModel)]="selectedVerdeProduct"
                              [disabled]="allVerdeProducts.length <= 1"
                              id="productName"
                              name="productName">
          </kendo-dropdownlist>
        </div>

        <div class="inline-option-grid">
          <label>New Customer:</label>
          <input [(ngModel)]="newCustomer"
                 class="form-check-input"
                 disabled
                 kendoCheckBox
                 type="checkbox"
                 id="productNewCust"
                 name="productNewCust"
                 style="margin-right: auto; margin-top: 6px;" />
        </div>

        <div class="inline-option-grid">
          <label>Customer Type:</label>
          <kendo-dropdownlist [data]="customerTypes"
                              [(ngModel)]="selectedCustomerType"
                              id="productCustType"
                              name="productCustType">
          </kendo-dropdownlist>
        </div>

        <div class="inline-option-grid">
          <label>No of Employees:</label>
          <input style="text-align: right;"
                 [(ngModel)]="noOfEmployees"
                 type="number"
                 [min]="selectedVerdeProduct.verde_minimumemployees"
                 [max]="selectedVerdeProduct.verde_maximumemployees"
                 id="productNoEmployees"
                 name="productNoEmployees"
                 kendoTextBox />
        </div>

        <div class="inline-option-grid"
             style="grid-column: auto / span 2">
          <label>Customer Name:</label>
          <kendo-textbox id="productCustName"
                         name="productCustName"
                         [(ngModel)]="opportunitiesName">
          </kendo-textbox>
        </div>

        <div>
          <label style="font-weight: bold;">Options:</label>
        </div>

        <div>
          <!-- Blank div for grid spacing -->
        </div>

        <div class="inline-option-grid">
          <label>Verde People Basic:</label>
          <input [(ngModel)]="optionOne"
                 disabled
                 class="form-check-input"
                 kendoCheckBox
                 type="checkbox"
                 id="productBasic"
                 name="productBasic"
                 style="margin-top: 6px;" />
        </div>

        <div>
          <!-- Blank div for grid spacing -->
        </div>

        <div class="inline-option-grid">
          <label>Verde People Full:</label>
          <input [(ngModel)]="optionTwo"
                 class="form-check-input"
                 kendoCheckBox
                 type="checkbox"
                 id="productFull"
                 name="productFull"
                 style="margin-top: 6px;" />
        </div>

        <div>
          <!-- Blank div for grid spacing -->
        </div>

        <div class="inline-option-grid">
          <label>Verde People Fleet:</label>
          <input [(ngModel)]="optionThree"
                 class="form-check-input"
                 kendoCheckBox
                 type="checkbox"
                 id="productFleet"
                 name="productFleet"
                 style="margin-top: 6px;" />
        </div>

        <div>
          <!-- Blank div for grid spacing -->
        </div>

        <div *ngIf="selectedNavigationOption == 'edit'">
          <label style="font-weight: bold;">Estimate:</label>
        </div>

        <div *ngIf="selectedNavigationOption == 'edit'">
          <!-- Blank div for grid spacing -->
        </div>

        <div *ngIf="selectedNavigationOption == 'edit'"
             class="inline-option-grid">
          <label>Price per User:</label>
          <input style="text-align: right;"
                 [readonly]="true"
                 [ngModel]="pricePerUser | number: '.2'"
                 (ngModelChange)="pricePerUser=$event"
                 id="productPriceUser"
                 name="productPriceUser"
                 kendoTextBox />
        </div>

        <!-- <div>
          Blank div for grid spacing
        </div> -->

        <div *ngIf="selectedNavigationOption == 'edit'"
             class="inline-option-grid">
          <label>No of Employees:</label>
          <input style="text-align: right;"
                 [readonly]="true"
                 [(ngModel)]="numberOfEmployees"
                 id="productNumEmployees"
                 name="productNumEmployees"
                 kendoTextBox />
        </div>

        <!-- <div>
          Blank div for grid spacing
        </div> -->

        <div *ngIf="selectedNavigationOption == 'edit'"
             class="inline-option-grid">
          <label>Monthly:</label>
          <input style="text-align: right;"
                 [readonly]="true"
                 [ngModel]="monthly | number: '.2'"
                 (ngModelChange)="monthly=$event"
                 id="productMonthly"
                 name="productMonthly"
                 kendoTextBox />
        </div>

        <!-- <div>
          Blank div for grid spacing
        </div> -->

        <div *ngIf="selectedNavigationOption == 'edit'"
             class="inline-option-grid">
          <label>Annual:</label>
          <input style="text-align: right;"
                 [readonly]="true"
                 [ngModel]="annual | number: '.2'"
                 (ngModelChange)="annual=$event"
                 id="productAnnual"
                 name="productAnnual"
                 kendoTextBox />
        </div>

      </div>
      <div class="block-two-grid"
           *ngIf="selectedNavigationOption == 'edit'">
        <!-- Header -->
        <div>
          <!-- Blank div for grid spacing -->
        </div>
        <p style="text-align: center">Included</p>
        <p style="text-align: center">Add</p>
        <p style="text-align: center">Cost</p>

        <!-- Row 1 -->
        <p>No of System Users:</p>
        <input style="text-align: right;"
               [readonly]="true"
               [(ngModel)]="includedNoSystemUsers"
               id="productSystemUsers"
               name="productSystemUsers"
               kendoTextBox />
        <input style="text-align: right;"
               [(ngModel)]="addNoSystemUsers"
               id="productAddSystemUsers"
               name="productAddSystemUsers"
               type="number"
               min="0"
               pattern="\d*"
               kendoTextBox />
        <input style="text-align: right;"
               [readonly]="true"
               [(ngModel)]="costNoSystemUsers"
               [ngModel]="annual | number: '.2'"
               (ngModelChange)="annual=$event"
               id="productCostSystemUsers"
               name="productCostSystemUsers"
               kendoTextBox />

        <!-- Row 2 -->
        <p>No of Admin Users:</p>
        <input style="text-align: right;"
               [readonly]="true"
               [(ngModel)]="includedNoAdminUsers"
               id="productAdminUsers"
               name="productAdminUsers"
               kendoTextBox />
        <input style="text-align: right;"
               [(ngModel)]="addNoAdminUsers"
               id="productAddAdminUsers"
               name="productAddAdminUsers"
               type="number"
               min="0"
               pattern="\d*"
               kendoTextBox />
        <input style="text-align: right;"
               [readonly]="true"
               [(ngModel)]="costNoAdminUsers"
               [ngModel]="annual | number: '.2'"
               (ngModelChange)="annual=$event"
               id="productCostAdminUsers"
               name="productCostAdminUsers"
               kendoTextBox />

        <!-- Row 3 -->
        <p>Database Capacity (GB):</p>
        <input style="text-align: right;"
               [readonly]="true"
               [(ngModel)]="includedDatabaseCapacity"
               id="productDatabase"
               name="productDatabase"
               kendoTextBox />
        <input style="text-align: right;"
               [(ngModel)]="addDatabaseCapacity"
               id="productAddDatabase"
               name="productAddDatabase"
               type="number"
               min="0"
               pattern="[-+]?[0-9]*\.?[0-9]+"
               kendoTextBox />
        <input style="text-align: right;"
               [readonly]="true"
               [ngModel]="costDatabaseCapacity | number: '.2'"
               (ngModelChange)="costDatabaseCapacity=$event"
               id="productCostDatabase"
               name="productCostDatabase"
               kendoTextBox />

        <!-- Row 4 -->
        <p>File Capacity (GB):</p>
        <input style="text-align: right;"
               [readonly]="true"
               [(ngModel)]="includedFileCapacity"
               id="productFile"
               name="productFile"
               kendoTextBox />
        <input style="text-align: right;"
               [(ngModel)]="addFileCapacity"
               id="productAddFile"
               name="productAddFile"
               type="number"
               min="0"
               pattern="[-+]?[0-9]*\.?[0-9]+"
               kendoTextBox />
        <input style="text-align: right;"
               [readonly]="true"
               [ngModel]="costFileCapacity | number: '.2'"
               (ngModelChange)="costFileCapacity=$event"
               id="productCostFile"
               name="productCostFile"
               kendoTextBox />

        <!-- Row 5 -->
        <p>Log Capacity (GB):</p>
        <input style="text-align: right;"
               [readonly]="true"
               [(ngModel)]="includedLogCapacity"
               id="productLog"
               name="productLog"
               kendoTextBox />
        <input style="text-align: right;"
               [(ngModel)]="addLogCapacity"
               id="productAddLog"
               name="productAddLog"
               type="number"
               min="0"
               pattern="[-+]?[0-9]*\.?[0-9]+"
               kendoTextBox />
        <input style="text-align: right;"
               [readonly]="true"
               [(ngModel)]="costLogCapacity"
               [ngModel]="costLogCapacity | number: '.2'"
               (ngModelChange)="costLogCapacity=$event"
               id="productCostLog"
               name="productCostLog"
               kendoTextBox />
      </div>
    </form>
    <div class="container">
      <button id="edit-button"
              style="position: absolute; bottom: 20px; right: 65px; z-index: 1; "
              [disabled]="!opportunityCustomerForm.valid"
              kendoButton
              themeColor="primary"
              (click)="createOrUpdateCustomer()">
        Submit
      </button>
    </div>
  </div>
  <br />
  <br />

</div>
<!-- #region Confirmation Modal -->
<kendo-dialog *ngIf="showConfirmationModal"
              title="Confirmation"
              [width]="500">
  <div style="height: 60px">
    <p>
      {{ resultMessage }}
    </p>
  </div>
  <div class="confirmation-modal-footer">
    <button kendoButton
            themeColor="primary"
            style="margin-left: auto"
            (click)="closeConfirmationModal()">
      Continue
    </button>
  </div>
</kendo-dialog>
<!-- #endregion Confirmation Modal -->
