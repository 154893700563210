/* tslint:disable */
/* eslint-disable */
import { ProcessesProcessesLegalEntity } from './processes-processes-legal-entity';
import { ProcessesBtProfileRulefrom } from './processes-bt-profile-rulefrom';
export interface ProcessesStructureAppConfig {
  bt_allowancesyn?: null | boolean;
  bt_budgets?: null | boolean;
  bt_eerequired?: null | boolean;
  bt_financialinfo?: null | boolean;
  bt_profilerulefrom?: ProcessesBtProfileRulefrom;
  bt_securityprofile?: null | boolean;
  legalEntity?: ProcessesProcessesLegalEntity;
}
