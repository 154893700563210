/* tslint:disable */
/* eslint-disable */
import { LocationsNew } from './locations-new';
export interface EquipmentItem {
  '_bt_asset_value'?: null | string;
  '_bt_assetclass_value'?: null | string;
  '_bt_createdby_value'?: null | string;
  '_bt_department_value'?: null | string;
  '_bt_division_value'?: null | string;
  '_bt_employee_value'?: null | string;
  '_bt_employees_value'?: null | string;
  '_bt_equipmentmake_value'?: null | string;
  '_bt_equipmentmodel_value'?: null | string;
  '_bt_equipmentrental_value'?: null | string;
  '_bt_equipmentspec_value'?: null | string;
  '_bt_equipmentspectype_value'?: null | string;
  '_bt_equipmenttype_value'?: null | string;
  '_bt_equipmenttypeclass_value'?: null | string;
  '_bt_inspectionperformedby_value'?: null | string;
  '_bt_legalentity_value'?: null | string;
  '_bt_location_value'?: null | string;
  '_bt_newemployee_value'?: null | string;
  '_bt_newlocation_value'?: null | string;
  '_bt_rentalsupplier_value'?: null | string;
  '_bt_requestequipmentitem_value'?: null | string;
  '_bt_supplier_value'?: null | string;
  bt_NewLocation?: LocationsNew;
  bt_alternateserviceunit?: null | number;
  bt_alternatserviceinterval?: null | number;
  bt_assetloaded?: null | boolean;
  bt_assetnumber?: null | string;
  bt_classification?: null | number;
  bt_companyasset?: null | boolean;
  bt_depreciatedamount?: null | number;
  bt_depreciateddate?: null | string;
  bt_depreciationstartdate?: null | string;
  bt_disposaldate?: null | string;
  bt_disposaltype?: null | number;
  bt_disposed?: null | boolean;
  bt_equipmentaging?: null | number;
  bt_equipmentitemid?: null | string;
  bt_equipmentitemno?: null | string;
  bt_equipmentmovementstatus?: null | number;
  bt_equipmentno?: null | string;
  bt_equipmentunique?: null | string;
  bt_expirydate?: null | string;
  bt_fleetmovementstatus?: null | number;
  bt_fueltype?: null | number;
  bt_inspectionstatus?: null | string;
  bt_itemtype?: null | number;
  bt_lastinspectiondate?: null | string;
  bt_lastservicedate?: null | string;
  bt_lastserviceodometerreading?: null | number;
  bt_maintenanceplan?: null | boolean;
  bt_modelyear?: null | string;
  bt_odometerreading?: null | number;
  bt_purchasedate?: null | string;
  bt_purchaseprice?: null | number;
  bt_recordstatus?: null | number;
  bt_refno?: null | string;
  bt_regno?: null | string;
  bt_rentalcost?: null | number;
  bt_rentalend?: null | string;
  bt_rentalquoteamount?: null | number;
  bt_rentalstart?: null | string;
  bt_serviceinterval?: null | number;
  bt_serviceplan?: null | boolean;
  bt_serviceunit?: null | number;
  bt_statuscomment?: null | string;
  bt_tankcapacityl?: null | number;
  bt_tare?: null | number;
  bt_todaydate?: null | string;
  bt_vehiclestatus?: null | number;
  bt_warrantyenddate?: null | string;
  bt_warrantyperiodmonths?: null | number;
  bt_warrantystartdate?: null | string;
  contents?: null | Array<EquipmentItem>;
  name?: null | string;
  odataAlternateServiceUnit?: null | string;
  odataAsset?: null | string;
  odataAssetClass?: null | string;
  odataClassification?: null | string;
  odataCreatedBy?: null | string;
  odataDepartment?: null | string;
  odataDisposalType?: null | string;
  odataDivision?: null | string;
  odataEmployee?: null | string;
  odataEmployees?: null | string;
  odataEquipmentAge?: null | string;
  odataEquipmentModel?: null | string;
  odataEquipmentMovementStatus?: null | string;
  odataEquipmentRental?: null | string;
  odataEquipmentSpec?: null | string;
  odataEquipmentSpecType?: null | string;
  odataEquipmentType?: null | string;
  odataEquipmentTypeClass?: null | string;
  odataFleetMovementStatus?: null | string;
  odataFuelType?: null | string;
  odataInspectionPerformedBy?: null | string;
  odataItemType?: null | string;
  odataLegalEntity?: null | string;
  odataLocation?: null | string;
  odataMake?: null | string;
  odataNewEmployee?: null | string;
  odataNewLocation?: null | string;
  odataRecordStatus?: null | string;
  odataRentalSupplier?: null | string;
  odataRequestEquipmentItem?: null | string;
  odataServiceUnit?: null | string;
  odataVehicleStatus?: null | string;
  odataupplier?: null | string;
  parentID?: null | string;
  quantity?: null | number;
}
