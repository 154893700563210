/* tslint:disable */
/* eslint-disable */
export interface DvLanguageProfeciency {
  bthr_employeelanguageproficiencyid?: null | string;
  bthr_proficiencyread?: null | number;
  bthr_proficiencyspeak?: null | number;
  bthr_proficiencywrite?: null | number;
  odataLanguage?: null | string;
  odataRecordStatus?: null | string;
  odataStatus?: null | string;
  odataproficiencyread?: null | string;
  odataproficiencyspeak?: null | string;
  odataproficiencywrite?: null | string;
}
