/* tslint:disable */
/* eslint-disable */
import { FleetBtProcessStatus } from './fleet-bt-process-status';
export interface FleetFleetMovementCancellation {
  cancellationTypeID?: string;
  cancelledMovementID?: string;
  fleetItemID?: string;
  fleetLocationID?: string;
  fleetMovementCancellationID?: string;
  fleetMovementCancellationName?: null | string;
  legalEntityID?: string;
  processComment?: null | string;
  processStatus?: FleetBtProcessStatus;
  processedDate?: string;
  reasonForRequest?: null | string;
  requestorID?: string;
  requestorLegalEntityID?: string;
}
