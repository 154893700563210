import { Injectable } from '@angular/core';
import { ProcessesApiProcessesService, ProcessesCustomerDetail, ProcessesVerdeProduct } from '@verde/shared';
import { BehaviorSubject, take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OpportunitiesService {
  constructor(private processesApiProcessesService: ProcessesApiProcessesService) {}

  createOrUpdateCustomerDetails(body: ProcessesCustomerDetail) {
    this.processesApiProcessesService
      .postCustomerDetail({ body: { body: body } })
      .pipe(take(1))
      .subscribe((ret) => {
        console.log(ret);
      });
  }

  private verdeProduct$: BehaviorSubject<Array<ProcessesVerdeProduct>> = new BehaviorSubject<Array<ProcessesVerdeProduct>>([]);

  getVerdeProducts() {
    return this.verdeProduct$.asObservable();
  }

  setVerdeProducts(items: ProcessesVerdeProduct[]) {
    this.verdeProduct$.next(items);
  }

  fetchVerdeProducts() {
    this.processesApiProcessesService
      .getVerdeProduct({ body: { filterProperty: '' } })
      .pipe(take(1))
      .subscribe((ret) => {
        this.setVerdeProducts(ret);
      });
  }
}
