<verde-modal id="verdeOnlineTrainingModal"
             [hasCancelButton]="false"
             modalMinWidth="1100"
             modalMaxWidth="1100"
             [showHeader]="false"
             [showFooter]="false"
             [modalHasPadding]="false"
             overflowValue="hidden">
  <div *ngIf="isLottie"
       class="training-modal-background"
       style="background: url('{{selectedStep?.background}}') no-repeat;">
    <ng-lottie [options]="options"
               (animationCreated)="onAnimate($event)">
    </ng-lottie>
  </div>

  <div *ngIf="!isLottie"
       id="stepsInnerHTML"
       class="training-modal-background"
       style="background: url('{{selectedStep?.background}}') no-repeat;">
  </div>

  <div class="loading-modal-footer"
       style="height: 101px; flex-direction: column;">
    <div style="display: inline-flex; align-items: flex-end; height: 100%;">
      <button *ngIf="onlineTrainingConfig?.bt_stepcount > 1"
              kendoButton
              themeColor="primary"
              style="margin-left: 8px; margin-bottom: 1rem; width: 91.48px !important; "
              [disabled]="current === 0"
              (click)="goPrevious()">
        Previous
      </button>
      <div *ngIf="onlineTrainingConfig?.bt_stepcount > 1"
           style="display: inline-flex; width: 100%; height: 100%;">
        <kendo-stepper #stepper
                       style="width: 90%; height: 50px; margin-top: 30px; margin-left:auto;"
                       [steps]="steps"
                       stepType="full"
                       [(currentStep)]="current"
                       [linear]="true"
                       (currentStepChange)="currentStepChange($event)">
        </kendo-stepper>
      </div>
      <div style="display: flex; flex-wrap: wrap; justify-content: flex-end; margin-bottom: 1rem;">
        <div style="width: 142px; margin-bottom: 4px; ">
          <label *ngIf="onlineTrainingConfig?.bt_enabledonotshowagain && allowContinue"
                 style="margin: unset; font-size: 14px;"
                 for="dontShowAgain">Don't show again?</label>
          <input *ngIf="onlineTrainingConfig?.bt_enabledonotshowagain && allowContinue"
                 style="margin-left: 4px;"
                 (change)="dontShowAgainChange($event.target.checked)"
                 type="checkbox"
                 kendoCheckBox
                 id="dontShowAgain"
                 name="dontShowAgain"
                 #varDontShowAgain="ngModel"
                 [ngModel]="dontShowAgain" />
          <br *ngIf="onlineTrainingConfig?.bt_enabledonotshowagain && !allowContinue"
              style="height: 24px;" />
        </div>
        <button kendoButton
                themeColor="primary"
                style="width: 91.48px !important; margin-right: 8px;"
                (click)="goNext()">
          {{continueButtonText}}
        </button>

      </div>

    </div>
  </div>
</verde-modal>

<verde-modal modalTitle="Confirmation"
             id="confirmDontShowAgain"
             [hasCancelButton]="true"
             submitButton="Confirm"
             (actionButton)="confirmDontShowAgain()"
             modalMaxWidth="400"
             [zIndex]="2002">
  <div style="height: 60px;">
    <p>Are you sure you don't want to see this learning content again?</p>
  </div>
</verde-modal>