/* tslint:disable */
/* eslint-disable */
import { DocumentTaxYearAndPeriod } from './document-tax-year-and-period';
export interface DocumentTaxYear {
  bt_bt_taxyearperiod_TaxYear_bt_taxyear?: null | Array<DocumentTaxYearAndPeriod>;
  bt_enddate?: null | string;
  bt_legalentity?: null | string;
  bt_noofholidays?: null | number;
  bt_noofworkdays?: null | number;
  bt_startdate?: null | string;
  bt_taxyearid?: null | string;
  bt_year?: null | string;
}
