import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { UserAppConfigDto } from '@verde/shared';
import { Subject, combineLatest, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';
import { AuthenticationService } from '../../services/authentication.service';
import { UserService } from '../../services/user.service';
@Component({
  selector: 'verde-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnDestroy {
  private onDestroy$ = new Subject<boolean>();
  config: UserAppConfigDto | undefined;
  heading?: string | null = "Welcome to Verde! We're glad you're here.";
  detail?: string | null = 'Make every workday a good one.';
  loginDisable = false;
  loginText = 'Login';

  constructor(private router: Router, private userService: UserService, private authenticationService: AuthenticationService) {
    combineLatest([this.authenticationService.loaded$, this.userService.loaded$, this.userService.config$])
      .pipe(debounceTime(500), distinctUntilChanged(), takeUntil(this.onDestroy$))
      .subscribe((data) => {
        const authLoaded = data[0];
        const userLoaded = data[1];
        this.config = data[2];

        if (authLoaded) {
          if ((!userLoaded && this.config === undefined) || (userLoaded && this.config !== undefined && !this.config.verdeEnvironmentConfig?.verdeError)) {
            this.heading = 'Signed In to Verde!';
            this.detail = 'Loading User Configuration ...';
            this.loginText = 'Signing In ...';
            this.loginDisable = true;
            this.router.navigate(['/']);
          } else {
            this.heading = this.config?.verdeEnvironmentConfig?.verdeErrorMessage;
            this.detail = this.config?.verdeEnvironmentConfig?.verdeErrorDetail;
            this.loginText = 'Retry Login';
            this.loginDisable = false;
          }
        }
      });
  }

  async login() {
    this.loginDisable = true;
    this.loginText = 'Signing In ...';
    await this.authenticationService.signIn();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
