/* tslint:disable */
/* eslint-disable */
import { User } from './user';
export interface VoteGroupOptions {
  '_bt_employee_value'?: null | string;
  '_bt_legalentity_value'?: null | string;
  '_bt_votegroup_value'?: null | string;
  bt_Employee?: User;
  bt_adjustedvotes?: null | number;
  bt_name?: null | string;
  bt_nominationreason?: null | string;
  bt_noofvotes?: null | number;
  bt_votegroupoptionsid?: null | string;
  bt_weight?: null | number;
  odataLegalEntity?: null | string;
  odataVoteGroup?: null | string;
}
