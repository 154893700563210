/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AllTimeTrackingAcessEntries } from '../models/all-time-tracking-acess-entries';
import { StructureActivityModel } from '../models/structure-activity-model';
import { TimeEntryApiModel } from '../models/time-entry-api-model';
import { TimeEntryActivityApiModel } from '../models/time-entry-activity-api-model';
import { TimeEntryGroupApiModel } from '../models/time-entry-group-api-model';
import { User } from '../models/user';
import { UserAccessActivity } from '../models/user-access-activity';

@Injectable({
  providedIn: 'root',
})
export class SharedApiVerdeTimeEntryService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation bthrEmployeesDepartment
   */
  static readonly BthrEmployeesDepartmentPath = '/api/VerdeTimeEntry/get/bthr_employeesDepartment/{DepartmentID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bthrEmployeesDepartment()` instead.
   *
   * This method doesn't expect any request body.
   */
  bthrEmployeesDepartment$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    DepartmentID: string;
  }): Observable<StrictHttpResponse<Array<User>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiVerdeTimeEntryService.BthrEmployeesDepartmentPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('DepartmentID', params.DepartmentID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<User>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `bthrEmployeesDepartment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  bthrEmployeesDepartment(params: {
    subscriptionKey?: string;
    APIURL?: string;
    DepartmentID: string;
  }): Observable<Array<User>> {

    return this.bthrEmployeesDepartment$Response(params).pipe(
      map((r: StrictHttpResponse<Array<User>>) => r.body as Array<User>)
    );
  }

  /**
   * Path part for operation getUserAccessForActivity
   */
  static readonly GetUserAccessForActivityPath = '/api/VerdeTimeEntry/get/GetUserAccessForActivity/{ActivityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserAccessForActivity()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserAccessForActivity$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    ActivityID: string;
  }): Observable<StrictHttpResponse<Array<UserAccessActivity>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiVerdeTimeEntryService.GetUserAccessForActivityPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('ActivityID', params.ActivityID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserAccessActivity>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserAccessForActivity$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserAccessForActivity(params: {
    subscriptionKey?: string;
    APIURL?: string;
    ActivityID: string;
  }): Observable<Array<UserAccessActivity>> {

    return this.getUserAccessForActivity$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserAccessActivity>>) => r.body as Array<UserAccessActivity>)
    );
  }

  /**
   * Path part for operation getEmployeeTimeEntry
   */
  static readonly GetEmployeeTimeEntryPath = '/api/VerdeTimeEntry/get/employeetimeentry/{userID}/{legalEntityID}/{timeEntryStatus}/{source}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEmployeeTimeEntry()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployeeTimeEntry$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userID: string;
    legalEntityID: string;
    timeEntryStatus: string;
    source: string;
  }): Observable<StrictHttpResponse<Array<TimeEntryApiModel>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiVerdeTimeEntryService.GetEmployeeTimeEntryPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('userID', params.userID, {});
      rb.path('legalEntityID', params.legalEntityID, {});
      rb.path('timeEntryStatus', params.timeEntryStatus, {});
      rb.path('source', params.source, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TimeEntryApiModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEmployeeTimeEntry$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployeeTimeEntry(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userID: string;
    legalEntityID: string;
    timeEntryStatus: string;
    source: string;
  }): Observable<Array<TimeEntryApiModel>> {

    return this.getEmployeeTimeEntry$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TimeEntryApiModel>>) => r.body as Array<TimeEntryApiModel>)
    );
  }

  /**
   * Path part for operation getAllEmployeeTimeEntryFiltered
   */
  static readonly GetAllEmployeeTimeEntryFilteredPath = '/api/VerdeTimeEntry/get/getAllEmployeeTimeEntryFiltered/{ActID}/{GroupID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllEmployeeTimeEntryFiltered()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllEmployeeTimeEntryFiltered$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    ActID: string;
    GroupID: string;
  }): Observable<StrictHttpResponse<Array<TimeEntryApiModel>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiVerdeTimeEntryService.GetAllEmployeeTimeEntryFilteredPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('ActID', params.ActID, {});
      rb.path('GroupID', params.GroupID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TimeEntryApiModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllEmployeeTimeEntryFiltered$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllEmployeeTimeEntryFiltered(params: {
    subscriptionKey?: string;
    APIURL?: string;
    ActID: string;
    GroupID: string;
  }): Observable<Array<TimeEntryApiModel>> {

    return this.getAllEmployeeTimeEntryFiltered$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TimeEntryApiModel>>) => r.body as Array<TimeEntryApiModel>)
    );
  }

  /**
   * Path part for operation allTimeTrackingAcessEntries
   */
  static readonly AllTimeTrackingAcessEntriesPath = '/api/VerdeTimeEntry/get/AllTimeTrackingAcessEntries/{TimeEntryID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `allTimeTrackingAcessEntries()` instead.
   *
   * This method doesn't expect any request body.
   */
  allTimeTrackingAcessEntries$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    TimeEntryID: string;
  }): Observable<StrictHttpResponse<Array<AllTimeTrackingAcessEntries>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiVerdeTimeEntryService.AllTimeTrackingAcessEntriesPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('TimeEntryID', params.TimeEntryID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AllTimeTrackingAcessEntries>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `allTimeTrackingAcessEntries$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  allTimeTrackingAcessEntries(params: {
    subscriptionKey?: string;
    APIURL?: string;
    TimeEntryID: string;
  }): Observable<Array<AllTimeTrackingAcessEntries>> {

    return this.allTimeTrackingAcessEntries$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AllTimeTrackingAcessEntries>>) => r.body as Array<AllTimeTrackingAcessEntries>)
    );
  }

  /**
   * Path part for operation getAccessForUser
   */
  static readonly GetAccessForUserPath = '/api/VerdeTimeEntry/get/GetAccessForUser/{EmpID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAccessForUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccessForUser$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    EmpID: string;
  }): Observable<StrictHttpResponse<Array<AllTimeTrackingAcessEntries>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiVerdeTimeEntryService.GetAccessForUserPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('EmpID', params.EmpID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AllTimeTrackingAcessEntries>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAccessForUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccessForUser(params: {
    subscriptionKey?: string;
    APIURL?: string;
    EmpID: string;
  }): Observable<Array<AllTimeTrackingAcessEntries>> {

    return this.getAccessForUser$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AllTimeTrackingAcessEntries>>) => r.body as Array<AllTimeTrackingAcessEntries>)
    );
  }

  /**
   * Path part for operation getEmployeeTimeEntryActivity
   */
  static readonly GetEmployeeTimeEntryActivityPath = '/api/VerdeTimeEntry/get/employeetimeentryactivity/{userID}/{legalEntityID}/{activityStatus}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEmployeeTimeEntryActivity()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployeeTimeEntryActivity$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userID: string;
    legalEntityID: string;
    activityStatus: string;
  }): Observable<StrictHttpResponse<Array<TimeEntryActivityApiModel>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiVerdeTimeEntryService.GetEmployeeTimeEntryActivityPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('userID', params.userID, {});
      rb.path('legalEntityID', params.legalEntityID, {});
      rb.path('activityStatus', params.activityStatus, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TimeEntryActivityApiModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEmployeeTimeEntryActivity$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployeeTimeEntryActivity(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userID: string;
    legalEntityID: string;
    activityStatus: string;
  }): Observable<Array<TimeEntryActivityApiModel>> {

    return this.getEmployeeTimeEntryActivity$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TimeEntryActivityApiModel>>) => r.body as Array<TimeEntryActivityApiModel>)
    );
  }

  /**
   * Path part for operation patchEmployeeTimeEntry
   */
  static readonly PatchEmployeeTimeEntryPath = '/api/VerdeTimeEntry/patch/employeetimeentry';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchEmployeeTimeEntry()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchEmployeeTimeEntry$Response(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: TimeEntryApiModel
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiVerdeTimeEntryService.PatchEmployeeTimeEntryPath, 'patch');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchEmployeeTimeEntry$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchEmployeeTimeEntry(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: TimeEntryApiModel
  }): Observable<boolean> {

    return this.patchEmployeeTimeEntry$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation postEmployeeTimeEntry
   */
  static readonly PostEmployeeTimeEntryPath = '/api/VerdeTimeEntry/post/employeetimeentry';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postEmployeeTimeEntry()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postEmployeeTimeEntry$Response(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: TimeEntryApiModel
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiVerdeTimeEntryService.PostEmployeeTimeEntryPath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postEmployeeTimeEntry$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postEmployeeTimeEntry(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: TimeEntryApiModel
  }): Observable<boolean> {

    return this.postEmployeeTimeEntry$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation postVerdeTimeEntryGroup
   */
  static readonly PostVerdeTimeEntryGroupPath = '/api/VerdeTimeEntry/post/postVerdeTimeEntryGroup';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postVerdeTimeEntryGroup()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postVerdeTimeEntryGroup$Response(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: TimeEntryGroupApiModel
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiVerdeTimeEntryService.PostVerdeTimeEntryGroupPath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postVerdeTimeEntryGroup$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postVerdeTimeEntryGroup(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: TimeEntryGroupApiModel
  }): Observable<boolean> {

    return this.postVerdeTimeEntryGroup$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation postVerdeTimeEntryActivity
   */
  static readonly PostVerdeTimeEntryActivityPath = '/api/VerdeTimeEntry/post/postVerdeTimeEntryActivity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postVerdeTimeEntryActivity()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postVerdeTimeEntryActivity$Response(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: TimeEntryActivityApiModel
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiVerdeTimeEntryService.PostVerdeTimeEntryActivityPath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postVerdeTimeEntryActivity$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postVerdeTimeEntryActivity(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: TimeEntryActivityApiModel
  }): Observable<boolean> {

    return this.postVerdeTimeEntryActivity$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation postVerdeTimeEntryActivitUserAccess
   */
  static readonly PostVerdeTimeEntryActivitUserAccessPath = '/api/VerdeTimeEntry/post/postVerdeTimeEntryActivitUserAccess';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postVerdeTimeEntryActivitUserAccess()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postVerdeTimeEntryActivitUserAccess$Response(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: AllTimeTrackingAcessEntries
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiVerdeTimeEntryService.PostVerdeTimeEntryActivitUserAccessPath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postVerdeTimeEntryActivitUserAccess$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postVerdeTimeEntryActivitUserAccess(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: AllTimeTrackingAcessEntries
  }): Observable<boolean> {

    return this.postVerdeTimeEntryActivitUserAccess$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation getAdminTimeTrackingActivity
   */
  static readonly GetAdminTimeTrackingActivityPath = '/api/VerdeTimeEntry/get/getAdminTimeTrackingActivity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAdminTimeTrackingActivity()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAdminTimeTrackingActivity$Response(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    ActivityOwnerID?: string;
  }): Observable<StrictHttpResponse<Array<TimeEntryActivityApiModel>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiVerdeTimeEntryService.GetAdminTimeTrackingActivityPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.query('ActivityOwnerID', params.ActivityOwnerID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TimeEntryActivityApiModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAdminTimeTrackingActivity$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAdminTimeTrackingActivity(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    ActivityOwnerID?: string;
  }): Observable<Array<TimeEntryActivityApiModel>> {

    return this.getAdminTimeTrackingActivity$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TimeEntryActivityApiModel>>) => r.body as Array<TimeEntryActivityApiModel>)
    );
  }

  /**
   * Path part for operation getAdminTimeTrackingGrouping
   */
  static readonly GetAdminTimeTrackingGroupingPath = '/api/VerdeTimeEntry/get/getAdminTimeTrackingGrouping';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAdminTimeTrackingGrouping()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAdminTimeTrackingGrouping$Response(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    GroupOwnerID?: string;
  }): Observable<StrictHttpResponse<Array<TimeEntryGroupApiModel>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiVerdeTimeEntryService.GetAdminTimeTrackingGroupingPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.query('GroupOwnerID', params.GroupOwnerID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TimeEntryGroupApiModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAdminTimeTrackingGrouping$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAdminTimeTrackingGrouping(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    GroupOwnerID?: string;
  }): Observable<Array<TimeEntryGroupApiModel>> {

    return this.getAdminTimeTrackingGrouping$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TimeEntryGroupApiModel>>) => r.body as Array<TimeEntryGroupApiModel>)
    );
  }

  /**
   * Path part for operation patchVerdeTimeEntryGroup
   */
  static readonly PatchVerdeTimeEntryGroupPath = '/api/VerdeTimeEntry/patch/patchVerdeTimeEntryGroup';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchVerdeTimeEntryGroup()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchVerdeTimeEntryGroup$Response(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: TimeEntryGroupApiModel
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiVerdeTimeEntryService.PatchVerdeTimeEntryGroupPath, 'patch');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchVerdeTimeEntryGroup$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchVerdeTimeEntryGroup(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: TimeEntryGroupApiModel
  }): Observable<boolean> {

    return this.patchVerdeTimeEntryGroup$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation patchVerdeTimeEntryActivity
   */
  static readonly PatchVerdeTimeEntryActivityPath = '/api/VerdeTimeEntry/patch/patchVerdeTimeEntryActivity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchVerdeTimeEntryActivity()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchVerdeTimeEntryActivity$Response(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: TimeEntryActivityApiModel
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiVerdeTimeEntryService.PatchVerdeTimeEntryActivityPath, 'patch');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchVerdeTimeEntryActivity$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchVerdeTimeEntryActivity(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: TimeEntryActivityApiModel
  }): Observable<boolean> {

    return this.patchVerdeTimeEntryActivity$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation patchVerdeTimeEntryActivitUserAccess
   */
  static readonly PatchVerdeTimeEntryActivitUserAccessPath = '/api/VerdeTimeEntry/patch/patchVerdeTimeEntryActivitUserAccess';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchVerdeTimeEntryActivitUserAccess()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchVerdeTimeEntryActivitUserAccess$Response(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: AllTimeTrackingAcessEntries
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiVerdeTimeEntryService.PatchVerdeTimeEntryActivitUserAccessPath, 'patch');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchVerdeTimeEntryActivitUserAccess$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchVerdeTimeEntryActivitUserAccess(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: AllTimeTrackingAcessEntries
  }): Observable<boolean> {

    return this.patchVerdeTimeEntryActivitUserAccess$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation postVerdeTimeEntryStructureActivity
   */
  static readonly PostVerdeTimeEntryStructureActivityPath = '/api/VerdeTimeEntry/post/postVerdeTimeEntryStructureActivity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postVerdeTimeEntryStructureActivity()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postVerdeTimeEntryStructureActivity$Response(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: StructureActivityModel
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiVerdeTimeEntryService.PostVerdeTimeEntryStructureActivityPath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postVerdeTimeEntryStructureActivity$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postVerdeTimeEntryStructureActivity(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: StructureActivityModel
  }): Observable<boolean> {

    return this.postVerdeTimeEntryStructureActivity$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

}
