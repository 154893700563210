<ng-container *ngIf="visible">
  <kendo-button #target
                class="target"
                class="menu"
                [svgIcon]="svgMoreHorizontal"
                [fillMode]="'clear'"
                (click)="openMenu()"></kendo-button>

  <kendo-contextmenu [target]="target"
                     [items]="items"
                     [showOn]="'click'"
                     (select)="itemClicked($event)"></kendo-contextmenu>
</ng-container>