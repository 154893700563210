/* tslint:disable */
/* eslint-disable */
export enum ProfileBtDynamiccomponent {
  Text = 'text',
  Number = 'number',
  Date = 'date',
  Datetimelocal = 'datetimelocal',
  Password = 'password',
  File = 'file',
  Lookup = 'lookup',
  Choice = 'choice'
}
