import { Component, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { VerdeApprovalService } from '../../../../../services/verde-approval/verde-approval.service';
import { TreeListModule } from '@progress/kendo-angular-treelist';

@Component({
  selector: 'verde-template-edit',
  templateUrl: './template-edit.component.html',
  styleUrls: ['./template-edit.component.scss'],
})
export class TemplateEditComponent implements OnInit {
  private onDestroy$ = new Subject<boolean>();

  public selectedTemplateLink: any;
  public selectedTemplateType: string;
  public selectedTemplateName: string;

  constructor(private sidebarService: VerdeApprovalService) {}

  ngOnInit(): void {
    this.selectedTemplateLink = [];

    this.sidebarService
      .getSelectedTemplate()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((template: any) => {
        this.selectedTemplateLink = template;
      });

    this.sidebarService
      .getSelectedTemplateName()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((templateName: any) => {
        this.selectedTemplateName = templateName;
      });

    this.sidebarService
      .getSelectedTemplateType()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((templateType: any) => {
        this.selectedTemplateType = templateType;
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
