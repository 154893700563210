import { ChangeDetectorRef, HostListener, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GridPagingService implements OnDestroy {
  onDestroy$: Subject<any> = new Subject();

  private _pageSize: number = 8;

  set pageSize(val: number) {
    this._pageSize = val;
  }

  get pageSize(): number {
    return this._pageSize;
  }

  private gridIDandRowHeight: string = '';
  private _gridIDandRowHeight = new BehaviorSubject<string>('');
  currentGridIDandRowHeight = this._gridIDandRowHeight.asObservable();

  constructor() {
    this.currentGridIDandRowHeight.pipe(takeUntil(this.onDestroy$)).subscribe((id) => {
      if (id !== '') {
        this.getPageSize(id);
      }
    });

    window.addEventListener('resize', (event) => {
      this.getPageSize(this.gridIDandRowHeight);
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  public changeGridID(id: string) {
    this._gridIDandRowHeight.next(id);
    this.gridIDandRowHeight = id;
  }

  private getPageSize(elementID: string) {
    setTimeout(() => {
      var el = document.getElementById(elementID);

      let rowHeight = el?.children[2]?.children[1]?.children[0]?.children[0]?.children[0]?.children[1]?.children[0]?.children[0]?.clientHeight;

      if (rowHeight === undefined || rowHeight === null) {
        rowHeight = 25;
      }

      let numRows = el?.children[2]?.children[1]?.clientHeight! / rowHeight;

      if (Math.floor(numRows) - 1 <= 0) {
        let roughGridHeight = window.innerHeight - 200;
        let roughPageSize = roughGridHeight / rowHeight;
        this.pageSize = Math.floor(roughPageSize) - 5;
      } else {
        this.pageSize = Math.floor(numRows) - 1;
      }
    }, 100);
  }
}
