/* tslint:disable */
/* eslint-disable */
import { EquipmentEquipmentLegalEntity } from './equipment-equipment-legal-entity';
import { EquipmentEquipmentTypeClass } from './equipment-equipment-type-class';
export interface EquipmentEquipmentType {
  bt_EquipmentTypeClass?: EquipmentEquipmentTypeClass;
  bt_LegalEntity?: EquipmentEquipmentLegalEntity;
  bt_checklistrequired?: null | boolean;
  bt_equipmenttype?: null | string;
  bt_equipmenttypeid?: string;
  bt_equipmenttypeunique?: null | string;
}
