/* tslint:disable */
/* eslint-disable */
export interface DvQualifications {
  bthr_dateawarded?: null | string;
  bthr_employeeeducationalqualificationid?: null | string;
  odataEducationalInstitution?: null | string;
  odataQualification?: null | string;
  odataRecordStatus?: null | string;
  odataStatus?: null | string;
}
