/* tslint:disable */
/* eslint-disable */
import { TasksAssignedToDto } from './tasks-assigned-to-dto';
import { TasksFollowUpDto } from './tasks-follow-up-dto';
import { TasksRelatedToDto } from './tasks-related-to-dto';
import { TasksBtTaskSource } from './tasks-bt-task-source';
import { TasksBtTaskStatus } from './tasks-bt-task-status';
import { TasksBtPriority } from './tasks-bt-priority';
export interface TasksCreateVerdeTaskListCommand {
  approval?: boolean;
  assignedTo?: TasksAssignedToDto;
  dueDate?: string;
  followUp?: TasksFollowUpDto;
  followUpRequired?: boolean;
  priority?: TasksBtPriority;
  referenceColumn?: null | string;
  referenceGUID?: null | string;
  referenceTable?: null | string;
  relatedTo?: TasksRelatedToDto;
  taskDescription?: null | string;
  taskGroupInstanceId?: null | string;
  taskHeader?: null | string;
  taskSource?: TasksBtTaskSource;
  taskStatus?: TasksBtTaskStatus;
  verdeFunctionTechnicalName?: null | string;
  verdeTaskListTemplateId?: null | string;
}
