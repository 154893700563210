import { createEntityAdapter, EntityState, EntityAdapter } from '@ngrx/entity';
import { TaskClosedActions, TaskClosedActionTypes } from '../actions/task-closed.actions';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromTaskRelatedReducer from './task-related.reducer';

// Models
import { VerdeTaskCustom } from '@verde/shared';

// Helpers
import { keyHandler } from '@verde/core';

export interface TaskClosedState extends EntityState<VerdeTaskCustom> {}

export interface TaskClosedRelatedState {
  taskClosedState: TaskClosedState;
  loading: boolean;
  error: any;
}

export const taskClosedKeys = ['bt_verdetasklistid'];
export const taskClosedStateAdapter: EntityAdapter<VerdeTaskCustom> = createEntityAdapter<VerdeTaskCustom>({
  selectId: (data) => keyHandler(data, taskClosedKeys),
});

export const initialTaskClosedState: TaskClosedState = taskClosedStateAdapter.getInitialState({});

export const initialTaskClosedRelatedState = {
  taskClosedState: initialTaskClosedState,
  loading: false,
  error: {},
};

export function taskClosedRelatedReducer(state = initialTaskClosedRelatedState, action: TaskClosedActions): TaskClosedRelatedState {
  switch (action.type) {
    // Get All Task
    case TaskClosedActionTypes.TASK_CLOSED_GET_ALL:
      return { ...state, loading: true };
    case TaskClosedActionTypes.TASK_CLOSED_GET_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        taskClosedState: taskClosedStateAdapter.setAll(action.payload, state.taskClosedState),
      };
    case TaskClosedActionTypes.TASK_CLOSED_GET_ALL_ERROR:
      return { ...state, loading: false, error: action.payload };
    case TaskClosedActionTypes.TASK_CLOSED_CLEAR:
      return { ...initialTaskClosedRelatedState };
    default:
      return state;
  }
}

export const getTaskRelatedState = createFeatureSelector<fromTaskRelatedReducer.TaskRelatedState>('taskRelatedReducer');

export const getTaskClosedRelatedState = createSelector(getTaskRelatedState, (state: fromTaskRelatedReducer.TaskRelatedState) => state.taskClosedRelatedState);

// Current Task

export const getTaskClosedState = createSelector(getTaskClosedRelatedState, (state) => state.taskClosedState);
export const { selectAll: getAllClosedTasks } = taskClosedStateAdapter.getSelectors(getTaskClosedState);

// Loading

export const isTaskClosedLoading = createSelector(getTaskClosedRelatedState, (state) => state.loading);

// Error

export const getTaskClosedError = createSelector(getTaskClosedRelatedState, (state) => state.error);
