<div style="margin-top: 20px">

  <kendo-treelist kendoTreeListExpandable
                  [kendoTreeListHierarchyBinding]="selectedTemplateLink"
                  childrenField="contents"
                  [sortable]="true"
                  [height]="850">

    <kendo-treelist-column [expandable]="true"
                           field="name"
                           title="Groups / Subgroups / Measures"
                           [width]="300">
    </kendo-treelist-column>
    <kendo-treelist-column field="type"
                           title="Type"
                           [width]="100">
    </kendo-treelist-column>

    <kendo-treelist-command-column [width]="40"
                                   title="">
      <ng-template kendoTreeListCellTemplate
                   let-dataItem
                   let-rowIndex="rowIndex"
                   let-cellContext="cellContext">
        <verde-dots-menu-button [items]="treelistMenuItems"
                                [visible]="dataItem.availableBenchmarks == true"
                                (menuClicked)="treelistMenuClicked(dataItem)"
                                (menuItemClicked)="treelistMenuItemClicked($event)"></verde-dots-menu-button>
      </ng-template>
    </kendo-treelist-command-column>


  </kendo-treelist>

</div>