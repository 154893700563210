/* tslint:disable */
/* eslint-disable */
export interface BookingTypesFiltered {
  bt_allowdatetime?: boolean;
  bt_approvalrequired?: boolean;
  bt_bookingcategory?: null | string;
  bt_bookingtype?: null | string;
  bt_bookingtypeid?: null | string;
  bt_department?: boolean;
  bt_displayimage?: boolean;
  bt_location?: boolean;
  bt_office?: boolean;
  bt_reasonrequired?: boolean;
  bt_suboffice?: boolean;
}
