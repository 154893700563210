export enum StructureFunctions {
  // Structure Add
  STRUCTURE_NEW_DEPARTMENT = 'STRUCTURE-NEW-DEPARTMENT',
  STRUCTURE_NEW_DIVISION = 'STRUCTURE-NEW-DIVISION',
  STRUCTURE_NEW_ENTITY = 'STRUCTURE-NEW-ENTITY',
  STRUCTURE_NEW_POSITION = 'STRUCTURE-NEW-POSITION',
  STRUCTURE_NEW_SEAT = 'STRUCTURE-NEW-SEAT',
  // Structure Add Approve
  STRUCTURE_APPROVE_NEW_DEPARTMENT = 'STRUCTURE-APPROVE-NEW-DEPARTMENT',
  STRUCTURE_APPROVE_NEW_DIVISION = 'STRUCTURE-APPROVE-NEW-DIVISION',
  STRUCTURE_APPROVE_NEW_ENTITY = 'STRUCTURE-APPROVE-NEW-ENTITY',
  STRUCTURE_APPROVE_NEW_POSITION = 'STRUCTURE-APPROVE-NEW-POSITION',
  STRUCTURE_APPROVE_NEW_SEAT = 'STRUCTURE-APPROVE-NEW-SEAT',
  // Structure Transfer
  STRUCTURE_TRANSFER_SEAT = 'STRUCTURE-TRANSFER-SEAT',
  STRUCTURE_TRANSFER_TO_DIVISION = 'STRUCTURE-TRANSFER-TO-DIVISION',
  STRUCTURE_TRANSFER_TO_DEPARTMENT = 'STRUCTURE-TRANSFER-TO-DEPARTMENT',
  STRUCTURE_TRANSFER_TO_LEGAL_ENTITY = 'STRUCTURE-TRANSFER-TO-LEGAL-ENTITY',
  STRUCTURE_TRANSFER_EMPLOYEE_AND_SEAT = 'STRUCTURE-TRANSFER-EMPLOYEE-AND-SEAT',
  STRUCTURE_TRANSFER_EMPLOYEE_AND_SEAT_TO_DIVISION = 'STRUCTURE-TRANSFER-EMPLOYEE-AND-SEAT-TO-DIVISION',
  STRUCTURE_TRANSFER_EMPLOYEE_AND_SEAT_TO_DEPARTMENT = 'STRUCTURE-TRANSFER-EMPLOYEE-AND-SEAT-TO-DEPARTMENT',
  // Structure Transfer Approve
  STRUCTURE_APPROVE_TRANSFER_SEAT = 'STRUCTURE-APPROVE-TRANSFER-SEAT',
  STRUCTURE_APPROVE_TRANSFER_TO_DIVISION = 'STRUCTURE-APPROVE-TRANSFER-TO-DIVISION',
  STRUCTURE_APPROVE_TRANSFER_TO_DEPARTMENT = 'STRUCTURE-APPROVE-TRANSFER-TO-DEPARTMENT',
  STRUCTURE_APPROVE_TRANSFER_TO_LEGAL_ENTITY = 'STRUCTURE-APPROVE-TRANSFER-TO-LEGAL-ENTITY',
  STRUCTURE_APPROVE_TRANSFER_EMPLOYEE_AND_SEAT = 'STRUCTURE-APPROVE-TRANSFER-EMPLOYEE-AND-SEAT',
  STRUCTURE_APPROVE_TRANSFER_EMPLOYEE_AND_SEAT_TO_DIVISION = 'STRUCTURE-APPROVE-TRANSFER-EMPLOYEE-AND-SEAT-TO-DIVISION',
  STRUCTURE_APPROVE_TRANSFER_EMPLOYEE_AND_SEAT_TO_DEPARTMENT = 'STRUCTURE-APPROVE-TRANSFER-EMPLOYEE-AND-SEAT-TO-DEPARTMENT',
  // Structure Dispose
  STRUCTURE_DISPOSE_DEPARTMENT = 'STRUCTURE-DISPOSE-DEPARTMENT',
  STRUCTURE_DISPOSE_DIVISION = 'STRUCTURE-DISPOSE-DIVISION',
  STRUCTURE_DISPOSE_ENTITY = 'STRUCTURE-DISPOSE-ENTITY',
  STRUCTURE_DISPOSE_POSITION = 'STRUCTURE-DISPOSE-POSITION',
  STRUCTURE_DISPOSE_SEAT = 'STRUCTURE-DISPOSE-SEAT',
  // Structure Dispose Approve
  STRUCTURE_APPROVE_DISPOSE_DEPARTMENT = 'STRUCTURE-APPROVE-DISPOSE-DEPARTMENT',
  STRUCTURE_APPROVE_DISPOSE_DIVISION = 'STRUCTURE-APPROVE-DISPOSE-DIVISION',
  STRUCTURE_APPROVE_DISPOSE_ENTITY = 'STRUCTURE-APPROVE-DISPOSE-ENTITY',
  STRUCTURE_APPROVE_DISPOSE_POSITION = 'STRUCTURE-APPROVE-DISPOSE-POSITION',
  STRUCTURE_APPROVE_DISPOSE_SEAT = 'STRUCTURE-APPROVE-DISPOSE-SEAT',
}
