import { NgModule } from '@angular/core';

// Kendo
import { GridModule, PDFModule, ExcelModule } from '@progress/kendo-angular-grid';
import { DrawerModule, LayoutModule, TabStripModule, ExpansionPanelModule, SplitterModule, PanelBarModule } from '@progress/kendo-angular-layout';
import { DropDownListModule, DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { EditorModule } from '@progress/kendo-angular-editor';
import { TreeListModule } from '@progress/kendo-angular-treelist';
import { ToolBarModule } from '@progress/kendo-angular-toolbar';
import { MenusModule } from '@progress/kendo-angular-menu';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { FilterModule } from '@progress/kendo-angular-filter';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { ListBoxModule } from '@progress/kendo-angular-listbox';
import { IconsModule } from '@progress/kendo-angular-icons';
import { GaugesModule } from '@progress/kendo-angular-gauges';
import { ScrollViewModule } from '@progress/kendo-angular-scrollview';
import { PivotGridModule } from '@progress/kendo-angular-pivotgrid';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';
import { PopoverModule } from '@progress/kendo-angular-tooltip';
import { IntlModule } from '@progress/kendo-angular-intl';
import { FormFieldModule } from '@progress/kendo-angular-inputs';

export const KENDO_MODULE_EXPORT = [
  ListBoxModule,
  ButtonsModule,
  ChartsModule,
  DateInputsModule,
  DialogsModule,
  DrawerModule,
  DropDownsModule,
  DropDownListModule,
  EditorModule,
  ExcelModule,
  ExpansionPanelModule,
  FilterModule,
  GridModule,
  IndicatorsModule,
  InputsModule,
  LabelModule,
  LayoutModule,
  MenusModule,
  PDFExportModule,
  PDFModule,
  PanelBarModule,
  SplitterModule,
  TabStripModule,
  ToolBarModule,
  TooltipModule,
  TreeListModule,
  UploadsModule,
  IconsModule,
  GaugesModule,
  ScrollViewModule,
  PivotGridModule,
  NotificationModule,
  TooltipsModule,
  PopoverModule,
  IntlModule,
  FormFieldModule,
];

@NgModule({
  imports: [...KENDO_MODULE_EXPORT],
  exports: [...KENDO_MODULE_EXPORT],
})
export class VerdeKendoModule {}
