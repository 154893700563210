/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { EmployeeReviewFiltered } from '../models/employee-review-filtered';
import { EnhancedReviewDetail } from '../models/enhanced-review-detail';
import { EnhancedReviewSummary } from '../models/enhanced-review-summary';
import { PerformanceReviewsFiltered } from '../models/performance-reviews-filtered';
import { ReviewBenchmarks } from '../models/review-benchmarks';
import { ReviewSummariesFiltered } from '../models/review-summaries-filtered';
import { SubgroupSummary } from '../models/subgroup-summary';
import { DvBusinessCycleTypes } from '../models/dv-business-cycle-types';
import { DvBusinessCycles } from '../models/dv-business-cycles';
import { DvPerformanceReview } from '../models/dv-performance-review';
import { DvPerformanceReviewSummaries } from '../models/dv-performance-review-summaries';
import { DvPerformanceReviewPatch } from '../models/dv-performance-review-patch';
import { DvReviewPeriods } from '../models/dv-review-periods';

@Injectable({
  providedIn: 'root',
})
export class SharedApiPerformanceReviewService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getPerformanceReviewRecords
   */
  static readonly GetPerformanceReviewRecordsPath = '/api/PerformanceReview/{employeeGUID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPerformanceReviewRecords()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPerformanceReviewRecords$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    employeeGUID: string;
  }): Observable<StrictHttpResponse<Array<DvPerformanceReview>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiPerformanceReviewService.GetPerformanceReviewRecordsPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('employeeGUID', params.employeeGUID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DvPerformanceReview>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPerformanceReviewRecords$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPerformanceReviewRecords(params: {
    subscriptionKey?: string;
    APIURL?: string;
    employeeGUID: string;
  }): Observable<Array<DvPerformanceReview>> {

    return this.getPerformanceReviewRecords$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DvPerformanceReview>>) => r.body as Array<DvPerformanceReview>)
    );
  }

  /**
   * Path part for operation getAllPerformanceReviewRecords
   */
  static readonly GetAllPerformanceReviewRecordsPath = '/api/PerformanceReview/all/{managerguid}/{periodguid}/{cycletypeguid}/{cycleguid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllPerformanceReviewRecords()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllPerformanceReviewRecords$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    managerguid: string;
    periodguid: string;
    cycletypeguid: string;
    cycleguid: string;
  }): Observable<StrictHttpResponse<Array<PerformanceReviewsFiltered>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiPerformanceReviewService.GetAllPerformanceReviewRecordsPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('managerguid', params.managerguid, {});
      rb.path('periodguid', params.periodguid, {});
      rb.path('cycletypeguid', params.cycletypeguid, {});
      rb.path('cycleguid', params.cycleguid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PerformanceReviewsFiltered>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllPerformanceReviewRecords$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllPerformanceReviewRecords(params: {
    subscriptionKey?: string;
    APIURL?: string;
    managerguid: string;
    periodguid: string;
    cycletypeguid: string;
    cycleguid: string;
  }): Observable<Array<PerformanceReviewsFiltered>> {

    return this.getAllPerformanceReviewRecords$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PerformanceReviewsFiltered>>) => r.body as Array<PerformanceReviewsFiltered>)
    );
  }

  /**
   * Path part for operation getManagerReviewSummaries
   */
  static readonly GetManagerReviewSummariesPath = '/api/PerformanceReview/managersummaries/{managerguid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getManagerReviewSummaries()` instead.
   *
   * This method doesn't expect any request body.
   */
  getManagerReviewSummaries$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    managerguid: string;
  }): Observable<StrictHttpResponse<Array<ReviewSummariesFiltered>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiPerformanceReviewService.GetManagerReviewSummariesPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('managerguid', params.managerguid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ReviewSummariesFiltered>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getManagerReviewSummaries$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getManagerReviewSummaries(params: {
    subscriptionKey?: string;
    APIURL?: string;
    managerguid: string;
  }): Observable<Array<ReviewSummariesFiltered>> {

    return this.getManagerReviewSummaries$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ReviewSummariesFiltered>>) => r.body as Array<ReviewSummariesFiltered>)
    );
  }

  /**
   * Path part for operation getReviewsAndSummaries
   */
  static readonly GetReviewsAndSummariesPath = '/api/PerformanceReview/reviewsandsummaries/{employeeguid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getReviewsAndSummaries()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReviewsAndSummaries$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    employeeguid: string;
  }): Observable<StrictHttpResponse<Array<ReviewSummariesFiltered>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiPerformanceReviewService.GetReviewsAndSummariesPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('employeeguid', params.employeeguid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ReviewSummariesFiltered>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getReviewsAndSummaries$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReviewsAndSummaries(params: {
    subscriptionKey?: string;
    APIURL?: string;
    employeeguid: string;
  }): Observable<Array<ReviewSummariesFiltered>> {

    return this.getReviewsAndSummaries$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ReviewSummariesFiltered>>) => r.body as Array<ReviewSummariesFiltered>)
    );
  }

  /**
   * Path part for operation getPerformanceReviewRecordSummaries
   */
  static readonly GetPerformanceReviewRecordSummariesPath = '/api/PerformanceReview/summaries/{employeeGUID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPerformanceReviewRecordSummaries()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPerformanceReviewRecordSummaries$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    employeeGUID: string;
  }): Observable<StrictHttpResponse<Array<DvPerformanceReviewSummaries>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiPerformanceReviewService.GetPerformanceReviewRecordSummariesPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('employeeGUID', params.employeeGUID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DvPerformanceReviewSummaries>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPerformanceReviewRecordSummaries$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPerformanceReviewRecordSummaries(params: {
    subscriptionKey?: string;
    APIURL?: string;
    employeeGUID: string;
  }): Observable<Array<DvPerformanceReviewSummaries>> {

    return this.getPerformanceReviewRecordSummaries$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DvPerformanceReviewSummaries>>) => r.body as Array<DvPerformanceReviewSummaries>)
    );
  }

  /**
   * Path part for operation getAllReviewPeriods
   */
  static readonly GetAllReviewPeriodsPath = '/api/PerformanceReview/periods/{legalEntityGuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllReviewPeriods()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllReviewPeriods$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityGuid: string;
  }): Observable<StrictHttpResponse<Array<DvReviewPeriods>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiPerformanceReviewService.GetAllReviewPeriodsPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityGuid', params.legalEntityGuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DvReviewPeriods>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllReviewPeriods$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllReviewPeriods(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityGuid: string;
  }): Observable<Array<DvReviewPeriods>> {

    return this.getAllReviewPeriods$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DvReviewPeriods>>) => r.body as Array<DvReviewPeriods>)
    );
  }

  /**
   * Path part for operation getAllBusinessCycleTypes
   */
  static readonly GetAllBusinessCycleTypesPath = '/api/PerformanceReview/cycletypes/{legalEntityGuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllBusinessCycleTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllBusinessCycleTypes$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityGuid: string;
  }): Observable<StrictHttpResponse<Array<DvBusinessCycleTypes>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiPerformanceReviewService.GetAllBusinessCycleTypesPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityGuid', params.legalEntityGuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DvBusinessCycleTypes>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllBusinessCycleTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllBusinessCycleTypes(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityGuid: string;
  }): Observable<Array<DvBusinessCycleTypes>> {

    return this.getAllBusinessCycleTypes$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DvBusinessCycleTypes>>) => r.body as Array<DvBusinessCycleTypes>)
    );
  }

  /**
   * Path part for operation getAllBusinessCycles
   */
  static readonly GetAllBusinessCyclesPath = '/api/PerformanceReview/cycles/{legalEntityGuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllBusinessCycles()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllBusinessCycles$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityGuid: string;
  }): Observable<StrictHttpResponse<Array<DvBusinessCycles>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiPerformanceReviewService.GetAllBusinessCyclesPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityGuid', params.legalEntityGuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DvBusinessCycles>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllBusinessCycles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllBusinessCycles(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityGuid: string;
  }): Observable<Array<DvBusinessCycles>> {

    return this.getAllBusinessCycles$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DvBusinessCycles>>) => r.body as Array<DvBusinessCycles>)
    );
  }

  /**
   * Path part for operation patchPerformanceReviews
   */
  static readonly PatchPerformanceReviewsPath = '/api/PerformanceReview/patch/{managerguid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchPerformanceReviews()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchPerformanceReviews$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    managerguid: string;
    body?: DvPerformanceReviewPatch
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiPerformanceReviewService.PatchPerformanceReviewsPath, 'patch');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('managerguid', params.managerguid, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchPerformanceReviews$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchPerformanceReviews(params: {
    subscriptionKey?: string;
    APIURL?: string;
    managerguid: string;
    body?: DvPerformanceReviewPatch
  }): Observable<boolean> {

    return this.patchPerformanceReviews$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation getUpdatedReview
   */
  static readonly GetUpdatedReviewPath = '/api/PerformanceReview/updatedreview/{reviewid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUpdatedReview()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUpdatedReview$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    reviewid: string;
  }): Observable<StrictHttpResponse<EmployeeReviewFiltered>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiPerformanceReviewService.GetUpdatedReviewPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('reviewid', params.reviewid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmployeeReviewFiltered>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUpdatedReview$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUpdatedReview(params: {
    subscriptionKey?: string;
    APIURL?: string;
    reviewid: string;
  }): Observable<EmployeeReviewFiltered> {

    return this.getUpdatedReview$Response(params).pipe(
      map((r: StrictHttpResponse<EmployeeReviewFiltered>) => r.body as EmployeeReviewFiltered)
    );
  }

  /**
   * Path part for operation getManagerUpdatedReview
   */
  static readonly GetManagerUpdatedReviewPath = '/api/PerformanceReview/managerupdatedreview/{managerguid}/{periodguid}/{cycletypeguid}/{cycleguid}/{reviewid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getManagerUpdatedReview()` instead.
   *
   * This method doesn't expect any request body.
   */
  getManagerUpdatedReview$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    managerguid: string;
    periodguid: string;
    cycletypeguid: string;
    cycleguid: string;
    reviewid: string;
  }): Observable<StrictHttpResponse<PerformanceReviewsFiltered>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiPerformanceReviewService.GetManagerUpdatedReviewPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('managerguid', params.managerguid, {});
      rb.path('periodguid', params.periodguid, {});
      rb.path('cycletypeguid', params.cycletypeguid, {});
      rb.path('cycleguid', params.cycleguid, {});
      rb.path('reviewid', params.reviewid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PerformanceReviewsFiltered>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getManagerUpdatedReview$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getManagerUpdatedReview(params: {
    subscriptionKey?: string;
    APIURL?: string;
    managerguid: string;
    periodguid: string;
    cycletypeguid: string;
    cycleguid: string;
    reviewid: string;
  }): Observable<PerformanceReviewsFiltered> {

    return this.getManagerUpdatedReview$Response(params).pipe(
      map((r: StrictHttpResponse<PerformanceReviewsFiltered>) => r.body as PerformanceReviewsFiltered)
    );
  }

  /**
   * Path part for operation getEnhancedPerformanceReviewEmployee
   */
  static readonly GetEnhancedPerformanceReviewEmployeePath = '/api/PerformanceReview/enhancedperformancereview/{employeeID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEnhancedPerformanceReviewEmployee()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEnhancedPerformanceReviewEmployee$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    employeeID: string;
  }): Observable<StrictHttpResponse<Array<DvPerformanceReview>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiPerformanceReviewService.GetEnhancedPerformanceReviewEmployeePath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('employeeID', params.employeeID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DvPerformanceReview>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEnhancedPerformanceReviewEmployee$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEnhancedPerformanceReviewEmployee(params: {
    subscriptionKey?: string;
    APIURL?: string;
    employeeID: string;
  }): Observable<Array<DvPerformanceReview>> {

    return this.getEnhancedPerformanceReviewEmployee$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DvPerformanceReview>>) => r.body as Array<DvPerformanceReview>)
    );
  }

  /**
   * Path part for operation getEnhancedPerformanceReviewManager
   */
  static readonly GetEnhancedPerformanceReviewManagerPath = '/api/PerformanceReview/enhancedreviewmanager/{managerID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEnhancedPerformanceReviewManager()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEnhancedPerformanceReviewManager$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    managerID: string;
  }): Observable<StrictHttpResponse<Array<DvPerformanceReview>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiPerformanceReviewService.GetEnhancedPerformanceReviewManagerPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('managerID', params.managerID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DvPerformanceReview>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEnhancedPerformanceReviewManager$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEnhancedPerformanceReviewManager(params: {
    subscriptionKey?: string;
    APIURL?: string;
    managerID: string;
  }): Observable<Array<DvPerformanceReview>> {

    return this.getEnhancedPerformanceReviewManager$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DvPerformanceReview>>) => r.body as Array<DvPerformanceReview>)
    );
  }

  /**
   * Path part for operation getReviewBenchmarksByGroup
   */
  static readonly GetReviewBenchmarksByGroupPath = '/api/PerformanceReview/reviewbenchmarksgroup/{legalEntityID}/{groupID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getReviewBenchmarksByGroup()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReviewBenchmarksByGroup$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
    groupID: string;
  }): Observable<StrictHttpResponse<Array<ReviewBenchmarks>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiPerformanceReviewService.GetReviewBenchmarksByGroupPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityID', params.legalEntityID, {});
      rb.path('groupID', params.groupID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ReviewBenchmarks>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getReviewBenchmarksByGroup$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReviewBenchmarksByGroup(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
    groupID: string;
  }): Observable<Array<ReviewBenchmarks>> {

    return this.getReviewBenchmarksByGroup$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ReviewBenchmarks>>) => r.body as Array<ReviewBenchmarks>)
    );
  }

  /**
   * Path part for operation getReviewBenchmarksBySubgroup
   */
  static readonly GetReviewBenchmarksBySubgroupPath = '/api/PerformanceReview/reviewbenchmarkssubgroup/{legalEntityID}/{subgroupID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getReviewBenchmarksBySubgroup()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReviewBenchmarksBySubgroup$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
    subgroupID: string;
  }): Observable<StrictHttpResponse<Array<ReviewBenchmarks>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiPerformanceReviewService.GetReviewBenchmarksBySubgroupPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityID', params.legalEntityID, {});
      rb.path('subgroupID', params.subgroupID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ReviewBenchmarks>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getReviewBenchmarksBySubgroup$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReviewBenchmarksBySubgroup(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
    subgroupID: string;
  }): Observable<Array<ReviewBenchmarks>> {

    return this.getReviewBenchmarksBySubgroup$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ReviewBenchmarks>>) => r.body as Array<ReviewBenchmarks>)
    );
  }

  /**
   * Path part for operation getEnhancedReviewSubgroupSummaryEmployee
   */
  static readonly GetEnhancedReviewSubgroupSummaryEmployeePath = '/api/PerformanceReview/reviewsubgroupsummaryemployee/{employeeID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEnhancedReviewSubgroupSummaryEmployee()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEnhancedReviewSubgroupSummaryEmployee$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    employeeID: string;
  }): Observable<StrictHttpResponse<Array<SubgroupSummary>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiPerformanceReviewService.GetEnhancedReviewSubgroupSummaryEmployeePath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('employeeID', params.employeeID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SubgroupSummary>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEnhancedReviewSubgroupSummaryEmployee$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEnhancedReviewSubgroupSummaryEmployee(params: {
    subscriptionKey?: string;
    APIURL?: string;
    employeeID: string;
  }): Observable<Array<SubgroupSummary>> {

    return this.getEnhancedReviewSubgroupSummaryEmployee$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SubgroupSummary>>) => r.body as Array<SubgroupSummary>)
    );
  }

  /**
   * Path part for operation getEnhancedReviewSubgroupSummaryManager
   */
  static readonly GetEnhancedReviewSubgroupSummaryManagerPath = '/api/PerformanceReview/reviewsubgroupsummarymanager/{managerID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEnhancedReviewSubgroupSummaryManager()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEnhancedReviewSubgroupSummaryManager$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    managerID: string;
  }): Observable<StrictHttpResponse<Array<SubgroupSummary>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiPerformanceReviewService.GetEnhancedReviewSubgroupSummaryManagerPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('managerID', params.managerID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SubgroupSummary>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEnhancedReviewSubgroupSummaryManager$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEnhancedReviewSubgroupSummaryManager(params: {
    subscriptionKey?: string;
    APIURL?: string;
    managerID: string;
  }): Observable<Array<SubgroupSummary>> {

    return this.getEnhancedReviewSubgroupSummaryManager$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SubgroupSummary>>) => r.body as Array<SubgroupSummary>)
    );
  }

  /**
   * Path part for operation getEnhancedReviewDetailSummaryEmployee
   */
  static readonly GetEnhancedReviewDetailSummaryEmployeePath = '/api/PerformanceReview/reviewdetailsummaryemployee/{employeeID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEnhancedReviewDetailSummaryEmployee()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEnhancedReviewDetailSummaryEmployee$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    employeeID: string;
  }): Observable<StrictHttpResponse<Array<EnhancedReviewSummary>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiPerformanceReviewService.GetEnhancedReviewDetailSummaryEmployeePath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('employeeID', params.employeeID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EnhancedReviewSummary>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEnhancedReviewDetailSummaryEmployee$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEnhancedReviewDetailSummaryEmployee(params: {
    subscriptionKey?: string;
    APIURL?: string;
    employeeID: string;
  }): Observable<Array<EnhancedReviewSummary>> {

    return this.getEnhancedReviewDetailSummaryEmployee$Response(params).pipe(
      map((r: StrictHttpResponse<Array<EnhancedReviewSummary>>) => r.body as Array<EnhancedReviewSummary>)
    );
  }

  /**
   * Path part for operation getEnhancedReviewDetailSummaryManager
   */
  static readonly GetEnhancedReviewDetailSummaryManagerPath = '/api/PerformanceReview/reviewdetailsummarymanager/{managerID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEnhancedReviewDetailSummaryManager()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEnhancedReviewDetailSummaryManager$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    managerID: string;
  }): Observable<StrictHttpResponse<Array<EnhancedReviewSummary>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiPerformanceReviewService.GetEnhancedReviewDetailSummaryManagerPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('managerID', params.managerID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EnhancedReviewSummary>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEnhancedReviewDetailSummaryManager$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEnhancedReviewDetailSummaryManager(params: {
    subscriptionKey?: string;
    APIURL?: string;
    managerID: string;
  }): Observable<Array<EnhancedReviewSummary>> {

    return this.getEnhancedReviewDetailSummaryManager$Response(params).pipe(
      map((r: StrictHttpResponse<Array<EnhancedReviewSummary>>) => r.body as Array<EnhancedReviewSummary>)
    );
  }

  /**
   * Path part for operation getEnhancedReviewDetail
   */
  static readonly GetEnhancedReviewDetailPath = '/api/PerformanceReview/enhancedreviewdetail/{responderID}/{employeeID}/{legalEntityID}/{templateID}/{cycleID}/{periodID}/{detailType}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEnhancedReviewDetail()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEnhancedReviewDetail$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    responderID: string;
    employeeID: string;
    legalEntityID: string;
    templateID: string;
    cycleID: string;
    periodID: string;
    detailType: string;
  }): Observable<StrictHttpResponse<Array<EnhancedReviewDetail>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiPerformanceReviewService.GetEnhancedReviewDetailPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('responderID', params.responderID, {});
      rb.path('employeeID', params.employeeID, {});
      rb.path('legalEntityID', params.legalEntityID, {});
      rb.path('templateID', params.templateID, {});
      rb.path('cycleID', params.cycleID, {});
      rb.path('periodID', params.periodID, {});
      rb.path('detailType', params.detailType, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EnhancedReviewDetail>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEnhancedReviewDetail$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEnhancedReviewDetail(params: {
    subscriptionKey?: string;
    APIURL?: string;
    responderID: string;
    employeeID: string;
    legalEntityID: string;
    templateID: string;
    cycleID: string;
    periodID: string;
    detailType: string;
  }): Observable<Array<EnhancedReviewDetail>> {

    return this.getEnhancedReviewDetail$Response(params).pipe(
      map((r: StrictHttpResponse<Array<EnhancedReviewDetail>>) => r.body as Array<EnhancedReviewDetail>)
    );
  }

  /**
   * Path part for operation getEnhancedReviewDetailOverview
   */
  static readonly GetEnhancedReviewDetailOverviewPath = '/api/PerformanceReview/enhanceddetailoverview/{legalEntityID}/{templateID}/{cycleID}/{periodID}/{employeeID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEnhancedReviewDetailOverview()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEnhancedReviewDetailOverview$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    employeeID: string;
    legalEntityID: string;
    templateID: string;
    cycleID: string;
    periodID: string;
  }): Observable<StrictHttpResponse<Array<EnhancedReviewDetail>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiPerformanceReviewService.GetEnhancedReviewDetailOverviewPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('employeeID', params.employeeID, {});
      rb.path('legalEntityID', params.legalEntityID, {});
      rb.path('templateID', params.templateID, {});
      rb.path('cycleID', params.cycleID, {});
      rb.path('periodID', params.periodID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EnhancedReviewDetail>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEnhancedReviewDetailOverview$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEnhancedReviewDetailOverview(params: {
    subscriptionKey?: string;
    APIURL?: string;
    employeeID: string;
    legalEntityID: string;
    templateID: string;
    cycleID: string;
    periodID: string;
  }): Observable<Array<EnhancedReviewDetail>> {

    return this.getEnhancedReviewDetailOverview$Response(params).pipe(
      map((r: StrictHttpResponse<Array<EnhancedReviewDetail>>) => r.body as Array<EnhancedReviewDetail>)
    );
  }

  /**
   * Path part for operation patchEnhancedReviewDetailSummary
   */
  static readonly PatchEnhancedReviewDetailSummaryPath = '/api/PerformanceReview/patch/enhancedsummary';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchEnhancedReviewDetailSummary()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchEnhancedReviewDetailSummary$Response(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: EnhancedReviewSummary
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiPerformanceReviewService.PatchEnhancedReviewDetailSummaryPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchEnhancedReviewDetailSummary$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchEnhancedReviewDetailSummary(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: EnhancedReviewSummary
  }): Observable<boolean> {

    return this.patchEnhancedReviewDetailSummary$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation patchEnhancedReviewDetail
   */
  static readonly PatchEnhancedReviewDetailPath = '/api/PerformanceReview/patch/enhanceddetail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchEnhancedReviewDetail()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchEnhancedReviewDetail$Response(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: EnhancedReviewDetail
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiPerformanceReviewService.PatchEnhancedReviewDetailPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchEnhancedReviewDetail$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchEnhancedReviewDetail(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: EnhancedReviewDetail
  }): Observable<boolean> {

    return this.patchEnhancedReviewDetail$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

}
