/* tslint:disable */
/* eslint-disable */
import { DocumentBtMfaStatus } from './document-bt-mfa-status';
export interface DocumentPostVerdePayslipAccessControl {
  bt_mfaresponse?: null | string;
  bt_mfastatus?: DocumentBtMfaStatus;
  bt_payslipforemployee?: null | string;
  bt_payslipforentity?: null | string;
  bt_requestedby?: null | string;
  bt_requestedbyentity?: null | string;
}
