/* tslint:disable */
/* eslint-disable */
import { ProfileBthrRecordStatus } from './profile-bthr-record-status';
import { ProfileBthrRestrictions } from './profile-bthr-restrictions';
import { ProfileBthrVehicleRestrictions } from './profile-bthr-vehicle-restrictions';
import { ProfileBthrCode } from './profile-bthr-code';
export interface ProfileDriversLicense {
  '_bt_country_value'?: null | string;
  '_bt_type_value'?: null | string;
  bt_backimage?: null | string;
  bt_backimagename?: null | string;
  bt_countryidissued?: null | string;
  bt_frontimage?: null | string;
  bt_frontimagename?: null | string;
  bt_idnumbervalid?: null | boolean;
  bt_licensenocaptured?: null | string;
  bt_licensevalidated?: null | boolean;
  bt_processfrombarcode?: null | boolean;
  bt_recordname?: null | string;
  bt_scanbirthdate?: null | string;
  bt_scangender?: null | string;
  bt_scanidnumber?: null | string;
  bt_scanidtype?: null | string;
  bt_scaninitials?: null | string;
  bt_scansurname?: null | string;
  bthr_codes?: null | Array<ProfileBthrCode>;
  bthr_driverslicenseid?: string;
  bthr_firstissue?: null | string;
  bthr_licensenonumber_t_?: null | string;
  bthr_recordstatus?: ProfileBthrRecordStatus;
  bthr_restrictions?: ProfileBthrRestrictions;
  bthr_validfrom?: null | string;
  bthr_validto?: null | string;
  bthr_vehiclerestrictionsmultiselect?: null | Array<ProfileBthrVehicleRestrictions>;
}
