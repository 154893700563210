/* tslint:disable */
/* eslint-disable */
export enum UserBthrOccupationalLevel {
  TopManagementExecutives = 'TopManagementExecutives',
  SeniorManagement = 'SeniorManagement',
  ProfessionallyQualifiedExperiencedSpecialistsMidmanagement = 'ProfessionallyQualifiedExperiencedSpecialistsMidmanagement',
  SkilledTechnicalAcademicallyQualifiedJuniorManagementSuperviso = 'SkilledTechnicalAcademicallyQualifiedJuniorManagementSuperviso',
  SemiSkilledDiscretionaryDecisionmaking = 'SemiSkilledDiscretionaryDecisionmaking',
  UnskilledDefinedDecisionmaking = 'UnskilledDefinedDecisionmaking'
}
