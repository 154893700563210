<!--<div style="padding: 20px">

  <div>
    
    <label>Benchmark:</label>
    <br />
    <kendo-textbox [readonly]="false"
                   [value]=""
                   required></kendo-textbox>
    <br />
    <br />
    <label>Min Value:</label>
    <br />
    <kendo-textbox [readonly]="false"
                   [value]=""
                   required
                   pattern="[0-9]*"></kendo-textbox>
    <br />
    <br />
    <label>Max Value:</label>
    <br />
    <kendo-textbox [readonly]="false"
                   [value]=""
                   required
                   pattern="[0-9]*"></kendo-textbox>
    <br />
    <br />
    <label>Description:</label>
    <br />
    <kendo-textbox [readonly]="false"
                   [value]=""
                   required></kendo-textbox>
  </div>

</div>

<div class="container">
  <button id="add-button" style="float: right; margin-right: 30px" kendoButton themeColor="primary" (click)="addBenchmark()">Add</button>
</div>-->


<form [formGroup]="benchmarkForm" (ngSubmit)="addBenchmark()">
  <!-- Form fields -->
  <div style="padding: 20px">
    <div>
      <label>Benchmark:</label>
      <br />
      <kendo-textbox formControlName="benchmark" [readonly]="false"></kendo-textbox>
      <br />
      <br />
      <label>Min Value:</label>
      <br />
      <kendo-textbox formControlName="minValue" [readonly]="false"></kendo-textbox>
      <br />
      <br />
      <label>Max Value:</label>
      <br />
      <kendo-textbox formControlName="maxValue" [readonly]="false"></kendo-textbox>
      <br />
      <br />
      <label>Description:</label>
      <br />
      <kendo-textbox formControlName="description" [readonly]="false"></kendo-textbox>
    </div>
  </div>

  <div class="container">
    <button id="add-button" style="float: right; margin-right: 30px" kendoButton themeColor="primary" [disabled]="!benchmarkForm.valid">Add</button>
  </div>
</form>
