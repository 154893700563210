/* eslint-disable no-console */
import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Subject, take } from 'rxjs';
import { DocumentApiDocumentService, DocumentEnhanced, DocumentGetEnhancedQuery, PeopleManagement, SharedApiHrPortalService } from '@verde/shared';

@Injectable({
  providedIn: 'root',
})
export class HrPortalService {
  private refrsh = new Subject<void>();
  public parameterEmitter: EventEmitter<any> = new EventEmitter();
  methodCall$ = this.refrsh.asObservable();

  constructor(private sharedApiHrPortalService: SharedApiHrPortalService, private documentApiDocumentService: DocumentApiDocumentService) {}

  // Payroll Tab
  private _showPayroll = new BehaviorSubject<boolean>(false);

  currentShowPayroll = this._showPayroll.asObservable();

  changeShowPayroll(flag: boolean) {
    this._showPayroll.next(flag);
  }

  // HR Payslips Tab
  private _showHRPayslips = new BehaviorSubject<boolean>(false);

  currentShowHRPayslips = this._showHRPayslips.asObservable();

  changeShowHRPayslips(flag: boolean) {
    this._showHRPayslips.next(flag);
  }

  // Bulk upload Docs
  private _showBulkUploadDocs = new BehaviorSubject<boolean>(false);

  currentShowBulkUploadDocs = this._showBulkUploadDocs.asObservable();

  changeShowBulkUploadDocs(flag: boolean) {
    this._showBulkUploadDocs.next(flag);
  }

  // Hr Succs
  private _showHrSuccs = new BehaviorSubject<boolean>(false);

  currentHrSuccs = this._showHrSuccs.asObservable();

  changeShowHrSuccs(flag: boolean) {
    this._showHrSuccs.next(flag);
  }

  // View Employees Tab
  private _showViewEmployees = new BehaviorSubject<boolean>(false);

  currentShowViewEmployees = this._showViewEmployees.asObservable();

  changeShowViewEmployees(flag: boolean) {
    this._showViewEmployees.next(flag);
  }

  // Employees Exit Tab
  private _showEmployeeExit = new BehaviorSubject<boolean>(false);

  currentShowEmployeeExit = this._showEmployeeExit.asObservable();

  changeShowEmployeeExit(flag: boolean) {
    this._showEmployeeExit.next(flag);
  }
  // Employees Resignations Tab
  private _showEmployeeResignations = new BehaviorSubject<boolean>(false);

  currentShowEmployeeResignations = this._showEmployeeResignations.asObservable();

  changeShowResignations(flag: boolean) {
    this._showEmployeeResignations.next(flag);
  }
  // Employees Onboarding Tab
  private _showEmployeeOnboarding = new BehaviorSubject<boolean>(false);

  currentShowEmployeeOnboarding = this._showEmployeeOnboarding.asObservable();

  changeShowEmployeeOnboarding(flag: boolean) {
    this._showEmployeeOnboarding.next(flag);
  }

  // Timeoff Calendar Tab
  private _showTimeoffCalendar = new BehaviorSubject<boolean>(false);

  currentShowTimeoffCalendar = this._showTimeoffCalendar.asObservable();

  changeShowTimeoffCalendar(flag: boolean) {
    this._showTimeoffCalendar.next(flag);
  }

  // Dynamic Form Grid
  private _showDynamicFormGrid = new BehaviorSubject<boolean>(false);

  currentShowDynamicFormGrid = this._showDynamicFormGrid.asObservable();

  changeShowDynamicFormGrid(flag: boolean) {
    this._showDynamicFormGrid.next(flag);
  }

  // View Employees People Managment
  private _peopleManagement = new BehaviorSubject<PeopleManagement>({});

  currentPeopleManagement = this._peopleManagement.asObservable();

  changePeopleManagement(man: PeopleManagement) {
    this._peopleManagement.next(man);
  }

  // Employee Count
  private _allEmployeeCounts = new BehaviorSubject<Array<PeopleManagement>>(new Array<PeopleManagement>());

  currentAllEmployeeCounts = this._allEmployeeCounts.asObservable();

  changeAllEmployeeCounts(counts: PeopleManagement[]) {
    this._allEmployeeCounts.next(counts);
  }

  getPeopleManagementEmployeeCounts(legalEntityID: string) {
    this.sharedApiHrPortalService
      .getPeopleManagementEmployeeCounts({ legalEntityID: legalEntityID })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          this.changeAllEmployeeCounts(ret);
        },
        (error) => {
          console.error(error);
        },
      );
  }
  // Enhanced Security
  private _allEnhancedSecurity = new BehaviorSubject<Array<DocumentEnhanced>>(new Array<DocumentEnhanced>());

  currentAllEnhancedSecurity = this._allEnhancedSecurity.asObservable();

  changeAllEnhancedSecurity(enhanced: DocumentEnhanced[]) {
    this._allEnhancedSecurity.next(enhanced);
  }

  getEnhancedSecurity(body: DocumentGetEnhancedQuery) {
    this.documentApiDocumentService
      .getEnhanced({ body: body })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          this.changeAllEnhancedSecurity(ret);
        },
        (error) => {
          console.error(error);
        },
      );
  }

  refreshView() {
    this.refrsh.next();
  }

  emitParameter(param: any) {
    this.parameterEmitter.emit(param);
  }
}
