/* tslint:disable */
/* eslint-disable */
export interface DvAccreditationAuthorities {
  bthr_accreditationauthority?: null | string;
  bthr_accreditationauthorityid?: null | string;
  bthr_enddate?: null | string;
  bthr_registrationnumber?: null | string;
  bthr_startdate?: null | string;
  odataAuthorityType?: null | string;
  odataCountry?: null | string;
  odataLegalEntity?: null | string;
}
