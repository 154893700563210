/* tslint:disable */
/* eslint-disable */
export interface DvCards {
  bthr_accountnumber?: null | string;
  bthr_expirydate?: null | string;
  bthr_issueddate?: null | string;
  bthr_lastfordigits?: null | string;
  odataIssuer?: null | string;
  odataStatus?: null | string;
  odataType?: null | string;
}
