/* tslint:disable */
/* eslint-disable */
export interface PerformanceReviewUser {
  _bt_citizenshipstatus_value?: null | string;
  _bt_costcentre_value?: null | string;
  _bt_countryofresidence_value?: null | string;
  _bt_defaultequipmentstore_value?: null | string;
  _bt_defaultequipmentworkshop_value?: null | string;
  _bt_defaultfleetlocation_value?: null | string;
  _bt_district_value?: null | string;
  _bt_employeeclassification_value?: null | string;
  _bt_ethnicitylookup_value?: null | string;
  _bt_genderlookup_value?: null | string;
  _bt_grouplocation_value?: null | string;
  _bt_managerentity_value?: null | string;
  _bt_occupationallevellookup_value?: null | string;
  _bt_office_value?: null | string;
  _bt_partnershipstatuslookup_value?: null | string;
  _bt_paymentmethod_value?: null | string;
  _bt_probationtype_value?: null | string;
  _bt_provincestate_value?: null | string;
  _bt_racelookup_value?: null | string;
  _bt_shifttype_value?: null | string;
  _bt_skillsclassificationlookup_value?: null | string;
  _bt_verdesuboffice_value?: null | string;
  _bthr_department_value?: null | string;
  _bthr_division_value?: null | string;
  _bthr_employeetype_value?: null | string;
  _bthr_grade_value?: null | string;
  _bthr_legalentity_value?: null | string;
  _bthr_manager_value?: null | string;
  _bthr_primarylocation_value?: null | string;
  _bthr_religioncode_value?: null | string;
  _createdby_value?: null | string;
  _createdonbehalfby_value?: null | string;
  _modifiedby_value?: null | string;
  _modifiedonbehalfby_value?: null | string;
  _ownerid_value?: null | string;
  bt_azureobjectid?: null | string;
  bt_channelid?: null | string;
  bt_chatid?: null | string;
  bt_commissionearner?: null | boolean;
  bt_defaultworkplace?: null | string;
  bt_driverslicense?: null | boolean;
  bt_driverslicenseexpirydate?: null | string;
  bt_driverslicensestatus?: null | number;
  bt_employeestatus?: null | number;
  bt_enableforemployee?: null | boolean;
  bt_fullname?: null | string;
  fullNameWithEmpId?: null | string;
  bt_hassubordinates?: null | boolean;
  bt_ismanager?: null | boolean;
  bt_kpi?: null | boolean;
  bt_legalentityjoindate?: null | string;
  bt_managerfullname?: null | string;
  bt_neworganizationjoindate?: null | string;
  bt_overridemanagerpayslipaccess?: null | boolean;
  bt_phonenumber?: null | string;
  bt_probationenddate?: null | string;
  bt_probationmonths?: null | string;
  bt_subordinates?: null | number;
  bt_succession?: null | boolean;
  bt_tenantid?: null | string;
  bt_timeoffissues?: null | string;
  bthr_aduser?: null | boolean;
  bthr_crimicalrecord?: null | boolean;
  bthr_criminalrecordverified?: null | string;
  bthr_dateofbirth?: null | string;
  bthr_disabilities?: null | boolean;
  bthr_email?: null | string;
  bthr_employee_id?: null | string;
  bthr_employeeid?: null | string;
  bthr_employeephoto?: null | string;
  bthr_employeephotoid?: null | string;
  bthr_firstname?: null | string;
  bthr_foreignnational?: null | boolean;
  bthr_hometelephone?: null | string;
  bthr_initials?: null | string;
  bthr_jobskill?: null | number;
  bthr_lastname?: null | string;
  bthr_lastworkday?: null | string;
  bthr_manageremail?: null | string;
  bthr_middlenames?: null | string;
  bthr_mobiletelephone?: null | string;
  bthr_nationalidnumber?: null | string;
  bthr_organisationjoindate?: null | string;
  bthr_partnershipstatus?: null | number;
  bthr_paypoint?: null | number;
  bthr_payrollnumber?: null | string;
  bthr_personalemail?: null | string;
  bthr_placeofbirthtext?: null | string;
  bthr_preferredname?: null | string;
  bthr_recordstatus?: null | number;
  bthr_resignationdate?: null | string;
  bthr_startdate?: null | string;
  bthr_taxnumber?: null | string;
  bthr_terminationnoticedate?: null | string;
  bthr_terminationreason?: null | number;
  bthr_userid?: null | string;
  bthr_yearsatentity?: null | number;
  bthr_yearsatorg?: null | number;
  createdon?: null | string;
  modifiedon?: null | string;
  status?: null | string;
}
