/* tslint:disable */
/* eslint-disable */
import { ProcessesProcessesEmployee } from './processes-processes-employee';
import { ProcessesBtSeatMovementType } from './processes-bt-seat-movement-type';
export interface ProcessesPositionSeatEmployee {
  bt_employee?: string;
  bt_employeelegalentity?: string;
  bt_legalentity?: string;
  bt_movementtype?: ProcessesBtSeatMovementType;
  bt_positionseat?: string;
  bthr_Employee?: ProcessesProcessesEmployee;
  positionSeatId?: string;
}
