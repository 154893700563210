<div class="image-upload-container">
  <div class="title-option"
       *ngIf="!imageOption">
    <h3>Please select an option:</h3>
  </div>
  <div class="options-container">
    <div *ngIf="imageOption === 'upload'"
         class="upload-file-option">
      <div class="image-dropzone"
           *ngIf="!uploadedImage"
           fileDragAndDrop
           [style.background-color]="hoverActive ? '#3E3E3E22' : '#00000000'"
           (fileDropped)="fileDropped($event)"
           (fileOnHover)="setHoverState($event)">
        <mat-icon>file_upload</mat-icon>
        <input id="file-upload"
               type="file"
               [accept]="acceptFileTypes"
               (change)="onFileChanged($event)" />
        <div class="upload-text">
          <h3>Drag and drop image here</h3>
          <h3>or</h3>
        </div>
        <label for="file-upload">Browse for image</label>
      </div>

      <div *ngIf="uploadedImage"
           class="uploaded-image">
        <div *ngIf="!isPdf"
             class="image-container">
          <img [src]="uploadedImage"
               alt="" />
        </div>

        <div *ngIf="isPdf"
             class="pdf-container">
          <mat-icon>picture_as_pdf</mat-icon>
          <h6>PDF file</h6>
        </div>
        <button kendoButton
                themeColor="primary"
                (click)="resetImage()">Change</button>
      </div>
    </div>
    <div *ngIf="imageOption === 'photo'"
         class="take-photo-option">
      <div *ngIf="!uploadedImage"
           class="camera-capture-container">
        <webcam [height]="300"
                [trigger]="trigger"
                [mirrorImage]="'never'"
                (imageCapture)="handleImage($event)"></webcam>
        <button kendoButton
                themeColor="primary"
                (click)="triggerSnapshot()">Capture image</button>
      </div>
      <div *ngIf="uploadedImage"
           class="uploaded-image">
        <div class="image-container">
          <img [src]="uploadedImage"
               alt="" />
        </div>
        <button kendoButton
                themeColor="primary"
                (click)="resetImage()">Retake image</button>
      </div>
    </div>
  </div>

  <div *ngIf="state === 'all'"
       class="select-options">
    <button kendoButton
            themeColor="primary"
            (click)="selectOption(IMAGE_OPTIONS.Upload)">{{ addText }}</button>
    <button kendoButton
            themeColor="primary"
            (click)="selectOption(IMAGE_OPTIONS.Photo)">Use camera</button>
  </div>
</div>
