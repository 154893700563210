<div style="padding: 20px">

  <div>
    <label>Legal Entity:</label>
    <br />
    <kendo-textbox [readonly]="true"
                   [value]="selectedGroup.bt_LegalEntity.bthr_legalentityname"></kendo-textbox>
    <br />
    <br />
    <label>Template Type:</label>
    <br />
    <kendo-textbox [readonly]="true"
                   [value]="selectedGroup.bt_TemplateType"></kendo-textbox>
    <br />
    <br />
    <label>Name:</label>
    <br />
    <kendo-textbox [readonly]="false"
                   [value]="selectedGroup.bt_name"></kendo-textbox>
    <br />
    <br />
    <label>Sorting:</label>
    <br />
    <kendo-textbox [readonly]="false"
                   [value]="selectedGroup.bt_sorting"></kendo-textbox>

  </div>

</div>
