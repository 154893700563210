/* tslint:disable */
/* eslint-disable */
import { ProfileOptions } from './profile-options';
export interface ProfileProps {
  disabled?: null | boolean;
  label?: null | string;
  max?: null | number;
  maxLength?: null | number;
  min?: null | number;
  minLength?: null | number;
  options?: null | Array<ProfileOptions>;
  pattern?: null | string;
  readonly?: null | boolean;
  required?: boolean;
  rows?: null | number;
  type?: null | string;
  valid?: null | boolean;
}
