<div class="emp-exit-form">
  <form class="form-container k-form"
        [formGroup]="registerForm">


    <div class="grid-layout-container">
      <kendo-gridlayout gap="1% 10%"
                        [rows]="[
          { height: 50 },
          { height: 80 },
          { height: 80 },
          { height: 80 },
          { height: 120 } ]"
                        [cols]="[{ width: gridColWidth }, { width: gridColWidth }]">

        <!--EMP NOTICE-->
        <kendo-gridlayout-item [row]="1"
                               [col]="1">
          <kendo-formfield orientation="horizontal">
            <label class="exit-form-label"
                   text="Employee Notice">Employee Notice:</label>
            <ul class="k-radio-list k-list-horizontal">
              <li class="k-radio-item">
                <input type="radio"
                       #yes
                       value="yes"
                       kendoRadioButton
                       formControlName="empNotice"
                       (change)="changeYes()" />
                <kendo-label class="k-radio-label"
                             [for]="yes"
                             text="Yes"></kendo-label>
              </li>
              <li class="k-radio-item">
                <input type="radio"
                       #no
                       value="no"
                       kendoRadioButton
                       formControlName="empNotice"
                       (change)="changeNo()" />
                <kendo-label class="k-radio-label"
                             [for]="no"
                             text="No"></kendo-label>
              </li>
            </ul>
            <!--<kendo-formerror>This field is required</kendo-formerror>-->
          </kendo-formfield>
        </kendo-gridlayout-item>
        <!--EMP NOTICE-->
        <!--Termination Reason-->
        <kendo-gridlayout-item [row]="1"
                               [col]="2"
                               *ngIf="registerForm.get('empNotice').value === 'yes'">
          <kendo-formfield class="">
            <label class="exit-form-label"
                   text="Termination Reason">Termination Reason:</label>
            <kendo-dropdownlist class="dropdowns"
                                #legalEntity
                                [data]="terminationOptionsList"
                                textField="value"
                                valueField="value"
                                formControlName="terminationReason"
                                (valueChange)="setTerminationReasonValue($event)"
                                id="legalEntityDropdown"
                                name="legalEntityDropdown"
                                required
                                [ngModel]
                                [disabled]="">
              <ng-template kendoDropDownListNoDataTemplate>
                <p></p>
              </ng-template>
            </kendo-dropdownlist>
            <kendo-formerror>Error: Termination Reason is required</kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <!--Termination Reason-->

        <!--EXIT REASON-->
        <kendo-gridlayout-item [row]="2"
                               [col]="1"
                               [colSpan]="2">
          <kendo-formfield class="">
            <label class="exit-form-label"
                   [for]="exitReason"
                   text="">Exit Reason:</label>
            <kendo-dropdownlist class="dropdowns"
                                [data]="exitReasons"
                                textField="bt_verdeemployeeexitreasons"
                                valueField="bt_verdeemployeeexitreasonsid"
                                formControlName="exitReason"
                                #exitReason
                                (filterChange)="handleToExitReasonFilter($event)"
                                (valueChange)="setExitReasonValue($event)"
                                id="exitReasonDropdown"
                                name="exitReasonDropdown"
                                required
                                [ngModel]
                                [disabled]="isApiCallInProgress">
              <ng-template kendoDropDownListNoDataTemplate>
                <p></p>
              </ng-template>
            </kendo-dropdownlist>
            <kendo-formerror>Error: Exit reason is required</kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <!--END EXIT REASON-->

        <!--LEGAL ENTITY-->
        <kendo-gridlayout-item [row]="3"
                               [col]="1">
          <kendo-formfield class="">
            <label class="exit-form-label"
                   [for]="legalEntity"
                   text="Legal Entity">Legal Entity:</label>
            <kendo-dropdownlist class="dropdowns"
                                #legalEntity
                                formControlName="legalEntity"
                                [data]="employeeExitAccess"
                                textField="legalEntityName"
                                valueField="legalEntityId"
                                id="legalEntityDropdown"
                                name="legalEntityDropdown"
                                (valueChange)="setLegalEntityDropdownValue($event)"
                                required
                                [ngModel]
                                [disabled]="">
              <ng-template kendoDropDownListNoDataTemplate>
                <p></p>
              </ng-template>
            </kendo-dropdownlist>
            <kendo-formerror>Error: Legal entity is required</kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <!--END LEGAL ENTITY
  EMPLOYEE-->
        <kendo-gridlayout-item [row]="3"
                               [col]="2">
          <kendo-formfield class="formfield">
            <label class="exit-form-label"
                   [for]="employee"
                   text="Last Name">Employee:</label>
            <kendo-dropdownlist class="dropdowns"
                                [data]="allEmployeesFilteredData"
                                textField="fullNameWithEmpId"
                                valueField="employeeId"
                                formControlName="employee"
                                #employee
                                [filterable]="true"
                                (filterChange)="handleToEmployeeFilter($event)"
                                (valueChange)="setEmployeeDropdownValue($event)"
                                id="employeeDropdown"
                                name="employeeDropdown"
                                required
                                [ngModel]
                                [disabled]="!selectedLegalEntity">
              <ng-template kendoDropDownListNoDataTemplate>
                <p></p>
              </ng-template>
            </kendo-dropdownlist>
            <kendo-formerror>Error: Employee is required</kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <!--END EMPLOYEE-->
        <!--EXIT DATE-->
        <kendo-gridlayout-item [row]="4"
                               [col]="2">
          <kendo-formfield class="formfield">
            <label class="exit-form-label"
                   [for]="exitDate"
                   text="Exit Date">Exit Date (Last working day):</label>
            <kendo-datepicker #exitDate
                              required
                              formControlName="exitDate"
                              [format]="'yyyy-MM-dd'">
            </kendo-datepicker>
            <kendo-formerror>Error: Exit date is required</kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <!--END EXIT DATE
  NOTICE DATE-->
        <kendo-gridlayout-item [row]="4"
                               [col]="1">
          <kendo-formfield class="formfield">
            <label class="exit-form-label"
                   [for]="noticeDate"
                   text="Notice Date">Notice Date:</label>
            <kendo-datepicker #noticeDate
                              required
                              formControlName="noticeDate"
                              [format]="'yyyy-MM-dd'">
            </kendo-datepicker>
            <kendo-formerror>Error: Notice date is required</kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <!--NOTICE DATE
  COMMENTS-->
        <kendo-gridlayout-item [row]="5"
                               [col]="1"
                               [colSpan]="2">
          <kendo-formfield>
            <label class="exit-form-label"
                   [for]="comments"
                   text="comments">Comments:</label>
            <kendo-textarea [rows]="8"
                            formControlName="comments"
                            #comments></kendo-textarea>
            <div style="margin-top: auto;">
              <kendo-fileselect (valueChange)="onFileSelect($event)"
                                (remove)="removeFile($event)"> </kendo-fileselect>
            </div>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <!--END COMMENTS
  FILE UPLOAD-->
        <!--<kendo-gridlayout-item [row]="5" [col]="1">
    <kendo-formfield>
      <label class="exit-form-label" for="fileUpload">Upload Files:</label>
      <input type="file" id="fileUpload" name="fileUpload" multiple (change)="onFileChange($event)" formControlName="fileUpload" #fileUpload>
      <kendo-formhint>Upload one or more files</kendo-formhint>
    </kendo-formfield>

    List to display uploaded file names
    <div *ngIf="uploadedFiles.length > 0">
      <p>Uploaded Files:</p>
      <ul>
        <li *ngFor="let file of uploadedFiles">{{ file.name }}</li>
      </ul>
    </div>
  </kendo-gridlayout-item>-->
        <!--END FILE UPLOAD-->
      </kendo-gridlayout>
    </div>
  </form>
</div>
<div class="k-form-buttons, form-buttons">
  <div class="myButtons">
    <button kendoButton
            themeColor="primary"
            (click)="submitForm()">
      Submit
    </button>
    <button kendoButton
            (click)="clearForm()">Clear</button>
  </div>
</div>