import { createEntityAdapter, EntityState, EntityAdapter } from '@ngrx/entity';
import { StructureActions, StructureActionTypes } from '../actions/structure.actions';
import { StructureChangeRequestActions, StructureChangeRequestActionTypes } from '../actions/structure-change-request.actions';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromStructureManagementReducer from './structure-management.reducer';
import * as uuid from 'uuid';

// Models
import { StructureFullExtended } from '../models/structure-full-extended.model';

// Helpers
import { entitiesFromStateHandler, keyHandler } from '@verde/core';

export interface StructureState extends EntityState<StructureFullExtended> {}

export interface StructureRelatedState {
  structureState: StructureState;
  structureSavedState: StructureState;
  selectedIds: string[];
  loading: boolean;
  error: any;
}

export const structureKeys = ['recordID'];
export const structureStateAdapter: EntityAdapter<StructureFullExtended> = createEntityAdapter<StructureFullExtended>({
  selectId: (data) => keyHandler(data, structureKeys),
});

export const initialStructureState: StructureState = structureStateAdapter.getInitialState({});

export const initialStructureRelatedState = {
  structureState: initialStructureState,
  structureSavedState: initialStructureState,
  selectedIds: [],
  loading: false,
  error: {},
};

export function structureRelatedReducer(state = initialStructureRelatedState, action: StructureActions | StructureChangeRequestActions): StructureRelatedState {
  switch (action.type) {
    // Get All Structure
    case StructureActionTypes.STRUCTURE_GET_ALL:
      return { ...state, loading: true };
    case StructureActionTypes.STRUCTURE_GET_ALL_SUCCESS: {
      const payload = action.payload.map((m) => {
        return {
          ...m,
          $new: false,
          $dispose: false,
        };
      });

      return {
        ...state,
        loading: false,
        structureState: structureStateAdapter.setAll(payload, state.structureState),
        structureSavedState: structureStateAdapter.setAll(payload, state.structureSavedState),
      };
    }
    case StructureActionTypes.STRUCTURE_GET_ALL_ERROR:
      return { ...state, loading: false, error: action.payload };
    // Structure Add
    case StructureActionTypes.STRUCTURE_ADD_SUCCESS: {
      const payload: StructureFullExtended[] = action.payload.map((m) => {
        return {

          ...m,
          recordID: uuid.v4(),
          $new: true,
          $timestamp: Date.now(),
        };
      });

      console.log('New structures added:', payload);

      return {
        ...state,
        structureState: structureStateAdapter.addMany(payload, state.structureState),
      };
    }
    // Structure Update
    case StructureActionTypes.STRUCTURE_UPDATE_SUCCESS: {
      const payload: StructureFullExtended[] = action.payload.map((m) => {
        return {
          ...m,
          $new: true,
          $timestamp: Date.now(),
        };
      });

      return {
        ...state,
        structureState: structureStateAdapter.upsertMany(payload, state.structureState),
      };
    }
    // Structure Dispose
    case StructureActionTypes.STRUCTURE_DISPOSE_SUCCESS: {
      const payload: StructureFullExtended[] = action.payload.map((m) => {
        return {
          ...m,
          $dispose: true,
          $timestamp: Date.now(),
        };
      });

      return {
        ...state,
        structureState: structureStateAdapter.upsertMany(payload, state.structureState),
      };
    }
    // Structure Delete
    case StructureActionTypes.STRUCTURE_DELETE_SUCCESS:
      return {
        ...state,
        structureState: structureStateAdapter.removeMany(
          action.payload.map((p) => keyHandler(p, structureKeys)),
          state.structureState,
        ),
      };
    // Structure Undo One
    case StructureActionTypes.STRUCTURE_UNDO_ONE_SUCCESS: {
      const savedEntities: StructureFullExtended[] = entitiesFromStateHandler(state.structureSavedState.entities);
      const undoEntity = savedEntities.find((entity) => entity.recordID === action.payload.recordID);
      if (undoEntity) {
        return {
          ...state,
          structureState: structureStateAdapter.upsertOne(undoEntity, state.structureState),
        };
      }
      return state;
    }
    // Structure Undo
    case StructureActionTypes.STRUCTURE_UNDO_SUCCESS: {
      return {
        ...state,
        structureState: state.structureSavedState,
      };
    }
    // Structure Selected
    case StructureActionTypes.STRUCTURE_PARENT_SELECT:
      return { ...state, selectedIds: [...state.selectedIds, action.payload] };
    case StructureActionTypes.STRUCTURE_PARENT_DESELECT:
      return { ...state, selectedIds: [] };
    case StructureChangeRequestActionTypes.STRUCTURE_CHANGE_REQUEST_UPLOAD_SUCCESS: {
      const entities: StructureFullExtended[] = entitiesFromStateHandler(state.structureState.entities);

      let updateEntity = entities.find((entity) => entity.recordID === action.payload.$linkedStructureId);
      if (updateEntity) {
        updateEntity = {
          ...updateEntity,
          $new: false,
          $dispose: false,
          $submitted: true,
        };

        return {
          ...state,
          structureState: structureStateAdapter.upsertOne(updateEntity, state.structureState),
        };
      }
      return state;
    }
    // Clear
    case StructureActionTypes.STRUCTURE_CLEAR:
      return { ...initialStructureRelatedState };
    default:
      return state;
  }
}

export const getStructureManagementState = createFeatureSelector<fromStructureManagementReducer.StructureManagementState>('structureManagementReducer');

export const getStructureRelatedState = createSelector(
  getStructureManagementState,
  (state: fromStructureManagementReducer.StructureManagementState) => state.structureState,
);

// Current Structure

export const getStructureState = createSelector(getStructureRelatedState, (state) => state.structureState);
export const { selectAll: getFullStructure } = structureStateAdapter.getSelectors(getStructureState);

// Filtered Structure

export const getFullStructureNew = createSelector(getFullStructure, (structures) => structures.filter((structure) => structure.$new));
export const getFullStructureDispose = createSelector(getFullStructure, (structures) => structures.filter((structure) => structure.$dispose));

// Selected

export const getSelectedStructureIds = createSelector(getStructureRelatedState, (state) => state.selectedIds);

export const getSelectedStructures = createSelector(getFullStructure, getSelectedStructureIds, (structures, selectedIds: string[]) =>
  structures.filter((m) => selectedIds.indexOf(keyHandler(m, structureKeys)) !== -1),
);

// Loading

export const isStructureLoading = createSelector(getStructureRelatedState, (state) => state.loading);

// Error

export const getStructureError = createSelector(getStructureRelatedState, (state) => state.error);
