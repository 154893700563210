/* tslint:disable */
/* eslint-disable */
export interface DvAllEducationalInstitutionsPost {
  accreditationAuthorityID?: null | string;
  bthr_institutiontype?: null | number;
  bthr_name?: null | string;
  bthr_recordstatus?: number;
  countryID?: null | string;
  legalEntityID?: null | string;
  odataAccreditationAuthority?: null | string;
  odataCountry?: null | string;
  odataLegalEntity?: null | string;
}
