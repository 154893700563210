/* eslint-disable @typescript-eslint/no-empty-interface */
import { createEntityAdapter, EntityState, EntityAdapter } from '@ngrx/entity';
import { PerformanceReviewActions, PerformanceReviewActionTypes } from '../actions/performance-review.actions';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromPerformanceReviewRelatedReducer from './performance-review-related.reducer';

// Models
import { PerformanceReviewDvPerformanceReviewPatch } from '@verde/shared';

// Helpers
import { keyHandler } from '@verde/core';

export interface PerformanceReviewState extends EntityState<PerformanceReviewDvPerformanceReviewPatch> {}

export interface PerformanceReviewRelatedState {
  performanceReviewState: PerformanceReviewState;
  loading: boolean;
  error: any;
}

export const performanceReviewKeys = ['bt_anceReviewid'];
export const performanceReviewStateAdapter: EntityAdapter<PerformanceReviewDvPerformanceReviewPatch> =
  createEntityAdapter<PerformanceReviewDvPerformanceReviewPatch>({
    selectId: (data) => keyHandler(data, performanceReviewKeys),
  });

export const initialPerformanceReviewState: PerformanceReviewState = performanceReviewStateAdapter.getInitialState({});

export const initialPerformanceReviewRelatedState = {
  performanceReviewState: initialPerformanceReviewState,
  performanceReviewSavedState: initialPerformanceReviewState,
  loading: false,
  error: {},
};

export function performanceReviewRelatedReducer(state = initialPerformanceReviewRelatedState, action: PerformanceReviewActions): PerformanceReviewRelatedState {
  switch (action.type) {
    // Get PerformanceReview
    //case PerformanceReviewActionTypes.PERFORMANCEREVIEW_GET_ALL:
    //  return { ...state, loading: true };
    //case PerformanceReviewActionTypes.PERFORMANCEREVIEW_GET_ALL_SUCCESS:
    //  return {
    //    ...state,
    //    loading: false,
    //    performanceReviewState: performanceReviewStateAdapter.upsertMany(action.payload, state.performanceReviewState),
    //  };
    //case PerformanceReviewActionTypes.PERFORMANCEREVIEW_GET_ALL_ERROR:
    //  return { ...state, loading: false, error: action.payload };

    //// PerformanceReview Upload
    //case PerformanceReviewActionTypes.PERFORMANCEREVIEW_UPLOAD_SUCCESS: {
    //  return {
    //    ...state,
    //  };
    //}

    // PerformanceReview Update
    case PerformanceReviewActionTypes.PERFORMANCEREVIEW_UPDATE_SUCCESS: {
      return {
        ...state,
      };
    }

    // Clear
    case PerformanceReviewActionTypes.PERFORMANCEREVIEW_ITEM_CLEAR:
      return { ...initialPerformanceReviewRelatedState };

    default:
      return state;
  }
}

export const getPerformanceReviewsRelatedState =
  createFeatureSelector<fromPerformanceReviewRelatedReducer.PerformanceReviewRelatedState>('performanceReviewRelatedReducer');

export const getPerformanceReviewRelatedState = createSelector(
  getPerformanceReviewsRelatedState,
  (state: fromPerformanceReviewRelatedReducer.PerformanceReviewRelatedState) => state.performanceReviewState,
);

// Current PerformanceReview

export const getPerformanceReviewState = createSelector(getPerformanceReviewRelatedState, (state) => state.performanceReviewState);
export const { selectAll: getAllPerformanceReview } = performanceReviewStateAdapter.getSelectors(getPerformanceReviewState);

// Loading

export const isPerformanceReviewLoading = createSelector(getPerformanceReviewRelatedState, (state) => state.loading);

// Error

export const getPerformanceReviewError = createSelector(getPerformanceReviewRelatedState, (state) => state.error);
