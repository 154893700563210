/* tslint:disable */
/* eslint-disable */
export interface ProfileAllVaccines {
  bt_approvalrequired?: null | boolean;
  bt_attachmentrequirment?: null | boolean;
  bt_booster?: null | boolean;
  bt_dayshistorycapture?: null | number;
  bt_isbooster?: null | boolean;
  bt_noofdoses?: null | number;
  bt_vaccinationno?: null | boolean;
  bt_vaccinationno2?: null | boolean;
  bt_vaccineid?: null | string;
  bt_vaccinename?: null | string;
}
