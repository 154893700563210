import { Injectable, OnDestroy } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';

// Tablet
const TABLET_SCREEN = 868;
const TABLET_SCREEN_BREAKPOINT = `(max-width: ${TABLET_SCREEN}px)`;

// Mobile
const MOBILE_SCREEN = 586;
const MOBILE_SCREEN_BREAKPOINT = `(max-width: ${MOBILE_SCREEN}px)`;

@Injectable({
  providedIn: 'root',
})
export class DeviceTypeService implements OnDestroy {
  onDestroy$ = new Subject<boolean>();
  isTablet$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isMobile$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private readonly breakpointObserver: BreakpointObserver) {
    this.breakpointObserver
      .observe([MOBILE_SCREEN_BREAKPOINT])
      .pipe(
        map((m) => m.matches),
        startWith(false),
        takeUntil(this.onDestroy$),
      )
      .subscribe((isMobile) => {
        this.isMobile$.next(isMobile);
        this.isTablet$.next(!isMobile);
      });

    this.breakpointObserver
      .observe([TABLET_SCREEN_BREAKPOINT])
      .pipe(
        map((m) => m.matches),
        startWith(false),
        takeUntil(this.onDestroy$),
      )
      .subscribe((isTablet) => {
        if (isTablet) {
          this.isTablet$.next(isTablet);
          this.isMobile$.next(false);

          //test
          this.breakpointObserver
            .observe([MOBILE_SCREEN_BREAKPOINT])
            .pipe(
              map((m) => m.matches),
              startWith(false),
              takeUntil(this.onDestroy$),
            )
            .subscribe((isMobile) => {
              if (isMobile) {
                this.isMobile$.next(isMobile);
                this.isTablet$.next(false);
              }
            });
        } else {
          this.isTablet$.next(false);
          this.isMobile$.next(false);
        }
      });
  }

  isTablet() {
    return this.isTablet$.getValue();
  }

  isMobile() {
    return this.isMobile$.getValue();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
