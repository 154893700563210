import { createEntityAdapter, EntityState, EntityAdapter } from '@ngrx/entity';
import { TaskFollowUpActions, TaskFollowUpActionTypes } from '../actions/task-follow-up.actions';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromTaskRelatedReducer from './task-related.reducer';

// Models
import { VerdeTaskCustom } from '@verde/shared';

// Helpers
import { keyHandler } from '@verde/core';

export interface TaskFollowUpState extends EntityState<VerdeTaskCustom> {}

export interface TaskFollowUpRelatedState {
  taskFollowUpState: TaskFollowUpState;
  loading: boolean;
  error: any;
}

export const taskFollowUpKeys = ['bt_verdetasklistid'];
export const taskFollowUpStateAdapter: EntityAdapter<VerdeTaskCustom> = createEntityAdapter<VerdeTaskCustom>({
  selectId: (data) => keyHandler(data, taskFollowUpKeys),
});

export const initialTaskFollowUpState: TaskFollowUpState = taskFollowUpStateAdapter.getInitialState({});

export const initialTaskFollowUpRelatedState = {
  taskFollowUpState: initialTaskFollowUpState,
  loading: false,
  error: {},
};

export function taskFollowUpRelatedReducer(state = initialTaskFollowUpRelatedState, action: TaskFollowUpActions): TaskFollowUpRelatedState {
  switch (action.type) {
    // Get All Task
    case TaskFollowUpActionTypes.TASK_FOLLOWUP_GET_ALL:
      return { ...state, loading: true };
    case TaskFollowUpActionTypes.TASK_FOLLOWUP_GET_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        taskFollowUpState: taskFollowUpStateAdapter.setAll(action.payload, state.taskFollowUpState),
      };
    case TaskFollowUpActionTypes.TASK_FOLLOWUP_GET_ALL_ERROR:
      return { ...state, loading: false, error: action.payload };
    case TaskFollowUpActionTypes.TASK_FOLLOWUP_CLEAR:
      return { ...initialTaskFollowUpRelatedState };
    default:
      return state;
  }
}

export const getTaskRelatedState = createFeatureSelector<fromTaskRelatedReducer.TaskRelatedState>('taskRelatedReducer');

export const getTaskFollowUpRelatedState = createSelector(
  getTaskRelatedState,
  (state: fromTaskRelatedReducer.TaskRelatedState) => state.taskFollowUpRelatedState,
);

// Current Task

export const getTaskFollowUpState = createSelector(getTaskFollowUpRelatedState, (state) => state.taskFollowUpState);
export const { selectAll: getAllFollowUpTasks } = taskFollowUpStateAdapter.getSelectors(getTaskFollowUpState);

// Loading

export const isTaskFollowUpLoading = createSelector(getTaskFollowUpRelatedState, (state) => state.loading);

// Error

export const getTaskFollowUpError = createSelector(getTaskFollowUpRelatedState, (state) => state.error);
