import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

// Store
import * as PositionActions from '../store/actions/position.actions';
import * as fromPositionReducer from '../store/reducers/position.reducer';

// Models
import { StructurePositionExtended } from '../store/models/structure-position-extended.model';

@Injectable({
  providedIn: 'root',
})
export class PositionService {
  loading$: Observable<boolean>;
  allPositions$: Observable<StructurePositionExtended[]>;
  allEntityPositions$: Observable<StructurePositionExtended[]>;
  allPositionsNew$: Observable<StructurePositionExtended[]>;
  allPositionsDispose$: Observable<StructurePositionExtended[]>;

  constructor(private store: Store<fromPositionReducer.PositionState>) {
    this.loading$ = this.store.pipe(select(fromPositionReducer.isPositionLoading));
    this.allPositions$ = this.store.pipe(select(fromPositionReducer.getAllPositions));
    this.allEntityPositions$ = this.store.pipe(select(fromPositionReducer.getAllEntityPositions));
    this.allPositionsNew$ = this.store.pipe(select(fromPositionReducer.getAllPositionsNew));
    this.allPositionsDispose$ = this.store.pipe(select(fromPositionReducer.getAllPositionsDispose));
  }

  // Get

  getAllPositions(entityId: string) {
    this.deselectEntity();
    this.store.dispatch(new PositionActions.PositionGetAll(entityId));
    this.selectEntity(entityId);
  }

  // Add

  addPositions(positions: StructurePositionExtended[]) {
    this.store.dispatch(new PositionActions.PositionAdd(positions));
  }

  // Dispose

  disposePositions(positions: StructurePositionExtended[]) {
    this.store.dispatch(new PositionActions.PositionDispose(positions.filter((p) => !p.$new)));
    this.store.dispatch(new PositionActions.PositionDelete(positions.filter((p) => p.$new)));
  }

  // Selectors

  selectEntity(entityId: string) {
    this.store.dispatch(new PositionActions.PositionEntitySelect(entityId));
  }

  deselectEntity() {
    this.store.dispatch(new PositionActions.PositionEntityDeselect(null));
  }

  // Undo
  positionsUndo() {
    this.store.dispatch(new PositionActions.PositionUndo(null));
  }

  // Clear

  positionsClear() {
    this.store.dispatch(new PositionActions.PositionClear(null));
  }
}
