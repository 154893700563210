/* tslint:disable */
/* eslint-disable */
import { FleetSharedLookupExtrapolate } from './fleet-shared-lookup-extrapolate';
import { FleetBtProcessStatus } from './fleet-bt-process-status';
export interface FleetFleetEntityTransfer {
  bt_fleetentitytransferid?: null | string;
  bt_fleetentitytransferno?: null | string;
  bt_fleetitem?: FleetSharedLookupExtrapolate;
  bt_fromentity?: FleetSharedLookupExtrapolate;
  bt_fromfleetlocation?: FleetSharedLookupExtrapolate;
  bt_processcomment?: null | string;
  bt_processed?: null | boolean;
  bt_processedby?: FleetSharedLookupExtrapolate;
  bt_processedbyentity?: FleetSharedLookupExtrapolate;
  bt_processstatus?: FleetBtProcessStatus;
  bt_toentity?: FleetSharedLookupExtrapolate;
  bt_tofleetlocation?: FleetSharedLookupExtrapolate;
  bt_transferreason?: null | string;
}
