/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { EmployeeObjections } from '../models/employee-objections';
import { ManagerVaccinationInfo } from '../models/manager-vaccination-info';
import { ObjectionReason } from '../models/objection-reason';
import { DvRiskManagement } from '../models/dv-risk-management';
import { DvRiskManagementPost } from '../models/dv-risk-management-post';

@Injectable({
  providedIn: 'root',
})
export class SharedApiRiskManagementService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getRiskManagement
   */
  static readonly GetRiskManagementPath = '/api/RiskManagement/{employeeGUID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRiskManagement()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRiskManagement$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    employeeGUID: string;
  }): Observable<StrictHttpResponse<Array<DvRiskManagement>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiRiskManagementService.GetRiskManagementPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('employeeGUID', params.employeeGUID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DvRiskManagement>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRiskManagement$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRiskManagement(params: {
    subscriptionKey?: string;
    APIURL?: string;
    employeeGUID: string;
  }): Observable<Array<DvRiskManagement>> {

    return this.getRiskManagement$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DvRiskManagement>>) => r.body as Array<DvRiskManagement>)
    );
  }

  /**
   * Path part for operation patchRiskManagement
   */
  static readonly PatchRiskManagementPath = '/api/RiskManagement/patch/{userguid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchRiskManagement()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchRiskManagement$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userguid: string;
    body?: DvRiskManagementPost
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiRiskManagementService.PatchRiskManagementPath, 'patch');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('userguid', params.userguid, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchRiskManagement$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchRiskManagement(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userguid: string;
    body?: DvRiskManagementPost
  }): Observable<boolean> {

    return this.patchRiskManagement$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation getObjectionReasons
   */
  static readonly GetObjectionReasonsPath = '/api/RiskManagement/reasons/{legalEntityGuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getObjectionReasons()` instead.
   *
   * This method doesn't expect any request body.
   */
  getObjectionReasons$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityGuid: string;
  }): Observable<StrictHttpResponse<Array<ObjectionReason>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiRiskManagementService.GetObjectionReasonsPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityGuid', params.legalEntityGuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ObjectionReason>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getObjectionReasons$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getObjectionReasons(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityGuid: string;
  }): Observable<Array<ObjectionReason>> {

    return this.getObjectionReasons$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ObjectionReason>>) => r.body as Array<ObjectionReason>)
    );
  }

  /**
   * Path part for operation getEmployeeObjections
   */
  static readonly GetEmployeeObjectionsPath = '/api/RiskManagement/objections/{employeeGUID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEmployeeObjections()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployeeObjections$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    employeeGUID: string;
  }): Observable<StrictHttpResponse<Array<EmployeeObjections>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiRiskManagementService.GetEmployeeObjectionsPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('employeeGUID', params.employeeGUID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EmployeeObjections>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEmployeeObjections$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployeeObjections(params: {
    subscriptionKey?: string;
    APIURL?: string;
    employeeGUID: string;
  }): Observable<Array<EmployeeObjections>> {

    return this.getEmployeeObjections$Response(params).pipe(
      map((r: StrictHttpResponse<Array<EmployeeObjections>>) => r.body as Array<EmployeeObjections>)
    );
  }

  /**
   * Path part for operation patchEmployeeObjections
   */
  static readonly PatchEmployeeObjectionsPath = '/api/RiskManagement/patch/objections';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchEmployeeObjections()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchEmployeeObjections$Response(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: EmployeeObjections
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiRiskManagementService.PatchEmployeeObjectionsPath, 'patch');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchEmployeeObjections$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchEmployeeObjections(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: EmployeeObjections
  }): Observable<boolean> {

    return this.patchEmployeeObjections$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation getManagerVaccinationInfo
   */
  static readonly GetManagerVaccinationInfoPath = '/api/RiskManagement/managerinfo/{managerID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getManagerVaccinationInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  getManagerVaccinationInfo$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    managerID: string;
  }): Observable<StrictHttpResponse<Array<ManagerVaccinationInfo>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiRiskManagementService.GetManagerVaccinationInfoPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('managerID', params.managerID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ManagerVaccinationInfo>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getManagerVaccinationInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getManagerVaccinationInfo(params: {
    subscriptionKey?: string;
    APIURL?: string;
    managerID: string;
  }): Observable<Array<ManagerVaccinationInfo>> {

    return this.getManagerVaccinationInfo$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ManagerVaccinationInfo>>) => r.body as Array<ManagerVaccinationInfo>)
    );
  }

  /**
   * Path part for operation getVaccinationSummary
   */
  static readonly GetVaccinationSummaryPath = '/api/RiskManagement/vaccinationacceptancesummary/{userID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVaccinationSummary()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVaccinationSummary$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userID: string;
  }): Observable<StrictHttpResponse<Array<DvRiskManagement>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiRiskManagementService.GetVaccinationSummaryPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('userID', params.userID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DvRiskManagement>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVaccinationSummary$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVaccinationSummary(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userID: string;
  }): Observable<Array<DvRiskManagement>> {

    return this.getVaccinationSummary$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DvRiskManagement>>) => r.body as Array<DvRiskManagement>)
    );
  }

}
