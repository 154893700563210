import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[fileDragAndDrop]',
})
export class ImageUploadDirective {
  @Output() fileDropped: EventEmitter<File> = new EventEmitter<File>();
  @Output() fileOnHover: EventEmitter<boolean> = new EventEmitter<boolean>();

  @HostListener('dragover', ['$event']) onDragOver(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();
  }

  @HostListener('dragenter', ['$event']) onDragEnter(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();

    this.fileOnHover.emit(true);
  }

  @HostListener('dragleave', ['$event']) onDragLeave(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();

    this.fileOnHover.emit(false);
  }

  @HostListener('drop', ['$event']) onDrop(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOnHover.emit(false);

    const files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.fileDropped.emit(files[0]);
    }
  }

  constructor() {}
}
