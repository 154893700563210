/* tslint:disable */
/* eslint-disable */
import { EquipmentEquipmentLegalEntity } from './equipment-equipment-legal-entity';
import { EquipmentEquipmentTakeOnGroup } from './equipment-equipment-take-on-group';
export interface EquipmentEquipmentTakeOnType {
  bt_LegalEntity?: EquipmentEquipmentLegalEntity;
  bt_TakeOnGroup?: EquipmentEquipmentTakeOnGroup;
  bt_allowqty?: null | boolean;
  bt_equipmenttakeontype?: null | string;
  bt_equipmenttakeontypeid?: string;
}
