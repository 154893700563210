import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { EditorResizableOptions } from '@progress/kendo-angular-editor';
import { UserService } from '@verde/core';
import { ModalService, ProcessesApiProcessesService, ProcessesBtProcessStatus, ProcessesEmployeeResignation, VerdeApprovalService } from '@verde/shared';
import { Subject, take, takeUntil } from 'rxjs';
import { HrPortalService } from '../../../../../../../../../../apps/verde/src/app/features/hr-portal/services/hr-portal.service';

@Component({
  selector: 'verde-employee-resignation-edit',
  templateUrl: './employee-resignation-edit.component.html',
  styleUrls: ['./employee-resignation-edit.component.scss'],
})
export class EmployeeResignationEditComponent implements OnInit {
  private onDestroy$ = new Subject<boolean>();

  public registerForm: FormGroup = new FormGroup({
    noticeDate: new FormControl(),
    exitReason: new FormControl(),
    exitDate: new FormControl(),
    comments: new FormControl(),
    fileUpload: new FormControl(),
  });

  editorId: string = 'commentEditor';
  /*terminationReasons = ProcessesBthrTerminationReason;*/
  public resignationTypes: any[] = [];
  uploadedFiles: File[] = [];
  joinDate: Date;
  gridColWidth = '45%';
  editorValue: string;
  todayDate: Date = new Date();

  exitDate: Date = new Date();
  noticeDate: Date = new Date();
  confirmMessage: string;
  resignationEdit: any;

  constructor(
    private userService: UserService,
    private processesApiProcessesService: ProcessesApiProcessesService,
    private sidebarService: VerdeApprovalService,
    private modalService: ModalService,
    private refreshService: HrPortalService,
  ) {}

  ngOnInit(): void {
    this.sidebarService
      .getEmployeeResignationData()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((data) => {
        this.resignationEdit = data;
        console.log('RESIG', this.resignationEdit);
      });

    this.exitDate = new Date(this.resignationEdit.bthr_resignationdate);
    this.noticeDate = new Date(this.resignationEdit.bthr_terminationnoticedate);

    this.registerForm = new FormGroup({
      exitDate: new FormControl(this.exitDate),
      noticeDate: new FormControl(this.noticeDate),
      editor: new FormControl(this.resignationEdit.bthr_terminationcomment),
      fileUpload: new FormControl(),
      resignationType: new FormControl(this.resignationEdit.bthr_terminationreason),
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  public resizable: boolean | EditorResizableOptions = {
    minHeight: 150,
    maxHeight: 450,
  };

  public onChange(event: Event): void {
    const isChecked = (event.target as HTMLInputElement).checked;
    this.resizable = isChecked
      ? {
          minHeight: 150,
          maxHeight: 450,
        }
      : false;
  }

  postResignation() {
    console.log(this.registerForm);

    let body = {
      EmployeeID: this.userService.user.employeeId,
      LegalEntityID: this.userService.user.legalEntityId,
      bthr_terminationcomment: this.registerForm.controls.editor.value,
      filesToUpload: this.uploadedFiles,
      bthr_resignationdate: this.registerForm.value.exitDate,
      bthr_terminationnoticedate: this.registerForm.value.noticeDate,
    };
  }

  editorValueChange(event: any) {
    this.editorValue = event;
  }

  onFileSelect(event: any) {
    console.log(event);
    const selectedFiles: FileList = event;
    for (let i = 0; i < selectedFiles.length; i++) {
      this.uploadedFiles.push(selectedFiles[i]);
    }
    console.log('Selected files:', this.uploadedFiles);
  }

  submit() {
    this.postResignation();
  }

  process() {
    this.modalService.open('processModal');
    this.confirmMessage = 'Please confirm that you would like to process employee termination';
  }

  reversal() {
    this.modalService.open('reversalModal');
    this.confirmMessage = 'Please confirm that you would like to reverse employee termination';
  }

  confirmProcess() {
    let body: ProcessesEmployeeResignation = {
      bt_ProcessStatus: ProcessesBtProcessStatus.Processing,
      bt_employeeresignationsid: this.resignationEdit.bt_employeeresignationsid,
      bt_processedBy: this.userService.user.employeeId,
      bt_processLegalEntity: this.userService.user.legalEntityId,
    };
    this.processesApiProcessesService
      .patchEmployeeResignation({ body: { body: body } })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          console.log(ret);
        },
        (error) => {
          console.error(error);
        },
        () => {
          this.sidebarService.setShowSidebar(false);
          this.refreshService.refreshView();
        },
      );
  }

  confirmReversal() {
    let body: ProcessesEmployeeResignation = {
      bt_ProcessStatus: ProcessesBtProcessStatus.Cancelled,
      bt_employeeresignationsid: this.resignationEdit.bt_employeeresignationsid,
      bt_processedBy: this.userService.user.employeeId,
      bt_processLegalEntity: this.userService.user.legalEntityId,
    };
    this.processesApiProcessesService
      .patchEmployeeResignation({ body: { body: body } })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          console.log(ret);
        },
        (error) => {
          console.error(error);
        },
        () => {
          this.sidebarService.setShowSidebar(false);
          this.refreshService.refreshView();
        },
      );
  }
}
