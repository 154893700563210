import { createEntityAdapter, EntityState, EntityAdapter } from '@ngrx/entity';
import { EmployeeEducationalQualificationActions, EmployeeEducationalQualificationActionTypes } from '../actions/employee-educational-qualification.actions';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromProfileRelatedReducer from './profile-related.reducer';

// Models
import { QualificationsBthrEmployeeEducationalQualification } from '@verde/shared';

// Helpers
import { keyHandler } from '@verde/core';

export type EmployeeEducationalQualificationState = EntityState<QualificationsBthrEmployeeEducationalQualification>;

export interface EmployeeEducationalQualificationRelatedState {
  employeeEducationalQualificationState: EmployeeEducationalQualificationState;
  selectedIds: string[];
  loading: boolean;
  error: any;
}

export const employeeEducationalQualificationKeys = ['bthr_employeeeducationalqualificationid'];
export const employeeEducationalQualificationStateAdapter: EntityAdapter<QualificationsBthrEmployeeEducationalQualification> =
  createEntityAdapter<QualificationsBthrEmployeeEducationalQualification>({
    selectId: (data) => keyHandler(data, employeeEducationalQualificationKeys),
  });

export const initialEmployeeEducationalQualificationState: EmployeeEducationalQualificationState = employeeEducationalQualificationStateAdapter.getInitialState(
  {},
);

export const initialEmployeeEducationalQualificationRelatedState = {
  employeeEducationalQualificationState: initialEmployeeEducationalQualificationState,
  selectedIds: [],
  loading: false,
  error: {},
};

export function EmployeeEducationalQualificationRelatedReducer(
  state = initialEmployeeEducationalQualificationRelatedState,
  action: EmployeeEducationalQualificationActions,
): EmployeeEducationalQualificationRelatedState {
  switch (action.type) {
    // Get EmployeeEducationalQualification
    case EmployeeEducationalQualificationActionTypes.EMPLOYEEEDUCATIONALQUALIFICATION_GET_ALL:
      return { ...state, loading: true };
    case EmployeeEducationalQualificationActionTypes.EMPLOYEEEDUCATIONALQUALIFICATION_GET_ALL_SUCCESS: {
      return {
        ...state,
        loading: false,
        employeeEducationalQualificationState: employeeEducationalQualificationStateAdapter.setAll(action.payload, state.employeeEducationalQualificationState),
      };
    }
    case EmployeeEducationalQualificationActionTypes.EMPLOYEEEDUCATIONALQUALIFICATION_GET_ALL_ERROR:
      return { ...state, loading: false, error: action.payload };

    // Upload
    case EmployeeEducationalQualificationActionTypes.EMPLOYEEEDUCATIONALQUALIFICATION_UPLOAD_SUCCESS: {
      return {
        ...state,
      };
    }
    case EmployeeEducationalQualificationActionTypes.EMPLOYEEEDUCATIONALQUALIFICATION_UPLOAD_ERROR:
      return { ...state, loading: false, error: action.payload };

    // Update
    case EmployeeEducationalQualificationActionTypes.EMPLOYEEEDUCATIONALQUALIFICATION_UPDATE_SUCCESS: {
      return {
        ...state,
      };
    }
    case EmployeeEducationalQualificationActionTypes.EMPLOYEEEDUCATIONALQUALIFICATION_UPDATE_ERROR:
      return { ...state, loading: false, error: action.payload };

    // Clear
    case EmployeeEducationalQualificationActionTypes.EMPLOYEEEDUCATIONALQUALIFICATION_CLEAR:
      return { ...initialEmployeeEducationalQualificationRelatedState };
    default:
      return state;
  }
}

export const getProfileRelatedState = createFeatureSelector<fromProfileRelatedReducer.ProfileRelatedState>('profileRelatedReducer');

export const getEmployeeEducationalQualificationRelatedState = createSelector(
  getProfileRelatedState,
  (state: fromProfileRelatedReducer.ProfileRelatedState) => state.employeeEducationalQualificationState,
);

// Current EmployeeEducationalQualification

export const getEmployeeEducationalQualificationState = createSelector(
  getEmployeeEducationalQualificationRelatedState,
  (state) => state.employeeEducationalQualificationState,
);
export const { selectAll: getEmployeeEducationalQualification } =
  employeeEducationalQualificationStateAdapter.getSelectors(getEmployeeEducationalQualificationState);

// Selected

export const getSelectedEmployeeEducationalQualificationIds = createSelector(getEmployeeEducationalQualificationRelatedState, (state) => state.selectedIds);

export const getSelectedProfiles = createSelector(
  getEmployeeEducationalQualification,
  getSelectedEmployeeEducationalQualificationIds,
  (EmployeeEducationalQualification, selectedIds: string[]) =>
    EmployeeEducationalQualification.filter((m) => selectedIds.indexOf(keyHandler(m, employeeEducationalQualificationKeys)) !== -1),
);

// Loading

export const isEmployeeEducationalQualificationLoading = createSelector(getEmployeeEducationalQualificationRelatedState, (state) => state.loading);

// Error

export const getProfileError = createSelector(getEmployeeEducationalQualificationRelatedState, (state) => state.error);
