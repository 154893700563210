import { Component, Input, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import {
  ModalService,
  SharedApiVerdeDocumentsService,
  VerdeDocumentsAll,
  VerdeDocumentsOne,
  VerdeHeaderTemplate,
  VerdeFooterTemplate,
  FundOptionChanges,
  EmployeeFunds,
  VerdeApprovalService,
  SharedApiFileService,
  SharedApiFundsService,
  RefreshService,
} from '@verde/shared';
import { UserService } from '@verde/core';
import moment from 'moment';
import { DatePipe } from '@angular/common';
import { exportPDF, Group } from '@progress/kendo-drawing';
import { PDFExportComponent } from '@progress/kendo-angular-pdf-export';

enum PropertyType {
  Header = '692360000',
  Footer = '692360001',
}

@Component({
  selector: 'verde-provident-fund',
  templateUrl: './provident-fund.component.html',
  styleUrls: ['./provident-fund.component.scss'],
})
export class ProvidentFundComponent implements OnInit {
  @Input() pdfObject: any;
  @Input() selectedFund: any;
  @Input() newFundOptionID: any;
  @Input() existingOptionInt: any;

  allTemplatesGridData: VerdeDocumentsAll[] = [];
  SingleDocument: VerdeDocumentsOne = {};
  editorContentValue: string | undefined | null;

  HeaderContent: VerdeHeaderTemplate | undefined;
  FooterContent: VerdeFooterTemplate | undefined;
  selectedDocument: VerdeDocumentsOne | undefined;
  type: PropertyType | undefined;
  documentTemplate: any;

  headerText: string | null = null;
  footerText: string | null = null;
  layout: string[] | undefined;
  fundName: string;
  fileName: string;
  myPdfInputs: any;

  fundOptionChangeID: string;

  dropdownCorrected: number;
  datePipe = new DatePipe('en-ZA');

  patchPDFFile: File;

  constructor(
    private sharedapiverdedocumentsservice: SharedApiVerdeDocumentsService,
    private userService: UserService,
    private modalService: ModalService,
    private sidebarService: VerdeApprovalService,
    private sharedApiFileService: SharedApiFileService,
    private sharedApiFundsService: SharedApiFundsService,
    private refreshService: RefreshService,
  ) {}

  ngOnInit(): void {
    this.dropdownCorrected = parseFloat(this.pdfObject.changeOption.options.replace(/[^\d.]/g, ''));
    const myDate = new Date();
    const formattedDate = this.datePipe.transform(myDate, 'MM-dd-yyyy HH:mm');

    this.myPdfInputs = {
      bt_fullname: this.userService.user.fullName,
      bt_fundName: this.pdfObject.FundName,
      bt_oldOption: this.pdfObject.FundOption,
      bt_newOption: this.pdfObject.changeOption.options,
      now: formattedDate,
    };

    this.getAllTemplates();
    /*this.getHeaderData();*/
  }

  //getFooterData() {
  //  this.sharedapiverdedocumentsservice
  //    .verdeFooterTemplate({
  //      LegalEntity: this.userService.proxyUser.legalEntityId,
  //    })
  //    .pipe(take(1))
  //    .subscribe(
  //      (ret) => {
  //        this.FooterContent = ret[0];
  //      },
  //      (error) => {
  //        console.error(error);
  //      },
  //      () => {
  //        if (this.FooterContent?.bt_textorimage) {
  //          //Image
  //          this.footerText = '<img src="' + this.FooterContent?.bt_footerurl + '" width="100%" height = "80%" />';
  //        } else if (!this.FooterContent?.bt_textorimage) {
  //          //Text
  //        }
  //        this.loadPDF();
  //      },
  //    );
  //}

  //getHeaderData() {
  //  this.sharedapiverdedocumentsservice
  //    .verdeHeaderTemplate({
  //      LegalEntity: this.userService.proxyUser.legalEntityId,
  //    })
  //    .pipe(take(1))
  //    .subscribe(
  //      (ret) => {
  //        this.HeaderContent = ret[0];
  //      },
  //      (error) => {
  //        console.error(error);
  //      },
  //      () => {
  //        if (this.HeaderContent?.bt_textorimage) {
  //          //Image
  //          this.headerText = '<img src="' + this.HeaderContent.bt_headerurl + '" width="80%" height = "80%" />';
  //        } else if (!this.HeaderContent?.bt_textorimage) {
  //          //Text
  //        }
  //        this.getFooterData();
  //      },
  //    );
  //}

  getAllTemplates() {
    this.sharedapiverdedocumentsservice
      .getVerdeDocumentsAll({
        legalEntityGuid: this.userService.proxyUser.legalEntityId,
      })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          this.allTemplatesGridData = ret;
          let match = this.allTemplatesGridData.find((obj) => obj.bt_verdedocumentstemplateid === this.selectedFund.bthr_Fund._bt_documenttemplate_value);
          this.fundName = match?.bt_templatename;
          this.documentTemplate = match;
        },
        (error) => {
          console.error(error);
        },
        () => {
          if (this.documentTemplate?.bt_HeaderTemplate?.bt_textorimage) {
            //Image
            this.headerText = '<img src="' + this.documentTemplate?.bt_HeaderTemplate?.bt_headerurl + '" width="80%" height = "80%" />';
          } else if (!this.documentTemplate?.bt_HeaderTemplate?.bt_textorimage) {
            //Text
          }
          if (this.documentTemplate?.bt_TemplateFooter?.bt_textorimage) {
            //Image
            this.footerText = '<img src="' + this.documentTemplate?.bt_TemplateFooter?.bt_footerurl + '" width="100%" height = "80%" />';
          } else if (!this.documentTemplate?.bt_TemplateFooter?.bt_textorimage) {
            //Text
          }

          this.loadPDF();
        },
      );
  }

  loadPDF() {
    this.sharedapiverdedocumentsservice
      .getVerdeDocumentsOne({ legalEntityGuid: this.userService.user.legalEntityId, tempname: this.fundName })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          this.SingleDocument = ret[0];

          this.SingleDocument.allKeys?.forEach((t) => {
            if (this.SingleDocument.allKeys) {
              this.SingleDocument.allKeys.forEach((t, index) => {
                let reWordedT = t.substring(1, t.length - 1);
                //Check user Service
                if (this.myPdfInputs[reWordedT] != null) {
                  let replaceWith = this.myPdfInputs[reWordedT];
                  this.SingleDocument.bt_templatecontent = this.SingleDocument?.bt_templatecontent?.replaceAll(t, replaceWith);
                } else {
                  this.SingleDocument.bt_templatecontent = this.SingleDocument?.bt_templatecontent?.replaceAll(t, '[NO DATA FOUND]');
                }
              });
            }
            this.editorContentValue = this.SingleDocument?.bt_templatecontent;
          });
        },
        (error) => {
          console.error(error);
        },
        () => {
          this.layout = this.SingleDocument?.bt_layout?.split(',');
          if (this.layout) {
            this.layout.forEach((y, index) => {
              if (y == PropertyType.Footer) {
                this.editorContentValue = (this.editorContentValue ?? '') + this.footerText;
              } else if (y == PropertyType.Header) {
                this.editorContentValue = this.headerText + (this.editorContentValue ?? '');
              }
            });
            setTimeout(() => {
              const HtmlTemplateContent = document.getElementById('HtmlTemplateContent');
              if (HtmlTemplateContent) {
                HtmlTemplateContent.innerHTML = this.editorContentValue ?? '';
              }
            }, 20);
          }
        },
      );
  }

  cancel() {
    this.sidebarService.setShowSidebar(false);
  }

  sign() {
    this.modalService.open('confirmFundOptionChange');
  }

  postFundOptionChange(file: any) {
    console.log(file);
    let todayDate = new Date();
    let changes: FundOptionChanges = {
      odataPostLegalEntity: '/bthr_legalentitieses(' + this.userService.user.legalEntityId + ')',
      odataPostEmployee: '/bthr_employees(' + this.userService.user.employeeId + ')',
      odataPostFund: '/bthr_funds(' + this.selectedFund._bthr_fund_value + ')',
      odataPostExistingOption: '/bt_fundoptionses(' + this.selectedFund._bt_fundoption_value + ')',
      odataPostNewOption: '/bt_fundoptionses(' + this.newFundOptionID + ')',
      bt_existing: this.existingOptionInt,
      bt_new: this.dropdownCorrected,
      bt_effectivedate: moment(todayDate).format('YYYY-MM-DDTHH:mm:ss[Z]'),
      odataPostEmployeeFund: '/bthr_employeefunds(' + this.selectedFund.bthr_employeefundid + ')',
    };

    this.sharedApiFundsService
      .postFundOptionChanges({ body: changes })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          this.fundOptionChangeID = ret;
        },
        (error) => {
          console.error(error);
        },
        () => {
          this.patchEmployeeFund();
          if (this.patchPDFFile) {
            this.patchFundsFile(this.patchPDFFile);
          }
        },
      );
  }

  patchEmployeeFund() {
    let patchBody: EmployeeFunds = {};
    let recordID = this.selectedFund.bthr_employeefundid;
    patchBody.bt_contribution = this.dropdownCorrected;
    patchBody.odataPostFundOption = '/bt_fundoptionses(' + this.newFundOptionID + ')';

    this.sharedApiFundsService
      .patchEmployeeFunds({ body: patchBody, recordID: recordID })
      .pipe(take(1))
      .subscribe(
        () => {
          //
        },
        (error) => {
          console.error(error);
        },
        () => {
          this.sidebarService.setShowSidebar(false);
          this.refreshService.notifyApiCallCompleted();
        },
      );
  }

  patchFundsFile(file: Blob) {
    this.sharedApiFileService
      .patchFundsFile({ recordID: this.fundOptionChangeID, body: { file: file } })
      .pipe(take(1))
      .subscribe(
        () => {
          //
        },
        (error) => {
          console.error(error);
        },
        () => {
          if (file !== null) {
            this.patchFundOptionChanges(this.fundOptionChangeID);
          }
        },
      );
  }

  patchFundOptionChanges(id: string) {
    let patchBody = {
      bt_sendnotification: true,
      bt_attachmentname: this.fileName,
      bt_signed: true,
    };

    this.sharedApiFundsService
      .patchEmployeeFundOptionChanges({ recordID: id, body: patchBody })
      .pipe(take(1))
      .subscribe(
        () => {
          //
        },
        (error) => {
          console.error(error);
        },
        () => {},
      );
  }

  SaveThePdf(pdf: any) {
    pdf.saveAs(this.userService.proxyUser.fullNameWithEmpId + ' -- ' + 'Provident Fund' + '.pdf');
  }

  convertToFile(dataURL: string) {
    const parts = dataURL.split(';base64,');
    const mimeType = parts[0].split(':')[1];
    const base64Data = parts[1];
    const binaryData = atob(base64Data);
    const array = new Uint8Array(binaryData.length);
    for (let i = 0; i < binaryData.length; i++) {
      array[i] = binaryData.charCodeAt(i);
    }
    const blob = new Blob([array], { type: mimeType });
    this.patchPDFFile = new File([blob], this.userService.user.fullName + '_' + this.pdfObject.FundName + '.pdf', { type: mimeType });
    this.fileName = this.patchPDFFile.name;
    this.postFundOptionChange(this.patchPDFFile);
  }

  confirm(pdf: PDFExportComponent) {
    pdf.export().then((group: Group) => {
      exportPDF(group).then((dataURI) => {
        this.convertToFile(dataURI);
      });
    });
  }
}
