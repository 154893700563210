/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { DelegationsBaseService } from '../delegations-base-service';
import { DelegationsApiConfiguration } from '../delegations-api-configuration';
import { DelegationsStrictHttpResponse } from '../delegations-strict-http-response';
import { DelegationsRequestBuilder } from '../delegations-request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DelegationsVerdeVersion } from '../models/delegations-verde-version';

@Injectable({
  providedIn: 'root',
})
export class DelegationsApiVersionService extends DelegationsBaseService {
  constructor(config: DelegationsApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getVersionNumber
   */
  static readonly GetVersionNumberPath = '/api/Version/GetVersionNumber';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVersionNumber()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVersionNumber$Response(params?: {}): Observable<DelegationsStrictHttpResponse<DelegationsVerdeVersion>> {
    const rb = new DelegationsRequestBuilder(this.rootUrl, DelegationsApiVersionService.GetVersionNumberPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as DelegationsStrictHttpResponse<DelegationsVerdeVersion>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVersionNumber$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVersionNumber(params?: {}): Observable<DelegationsVerdeVersion> {
    return this.getVersionNumber$Response(params).pipe(map((r: DelegationsStrictHttpResponse<DelegationsVerdeVersion>) => r.body as DelegationsVerdeVersion));
  }
}
