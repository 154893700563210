/* tslint:disable */
/* eslint-disable */
import { QualificationsDataQuery } from './qualifications-data-query';
import { QualificationsBthrQualificationType } from './qualifications-bthr-qualification-type';
export interface QualificationsGetQualificationQuery {
  bthr_Qualificationid?: null | string;
  qualificationType?: QualificationsBthrQualificationType;
  query?: QualificationsDataQuery;
  searchParam?: null | string;
}
