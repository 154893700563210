/* tslint:disable */
/* eslint-disable */
export interface DocumentDataQuery {
  count?: boolean;
  filter?: null | string;
  pageNumber?: null | number;
  search?: null | string;
  searchColumns?: null | Array<string>;
  skip?: null | number;
  sort?: null | string;
  take?: null | number;
}
