/* tslint:disable */
/* eslint-disable */
import { ProfileBtWorkflowStatus } from './profile-bt-workflow-status';
export interface ProfileVaccinations {
  bt_approvalcomment?: null | string;
  bt_approvalname?: null | string;
  bt_date?: null | string;
  bt_doseno?: null | number;
  bt_employeevaccinationid?: string;
  bt_isbooster?: null | boolean;
  bt_proofofvaccination_name?: null | string;
  bt_referencenumber?: null | string;
  bt_referencenumber2?: null | string;
  bt_sequenceno?: null | number;
  bt_vacno?: null | string;
  bt_workflowstatus?: ProfileBtWorkflowStatus;
}
