/* tslint:disable */
/* eslint-disable */
export interface DocumentDocSalaryEmployee {
  bt_department?: null | string;
  bt_division?: null | string;
  bt_employeenumber?: null | string;
  bt_fullname?: null | string;
  bt_manager?: null | string;
  bthr_employeeid?: string;
}
