/* tslint:disable */
/* eslint-disable */
export interface UserPositionSeatNumberingDto {
  isPositionAutoNumber?: null | boolean;
  isSeatAutoNumber?: null | boolean;
  nextSeatNumber?: null | string;
  positionNextNumber?: null | string;
  positionNumberMask?: null | string;
  seatNumberMask?: null | string;
}
