/* tslint:disable */
/* eslint-disable */
export interface DvVulnerabilityAssessments {
  bt_age?: null | number;
  bt_covidvacince?: null | boolean;
  bt_covvacdate?: null | string;
  bt_fluvacdate?: null | string;
  bt_olderthan60?: null | boolean;
  bt_recordstatus?: null | number;
  bt_vulnerable?: null | boolean;
  bt_workflowstatus?: null | number;
  bthr_asthma?: null | number;
  bthr_chronickidneydisease?: null | number;
  bthr_chronicliverdisease?: null | number;
  bthr_chroniclungdisease?: null | number;
  bthr_dateofbirth?: null | string;
  bthr_diabetes?: null | number;
  bthr_hiv?: null | number;
  bthr_hypertension?: null | number;
  bthr_immunocompromised?: null | number;
  bthr_obesity?: null | number;
  bthr_pregnancyequaltogreater28weeks?: null | number;
  bthr_receivedfluvaccine?: null | number;
  bthr_seriousheartconditions?: null | number;
  bthr_smokingcurrent?: null | number;
  bthr_tuberculosiscurrent?: null | number;
  bthr_tuberculosisprevious?: null | number;
  bthr_vulnerabilityassessmentid?: null | string;
  odataAsthma?: null | string;
  odataChronicKidneyDisease?: null | string;
  odataCovidVaccine?: null | string;
  odataDiabetes?: null | string;
  odataEmployee?: null | string;
  odataFluVaccine?: null | string;
  odataHIV?: null | string;
  odataHeartCondition?: null | string;
  odataHypertension?: null | string;
  odataImmunoCompromised?: null | string;
  odataLiverDisease?: null | string;
  odataLungDisease?: null | string;
  odataObesity?: null | string;
  odataOver60?: null | string;
  odataPregnacyPast28?: null | string;
  odataRecordStatus?: null | string;
  odataSmokingCurrent?: null | string;
  odataTBCurrent?: null | string;
  odataTBPrevious?: null | string;
  odataVulnerable?: null | string;
  odataWorkflowStatus?: null | string;
}
