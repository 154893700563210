/* tslint:disable */
/* eslint-disable */
import { PerformanceReviewBtRecordStatus } from './performance-review-bt-record-status';
import { PerformanceReviewBtResponseType } from './performance-review-bt-response-type';
import { PerformanceReviewBtTemplateType } from './performance-review-bt-template-type';
export interface PerformanceReviewEnhancedReviewSummary {
  bt_Cycle?: null | string;
  bt_Employee?: null | string;
  bt_Period?: null | string;
  bt_Responder?: null | string;
  bt_Template?: null | string;
  bt_basepoint?: null | number;
  bt_basepointpercentage?: null | number;
  bt_benchmark?: null | string;
  bt_date?: null | string;
  bt_displaysubgroup?: null | boolean;
  bt_feedbackcompleted?: null | boolean;
  bt_groupsorting?: null | number;
  bt_measuresorting?: null | number;
  bt_performancereviewdetailsummary?: null | string;
  bt_performancereviewdetailsummaryid?: null | string;
  bt_ratingaverage?: null | number;
  bt_ratingfloat?: null | number;
  bt_recordstatus?: PerformanceReviewBtRecordStatus;
  bt_response?: null | string;
  bt_responsetype?: PerformanceReviewBtResponseType;
  bt_subgroupsorting?: null | number;
  bt_templatetype?: PerformanceReviewBtTemplateType;
}
