/* tslint:disable */
/* eslint-disable */
export interface DvPermitRequests {
  bt_approvalcomment?: null | string;
  bt_approvername?: null | string;
  bt_permitattachment_name?: null | string;
  bt_permitend?: null | string;
  bt_permitrequestid?: null | string;
  bt_permitstart?: null | string;
  bt_reason?: null | string;
  bt_requestno?: null | string;
  odataPermitStatus?: null | string;
  odataPermitType?: null | string;
  odataWorkflowStatus?: null | string;
}
