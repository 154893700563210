import { Action } from '@ngrx/store';
import { PerformanceReviewPatchPerformanceReviewsCommand } from '@verde/shared';

export enum PerformanceReviewActionTypes {
  // Get PERFORMANCEREVIEW
  PERFORMANCEREVIEW_GET_ALL = '[PERFORMANCEREVIEW] Get All Start',
  PERFORMANCEREVIEW_GET_ALL_SUCCESS = '[PERFORMANCEREVIEW] Get All Start Success',
  PERFORMANCEREVIEW_GET_ALL_ERROR = '[PERFORMANCEREVIEW] Get All Start Error',

  // UPDATE PERFORMANCEREVIEW
  PERFORMANCEREVIEW_UPDATE = '[PERFORMANCEREVIEW] UPDATE',
  PERFORMANCEREVIEW_UPDATE_SUCCESS = '[PERFORMANCEREVIEW] UPDATE Success',
  PERFORMANCEREVIEW_UPDATE_ERROR = '[PERFORMANCEREVIEW] UPDATE Error',

  // PERFORMANCEREVIEW Add
  PERFORMANCEREVIEW_UPLOAD = '[PERFORMANCEREVIEW] Upload',
  PERFORMANCEREVIEW_UPLOAD_SUCCESS = '[PERFORMANCEREVIEW] Upload Success',
  PERFORMANCEREVIEW_UPLOAD_ERROR = '[PERFORMANCEREVIEW] Upload Error',

  // Clear All PERFORMANCEREVIEW
  PERFORMANCEREVIEW_ITEM_CLEAR = '[PERFORMANCEREVIEW] Clear',
}

// Get PerformanceReview
//export class PerformanceReviewGet implements Action {
//  readonly type = PerformanceReviewActionTypes.PERFORMANCEREVIEW_GET_ALL;
//  constructor(public body: TemplateGetPerformanceReviewQuery) {}
//}

//export class PerformanceReviewGetSuccess implements Action {
//  readonly type = PerformanceReviewActionTypes.PERFORMANCEREVIEW_GET_ALL_SUCCESS;
//  constructor(public payload: any[]) {}
//}

//export class PerformanceReviewGetError implements Action {
//  readonly type = PerformanceReviewActionTypes.PERFORMANCEREVIEW_GET_ALL_ERROR;
//  constructor(public payload: any) {}
//}

// UPDATE PERFORMANCEREVIEW
export class PerformanceReviewUpdate implements Action {
  readonly type = PerformanceReviewActionTypes.PERFORMANCEREVIEW_UPDATE;
  constructor(public payload: PerformanceReviewPatchPerformanceReviewsCommand) {}
}

export class PerformanceReviewUpdateSuccess implements Action {
  readonly type = PerformanceReviewActionTypes.PERFORMANCEREVIEW_UPDATE_SUCCESS;
  constructor(public payload: any) {}
}

export class PerformanceReviewUpdateError implements Action {
  readonly type = PerformanceReviewActionTypes.PERFORMANCEREVIEW_UPDATE_ERROR;
  constructor(public payload: any) {}
}

// UPLOAD MEASURE
//export class PerformanceReviewUpload implements Action {
//  readonly type = PerformanceReviewActionTypes.PERFORMANCEREVIEW_UPLOAD;
//  constructor(public payload: TemplateUploadPerformanceReviewCommand) { }
//}

//export class PerformanceReviewUploadSuccess implements Action {
//  readonly type = PerformanceReviewActionTypes.PERFORMANCEREVIEW_UPLOAD_SUCCESS;
//  constructor(public payload: any) { }
//}

//export class PerformanceReviewUploadError implements Action {
//  readonly type = PerformanceReviewActionTypes.PERFORMANCEREVIEW_UPLOAD_ERROR;
//  constructor(public payload: any) { }
//}

// Clear All PerformanceReview
export class PerformanceReviewClear implements Action {
  readonly type = PerformanceReviewActionTypes.PERFORMANCEREVIEW_ITEM_CLEAR;
  constructor(public payload: any) {}
}

export type PerformanceReviewActions =
  // Get PerformanceReview
  //| PerformanceReviewGet
  //| PerformanceReviewGetSuccess
  //| PerformanceReviewGetError
  // Set All Structure Change Requests
  | PerformanceReviewUpdate
  | PerformanceReviewUpdateSuccess
  | PerformanceReviewUpdateError
  // Structure Change Request Upload
  //| PerformanceReviewUpload
  //| PerformanceReviewUploadSuccess
  //| PerformanceReviewUploadError
  // Clear All PerformanceReview
  | PerformanceReviewClear;
