<ng-container [formGroup]="structure">

  <kendo-formfield>
    <kendo-label [for]="legalEntity" text="Legal Entity"></kendo-label>
    <kendo-textbox formControlName="legalEntity" #legalEntity placeholder="{{ positionSeat.cr1f2_legalentity?.primaryName }}" readonly="true"></kendo-textbox>
  </kendo-formfield>

  <kendo-formfield>
    <kendo-label [for]="division" text="Division"></kendo-label>
    <kendo-textbox formControlName="division" #division placeholder="{{ positionSeat.cr1f2_division?.primaryName }}" readonly="true"></kendo-textbox>
  </kendo-formfield>

  <kendo-formfield>
    <kendo-label [for]="department" text="Department"></kendo-label>
    <kendo-textbox formControlName="department" #department placeholder="{{ positionSeat.cr1f2_department?.primaryName }}" readonly="true"></kendo-textbox>
  </kendo-formfield>

  <kendo-formfield>
    <kendo-label [for]="position" text="Position"></kendo-label>
    <kendo-textbox formControlName="position" #position placeholder="{{ positionSeat.bt_seattitle }}" readonly="true"></kendo-textbox>
  </kendo-formfield>

  <!--<kendo-formfield>
    <kendo-label [for]="description" text="Description"></kendo-label>
    <kendo-textbox formControlName="description" #description placeholder="{{ positionSeat.bthr_description }}" readonly="true"></kendo-textbox>
  </kendo-formfield>-->

  <kendo-formfield>
    <kendo-label [for]="cvDoc" text="Upload CV - Required"></kendo-label>
    <kendo-fileselect #cvDoc
                      formControlName="cvDoc"
                      [restrictions]="restrictions"
                      (select)="onFileSelect($event)">
    </kendo-fileselect>
    <kendo-formhint>Allowed extensions are pdf</kendo-formhint>

    <kendo-formhint></kendo-formhint>
  </kendo-formfield>

</ng-container>

