/* tslint:disable */
/* eslint-disable */
import { DocumentDocSalaryEmployee } from './document-doc-salary-employee';
import { DocumentDocTypeFinancial } from './document-doc-type-financial';
import { DocumentLegalEntityDto } from './document-legal-entity-dto';
import { DocumentBtProcessStatus } from './document-bt-process-status';
export interface DocumentVerdeBulkControlTable {
  bt_DocumentType?: DocumentDocTypeFinancial;
  bt_ImportByEntity?: DocumentLegalEntityDto;
  bt_ImportedBy?: DocumentDocSalaryEmployee;
  bt_LegalEntity?: DocumentLegalEntityDto;
  bt_ProcessComment?: null | string;
  bt_Status?: DocumentBtProcessStatus;
  bt_VerdeBulkControlTable?: null | string;
  bt_VerdeBulkControlTableId?: null | string;
}
