/* tslint:disable */
/* eslint-disable */
import { BtStructureManager } from './bt-structure-manager';
import { BtStructurePositionSeat } from './bt-structure-position-seat';
import { BtPositionseat } from './bt-positionseat';
export interface StructureDivisions {
  '_bt_parentdivision_value'?: null | string;
  '_bthr_legalentity_value'?: null | string;
  btHaschildrenODataCommunityDisplayV1FormattedValue?: null | string;
  btParentdivisionValueODataCommunityDisplayV1FormattedValue?: null | string;
  bt_Manager?: BtStructureManager;
  bt_PositionSeat?: BtStructurePositionSeat;
  bt_haschildren?: boolean;
  bt_seatstatus?: BtPositionseat;
  bthrLegalentityValueODataCommunityDisplayV1FormattedValue?: null | string;
  bthr_division?: null | string;
  bthr_divisionid?: null | string;
  odataSeatStatus?: null | string;
}
