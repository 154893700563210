/* tslint:disable */
/* eslint-disable */
import { ProcessesLookupExtrapolate } from './processes-lookup-extrapolate';
import { ProcessesMoney } from './processes-money';
import { ProcessesVerdeCalculationStatus } from './processes-verde-calculation-status';
import { ProcessesVerdeCustomerType } from './processes-verde-customer-type';
export interface ProcessesCustomerDetail {
  verde_additional?: null | number;
  verde_additionaladminextended?: ProcessesMoney;
  verde_additionaladminuseritem?: ProcessesLookupExtrapolate;
  verde_additionaladminusers?: null | number;
  verde_additionaladminuserunitprice?: ProcessesMoney;
  verde_additionaldatabase?: null | number;
  verde_additionaldataextended?: ProcessesMoney;
  verde_additionaldataitem?: ProcessesLookupExtrapolate;
  verde_additionaldataunitprice?: ProcessesMoney;
  verde_additionalfile?: null | number;
  verde_additionalfileextended?: ProcessesMoney;
  verde_additionalfileitem?: ProcessesLookupExtrapolate;
  verde_additionalfileunitprice?: ProcessesMoney;
  verde_additionallog?: null | number;
  verde_additionallogextended?: ProcessesMoney;
  verde_additionallogitem?: ProcessesLookupExtrapolate;
  verde_additionlogunitprice?: ProcessesMoney;
  verde_annual?: null | number;
  verde_basicconsulting?: ProcessesMoney;
  verde_basicconsultingperc?: null | number;
  verde_basicinfrastructure?: ProcessesMoney;
  verde_bssicperuser?: ProcessesMoney;
  verde_calculationstatus?: ProcessesVerdeCalculationStatus;
  verde_customer?: ProcessesLookupExtrapolate;
  verde_customercompanyname?: null | string;
  verde_customerdetailid?: string;
  verde_customerdetailno?: null | string;
  verde_customerno?: null | string;
  verde_customertype?: ProcessesVerdeCustomerType;
  verde_databasecapacity?: null | number;
  verde_email?: null | string;
  verde_filecapacity?: null | number;
  verde_fleet?: null | boolean;
  verde_fleetconsulting?: ProcessesMoney;
  verde_fleetconsultingper?: null | number;
  verde_fleetinfrastructure?: ProcessesMoney;
  verde_fleetitem?: ProcessesLookupExtrapolate;
  verde_fleetperuser?: ProcessesMoney;
  verde_fullconsultingper?: null | number;
  verde_fullinfrastructure?: ProcessesMoney;
  verde_fullname?: null | string;
  verde_fullperuser?: ProcessesMoney;
  verde_legalentity?: null | string;
  verde_logcapacity?: null | number;
  verde_monthly?: null | number;
  verde_newcustomer?: null | boolean;
  verde_noofadmin?: null | number;
  verde_noofemployees?: null | number;
  verde_noofsystemusers?: null | number;
  verde_peoplebasic?: null | boolean;
  verde_peoplebasicitem?: ProcessesLookupExtrapolate;
  verde_peoplefull?: null | boolean;
  verde_peoplefullitem?: ProcessesLookupExtrapolate;
  verde_peruser?: null | number;
  verde_product?: ProcessesLookupExtrapolate;
  verde_productcustomerno?: ProcessesLookupExtrapolate;
  verde_reseller?: ProcessesLookupExtrapolate;
  verde_subtotalbasic?: ProcessesMoney;
  verde_subtotalfleet?: ProcessesMoney;
  verde_subtotalfull?: ProcessesMoney;
  verde_systemextended?: ProcessesMoney;
  verde_systemusers?: null | number;
  verde_tenantid?: null | string;
  verde_totaladditionallicenses?: ProcessesMoney;
  verde_verdefleet?: ProcessesMoney;
  verde_verdefleetplatform?: ProcessesMoney;
  verde_verdefullbasic?: ProcessesMoney;
  verde_verdepeoplebasic?: ProcessesMoney;
  verde_verdeplatform?: ProcessesMoney;
  verde_verdeplatformfull?: ProcessesMoney;
}
