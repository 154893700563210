/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { DocumentBaseService } from '../document-base-service';
import { DocumentApiConfiguration } from '../document-api-configuration';
import { DocumentStrictHttpResponse } from '../document-strict-http-response';
import { DocumentRequestBuilder } from '../document-request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DocumentVerdeVersion } from '../models/document-verde-version';

@Injectable({
  providedIn: 'root',
})
export class DocumentApiVersionService extends DocumentBaseService {
  constructor(
    config: DocumentApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getVersionNumber
   */
  static readonly GetVersionNumberPath = '/api/Version/GetVersionNumber';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVersionNumber()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVersionNumber$Response(params?: {
  }): Observable<DocumentStrictHttpResponse<DocumentVerdeVersion>> {

    const rb = new DocumentRequestBuilder(this.rootUrl, DocumentApiVersionService.GetVersionNumberPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as DocumentStrictHttpResponse<DocumentVerdeVersion>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVersionNumber$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVersionNumber(params?: {
  }): Observable<DocumentVerdeVersion> {

    return this.getVersionNumber$Response(params).pipe(
      map((r: DocumentStrictHttpResponse<DocumentVerdeVersion>) => r.body as DocumentVerdeVersion)
    );
  }

}
