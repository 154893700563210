/* tslint:disable */
/* eslint-disable */
import { DocumentDocSalaryLegalEntity } from './document-doc-salary-legal-entity';
import { DocumentBtIntegrationType } from './document-bt-integration-type';
export interface DocumentVerdeIntegrationSource {
  bt_apiurl?: null | string;
  bt_authenticationrequired?: null | boolean;
  bt_clientid?: null | string;
  bt_clientsecret?: null | string;
  bt_description?: null | string;
  bt_detail?: null | boolean;
  bt_employeeidmask?: null | string;
  bt_employeeidminlength?: null | number;
  bt_encryptionrequired?: null | boolean;
  bt_header?: null | boolean;
  bt_integrationtype?: DocumentBtIntegrationType;
  bt_legalentity?: DocumentDocSalaryLegalEntity;
  bt_noofdetails?: null | number;
  bt_noofheaders?: null | number;
  bt_numberoffiles?: null | number;
  bt_password?: null | string;
  bt_replacetaxyearwithyearfromperiodend?: null | boolean;
  bt_scope?: null | string;
  bt_type?: null | string;
  bt_username?: null | string;
  bt_verdeintegrationsource?: null | string;
  guid?: null | string;
}
