<kendo-dialog *ngIf="opened" (close)="closeModal()">
  <kendo-dialog-titlebar>
    <div style="font-size: 18px; line-height: 1.3em;">
      Please complete the ID Number below:
    </div>
  </kendo-dialog-titlebar>
  <kendo-dialog-content style="padding-top: 0; padding-left: 0; padding-right: 0; padding-bottom: 0; ">
    <div class="james-fancy" style="padding:0 0 0 0 !important">
      <div *ngFor="let loc of arrayOfID; index as i;" #inputs>
        <kendo-textbox #comp
                       id="TestID{{i}}"
                       name="TestID{{i}}"
                       style="margin: 5px 5px 5px 5px "
                       [style.width.px]="35"
                       [style.height.px]="35"
                       [style.font-size.px]="30"
                       [style.text-align]="center"
                       required
                       [ngModel]="loc"
                       [clearButton]="false"
                       [readonly]="loc !== '*'"
                       [maxlength]=1
                       [size]="large"
                       [tabindex]="loc !== '*' ? -1 : 36"
                       (click)="$event.target.value === '*' ? $event.target.select() : null"
                       (valueChange)="selectText('TestID',i, $event)">
        </kendo-textbox>
      </div>
      <kendo-dialog-actions layout="normal">
        <button kendoButton (click)="CheckIDs()" themeColor="primary">
          Submit
        </button>
      </kendo-dialog-actions>
    </div>
  </kendo-dialog-content>
</kendo-dialog>

<kendo-dialog *ngIf="openedIncorrect" (close)="closeIncorrect()">
  <h5 style="text-align:center">
    ID was entered incorrectly. Please try again.
  </h5>
  <br />
  <kendo-dialog-actions>
    <button kendoButton (click)="closeIncorrect()" themeColor="primary">
      Continue.
    </button>
  </kendo-dialog-actions>
</kendo-dialog>
