/* tslint:disable */
/* eslint-disable */
import { TemplateTemplateGroup } from './template-template-group';
import { TemplateTemplateLegalEntity } from './template-template-legal-entity';
export interface TemplateBenchmark {
  bt_Group?: TemplateTemplateGroup;
  bt_LegalEntity?: TemplateTemplateLegalEntity;
  bt_description?: null | string;
  bt_maxvalue?: null | number;
  bt_minvalue?: null | number;
  bt_peerreviewbenchmarkid?: null | string;
  bt_reviewbenchmark?: null | string;
}
