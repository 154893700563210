/* tslint:disable */
/* eslint-disable */
import { DocumentDocSalaryEmployee } from './document-doc-salary-employee';
import { DocumentDocSalaryLegalEntity } from './document-doc-salary-legal-entity';
export interface DocumentEnhanced {
  employee?: DocumentDocSalaryEmployee;
  enhancedID?: string;
  legalEntity?: DocumentDocSalaryLegalEntity;
  primaryName?: null | string;
  secureCode?: null | string;
}
