import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  TimeEntryApiModel,
  TimeEntryActivityApiModel,
  EmployeeFunds,
  ImportHeader,
  DelegationsSuccessionPlanSuccessor,
  FleetChecklist,
  VerdeApprovalService,
} from '@verde/shared';
import { SidePanelWidth } from '../../enums/sidepanel-width.enum';

@Injectable({
  providedIn: 'root',
})
export class VerdeDynamicPageService {
  private entityId$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private formname$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private formTitle$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private formNameAndEntityId$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  private pageEntityId$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private pagename$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private pageTitle$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private pageNameAndEntityId$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  private formExtraInfoGlobal$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  private useToReload$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  reloadObaerve = this.useToReload$.asObservable();

  constructor(private sidebarService: VerdeApprovalService) {}

  //setDynamicForm(entityId: string, formname: string) {
  //  this.entityId$.next(entityId);
  //  this.formname$.next(formname);
  //}

  setDynamicForm(formNameAndEntityId: string[], componentToOpen: string, extraInfoForComponent?: any[]) {
    formNameAndEntityId[2] = componentToOpen;

    this.formNameAndEntityId$.next(formNameAndEntityId);

    if (componentToOpen == 'sidepanel') {
      this.sidebarService.setShowSidebar(extraInfoForComponent[0]);
      this.sidebarService.setSidebarSize(extraInfoForComponent[1]);
      this.sidebarService.setSidebarType(extraInfoForComponent[2]);
    }

    if (componentToOpen == 'Grid') {
    }
  }

  setDynamicFrontPage(pageNameAndEntityId: string[], componentToOpen: string, extraInfoForComponent?: any[]) {
    pageNameAndEntityId[2] = componentToOpen;

    this.pageNameAndEntityId$.next(pageNameAndEntityId);

    if (componentToOpen == 'Grid') {
    }
  }

  setDynamicFormTitle(formTitle: string) {
    this.formTitle$.next(formTitle);
  }

  getEntityId() {
    return this.entityId$.asObservable();
  }

  getFormName() {
    return this.formname$.asObservable();
  }

  getFormTitle() {
    setTimeout(function () {
      this.formTitle$ = null;
    }, 1);
    return this.formTitle$.asObservable();
  }

  getFormNameAndEntityId() {
    return this.formNameAndEntityId$;
  }

  clearFormNameAndEntityId() {
    //this.formNameAndEntityId$.next(null);
  }

  getPageNameAndEntityId() {
    return this.pageNameAndEntityId$;
  }

  setFormExtraInfoGlobal(input: string) {
    this.formExtraInfoGlobal$.next(input);
  }

  //getFormExtraInfoGlobal(key: string) {
  //  return this.formExtraInfoGlobal$.asObservable()[key];
  //}

  getFormExtraInfoGlobal() {
    return this.formExtraInfoGlobal$.asObservable();
  }
}
