import { Component, Input } from '@angular/core';
import { EnhancedReviewDetail } from '@verde/shared';

@Component({
  selector: 'verde-enhanced-response-option',
  templateUrl: './enhanced-response-option.component.html',
  styleUrls: ['./enhanced-response-option.component.scss'],
})
export class EnhancedResponseOptionComponent {
  @Input() set setReviewOption(val: EnhancedReviewDetail) {
    this.reviewOption = val;
  }

  reviewOption: EnhancedReviewDetail = {};

  doTextareaValueChange(ev) {
    try {
      this.reviewOption = ev.target.value;
    } catch (e) {
      console.info('could not set textarea-value');
    }
  }
}
