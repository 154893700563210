/* tslint:disable */
/* eslint-disable */
export enum TasksBtTaskStatus {
  New = 'New',
  Assigned = 'Assigned',
  InProgress = 'InProgress',
  Completed = 'Completed',
  Rejected = 'Rejected',
  Reassigned = 'Reassigned',
  Closed = 'Closed'
}
