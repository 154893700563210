import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UserSecurityLegalEntity, UserService } from '@verde/core';
import { ProcessesApiProcessesService, ProcessesBthrTerminationreason, ProcessesEmployeeExit, User, UserApiUserService, UserUserDto } from '@verde/shared';
import * as moment from 'moment';
import { Subject, take, takeUntil } from 'rxjs';
import { HrPortalService } from '../../../../../../../../../apps/verde/src/app/features/hr-portal/services/hr-portal.service';
import { VerdeApprovalService } from '../../../../services/verde-approval/verde-approval.service';

@Component({
  selector: 'verde-employee-exit',
  templateUrl: './employee-exit.component.html',
  styleUrls: ['./employee-exit.component.scss'],
})
export class EmployeeExitComponent implements OnInit {
  private onDestroy$ = new Subject<boolean>();

  @ViewChild('comments') commentsInput: any;
  @ViewChild('fileUpload') fileUploadInput: any;

  public min: Date = new Date(1917, 0, 1);
  public max: Date = new Date(2020, 4, 31);

  uploadedFiles: File[] = [];

  employeeExitAccess: UserSecurityLegalEntity[] = [];
  selectedLegalEntity: string;
  selectedEmployee: string;
  selectedExitReason: string;
  allEmployeesFilteredData: Array<UserUserDto> = new Array<UserUserDto>();
  employeeDropdownMessage: string = 'Type at least 3 characters';
  exitReasons: any;
  exitReasonsBackup: any;
  gridColWidth = '45%';
  terminationOptions: ProcessesBthrTerminationreason[] = Object.values(ProcessesBthrTerminationreason);
  public terminationOptionsList: Array<{ value: string; text: number }>;
  public isApiCallInProgress: boolean = false;
  selectedTerminationReason: any;

  constructor(
    private userService: UserService,
    private userApiUserService: UserApiUserService,
    private processesApiProcessesService: ProcessesApiProcessesService,
    private sidebarService: VerdeApprovalService,
    private refreshService: HrPortalService,
    private datePipe: DatePipe,
  ) {}

  ngOnInit(): void {
    this.employeeExitAccess = this.userService.getLegalEntitiesWithPermission('PEOPLE-PEOPLE-MANAGEMENT-ACCESS');
    this.terminationOptionsList = this.terminationOptions.map((word, index) => ({ value: word.trim(), text: index + 1 }));
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  public registerForm: FormGroup = new FormGroup({
    empNotice: new FormControl(),
    legalEntity: new FormControl(),
    employee: new FormControl(),
    exitReason: new FormControl(),
    terminationReason: new FormControl(),
    exitDate: new FormControl(moment().toDate()),
    noticeDate: new FormControl(moment().toDate()),
    comments: new FormControl(),
    fileUpload: new FormControl(),
  });

  changeYes() {
    this.exitReasons = [];
    this.registerForm.get('exitReason').setValue(null);
  }

  changeNo() {
    this.selectedTerminationReason = null;
    this.isApiCallInProgress = true;
    this.exitReasons = [];
    this.processesApiProcessesService
      .getExitReason({ body: { statusCode: true, empNotice: false } })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (val) => {
          this.exitReasons = val;
          this.exitReasonsBackup = val;
          this.registerForm.get('exitReason').setValue(null);
        },
        () => {},
        () => {
          this.exitReasons.sort((a, b) => a.bt_verdeemployeeexitreasons.localeCompare(b.bt_verdeemployeeexitreasons));
          this.exitReasonsBackup.sort((a, b) => a.bt_verdeemployeeexitreasons.localeCompare(b.bt_verdeemployeeexitreasons));

          this.isApiCallInProgress = false;
        },
      );
  }

  setTerminationReasonValue(terminationReason) {
    this.selectedTerminationReason = terminationReason.value;
    this.isApiCallInProgress = true;
    this.exitReasons = [];
    this.processesApiProcessesService
      .getExitReason({ body: { statusCode: true, empNotice: true, reason: terminationReason.value } })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (val) => {
          this.exitReasons = val;
          this.exitReasonsBackup = val;
          this.registerForm.get('exitReason').setValue(null);
        },
        () => {},
        () => {
          this.exitReasons.sort((a, b) => a.bt_verdeemployeeexitreasons.localeCompare(b.bt_verdeemployeeexitreasons));
          this.exitReasonsBackup.sort((a, b) => a.bt_verdeemployeeexitreasons.localeCompare(b.bt_verdeemployeeexitreasons));

          this.isApiCallInProgress = false;
        },
      );
  }

  //onFileChange(event: any) {
  //  console.log(event)
  //  const selectedFiles: FileList = event.target.files;
  //  for (let i = 0; i < selectedFiles.length; i++) {
  //    this.uploadedFiles.push(selectedFiles[i]);
  //  }
  //}

  setLegalEntityDropdownValue(legalEntity) {
    this.selectedLegalEntity = legalEntity._bt_legalentity_value;
  }

  setExitReasonValue(exitReason) {
    this.selectedExitReason = exitReason.bt_verdeemployeeexitreasonsid;
  }

  setEmployeeDropdownValue(employee) {
    this.selectedEmployee = employee.bthr_employeeid;
  }

  handleToEmployeeFilter(search: string) {
    if (search.length >= 3) {
      this.userApiUserService
        .getUsersByLegalEntity({
          body: {
            search,
            legalEntityId: this.selectedLegalEntity,
          },
        })
        .pipe(take(1))
        .subscribe(
          (ret) => {
            this.allEmployeesFilteredData = ret;
          },
          (error) => {
            console.error(error);
          },
          () => {
            if (this.allEmployeesFilteredData.length === 0) {
              this.employeeDropdownMessage = 'No Data Found for ' + search;
            }
          },
        );
    } else if (search.length === 0) {
      this.allEmployeesFilteredData = [];
      this.employeeDropdownMessage = 'Type at least 3 characters';
    }
  }

  handleToExitReasonFilter(searchParam: string) {
    this.exitReasons = this.exitReasons.filter((reason) => reason.bt_verdeemployeeexitreasons.toLowerCase().includes(searchParam.toLowerCase()));

    if (!searchParam) {
      this.exitReasons = this.exitReasonsBackup;
    }
  }

  public submitForm(): void {
    if (this.registerForm.valid) {
      this.postEMPEXIT();
    }
  }

  public clearForm(): void {
    this.uploadedFiles = [];
    this.registerForm.reset();
    this.commentsInput.nativeElement.value = '';
    this.fileUploadInput.nativeElement.value = '';
  }

  postEMPEXIT() {
    let body: ProcessesEmployeeExit = {
      bt_comment: this.registerForm.value.comments,
      legalEntityID: this.selectedLegalEntity,
      employeeID: this.selectedEmployee,
      exitReasonID: this.selectedExitReason,
      bt_exitdate: this.registerForm.value.exitDate,
      filesToUpload: this.uploadedFiles,
      bt_noticedate: this.registerForm.value.exitDate,
    };

    let todayDate = new Date();
    let noticeDate = this.datePipe.transform(todayDate, 'yyyy-MM-ddTHH:mm:ss.SSSZ');

    console.log(this.selectedTerminationReason);
    if (this.selectedTerminationReason == null) {
      this.selectedTerminationReason == '217871f6-63e6-ec11-bb3e-00224881abe4';
    }

    this.processesApiProcessesService
      .postEmployeeExit({
        body: {
          bt_comment: this.registerForm.value.comments,
          EmployeeID: this.selectedEmployee,
          bt_exitdate: this.registerForm.value.exitDate,
          LegalEntityID: this.selectedLegalEntity,
          ExitReasonID: this.selectedExitReason,
          filesToUpload: this.uploadedFiles,
          bt_noticedate: this.registerForm.value.noticeDate,
          noticeType: this.selectedTerminationReason,
        },
      })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          console.log(ret);
        },
        (error) => {
          console.error(error);
        },
        () => {
          this.sidebarService.setShowSidebar(false);
          this.refreshService.refreshView();
        },
      );
  }

  onFileSelect(event: any) {
    const selectedFiles: FileList = event;
    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];
      if (!this.uploadedFiles.includes(file)) {
        this.uploadedFiles.push(file);
      }
    }
  }

  removeFile(event: any) {
    const index = this.uploadedFiles.findIndex((item) => item.size === event.files[0].size);
    if (index >= 0) {
      this.uploadedFiles.splice(index, 1);
    }
  }
}
