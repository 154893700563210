import { Injectable, ViewChild } from '@angular/core';
import { GridDataResult, DataBindingDirective } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import { BookingAvailabilitiesFiltered, BookingsFiltered, SharedApiBookingsService } from '@verde/api';
import { UserService } from '@verde/core';
import moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, ReplaySubject, Subject, take, takeUntil } from 'rxjs';
import { VerdeApprovalService } from '../../../layout/verde-approval-panel/services/verde-approval.service';
import { ModalService } from '../../modals/modal.service';

@Injectable({
  providedIn: 'root',
})
export class BookingsDataService {
  refreshBooking: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  allBookings: ReplaySubject<BookingsFiltered[]> = new ReplaySubject<BookingsFiltered[]>(1);
  onDestroy$: Subject<any> = new Subject();

  bookingStartDate: string;
  bookingEndDate: string;
  bookingDate: string;
  bookingAvailabilitySearchDetails: any;
  BookingItemName: string;
  bookingItemSelected: BookingAvailabilitiesFiltered;

  bookingAvailabilities: GridDataResult;
  bookingAvailabilityGridData: BookingAvailabilitiesFiltered[] = [];

  bookingId: string;
  showConfirmationModal: boolean;
  bookingAvailabilitiesLoading: boolean;

  DisplayMessage: string;
  disableAnimation: boolean;

  deskBookedImageStyle = 'filter: invert(50%) sepia(100%) saturate(5000%) hue-rotate(330deg) brightness(90%);';
  deskUnavailableImageStyle = 'filter: grayscale(100%) brightness(90%);';
  deskAvailableCursorStyle = 'cursor: pointer;';
  deskUnavailableCursorStyle = 'cursor: default;';

  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  bookingsGridView: any[];

  gridSort: SortDescriptor[] = [
    {
      field: 'bt_bookingitem',
      dir: 'asc',
    },
  ];

  constructor(
    private bookingService: SharedApiBookingsService,
    private userService: UserService,
    private spinner: NgxSpinnerService,
    private sharedApiBookingsService: SharedApiBookingsService,
    private sidebarService: VerdeApprovalService,
    private modalService: ModalService,
  ) {}

  loadBookings(): void {
    this.bookingService
      .getBookingsByEmployee({
        employeeId: this.userService.user?.employeeId,
        legalEntity: this.userService.user?.legalEntityId,
      })
      .pipe(take(1))
      .subscribe({
        next: (o) => this.allBookings.next(o),
      });
  }

  openConfirmModel(bookingItem: BookingAvailabilitiesFiltered) {
    this.bookingItemSelected = bookingItem;
    this.bookingStartDate = moment(this.bookingAvailabilitySearchDetails?.bookingFrom).format('HH:mm');
    this.bookingEndDate = moment(this.bookingAvailabilitySearchDetails?.bookingTo).format('HH:mm');
    this.bookingDate = moment(this.bookingAvailabilitySearchDetails?.bookingFrom).format('YYYY-MM-DD');
    this.bookingAvailabilitySearchDetails.bookingItem = this.bookingItemSelected.bt_verdebookingitemsid;
    this.bookingAvailabilitySearchDetails.BookingItemName = this.bookingItemSelected.bt_bookingitem;
    this.modalService.open(`bookingDetailsModal`);
  }

  bookItem(legalEntity: string = this.userService.user?.legalEntityId, employee: string = this.userService.user?.employeeId) {
    this.spinner.show('bookingdetailsloading');

    const booking: BookingsFiltered = {
      bt_fromdate: moment(this.bookingAvailabilitySearchDetails?.bookingFrom).format('YYYY-MM-DDTHH:mm:ss[Z]'),
      bt_todate: moment(this.bookingAvailabilitySearchDetails?.bookingTo).format('YYYY-MM-DDTHH:mm:ss[Z]'),
      odataPostLegalEntity: '/bthr_legalentitieses(' + legalEntity + ')',
      odataPostDuration: '/bt_verdebookingdurations(' + this.bookingAvailabilitySearchDetails?.bookingDuration + ')',
      odataPostPrimaryLocation: '/bthr_locations(' + this.bookingAvailabilitySearchDetails?.primaryLocation + ')',
      odataPostBookingType: '/bt_bookingtypes(' + this.bookingAvailabilitySearchDetails?.bookingType + ')',
      odataPostBookingItem: '/bt_verdebookingitemses(' + this.bookingAvailabilitySearchDetails?.bookingItem + ')',
      odataPostEmployee: '/bthr_employees(' + employee + ')',
      odataPostOffice: this.bookingAvailabilitySearchDetails?.office != null ? '/bt_verdeoffices(' + this.bookingAvailabilitySearchDetails?.office + ')' : null,
      odataPostSubOffice:
        this.bookingAvailabilitySearchDetails?.subOffice != null ? '/bt_verdesuboffices(' + this.bookingAvailabilitySearchDetails?.subOffice + ')' : null,
      odataPostDepartment: '/bthr_departments(' + this.bookingAvailabilitySearchDetails?.DepartmentToPass + ')',
      bt_bookingreason: this.bookingAvailabilitySearchDetails?.BookingReason != null ? this.bookingAvailabilitySearchDetails?.BookingReason : null,
      bt_departmental: false,
    };

    this.sharedApiBookingsService
      .postBooking({ body: booking })
      .pipe(take(1), takeUntil(this.onDestroy$))
      .subscribe(
        (success) => {
          console.log(success);
          this.updateBookings(true);
          this.loadBookings();
        },
        (error) => {
          console.error(error);
        },
        () => {
          this.sidebarService.setShowSidebar(false);
          this.spinner.hide('bookingdetailsloading');
        },
      );
  }

  bookDesk(deskId: string) {
    console.log(`Desk ${deskId} clicked!`);
    const bookingItem = this.bookingsGridView.find((desk) => desk?.bt_bookingitem === `${deskId}`);
    if (bookingItem) {
      bookingItem.bt_VerdeBookings_BookingItem_bt_VerdeBook?.length > 0 ? null : this.openConfirmModel(bookingItem);
    }
  }
  getDeskImageStyle(deskId: string) {
    const bookingItem = this.bookingsGridView.find((desk) => desk?.bt_bookingitem === `${deskId}`);
    if (bookingItem) {
      return bookingItem.bt_VerdeBookings_BookingItem_bt_VerdeBook?.length > 0 ? this.deskBookedImageStyle : '';
    } else {
      return this.deskUnavailableImageStyle;
    }
  }
  getDeskCursorStyle(deskId: string) {
    const bookingItem = this.bookingsGridView.find((desk) => desk?.bt_bookingitem === `${deskId}`);
    if (bookingItem) {
      return bookingItem.bt_VerdeBookings_BookingItem_bt_VerdeBook?.length > 0 ? this.deskUnavailableCursorStyle : this.deskAvailableCursorStyle;
    } else {
      return this.deskUnavailableCursorStyle;
    }
  }
  getDeskToolTip(deskId: string) {
    const bookingItem = this.bookingsGridView.find((desk) => desk?.bt_bookingitem === `${deskId}`);
    if (bookingItem) {
      return bookingItem.bt_VerdeBookings_BookingItem_bt_VerdeBook?.length > 0
        ? `${bookingItem?.bt_bookingitem} Booked`
        : `Book ${bookingItem?.bt_bookingitem}`;
    } else {
      return `${bookingItem?.bt_bookingitem ?? deskId} Unavailable`;
    }
  }

  updateBookings(bookings: boolean) {
    this.refreshBooking.next(bookings);
  }
}
