/* tslint:disable */
/* eslint-disable */
import { PerformanceReviewGuidExtrapolate } from './performance-review-guid-extrapolate';
export interface PerformanceReviewEmployeeReviewFiltered {
  bt_Summary?: null | string;
  bt_attachmentfilename?: null | string;
  bt_commission?: null | boolean;
  bt_employeecompleted?: null | boolean;
  bt_employeerecallaccepted?: null | boolean;
  bt_employeerecallaccepteddate?: null | string;
  bt_kpi?: null | boolean;
  bt_managercompleted?: null | boolean;
  bt_managerrecallrequest?: null | boolean;
  bt_managerrecallrequestdate?: null | string;
  bt_reviewenhancement?: null | boolean;
  bt_totalscore?: null | number;
  bthr_employeeperformancereviewid?: null | string;
  bthr_name?: null | string;
  bthr_reviewdate?: null | string;
  bthr_reviewperiodenddate?: null | string;
  bthr_reviewperiodstartdate?: null | string;
  bthr_summaryofresults?: null | string;
  summary?: PerformanceReviewGuidExtrapolate;
}
