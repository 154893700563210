/* tslint:disable */
/* eslint-disable */
import { BtStructureType } from './bt-structure-type';
import { BtPositionseat } from './bt-positionseat';
export interface StructureFull {
  employeeName?: null | string;
  employeeNumber?: null | string;
  hasChildren?: null | boolean;
  name?: null | string;
  parentRecordID?: null | string;
  positionDescription?: null | string;
  positionSeatID?: null | string;
  recordID?: null | string;
  seatName?: null | string;
  seatStatus?: BtPositionseat;
  structureType?: BtStructureType;
}
