import { Action } from '@ngrx/store';
import { UserUserDto, VerdeTaskCustom } from '@verde/shared';

export enum TaskFollowUpActionTypes {
  // Get All Task FollowUp
  TASK_FOLLOWUP_GET_ALL = '[Task] FollowUp Get All Start',
  TASK_FOLLOWUP_GET_ALL_SUCCESS = '[Task] FollowUp Get All Start Success',
  TASK_FOLLOWUP_GET_ALL_ERROR = '[Task] FollowUp Get All Start Error',
  TASK_FOLLOWUP_GET_ALL_STOP = '[Task] FollowUp Get All Polling Stop',
  TASK_FOLLOWUP_GET_ALL_STOP_SUCCESS = '[Task] FollowUp Get All Stop Success',

  // Clear All Task FollowUp
  TASK_FOLLOWUP_CLEAR = '[Task] FollowUp Clear',
}

// Get All Task FollowUp
export class TaskFollowUpGetAll implements Action {
  readonly type = TaskFollowUpActionTypes.TASK_FOLLOWUP_GET_ALL;
  constructor(public proxyUser: UserUserDto) {}
}

export class TaskFollowUpGetAllSuccess implements Action {
  readonly type = TaskFollowUpActionTypes.TASK_FOLLOWUP_GET_ALL_SUCCESS;
  constructor(public payload: VerdeTaskCustom[]) {}
}

export class TaskFollowUpGetAllError implements Action {
  readonly type = TaskFollowUpActionTypes.TASK_FOLLOWUP_GET_ALL_ERROR;
  constructor(public payload: any) {}
}

export class TaskFollowUpGetAllStop implements Action {
  readonly type = TaskFollowUpActionTypes.TASK_FOLLOWUP_GET_ALL_STOP;
  constructor(public proxyUser: UserUserDto) {}
}

export class TaskFollowUpGetAllStopSuccess implements Action {
  readonly type = TaskFollowUpActionTypes.TASK_FOLLOWUP_GET_ALL_STOP_SUCCESS;
  constructor(public proxyUser: UserUserDto) {}
}

// Clear All Task FollowUp
export class TaskFollowUpClear implements Action {
  readonly type = TaskFollowUpActionTypes.TASK_FOLLOWUP_CLEAR;
  constructor(public payload: any) {}
}

export type TaskFollowUpActions =
  // Get All Task FollowUp
  | TaskFollowUpGetAll
  | TaskFollowUpGetAllSuccess
  | TaskFollowUpGetAllError
  | TaskFollowUpGetAllStop
  | TaskFollowUpGetAllStopSuccess
  // Clear All Task FollowUp
  | TaskFollowUpClear;
