import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-input',
  template: ` <kendo-textbox [id]="field.key" style="margin-bottom: auto; width: 100%;" type="input" [formControl]="formControl" [formlyAttributes]="field" /> `,
})
export class FormlyInputComponentKendo extends FieldType {
  ngOnInit() {
    //console.log(this.field.key)
    //console.log("AAAAAAA", this.field, this.model)
  }
}
