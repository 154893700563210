/* tslint:disable */
/* eslint-disable */
import { EquipmentEquipmentLegalEntity } from './equipment-equipment-legal-entity';
import { EquipmentEquipmentSpec } from './equipment-equipment-spec';
import { EquipmentEquipmentSpecType } from './equipment-equipment-spec-type';
import { EquipmentEquipmentType } from './equipment-equipment-type';
import { EquipmentEquipmentTypeClass } from './equipment-equipment-type-class';
import { EquipmentMoney } from './equipment-money';
export interface EquipmentEquipmentKit {
  bt_EquipmentSpec?: EquipmentEquipmentSpec;
  bt_EquipmentSpecType?: EquipmentEquipmentSpecType;
  bt_EquipmentType?: EquipmentEquipmentType;
  bt_EquipmentTypeClass?: EquipmentEquipmentTypeClass;
  bt_LegalEntity?: EquipmentEquipmentLegalEntity;
  bt_calculatedbudget?: EquipmentMoney;
  bt_calculatedbudget_date?: null | string;
  bt_calculatedbudget_state?: null | number;
  bt_description?: null | string;
  bt_equipmentkit?: null | string;
  bt_equipmentkitid?: string;
  bt_totalitemsuniqueitems?: null | number;
  bt_totalitemsuniqueitems_date?: null | string;
  bt_totalitemsuniqueitems_state?: null | number;
  bt_totalkititems?: null | number;
  bt_totalkititems_date?: null | string;
  bt_totalkititems_state?: null | number;
}
