<div class="expansion-header-flex">
  <div class="left-align-container">
    <div class="built-in-actions">
      <img *ngIf="helpURL != null && helpURL != ''"
           title="View Help"
           class="help_icon built-in-actions-icon"
           src="https://engageimages.z1.web.core.windows.net/Images/Verde/Other/help.svg"
           alt=""
           (click)="openHelpOrPolicy(helpURL)">
      <img *ngIf="policyURL != null && policyURL != ''"
           title="View Policies"
           class="policy_icon built-in-actions-icon"
           src="https://engageimages.z1.web.core.windows.net/Images/Verde/Other/policy.svg"
           alt=""
           (click)="openHelpOrPolicy(policyURL)">
      <img *ngIf="config?.legalEntityConfig?.reportIssue"
           title="Report Issue"
           class="issue_icon"
           src="https://engageimages.z1.web.core.windows.net/Images/Verde/issue.svg"
           alt=""
           (click)="reportIssue(issueLocation)">
    </div>

    <div *ngIf="config?.legalEntityConfig?.managerProxy && user?.hasSubordinates && config?.legalEntityConfig?.allProxy && showProxy"
         class="user_impersonation">
      <kendo-dropdownlist [data]="userService.team"
                          [disabled]="loading"
                          textField="fullNameWithEmpId"
                          valueField="employeeId"
                          id="userDropdown"
                          name="userDropdown"
                          (valueChange)="applyNewUser($event)"
                          [ngModel]="userService?.user">
      </kendo-dropdownlist>

      <!--<select class="custom-select responsive_select"
              [disabled]="loading"
              id="userDropdown"
              name="userDropdown"
              #varUserDropdown="ngModel"
              (change)="applyNewUser($event)"
              [(ngModel)]="currentSelectedId">
        <option *ngFor="let member of allTeamMembers"
                value="{{member.employeeId}}">
          {{member.fullNameWithEmpId}}
        </option>
      </select>-->
    </div>
  </div>
  <div *ngIf="customButtons.length > 0"
       class="right-align-container">
    <div class="custom-buttons single-button">
      <ng-container *ngFor="let button of customButtons">
        <button *ngIf="button.icon  || button.buttonTitle"
                (click)="button.action()"
                kendoButton
                themeColor="primary"
                [icon]="button.icon"
                class="action-button btn-sm">
          <span *ngIf="button.buttonTitle && windowWidth > 600">{{button.buttonTitle}}</span>
        </button>
        <div *ngIf="button.image"
             (click)="button.action()"
             class="refresh-image-icon">
          <img [src]="button.image">
        </div>
      </ng-container>
    </div>
  </div>
</div>

<div class="page-container-content">
  <ng-content></ng-content>
</div>

<verde-issue-modal #issueModal
                   *ngIf="showIssueModal"
                   issueLocation="{{issueLocation}}"
                   (hideModalEvent)="hideIssueModal($event)">
</verde-issue-modal>