/* tslint:disable */
/* eslint-disable */
import { EquipmentEquipmentLegalEntity } from './equipment-equipment-legal-entity';
import { EquipmentEquipmentLocation } from './equipment-equipment-location';
import { EquipmentEquipmentMake } from './equipment-equipment-make';
import { EquipmentEquipmentModel } from './equipment-equipment-model';
import { EquipmentEquipmentRequestItem } from './equipment-equipment-request-item';
export interface EquipmentEquipmentItemSummary {
  ageValue?: null | string;
  bt_LegalEntity?: EquipmentEquipmentLegalEntity;
  bt_Location?: EquipmentEquipmentLocation;
  bt_Make?: EquipmentEquipmentMake;
  bt_Model?: EquipmentEquipmentModel;
  bt_RequestEquipmentItem?: EquipmentEquipmentRequestItem;
  bt_equipmentitemsummaryid?: string;
  bt_equipmentno?: null | string;
  bt_onhandallocated?: null | number;
  bt_onhandallocated_date?: null | string;
  bt_onhandallocated_state?: null | number;
  bt_onhandavailable?: null | number;
  bt_onhandavailable_date?: null | string;
  bt_onhandavailable_state?: null | number;
  bt_onorder?: null | number;
  bt_onorder_date?: null | string;
  bt_onorder_state?: null | number;
  bt_onorderallocated?: null | number;
  bt_onorderallocated_date?: null | string;
  bt_onorderallocated_state?: null | number;
  bt_requested?: null | number;
  bt_requested_date?: null | string;
  bt_requested_state?: null | number;
  bt_requestedallocated?: null | number;
  bt_requestedallocated_date?: null | string;
  bt_requestedallocated_state?: null | number;
  bt_summaryid?: null | string;
  name?: null | string;
  parentID?: string;
}
