/* tslint:disable */
/* eslint-disable */
import { User } from './user';
export interface DvVerdeErrorLog {
  '_bt_taskno_value'?: null | string;
  bt_EmployeeNo?: User;
  bt_attachment?: null | string;
  bt_attachment_name?: null | string;
  bt_daysopen?: null | number;
  bt_email?: null | string;
  bt_errordescription?: null | string;
  bt_errortype?: null | string;
  bt_logno?: null | string;
  bt_resolutioncomment?: null | string;
  bt_resolutionstatus?: null | number;
  bt_type?: null | number;
  bt_uniquerecord?: null | string;
  bt_verdeerrorlogid?: null | string;
  createdon?: null | string;
  odataCreatedOn?: null | string;
  odataEmployee?: null | string;
  odataErrorType?: null | string;
  odataLegalEntity?: null | string;
  odataPostEmployee?: null | string;
  odataPostLegalEntity?: null | string;
  odataResolutionStatus?: null | string;
  odataTaskNo?: null | string;
}
