/* tslint:disable */
/* eslint-disable */
export interface DvAllowances {
  bt_employeeallowancesid?: null | string;
  bt_motivation?: null | string;
  bt_value?: null | number;
  bt_value_base?: null | number;
  odataAllowanceName?: null | string;
  odataRecordStatus?: null | string;
  odataStatus?: null | string;
  odataValueBased?: null | string;
}
