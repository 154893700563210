/* tslint:disable */
/* eslint-disable */
import { PerformanceReviewGuidExtrapolate } from './performance-review-guid-extrapolate';
import { PerformanceReviewBtResponseType } from './performance-review-bt-response-type';
export interface PerformanceReviewPeerReviewFeedback {
  bt_date?: null | string;
  bt_displaysubgroup?: null | boolean;
  bt_employee?: null | string;
  bt_feedbackcompleted?: null | boolean;
  bt_groupsequence?: null | number;
  bt_maxvalue?: null | number;
  bt_measuresequence?: null | number;
  bt_minvalue?: null | number;
  bt_name?: null | string;
  bt_peerreviewfeedbackid?: null | string;
  bt_rating?: null | number;
  bt_response?: null | string;
  bt_responsetype?: PerformanceReviewBtResponseType;
  bt_subgroupsequence?: null | number;
  employee?: PerformanceReviewGuidExtrapolate;
  group?: PerformanceReviewGuidExtrapolate;
  measure?: PerformanceReviewGuidExtrapolate;
  subGroup?: PerformanceReviewGuidExtrapolate;
}
