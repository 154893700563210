import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { Subject, take } from 'rxjs';
import { ModalService } from '../modal.service';
import { UserService } from '@verde/core';
import { SharedApiTrainingLinksService, VerdeOnlineTraining, VerdeOnlineTrainingConfig } from '@verde/shared';

interface stepsStructure {
  input: string;
  background: string;
  text: string;
  isLottie: boolean;
}

@Component({
  selector: 'verde-online-training-modal',
  templateUrl: './online-training-modal.component.html',
  styleUrls: ['./online-training-modal.component.scss'],
})
export class OnlineTrainingModalComponent implements OnInit, AfterViewInit, OnDestroy {
  onDestroy$ = new Subject<boolean>();

  @ViewChild('mainInnerHTML') mainInnerHTML: HTMLElement;

  @Output() hideModalEvent = new EventEmitter<boolean>();
  @Input() onlineTrainingConfig: VerdeOnlineTrainingConfig = {};

  public current = 0;

  public steps: stepsStructure[] = [];
  selectedStep: stepsStructure;

  allowContinue: boolean = false;
  dontShowAgain: boolean = false;
  isLottie: boolean = false;
  continueButtonText: string = 'Continue';

  options: AnimationOptions = {};

  constructor(private modalService: ModalService, private userService: UserService, private sharedApiTrainingLinksService: SharedApiTrainingLinksService) {}

  ngOnInit(): void {
    if (this.onlineTrainingConfig?.bt_stepcount > 1) {
      let sortedSteps = this.onlineTrainingConfig?.bt_bt_verdeonlinetrainingsteps_VerdeOnlineTr?.sort((a, b) => a.bt_stepno! - b.bt_stepno!);

      sortedSteps?.forEach((t) => {
        if (t.bt_html !== null) {
          this.steps.push({
            input: t.bt_html!,
            text: t.bt_stepno?.toString()!,
            background: t.bt_background!,
            isLottie: false,
          });
        } else {
          this.steps.push({
            input: t.bt_lottie!,
            text: t.bt_stepno?.toString()!,
            background: t.bt_background!,
            isLottie: true,
          });
        }
      });

      this.continueButtonText = 'Next';
    } else {
      this.continueButtonText = 'Continue';
      this.allowContinue = true;
    }
  }

  onAnimate(animationItem: AnimationItem): void {
    //console.log(animationItem);
  }

  ngAfterViewInit(): void {
    this.modalService.open('verdeOnlineTrainingModal');

    setTimeout(() => {
      this.selectedStep = this.steps[0];

      if (this.onlineTrainingConfig?.bt_bt_verdeonlinetrainingsteps_VerdeOnlineTr[0]?.bt_html !== null) {
        this.changeSelectedStepInput(this.onlineTrainingConfig?.bt_bt_verdeonlinetrainingsteps_VerdeOnlineTr[0]?.bt_html, false);
      } else {
        this.changeSelectedStepInput(this.onlineTrainingConfig?.bt_bt_verdeonlinetrainingsteps_VerdeOnlineTr[0]?.bt_lottie, true);
      }
    }, 10);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  hideModal(value: boolean) {
    if (!value) {
      this.hideModalEvent.emit(value);
    }
  }

  goPrevious() {
    if (this.current === this.onlineTrainingConfig?.bt_bt_verdeonlinetrainingsteps_VerdeOnlineTr?.length - 1) {
      this.continueButtonText = 'Next';
      this.allowContinue = false;
    }

    this.current = this.current - 1;
    this.currentStepChange(this.current);
  }

  goNext() {
    if (this.allowContinue) {
      this.continue();
    } else {
      if (this.onlineTrainingConfig?.bt_stepcount > 1) {
        if (this.current !== this.onlineTrainingConfig?.bt_bt_verdeonlinetrainingsteps_VerdeOnlineTr?.length - 1) {
          this.current = this.current + 1;
        }

        if (this.current === this.onlineTrainingConfig?.bt_bt_verdeonlinetrainingsteps_VerdeOnlineTr?.length - 1) {
          this.continueButtonText = 'Continue';
          this.allowContinue = true;
        }
      }
    }

    this.currentStepChange(this.current);
  }

  continue() {
    if (this.dontShowAgain) {
      this.modalService.open('confirmDontShowAgain');
    } else {
      this.hideModal(false);
    }
  }

  changeSelectedStepInput(input: string, isLottie: boolean) {
    this.isLottie = isLottie;

    if (isLottie) {
      this.options = {
        path: input,
        loop: false,
      };
    } else {
      setTimeout(() => {
        document.getElementById('stepsInnerHTML').innerHTML = input;
      }, 10);
    }
  }

  currentStepChange(index: number) {
    this.selectedStep = this.steps[index];
    this.changeSelectedStepInput(this.selectedStep.input, this.selectedStep.isLottie);

    if (index === this.steps.length - 1) {
      this.allowContinue = true;
    } else {
      this.allowContinue = false;
    }
  }

  dontShowAgainChange(e: boolean) {
    this.dontShowAgain = e;
  }

  confirmDontShowAgain(): void {
    this.hideModal(false);

    let body: VerdeOnlineTraining = {
      odataPosEmployee: '/bthr_employees(' + this.userService?.user?.employeeId + ')',
      odataPostLegalEntity: '/bthr_legalentitieses(' + this.userService?.user?.legalEntityId + ')',
      odataPostOnlineTrainingConfig: '/bt_verdeonlinetrainingconfigs(' + this.onlineTrainingConfig.bt_verdeonlinetrainingconfigid + ')',
    };

    this.sharedApiTrainingLinksService
      .postOnlineTrainingEmployee({ body: body })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          console.log(ret);
        },
        (error) => {
          console.error(error);
        },
        () => {
          body = {};
        },
      );
  }
}
