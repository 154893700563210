<div class="expansion_header_flex">
  <img *ngIf="config?.legalEntityConfig?.helpDocManagement != null"
       title="View Help"
       class="help_icon"
       src="https://engageimages.z1.web.core.windows.net/Images/Verde/Other/help.svg"
       alt=""
       (click)="openHelpOrPolicy(config?.legalEntityConfig?.helpDocManagement)" />
  <img *ngIf="config?.legalEntityConfig?.policyDocManagement != null"
       title="View Policies"
       class="policy_icon"
       src="https://engageimages.z1.web.core.windows.net/Images/Verde/Other/policy.svg"
       alt=""
       (click)="openHelpOrPolicy(config?.legalEntityConfig?.policyDocManagement)" />
  <img *ngIf="config?.legalEntityConfig?.reportIssue"
       title="Report Issue"
       class="issue_icon"
       src="https://engageimages.z1.web.core.windows.net/Images/Verde/issue.svg"
       alt=""
       (click)="reportIssue('Document Management')" />

  <div *ngIf="config?.legalEntityConfig?.managerProxy && config?.legalEntityConfig?.payslipProxy && onSalaryAdvice"
       class="user_impersonation">
    <kendo-dropdownlist [data]="allAvailableTeamMembers"
                        textField="fullNameWithEmpId"
                        valueField="employeeId"
                        id="userDropdown"
                        name="userDropdown"
                        (valueChange)="applyNewUser($event)"
                        [ngModel]="userService?.user">
    </kendo-dropdownlist>
  </div>
</div>

<kendo-tabstrip #documentTabstrip
                (tabSelect)="onTabSelect($event)"
                class="document_tabstrip">
  <kendo-tabstrip-tab title="Salary Advice"
                      [selected]="true">
    <ng-template kendoTabContent>
      <verde-payroll-documents></verde-payroll-documents>
    </ng-template>
  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab title="WFH">
    <ng-template kendoTabContent>
      <verde-personal-tax-documents></verde-personal-tax-documents>
    </ng-template>
  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab title="Personal">
    <ng-template kendoTabContent>
      <verde-bulk-docs GroupingType="Personal"></verde-bulk-docs>
    </ng-template>
  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab title="Tax">
    <ng-template kendoTabContent>
      <verde-bulk-docs GroupingType="Tax"></verde-bulk-docs>
    </ng-template>
  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab title="Financial">
    <ng-template kendoTabContent>
      <verde-bulk-docs GroupingType="Financial"></verde-bulk-docs>
    </ng-template>
  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab title="Other">
    <ng-template kendoTabContent>
      <verde-other-documents></verde-other-documents>
    </ng-template>
  </kendo-tabstrip-tab>
</kendo-tabstrip>

<!-- Report Issue Modal -->
<verde-issue-modal #issueModal
                   *ngIf="showIssueModal"
                   issueLocation="{{ issueLocation }}"
                   (hideModalEvent)="hideIssueModal($event)"> </verde-issue-modal>