/* tslint:disable */
/* eslint-disable */
import { UserEntityState } from './user-entity-state';
import { UserRelationshipEntityCollectionKeyValuePair } from './user-relationship-entity-collection-key-value-pair';
import { UserStringObjectKeyValuePair } from './user-string-object-key-value-pair';
import { UserStringStringKeyValuePair } from './user-string-string-key-value-pair';
export interface UserBtProbation {
  attributes?: null | Array<UserStringObjectKeyValuePair>;
  entityState?: UserEntityState;
  formattedValues?: null | Array<UserStringStringKeyValuePair>;
  id?: string;
  keyAttributes?: null | Array<UserStringObjectKeyValuePair>;
  logicalName?: null | string;
  relatedEntities?: null | Array<UserRelationshipEntityCollectionKeyValuePair>;
  rowVersion?: null | string;
}
