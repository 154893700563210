/* tslint:disable */
/* eslint-disable */
export interface TimeActivityEmployeeApiModel {
  '_bt_activity_value'?: null | string;
  '_bt_activityentity_value'?: null | string;
  '_bt_employee_value'?: null | string;
  '_bt_employeelegalentity_value'?: null | string;
  '_bt_timeentryactivity_value'?: null | string;
  bt_timeactivityemployeeno?: null | string;
  bt_verdetimeactivityemployeeid?: null | string;
  odataActivity?: null | string;
  odataActivityLegalEntity?: null | string;
  odataEmployee?: null | string;
  odataEmployeeLegalEntity?: null | string;
  odataTimeEntryActivity?: null | string;
}
