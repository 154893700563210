<!-- Rendom Button -->
<button id="showConfirmationModal"
        hidden
        (click)="getFleetMovement(fleetTransferConfirmationForm)"></button>
<!-- #region Confirm Fleet Modal -->
<verde-modal id="newFleetTransferConfirmationModal"
             modalTitle="Confirm {{transferDirection}}"
             modalMinWidth="750"
             submitButton="Submit"
             (actionButton)="patchFleetTransferConfirmation(fleetTransferConfirmationForm)"
             (modalCancelled)="hideModal()"
             [submitActive]="fleetTransferConfirmationForm.valid"
             submitConfirmationMessage="{{confirmationText}}"
             cancelConfirmationMessage="Please confirm that you would like to cancel"
             [hasSubmitConfirmation]="true"
             [hasCancelConfirmation]="fleetTransferConfirmationForm.touched && !fleetTransferConfirmationForm.pristine">
  <form #fleetTransferConfirmationForm="ngForm"
        novalidate
        autocomplete="off"
        style="position: relative; height: 280px;">
    <ngx-spinner [disableAnimation]="disableAnimation"
                 name="fleettransferconfirmation"
                 bdColor="rgb(237, 237, 237)"
                 size="default"
                 color="#6264a7"
                 type="ball-clip-rotate"
                 [fullScreen]="false">

    </ngx-spinner>
    <div *ngIf="!actionAlreadyCompletedWarning">
      <div class="modal_grid">

        <div *ngIf="selectedTransfer?.odataFromEmployee != null"
             class="form-group">
          <label for="confirmFromEmployee">From Employee:</label>
          <kendo-textbox [readonly]="true"
                         id="confirmFromEmployee"
                         name="confirmFromEmployee"
                         [ngModel]="selectedTransfer?.bt_FromEmployee?.bt_fullname">
          </kendo-textbox>
        </div>

        <div *ngIf="selectedTransfer?.odataFromLocation != null"
             class="form-group">
          <label for="confirmFromLocation">From Location:</label>
          <kendo-textbox [readonly]="true"
                         id="confirmFromLocation"
                         name="confirmFromLocation"
                         [ngModel]="selectedTransfer?.odataFromLocation">
          </kendo-textbox>
        </div>

        <div *ngIf="selectedTransfer?.odataToEmployee != null"
             class="form-group">
          <label for="confirmToEmployee">To Employee:</label>
          <kendo-textbox [readonly]="true"
                         id="confirmToEmployee"
                         name="confirmToEmployee"
                         [ngModel]="selectedTransfer?.bt_ToEmployee?.bt_fullname">
          </kendo-textbox>
        </div>

        <div *ngIf="selectedTransfer?.odataToLocation != null"
             class="form-group">
          <label for="confirmToLocation">To Location:</label>
          <kendo-textbox [readonly]="true"
                         id="confirmToLocation"
                         name="confirmToLocation"
                         [ngModel]="selectedTransfer?.odataToLocation">
          </kendo-textbox>
        </div>

        <div class="form-group">
          <label for="confirmModel">Make - Model:</label>
          <kendo-textbox [readonly]="true"
                         id="confirmModel"
                         name="confirmModel"
                         [ngModel]="selectedTransfer?.odataMake">
          </kendo-textbox>
        </div>

        <div class="form-group">
          <label for="confirmLicenseNo">License No:</label>
          <kendo-textbox [readonly]="true"
                         id="confirmLicenseNo"
                         name="confirmLicenseNo"
                         [ngModel]="selectedTransfer?.bthr_licensenumber">
          </kendo-textbox>
        </div>
      </div>

      <div *ngIf="agreedNo"
           class="form-group">
        <label for="confirmRequestComment">Reason for not confirming:</label>
        <kendo-textarea id="confirmRequestComment"
                        name="confirmRequestComment"
                        #varConfirmRequestComment="ngModel"
                        maxlength="300"
                        resizable="vertical"
                        required
                        [ngModel]>
        </kendo-textarea>
        <!--Validation -->
        <div class="alert alert-danger"
             *ngIf="varConfirmRequestComment.touched && !varConfirmRequestComment.valid">
          <div *ngIf="varConfirmRequestComment.errors?.required">Field is required!</div>
          <div *ngIf="varConfirmRequestComment.errors?.minlength">Enter a detailed reason.</div>
        </div>
      </div>

      <div class="form-group"
           style="height: 30px;"
           [ngStyle]="{'margin-top': agreedNo ? '0' : '94px'}">
        <div style="display: inline-flex; margin-bottom: unset; float: right">
          <label style="margin-bottom: unset;">Confirm this transfer:</label>

          <div style="display: inline-flex; margin-bottom: unset; margin-left: 5px; float: right">

            <label style="margin-bottom: unset;"
                   for="agreedYes">Yes:</label>
            <input style="width: 25px; height: 20px; margin: 3px 0 0 5px; vertical-align:middle;"
                   (change)="agreedYesChange($event.target.checked, varAgreedNo)"
                   type="checkbox"
                   kendoCheckBox
                   class="form-control"
                   id="agreedYes"
                   name="agreedYes"
                   #varAgreedYes="ngModel"
                   [ngModel]="agreedYes">

            <label for="agreedNo"
                   style="margin-left: 5px; margin-bottom: unset;">No:</label>
            <input style="width: 25px; height: 20px; margin: 3px 0 0 5px; vertical-align:middle;"
                   (change)="agreedNoChange($event.target.checked, varAgreedYes)"
                   type="checkbox"
                   kendoCheckBox
                   class="form-control"
                   id="agreedNo"
                   name="agreedNo"
                   #varAgreedNo="ngModel"
                   [ngModel]="agreedNo">
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="actionAlreadyCompletedWarning">
      <p>This assignment has already been processed.</p>
    </div>
  </form>
</verde-modal>
<!-- #endregion Confirm Fleet Modal -->
<!-- #region Confirmation Modal -->
<verde-modal modalTitle="Confirmation"
             id="confirmFleetTransferConfirmationModal"
             [modalMaxWidth]="400"
             [hasCancelButton]="false"
             submitButton="Continue"
             (actionButton)="onConfirmClick()">
  <div style="position: relative; height: 60px">
    <ngx-spinner [disableAnimation]="disableAnimation"
                 name="confirmfleettransferconfirmation"
                 bdColor="rgb(237, 237, 237)"
                 size="default"
                 color="#6264a7"
                 type="ball-clip-rotate"
                 [fullScreen]="false">

    </ngx-spinner>

    <p>{{ confirmationMessage }}</p>
  </div>
</verde-modal>
<!-- #endregion Confirmation Modal -->