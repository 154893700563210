import { AfterViewInit, Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { take, takeUntil } from 'rxjs/operators';
import {
  ModalService,
  SharedApiVerdeDocumentsService,
  VerdeDocumentsAll,
  VerdeDocumentsOne,
  VerdeWfhSummary,
  VerdeHeaderTemplate,
  VerdeFooterTemplate,
} from '@verde/shared';
import { UserService } from '@verde/core';
import moment from 'moment';
import { Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';

enum PropertyType {
  Header = '692360000',
  Footer = '692360001',
}

@Component({
  selector: 'verde-wfh-documents',
  templateUrl: './wfh-documents.component.html',
  styleUrls: ['./wfh-documents.component.scss'],
})
export class WfhDocumentsComponent implements OnInit, AfterViewInit {
  @ViewChildren('inputs') private inputs: QueryList<ElementRef>;
  private onDestroy$ = new Subject<boolean>();
  disableAnimation: boolean = false;
  wfhDocsLoading: boolean = true;

  SingleDocument: VerdeDocumentsOne = {};
  editorContentValue: string | undefined | null;
  allTemplatesGridData: VerdeDocumentsAll[] = [];
  allYearGridData: VerdeWfhSummary[] = [];
  selectedDocument: VerdeDocumentsOne | undefined;
  editorDisabled: boolean = true;
  wfhSummary: any | undefined;
  HeaderContent: VerdeHeaderTemplate | undefined;
  FooterContent: VerdeFooterTemplate | undefined;
  type: PropertyType | undefined;

  headerText: string | null = null;
  footerText: string | null = null;

  htmlFooter: string = null;
  htmlHeader: string = null;

  layout: string[] | undefined;

  showIDValidationModal: boolean = false;

  inMicrosoftSignIn: boolean = false;

  constructor(
    private sharedapiverdedocumentsservice: SharedApiVerdeDocumentsService,
    private userService: UserService,
    private modalService: ModalService,
    private spinner: NgxSpinnerService,
  ) {}

  ngAfterViewInit(): void {
    //this.inputs.changes.subscribe((res) =>
  }

  validationSuccess(e: boolean) {
    if (e) {
      this.showIDValidationModal = false;

      this.modalService.open('DisplayDocModal');

      setTimeout(() => {
        const HtmlTemplateContent = document.getElementById('HtmlTemplateContent');
        if (HtmlTemplateContent) {
          HtmlTemplateContent.innerHTML = this.editorContentValue ?? '';
        }
      }, 5);
    }
  }

  ngOnInit(): void {
    this.getFooterData();
    this.getHeaderData();
    this.getAllTemplates();
    this.userService.disableAnimation$.pipe(takeUntil(this.onDestroy$)).subscribe((data) => {
      this.disableAnimation = data;
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  GetTemplateType(selectedRecord: VerdeDocumentsOne) {
    this.spinner.show('displayYearSpinnyBoi');
    this.sharedapiverdedocumentsservice
      .getVerdeWfhSummary({
        LegalEntity: this.userService.proxyUser.legalEntityId,
        EmpID: this.userService.proxyUser.employeeId,
      })
      .pipe(take(1))
      .subscribe(
        (ret1) => {
          this.allYearGridData = ret1;

          this.selectedDocument = selectedRecord;
        },
        (error) => {
          console.error(error);
        },
        () => {
          this.ShowYearModal();
          this.spinner.hide('displayYearSpinnyBoi');
        },
      );
  }

  ShowYearModal() {
    this.modalService.open('DisplayYearData');
  }

  getHeaderData() {
    this.sharedapiverdedocumentsservice
      .verdeHeaderTemplate({
        LegalEntity: this.userService.proxyUser.legalEntityId,
      })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          this.HeaderContent = ret[0];
        },
        (error) => {
          console.error(error);
        },
        () => {
          if (this.HeaderContent?.bt_textorimage) {
            //Image
            //this.headerText = '<img src="' + this.HeaderContent.bt_headerurl + '" width="80%" height = "80%" />';
            this.htmlHeader = this.HeaderContent.bt_headerurl;
          } else if (!this.HeaderContent?.bt_textorimage) {
            //Text
          }
        },
      );
  }

  getFooterData() {
    this.sharedapiverdedocumentsservice
      .verdeFooterTemplate({
        LegalEntity: this.userService.proxyUser.legalEntityId,
      })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          this.FooterContent = ret[0];
        },
        (error) => {
          console.error(error);
        },
        () => {
          if (this.FooterContent?.bt_textorimage) {
            //Image
            //this.footerText = '<img src="' + this.FooterContent?.bt_footerurl + '" width="100%" height = "80%" />';
            this.htmlFooter = this.FooterContent?.bt_footerurl;
          } else if (!this.FooterContent?.bt_textorimage) {
            //Text
          }
        },
      );
  }

  PopulateDocumentData(YearValue: any) {
    this.spinner.show('displayTemplateSpinnyBoi');
    this.wfhSummary = YearValue;
    this.sharedapiverdedocumentsservice
      .getVerdeDocumentsOne({
        legalEntityGuid: this.userService.proxyUser.legalEntityId,
        tempname: this.selectedDocument?.bt_templatename ?? '',
      })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          this.SingleDocument = ret[0];
          if (this.SingleDocument.allKeys) {
            this.SingleDocument.allKeys.forEach((t, index) => {
              let reWordedT = t.substring(1, t.length - 1);
              //Check user Service
              if (this.userService.proxyUser[reWordedT] != null) {
                let replaceWith = this.userService.proxyUser[reWordedT];
                this.SingleDocument.bt_templatecontent = this.SingleDocument?.bt_templatecontent?.replaceAll(t, replaceWith);
              }
              //Check WFH summary
              else if (this.wfhSummary && this.wfhSummary[reWordedT] != null) {
                let replaceWith = this.wfhSummary[reWordedT];
                this.SingleDocument.bt_templatecontent = this.SingleDocument?.bt_templatecontent?.replaceAll(t, replaceWith);
              } else if (reWordedT == 'Date') {
                this.SingleDocument.bt_templatecontent = this.SingleDocument?.bt_templatecontent?.replaceAll(t, moment().format('YYYY-MM-DD'));
              } else {
                this.SingleDocument.bt_templatecontent = this.SingleDocument?.bt_templatecontent?.replaceAll(t, '[NO DATA FOUND]');
              }
            });
          }

          this.editorContentValue = this.SingleDocument?.bt_templatecontent;
        },
        (error) => {
          console.error(error);
        },
        () => {
          this.spinner.hide('displayTemplateSpinnyBoi');

          this.modalService.open('DisplayDocModal');

          this.layout = this.SingleDocument?.bt_layout?.split(',');

          console.log(this.headerText);
          console.log(this.footerText);

          if (this.layout) {
            this.layout.forEach((y, index) => {
              if (y == PropertyType.Footer) {
                //this.editorContentValue = (this.editorContentValue ?? '') + this.footerText;
              } else if (y == PropertyType.Header) {
                //this.editorContentValue = this.headerText + (this.editorContentValue ?? '');
              }
            });

            setTimeout(() => {
              const HtmlTemplateContent = document.getElementById('HtmlTemplateContent');
              if (HtmlTemplateContent) {
                HtmlTemplateContent.innerHTML = this.editorContentValue ?? '';
              }
            }, 5);
          }
        },
      );
  }

  getAllTemplates() {
    this.spinner.show('WFHgridSpinner');
    this.sharedapiverdedocumentsservice
      .getVerdeDocumentsAll({
        legalEntityGuid: this.userService.proxyUser.legalEntityId,
      })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          this.allTemplatesGridData = ret;
          this.wfhDocsLoading = false;
        },
        (error) => {
          console.error(error);
          this.wfhDocsLoading = false;
        },
        () => {
          this.wfhDocsLoading = false;
          this.spinner.hide('WFHgridSpinner');
        },
      );
  }

  SaveThePdf(pdf: any) {
    console.log(pdf.element.nativeElement.innerHTML);
    pdf.saveAs(this.userService.proxyUser.fullNameWithEmpId + ' -- ' + this.selectedDocument?.bt_templatename + '.pdf');
  }

  refresh() {
    this.getFooterData();
    this.getHeaderData();
    this.getAllTemplates();
  }
}
