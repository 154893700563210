/* tslint:disable */
/* eslint-disable */
import { TasksBtTaskSource } from './tasks-bt-task-source';
import { TasksBtPriority } from './tasks-bt-priority';
export interface TasksTaskTemplateDto {
  bt_Approval?: null | boolean;
  bt_DefaultRole?: null | string;
  bt_FollowUp?: null | boolean;
  bt_FollowUpRole?: null | string;
  bt_LeadTimeHours?: null | number;
  bt_LegalEntity?: null | string;
  bt_TaskDescription?: null | string;
  bt_TaskHeader?: null | string;
  bt_TaskPriority?: TasksBtPriority;
  bt_TaskSource?: TasksBtTaskSource;
  bt_VerdeFunctionTechnicalName?: null | string;
  bt_VerdeTaskListTemplateId?: null | string;
}
