/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { VerdeRequest } from '../models/verde-request';
import { VerdeRequestType } from '../models/verde-request-type';

@Injectable({
  providedIn: 'root',
})
export class SharedApiVerdeRequestsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAllVerdeRequestsForEmployee
   */
  static readonly GetAllVerdeRequestsForEmployeePath = '/api/VerdeRequests/allrequestsforemployee/{userID}/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllVerdeRequestsForEmployee()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllVerdeRequestsForEmployee$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userID: string;
    legalEntityID: string;
  }): Observable<StrictHttpResponse<Array<VerdeRequest>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiVerdeRequestsService.GetAllVerdeRequestsForEmployeePath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('userID', params.userID, {});
      rb.path('legalEntityID', params.legalEntityID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VerdeRequest>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllVerdeRequestsForEmployee$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllVerdeRequestsForEmployee(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userID: string;
    legalEntityID: string;
  }): Observable<Array<VerdeRequest>> {

    return this.getAllVerdeRequestsForEmployee$Response(params).pipe(
      map((r: StrictHttpResponse<Array<VerdeRequest>>) => r.body as Array<VerdeRequest>)
    );
  }

  /**
   * Path part for operation getVerdeRequestManagerApprovals
   */
  static readonly GetVerdeRequestManagerApprovalsPath = '/api/VerdeRequests/verderequestmanagerapprovals/{managerID}/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVerdeRequestManagerApprovals()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVerdeRequestManagerApprovals$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    managerID: string;
    legalEntityID: string;
  }): Observable<StrictHttpResponse<Array<VerdeRequest>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiVerdeRequestsService.GetVerdeRequestManagerApprovalsPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('managerID', params.managerID, {});
      rb.path('legalEntityID', params.legalEntityID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VerdeRequest>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVerdeRequestManagerApprovals$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVerdeRequestManagerApprovals(params: {
    subscriptionKey?: string;
    APIURL?: string;
    managerID: string;
    legalEntityID: string;
  }): Observable<Array<VerdeRequest>> {

    return this.getVerdeRequestManagerApprovals$Response(params).pipe(
      map((r: StrictHttpResponse<Array<VerdeRequest>>) => r.body as Array<VerdeRequest>)
    );
  }

  /**
   * Path part for operation getAllRequestTypes
   */
  static readonly GetAllRequestTypesPath = '/api/VerdeRequests/alltypes/{email}/{environmentURL}/{slot}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllRequestTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllRequestTypes$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    email: string;
    environmentURL: string;
    slot: string;
  }): Observable<StrictHttpResponse<Array<VerdeRequestType>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiVerdeRequestsService.GetAllRequestTypesPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('email', params.email, {});
      rb.path('environmentURL', params.environmentURL, {});
      rb.path('slot', params.slot, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VerdeRequestType>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllRequestTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllRequestTypes(params: {
    subscriptionKey?: string;
    APIURL?: string;
    email: string;
    environmentURL: string;
    slot: string;
  }): Observable<Array<VerdeRequestType>> {

    return this.getAllRequestTypes$Response(params).pipe(
      map((r: StrictHttpResponse<Array<VerdeRequestType>>) => r.body as Array<VerdeRequestType>)
    );
  }

  /**
   * Path part for operation getAllRequestTypesNonGeneric
   */
  static readonly GetAllRequestTypesNonGenericPath = '/api/VerdeRequests/verderequesttypesnongeneric/{email}/{environmentURL}/{nonGenericType}/{slot}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllRequestTypesNonGeneric()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllRequestTypesNonGeneric$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    email: string;
    environmentURL: string;
    nonGenericType: string;
    slot: string;
  }): Observable<StrictHttpResponse<Array<VerdeRequestType>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiVerdeRequestsService.GetAllRequestTypesNonGenericPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('email', params.email, {});
      rb.path('environmentURL', params.environmentURL, {});
      rb.path('nonGenericType', params.nonGenericType, {});
      rb.path('slot', params.slot, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VerdeRequestType>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllRequestTypesNonGeneric$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllRequestTypesNonGeneric(params: {
    subscriptionKey?: string;
    APIURL?: string;
    email: string;
    environmentURL: string;
    nonGenericType: string;
    slot: string;
  }): Observable<Array<VerdeRequestType>> {

    return this.getAllRequestTypesNonGeneric$Response(params).pipe(
      map((r: StrictHttpResponse<Array<VerdeRequestType>>) => r.body as Array<VerdeRequestType>)
    );
  }

  /**
   * Path part for operation getVerdeRequestTypesNonGeneric
   */
  static readonly GetVerdeRequestTypesNonGenericPath = '/api/VerdeRequests/verderequesttypesnongenericnew/{roleID}/{legalEntityID}/{nonGenericType}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVerdeRequestTypesNonGeneric()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVerdeRequestTypesNonGeneric$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    roleID: string;
    legalEntityID: string;
    nonGenericType: string;
  }): Observable<StrictHttpResponse<Array<VerdeRequestType>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiVerdeRequestsService.GetVerdeRequestTypesNonGenericPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('roleID', params.roleID, {});
      rb.path('legalEntityID', params.legalEntityID, {});
      rb.path('nonGenericType', params.nonGenericType, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VerdeRequestType>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVerdeRequestTypesNonGeneric$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVerdeRequestTypesNonGeneric(params: {
    subscriptionKey?: string;
    APIURL?: string;
    roleID: string;
    legalEntityID: string;
    nonGenericType: string;
  }): Observable<Array<VerdeRequestType>> {

    return this.getVerdeRequestTypesNonGeneric$Response(params).pipe(
      map((r: StrictHttpResponse<Array<VerdeRequestType>>) => r.body as Array<VerdeRequestType>)
    );
  }

  /**
   * Path part for operation patchVerdeRequest
   */
  static readonly PatchVerdeRequestPath = '/api/VerdeRequests/patch/request';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchVerdeRequest()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchVerdeRequest$Response(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: VerdeRequest
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiVerdeRequestsService.PatchVerdeRequestPath, 'patch');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchVerdeRequest$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchVerdeRequest(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: VerdeRequest
  }): Observable<boolean> {

    return this.patchVerdeRequest$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation postVerdeRequest
   */
  static readonly PostVerdeRequestPath = '/api/VerdeRequests/post/request';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postVerdeRequest()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postVerdeRequest$Response(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: VerdeRequest
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiVerdeRequestsService.PostVerdeRequestPath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postVerdeRequest$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postVerdeRequest(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: VerdeRequest
  }): Observable<boolean> {

    return this.postVerdeRequest$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

}
