<!-- Rendom Button -->
<button id="showAssignModal"
        hidden
        (click)="getFleetMovement(fleetTransferAssignForm)"></button>
<!-- #region Assign Fleet Modal -->
<verde-modal id="newFleetTransferAssignModal"
             modalTitle="Assign Fleet"
             modalMinWidth="750"
             submitButton="Submit"
             (actionButton)="patchFleetAssignment()"
             (modalCancelled)="hideModal()"
             (modalSecondCancelled)="confirmRejection()"
             [hasSecondCancelButton]="true"
             secondCancelText="Reject"
             [hasSecondCancelConfirmation]="true"
             [secondCancelButtonDisabled]="disableRejectionButton"
             secondCancelConfirmationHeader="Confirm Rejection"
             secondCancelConfirmationMessage="Please confirm that you would like to reject this movement request"
             [submitActive]="fleetTransferAssignForm.valid && !actionAlreadyCompletedWarning"
             submitConfirmationMessage="Please confirm that you would like to assign this fleet item"
             cancelConfirmationMessage="Please confirm that you would like to cancel"
             [hasSubmitConfirmation]="true"
             [hasCancelConfirmation]="fleetTransferAssignForm.touched && !fleetTransferAssignForm.pristine">
  <form #fleetTransferAssignForm="ngForm"
        novalidate
        autocomplete="off"
        style="position: relative">
    <ngx-spinner [disableAnimation]="disableAnimation"
                 name="fleettransferassign"
                 bdColor="rgb(237, 237, 237)"
                 size="default"
                 color="#6264a7"
                 type="ball-clip-rotate"
                 [fullScreen]="false">

    </ngx-spinner>

    <div class="modal_grid"
         *ngIf="!actionAlreadyCompletedWarning">

      <div *ngIf="selectedTransfer?.odataToEmployee != null"
           class="form-group">
        <label for="assignToEmployee">To Employee:</label>
        <kendo-textbox [readonly]="true"
                       id="assignToEmployee"
                       name="assignToEmployee"
                       [ngModel]="selectedTransfer?.bt_ToEmployee?.bt_fullname">
        </kendo-textbox>
      </div>

      <div class="form-group">
        <label for="assignRequestReason">Reason For Request:</label>
        <kendo-textarea id="assignRequestReason"
                        name="assignRequestReason"
                        resizable="vertical"
                        [readonly]="true"
                        [ngModel]>
        </kendo-textarea>
      </div>

      <div class="form-group wide">
        <label for="assignFleetItem">Select Fleet Item:</label>
        <br />
        <kendo-dropdownlist [data]="allFleetItemsData"
                            textField="{{odataLocation - odataModel}}"
                            valueField="bthr_vehiclesid"
                            [filterable]="true"
                            (filterChange)="handleFilter($event)"
                            id="assignFleetItem"
                            name="assignFleetItem"
                            required
                            [ngModel]
                            #varAssignFleetItem="ngModel"
                            (valueChange)="setAssignFleetItemDropdownValue($event)">
          <ng-template *ngIf="assignFleetItemDropdownValue !== 'default'"
                       kendoDropDownListValueTemplate
                       let-dataItem>
            {{ dataItem?.odataLocation }} - {{dataItem?.bt_fleetno}} - {{ dataItem?.odataModel }} - {{
            dataItem?.bthr_licensenumber }} - {{dataItem?.odataClassification}}
          </ng-template>
          <ng-template kendoDropDownListItemTemplate
                       let-dataItem>
            {{ dataItem.odataLocation }} - {{dataItem.bt_fleetno}} - {{ dataItem.odataModel }} - {{
            dataItem.bthr_licensenumber }} - {{dataItem.odataClassification}}
          </ng-template>
        </kendo-dropdownlist>

        <!-- Validation -->
        <div class="alert alert-danger"
             *ngIf="varAssignFleetItem.touched && !varAssignFleetItem.valid">
          <div *ngIf="varAssignFleetItem.errors?.required">Field is required!</div>
        </div>
      </div>

    </div>
    <div *ngIf="actionAlreadyCompletedWarning">
      <p>This assignment has already been processed.</p>
    </div>
  </form>
</verde-modal>
<!-- #endregion Assign Fleet Modal -->
<!-- #region Confirm Rejection Modal -->
<verde-modal modalTitle="Provide Reason"
             id="confirmFleetTransferAssignRejectionModal"
             modalMaxWidth="400"
             submitButton="Submit"
             [submitActive]="fleetTransferAssignRejectionForm.valid"
             (actionButton)="patchRejectection(fleetTransferAssignRejectionForm)"
             (modalCancelled)="hideModal()"
             cancelConfirmationMessage="Please confirm that you would like to cancel"
             [hasCancelConfirmation]="fleetTransferAssignRejectionForm.touched && !fleetTransferAssignRejectionForm.pristine">
  <form #fleetTransferAssignRejectionForm="ngForm"
        novalidate
        autocomplete="off">
    <div class="form-group">
      <label for="confirmRejectionComment">Reason for rejection:</label>
      <kendo-textarea id="confirmRejectionComment"
                      name="confirmRejectionComment"
                      #varConfirmRejectionComment="ngModel"
                      maxlength="300"
                      resizable="vertical"
                      required
                      [ngModel]>
      </kendo-textarea>
      <!--Validation -->
      <div class="alert alert-danger"
           *ngIf="varConfirmRejectionComment.touched && !varConfirmRejectionComment.valid">
        <div *ngIf="varConfirmRejectionComment.errors?.required">Field is required!</div>
        <div *ngIf="varConfirmRejectionComment.errors?.minlength">Enter a detailed reason.</div>
      </div>
    </div>
  </form>
</verde-modal>
<!-- #endregion Confirm Rejection Modal -->
<!-- #region Confirmation Modal -->
<verde-modal modalTitle="Confirmation"
             id="confirmFleetTransferAssignModal"
             modalMaxWidth="400"
             [hasCancelButton]="false"
             submitButton="Continue"
             (actionButton)="onConfirmClick()">
  <div style="position: relative; height: 60px">
    <ngx-spinner [disableAnimation]="disableAnimation"
                 name="confirmfleettransferassign"
                 bdColor="rgb(237, 237, 237)"
                 size="default"
                 color="#6264a7"
                 type="ball-clip-rotate"
                 [fullScreen]="false">

    </ngx-spinner>

    <p>{{ confirmationMessage }}</p>
  </div>
</verde-modal>
<!-- #endregion Confirmation Modal -->