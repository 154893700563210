/* tslint:disable */
/* eslint-disable */
export interface EquipmentKits {
  '_bt_equipmentspec_value'?: null | string;
  '_bt_equipmentspectype_value'?: null | string;
  '_bt_equipmenttype_value'?: null | string;
  '_bt_equipmenttypeclass_value'?: null | string;
  '_bt_legalentity_value'?: null | string;
  bt_calculatedbudget?: null | number;
  bt_calculatedbudget_date?: null | string;
  bt_calculatedbudget_state?: null | number;
  bt_description?: null | string;
  bt_equipmentkit?: null | string;
  bt_equipmentkitid?: null | string;
  bt_totalitemsuniqueitems?: null | number;
  bt_totalitemsuniqueitems_date?: null | string;
  bt_totalitemsuniqueitems_state?: null | number;
  bt_totalkititems?: null | number;
  bt_totalkititems_date?: null | string;
  bt_totalkititems_state?: null | number;
  odataEquipmentSpec?: null | string;
  odataEquipmentSpecType?: null | string;
  odataEquipmentType?: null | string;
  odataEquipmentTypeClass?: null | string;
  odataLegalEntity?: null | string;
}
