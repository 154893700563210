/* tslint:disable */
/* eslint-disable */
import { BtPeriod } from './bt-period';
import { BthrPerformanceReviewCycle } from './bthr-performance-review-cycle';
import { User } from './user';
export interface PerformanceReviewsFiltered {
  '_bthr_employee_value'?: null | string;
  bt_Period?: BtPeriod;
  bt_attachmentfilename?: null | string;
  bt_commission?: null | boolean;
  bt_employeeapprovaldate?: null | string;
  bt_employeecompleted?: null | boolean;
  bt_employeerecallaccepted?: null | boolean;
  bt_employeerecallaccepteddate?: null | string;
  bt_kpi?: null | boolean;
  bt_managerapprovaldate?: null | string;
  bt_managercompleted?: null | boolean;
  bt_managerrecallrequest?: null | boolean;
  bt_managerrecallrequestdate?: null | string;
  bt_reviewenhancement?: null | boolean;
  bt_totalscore?: null | number;
  bthr_Employee?: User;
  bthr_PerformanceReviewCycle?: BthrPerformanceReviewCycle;
  bthr_employeeperformancereviewid?: null | string;
  bthr_reviewdate?: null | string;
  bthr_summaryofresults?: null | string;
  odataCommission?: null | string;
  odataEmployee?: null | string;
  odataEmployeeCompleted?: null | string;
  odataKPI?: null | string;
  odataManagerCompleted?: null | string;
}
