<div style="position: relative">
  <div style="overflow-x: auto;">
    <form #StructureForm="ngForm"
          novalidate
          autocomplete="off">
      <div class="modal_grid2">
        <!-- StructureEntity  Lookup to Legal Entity-->
        <div class="form-group">
          <label for="StructureEntityDropdown">Structure Entity:</label>
          <kendo-dropdownlist [data]="StructureEntityData"
                              textField="bthr_legalentityname"
                              valueField="bthr_legalentitiesid"
                              id="StructureEntityDropdown"
                              name="StructureEntityDropdown"
                              #varStructureEntityDropdown="ngModel"
                              required
                              [ngModel]
                              (valueChange)="SetStructureEntity($event, varAllDivisionsDropdown, varDivisionDropdown,varAllDepartmentsDropdown,varDepartmentDropdown)">
          </kendo-dropdownlist>
        </div>
        <!-- AllDivisions  Yes/No-->
        <div class="form-group">
          <label for="AllDivisionsDropdown">All Divisions:</label>
          <kendo-dropdownlist [data]="AllDivisionsData"
                              textField="text"
                              valueField="id"
                              id="AllDivisionsDropdown"
                              name="AllDivisionsDropdown"
                              #varAllDivisionsDropdown="ngModel"
                              required
                              [ngModel]
                              (valueChange)="SetAllDivisions($event, varDivisionDropdown,varAllDepartmentsDropdown,varDepartmentDropdown)"
                              [disabled]="AllDivisionDisabled">
          </kendo-dropdownlist>
        </div>
        <!-- Division Lookup to Division-->
        <div class="form-group"
             [hidden]="AllDivisionChoice">
          <label for="DivisionDropdown">Division:</label>
          <kendo-dropdownlist [data]="FilteredDivisionData"
                              textField="bthr_division"
                              valueField="bthr_divisionid"
                              id="DivisionDropdown"
                              name="DivisionDropdown"
                              #varDivisionDropdown="ngModel"
                              required
                              [ngModel]
                              (valueChange)="SetDivision($event,varAllDepartmentsDropdown,varDepartmentDropdown)"
                              [disabled]="DivisionDisabled">
          </kendo-dropdownlist>
        </div>
        <!-- AllDepartments  Yes/No-->
        <div class="form-group"
             [hidden]="AllDivisionChoice">
          <label for="AllDepartmentsDropdown">All Departments:</label>
          <kendo-dropdownlist [data]="AllDepartmentsData"
                              textField="text"
                              valueField="id"
                              id="AllDepartmentsDropdown"
                              name="AllDepartmentsDropdown"
                              #varAllDepartmentsDropdown="ngModel"
                              required
                              [ngModel]
                              (valueChange)="SetAllDepartments($event,varDepartmentDropdown)"
                              [disabled]="AllDepartmentDisabled">
          </kendo-dropdownlist>
        </div>
        <!-- Department Lookup to Department-->
        <div class="form-group"
             [hidden]="AllDepartmentChoice">
          <label for="DepartmentDropdown">Department:</label>
          <kendo-dropdownlist [data]="FilteredDepartmentData"
                              textField="bthr_department"
                              valueField="bthr_departmentid"
                              id="DepartmentDropdown"
                              name="DepartmentDropdown"
                              #varDepartmentDropdown="ngModel"
                              required
                              [ngModel]
                              (valueChange)="SetDepartment($event)"
                              [disabled]="DepartmentDisabled">
          </kendo-dropdownlist>
        </div>
      </div>

    </form>
  </div>

</div>
<button kendoButton
        themeColor="primary"
        (click)="SubmitStructureAccess(StructureForm)"
        style=" position: absolute; bottom: 5%; right: 5%;">
  Submit
</button>

<verde-modal id="Confirmation"
             modalTitle="Confirm"
             submitButton="Confirm"
             (actionButton)="Submit()">

  <p>Please confirm that you would like to create this record.</p>

</verde-modal>