/* tslint:disable */
/* eslint-disable */
import { StructureStructureEmployee } from './structure-structure-employee';
import { StructureStructurePositionSeat } from './structure-structure-position-seat';
import { StructureBtPositionseat } from './structure-bt-positionseat';
export interface StructureStructureLegalEntity {
  hasChildren?: null | boolean;
  id?: null | string;
  managingDirector?: StructureStructureEmployee;
  mdPositionSeat?: StructureStructurePositionSeat;
  mdSeatStatus?: StructureBtPositionseat;
  name?: null | string;
  parentLegalEntity?: StructureStructureLegalEntity;
}
