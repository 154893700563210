/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { SharedApiAccreditationAuthoritiesService } from './services/shared-api-accreditation-authorities.service';
import { SharedApiAddressesService } from './services/shared-api-addresses.service';
import { SharedApiAllEducationalInstitutionsService } from './services/shared-api-all-educational-institutions.service';
import { SharedApiAllLanguagesService } from './services/shared-api-all-languages.service';
import { SharedApiAllMedicalSchemesService } from './services/shared-api-all-medical-schemes.service';
import { SharedApiAllowancesService } from './services/shared-api-allowances.service';
import { SharedApiAllQualificationsService } from './services/shared-api-all-qualifications.service';
import { SharedApiAnnotationService } from './services/shared-api-annotation.service';
import { SharedApiBankAccountsService } from './services/shared-api-bank-accounts.service';
import { SharedApiBanksAndBranchesService } from './services/shared-api-banks-and-branches.service';
import { SharedApiBookingAvailabilitiesService } from './services/shared-api-booking-availabilities.service';
import { SharedApiBookingDurationService } from './services/shared-api-booking-duration.service';
import { SharedApiBookingItemsService } from './services/shared-api-booking-items.service';
import { SharedApiBookingLocationsService } from './services/shared-api-booking-locations.service';
import { SharedApiBookingOfficesService } from './services/shared-api-booking-offices.service';
import { SharedApiBookingPoolVehicleAvailabilitesService } from './services/shared-api-booking-pool-vehicle-availabilites.service';
import { SharedApiBookingsService } from './services/shared-api-bookings.service';
import { SharedApiBookingSubOfficesService } from './services/shared-api-booking-sub-offices.service';
import { SharedApiBookingTypesService } from './services/shared-api-booking-types.service';
import { SharedApiCalendarService } from './services/shared-api-calendar.service';
import { SharedApiCardsService } from './services/shared-api-cards.service';
import { SharedApiConfigService } from './services/shared-api-config.service';
import { SharedApiCountersService } from './services/shared-api-counters.service';
import { SharedApiCountriesService } from './services/shared-api-countries.service';
import { SharedApiCriminalRecordsService } from './services/shared-api-criminal-records.service';
import { SharedApiDisciplinaryService } from './services/shared-api-disciplinary.service';
import { SharedApiDriversLicensesService } from './services/shared-api-drivers-licenses.service';
import { SharedApiEmployeeDependentsService } from './services/shared-api-employee-dependents.service';
import { SharedApiEmployeeSeatsService } from './services/shared-api-employee-seats.service';
import { SharedApiEngageService } from './services/shared-api-engage.service';
import { SharedApiEquipmentService } from './services/shared-api-equipment.service';
import { SharedApiFileService } from './services/shared-api-file.service';
import { SharedApiFleetMovementService } from './services/shared-api-fleet-movement.service';
import { SharedApiFundsService } from './services/shared-api-funds.service';
import { SharedApiGiftsService } from './services/shared-api-gifts.service';
import { SharedApiHrPortalService } from './services/shared-api-hr-portal.service';
import { SharedApiIdentityDocumentsService } from './services/shared-api-identity-documents.service';
import { SharedApiIncidentsService } from './services/shared-api-incidents.service';
import { SharedApiLanguageProfService } from './services/shared-api-language-prof.service';
import { SharedApiLegalEntitiesService } from './services/shared-api-legal-entities.service';
import { SharedApiMedicalRecordsService } from './services/shared-api-medical-records.service';
import { SharedApiMedicalSchemeService } from './services/shared-api-medical-scheme.service';
import { SharedApiMembershipsService } from './services/shared-api-memberships.service';
import { SharedApiOrganisationsService } from './services/shared-api-organisations.service';
import { SharedApiPayrollService } from './services/shared-api-payroll.service';
import { SharedApiPeerReviewService } from './services/shared-api-peer-review.service';
import { SharedApiPerformanceReviewService } from './services/shared-api-performance-review.service';
import { SharedApiPermitsService } from './services/shared-api-permits.service';
import { SharedApiQualificationsService } from './services/shared-api-qualifications.service';
import { SharedApiRiskManagementService } from './services/shared-api-risk-management.service';
import { SharedApiStructureService } from './services/shared-api-structure.service';
import { SharedApiSuccessionsService } from './services/shared-api-successions.service';
import { SharedApiTaskService } from './services/shared-api-task.service';
import { SharedApiTaxNaturesService } from './services/shared-api-tax-natures.service';
import { SharedApiTaxRecordsService } from './services/shared-api-tax-records.service';
import { SharedApiTimeOffService } from './services/shared-api-time-off.service';
import { SharedApiTrainingLinksService } from './services/shared-api-training-links.service';
import { SharedApiTriggerService } from './services/shared-api-trigger.service';
import { SharedApiUserService } from './services/shared-api-user.service';
import { SharedApiVaccinationsService } from './services/shared-api-vaccinations.service';
import { SharedApiVaccinesService } from './services/shared-api-vaccines.service';
import { SharedApiVehiclesService } from './services/shared-api-vehicles.service';
import { SharedApiVerdeApprovalsService } from './services/shared-api-verde-approvals.service';
import { SharedApiVerdeDocumentsService } from './services/shared-api-verde-documents.service';
import { SharedApiVerdeErrorLogService } from './services/shared-api-verde-error-log.service';
import { SharedApiVerdeLearnCalloutsService } from './services/shared-api-verde-learn-callouts.service';
import { SharedApiVerdePoliciesService } from './services/shared-api-verde-policies.service';
import { SharedApiVerdeRequestsService } from './services/shared-api-verde-requests.service';
import { SharedApiVerdeTimeEntryService } from './services/shared-api-verde-time-entry.service';
import { SharedApiVersionService } from './services/shared-api-version.service';
import { SharedApiVotingService } from './services/shared-api-voting.service';
import { SharedApiVulnerabilityAssessmentsService } from './services/shared-api-vulnerability-assessments.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    SharedApiAccreditationAuthoritiesService,
    SharedApiAddressesService,
    SharedApiAllEducationalInstitutionsService,
    SharedApiAllLanguagesService,
    SharedApiAllMedicalSchemesService,
    SharedApiAllowancesService,
    SharedApiAllQualificationsService,
    SharedApiAnnotationService,
    SharedApiBankAccountsService,
    SharedApiBanksAndBranchesService,
    SharedApiBookingAvailabilitiesService,
    SharedApiBookingDurationService,
    SharedApiBookingItemsService,
    SharedApiBookingLocationsService,
    SharedApiBookingOfficesService,
    SharedApiBookingPoolVehicleAvailabilitesService,
    SharedApiBookingsService,
    SharedApiBookingSubOfficesService,
    SharedApiBookingTypesService,
    SharedApiCalendarService,
    SharedApiCardsService,
    SharedApiConfigService,
    SharedApiCountersService,
    SharedApiCountriesService,
    SharedApiCriminalRecordsService,
    SharedApiDisciplinaryService,
    SharedApiDriversLicensesService,
    SharedApiEmployeeDependentsService,
    SharedApiEmployeeSeatsService,
    SharedApiEngageService,
    SharedApiEquipmentService,
    SharedApiFileService,
    SharedApiFleetMovementService,
    SharedApiFundsService,
    SharedApiGiftsService,
    SharedApiHrPortalService,
    SharedApiIdentityDocumentsService,
    SharedApiIncidentsService,
    SharedApiLanguageProfService,
    SharedApiLegalEntitiesService,
    SharedApiMedicalRecordsService,
    SharedApiMedicalSchemeService,
    SharedApiMembershipsService,
    SharedApiOrganisationsService,
    SharedApiPayrollService,
    SharedApiPeerReviewService,
    SharedApiPerformanceReviewService,
    SharedApiPermitsService,
    SharedApiQualificationsService,
    SharedApiRiskManagementService,
    SharedApiStructureService,
    SharedApiSuccessionsService,
    SharedApiTaskService,
    SharedApiTaxNaturesService,
    SharedApiTaxRecordsService,
    SharedApiTimeOffService,
    SharedApiTrainingLinksService,
    SharedApiTriggerService,
    SharedApiUserService,
    SharedApiVaccinationsService,
    SharedApiVaccinesService,
    SharedApiVehiclesService,
    SharedApiVerdeApprovalsService,
    SharedApiVerdeDocumentsService,
    SharedApiVerdeErrorLogService,
    SharedApiVerdeLearnCalloutsService,
    SharedApiVerdePoliciesService,
    SharedApiVerdeRequestsService,
    SharedApiVerdeTimeEntryService,
    SharedApiVersionService,
    SharedApiVotingService,
    SharedApiVulnerabilityAssessmentsService,
    ApiConfiguration
  ],
})
export class SharedApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<SharedApiModule> {
    return {
      ngModule: SharedApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: SharedApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('SharedApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
