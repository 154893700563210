/* tslint:disable */
/* eslint-disable */
import { DelegationsSharedLookupExtrapolate } from './delegations-shared-lookup-extrapolate';
import { DelegationsBtGapAnalysisStatus } from './delegations-bt-gap-analysis-status';
import { DelegationsBtReadyin } from './delegations-bt-readyin';
import { DelegationsBtRetentionPotential } from './delegations-bt-retention-potential';
import { DelegationsBtRetirementStatus } from './delegations-bt-retirement-status';
import { DelegationsBtSuccessorType } from './delegations-bt-successor-type';
import { DelegationsBtTrainingPlanDeveloped } from './delegations-bt-training-plan-developed';
import { DelegationsBtMobilitytransferpotential } from './delegations-bt-mobilitytransferpotential';
export interface DelegationsSuccessionPlanSuccessor {
  bt_comment?: null | string;
  bt_employeeentity?: DelegationsSharedLookupExtrapolate;
  bt_employeefullname?: null | string;
  bt_employeeid?: DelegationsSharedLookupExtrapolate;
  bt_externaltrainingcomments?: null | string;
  bt_externaltrainingrequired?: null | boolean;
  bt_gapanalysisstatus?: DelegationsBtGapAnalysisStatus;
  bt_internaltrainingcomments?: null | string;
  bt_internaltrainingrequired?: null | boolean;
  bt_lastreviewdate?: null | string;
  bt_mobilitytransferpotential?: DelegationsBtMobilitytransferpotential;
  bt_name?: null | string;
  bt_priorityno?: null | number;
  bt_readyin?: DelegationsBtReadyin;
  bt_readynow?: null | boolean;
  bt_retentionpotential?: DelegationsBtRetentionPotential;
  bt_retirementstatus?: DelegationsBtRetirementStatus;
  bt_successoremployeeid?: DelegationsSharedLookupExtrapolate;
  bt_successorfullname?: null | string;
  bt_successorlegalentity?: DelegationsSharedLookupExtrapolate;
  bt_successortype?: DelegationsBtSuccessorType;
  bt_successplanid?: null | string;
  bt_trainingplanattachment_name?: null | string;
  bt_trainingplanattachmentname?: null | string;
  bt_trainingplandeveloped?: DelegationsBtTrainingPlanDeveloped;
  trainingPlanBase64?: null | string;
}
