/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Successions } from '../models/successions';
import { SuccessionsManager } from '../models/successions-manager';

@Injectable({
  providedIn: 'root',
})
export class SharedApiSuccessionsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getSuccessionPlans
   */
  static readonly GetSuccessionPlansPath = '/api/Successions/plans/{userID}/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSuccessionPlans()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSuccessionPlans$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userID: string;
    legalEntityID: string;
  }): Observable<StrictHttpResponse<Array<Successions>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiSuccessionsService.GetSuccessionPlansPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('userID', params.userID, {});
      rb.path('legalEntityID', params.legalEntityID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Successions>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSuccessionPlans$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSuccessionPlans(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userID: string;
    legalEntityID: string;
  }): Observable<Array<Successions>> {

    return this.getSuccessionPlans$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Successions>>) => r.body as Array<Successions>)
    );
  }

  /**
   * Path part for operation getSuccessionPlanManagers
   */
  static readonly GetSuccessionPlanManagersPath = '/api/Successions/managers/{userID}/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSuccessionPlanManagers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSuccessionPlanManagers$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userID: string;
    legalEntityID: string;
  }): Observable<StrictHttpResponse<Array<SuccessionsManager>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiSuccessionsService.GetSuccessionPlanManagersPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('userID', params.userID, {});
      rb.path('legalEntityID', params.legalEntityID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SuccessionsManager>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSuccessionPlanManagers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSuccessionPlanManagers(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userID: string;
    legalEntityID: string;
  }): Observable<Array<SuccessionsManager>> {

    return this.getSuccessionPlanManagers$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SuccessionsManager>>) => r.body as Array<SuccessionsManager>)
    );
  }

  /**
   * Path part for operation patchSuccessor
   */
  static readonly PatchSuccessorPath = '/api/Successions/patch';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchSuccessor()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchSuccessor$Response(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: Successions
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiSuccessionsService.PatchSuccessorPath, 'patch');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchSuccessor$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchSuccessor(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: Successions
  }): Observable<boolean> {

    return this.patchSuccessor$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation deleteSuccessor
   */
  static readonly DeleteSuccessorPath = '/api/Successions/delete/{recordID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteSuccessor()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSuccessor$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    recordID: string;
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiSuccessionsService.DeleteSuccessorPath, 'delete');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('recordID', params.recordID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteSuccessor$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSuccessor(params: {
    subscriptionKey?: string;
    APIURL?: string;
    recordID: string;
  }): Observable<boolean> {

    return this.deleteSuccessor$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation postSuccessor
   */
  static readonly PostSuccessorPath = '/api/Successions/post';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postSuccessor()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postSuccessor$Response(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: Successions
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiSuccessionsService.PostSuccessorPath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postSuccessor$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postSuccessor(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: Successions
  }): Observable<boolean> {

    return this.postSuccessor$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation changeSuccessionPermissionsFunction
   */
  static readonly ChangeSuccessionPermissionsFunctionPath = '/api/Successions/function/{environmentURL}/{slot}/{userID}/{legalEntityID}/{activationType}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changeSuccessionPermissionsFunction()` instead.
   *
   * This method doesn't expect any request body.
   */
  changeSuccessionPermissionsFunction$Response(params: {
    environmentURL: string;
    slot: string;
    userID: string;
    legalEntityID: string;
    activationType: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiSuccessionsService.ChangeSuccessionPermissionsFunctionPath, 'get');
    if (params) {
      rb.path('environmentURL', params.environmentURL, {});
      rb.path('slot', params.slot, {});
      rb.path('userID', params.userID, {});
      rb.path('legalEntityID', params.legalEntityID, {});
      rb.path('activationType', params.activationType, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `changeSuccessionPermissionsFunction$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  changeSuccessionPermissionsFunction(params: {
    environmentURL: string;
    slot: string;
    userID: string;
    legalEntityID: string;
    activationType: string;
  }): Observable<void> {

    return this.changeSuccessionPermissionsFunction$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
