/* tslint:disable */
/* eslint-disable */
export interface DocumentTaxYearAndPeriod {
  bt_TaxYear?: null | string;
  bt_periodend?: null | string;
  bt_periodno?: null | number;
  bt_periodstart?: null | string;
  bt_taxyearperiod?: null | string;
  bt_taxyearperiodid?: null | string;
}
