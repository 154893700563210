/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ProfileBaseService } from '../profile-base-service';
import { ProfileApiConfiguration } from '../profile-api-configuration';
import { ProfileStrictHttpResponse } from '../profile-strict-http-response';
import { ProfileRequestBuilder } from '../profile-request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ProfileAllVaccines } from '../models/profile-all-vaccines';
import { ProfileDriversLicense } from '../models/profile-drivers-license';
import { ProfileDynamicFormJson } from '../models/profile-dynamic-form-json';
import { ProfileGetAllVaccinesQuery } from '../models/profile-get-all-vaccines-query';
import { ProfileGetDriversLicenseQuery } from '../models/profile-get-drivers-license-query';
import { ProfileGetDynamicFormQuery } from '../models/profile-get-dynamic-form-query';
import { ProfileGetMyProfileFieldsQuery } from '../models/profile-get-my-profile-fields-query';
import { ProfileGetMyProfileGroupsQuery } from '../models/profile-get-my-profile-groups-query';
import { ProfileGetObjectionReasonsQuery } from '../models/profile-get-objection-reasons-query';
import { ProfileGetProfileQuery } from '../models/profile-get-profile-query';
import { ProfileGetRiskQuery } from '../models/profile-get-risk-query';
import { ProfileGetVaccinationSummariesQuery } from '../models/profile-get-vaccination-summaries-query';
import { ProfileGetVaccinationTypesQuery } from '../models/profile-get-vaccination-types-query';
import { ProfileGetVaccinationsQuery } from '../models/profile-get-vaccinations-query';
import { ProfileGetVaccineBoostersQuery } from '../models/profile-get-vaccine-boosters-query';
import { ProfileGetVulnerabilityAssessmentsQuery } from '../models/profile-get-vulnerability-assessments-query';
import { ProfileMyProfileFields } from '../models/profile-my-profile-fields';
import { ProfileMyProfileGroups } from '../models/profile-my-profile-groups';
import { ProfileObjectionReasons } from '../models/profile-objection-reasons';
import { ProfileRisk } from '../models/profile-risk';
import { ProfileUpdateDynamicCommand } from '../models/profile-update-dynamic-command';
import { ProfileUpdateProfileCommand } from '../models/profile-update-profile-command';
import { ProfileVaccinationSummary } from '../models/profile-vaccination-summary';
import { ProfileVaccinationTypes } from '../models/profile-vaccination-types';
import { ProfileVaccinations } from '../models/profile-vaccinations';
import { ProfileVaccineBoosters } from '../models/profile-vaccine-boosters';
import { ProfileVerdeEmployee } from '../models/profile-verde-employee';
import { ProfileVulnerabilityAssessments } from '../models/profile-vulnerability-assessments';

@Injectable({
  providedIn: 'root',
})
export class ProfileApiProfileService extends ProfileBaseService {
  constructor(config: ProfileApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation patchuser
   */
  static readonly PatchuserPath = '/api/Profile/patch/user';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchuser()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchuser$Response(params?: { tenantId?: string; body?: ProfileUpdateProfileCommand }): Observable<ProfileStrictHttpResponse<boolean>> {
    const rb = new ProfileRequestBuilder(this.rootUrl, ProfileApiProfileService.PatchuserPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as ProfileStrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchuser$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchuser(params?: { tenantId?: string; body?: ProfileUpdateProfileCommand }): Observable<boolean> {
    return this.patchuser$Response(params).pipe(map((r: ProfileStrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation getDynamicForm
   */
  static readonly GetDynamicFormPath = '/api/Profile/dynamicForm';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDynamicForm()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getDynamicForm$Response(params?: {
    tenantId?: string;
    body?: ProfileGetDynamicFormQuery;
  }): Observable<ProfileStrictHttpResponse<Array<ProfileDynamicFormJson>>> {
    const rb = new ProfileRequestBuilder(this.rootUrl, ProfileApiProfileService.GetDynamicFormPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as ProfileStrictHttpResponse<Array<ProfileDynamicFormJson>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDynamicForm$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getDynamicForm(params?: { tenantId?: string; body?: ProfileGetDynamicFormQuery }): Observable<Array<ProfileDynamicFormJson>> {
    return this.getDynamicForm$Response(params).pipe(
      map((r: ProfileStrictHttpResponse<Array<ProfileDynamicFormJson>>) => r.body as Array<ProfileDynamicFormJson>),
    );
  }

  /**
   * Path part for operation getVaccinations
   */
  static readonly GetVaccinationsPath = '/api/Profile/vaccinations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVaccinations()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getVaccinations$Response(params?: {
    tenantId?: string;
    body?: ProfileGetVaccinationsQuery;
  }): Observable<ProfileStrictHttpResponse<Array<ProfileVaccinations>>> {
    const rb = new ProfileRequestBuilder(this.rootUrl, ProfileApiProfileService.GetVaccinationsPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as ProfileStrictHttpResponse<Array<ProfileVaccinations>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVaccinations$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getVaccinations(params?: { tenantId?: string; body?: ProfileGetVaccinationsQuery }): Observable<Array<ProfileVaccinations>> {
    return this.getVaccinations$Response(params).pipe(map((r: ProfileStrictHttpResponse<Array<ProfileVaccinations>>) => r.body as Array<ProfileVaccinations>));
  }

  /**
   * Path part for operation getAllVaccines
   */
  static readonly GetAllVaccinesPath = '/api/Profile/allVaccinations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllVaccines()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAllVaccines$Response(params?: { tenantId?: string; body?: ProfileGetAllVaccinesQuery }): Observable<ProfileStrictHttpResponse<Array<ProfileAllVaccines>>> {
    const rb = new ProfileRequestBuilder(this.rootUrl, ProfileApiProfileService.GetAllVaccinesPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as ProfileStrictHttpResponse<Array<ProfileAllVaccines>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllVaccines$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAllVaccines(params?: { tenantId?: string; body?: ProfileGetAllVaccinesQuery }): Observable<Array<ProfileAllVaccines>> {
    return this.getAllVaccines$Response(params).pipe(map((r: ProfileStrictHttpResponse<Array<ProfileAllVaccines>>) => r.body as Array<ProfileAllVaccines>));
  }

  /**
   * Path part for operation getDriversLicense
   */
  static readonly GetDriversLicensePath = '/api/Profile/itemsbylocation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDriversLicense()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getDriversLicense$Response(params?: {
    tenantId?: string;
    body?: ProfileGetDriversLicenseQuery;
  }): Observable<ProfileStrictHttpResponse<Array<ProfileDriversLicense>>> {
    const rb = new ProfileRequestBuilder(this.rootUrl, ProfileApiProfileService.GetDriversLicensePath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as ProfileStrictHttpResponse<Array<ProfileDriversLicense>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDriversLicense$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getDriversLicense(params?: { tenantId?: string; body?: ProfileGetDriversLicenseQuery }): Observable<Array<ProfileDriversLicense>> {
    return this.getDriversLicense$Response(params).pipe(
      map((r: ProfileStrictHttpResponse<Array<ProfileDriversLicense>>) => r.body as Array<ProfileDriversLicense>),
    );
  }

  /**
   * Path part for operation getObjectionReasons
   */
  static readonly GetObjectionReasonsPath = '/api/Profile/objectionReasons';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getObjectionReasons()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getObjectionReasons$Response(params?: {
    tenantId?: string;
    body?: ProfileGetObjectionReasonsQuery;
  }): Observable<ProfileStrictHttpResponse<Array<ProfileObjectionReasons>>> {
    const rb = new ProfileRequestBuilder(this.rootUrl, ProfileApiProfileService.GetObjectionReasonsPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as ProfileStrictHttpResponse<Array<ProfileObjectionReasons>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getObjectionReasons$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getObjectionReasons(params?: { tenantId?: string; body?: ProfileGetObjectionReasonsQuery }): Observable<Array<ProfileObjectionReasons>> {
    return this.getObjectionReasons$Response(params).pipe(
      map((r: ProfileStrictHttpResponse<Array<ProfileObjectionReasons>>) => r.body as Array<ProfileObjectionReasons>),
    );
  }

  /**
   * Path part for operation getProfileDetails
   */
  static readonly GetProfileDetailsPath = '/api/Profile/profileDetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProfileDetails()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getProfileDetails$Response(params?: {
    tenantId?: string;
    body?: ProfileGetProfileQuery;
  }): Observable<ProfileStrictHttpResponse<Array<ProfileVerdeEmployee>>> {
    const rb = new ProfileRequestBuilder(this.rootUrl, ProfileApiProfileService.GetProfileDetailsPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as ProfileStrictHttpResponse<Array<ProfileVerdeEmployee>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getProfileDetails$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getProfileDetails(params?: { tenantId?: string; body?: ProfileGetProfileQuery }): Observable<Array<ProfileVerdeEmployee>> {
    return this.getProfileDetails$Response(params).pipe(
      map((r: ProfileStrictHttpResponse<Array<ProfileVerdeEmployee>>) => r.body as Array<ProfileVerdeEmployee>),
    );
  }

  /**
   * Path part for operation getRisk
   */
  static readonly GetRiskPath = '/api/Profile/risk';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRisk()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getRisk$Response(params?: { tenantId?: string; body?: ProfileGetRiskQuery }): Observable<ProfileStrictHttpResponse<Array<ProfileRisk>>> {
    const rb = new ProfileRequestBuilder(this.rootUrl, ProfileApiProfileService.GetRiskPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as ProfileStrictHttpResponse<Array<ProfileRisk>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRisk$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getRisk(params?: { tenantId?: string; body?: ProfileGetRiskQuery }): Observable<Array<ProfileRisk>> {
    return this.getRisk$Response(params).pipe(map((r: ProfileStrictHttpResponse<Array<ProfileRisk>>) => r.body as Array<ProfileRisk>));
  }

  /**
   * Path part for operation getVaccinationSummaries
   */
  static readonly GetVaccinationSummariesPath = '/api/Profile/vaccinationSummaries';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVaccinationSummaries()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getVaccinationSummaries$Response(params?: {
    tenantId?: string;
    body?: ProfileGetVaccinationSummariesQuery;
  }): Observable<ProfileStrictHttpResponse<Array<ProfileVaccinationSummary>>> {
    const rb = new ProfileRequestBuilder(this.rootUrl, ProfileApiProfileService.GetVaccinationSummariesPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as ProfileStrictHttpResponse<Array<ProfileVaccinationSummary>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVaccinationSummaries$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getVaccinationSummaries(params?: { tenantId?: string; body?: ProfileGetVaccinationSummariesQuery }): Observable<Array<ProfileVaccinationSummary>> {
    return this.getVaccinationSummaries$Response(params).pipe(
      map((r: ProfileStrictHttpResponse<Array<ProfileVaccinationSummary>>) => r.body as Array<ProfileVaccinationSummary>),
    );
  }

  /**
   * Path part for operation getVaccinationTypes
   */
  static readonly GetVaccinationTypesPath = '/api/Profile/vaccinationTypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVaccinationTypes()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getVaccinationTypes$Response(params?: {
    tenantId?: string;
    body?: ProfileGetVaccinationTypesQuery;
  }): Observable<ProfileStrictHttpResponse<Array<ProfileVaccinationTypes>>> {
    const rb = new ProfileRequestBuilder(this.rootUrl, ProfileApiProfileService.GetVaccinationTypesPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as ProfileStrictHttpResponse<Array<ProfileVaccinationTypes>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVaccinationTypes$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getVaccinationTypes(params?: { tenantId?: string; body?: ProfileGetVaccinationTypesQuery }): Observable<Array<ProfileVaccinationTypes>> {
    return this.getVaccinationTypes$Response(params).pipe(
      map((r: ProfileStrictHttpResponse<Array<ProfileVaccinationTypes>>) => r.body as Array<ProfileVaccinationTypes>),
    );
  }

  /**
   * Path part for operation getVaccineBoosters
   */
  static readonly GetVaccineBoostersPath = '/api/Profile/vaccineBoosters';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVaccineBoosters()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getVaccineBoosters$Response(params?: {
    tenantId?: string;
    body?: ProfileGetVaccineBoostersQuery;
  }): Observable<ProfileStrictHttpResponse<Array<ProfileVaccineBoosters>>> {
    const rb = new ProfileRequestBuilder(this.rootUrl, ProfileApiProfileService.GetVaccineBoostersPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as ProfileStrictHttpResponse<Array<ProfileVaccineBoosters>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVaccineBoosters$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getVaccineBoosters(params?: { tenantId?: string; body?: ProfileGetVaccineBoostersQuery }): Observable<Array<ProfileVaccineBoosters>> {
    return this.getVaccineBoosters$Response(params).pipe(
      map((r: ProfileStrictHttpResponse<Array<ProfileVaccineBoosters>>) => r.body as Array<ProfileVaccineBoosters>),
    );
  }

  /**
   * Path part for operation getVulnerabilityAssessments
   */
  static readonly GetVulnerabilityAssessmentsPath = '/api/Profile/vulnerabilityAssessments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVulnerabilityAssessments()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getVulnerabilityAssessments$Response(params?: {
    tenantId?: string;
    body?: ProfileGetVulnerabilityAssessmentsQuery;
  }): Observable<ProfileStrictHttpResponse<Array<ProfileVulnerabilityAssessments>>> {
    const rb = new ProfileRequestBuilder(this.rootUrl, ProfileApiProfileService.GetVulnerabilityAssessmentsPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as ProfileStrictHttpResponse<Array<ProfileVulnerabilityAssessments>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVulnerabilityAssessments$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getVulnerabilityAssessments(params?: {
    tenantId?: string;
    body?: ProfileGetVulnerabilityAssessmentsQuery;
  }): Observable<Array<ProfileVulnerabilityAssessments>> {
    return this.getVulnerabilityAssessments$Response(params).pipe(
      map((r: ProfileStrictHttpResponse<Array<ProfileVulnerabilityAssessments>>) => r.body as Array<ProfileVulnerabilityAssessments>),
    );
  }

  /**
   * Path part for operation getMyProfile
   */
  static readonly GetMyProfilePath = '/api/Profile/myProfile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMyProfile()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getMyProfile$Response(params?: {
    tenantId?: string;
    body?: ProfileGetMyProfileFieldsQuery;
  }): Observable<ProfileStrictHttpResponse<Array<ProfileMyProfileFields>>> {
    const rb = new ProfileRequestBuilder(this.rootUrl, ProfileApiProfileService.GetMyProfilePath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as ProfileStrictHttpResponse<Array<ProfileMyProfileFields>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getMyProfile$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getMyProfile(params?: { tenantId?: string; body?: ProfileGetMyProfileFieldsQuery }): Observable<Array<ProfileMyProfileFields>> {
    return this.getMyProfile$Response(params).pipe(
      map((r: ProfileStrictHttpResponse<Array<ProfileMyProfileFields>>) => r.body as Array<ProfileMyProfileFields>),
    );
  }

  /**
   * Path part for operation getMyProfileGroups
   */
  static readonly GetMyProfileGroupsPath = '/api/Profile/myProfileGroups';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMyProfileGroups()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getMyProfileGroups$Response(params?: {
    tenantId?: string;
    body?: ProfileGetMyProfileGroupsQuery;
  }): Observable<ProfileStrictHttpResponse<Array<ProfileMyProfileGroups>>> {
    const rb = new ProfileRequestBuilder(this.rootUrl, ProfileApiProfileService.GetMyProfileGroupsPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as ProfileStrictHttpResponse<Array<ProfileMyProfileGroups>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getMyProfileGroups$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getMyProfileGroups(params?: { tenantId?: string; body?: ProfileGetMyProfileGroupsQuery }): Observable<Array<ProfileMyProfileGroups>> {
    return this.getMyProfileGroups$Response(params).pipe(
      map((r: ProfileStrictHttpResponse<Array<ProfileMyProfileGroups>>) => r.body as Array<ProfileMyProfileGroups>),
    );
  }

  /**
   * Path part for operation patchDynamic
   */
  static readonly PatchDynamicPath = '/api/Profile/patch/dynamic';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchDynamic()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchDynamic$Response(params?: { tenantId?: string; body?: ProfileUpdateDynamicCommand }): Observable<ProfileStrictHttpResponse<boolean>> {
    const rb = new ProfileRequestBuilder(this.rootUrl, ProfileApiProfileService.PatchDynamicPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as ProfileStrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchDynamic$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchDynamic(params?: { tenantId?: string; body?: ProfileUpdateDynamicCommand }): Observable<boolean> {
    return this.patchDynamic$Response(params).pipe(map((r: ProfileStrictHttpResponse<boolean>) => r.body as boolean));
  }
}
