/* tslint:disable */
/* eslint-disable */
import { TimeActivityEmployeeApiModel } from './time-activity-employee-api-model';
import { User } from './user';
export interface TimeEntryActivityApiModel {
  '_bt_activityowner_value'?: null | string;
  '_bt_activityownerentity_value'?: null | string;
  '_bt_grouping_value'?: null | string;
  '_bt_legalentity_value'?: null | string;
  bt_ActivityOwner?: User;
  bt_VerdeTimeActivityEmployee_TimeEntryAct?: null | Array<TimeActivityEmployeeApiModel>;
  bt_accesscount?: null | number;
  bt_activedate?: null | string;
  bt_activity?: null | string;
  bt_activityownerapprovalrequired?: null | boolean;
  bt_activitystatus?: null | number;
  bt_description?: null | string;
  bt_enddate?: null | string;
  bt_grouping?: null | string;
  bt_managerapprovalrequired?: null | boolean;
  bt_noofactivities?: null | number;
  bt_recordingleadtime?: null | number;
  bt_startandendtime?: null | boolean;
  bt_submissioninterval?: null | number;
  bt_timerecordenddate?: null | string;
  bt_verdetimeentryactivityid?: null | string;
  odataActivityOwner?: null | string;
  odataActivityOwnerLegalEntity?: null | string;
  odataActivityStatus?: null | string;
  odataActivityownerapprovalrequired?: null | string;
  odataLegalEntity?: null | string;
  odataManagerapprovalrequired?: null | string;
  odataSubmissionInterval?: null | string;
  odatagrouping?: null | string;
  odatastartandendtime?: null | string;
  postOdataActivityOwner?: null | string;
  postOdataActivityOwnerLegalEntity?: null | string;
  postOdataLegalEntity?: null | string;
  postOdatagrouping?: null | string;
  statecode?: null | number;
}
