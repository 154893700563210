/* tslint:disable */
/* eslint-disable */
export interface DvMemberships {
  bthr_enddate?: null | string;
  bthr_membernumber?: null | string;
  bthr_membershipid?: null | string;
  bthr_organisationtype?: null | number;
  bthr_startdate?: null | string;
  odataOrganisation?: null | string;
  odataOrganisationType?: null | string;
  odataRecordStatus?: null | string;
  odataStatus?: null | string;
}
