/* tslint:disable */
/* eslint-disable */
import { QualificationsGuidExtrapolate } from './qualifications-guid-extrapolate';
import { QualificationsBthrRecordStatus } from './qualifications-bthr-record-status';
export interface QualificationsBthrCountry {
  bthr_Countryid?: null | string;
  bthr_country?: null | string;
  bthr_geographicalregion?: null | string;
  bthr_isoalpha2?: null | string;
  bthr_isoalpha3?: null | string;
  bthr_isonumeric?: null | string;
  bthr_recordstatus?: QualificationsBthrRecordStatus;
  geographicalregion?: QualificationsGuidExtrapolate;
}
