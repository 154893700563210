import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { UserService } from '@verde/core';

@Component({
  selector: 'verde-personal-tax-documents',
  templateUrl: './personal-tax-documents.component.html',
  styleUrls: ['./personal-tax-documents.component.scss'],
})
export class PersonalTaxDocumentsComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<boolean>();

  disableAnimation: boolean = false;
  constructor(private userService: UserService) {}

  ngOnInit(): void {
    this.userService.disableAnimation$.pipe(takeUntil(this.onDestroy$)).subscribe((data) => {
      this.disableAnimation = data;
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
