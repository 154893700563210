import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { ModalService } from '../../../../../modals/modal.service';
import { VerdeApprovalService } from '../../../../../services/verde-approval/verde-approval.service';
import { SubgroupService } from '../../../../../../../../../../apps/verde/src/app/features/profile2/services/subgroup.service';
import { RefreshServiceService } from '../../../../../../../../../../apps/verde/src/app/features/profile2/components/templates/services/refresh-service.service';
import { TemplateTemplateLegalEntity, TemplateBtTemplateType, TemplateTemplateSubgroup } from '@verde/shared';

@Component({
  selector: 'verde-subgroup-add',
  templateUrl: './subgroup-add.component.html',
  styleUrls: ['./subgroup-add.component.scss'],
})
export class SubgroupAddComponent implements OnInit {
  private onDestroy$ = new Subject<boolean>();

  filteredGroups: any[] = [];
  legalEntityID: string;
  legalEntityName: string;
  tempType: string;
  formValues: any;
  subgroupForm: FormGroup;
  visited: boolean;
  private methodCallSubscription: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private sidebarService: VerdeApprovalService,
    private modalService: ModalService,
    private subgroupService: SubgroupService,
    private refreshServiceService: RefreshServiceService,
  ) {
    this.methodCallSubscription = this.refreshServiceService.methodCall$.subscribe(() => {
      if (this.visited === true) {
        this.loadPage();
      }
    });
  }

  ngOnInit(): void {
    this.visited = true;
    this.loadPage();
  }

  loadPage() {
    this.sidebarService
      .getSelectedLegalEntityID()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((id: any) => {
        this.legalEntityID = id;
      });
    this.sidebarService
      .getSelectedLegalEntityName()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((name: any) => {
        this.legalEntityName = name;
      });
    this.sidebarService
      .getSelectedTemplateType()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((type: any) => {
        this.tempType = type;
      });

    this.subgroupForm = this.formBuilder.group({
      legalEntity: [this.legalEntityName],
      templateType: [this.tempType],
      name: [, Validators.required],
      sorting: [, [Validators.required, Validators.pattern('[0-9]*')]],
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  addSubgroup() {
    const formValues = this.subgroupForm.value;
    if (this.subgroupForm.valid) {
      this.formValues = formValues;
      this.modalService.open('confirmSubgroup');
    } else {
    }
  }

  confirm() {
    let le: TemplateTemplateLegalEntity = {
      bthr_legalentitiesid: this.legalEntityID,
      bthr_legalentityname: this.legalEntityName,
    };

    let postTempType: TemplateBtTemplateType | undefined;
    if (this.tempType === TemplateBtTemplateType.PeerReview) {
      postTempType = TemplateBtTemplateType.PeerReview;
    } else if (this.tempType === TemplateBtTemplateType.PerformanceReview) {
      postTempType = TemplateBtTemplateType.PerformanceReview;
    } else if (this.tempType === TemplateBtTemplateType.Fleet) {
      postTempType = TemplateBtTemplateType.Fleet;
    } else if (this.tempType === TemplateBtTemplateType.Equipment) {
      postTempType = TemplateBtTemplateType.Equipment;
    }

    var numberValue = Number(this.formValues.sorting);
    let body: TemplateTemplateSubgroup = {
      bt_sorting: numberValue,
      bt_peerreviewtemplatesubgroup: this.formValues.name,
      bt_TemplateType: postTempType,
    };
    this.subgroupService.uploadSubgroup({ templateSubgroupBody: body });

    this.sidebarService.setShowSidebar(false);

    this.resetForm();
  }

  resetForm(): void {
    const legalEntityValue = this.subgroupForm.get('legalEntity')?.value;
    const templateTypeValue = this.subgroupForm.get('templateType')?.value;
    this.subgroupForm.reset();
    this.subgroupForm.patchValue({
      legalEntity: legalEntityValue,
      templateType: templateTypeValue,
    });

    this.refresh();
  }

  refresh() {
    this.refreshServiceService.refreshView();
  }
}
