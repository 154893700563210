/* tslint:disable */
/* eslint-disable */
import { StructureBtStructureType } from './structure-bt-structure-type';
import { StructureBtPositionseat } from './structure-bt-positionseat';
export interface StructureStructureFull {
  bt_motivationforjobdescription?: null | string;
  bt_securityprofilecomment?: null | string;
  cr438_allowancecomment?: null | string;
  defaultAllowances?: boolean;
  eeSeat?: boolean;
  employeeName?: null | string;
  employeeNumber?: null | string;
  employeeTypeID?: null | string;
  hasChildren?: null | boolean;
  inheritJobDescription?: boolean;
  inheritSecurityProfile?: boolean;
  leganEntityID?: null | string;
  locationID?: null | string;
  managerID?: null | string;
  name?: null | string;
  parentRecordID?: null | string;
  positionDescription?: null | string;
  positionID?: null | string;
  positionSeatID?: null | string;
  recordID?: null | string;
  seatName?: null | string;
  seatStatus?: StructureBtPositionseat;
  structureType?: StructureBtStructureType;
  employeeStatus?: null | string;
}
