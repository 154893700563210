/* tslint:disable */
/* eslint-disable */
import { StructureBtApprovalStatus } from './structure-bt-approval-status';
import { StructureBtStructureMovementType } from './structure-bt-structure-movement-type';
import { StructureBtStructureProcessStatus } from './structure-bt-structure-process-status';
import { StructureBtStructureType } from './structure-bt-structure-type';
import { StructureBtPositionseat } from './structure-bt-positionseat';
export interface StructureStructureChangeRequest {
  approvalStatus?: StructureBtApprovalStatus;
  approvalrequired?: null | boolean;
  bt_securityprofilecomment?: null | string;
  cr438_allowancecomment?: null | string;
  defaultAllowances?: null | boolean;
  eeSeat?: null | boolean;
  effectiveDate?: null | string;
  employeeTypeId?: null | string;
  inheritJobDescription?: null | boolean;
  inheritSecurityProfile?: null | boolean;
  isManager?: null | boolean;
  motivation?: null | string;
  newDepartmentName?: null | string;
  newDivisionName?: null | string;
  newLegalEntityName?: null | string;
  newPosition?: null | string;
  originatorEmployeeId?: null | string;
  originatorLegalEntityId?: null | string;
  parentDepartmentId?: null | string;
  parentDivisionId?: null | string;
  parentLegalEntityId?: null | string;
  positionId?: null | string;
  positionSeatId?: null | string;
  seatStatus?: StructureBtPositionseat;
  structureMovementType?: StructureBtStructureMovementType;
  structureProcessStatus?: StructureBtStructureProcessStatus;
  structureType?: StructureBtStructureType;
  toParentDepartmentId?: null | string;
  toParentDivisionId?: null | string;
  toParentLegalEntityId?: null | string;
  toPositionSeatId?: null | string;
}
