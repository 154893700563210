/* eslint-disable @angular-eslint/component-selector */
import { Component, ViewEncapsulation, ElementRef, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { fadeInDownOnEnterAnimation, fadeInOnEnterAnimation, fadeOutOnLeaveAnimation, fadeOutUpOnLeaveAnimation } from 'angular-animations';
import { ModalService } from '../modal.service';

@Component({
  selector: 'verde-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  animations: [
    fadeInDownOnEnterAnimation({ anchor: 'modalEnter', duration: 300 }),
    fadeOutUpOnLeaveAnimation({ anchor: 'modalLeave', duration: 300 }),
    fadeInOnEnterAnimation({ anchor: 'modalBackgroundIn', duration: 300 }),
    fadeOutOnLeaveAnimation({ anchor: 'modalBackgroundOut', duration: 300 }),
  ],
  encapsulation: ViewEncapsulation.None,
})
export class ModalComponent implements OnInit, OnDestroy {
  private element: any;

  @Input() showHeader: boolean = true;
  @Input() showFooter: boolean = true;
  @Input() modalHasPadding: boolean = true;
  @Input() modalMinWidth: number = 600;
  @Input() modalMaxWidth: number = 900;
  @Input() modalTitle: string = 'Modal title';
  @Input() submitButton: string = '';
  @Input() nextButton: string = '';
  @Input() submitActive: boolean = true;
  @Input() id: string = '';
  @Input() zIndex: number = 2000;
  @Input() hasCancelButton: boolean = true;
  @Input() hasSecondCancelButton: boolean = false;
  @Input() cancelText: string = 'Cancel';
  @Input() secondCancelText: string = 'Cancel';
  @Input() overflowValue: string = 'auto';
  @Input() closeOnActionButtonClick: boolean = true;
  @Input() closeOnSecondCancelButtonClick: boolean = true;

  // Confirmation Inputs
  @Input() submitConfirmationHeader: string = 'Confirmation';
  @Input() submitConfirmationMessage: string = 'Are you sure you want to do this?';
  @Input() submitConfirmationMessageLine2: string = '';
  @Input() cancelConfirmationHeader: string = 'Confirm cancel';
  @Input() secondCancelConfirmationHeader: string = 'Confirm cancel';
  @Input() cancelConfirmationMessage: string = 'Are you sure you want to close this process?';
  @Input() secondCancelConfirmationMessage: string = 'Are you sure you want to close this process?';
  @Input() saveActionButtonText: string = 'Save';
  @Input() hasSubmitConfirmation: boolean = false;
  @Input() hasCancelConfirmation: boolean = false;
  @Input() hasSecondCancelConfirmation: boolean = false;
  @Input() linkedToPreviousModal: boolean = false;
  @Input() saveActionAvailable: boolean = false;
  @Input() secondCancelButtonDisabled: boolean = false;
  @Input() previousModalName: string = '';
  @Input() navigateToPreviousModal: ((modalName: string) => void) | undefined;
  @Input() confirmationModalSubmit: string = 'Confirm';
  @Input() confirmationModalCancel: string = 'Cancel';

  @Output() actionButton: EventEmitter<boolean> = new EventEmitter();
  @Output() saveActionButton: EventEmitter<boolean> = new EventEmitter();
  @Output() cancelButton: EventEmitter<boolean> = new EventEmitter();
  @Output() modalStateOpen: EventEmitter<boolean> = new EventEmitter();
  @Output() modalCancelled: EventEmitter<void> = new EventEmitter();
  @Output() modalSecondCancelled: EventEmitter<void> = new EventEmitter();

  showModal: boolean = false;
  confirmation: boolean = false;

  confirmationHeader: string = '';
  confirmationMessage: string = '';
  confirmationMessageLine2: string = '';

  modalAction: 'cancel' | 'secondcancel' | 'submit' = 'cancel';

  constructor(private modalService: ModalService, private el: ElementRef) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    // ensure id attribute exists
    if (!this.id) {
      console.error('modal must have an id');
      return;
    }

    // move element to bottom of page (just before </body>) so it can be displayed above everything else
    document.body.appendChild(this.element);

    // add self (this modal instance) to the modal service so it's accessible from controllers
    this.modalService.add(this);
  }

  // remove self from modal service when component is destroyed
  ngOnDestroy(): void {
    this.modalService.remove(this.id);
    this.element.remove();
  }

  mainModalCancel(forceCancel: boolean = false): void {
    this.modalAction = 'cancel';
    if (this.hasCancelConfirmation && !forceCancel) {
      this.confirmationHeader = this.cancelConfirmationHeader;
      this.confirmationMessage = this.cancelConfirmationMessage;
      this.confirmation = true;
      return;
    }
    this.close();
    this.modalCancelled.emit();
  }

  mainModalSecondCancel(forceCancel: boolean = false): void {
    this.modalAction = 'secondcancel';
    if (this.hasSecondCancelConfirmation && !forceCancel) {
      this.confirmationHeader = this.secondCancelConfirmationHeader;
      this.confirmationMessage = this.secondCancelConfirmationMessage;
      this.confirmation = true;
      return;
    }

    if (this.closeOnSecondCancelButtonClick) {
      this.close();
    }

    this.modalSecondCancelled.emit();
  }

  mainModalSubmit(forceSubmit: boolean = false): void {
    if (this.closeOnActionButtonClick) {
      // Close Modal
      this.modalAction = 'submit';
      if (this.hasSubmitConfirmation && !forceSubmit) {
        this.confirmationHeader = this.submitConfirmationHeader;
        this.confirmationMessage = this.submitConfirmationMessage;

        if (this.submitConfirmationMessageLine2 !== '') {
          this.confirmationMessageLine2 = this.submitConfirmationMessageLine2;
        }

        this.confirmation = true;
        return;
      }

      this.actionButton.emit(true);
      this.showModal = false;
      this.modalStateOpen.emit(this.showModal);
      document.body.classList.remove('verde-modal-open');
    } else {
      // Leave Modal Open
      this.modalAction = 'submit';
      if (this.hasSubmitConfirmation && !forceSubmit) {
        this.confirmationHeader = this.submitConfirmationHeader;
        this.confirmationMessage = this.submitConfirmationMessage;
        this.confirmation = true;
        return;
      }

      this.actionButton.emit(true);
      //this.showModal = false;
      //this.modalStateOpen.emit(this.showModal);
      //document.body.classList.remove('verde-modal-open');
    }
  }

  mainModalSave(saveSubmit: boolean = false): void {
    this.saveActionButton.emit(true);
    this.showModal = false;
    this.modalStateOpen.emit(this.showModal);

    document.body.classList.remove('verde-modal-open');
  }

  cancelConfirmation(): void {
    this.confirmation = false;
  }

  confirmAction(): void {
    this.cancelConfirmation();
    if (this.modalAction === 'submit') {
      this.mainModalSubmit(true);
    } else if (this.modalAction === 'cancel') {
      this.mainModalCancel(true);
    } else if (this.modalAction === 'secondcancel') {
      this.mainModalSecondCancel(true);
    }
  }

  // open modal
  open(): void {
    setTimeout(() => {
      this.showModal = true;
      this.modalStateOpen.emit(this.showModal);
    });
    // document.body.classList.add('verde-modal-open');
  }

  // close modal
  close(): void {
    this.showModal = false;
    this.modalStateOpen.emit(this.showModal);
    document.body.classList.remove('verde-modal-open');
  }
}
