/* tslint:disable */
/* eslint-disable */
import { User } from './user';
export interface FleetOdoUpdates {
  '_bt_fleetitem_value'?: null | string;
  '_bt_legalentity_value'?: null | string;
  '_bt_owner_value'?: null | string;
  '_bt_ownerentity_value'?: null | string;
  bt_fleetodoupdateid?: null | string;
  bt_fleetodoupdateno?: null | string;
  bt_odoreading?: number;
  bt_processed?: boolean;
  bt_processeddate?: string;
  bthr_Employee?: User;
  createdon?: string;
  odataFleetItem?: null | string;
  odataLegalEntity?: null | string;
  odataOwner?: null | string;
  odataOwnerEntity?: null | string;
}
