import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
// Store
//Related
import * as fromProfileRelatedReducer from '../store/reducers/profile-related.reducer';
// Equipment Request Detail
import * as QualificationActions from '../store/actions/qualification.actions';
import * as fromQualificationReducer from '../store/reducers/qualification.reducer';
import { QualificationsGetQualificationQuery, QualificationsCreateQualificationCommand, QualificationsGetReducedQualificationQuery } from '@verde/shared';

@Injectable({
  providedIn: 'root',
})
export class QualificationService {
  // Qualification
  qualificationLoading$: Observable<boolean>;
  qualificationObjects$: Observable<any[]>;

  constructor(private store: Store<fromProfileRelatedReducer.ProfileRelatedState>) {
    // Qualification
    this.qualificationLoading$ = this.store.pipe(select(fromQualificationReducer.isQualificationLoading));
    this.qualificationObjects$ = this.store.pipe(select(fromQualificationReducer.getQualification));
  }

  // Qualification Detail
  // Get
  getQualification(body: QualificationsGetQualificationQuery) {
    this.store.dispatch(new QualificationActions.QualificationGetAll(body));
  }

  // Get Reduced
  getReducedQualification(body: QualificationsGetReducedQualificationQuery) {
    this.store.dispatch(new QualificationActions.QualificationGetReduced(body));
  }

  // Clear
  qualificationClear() {
    this.store.dispatch(new QualificationActions.QualificationClear(null));
  }

  // Create
  uploadQualification(payload: QualificationsCreateQualificationCommand) {
    this.store.dispatch(new QualificationActions.QualificationUpload(payload));
  }
}
