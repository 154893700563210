/* tslint:disable */
/* eslint-disable */
export interface VerdeLearnCallouts {
  '_bt_callout_value'?: null | string;
  '_bt_employee_value'?: null | string;
  '_bt_legalentity_value'?: null | string;
  bt_employeecallout?: null | any;
  bt_employeecalloutid?: null | string;
  odataCallout?: null | string;
  odataEmployee?: null | string;
  odataLegalEntity?: null | string;
  odataPostCallout?: null | string;
  odataPostEmployee?: null | string;
  odataPostEmployeeCalloutid?: null | string;
  odataPostLegalEntity?: null | string;
}
