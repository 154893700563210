import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WindowService {
  private static _windowWidth: number = 0;
  private readonly MOBILE_WIDTH: number = 600;

  windowResize: ReplaySubject<number> = new ReplaySubject<number>(1);

  constructor() {
    this.windowWidth = window.innerWidth;

    let timeoutCheck: NodeJS.Timeout | undefined;

    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
      clearTimeout(timeoutCheck);
      timeoutCheck = setTimeout(() => this.resizeEvent(), 100);
    });
  }

  get windowWidth() {
    return WindowService._windowWidth;
  }

  set windowWidth(val: number) {
    WindowService._windowWidth = val;
  }

  isMobile(): boolean {
    return this.windowWidth <= this.MOBILE_WIDTH;
  }

  private resizeEvent(): void {
    this.windowResize.next(this.windowWidth);
  }
}
