/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DvAddresses } from '../models/dv-addresses';
import { DvAddressesPost } from '../models/dv-addresses-post';

@Injectable({
  providedIn: 'root',
})
export class SharedApiAddressesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAllAddresses
   */
  static readonly GetAllAddressesPath = '/api/Addresses/{employeeGUID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllAddresses()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllAddresses$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    employeeGUID: string;
  }): Observable<StrictHttpResponse<Array<DvAddresses>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiAddressesService.GetAllAddressesPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('employeeGUID', params.employeeGUID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DvAddresses>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllAddresses$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllAddresses(params: {
    subscriptionKey?: string;
    APIURL?: string;
    employeeGUID: string;
  }): Observable<Array<DvAddresses>> {

    return this.getAllAddresses$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DvAddresses>>) => r.body as Array<DvAddresses>)
    );
  }

  /**
   * Path part for operation patchAddresses
   */
  static readonly PatchAddressesPath = '/api/Addresses/patch/{userguid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchAddresses()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchAddresses$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userguid: string;
    body?: DvAddressesPost
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiAddressesService.PatchAddressesPath, 'patch');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('userguid', params.userguid, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchAddresses$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchAddresses(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userguid: string;
    body?: DvAddressesPost
  }): Observable<boolean> {

    return this.patchAddresses$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation postAddresses
   */
  static readonly PostAddressesPath = '/api/Addresses/post/{userguid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postAddresses()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postAddresses$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userguid: string;
    body?: DvAddressesPost
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiAddressesService.PostAddressesPath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('userguid', params.userguid, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postAddresses$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postAddresses(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userguid: string;
    body?: DvAddressesPost
  }): Observable<boolean> {

    return this.postAddresses$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

}
