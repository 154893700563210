import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private sessionStorage = sessionStorage;
  private localStorage = localStorage;

  constructor() {}

  sessionSaveKey(key: string, val: string | object): void {
    const saveVal = typeof val === 'object' ? JSON.stringify(val) : val;
    this.sessionStorage.setItem(key, saveVal);
  }

  sessionGetKey(key: string): any {
    const fetchedVal = this.sessionStorage.getItem(key);
    if (fetchedVal) {
      const returnVal = this.isJson(fetchedVal) ? JSON.parse(fetchedVal) : fetchedVal;
      return returnVal;
    }
    return fetchedVal;
  }

  sessionDeleteKey(key: string): void {
    this.sessionStorage.removeItem(key);
  }

  sessionClearAll(): void {
    this.sessionStorage.clear();
  }

  localSaveKey(key: string, val: string | object): void {
    const saveVal = typeof val === 'object' ? JSON.stringify(val) : val;
    this.localStorage.setItem(key, saveVal);
  }

  localGetKey(key: string): any {
    const fetchedVal = this.localStorage.getItem(key);
    if (fetchedVal) {
      const returnVal = this.isJson(fetchedVal) ? JSON.parse(fetchedVal) : fetchedVal;
      return returnVal;
    }
    return fetchedVal;
  }

  localDeleteKey(key: string): void {
    this.localStorage.removeItem(key);
  }

  localClearAll(): void {
    this.localStorage.clear();
  }

  private isJson(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
}
