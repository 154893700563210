import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControlDirective, FormGroup } from '@angular/forms';
import moment from 'moment';
import { Subject, take } from 'rxjs';
import { ModalService } from '../modal.service';
import { SharedApiVerdeApprovalsService, VerdeApprovalLogGroups, VerdeApprovalLogs } from '@verde/shared';
import { UserService } from '@verde/core';

@Component({
  selector: 'verde-approval-modal',
  templateUrl: './verde-approval-modal.component.html',
  styleUrls: ['./verde-approval-modal.component.scss'],
})
export class VerdeApprovalModalComponent implements OnInit, AfterViewInit {
  onDestroy$ = new Subject<boolean>();

  @Input() isTaskApproval: boolean = false;
  @Input() approvalDefinitionHeader: string = '';
  @Input() taskID: string = '';
  @Input() logID: string = '';
  @Input() logGroupID: string = '';

  @Output() hideModalEvent = new EventEmitter<boolean>();

  approvalLogGroup: VerdeApprovalLogGroups = {};

  approveButtonText: string = 'Approve';
  confirmationAction: string = 'approve';

  agreedYes: boolean = false;
  agreedNo: boolean = false;

  constructor(private modalService: ModalService, private userService: UserService, private sharedApiVerdeApprovalsService: SharedApiVerdeApprovalsService) {}

  ngOnInit(): void {
    // console.log('logID', this.logID);
    // console.log('logGroupID', this.logGroupID);
    if (this.logGroupID !== null) {
      if (!this.isTaskApproval) {
        this.getVerdeApprovalLogGroupsByID();
      } else {
        this.getVerdeApprovalLogGroupsByIDAndTask();
      }
    }
  }

  ngAfterViewInit(): void {
    this.modalService.open('verdeApprovalModal');
  }

  hideModal(value: boolean) {
    if (!value) {
      this.hideModalEvent.emit(value);
    }
  }

  getVerdeApprovalLogGroupsByID() {
    this.sharedApiVerdeApprovalsService
      .getVerdeApprovalLogGroupsById({ logGroupID: this.logGroupID })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          // console.log('ret', ret);
          if (ret.length > 0) {
            this.approvalLogGroup = ret[0];
            const doc = document.getElementById('approvalType');
            if (doc) {
              doc.innerHTML = this.approvalLogGroup.bt_comment;
            }
          }
        },
        (error) => {
          console.error(error);
        },
        () => {},
      );
  }

  getVerdeApprovalLogGroupsByIDAndTask() {
    this.sharedApiVerdeApprovalsService
      .getVerdeApprovalLogGroupsByIdAndTask({ logGroupID: this.logGroupID, taskID: this.taskID })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          // console.log('rettet', ret);
          if (ret.length > 0) {
            this.approvalLogGroup = ret[0];
            const doc = document.getElementById('approvalType');
            if (doc) {
              doc.innerHTML = this.approvalLogGroup.bt_comment;
            }
          }
        },
        (error) => {
          console.error(error);
        },
        () => {},
      );
  }

  patchApproval(myForm: FormGroup) {
    let updateBody: VerdeApprovalLogs = {};

    if (this.agreedYes) {
      updateBody.bt_approvalstatus = 692360003;
    } else {
      updateBody.bt_approvalstatus = 692360002;
      updateBody.bt_comment = myForm.value.approveRequestComment;
    }

    // checking if proxy
    //if (this.proxyUser.employeeId === this.userService?.user?.employeeId) {
    //  updateBody.bt_proxyapproval = false;
    //} else {
    //  updateBody.bt_proxyapproval = true;
    //  updateBody.odataPostProxy = '/bthr_employees(' + this.userService?.user?.employeeId + ')';
    //  updateBody.odataPostProxyEntity = '/bthr_legalentitieses(' + this.userService?.user?.legalEntityId + ')';
    //}

    updateBody.odataPostApprover = '/bthr_employees(' + this.userService?.user?.employeeId + ')';
    updateBody.odataPostApproverLegalEntity = '/bthr_legalentitieses(' + this.userService?.user?.legalEntityId + ')';
    updateBody.bt_approvaldate = moment().format('YYYY-MM-DD');

    updateBody.bt_verdeapprovallogsid = this.logID;

    this.sharedApiVerdeApprovalsService
      .patchVerdeApprovalsLogs({ body: updateBody })
      .pipe(take(1))
      .subscribe((ret) => {
        updateBody = {};
        this.hideModalEvent.emit(false);
      });
  }

  agreedYesChange(e: any, element: FormControlDirective) {
    this.agreedYes = e;
    this.agreedNo = !e;
    element.reset();

    if (this.agreedYes) {
      this.confirmationAction = 'approval';
      this.approveButtonText = 'Approve';
    } else {
      this.confirmationAction = 'rejection';
      this.approveButtonText = 'Reject';
    }
  }

  agreedNoChange(e: any, element: FormControlDirective) {
    this.agreedNo = e;
    this.agreedYes = !e;
    element.reset();

    if (this.agreedNo) {
      this.confirmationAction = 'rejection';
      this.approveButtonText = 'Reject';
    } else {
      this.confirmationAction = 'approval';
      this.approveButtonText = 'Approve';
    }
  }
}
