import { Subject, take, takeUntil } from 'rxjs';
import { GridPagingService, UserService } from '@verde/core';
import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DataBindingDirective, DataStateChangeEvent, GridDataResult, RowClassArgs, SelectableSettings } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { DeviceTypeService } from '@verde/core';
import {
  BtDocSalaryAdviceDetail,
  BtDocSalaryAdviceHeader,
  UserUserDto,
  DocumentApiDocumentService,
  DocumentDocSalaryAdviceDetail,
  DocumentDocSalaryAdviceHeader,
  DotsMenuItem,
  ManualDocumentsDataService,
  ModalService,
  SharedApiVerdeDocumentsService,
  VerdeHeaderTemplate,
} from '@verde/shared';
import { PDFExportComponent } from '@progress/kendo-angular-pdf-export';
import { PayslipDialogComponent } from '../../../../modals/payslip-dialog/payslip-dialog.component';

@Component({
  selector: 'verde-payroll-documents',
  templateUrl: './payroll-documents.component.html',
  styleUrls: ['./payroll-documents.component.scss'],
})
export class PayrollDocumentsComponent implements OnInit, AfterViewInit, OnDestroy {
  dotMenuItems: DotsMenuItem[] = [];
  showDialog: boolean = false;

  proxyUser: UserUserDto = {};
  @ViewChild('payslipDialog') payslipDialog: PayslipDialogComponent;
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  allSkeletonData: Array<any> = new Array<any>();
  skeletonGridView: GridDataResult;
  HeaderContent: VerdeHeaderTemplate | undefined;

  skeletonSelectedCallback = (args: { dataItem: any }) => args.dataItem;

  skeletonSelection: any[] = [];
  public opened = false;
  public selectableSettings: SelectableSettings = {
    enabled: true,
    checkboxOnly: true,
  };

  private onDestroy$ = new Subject<boolean>();

  selectedItem: BtDocSalaryAdviceHeader;
  salaryDocAdvice: BtDocSalaryAdviceHeader[] = [];
  EarningsGrid: BtDocSalaryAdviceDetail[] = [];
  DeductionsGrid: BtDocSalaryAdviceDetail[] = [];
  CompanyContributionsGrid: BtDocSalaryAdviceDetail[] = [];

  selectedItemNew: DocumentDocSalaryAdviceHeader;
  salaryDocAdviceNew: DocumentDocSalaryAdviceHeader[] = [];
  EarningsGridNew: DocumentDocSalaryAdviceDetail[] = [];
  DeductionsGridNew: DocumentDocSalaryAdviceDetail[] = [];
  CompanyContributionsGridNew: DocumentDocSalaryAdviceDetail[] = [];

  disableAnimation: boolean = false;

  showIDValidationModal: boolean = false;

  currentPerks: DocumentDocSalaryAdviceDetail = {};

  HeaderContentURL: string;
  CompanyContributionsDifferent: DocumentDocSalaryAdviceDetail[] = [];

  convertStringToDate(date: string): Date {
    const parts = date.split('/');
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1; // Months in JavaScript are 0-based (0 = January, 1 = February, etc.)
    const year = parseInt(parts[2], 10);

    const dateObject = new Date(year, month, day);
    return dateObject;
  }

  payslipLoading: boolean = true;
  public state: State = {
    skip: 0,
    take: this.gridPagingService.pageSize,
    group: [],
    filter: { filters: [], logic: 'and' },
    sort: [],
  };
  allPayslips: GridDataResult;

  constructor(
    private sharedapiverdedocumentsservice: SharedApiVerdeDocumentsService,
    public userService: UserService,
    public deviceTypeService: DeviceTypeService,
    private manualDocumentsDataService: ManualDocumentsDataService,
    private documentApiDocumentService: DocumentApiDocumentService,
    private modalService: ModalService,
    public gridPagingService: GridPagingService,
    public cdr: ChangeDetectorRef,
    @Inject('environment') private environment: any,
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.gridPagingService.changeGridID('viewPayslipGrid');

    setTimeout(() => {
      this.state.take = this.gridPagingService.pageSize;

      this.userService.proxyUser$.pipe(takeUntil(this.onDestroy$)).subscribe((user) => {
        if (user !== null && user !== undefined && Object.keys(user).length > 0) {
          this.proxyUser = user;

          this.GetDataNewIqueryable();
        }
      });
    }, 100);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  updateAnotherDivHeight() {
    const payrollMain = document.querySelector('.payroll-main') as HTMLElement;
    const payslipInnerContainer = document.querySelector('.payslip-inner-container') as HTMLElement;

    if (payrollMain && payslipInnerContainer) {
      // Get the height of payroll-main as a number (removing 'px')
      const mainDivHeight = parseFloat(getComputedStyle(payrollMain).height);

      // Calculate the new height for payslip-inner-container based on your pattern
      const initialHeight = 770; // Initial height for payslip-inner-container
      const initialMainHeight = 82.43; // Initial height for payroll-main
      const increasePer40px = 40.11; // Increase in payslip-inner-container height for every 40.11px in payroll-main height

      const newHeight = initialHeight + ((mainDivHeight - initialMainHeight) / increasePer40px) * increasePer40px;

      // Calculate the margin-top based on the height
      const baseHeight = 770; // Initial height for margin-top: 0px
      const incrementHeight = 40.11; // Height increment for margin-top increase
      const incrementMarginTop = 40.11; // Margin-top increment for every 40.11px increase in height

      let marginTop = 0;

      if (newHeight >= baseHeight) {
        const heightDifference = newHeight - baseHeight;
        const numberOfIncrements = Math.floor(heightDifference / incrementHeight);
        marginTop = numberOfIncrements * incrementMarginTop;
      }

      // Set the new height and margin-top for payslip-inner-container
      payslipInnerContainer.style.height = `${newHeight}px`;
      payslipInnerContainer.style.marginTop = `${marginTop}px`;
    }
  }

  applyStylesByScreenHeight() {
    const screenHeight = window.innerHeight;

    // Define screen width thresholds and corresponding style changes
    const thresholds = [
      { maxHeight: 1010, payrollMainStyle: { height: '910px', top: '28px' }, payslipInnerContainerStyle: { marginTop: '0px' } },
      { maxHeight: 940, payrollMainStyle: { height: '850px', top: '26px' }, payslipInnerContainerStyle: { marginTop: '0px' } },
      { maxHeight: 880, payrollMainStyle: { height: '780px', top: '26px' }, payslipInnerContainerStyle: { marginTop: '0px' } },
      { maxHeight: 810, payrollMainStyle: { height: '700px', top: '22px' }, payslipInnerContainerStyle: { marginTop: '70px' } },
      { maxHeight: 720, payrollMainStyle: { height: '630px', top: '20px' }, payslipInnerContainerStyle: { marginTop: '160px' } },
      { maxHeight: 650, payrollMainStyle: { height: '580px', top: '20px' }, payslipInnerContainerStyle: { marginTop: '250px' } },
      { maxHeight: 600, payrollMainStyle: { height: '520px', top: '18px' }, payslipInnerContainerStyle: { marginTop: '269px' } },
    ];

    // Apply styles based on screen height
    thresholds.forEach((threshold) => {
      if (screenHeight <= threshold.maxHeight) {
        const payslipTop = document.querySelector('.payslip-inner-container') as HTMLElement;

        if (payslipTop) {
          let payslipTopMarginTop = 0;
          payslipTopMarginTop = parseFloat(getComputedStyle(payslipTop).marginTop);

          payslipTop.style.marginTop = threshold.payslipInnerContainerStyle.marginTop;
        }

        return;
      }
    });
  }

  customDateSort(a: any, b: any): number {
    const dateA = new Date(a.bt_periodenddate.split('/').reverse().join('/'));
    const dateB = new Date(b.bt_periodenddate.split('/').reverse().join('/'));

    return dateB.getTime() - dateA.getTime();
  }

  // New IQueryable method
  GetDataNewIqueryable() {
    this.payslipLoading = true;
    let sortVal = '';

    if (this.state.sort.length > 0) {
      if (this.state.sort[0].dir === 'desc') {
        sortVal = '-' + this.state.sort[0].field;
      } else {
        sortVal = this.state.sort[0].field;
      }
    }

    this.documentApiDocumentService
      .getDocSalaryAdviceIQuery({
        body: {
          employeeID: this.proxyUser.employeeId!,
          query: {
            count: false,
            take: this.state.take,
            skip: this.state.skip,
            sort: sortVal,
            filter: '',
            search: '',
            searchColumns: [''],
          },
        },
      })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          this.allPayslips = { data: ret.results.sort(this.customDateSort), total: ret.count };
          this.payslipLoading = false;
        },
        (error) => {
          this.payslipLoading = false;
          console.error(error);
        },
      );
  }

  public close(status: string): void {
    this.opened = false;
  }

  public open(): void {
    this.opened = true;

    setTimeout(() => {
      let el = document.getElementById('payslipTop');
      el.scrollIntoView({ behavior: 'auto' });

      // Call the function to update payslip-inner-container's height
      this.updateAnotherDivHeight();
      this.applyStylesByScreenHeight();
    }, 10);

    setTimeout(() => {
      let el = document.getElementById('payslipTop');
      el.scrollIntoView({ behavior: 'auto' });

      // Call the function to update payslip-inner-container's height
      this.updateAnotherDivHeight();
      this.applyStylesByScreenHeight();
    }, 10);
  }

  menuClicked(parentItem: DocumentDocSalaryAdviceHeader) {
    this.selectedItemNew = parentItem;
    this.dotMenuItems = [];
    let tempItems: DotsMenuItem[] = [];

    tempItems.push({ id: 'openPayslip', text: 'Open' });

    this.dotMenuItems = [...tempItems];
  }

  menuItemClicked(menuItem: DotsMenuItem) {
    if (menuItem.id === 'openPayslip') {
      this.showDialog = true;
      this.cdr.detectChanges();
    }
  }

  rowCallback(context: RowClassArgs) {
    const isEven = context.index % 2 === 0;
    return {
      even: isEven,
      odd: !isEven,
    };
  }

  dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;

    this.GetDataNewIqueryable();
  }

  SaveThePdf(pdf: PDFExportComponent) {
    pdf.saveAs(this.proxyUser.fullNameWithEmpId + '.pdf');
  }

  getHeaderData() {
    this.sharedapiverdedocumentsservice
      .verdeHeaderTemplate({
        LegalEntity: this.proxyUser.legalEntityId!,
      })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          this.HeaderContent = ret[0];
        },
        (error) => {
          console.error(error);
        },
      );
  }

  refresh() {
    this.GetDataNewIqueryable();
  }

  goToWeb() {
    if (this.environment.slot === 'dev') {
      window.open('https://devapp.verde.work/document', '_blank');
    } else if (this.environment.slot === 'uat') {
      window.open('https://uatapp.verde.work/document', '_blank');
    } else if (this.environment.slot === 'vox-uat') {
      window.open('https://devapp.verde.work/document', '_blank');
    } else if (this.environment.slot === 'prod') {
      window.open('https://app.verde.work/', '_blank');
    }

    this.modalService.close('navToWebApp');
  }

  hideDialogModal(e: boolean) {
    this.showDialog = e;
    this.cdr.detectChanges();
  }
}
