import { Action } from '@ngrx/store';
import { TemplateGetSubgroupsQuery, TemplateUploadSubgroupCommand, TemplatePatchTemplateSubgroupCommand } from '@verde/shared';

export enum SubgroupsActionTypes {
  // Get SUBGROUPS
  SUBGROUPS_GET_ALL = '[SUBGROUPS] Get All Start',
  SUBGROUPS_GET_ALL_SUCCESS = '[SUBGROUPS] Get All Start Success',
  SUBGROUPS_GET_ALL_ERROR = '[SUBGROUPS] Get All Start Error',

  // UPDATE SUBGROUPS
  SUBGROUPS_UPDATE = '[SUBGROUPS] UPDATE',
  SUBGROUPS_UPDATE_SUCCESS = '[SUBGROUPS] UPDATE Success',
  SUBGROUPS_UPDATE_ERROR = '[SUBGROUPS] UPDATE Error',

  // SUBGROUPS Upload
  SUBGROUPS_UPLOAD = '[SUBGROUPS] Upload',
  SUBGROUPS_UPLOAD_SUCCESS = '[SUBGROUPS Upload Success',
  SUBGROUPS_UPLOAD_ERROR = '[SUBGROUPS] Upload Error',

  // Clear All SUBGROUPS
  SUBGROUPS_ITEM_CLEAR = '[SUBGROUPS] Clear',
}

// Get Subgroup
export class SubgroupsGet implements Action {
  readonly type = SubgroupsActionTypes.SUBGROUPS_GET_ALL;
  constructor(public body: TemplateGetSubgroupsQuery) {}
}

export class SubgroupsGetSuccess implements Action {
  readonly type = SubgroupsActionTypes.SUBGROUPS_GET_ALL_SUCCESS;
  constructor(public payload: any[]) {}
}

export class SubgroupsGetError implements Action {
  readonly type = SubgroupsActionTypes.SUBGROUPS_GET_ALL_ERROR;
  constructor(public payload: any) {}
}

// UPDATE SUBGROUPS
export class SubgroupUpdate implements Action {
  readonly type = SubgroupsActionTypes.SUBGROUPS_UPDATE;
  constructor(public payload: TemplatePatchTemplateSubgroupCommand) {}
}

export class SubgroupUpdateSuccess implements Action {
  readonly type = SubgroupsActionTypes.SUBGROUPS_UPDATE_SUCCESS;
  constructor(public payload: any) {}
}

export class SubgroupUpdateError implements Action {
  readonly type = SubgroupsActionTypes.SUBGROUPS_UPDATE_ERROR;
  constructor(public payload: any) {}
}

// SUBGROUPS UPLOAD
export class SubgroupsUpload implements Action {
  readonly type = SubgroupsActionTypes.SUBGROUPS_UPLOAD;
  constructor(public payload: TemplateUploadSubgroupCommand) {}
}

export class SubgroupsUploadSuccess implements Action {
  readonly type = SubgroupsActionTypes.SUBGROUPS_UPLOAD_SUCCESS;
  constructor(public payload: TemplateUploadSubgroupCommand) {}
}

export class SubgroupsUploadError implements Action {
  readonly type = SubgroupsActionTypes.SUBGROUPS_UPLOAD_ERROR;
  constructor(public payload: any) {}
}

// Clear All Subgroup
export class SubgroupsClear implements Action {
  readonly type = SubgroupsActionTypes.SUBGROUPS_ITEM_CLEAR;
  constructor(public payload: any) {}
}

export type SubgroupsActions =
  // Get Subgroup
  | SubgroupsGet
  | SubgroupsGetSuccess
  | SubgroupsGetError
  // Subgroup Update
  | SubgroupUpdate
  | SubgroupUpdateSuccess
  | SubgroupUpdateError
  // Subgroups Upload
  | SubgroupsUpload
  | SubgroupsUploadSuccess
  | SubgroupsUploadError
  // Clear All Subgroup
  | SubgroupsClear;
