/* eslint-disable @typescript-eslint/naming-convention */
export enum SidePanelWidth {
  Eighth = 12.5,
  Quater = 25,
  Third = 33,
  Half = 50,
  TwoThirds = 69.42,
  AlmostFull = 90,
  Full = 100,
}
