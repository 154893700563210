/* tslint:disable */
/* eslint-disable */
import { EquipmentEquipmentEmployee } from './equipment-equipment-employee';
import { EquipmentEquipmentLegalEntity } from './equipment-equipment-legal-entity';
import { EquipmentEquipmentTakeOn } from './equipment-equipment-take-on';
import { EquipmentEquipmentTaskList } from './equipment-equipment-task-list';
export interface EquipmentEquipmentTakeOnControl {
  bt_Employee?: EquipmentEquipmentEmployee;
  bt_LegalEntity?: EquipmentEquipmentLegalEntity;
  bt_TaskNo?: EquipmentEquipmentTaskList;
  bt_bt_equipmenttakeon_TakeOnControl_bt_equip?: null | Array<EquipmentEquipmentTakeOn>;
  bt_counter?: null | number;
  bt_counter_date?: null | string;
  bt_counter_state?: null | number;
  bt_counterinactive?: null | number;
  bt_counterinactive_date?: null | string;
  bt_counterinactive_state?: null | number;
  bt_equipmenttakeoncontrol?: null | string;
  bt_equipmenttakeoncontrolid?: string;
}
