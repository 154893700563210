/* tslint:disable */
/* eslint-disable */
export interface DvMedicalRecords {
  bthr_description?: null | string;
  bthr_employeemedicalid?: null | string;
  bthr_eventdate?: null | string;
  bthr_medicalrecordtype?: null | number;
  bthr_recordstatus?: null | number;
  odataEmployee?: null | string;
  odataRecordStatus?: null | string;
  odataStatus?: null | string;
  odatamedicalrecordtype?: null | string;
}
