/* tslint:disable */
/* eslint-disable */
import { BtApprovalStatus } from './bt-approval-status';
import { BtStructureMovementType } from './bt-structure-movement-type';
import { BtStructureProcessStatus } from './bt-structure-process-status';
import { BtStructureTransferType } from './bt-structure-transfer-type';
import { BtStructureType } from './bt-structure-type';
import { BtPositionseat } from './bt-positionseat';
export interface StructureChangeRequest {
  '_bt_approvaldefinitionheader_value'?: null | string;
  '_bt_currentsecurityprofile_value'?: null | string;
  '_bt_department_value'?: null | string;
  '_bt_division_value'?: null | string;
  '_bt_employee_value'?: null | string;
  '_bt_legalentity_value'?: null | string;
  '_bt_originator_value'?: null | string;
  '_bt_originatorlegalentity_value'?: null | string;
  '_bt_position_value'?: null | string;
  '_bt_seatbudgetno_value'?: null | string;
  '_bt_seatno_value'?: null | string;
  '_bt_securityprofile_value'?: null | string;
  '_bt_todepartment_value'?: null | string;
  '_bt_todivision_value'?: null | string;
  '_bt_tolegalentity_value'?: null | string;
  '_bt_toposition_value'?: null | string;
  '_bt_toseat_value'?: null | string;
  bt_additionalbenefits?: null | number;
  bt_additionalbenefits_base?: null | number;
  bt_approvalrequired?: null | boolean;
  bt_approvalstatus?: BtApprovalStatus;
  bt_budgeted?: null | boolean;
  bt_ctcadjustment?: null | number;
  bt_ctcadjustment_base?: null | number;
  bt_ctcadjustmentyn?: null | boolean;
  bt_ctcrequested?: null | number;
  bt_ctcrequested_base?: null | number;
  bt_effectivedate?: null | string;
  bt_employeeonly?: null | boolean;
  bt_functiontechnicalname?: null | string;
  bt_ismanager?: null | boolean;
  bt_motivation?: null | string;
  bt_newdepartment?: null | string;
  bt_newdivision?: null | string;
  bt_newlegalentity?: null | string;
  bt_newlegalentityregistrationdocument_name?: null | string;
  bt_newposition?: null | string;
  bt_newpositiondescription_name?: null | string;
  bt_newseat?: null | string;
  bt_nextstep?: null | string;
  bt_processstatuscomments?: null | string;
  bt_promotion?: null | boolean;
  bt_seatstatus?: BtPositionseat;
  bt_securityprofilechange?: null | boolean;
  bt_structurechangerequestno?: null | string;
  bt_structuremovementtype?: BtStructureMovementType;
  bt_structureprocessstatus?: BtStructureProcessStatus;
  bt_structuretransfertype?: BtStructureTransferType;
  bt_structuretype?: BtStructureType;
  bt_verdestructurechangerequestid?: null | string;
  odataApprovalDefinitionHeader?: null | string;
  odataApprovalStatus?: null | string;
  odataCurrentSecurityProfile?: null | string;
  odataDepartment?: null | string;
  odataDivision?: null | string;
  odataEmployee?: null | string;
  odataLegalEntity?: null | string;
  odataOriginator?: null | string;
  odataOriginatorLegalEntity?: null | string;
  odataPosition?: null | string;
  odataPostApprovalDefinitionHeader?: null | string;
  odataPostCurrentSecurityProfile?: null | string;
  odataPostDepartment?: null | string;
  odataPostDivision?: null | string;
  odataPostEmployee?: null | string;
  odataPostLegalEntity?: null | string;
  odataPostOriginator?: null | string;
  odataPostOriginatorLegalEntity?: null | string;
  odataPostPosition?: null | string;
  odataPostSeatBudgetNo?: null | string;
  odataPostSeatNo?: null | string;
  odataPostSecurityProfile?: null | string;
  odataPostToDepartment?: null | string;
  odataPostToDivision?: null | string;
  odataPostToLegalEntity?: null | string;
  odataPostToPosition?: null | string;
  odataSeatBudgetNo?: null | string;
  odataSeatNo?: null | string;
  odataSeatStatus?: null | string;
  odataSecurityProfile?: null | string;
  odataStructureMovementType?: null | string;
  odataStructureProcessStatus?: null | string;
  odataStructureTransferType?: null | string;
  odataStructureType?: null | string;
  odataToDepartment?: null | string;
  odataToDivision?: null | string;
  odataToLegalEntity?: null | string;
  odataToPosition?: null | string;
}
