/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { TasksBaseService } from '../tasks-base-service';
import { TasksApiConfiguration } from '../tasks-api-configuration';
import { TasksStrictHttpResponse } from '../tasks-strict-http-response';
import { TasksRequestBuilder } from '../tasks-request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { TasksCreateManualTaskCommand } from '../models/tasks-create-manual-task-command';
import { TasksCreateTaskFromTaskTemplateCommand } from '../models/tasks-create-task-from-task-template-command';
import { TasksCreateTaskGroupCommand } from '../models/tasks-create-task-group-command';
import { TasksCreateTasksFromTaskGroupTemplateCommand } from '../models/tasks-create-tasks-from-task-group-template-command';
import { TasksCreateVerdeTaskListAssignToCommand } from '../models/tasks-create-verde-task-list-assign-to-command';
import { TasksCreateVerdeTaskListCommand } from '../models/tasks-create-verde-task-list-command';
import { TasksGetTaskGroupTemplateQuery } from '../models/tasks-get-task-group-template-query';
import { TasksGetTaskTemplateInfoQuery } from '../models/tasks-get-task-template-info-query';
import { TasksGetTaskTemplatesFromTaskGroupTemplateQuery } from '../models/tasks-get-task-templates-from-task-group-template-query';
import { TasksGetTasksQuery } from '../models/tasks-get-tasks-query';
import { TasksPatchBulkTasksCommand } from '../models/tasks-patch-bulk-tasks-command';
import { TasksPostTasksCommand } from '../models/tasks-post-tasks-command';
import { TasksTaskGroupTemplateDto } from '../models/tasks-task-group-template-dto';
import { TasksTaskTemplateDto } from '../models/tasks-task-template-dto';
import { TasksTasksExample } from '../models/tasks-tasks-example';

@Injectable({
  providedIn: 'root',
})
export class TasksApiTasksService extends TasksBaseService {
  constructor(
    config: TasksApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getTasks
   */
  static readonly GetTasksPath = '/api/Tasks/get/tasks';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTasks()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getTasks$Response(params?: {
    tenantId?: string;
    body?: TasksGetTasksQuery
  }): Observable<TasksStrictHttpResponse<Array<TasksTasksExample>>> {

    const rb = new TasksRequestBuilder(this.rootUrl, TasksApiTasksService.GetTasksPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as TasksStrictHttpResponse<Array<TasksTasksExample>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTasks$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getTasks(params?: {
    tenantId?: string;
    body?: TasksGetTasksQuery
  }): Observable<Array<TasksTasksExample>> {

    return this.getTasks$Response(params).pipe(
      map((r: TasksStrictHttpResponse<Array<TasksTasksExample>>) => r.body as Array<TasksTasksExample>)
    );
  }

  /**
   * Path part for operation getTaskGroupTemplate
   */
  static readonly GetTaskGroupTemplatePath = '/api/Tasks/get/taskgrouptemplate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTaskGroupTemplate()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getTaskGroupTemplate$Response(params?: {
    tenantId?: string;
    body?: TasksGetTaskGroupTemplateQuery
  }): Observable<TasksStrictHttpResponse<TasksTaskGroupTemplateDto>> {

    const rb = new TasksRequestBuilder(this.rootUrl, TasksApiTasksService.GetTaskGroupTemplatePath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as TasksStrictHttpResponse<TasksTaskGroupTemplateDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTaskGroupTemplate$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getTaskGroupTemplate(params?: {
    tenantId?: string;
    body?: TasksGetTaskGroupTemplateQuery
  }): Observable<TasksTaskGroupTemplateDto> {

    return this.getTaskGroupTemplate$Response(params).pipe(
      map((r: TasksStrictHttpResponse<TasksTaskGroupTemplateDto>) => r.body as TasksTaskGroupTemplateDto)
    );
  }

  /**
   * Path part for operation getTaskTemplateInfo
   */
  static readonly GetTaskTemplateInfoPath = '/api/Tasks/get/tasktemplateinfo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTaskTemplateInfo()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getTaskTemplateInfo$Response(params?: {
    tenantId?: string;
    body?: TasksGetTaskTemplateInfoQuery
  }): Observable<TasksStrictHttpResponse<TasksTaskTemplateDto>> {

    const rb = new TasksRequestBuilder(this.rootUrl, TasksApiTasksService.GetTaskTemplateInfoPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as TasksStrictHttpResponse<TasksTaskTemplateDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTaskTemplateInfo$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getTaskTemplateInfo(params?: {
    tenantId?: string;
    body?: TasksGetTaskTemplateInfoQuery
  }): Observable<TasksTaskTemplateDto> {

    return this.getTaskTemplateInfo$Response(params).pipe(
      map((r: TasksStrictHttpResponse<TasksTaskTemplateDto>) => r.body as TasksTaskTemplateDto)
    );
  }

  /**
   * Path part for operation getTaskTemplatesFromTaskGroupTemplate
   */
  static readonly GetTaskTemplatesFromTaskGroupTemplatePath = '/api/Tasks/get/tasktemplatesfromtaskgrouptemplate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTaskTemplatesFromTaskGroupTemplate()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getTaskTemplatesFromTaskGroupTemplate$Response(params?: {
    tenantId?: string;
    body?: TasksGetTaskTemplatesFromTaskGroupTemplateQuery
  }): Observable<TasksStrictHttpResponse<Array<TasksTaskTemplateDto>>> {

    const rb = new TasksRequestBuilder(this.rootUrl, TasksApiTasksService.GetTaskTemplatesFromTaskGroupTemplatePath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as TasksStrictHttpResponse<Array<TasksTaskTemplateDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTaskTemplatesFromTaskGroupTemplate$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getTaskTemplatesFromTaskGroupTemplate(params?: {
    tenantId?: string;
    body?: TasksGetTaskTemplatesFromTaskGroupTemplateQuery
  }): Observable<Array<TasksTaskTemplateDto>> {

    return this.getTaskTemplatesFromTaskGroupTemplate$Response(params).pipe(
      map((r: TasksStrictHttpResponse<Array<TasksTaskTemplateDto>>) => r.body as Array<TasksTaskTemplateDto>)
    );
  }

  /**
   * Path part for operation postTasks
   */
  static readonly PostTasksPath = '/api/Tasks/post/tasks';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postTasks()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postTasks$Response(params?: {
    tenantId?: string;
    body?: TasksPostTasksCommand
  }): Observable<TasksStrictHttpResponse<boolean>> {

    const rb = new TasksRequestBuilder(this.rootUrl, TasksApiTasksService.PostTasksPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as TasksStrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postTasks$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postTasks(params?: {
    tenantId?: string;
    body?: TasksPostTasksCommand
  }): Observable<boolean> {

    return this.postTasks$Response(params).pipe(
      map((r: TasksStrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation createManualTask
   */
  static readonly CreateManualTaskPath = '/api/Tasks/post/manualtask';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createManualTask()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createManualTask$Response(params?: {
    tenantId?: string;
    body?: TasksCreateManualTaskCommand
  }): Observable<TasksStrictHttpResponse<string>> {

    const rb = new TasksRequestBuilder(this.rootUrl, TasksApiTasksService.CreateManualTaskPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as TasksStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createManualTask$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createManualTask(params?: {
    tenantId?: string;
    body?: TasksCreateManualTaskCommand
  }): Observable<string> {

    return this.createManualTask$Response(params).pipe(
      map((r: TasksStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation createTaskFromTaskTemplate
   */
  static readonly CreateTaskFromTaskTemplatePath = '/api/Tasks/post/taskfromtasktemplate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTaskFromTaskTemplate()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createTaskFromTaskTemplate$Response(params?: {
    tenantId?: string;
    body?: TasksCreateTaskFromTaskTemplateCommand
  }): Observable<TasksStrictHttpResponse<string>> {

    const rb = new TasksRequestBuilder(this.rootUrl, TasksApiTasksService.CreateTaskFromTaskTemplatePath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as TasksStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createTaskFromTaskTemplate$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createTaskFromTaskTemplate(params?: {
    tenantId?: string;
    body?: TasksCreateTaskFromTaskTemplateCommand
  }): Observable<string> {

    return this.createTaskFromTaskTemplate$Response(params).pipe(
      map((r: TasksStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation createTaskGroup
   */
  static readonly CreateTaskGroupPath = '/api/Tasks/post/taskgroup';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTaskGroup()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createTaskGroup$Response(params?: {
    tenantId?: string;
    body?: TasksCreateTaskGroupCommand
  }): Observable<TasksStrictHttpResponse<string>> {

    const rb = new TasksRequestBuilder(this.rootUrl, TasksApiTasksService.CreateTaskGroupPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as TasksStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createTaskGroup$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createTaskGroup(params?: {
    tenantId?: string;
    body?: TasksCreateTaskGroupCommand
  }): Observable<string> {

    return this.createTaskGroup$Response(params).pipe(
      map((r: TasksStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation createTasksFromTaskGroupTemplate
   */
  static readonly CreateTasksFromTaskGroupTemplatePath = '/api/Tasks/post/tasksfromtaskgrouptemplate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTasksFromTaskGroupTemplate()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createTasksFromTaskGroupTemplate$Response(params?: {
    tenantId?: string;
    body?: TasksCreateTasksFromTaskGroupTemplateCommand
  }): Observable<TasksStrictHttpResponse<boolean>> {

    const rb = new TasksRequestBuilder(this.rootUrl, TasksApiTasksService.CreateTasksFromTaskGroupTemplatePath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as TasksStrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createTasksFromTaskGroupTemplate$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createTasksFromTaskGroupTemplate(params?: {
    tenantId?: string;
    body?: TasksCreateTasksFromTaskGroupTemplateCommand
  }): Observable<boolean> {

    return this.createTasksFromTaskGroupTemplate$Response(params).pipe(
      map((r: TasksStrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation createVerdeTaskListAssignTo
   */
  static readonly CreateVerdeTaskListAssignToPath = '/api/Tasks/post/verdetasklistassignto';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createVerdeTaskListAssignTo()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createVerdeTaskListAssignTo$Response(params?: {
    tenantId?: string;
    body?: TasksCreateVerdeTaskListAssignToCommand
  }): Observable<TasksStrictHttpResponse<boolean>> {

    const rb = new TasksRequestBuilder(this.rootUrl, TasksApiTasksService.CreateVerdeTaskListAssignToPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as TasksStrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createVerdeTaskListAssignTo$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createVerdeTaskListAssignTo(params?: {
    tenantId?: string;
    body?: TasksCreateVerdeTaskListAssignToCommand
  }): Observable<boolean> {

    return this.createVerdeTaskListAssignTo$Response(params).pipe(
      map((r: TasksStrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation createVerdeTaskList
   */
  static readonly CreateVerdeTaskListPath = '/api/Tasks/post/verdetasklist';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createVerdeTaskList()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createVerdeTaskList$Response(params?: {
    tenantId?: string;
    body?: TasksCreateVerdeTaskListCommand
  }): Observable<TasksStrictHttpResponse<string>> {

    const rb = new TasksRequestBuilder(this.rootUrl, TasksApiTasksService.CreateVerdeTaskListPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as TasksStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createVerdeTaskList$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createVerdeTaskList(params?: {
    tenantId?: string;
    body?: TasksCreateVerdeTaskListCommand
  }): Observable<string> {

    return this.createVerdeTaskList$Response(params).pipe(
      map((r: TasksStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation patchBulkTasks
   */
  static readonly PatchBulkTasksPath = '/api/Tasks/patch/BulkTasks';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchBulkTasks()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchBulkTasks$Response(params?: {
    tenantId?: string;
    body?: TasksPatchBulkTasksCommand
  }): Observable<TasksStrictHttpResponse<Array<string>>> {

    const rb = new TasksRequestBuilder(this.rootUrl, TasksApiTasksService.PatchBulkTasksPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as TasksStrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchBulkTasks$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchBulkTasks(params?: {
    tenantId?: string;
    body?: TasksPatchBulkTasksCommand
  }): Observable<Array<string>> {

    return this.patchBulkTasks$Response(params).pipe(
      map((r: TasksStrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

}
