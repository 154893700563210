/* tslint:disable */
/* eslint-disable */
export interface StructureActivityModel {
  '_bt_activity_value'?: null | string;
  '_bt_department_value'?: null | string;
  '_bt_division_value'?: null | string;
  '_bt_group_value'?: null | string;
  '_bt_legalentity_value'?: null | string;
  '_bt_structureentity_value'?: null | string;
  bt_alldepartments?: boolean;
  bt_alldivisions?: boolean;
  bt_verdetimeentrystructureactivity?: null | string;
  bt_verdetimeentrystructureactivityid?: null | string;
  odataPostbt_activity_value?: null | string;
  odataPostbt_department_value?: null | string;
  odataPostbt_division_value?: null | string;
  odataPostbt_group_value?: null | string;
  odataPostbt_legalentity_value?: null | string;
  odataPostbt_structureentity_value?: null | string;
  odatabt_activity_value?: null | string;
  odatabt_department_value?: null | string;
  odatabt_division_value?: null | string;
  odatabt_group_value?: null | string;
  odatabt_legalentity_value?: null | string;
  odatabt_structureentity_value?: null | string;
}
