/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BookingAvailabilitiesFiltered } from '../models/booking-availabilities-filtered';

@Injectable({
  providedIn: 'root',
})
export class SharedApiBookingAvailabilitiesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getBookingAvailabilities
   */
  static readonly GetBookingAvailabilitiesPath = '/api/BookingAvailabilities/bookingavailabilitiesbylegalentity/{legalEntity}/{bookingtypeid}/{primarylocationid}/{officeid}/{subofficeid}/{fromdate}/{todate}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBookingAvailabilities()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBookingAvailabilities$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntity: string;
    bookingtypeid: string;
    primarylocationid: string;
    officeid: string;
    subofficeid: string;
    fromdate: string;
    todate: string;
  }): Observable<StrictHttpResponse<Array<BookingAvailabilitiesFiltered>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiBookingAvailabilitiesService.GetBookingAvailabilitiesPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntity', params.legalEntity, {});
      rb.path('bookingtypeid', params.bookingtypeid, {});
      rb.path('primarylocationid', params.primarylocationid, {});
      rb.path('officeid', params.officeid, {});
      rb.path('subofficeid', params.subofficeid, {});
      rb.path('fromdate', params.fromdate, {});
      rb.path('todate', params.todate, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BookingAvailabilitiesFiltered>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getBookingAvailabilities$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBookingAvailabilities(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntity: string;
    bookingtypeid: string;
    primarylocationid: string;
    officeid: string;
    subofficeid: string;
    fromdate: string;
    todate: string;
  }): Observable<Array<BookingAvailabilitiesFiltered>> {

    return this.getBookingAvailabilities$Response(params).pipe(
      map((r: StrictHttpResponse<Array<BookingAvailabilitiesFiltered>>) => r.body as Array<BookingAvailabilitiesFiltered>)
    );
  }

}
