<!-- Rendom Button -->
<button hidden id="showApprovalModal" (click)="getVerdeTaskApprovalByID(timeoffApprovalForm)"></button>
<!-- #region Time Off Approval Modal -->
<verde-modal
  id="newTimeoffApprovalModal"
  modalTitle="Approve / Reject Time Off"
  [modalMinWidth]="750"
  [modalMaxWidth]="750"
  submitButton="{{ approveButtonText }}"
  (actionButton)="patchTimeOffRequestApproval(timeoffApprovalForm)"
  (modalCancelled)="hideModal($event)"
  [submitActive]="timeoffApprovalForm.valid && allowApproval && (agreedYes || agreedNo)"
  submitConfirmationMessage="Please confirm that you would like to {{ confirmationAction }} this time off request"
  cancelConfirmationMessage="Please confirm that you would like to cancel"
  [hasSubmitConfirmation]="approverType === 'Manager' || agreedNo"
  [hasCancelConfirmation]="timeoffApprovalForm.touched && !timeoffApprovalForm.pristine && !showCalculationComment"
  [hasSecondCancelButton]="showErrorRetryButton"
  secondCancelText="Retry"
  (modalSecondCancelled)="waitForCompletedData()"
  [closeOnSecondCancelButtonClick]="false"
>
  <form #timeoffApprovalForm="ngForm" novalidate autocomplete="off" style="position: relative">
    <ngx-spinner
      [disableAnimation]="disableAnimation"
      name="timeoffapproval"
      bdColor="rgb(237, 237, 237)"
      size="default"
      color="#6264a7"
      type="ball-clip-rotate"
      [fullScreen]="false"
    >
      <p style="font-size: 20px; color: black; margin-top: 35px; text-align: center">Please be patient...</p>
    </ngx-spinner>

    <kendo-tabstrip (tabSelect)="onTabSelect($event.index)">
      <kendo-tabstrip-tab title="Approval" [selected]="true">
        <ng-template kendoTabContent>
          <div class="modal_grid">
            <div class="form-group" [ngStyle]="{ 'grid-column': (deviceTypeService.isMobile$ | async) ? 'auto / span 1' : 'auto / span 2' }">
              <label for="approveEmployee">Employee:</label>
              <kendo-textbox [readonly]="true" id="approveEmployee" name="approveEmployee" [ngModel]="employeeFullName"> </kendo-textbox>
            </div>

            <div
              *ngIf="showErrorRetryButton"
              class="alert alert-danger"
              style="padding: 8px 20px; height: 38px; margin-top: 28px; text-align: center"
              [ngStyle]="{ 'grid-column': (deviceTypeService.isMobile$ | async) ? 'auto / span 1' : 'auto / span 4' }"
            >
              > There was a problem. Please try again.
            </div>

            <div
              *ngIf="showCalculationComment && selectedTimeOffRequest?.bt_calculationcomment != null && selectedTimeOffRequest?.bt_calculationcomment != ''"
              class="alert alert-danger"
              style="padding: 12px 20px; height: fit-content; text-align: center"
              [ngStyle]="{ 'grid-column': (deviceTypeService.isMobile$ | async) ? 'auto / span 1' : 'auto / span 4' }"
            >
              {{ selectedTimeOffRequest?.bt_calculationcomment }}
            </div>

            <div
              *ngIf="!showCalculationComment && !showErrorRetryButton"
              class="form-group"
              [ngStyle]="{ 'grid-column': (deviceTypeService.isMobile$ | async) ? 'auto / span 1' : 'auto / span 2' }"
            >
              <label for="approveTimeOffTransactionType">Transaction Type:</label>
              <kendo-textbox
                [readonly]="true"
                id="approveTimeOffTransactionType"
                name="approveTimeOffTransactionType"
                [ngModel]="selectedTimeOffRequest.odataTxnType"
              >
              </kendo-textbox>
            </div>

            <div
              *ngIf="!showCalculationComment && !showErrorRetryButton"
              [ngStyle]="{ 'grid-column': (deviceTypeService.isMobile$ | async) ? 'auto / span 1' : 'auto / span 2' }"
            ></div>

            <div class="form-group" [ngStyle]="{ 'grid-column': (deviceTypeService.isMobile$ | async) ? 'auto / span 1' : 'auto / span 2' }">
              <label for="approveTimeOffType">Time Off Type:</label>
              <kendo-textbox [readonly]="true" id="approveTimeOffType" name="approveTimeOffType" [ngModel]="selectedTimeOffRequest.odataTimeOffCheck">
              </kendo-textbox>
            </div>

            <div class="form-group" [ngStyle]="{ 'grid-column': (deviceTypeService.isMobile$ | async) ? 'auto / span 1' : 'auto / span 2' }">
              <label for="approveStart">Start Date:</label>
              <kendo-textbox [readonly]="true" id="approveStart" name="approveStart" [ngModel]="approveStartDate"> </kendo-textbox>
            </div>

            <div
              class="form-group"
              style="margin-right: 6px"
              [ngStyle]="{ 'grid-column': (deviceTypeService.isMobile$ | async) ? 'auto / span 1' : 'auto / span 2' }"
            >
              <label for="approveEnd">End Date:</label>
              <kendo-textbox [readonly]="true" id="approveEnd" name="approveEnd" [ngModel]="approveEndDate"> </kendo-textbox>
            </div>

            <div class="form-group" [ngStyle]="{ 'grid-column': (deviceTypeService.isMobile$ | async) ? 'auto / span 1' : 'auto / span 2' }">
              <label for="approveDaysAvailable">Days Available:</label>
              <kendo-textbox
                [readonly]="true"
                id="approveDaysAvailable"
                name="approveDaysAvailable"
                [ngModel]="selectedTimeOffRequest.bthr_remainingbeforetimeoff"
              >
              </kendo-textbox>
            </div>

            <div class="form-group" [ngStyle]="{ 'grid-column': (deviceTypeService.isMobile$ | async) ? 'auto / span 1' : 'auto / span 2' }">
              <label for="approveDays">Requested Days:</label>
              <kendo-textbox [readonly]="true" id="approveDays" name="approveDays" [ngModel]="selectedTimeOffRequest.bthr_leavedays"> </kendo-textbox>
            </div>

            <div
              class="form-group"
              style="margin-right: 6px"
              [ngStyle]="{ 'grid-column': (deviceTypeService.isMobile$ | async) ? 'auto / span 1' : 'auto / span 2' }"
            >
              <label for="approveDaysRemaining">Days Remaining:</label>
              <kendo-textbox
                [readonly]="true"
                style="text-align: right !important; margin-right: 5px"
                id="approveDaysRemaining"
                name="approveDaysRemaining"
                [ngModel]="selectedTimeOffRequest.bthr_remainingbeforeaftertimeoff"
              >
              </kendo-textbox>
            </div>

            <div
              *ngIf="portionOfDayRequest"
              class="form-group"
              [ngStyle]="{ 'grid-column': (deviceTypeService.isMobile$ | async) ? 'auto / span 1' : 'auto / span 2' }"
            >
              <label for="approvePortion">Portion of Day:</label>
              <kendo-textbox [readonly]="true" id="approvePortion" name="approvePortion" [ngModel]="selectedTimeOffRequest.odataPartDayRequest">
              </kendo-textbox>
            </div>

            <div *ngIf="portionOfDayRequest && !(deviceTypeService.isMobile$ | async)"></div>
            <div *ngIf="portionOfDayRequest && !(deviceTypeService.isMobile$ | async)"></div>
            <div *ngIf="portionOfDayRequest && !(deviceTypeService.isMobile$ | async)"></div>
            <div *ngIf="portionOfDayRequest && !(deviceTypeService.isMobile$ | async)"></div>

            <div *ngIf="!(deviceTypeService.isMobile$ | async)" class="form-group" [ngStyle]="{ 'grid-column': agreedNo ? 'auto / span 3' : 'auto / span 6' }">
              <label for="approveRequestReason">Reason For Request:</label>
              <kendo-textarea
                id="approveRequestReason"
                name="approveRequestReason"
                resizable="vertical"
                [readonly]="true"
                [ngModel]="selectedTimeOffRequest.bthr_comments"
              >
              </kendo-textarea>
            </div>

            <div *ngIf="deviceTypeService.isMobile$ | async" class="form-group" style="grid-column: auto / span 1">
              <label for="approveRequestReason">Reason For Request:</label>
              <kendo-textarea
                id="approveRequestReason"
                name="approveRequestReason"
                resizable="vertical"
                [readonly]="true"
                [ngModel]="selectedTimeOffRequest.bthr_comments"
              >
              </kendo-textarea>
            </div>

            <div *ngIf="agreedNo" class="form-group" [ngStyle]="{ 'grid-column': (deviceTypeService.isMobile$ | async) ? 'auto / span 1' : 'auto / span 3' }">
              <label for="approveRequestComment">Reason for not approving:</label>
              <kendo-textarea
                id="approveRequestComment"
                name="approveRequestComment"
                #varApproveRequestComment="ngModel"
                maxlength="300"
                resizable="vertical"
                required
                [ngModel]
              >
              </kendo-textarea>
              <div class="alert alert-danger" *ngIf="varApproveRequestComment.touched && !varApproveRequestComment.valid">
                <div *ngIf="varApproveRequestComment.errors?.required">Field is required!</div>
                <div *ngIf="varApproveRequestComment.errors?.minlength">Enter a detailed reason.</div>
              </div>
            </div>

            <div
              class="form-group"
              style="margin-right: 6px"
              [ngStyle]="{ 'grid-column': (deviceTypeService.isMobile$ | async) ? 'auto / span 1' : 'auto / span 6' }"
            >
              <div style="float: right; display: inline-flex">
                <label *ngIf="selectedTimeOffRequest._bt_linkedtransaction_value === null" style="margin-bottom: unset">Approve this request:</label>
                <div style="display: block; max-width: 500px">
                  <label *ngIf="selectedTimeOffRequest._bt_linkedtransaction_value !== null" style="margin-bottom: unset">Approve this reversal request:</label>
                </div>

                <div style="margin-left: 5px">
                  <label style="margin-bottom: unset" for="agreedYes">Yes:</label>
                  <input
                    style="width: 25px; height: 20px; margin: 0 0 0 5px; vertical-align: middle"
                    (change)="agreedYesChange($event.target.checked, varAgreedNo)"
                    type="checkbox"
                    kendoCheckBox
                    class="form-control"
                    id="agreedYes"
                    name="agreedYes"
                    #varAgreedYes="ngModel"
                    [ngModel]="agreedYes"
                  />

                  <label for="agreedNo" style="margin-left: 5px; margin-bottom: unset">No:</label>
                  <input
                    style="width: 25px; height: 20px; margin: 0 0 0 5px; vertical-align: middle"
                    (change)="agreedNoChange($event.target.checked, varAgreedYes)"
                    type="checkbox"
                    kendoCheckBox
                    class="form-control"
                    id="agreedNo"
                    name="agreedNo"
                    #varAgreedNo="ngModel"
                    [ngModel]="agreedNo"
                  />
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab *ngIf="selectedTimeOffRequest.bthr_supportingnote_name !== null" title="Supporting Note">
        <ng-template kendoTabContent>
          <div id="pdf-div" class="pdf-view"></div>
        </ng-template>
      </kendo-tabstrip-tab>
    </kendo-tabstrip>
  </form>
</verde-modal>

<div *ngIf="showModal == false">
  <form #timeoffApprovalForm="ngForm" novalidate autocomplete="off" style="position: relative">
    <ngx-spinner
      [disableAnimation]="disableAnimation"
      name="timeoffapprovalNonModal"
      bdColor="rgb(237, 237, 237)"
      size="default"
      color="#6264a7"
      type="ball-clip-rotate"
      [fullScreen]="false"
    >
      <p style="font-size: 20px; color: black; margin-top: 35px; text-align: center">Please be patient...</p>
    </ngx-spinner>

    <kendo-tabstrip (tabSelect)="onTabSelect($event.index)">
      <!-- #region Approval Tab  -->
      <kendo-tabstrip-tab title="Approval" [selected]="true">
        <ng-template kendoTabContent>
          <div class="modal_grid">
            <div *ngIf="showErrorRetryButton" class="alert alert-danger" style="padding: 8px 20px; height: 38px; margin-top: 28px; text-align: center">
              > There was a problem. Please try again.
            </div>
            <div class="form-group">
              <label for="approveEmployee">Employee:</label>
              <kendo-textbox [readonly]="true" id="approveEmployee" name="approveEmployee" [ngModel]="employeeFullName"> </kendo-textbox>
            </div>

            <div
              *ngIf="showCalculationComment && selectedTimeOffRequest?.bt_calculationcomment != null && selectedTimeOffRequest?.bt_calculationcomment != ''"
              class="alert alert-danger"
              style="padding: 12px 20px; height: fit-content; text-align: center"
            >
              {{ selectedTimeOffRequest?.bt_calculationcomment }}
            </div>

            <div></div>

            <div *ngIf="!showCalculationComment && !showErrorRetryButton" class="form-group">
              <label for="approveTimeOffTransactionType">Transaction Type:</label>
              <kendo-textbox
                [readonly]="true"
                id="approveTimeOffTransactionType"
                name="approveTimeOffTransactionType"
                [ngModel]="selectedTimeOffRequest.odataTxnType"
              >
              </kendo-textbox>
            </div>

            <div class="form-group">
              <label for="approveTimeOffType">Time Off Type:</label>
              <kendo-textbox [readonly]="true" id="approveTimeOffType" name="approveTimeOffType" [ngModel]="selectedTimeOffRequest.odataTimeOffCheck">
              </kendo-textbox>
            </div>

            <div class="form-group">
              <label for="approveStart">Start Date:</label>
              <kendo-textbox [readonly]="true" id="approveStart" name="approveStart" [ngModel]="approveStartDate"> </kendo-textbox>
            </div>

            <div class="form-group" style="margin-right: 6px">
              <label for="approveEnd">End Date:</label>
              <kendo-textbox [readonly]="true" id="approveEnd" name="approveEnd" [ngModel]="approveEndDate"> </kendo-textbox>
            </div>

            <div class="form-group">
              <label for="approveDays">Requested Days:</label>
              <kendo-textbox [readonly]="true" id="approveDays" name="approveDays" [ngModel]="selectedTimeOffRequest.bthr_leavedays"> </kendo-textbox>
            </div>

            <div class="form-group" style="margin-right: 6px">
              <label for="approveDaysRemaining">Days Remaining:</label>
              <kendo-textbox
                [readonly]="true"
                style="text-align: right !important; margin-right: 5px"
                id="approveDaysRemaining"
                name="approveDaysRemaining"
                [ngModel]="selectedTimeOffRequest.bthr_remainingbeforeaftertimeoff"
              >
              </kendo-textbox>
            </div>

            <div *ngIf="portionOfDayRequest" class="form-group">
              <label for="approvePortion">Portion of Day:</label>
              <kendo-textbox [readonly]="true" id="approvePortion" name="approvePortion" [ngModel]="selectedTimeOffRequest.odataPartDayRequest">
              </kendo-textbox>
            </div>

            <div *ngIf="!(deviceTypeService.isMobile$ | async)" class="form-group">
              <label for="approveRequestReason">Reason For Request:</label>
              <kendo-textarea
                id="approveRequestReason"
                name="approveRequestReason"
                resizable="vertical"
                [readonly]="true"
                [ngModel]="selectedTimeOffRequest.bthr_comments"
              >
              </kendo-textarea>
            </div>

            <div *ngIf="deviceTypeService.isMobile$ | async" class="form-group">
              <label for="approveRequestReason">Reason For Request:</label>
              <kendo-textarea
                id="approveRequestReason"
                name="approveRequestReason"
                resizable="vertical"
                [readonly]="true"
                [ngModel]="selectedTimeOffRequest.bthr_comments"
              >
              </kendo-textarea>
            </div>

            <div class="form-group" style="margin-right: 6px; grid-column: auto / span 2">
              <div style="display: inline-flex">
                <label *ngIf="selectedTimeOffRequest._bt_linkedtransaction_value === null" style="margin-bottom: unset">Approve this request:</label>
                <div style="display: block; max-width: 500px">
                  <label *ngIf="selectedTimeOffRequest._bt_linkedtransaction_value !== null" style="margin-bottom: unset">Approve this reversal request:</label>
                </div>

                <div style="margin-left: 5px">
                  <label style="margin-bottom: unset" for="agreedYes">Yes:</label>
                  <input
                    style="width: 25px; height: 20px; margin: 0 0 0 5px; vertical-align: middle"
                    (change)="agreedYesChange($event.target.checked, varAgreedNo)"
                    type="checkbox"
                    kendoCheckBox
                    class="form-control"
                    id="agreedYes"
                    name="agreedYes"
                    #varAgreedYes="ngModel"
                    [ngModel]="agreedYes"
                  />

                  <label for="agreedNo" style="margin-left: 5px; margin-bottom: unset">No:</label>
                  <input
                    style="width: 25px; height: 20px; margin: 0 0 0 5px; vertical-align: middle"
                    (change)="agreedNoChange($event.target.checked, varAgreedYes)"
                    type="checkbox"
                    kendoCheckBox
                    class="form-control"
                    id="agreedNo"
                    name="agreedNo"
                    #varAgreedNo="ngModel"
                    [ngModel]="agreedNo"
                  />
                </div>
              </div>
            </div>

            <div *ngIf="agreedNo" class="form-group" style="grid-column: auto / span 2">
              <label for="approveRequestComment">Reason for not approving:</label>
              <kendo-textarea
                id="approveRequestComment"
                name="approveRequestComment"
                #varApproveRequestComment="ngModel"
                maxlength="300"
                resizable="vertical"
                required
                [ngModel]
              >
              </kendo-textarea>
              <!--Validation -->
              <div class="alert alert-danger" *ngIf="varApproveRequestComment.touched && !varApproveRequestComment.valid">
                <div *ngIf="varApproveRequestComment.errors?.required">Field is required!</div>
                <div *ngIf="varApproveRequestComment.errors?.minlength">Enter a detailed reason.</div>
              </div>
            </div>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
      <!-- #endregion Approval Tab  -->
      <!-- #region Supporting Note Tab  -->
      <kendo-tabstrip-tab *ngIf="selectedTimeOffRequest.bthr_supportingnote_name !== null" title="Supporting Note">
        <ng-template kendoTabContent>
          <div id="pdf-div" class="pdf-view"></div>
        </ng-template>
      </kendo-tabstrip-tab>
      <!-- #endregion Supporting Note Tab  -->
    </kendo-tabstrip>
  </form>
  <button style="margin-top: 30px; position: absolute; right: 0; margin-right: 10px" [disabled]="!enableSubmit" kendoButton (click)="openConfirmModal()">
    Submit
  </button>
</div>

<!-- #endregion Time Off Approval Modal -->
<!-- #region Confirmation Modal -->
<verde-modal
  modalTitle="Confirmation"
  id="confirmTimeOffApprovalModal"
  [modalMaxWidth]="400"
  [hasCancelButton]="true"
  submitButton="Submit"
  (actionButton)="patchTimeOffRequestApproval(timeoffApprovalForm)"
  [zIndex]="10001"
>
  <div style="position: relative; height: 60px">
    <ngx-spinner
      [disableAnimation]="disableAnimation"
      name="confirmtimeoffapproval"
      bdColor="rgb(237, 237, 237)"
      size="default"
      color="#6264a7"
      type="ball-clip-rotate"
      [fullScreen]="false"
    >
    </ngx-spinner>

    <p>Please confirm that you would like to {{ confirmationAction }} this time off request</p>
  </div>
</verde-modal>
<!-- #endregion Confirmation Modal -->
<!-- #region Error Modal -->
<verde-modal
  modalTitle="Error"
  id="timeOffApprovalErrorModal"
  [modalMaxWidth]="400"
  [hasCancelButton]="false"
  submitButton="Continue"
  (actionButton)="hideModal(false)"
  [zIndex]="10001"
>
  <p>{{ errorMessage }}</p>
</verde-modal>
<!-- #endregion Error Modal -->
