/* tslint:disable */
/* eslint-disable */
import { DocumentBtImportProcessStatus } from './document-bt-import-process-status';
export interface DocumentBtPayrollmportDetail {
  bt_ImportByEntity?: null | string;
  bt_ImportedBy?: null | string;
  bt_PayrollImportHeaderNo?: null | string;
  bt_PayrollmportDetailID?: null | string;
  bt_comment?: null | string;
  bt_importstatus?: DocumentBtImportProcessStatus;
  bt_numberofdetailrecords?: null | number;
  bt_numberofdetailrecordsimported?: null | number;
  bt_overwrite?: null | boolean;
  bt_payrollimportdetailno?: null | string;
  createdOn?: null | string;
  headernoString?: null | string;
  importedByName?: null | string;
  importedEntityName?: null | string;
}
