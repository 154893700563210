import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { ModalService } from '@verde/shared';
import { HttpCancelService } from '@verde/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PositionService } from 'apps/verde/src/app/features/structure-management/services/position.service';
import { StructureFullExtended } from 'apps/verde/src/app/features/structure-management/store/models/structure-full-extended.model';
import { StructurePositionExtended } from 'apps/verde/src/app/features/structure-management/store/models/structure-position-extended.model';

@Component({
  selector: 'verde-position-list',
  templateUrl: './position-list.component.html',
  styleUrls: ['./position-list.component.scss'],
})
export class PositionListComponent implements OnDestroy {
  @Input() miniStructure: StructureFullExtended[];
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter();

  private onDestroy$ = new Subject<boolean>();
  positions: StructurePositionExtended[];

  display: string = 'positionList';

  constructor(private modalService: ModalService, private positionService: PositionService, private httpCancelService: HttpCancelService) {
    this.positionService.allEntityPositions$.pipe(takeUntil(this.onDestroy$)).subscribe((positions: StructurePositionExtended[]) => {
      this.positions = positions;
    });
  }

  add() {
    //this.modalService.open('positionChangeRequestModal');
    //this.modalService.close('positionListModal');

    this.display = 'changeRequest';
  }

  close() {
    this.closeModal.emit();
  }

  rowClass({ dataItem }): any {
    return {
      'background-row-new': dataItem.$new,
      'background-row-disposed': dataItem.$dispose,
    };
  }

  ngOnDestroy(): void {
    this.httpCancelService.cancelPendingRequests();

    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
