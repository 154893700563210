/* tslint:disable */
/* eslint-disable */
export interface EmployeeSymptom {
  bt_bodyaches?: null | boolean;
  bt_contacttrace?: null | boolean;
  bt_contactwithcontact?: null | boolean;
  bt_contactwithcovid?: null | boolean;
  bt_cough?: null | boolean;
  bt_covid?: null | boolean;
  bt_date?: null | string;
  bt_diagnosedwithcovid?: null | boolean;
  bt_diarrhoea?: null | boolean;
  bt_fatigue?: null | boolean;
  bt_feelingfine?: null | boolean;
  bt_fever?: null | boolean;
  bt_headache?: null | boolean;
  bt_isproxy?: null | boolean;
  bt_lossofsmell?: null | boolean;
  bt_medicalcert?: null | boolean;
  bt_nausea?: null | boolean;
  bt_presencestatustext?: null | string;
  bt_recordstatustext?: null | string;
  bt_rednessofeyes?: null | boolean;
  bt_shortnessofbreath?: null | boolean;
  bt_sorethroat?: null | boolean;
  bt_temperature?: null | number;
  bt_title?: null | string;
  bt_usersymptomsid?: null | string;
  bt_visittreatment?: null | boolean;
  bt_waitingforresults?: null | boolean;
  bthr_experiencingsymptoms?: null | boolean;
  createdon?: null | string;
  odataEmployee?: null | string;
  odataLegalEntity?: null | string;
  odataLocation?: null | string;
  odataProxy?: null | string;
  odatabodyaches?: null | string;
  odatacontacttrace?: null | string;
  odatacontactwithcontact?: null | string;
  odatacontactwithcovid?: null | string;
  odatacough?: null | string;
  odatacovid?: null | string;
  odatadiagnosedwithcovid?: null | string;
  odatadiarrhoea?: null | string;
  odataexperiencingsymptoms?: null | string;
  odatafatigue?: null | string;
  odatafeelingfine?: null | string;
  odatafever?: null | string;
  odataheadache?: null | string;
  odatalossofsmell?: null | string;
  odatamedicalcert?: null | string;
  odatanausea?: null | string;
  odatarednessofeyes?: null | string;
  odatashortnessofbreath?: null | string;
  odatasorethroat?: null | string;
  odatavisittreatment?: null | string;
  odatawaitingforresults?: null | string;
}
