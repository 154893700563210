/* tslint:disable */
/* eslint-disable */
export interface VerdeSupplierReason {
  '_bt_legalentity_value'?: null | string;
  bt_autoapprove?: null | boolean;
  bt_maintenanceupdate?: null | boolean;
  bt_verdefleetsupplierreason?: null | string;
  bt_verdefleetsupplierreasonid?: null | string;
  odataLegalEntity?: null | string;
}
