/* tslint:disable */
/* eslint-disable */
export interface BtIntegrationSource {
  '_bt_legalentity_value'?: null | string;
  bt_apiurl?: null | any;
  bt_authenticationrequired?: boolean;
  bt_clientid?: null | any;
  bt_clientsecret?: null | any;
  bt_description?: null | string;
  bt_detail?: boolean;
  bt_encryptionrequired?: boolean;
  bt_field1data?: null | any;
  bt_field1label?: null | any;
  bt_header?: boolean;
  bt_integrationtype?: number;
  bt_noofdetails?: number;
  bt_noofheaders?: number;
  bt_numberoffiles?: number;
  bt_password?: null | any;
  bt_scope?: null | any;
  bt_type?: null | any;
  bt_username?: null | any;
  bt_verdeintegrationsource?: null | string;
  bt_verdeintegrationsourceid?: null | string;
}
