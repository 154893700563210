/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { PerformanceReviewBaseService } from '../performance-review-base-service';
import { PerformanceReviewApiConfiguration } from '../performance-review-api-configuration';
import { PerformanceReviewStrictHttpResponse } from '../performance-review-strict-http-response';
import { PerformanceReviewRequestBuilder } from '../performance-review-request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PerformanceReviewDvBusinessCycleTypes } from '../models/performance-review-dv-business-cycle-types';
import { PerformanceReviewDvBusinessCycles } from '../models/performance-review-dv-business-cycles';
import { PerformanceReviewDvPerformanceReview } from '../models/performance-review-dv-performance-review';
import { PerformanceReviewDvReviewPeriods } from '../models/performance-review-dv-review-periods';
import { PerformanceReviewEmployeeReviewFiltered } from '../models/performance-review-employee-review-filtered';
import { PerformanceReviewEnhancedReviewDetail } from '../models/performance-review-enhanced-review-detail';
import { PerformanceReviewGetAllBusinessCycleTypesQuery } from '../models/performance-review-get-all-business-cycle-types-query';
import { PerformanceReviewGetAllBusinessCyclesQuery } from '../models/performance-review-get-all-business-cycles-query';
import { PerformanceReviewGetAllPerformanceReviewRecordsQuery } from '../models/performance-review-get-all-performance-review-records-query';
import { PerformanceReviewGetAllReviewPeriodsQuery } from '../models/performance-review-get-all-review-periods-query';
import { PerformanceReviewGetEnhancedPerformanceReviewEmployeeQuery } from '../models/performance-review-get-enhanced-performance-review-employee-query';
import { PerformanceReviewGetEnhancedPerformanceReviewManagerQuery } from '../models/performance-review-get-enhanced-performance-review-manager-query';
import { PerformanceReviewGetEnhancedReviewDetailOverviewQuery } from '../models/performance-review-get-enhanced-review-detail-overview-query';
import { PerformanceReviewGetEnhancedReviewDetailQuery } from '../models/performance-review-get-enhanced-review-detail-query';
import { PerformanceReviewGetManagerReviewSummariesQuery } from '../models/performance-review-get-manager-review-summaries-query';
import { PerformanceReviewGetManagerUpdatedReviewQuery } from '../models/performance-review-get-manager-updated-review-query';
import { PerformanceReviewGetReviewsAndSummariesQuery } from '../models/performance-review-get-reviews-and-summaries-query';
import { PerformanceReviewGetUpdatedReviewQuery } from '../models/performance-review-get-updated-review-query';
import { PerformanceReviewPatchEnhancedReviewDetailCommand } from '../models/performance-review-patch-enhanced-review-detail-command';
import { PerformanceReviewPatchEnhancedReviewDetailSummaryCommand } from '../models/performance-review-patch-enhanced-review-detail-summary-command';
import { PerformanceReviewPatchPerformanceReviewsCommand } from '../models/performance-review-patch-performance-reviews-command';
import { PerformanceReviewPerformanceReviewsFiltered } from '../models/performance-review-performance-reviews-filtered';
import { PerformanceReviewReviewSummariesFiltered } from '../models/performance-review-review-summaries-filtered';

@Injectable({
  providedIn: 'root',
})
export class PerformanceReviewApiPerformanceReviewService extends PerformanceReviewBaseService {
  constructor(
    config: PerformanceReviewApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAllPerformanceReviewRecords
   */
  static readonly GetAllPerformanceReviewRecordsPath = '/api/PerformanceReview/allPerformanceReviewRecords';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllPerformanceReviewRecords()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAllPerformanceReviewRecords$Response(params?: {
    tenantId?: string;
    body?: PerformanceReviewGetAllPerformanceReviewRecordsQuery
  }): Observable<PerformanceReviewStrictHttpResponse<Array<PerformanceReviewPerformanceReviewsFiltered>>> {

    const rb = new PerformanceReviewRequestBuilder(this.rootUrl, PerformanceReviewApiPerformanceReviewService.GetAllPerformanceReviewRecordsPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PerformanceReviewStrictHttpResponse<Array<PerformanceReviewPerformanceReviewsFiltered>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllPerformanceReviewRecords$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAllPerformanceReviewRecords(params?: {
    tenantId?: string;
    body?: PerformanceReviewGetAllPerformanceReviewRecordsQuery
  }): Observable<Array<PerformanceReviewPerformanceReviewsFiltered>> {

    return this.getAllPerformanceReviewRecords$Response(params).pipe(
      map((r: PerformanceReviewStrictHttpResponse<Array<PerformanceReviewPerformanceReviewsFiltered>>) => r.body as Array<PerformanceReviewPerformanceReviewsFiltered>)
    );
  }

  /**
   * Path part for operation getManagerReviewSummaries
   */
  static readonly GetManagerReviewSummariesPath = '/api/PerformanceReview/managerReviewSummaries';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getManagerReviewSummaries()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getManagerReviewSummaries$Response(params?: {
    tenantId?: string;
    body?: PerformanceReviewGetManagerReviewSummariesQuery
  }): Observable<PerformanceReviewStrictHttpResponse<Array<PerformanceReviewReviewSummariesFiltered>>> {

    const rb = new PerformanceReviewRequestBuilder(this.rootUrl, PerformanceReviewApiPerformanceReviewService.GetManagerReviewSummariesPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PerformanceReviewStrictHttpResponse<Array<PerformanceReviewReviewSummariesFiltered>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getManagerReviewSummaries$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getManagerReviewSummaries(params?: {
    tenantId?: string;
    body?: PerformanceReviewGetManagerReviewSummariesQuery
  }): Observable<Array<PerformanceReviewReviewSummariesFiltered>> {

    return this.getManagerReviewSummaries$Response(params).pipe(
      map((r: PerformanceReviewStrictHttpResponse<Array<PerformanceReviewReviewSummariesFiltered>>) => r.body as Array<PerformanceReviewReviewSummariesFiltered>)
    );
  }

  /**
   * Path part for operation getReviewsAndSummaries
   */
  static readonly GetReviewsAndSummariesPath = '/api/PerformanceReview/reviewsAndSummaries';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getReviewsAndSummaries()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getReviewsAndSummaries$Response(params?: {
    tenantId?: string;
    body?: PerformanceReviewGetReviewsAndSummariesQuery
  }): Observable<PerformanceReviewStrictHttpResponse<Array<PerformanceReviewReviewSummariesFiltered>>> {

    const rb = new PerformanceReviewRequestBuilder(this.rootUrl, PerformanceReviewApiPerformanceReviewService.GetReviewsAndSummariesPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PerformanceReviewStrictHttpResponse<Array<PerformanceReviewReviewSummariesFiltered>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getReviewsAndSummaries$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getReviewsAndSummaries(params?: {
    tenantId?: string;
    body?: PerformanceReviewGetReviewsAndSummariesQuery
  }): Observable<Array<PerformanceReviewReviewSummariesFiltered>> {

    return this.getReviewsAndSummaries$Response(params).pipe(
      map((r: PerformanceReviewStrictHttpResponse<Array<PerformanceReviewReviewSummariesFiltered>>) => r.body as Array<PerformanceReviewReviewSummariesFiltered>)
    );
  }

  /**
   * Path part for operation getAllReviewPeriods
   */
  static readonly GetAllReviewPeriodsPath = '/api/PerformanceReview/allReviewPeriods';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllReviewPeriods()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAllReviewPeriods$Response(params?: {
    tenantId?: string;
    body?: PerformanceReviewGetAllReviewPeriodsQuery
  }): Observable<PerformanceReviewStrictHttpResponse<Array<PerformanceReviewDvReviewPeriods>>> {

    const rb = new PerformanceReviewRequestBuilder(this.rootUrl, PerformanceReviewApiPerformanceReviewService.GetAllReviewPeriodsPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PerformanceReviewStrictHttpResponse<Array<PerformanceReviewDvReviewPeriods>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllReviewPeriods$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAllReviewPeriods(params?: {
    tenantId?: string;
    body?: PerformanceReviewGetAllReviewPeriodsQuery
  }): Observable<Array<PerformanceReviewDvReviewPeriods>> {

    return this.getAllReviewPeriods$Response(params).pipe(
      map((r: PerformanceReviewStrictHttpResponse<Array<PerformanceReviewDvReviewPeriods>>) => r.body as Array<PerformanceReviewDvReviewPeriods>)
    );
  }

  /**
   * Path part for operation getAllBusinessCycleTypes
   */
  static readonly GetAllBusinessCycleTypesPath = '/api/PerformanceReview/allBusinessCycleTypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllBusinessCycleTypes()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAllBusinessCycleTypes$Response(params?: {
    tenantId?: string;
    body?: PerformanceReviewGetAllBusinessCycleTypesQuery
  }): Observable<PerformanceReviewStrictHttpResponse<Array<PerformanceReviewDvBusinessCycleTypes>>> {

    const rb = new PerformanceReviewRequestBuilder(this.rootUrl, PerformanceReviewApiPerformanceReviewService.GetAllBusinessCycleTypesPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PerformanceReviewStrictHttpResponse<Array<PerformanceReviewDvBusinessCycleTypes>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllBusinessCycleTypes$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAllBusinessCycleTypes(params?: {
    tenantId?: string;
    body?: PerformanceReviewGetAllBusinessCycleTypesQuery
  }): Observable<Array<PerformanceReviewDvBusinessCycleTypes>> {

    return this.getAllBusinessCycleTypes$Response(params).pipe(
      map((r: PerformanceReviewStrictHttpResponse<Array<PerformanceReviewDvBusinessCycleTypes>>) => r.body as Array<PerformanceReviewDvBusinessCycleTypes>)
    );
  }

  /**
   * Path part for operation getAllBusinessCycles
   */
  static readonly GetAllBusinessCyclesPath = '/api/PerformanceReview/allBusinessCycles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllBusinessCycles()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAllBusinessCycles$Response(params?: {
    tenantId?: string;
    body?: PerformanceReviewGetAllBusinessCyclesQuery
  }): Observable<PerformanceReviewStrictHttpResponse<Array<PerformanceReviewDvBusinessCycles>>> {

    const rb = new PerformanceReviewRequestBuilder(this.rootUrl, PerformanceReviewApiPerformanceReviewService.GetAllBusinessCyclesPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PerformanceReviewStrictHttpResponse<Array<PerformanceReviewDvBusinessCycles>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllBusinessCycles$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAllBusinessCycles(params?: {
    tenantId?: string;
    body?: PerformanceReviewGetAllBusinessCyclesQuery
  }): Observable<Array<PerformanceReviewDvBusinessCycles>> {

    return this.getAllBusinessCycles$Response(params).pipe(
      map((r: PerformanceReviewStrictHttpResponse<Array<PerformanceReviewDvBusinessCycles>>) => r.body as Array<PerformanceReviewDvBusinessCycles>)
    );
  }

  /**
   * Path part for operation patchPerformanceReviews
   */
  static readonly PatchPerformanceReviewsPath = '/api/PerformanceReview/patch/performanceReviews';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchPerformanceReviews()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchPerformanceReviews$Response(params?: {
    tenantId?: string;
    body?: PerformanceReviewPatchPerformanceReviewsCommand
  }): Observable<PerformanceReviewStrictHttpResponse<boolean>> {

    const rb = new PerformanceReviewRequestBuilder(this.rootUrl, PerformanceReviewApiPerformanceReviewService.PatchPerformanceReviewsPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as PerformanceReviewStrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchPerformanceReviews$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchPerformanceReviews(params?: {
    tenantId?: string;
    body?: PerformanceReviewPatchPerformanceReviewsCommand
  }): Observable<boolean> {

    return this.patchPerformanceReviews$Response(params).pipe(
      map((r: PerformanceReviewStrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation getUpdatedReview
   */
  static readonly GetUpdatedReviewPath = '/api/PerformanceReview/updatedReview';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUpdatedReview()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getUpdatedReview$Response(params?: {
    tenantId?: string;
    body?: PerformanceReviewGetUpdatedReviewQuery
  }): Observable<PerformanceReviewStrictHttpResponse<Array<PerformanceReviewEmployeeReviewFiltered>>> {

    const rb = new PerformanceReviewRequestBuilder(this.rootUrl, PerformanceReviewApiPerformanceReviewService.GetUpdatedReviewPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PerformanceReviewStrictHttpResponse<Array<PerformanceReviewEmployeeReviewFiltered>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUpdatedReview$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getUpdatedReview(params?: {
    tenantId?: string;
    body?: PerformanceReviewGetUpdatedReviewQuery
  }): Observable<Array<PerformanceReviewEmployeeReviewFiltered>> {

    return this.getUpdatedReview$Response(params).pipe(
      map((r: PerformanceReviewStrictHttpResponse<Array<PerformanceReviewEmployeeReviewFiltered>>) => r.body as Array<PerformanceReviewEmployeeReviewFiltered>)
    );
  }

  /**
   * Path part for operation getManagerUpdatedReview
   */
  static readonly GetManagerUpdatedReviewPath = '/api/PerformanceReview/managerUpdatedReview';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getManagerUpdatedReview()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getManagerUpdatedReview$Response(params?: {
    tenantId?: string;
    body?: PerformanceReviewGetManagerUpdatedReviewQuery
  }): Observable<PerformanceReviewStrictHttpResponse<Array<PerformanceReviewPerformanceReviewsFiltered>>> {

    const rb = new PerformanceReviewRequestBuilder(this.rootUrl, PerformanceReviewApiPerformanceReviewService.GetManagerUpdatedReviewPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PerformanceReviewStrictHttpResponse<Array<PerformanceReviewPerformanceReviewsFiltered>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getManagerUpdatedReview$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getManagerUpdatedReview(params?: {
    tenantId?: string;
    body?: PerformanceReviewGetManagerUpdatedReviewQuery
  }): Observable<Array<PerformanceReviewPerformanceReviewsFiltered>> {

    return this.getManagerUpdatedReview$Response(params).pipe(
      map((r: PerformanceReviewStrictHttpResponse<Array<PerformanceReviewPerformanceReviewsFiltered>>) => r.body as Array<PerformanceReviewPerformanceReviewsFiltered>)
    );
  }

  /**
   * Path part for operation getEnhancedPerformanceReviewEmployee
   */
  static readonly GetEnhancedPerformanceReviewEmployeePath = '/api/PerformanceReview/enhancedPerformanceReviewEmployee';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEnhancedPerformanceReviewEmployee()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEnhancedPerformanceReviewEmployee$Response(params?: {
    tenantId?: string;
    body?: PerformanceReviewGetEnhancedPerformanceReviewEmployeeQuery
  }): Observable<PerformanceReviewStrictHttpResponse<Array<PerformanceReviewDvPerformanceReview>>> {

    const rb = new PerformanceReviewRequestBuilder(this.rootUrl, PerformanceReviewApiPerformanceReviewService.GetEnhancedPerformanceReviewEmployeePath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PerformanceReviewStrictHttpResponse<Array<PerformanceReviewDvPerformanceReview>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEnhancedPerformanceReviewEmployee$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEnhancedPerformanceReviewEmployee(params?: {
    tenantId?: string;
    body?: PerformanceReviewGetEnhancedPerformanceReviewEmployeeQuery
  }): Observable<Array<PerformanceReviewDvPerformanceReview>> {

    return this.getEnhancedPerformanceReviewEmployee$Response(params).pipe(
      map((r: PerformanceReviewStrictHttpResponse<Array<PerformanceReviewDvPerformanceReview>>) => r.body as Array<PerformanceReviewDvPerformanceReview>)
    );
  }

  /**
   * Path part for operation getEnhancedPerformanceReviewManager
   */
  static readonly GetEnhancedPerformanceReviewManagerPath = '/api/PerformanceReview/enhancedPerformanceReviewManager';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEnhancedPerformanceReviewManager()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEnhancedPerformanceReviewManager$Response(params?: {
    tenantId?: string;
    body?: PerformanceReviewGetEnhancedPerformanceReviewManagerQuery
  }): Observable<PerformanceReviewStrictHttpResponse<Array<PerformanceReviewDvPerformanceReview>>> {

    const rb = new PerformanceReviewRequestBuilder(this.rootUrl, PerformanceReviewApiPerformanceReviewService.GetEnhancedPerformanceReviewManagerPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PerformanceReviewStrictHttpResponse<Array<PerformanceReviewDvPerformanceReview>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEnhancedPerformanceReviewManager$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEnhancedPerformanceReviewManager(params?: {
    tenantId?: string;
    body?: PerformanceReviewGetEnhancedPerformanceReviewManagerQuery
  }): Observable<Array<PerformanceReviewDvPerformanceReview>> {

    return this.getEnhancedPerformanceReviewManager$Response(params).pipe(
      map((r: PerformanceReviewStrictHttpResponse<Array<PerformanceReviewDvPerformanceReview>>) => r.body as Array<PerformanceReviewDvPerformanceReview>)
    );
  }

  /**
   * Path part for operation getEnhancedReviewDetail
   */
  static readonly GetEnhancedReviewDetailPath = '/api/PerformanceReview/enhancedReviewDetail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEnhancedReviewDetail()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEnhancedReviewDetail$Response(params?: {
    tenantId?: string;
    body?: PerformanceReviewGetEnhancedReviewDetailQuery
  }): Observable<PerformanceReviewStrictHttpResponse<Array<PerformanceReviewEnhancedReviewDetail>>> {

    const rb = new PerformanceReviewRequestBuilder(this.rootUrl, PerformanceReviewApiPerformanceReviewService.GetEnhancedReviewDetailPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PerformanceReviewStrictHttpResponse<Array<PerformanceReviewEnhancedReviewDetail>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEnhancedReviewDetail$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEnhancedReviewDetail(params?: {
    tenantId?: string;
    body?: PerformanceReviewGetEnhancedReviewDetailQuery
  }): Observable<Array<PerformanceReviewEnhancedReviewDetail>> {

    return this.getEnhancedReviewDetail$Response(params).pipe(
      map((r: PerformanceReviewStrictHttpResponse<Array<PerformanceReviewEnhancedReviewDetail>>) => r.body as Array<PerformanceReviewEnhancedReviewDetail>)
    );
  }

  /**
   * Path part for operation getEnhancedReviewDetailOverview
   */
  static readonly GetEnhancedReviewDetailOverviewPath = '/api/PerformanceReview/enhancedReviewDetailOverview';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEnhancedReviewDetailOverview()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEnhancedReviewDetailOverview$Response(params?: {
    tenantId?: string;
    body?: PerformanceReviewGetEnhancedReviewDetailOverviewQuery
  }): Observable<PerformanceReviewStrictHttpResponse<Array<PerformanceReviewEnhancedReviewDetail>>> {

    const rb = new PerformanceReviewRequestBuilder(this.rootUrl, PerformanceReviewApiPerformanceReviewService.GetEnhancedReviewDetailOverviewPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PerformanceReviewStrictHttpResponse<Array<PerformanceReviewEnhancedReviewDetail>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEnhancedReviewDetailOverview$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEnhancedReviewDetailOverview(params?: {
    tenantId?: string;
    body?: PerformanceReviewGetEnhancedReviewDetailOverviewQuery
  }): Observable<Array<PerformanceReviewEnhancedReviewDetail>> {

    return this.getEnhancedReviewDetailOverview$Response(params).pipe(
      map((r: PerformanceReviewStrictHttpResponse<Array<PerformanceReviewEnhancedReviewDetail>>) => r.body as Array<PerformanceReviewEnhancedReviewDetail>)
    );
  }

  /**
   * Path part for operation patchEnhancedReviewDetailSummary
   */
  static readonly PatchEnhancedReviewDetailSummaryPath = '/api/PerformanceReview/patch/enhancedReviewDetailSummary';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchEnhancedReviewDetailSummary()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchEnhancedReviewDetailSummary$Response(params?: {
    tenantId?: string;
    body?: PerformanceReviewPatchEnhancedReviewDetailSummaryCommand
  }): Observable<PerformanceReviewStrictHttpResponse<boolean>> {

    const rb = new PerformanceReviewRequestBuilder(this.rootUrl, PerformanceReviewApiPerformanceReviewService.PatchEnhancedReviewDetailSummaryPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as PerformanceReviewStrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchEnhancedReviewDetailSummary$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchEnhancedReviewDetailSummary(params?: {
    tenantId?: string;
    body?: PerformanceReviewPatchEnhancedReviewDetailSummaryCommand
  }): Observable<boolean> {

    return this.patchEnhancedReviewDetailSummary$Response(params).pipe(
      map((r: PerformanceReviewStrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation patchEnhancedReviewDetail
   */
  static readonly PatchEnhancedReviewDetailPath = '/api/PerformanceReview/patch/enhancedReviewDetail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchEnhancedReviewDetail()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchEnhancedReviewDetail$Response(params?: {
    tenantId?: string;
    body?: PerformanceReviewPatchEnhancedReviewDetailCommand
  }): Observable<PerformanceReviewStrictHttpResponse<boolean>> {

    const rb = new PerformanceReviewRequestBuilder(this.rootUrl, PerformanceReviewApiPerformanceReviewService.PatchEnhancedReviewDetailPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as PerformanceReviewStrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchEnhancedReviewDetail$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchEnhancedReviewDetail(params?: {
    tenantId?: string;
    body?: PerformanceReviewPatchEnhancedReviewDetailCommand
  }): Observable<boolean> {

    return this.patchEnhancedReviewDetail$Response(params).pipe(
      map((r: PerformanceReviewStrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

}
