/* tslint:disable */
/* eslint-disable */
export interface BtRegNo {
  bt_fleetno?: null | string;
  bt_odorequired?: null | boolean;
  bt_scanlicense?: null | boolean;
  bthr_expirydate?: null | string;
  bthr_licensenumber?: null | string;
  bthr_vehiclesid?: null | string;
  odataMake?: null | string;
  odataModel?: null | string;
}
