/* tslint:disable */
/* eslint-disable */
import { User } from './user';
import { VerdePositions } from './verde-positions';
export interface EquipmentRequestDetail {
  '_bt_allocatedby_value'?: null | string;
  '_bt_allocatedlegalentity_value'?: null | string;
  '_bt_employee_value'?: null | string;
  '_bt_employeelegalentity_value'?: null | string;
  '_bt_employeelocation_value'?: null | string;
  '_bt_employeeposition_value'?: null | string;
  '_bt_equipmentkit_value'?: null | string;
  '_bt_equipmentlocation_value'?: null | string;
  '_bt_equipmentrequestno_value'?: null | string;
  '_bt_equipmentspec_value'?: null | string;
  '_bt_equipmentspectype_value'?: null | string;
  '_bt_equipmenttype_value'?: null | string;
  '_bt_equipmenttypeclass_value'?: null | string;
  '_bt_legalentity_value'?: null | string;
  '_bt_linkedrecord_value'?: null | string;
  '_bt_location_value'?: null | string;
  '_bt_locationentity_value'?: null | string;
  '_bt_locationgroup_value'?: null | string;
  '_bt_originator_value'?: null | string;
  '_bt_overridespec_value'?: null | string;
  '_bt_pooldepartment_value'?: null | string;
  '_bt_pooldivision_value'?: null | string;
  '_bt_poollegalentity_value'?: null | string;
  '_bt_proxyentity_value'?: null | string;
  '_bt_proxyuser_value'?: null | string;
  '_bt_requestequipmentitem_value'?: null | string;
  '_bt_seat_value'?: null | string;
  bt_Employee?: User;
  bt_EmployeePosition?: VerdePositions;
  bt_allocationdate?: null | string;
  bt_allocationqty?: null | number;
  bt_allocationstatus?: null | number;
  bt_approvalcomment?: null | string;
  bt_approvalstatus?: null | number;
  bt_deactivatekititems?: null | boolean;
  bt_equipmentclassification?: null | number;
  bt_equipmentrequestdetailid?: null | string;
  bt_equipmenttypespecification?: null | string;
  bt_extendedlinebudget?: null | number;
  bt_kititembudget?: null | number;
  bt_leadtimedays?: null | number;
  bt_linetype?: null | number;
  bt_motivation?: null | string;
  bt_name?: null | string;
  bt_newemployee?: null | boolean;
  bt_overrideapprovalcomment?: null | string;
  bt_overrideapproved?: null | boolean;
  bt_overridedefault?: null | boolean;
  bt_overrideprocessed?: null | boolean;
  bt_overridestatus?: null | number;
  bt_pooltype?: null | number;
  bt_proxy?: null | boolean;
  bt_qty?: null | number;
  bt_reason?: null | string;
  bt_remainingqty?: null | number;
  bt_requiredbydate?: null | string;
  bt_split?: null | boolean;
  bt_stockkit?: null | boolean;
  bt_triggerdeactivation?: null | boolean;
  bt_triggeroverridereset?: null | boolean;
  odataAllocatedBy?: null | string;
  odataAllocatedByLegalEntity?: null | string;
  odataAllocationStatus?: null | string;
  odataApprovalStatus?: null | string;
  odataEmployee?: null | string;
  odataEmployeeLegalEntity?: null | string;
  odataEmployeeLocation?: null | string;
  odataEmployeePosition?: null | string;
  odataEquipmentClassification?: null | string;
  odataEquipmentKit?: null | string;
  odataEquipmentLocation?: null | string;
  odataEquipmentRequestHeader?: null | string;
  odataEquipmentSpec?: null | string;
  odataEquipmentSpecType?: null | string;
  odataEquipmentType?: null | string;
  odataEquipmentTypeClass?: null | string;
  odataLegalEntity?: null | string;
  odataLineType?: null | string;
  odataLinkedRecord?: null | string;
  odataLocation?: null | string;
  odataLocationGroup?: null | string;
  odataLocationLegalEntity?: null | string;
  odataNewEmployee?: null | string;
  odataOriginator?: null | string;
  odataOverride?: null | string;
  odataOverrideApproved?: null | string;
  odataOverrideProcessed?: null | string;
  odataOverrideSpec?: null | string;
  odataOverrideStatus?: null | string;
  odataPoolDepartment?: null | string;
  odataPoolDivision?: null | string;
  odataPoolLegalEntity?: null | string;
  odataPoolType?: null | string;
  odataPostAllocatedBy?: null | string;
  odataPostAllocatedByLegalEntity?: null | string;
  odataPostEmployee?: null | string;
  odataPostEmployeeLegalEntity?: null | string;
  odataPostEmployeeLocation?: null | string;
  odataPostEmployeePosition?: null | string;
  odataPostEquipmentKit?: null | string;
  odataPostEquipmentLocation?: null | string;
  odataPostEquipmentRequestHeader?: null | string;
  odataPostEquipmentSpec?: null | string;
  odataPostEquipmentSpecType?: null | string;
  odataPostEquipmentType?: null | string;
  odataPostEquipmentTypeClass?: null | string;
  odataPostLegalEntity?: null | string;
  odataPostLinkedRecord?: null | string;
  odataPostLocation?: null | string;
  odataPostLocationGroup?: null | string;
  odataPostLocationLegalEntity?: null | string;
  odataPostOriginator?: null | string;
  odataPostOverrideSpec?: null | string;
  odataPostPoolDepartment?: null | string;
  odataPostPoolDivision?: null | string;
  odataPostPoolLegalEntity?: null | string;
  odataPostProxyLegalEntity?: null | string;
  odataPostProxyUser?: null | string;
  odataPostRequestEquipmentItem?: null | string;
  odataPostSeat?: null | string;
  odataProxyLegalEntity?: null | string;
  odataProxyUser?: null | string;
  odataRequestEquipmentItem?: null | string;
  odataSeat?: null | string;
  odataSplit?: null | string;
  statecode?: null | number;
}
