/* tslint:disable */
/* eslint-disable */
import { PerformanceReviewBtDetailType } from './performance-review-bt-detail-type';
export interface PerformanceReviewGetEnhancedReviewDetailQuery {
  cycleID?: null | Array<string>;
  dataverseUrl?: null | string;
  detailType?: null | Array<PerformanceReviewBtDetailType>;
  employeeID?: null | Array<string>;
  legalEntityID?: null | Array<string>;
  periodID?: null | Array<string>;
  responderID?: null | Array<string>;
  templateID?: null | Array<string>;
}
