/* tslint:disable */
/* eslint-disable */
export interface DvAllEducationalInstitutions {
  bthr_educationalinstitutionid?: null | string;
  bthr_name?: null | string;
  odataAccreditationAuthority?: null | string;
  odataCountry?: null | string;
  odataInstitutionType?: null | string;
  odataLegalEntity?: null | string;
  odataRecordStatus?: null | string;
}
