/* tslint:disable */
/* eslint-disable */
export interface BookingItems {
  '_bt_bookingtype_value'?: null | string;
  '_bt_office_value'?: null | string;
  '_bt_primarylocation_value'?: null | string;
  '_bt_suboffice_value'?: null | string;
  bt_bookingitem?: null | string;
  bt_verdebookingitemsid?: null | string;
  odataBookingType?: null | string;
  odataOffice?: null | string;
  odataPostBookingType?: null | string;
  odataPostOffice?: null | string;
  odataPostPrimaryLocation?: null | string;
  odataPostSubOffice?: null | string;
  odataPrimaryLocation?: null | string;
  odataSubOffice?: null | string;
  statecode?: number;
}
