import { createEntityAdapter, EntityState, EntityAdapter } from '@ngrx/entity';
import { PositionActions, PositionActionTypes } from '../actions/position.actions';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromStructureManagementReducer from './structure-management.reducer';
import * as uuid from 'uuid';

// Models
import { StructurePositionExtended } from '../models/structure-position-extended.model';

// Helpers
import { keyHandler } from '@verde/core';

export interface PositionState extends EntityState<StructurePositionExtended> {}

export interface PositionRelatedState {
  positionState: PositionState;
  positionSavedState: PositionState;
  selectedEntity: string[];
  loading: boolean;
  error: any;
}

export const positionKeys = ['_bthr_legalentity_value', 'bthr_positionid'];
export const positionStateAdapter: EntityAdapter<StructurePositionExtended> = createEntityAdapter<StructurePositionExtended>({
  selectId: (data) => keyHandler(data, positionKeys),
});

export const initialPositionState: PositionState = positionStateAdapter.getInitialState({});

export const initialPositionRelatedState = {
  positionState: initialPositionState,
  positionSavedState: initialPositionState,
  selectedEntity: [],
  loading: false,
  error: {},
};

export function positionRelatedReducer(state = initialPositionRelatedState, action: PositionActions): PositionRelatedState {
  switch (action.type) {
    // Get All Position
    case PositionActionTypes.POSITION_GET_ALL:
      return { ...state, loading: true };
    case PositionActionTypes.POSITION_GET_ALL_SUCCESS: {
      const payload = action.payload.map((m) => {
        return {
          ...m,
          $new: false,
          $dispose: false,
        };
      });

      return {
        ...state,
        loading: false,
        positionState: positionStateAdapter.upsertMany(payload, state.positionState),
        positionSavedState: positionStateAdapter.upsertMany(payload, state.positionSavedState),
      };
    }
    case PositionActionTypes.POSITION_GET_ALL_ERROR:
      return { ...state, loading: false, error: action.payload };
    // Position Add
    case PositionActionTypes.POSITION_ADD_SUCCESS: {
      const payload: StructurePositionExtended[] = action.payload.map((m) => {
        return {
          ...m,
          bthr_positionid: uuid.v4(),
          $new: true,
          $timestamp: Date.now(),
        };
      });

      return {
        ...state,
        positionState: positionStateAdapter.addMany(payload, state.positionState),
      };
    }
    // Position Dispose
    case PositionActionTypes.POSITION_DISPOSE_SUCCESS: {
      const payload: StructurePositionExtended[] = action.payload.map((m) => {
        return {
          ...m,
          $dispose: true,
          $timestamp: Date.now(),
        };
      });

      return {
        ...state,
        positionState: positionStateAdapter.upsertMany(payload, state.positionState),
      };
    }
    // Position Delete
    case PositionActionTypes.POSITION_DELETE_SUCCESS:
      return {
        ...state,
        positionState: positionStateAdapter.removeMany(
          action.payload.map((p) => keyHandler(p, positionKeys)),
          state.positionState,
        ),
      };
    // Position Undo
    case PositionActionTypes.POSITION_UNDO_SUCCESS: {
      return {
        ...state,
        positionState: state.positionSavedState,
      };
    }
    // Position Entity Select
    case PositionActionTypes.POSITION_ENTITY_SELECT:
      return { ...state, selectedEntity: [...state.selectedEntity, action.payload] };
    case PositionActionTypes.POSITION_ENTITY_DESELECT:
      return { ...state, selectedEntity: [] };
    // Clear
    case PositionActionTypes.POSITION_CLEAR:
      return { ...initialPositionRelatedState };
    default:
      return state;
  }
}

export const getStructureManagementState = createFeatureSelector<fromStructureManagementReducer.StructureManagementState>('structureManagementReducer');

export const getPositionRelatedState = createSelector(
  getStructureManagementState,
  (state: fromStructureManagementReducer.StructureManagementState) => state.positionState,
);

// Current Positions

export const getPositionState = createSelector(getPositionRelatedState, (state) => state.positionState);
export const { selectAll: getAllPositions } = positionStateAdapter.getSelectors(getPositionState);

// Filtered Structure

export const getAllPositionsNew = createSelector(getAllPositions, (positions) => positions.filter((position) => position.$new));
export const getAllPositionsDispose = createSelector(getAllPositions, (positions) => positions.filter((position) => position.$dispose));

// Selected Entity

export const getSelectedEntityIds = createSelector(getPositionRelatedState, (state) => state.selectedEntity);

export const getAllEntityPositions = createSelector(getAllPositions, getSelectedEntityIds, (positions, entityIds: string[]) =>
  positions.filter((pos) => entityIds.indexOf(pos._bthr_legalentity_value) !== -1),
);

// Loading

export const isPositionLoading = createSelector(getPositionRelatedState, (state) => state.loading);

// Error

export const getPositionError = createSelector(getPositionRelatedState, (state) => state.error);
