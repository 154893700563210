/* tslint:disable */
/* eslint-disable */
import { BtProcessOwner } from './bt-process-owner';
import { BtVerdeRequestTypeRoleRequestTypeBtVe } from './bt-verde-request-type-role-request-type-bt-ve';
export interface VerdeRequestType {
  '_bt_legalentity_value'?: null | string;
  '_bt_processowner_value'?: null | string;
  '_bt_processownerentity_value'?: null | string;
  bt_ProcessOwner?: BtProcessOwner;
  bt_VerdeRequestTypeRole_RequestType_bt_Ve?: null | Array<BtVerdeRequestTypeRoleRequestTypeBtVe>;
  bt_allowquoteamount?: null | boolean;
  bt_capex?: null | boolean;
  bt_description?: null | string;
  bt_displayspecificationattachment?: null | boolean;
  bt_displaytemplate?: null | boolean;
  bt_employeerole?: null | boolean;
  bt_helpdetails?: null | string;
  bt_label1?: null | string;
  bt_label2?: null | string;
  bt_label3?: null | string;
  bt_leadtime?: null | number;
  bt_managerapprovalrequired?: null | boolean;
  bt_managerrole?: null | boolean;
  bt_nongenericrequest?: null | boolean;
  bt_nongenericrequesttype?: null | number;
  bt_numberofquotesrequired?: null | number;
  bt_policy?: null | string;
  bt_quiterequired?: null | boolean;
  bt_quoteallowed?: null | boolean;
  bt_quoteamountrequired?: null | boolean;
  bt_specificationrequired?: null | boolean;
  bt_speclabel?: null | string;
  bt_verderequesttype?: null | string;
  bt_verderequesttypeid?: null | string;
  odataLegalEntity?: null | string;
  odataProcessLegalEntity?: null | string;
  odataProcessOwner?: null | string;
}
