import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UserService } from '@verde/core';
import {
  FleetApiFleetService,
  FleetFleetMovementCancellation,
  FleetFleetMovementCancellationType,
  VehiclesFiltered,
  VerdeApprovalService,
} from '@verde/shared';
import { Subject, take, takeUntil } from 'rxjs';

@Component({
  selector: 'verde-fleet-return-item',
  templateUrl: './fleet-return-item.component.html',
  styleUrls: ['./fleet-return-item.component.scss'],
})
export class FleetReturnItemComponent implements OnInit, OnDestroy {
  onDestroy$: Subject<any> = new Subject();

  selectedFleetItem: VehiclesFiltered = {};

  allCancellationReasons: FleetFleetMovementCancellationType[] = [];

  showConfirmationModal: boolean = false;

  constructor(private userService: UserService, private sidebarService: VerdeApprovalService, private fleetApiFleetService: FleetApiFleetService) {}

  ngOnInit(): void {
    this.sidebarService
      .getSelectedFleetItem()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((item) => {
        if (Object.keys(item).length > 0) {
          this.selectedFleetItem = item;
          this.getCancellationTypes();
        }
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  getCancellationTypes() {
    this.fleetApiFleetService
      .getFleetMovementCancellationTypes({ body: { legalEntityID: [this.selectedFleetItem._bt_legalentity_value] } })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((ret) => {
        this.allCancellationReasons = ret;
      });
  }

  postThisThing(myForm: FormGroup) {
    let body: FleetFleetMovementCancellation = {
      legalEntityID: this.selectedFleetItem._bt_legalentity_value,
      fleetItemID: this.selectedFleetItem.bthr_vehiclesid,
      requestorID: this.userService.user.employeeId,
      requestorLegalEntityID: this.userService.user.legalEntityId,
      cancellationTypeID: myForm.value.cancellationtype.fleetMovementCancellationTypeID,
      fleetLocationID: this.selectedFleetItem._bt_newlocation_value,
      reasonForRequest: myForm.value.cancellationReason,
      processComment: '',
      fleetMovementCancellationName: '',
    };

    this.fleetApiFleetService
      .postFleetMovementCancellations({ body: { body: body } })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          console.log(ret);
        },
        (error) => {
          console.error(error);
        },
        () => {
          this.showConfirmationModal = true;
        },
      );
  }

  closeConfirmationModal() {
    this.showConfirmationModal = false;
    this.sidebarService.setShowSidebar(false);
  }
}
