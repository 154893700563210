/* tslint:disable */
/* eslint-disable */
import { StructureStructureEmployee } from './structure-structure-employee';
import { StructureStructureLegalEntity } from './structure-structure-legal-entity';
import { StructureStructurePositionSeat } from './structure-structure-position-seat';
import { StructureBtPositionseat } from './structure-bt-positionseat';
export interface StructureStructureDivision {
  hasChildren?: null | boolean;
  id?: null | string;
  manager?: StructureStructureEmployee;
  name?: null | string;
  parentDivision?: StructureStructureDivision;
  parentLegalEntity?: StructureStructureLegalEntity;
  positionSeat?: StructureStructurePositionSeat;
  seatStatus?: StructureBtPositionseat;
}
