<div class="scrollable-content" style="height:calc(100% - 28px)" *ngIf="getFormTypeValue == 'sidepanel'">

  <ngx-spinner [disableAnimation]="disableAnimation"
               name="dynamicFormSpinner"
               bdColor="rgb(255, 255, 255)"
               size="default"
               color="#6264a7"
               type="ball-clip-rotate"
               [fullScreen]="false">
    <p>Loading Data...</p>
  </ngx-spinner>

  <!--<kendo-tabstrip>
    <ng-container *ngFor="let tab of dynamicFormfields">
      <kendo-tabstrip-tab [title]="tab.title">
        <form class="k-form" [formGroup]="tab.dynamicFormGroup" *ngIf="tab">
          <formly-form [model]="tab.dynamicModel" [fields]="tab" [options]="tab.options" [form]="tab.dynamicFormGroup"></formly-form>
          <button id="submit-button" style="float: right" kendoButton themeColor="primary" (click)="submit()" type="submit">Submit</button>
        </form>
      </kendo-tabstrip-tab>
    </ng-container>
  </kendo-tabstrip>-->

  <div class="k-form" *ngIf="dynamicFormfields">
    <formly-form [model]="dynamicModel" [fields]="dynamicFormfields" [options]="options" [form]="dynamicFormGroup"></formly-form>
  </div>

</div>

<div style="position: sticky; bottom: 0px" *ngIf="getFormTypeValue == 'sidepanel'">
  <hr style="">
  <button [disabled]="!this.dynamicFormGroup?.valid ?? true"
          id="submit-button"
          style="float: right; bottom: 14px; margin-top: 14px; right:20px;"
          kendoButton themeColor="primary"
          (click)="openSubmitModal()"
          type="submit">
    Submit
  </button>
</div>

<div *ngIf="getFormTypeValue == 'Grid'">

  <ngx-spinner [disableAnimation]="disableAnimation"
               name="dynamicFormSpinner"
               bdColor="rgb(237, 237, 237)"
               size="default"
               color="#6264a7"
               type="ball-clip-rotate"
               style="min-width:fit-content"
               [fullScreen]="false">

  </ngx-spinner>

  <!--<kendo-tabstrip>
    <ng-container *ngFor="let tab of dynamicFormfields">
      <kendo-tabstrip-tab [title]="tab.title">
        <form class="k-form" [formGroup]="tab.dynamicFormGroup" *ngIf="tab">
          <formly-form [model]="tab.dynamicModel" [fields]="tab" [options]="tab.options" [form]="tab.dynamicFormGroup"></formly-form>
          <button id="submit-button" style="float: right" kendoButton themeColor="primary" (click)="submit()" type="submit">Submit</button>
        </form>
      </kendo-tabstrip-tab>
    </ng-container>
  </kendo-tabstrip>-->

  <form class="k-form" [formGroup]="dynamicFormGroup" *ngIf="dynamicFormfields">
    <formly-form [model]="dynamicModel" [fields]="dynamicFormfields" [options]="options" [form]="dynamicFormGroup"></formly-form>
  </form>

</div>



<verde-modal modalTitle="Confirmation"
             id="confirmModal"
             [hasCancelButton]="true"
             submitButton="Continue"
             (actionButton)="submit()"
             modalMaxWidth="400">
  <div style="position: relative; height: 60px;">
    <p>{{ notificationMessage }}</p>
  </div>
</verde-modal>


<verde-modal modalTitle="Error"
             id="errorModal"
             [hasCancelButton]="false"
             submitButton="Continue"
             modalMaxWidth="400">
  <div style="position: relative; height: 60px;">
    <p>{{ notificationMessage }}</p>
  </div>
</verde-modal>



