/* tslint:disable */
/* eslint-disable */
export interface BtVerdeRequestTypeRoleRequestTypeBtVe {
  '_bt_legalentity_value'?: null | string;
  '_bt_requesttype_value'?: null | string;
  '_bt_role_value'?: null | string;
  bt_verderequesttyperole?: null | string;
  bt_verderequesttyperoleid?: null | string;
  odataLegalEntity?: null | string;
  odataRequestType?: null | string;
  odataRole?: null | string;
}
