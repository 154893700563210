/* tslint:disable */
/* eslint-disable */
export interface PerformanceReviewGetAllPeerReviewFeedbackQuery {
  cycleID?: null | Array<string>;
  dataverseUrl?: null | string;
  employeeID?: null | Array<string>;
  legalEntityID?: null | Array<string>;
  periodID?: null | Array<string>;
  responderID?: null | Array<string>;
  templateID?: null | Array<string>;
}
