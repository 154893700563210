/* tslint:disable */
/* eslint-disable */
import { EquipmentEquipmentLegalEntity } from './equipment-equipment-legal-entity';
import { EquipmentEquipmentType } from './equipment-equipment-type';
export interface EquipmentEquipmentSpecType {
  bt_EquipmentType?: EquipmentEquipmentType;
  bt_LegalEntity?: EquipmentEquipmentLegalEntity;
  bt_verdeequipmentspectype?: null | string;
  bt_verdeequipmentspectypeid?: string;
}
