/* tslint:disable */
/* eslint-disable */
import { IntegrationSource } from './integration-source';
import { LegalEntityId } from './legal-entity-id';
export interface ImportHeader {
  detailFile?: boolean;
  integrationSource?: IntegrationSource;
  legalEntityID?: LegalEntityId;
  overwrite?: boolean;
  period?: number;
  recordID?: null | string;
  taxYear?: null | string;
}
