<form [formGroup]="groupForm"
      (ngSubmit)="addGroup()">
  <div style="padding: 20px">

    <div>
      <label>Legal Entity:</label>
      <br />
      <kendo-textbox [readonly]="true"
                     [value]=""
                     formControlName="legalEntity"></kendo-textbox>
      <br />
      <br />
      <label>Template Type:</label>
      <br />
      <kendo-textbox [readonly]="true"
                     [value]=""
                     formControlName="templateType"></kendo-textbox>

      <br />
      <br />
      <label>Name:</label>
      <br />
      <kendo-textbox [readonly]="false"
                     [value]=""
                     formControlName="name"></kendo-textbox>
      <br />
      <br />
      <label>Sorting:</label>
      <br />
      <kendo-textbox [readonly]="false"
                     [value]=""
                     formControlName="sorting"></kendo-textbox>

    </div>
  </div>

  <div class="container">
    <button id="add-button"
            style="float: right; margin-right: 30px"
            kendoButton
            themeColor="primary"
            (click)="addGroup()">Add</button>
  </div>
</form>

<verde-modal modalTitle="Confirmation"
             id="confirmGroup"
             [hasCancelButton]="true"
             submitButton="Continue"
             (actionButton)="confirm()"
             modalMaxWidth="400">
  <div style="position: relative; height: 60px;">
    <p>Please confirm that you would like to add group</p>
  </div>
</verde-modal>