/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FleetApiConfiguration, FleetApiConfigurationParams } from './fleet-api-configuration';

import { FleetApiFleetService } from './services/fleet-api-fleet.service';
import { FleetApiVersionService } from './services/fleet-api-version.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    FleetApiFleetService,
    FleetApiVersionService,
    FleetApiConfiguration
  ],
})
export class FleetApiModule {
  static forRoot(params: FleetApiConfigurationParams): ModuleWithProviders<FleetApiModule> {
    return {
      ngModule: FleetApiModule,
      providers: [
        {
          provide: FleetApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: FleetApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('FleetApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
