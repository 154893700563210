/* tslint:disable */
/* eslint-disable */
import { BtRegNo } from './bt-reg-no';
import { User } from './user';
export interface FleetMovement {
  '_bt_approvalemployee_value'?: null | string;
  '_bt_approvallegalentity_value'?: null | string;
  '_bt_assignedemployee_value'?: null | string;
  '_bt_assignedlegalentity_value'?: null | string;
  '_bt_fromconfirmationemployee_value'?: null | string;
  '_bt_fromconfirmationlegalentity_value'?: null | string;
  '_bt_fromemployee_value'?: null | string;
  '_bt_fromlocation_value'?: null | string;
  '_bt_fromsupplier_value'?: null | string;
  '_bt_legalentity_value'?: null | string;
  '_bt_locationgroup_value'?: null | string;
  '_bt_movementoriginator_value'?: null | string;
  '_bt_movementoriginatorlegal_value'?: null | string;
  '_bt_regno_value'?: null | string;
  '_bt_suppliertransferreason_value'?: null | string;
  '_bt_toconfirmationemployee_value'?: null | string;
  '_bt_toconfirmationlegalentity_value'?: null | string;
  '_bt_toemployee_value'?: null | string;
  '_bt_tolocation_value'?: null | string;
  '_bt_tosupplier_value'?: null | string;
  bt_FromEmployee?: User;
  bt_RegNo?: BtRegNo;
  bt_ToEmployee?: User;
  bt_acceptfrom?: null | boolean;
  bt_acceptfromdate?: null | string;
  bt_acceptto?: null | boolean;
  bt_accepttodate?: null | string;
  bt_additionaldocumentname?: null | string;
  bt_approvalcomment?: null | string;
  bt_approvaldate?: null | string;
  bt_approvalid?: null | string;
  bt_approvalname?: null | string;
  bt_approvalstatus?: null | number;
  bt_approvaltriggered?: null | boolean;
  bt_assigneddate?: null | string;
  bt_autoapprove?: null | boolean;
  bt_checklistfromname?: null | string;
  bt_checklisttoname?: null | string;
  bt_classification?: null | number;
  bt_colour?: null | string;
  bt_comment?: null | string;
  bt_disposaltype?: null | number;
  bt_effectivedate?: null | string;
  bt_fleetmovementid?: null | string;
  bt_fromchecklistcompleted?: null | boolean;
  bt_fromchecklistready?: null | boolean;
  bt_fromconfirmationdate?: null | string;
  bt_fromdate?: null | string;
  bt_fromodo?: null | number;
  bt_fromrejectedreason?: null | string;
  bt_isservice?: null | boolean;
  bt_lasttransactiondate?: null | string;
  bt_maintenanceupdate?: null | boolean;
  bt_modelyear?: null | string;
  bt_movementno?: null | string;
  bt_movementstatus?: null | number;
  bt_movementtype?: null | number;
  bt_newtype?: null | number;
  bt_nextmovementstep?: null | string;
  bt_odoreading?: null | number;
  bt_processerror?: null | string;
  bt_proxy?: null | boolean;
  bt_purchasedate?: null | string;
  bt_purchaseinvoicename?: null | string;
  bt_purchaseprice?: null | number;
  bt_recallorrejected?: null | boolean;
  bt_registrationdocumentname?: null | string;
  bt_registrationnumber?: null | string;
  bt_supplier?: null | boolean;
  bt_tochecklistcompleted?: null | boolean;
  bt_tochecklistready?: null | boolean;
  bt_toconfirmationdate?: null | string;
  bt_todate?: null | string;
  bt_toodo?: null | number;
  bt_torejectionreason?: null | string;
  bt_transfertype?: null | number;
  bt_triggerrequired?: null | boolean;
  bt_verdetransaction?: null | string;
  bt_workflowstatus?: null | number;
  count?: null | number;
  odataApprovalEmployee?: null | string;
  odataApprovalLegalEntity?: null | string;
  odataApprovalStatus?: null | string;
  odataAssignedEmployee?: null | string;
  odataAssignedLegalEntity?: null | string;
  odataClassification?: null | string;
  odataDisposalType?: null | string;
  odataFromConfirmationEmployee?: null | string;
  odataFromConfirmationLegalEntity?: null | string;
  odataFromEmployee?: null | string;
  odataFromLocation?: null | string;
  odataFromSupplier?: null | string;
  odataLocationGroup?: null | string;
  odataMovementStatus?: null | string;
  odataMovementType?: null | string;
  odataNewType?: null | string;
  odataOriginator?: null | string;
  odataOriginatorLegalEntity?: null | string;
  odataPostApprovalEmployee?: null | string;
  odataPostApprovalLegalEntity?: null | string;
  odataPostAssignedEmployee?: null | string;
  odataPostAssignedLegalEntity?: null | string;
  odataPostCreatedBy?: null | string;
  odataPostFromConfirmationEmployee?: null | string;
  odataPostFromConfirmationLegalEntity?: null | string;
  odataPostFromEmployee?: null | string;
  odataPostFromLocation?: null | string;
  odataPostFromSupplier?: null | string;
  odataPostLegalEntity?: null | string;
  odataPostLocationGroup?: null | string;
  odataPostOriginator?: null | string;
  odataPostOriginatorLegalEntity?: null | string;
  odataPostProxy?: null | string;
  odataPostRegNo?: null | string;
  odataPostSupplierTransferReason?: null | string;
  odataPostToConfirmationEmployee?: null | string;
  odataPostToConfirmationLegalEntity?: null | string;
  odataPostToEmployee?: null | string;
  odataPostToLocation?: null | string;
  odataPostToSupplier?: null | string;
  odataRegNo?: null | string;
  odataSupplierTransferReason?: null | string;
  odataToConfirmationEmployee?: null | string;
  odataToConfirmationLegalEntity?: null | string;
  odataToEmployee?: null | string;
  odataToLocation?: null | string;
  odataToSupplier?: null | string;
  odataTransferType?: null | string;
  odataWorkflowStatus?: null | string;
}
