/* tslint:disable */
/* eslint-disable */
import { TemplateTemplateGroup } from './template-template-group';
import { TemplateTemplateLegalEntity } from './template-template-legal-entity';
import { TemplateBtTemplateType } from './template-bt-template-type';
export interface TemplateTemplateSubgroup {
  bt_LegalEntity?: TemplateTemplateLegalEntity;
  bt_PeerReviewTemplateGroup?: TemplateTemplateGroup;
  bt_TemplateType?: TemplateBtTemplateType;
  bt_peerreviewtemplatesubgroup?: null | string;
  bt_peerreviewtemplatesubgroupid?: null | string;
  bt_sorting?: null | number;
}
