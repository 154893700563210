/* tslint:disable */
/* eslint-disable */
import { ProfileExpressions } from './profile-expressions';
import { ProfileProps } from './profile-props';
export interface ProfileInput {
  defaultValue?: null | string;
  expressions?: ProfileExpressions;
  key?: null | string;
  props?: ProfileProps;
  relatedID?: null | string;
  subtype?: null | string;
  type?: null | string;
}
