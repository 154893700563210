/* tslint:disable */
/* eslint-disable */
import { ProcessesEntityReference } from './processes-entity-reference';
import { ProcessesBtVerdeStandardProcess } from './processes-bt-verde-standard-process';
export interface ProcessesPostProcessesQueCommand {
  colName?: null | string;
  legalEntity?: ProcessesEntityReference;
  process?: ProcessesBtVerdeStandardProcess;
  recordId?: string;
  tableName?: null | string;
}
