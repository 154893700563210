/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DelegationsApiConfiguration, DelegationsApiConfigurationParams } from './delegations-api-configuration';

import { DelegationsApiDelegationsService } from './services/delegations-api-delegations.service';
import { DelegationsApiVersionService } from './services/delegations-api-version.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    DelegationsApiDelegationsService,
    DelegationsApiVersionService,
    DelegationsApiConfiguration
  ],
})
export class DelegationsApiModule {
  static forRoot(params: DelegationsApiConfigurationParams): ModuleWithProviders<DelegationsApiModule> {
    return {
      ngModule: DelegationsApiModule,
      providers: [
        {
          provide: DelegationsApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: DelegationsApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('DelegationsApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
