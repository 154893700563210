<div class="main">
  <div class="scrollable-content">
    <ngx-spinner
      [disableAnimation]="disableAnimation"
      name="LoadingData"
      bdColor="rgb(255, 255, 255)"
      size="default"
      color="#6264a7"
      type="ball-clip-rotate"
      [fullScreen]="false"
    >
      <p>Loading Data...</p>
    </ngx-spinner>
    <form class="k-form sidepanel-form" [formGroup]="fg" autocomplete="off">
      <kendo-gridlayout gap="2% 5%" [rows]="[]" [cols]="[{ width: '45%' }, { width: '45%' }]">
        <kendo-gridlayout-item [row]="1" [col]="1">
          <kendo-formfield>
            <kendo-label for="legalEntityDropdown">Legal Entity:</kendo-label>
            <kendo-dropdownlist
              formControlName="legalEntityDropdown"
              [data]="legalEntities"
              textField="legalEntityName"
              valueField="legalEntityId"
              id="legalEntityDropdown"
              name="legalEntityDropdown"
              required
            >
            </kendo-dropdownlist>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item [row]="1" [col]="2">
          <kendo-formfield>
            <kendo-label for="docTypeDropdown">Doc Type:</kendo-label>
            <kendo-dropdownlist
              formControlName="docTypeDropdown"
              [data]="docType"
              textField="bt_doctypefinancial"
              valueField="bt_doctypefinancialid"
              id="docTypeDropdown"
              name="docTypeDropdown"
              required
            >
            </kendo-dropdownlist>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item [row]="2" [col]="1" [colSpan]="2">
          <kendo-formfield>
            <kendo-label>Files:</kendo-label>
            <br />
            <kendo-fileselect formControlName="fileUpload" id="files" name="files" [multiple]="true" required> </kendo-fileselect>
          </kendo-formfield>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
    </form>
  </div>
</div>
<div #appendTo class="fixed-buttons">
  <span class="k-form-separator"></span>
  <br />
  <div class="k-form-buttons k-buttons-end">
    <div>
      <button kendoButton themeColor="primary" (click)="uploadFiles()" [disabled]="!isFormValid()">Upload Files</button>
    </div>
  </div>
</div>
<!-- #region Confirmation Modal -->
<kendo-dialog *ngIf="showConfirmationModal" title="{{ titleUploadStatusMessage }}" [width]="500">
  <div style="height: 60px">
    <ngx-spinner
      [disableAnimation]="disableAnimation"
      name="uploadSpinner"
      bdColor="rgb(255, 255, 255)"
      size="default"
      color="#6264a7"
      type="ball-clip-rotate"
      [fullScreen]="false"
    >
    </ngx-spinner>
    <p style="text-align: center">
      {{ uploadStatusMessage }}
    </p>
  </div>
  <div class="confirmation-modal-footer">
    <button [disabled]="cannotContinue" kendoButton themeColor="primary" style="margin-left: auto" (click)="closeConfirmationModal()">Continue</button>
  </div>
</kendo-dialog>
<!-- #endregion Confirmation Modal -->
