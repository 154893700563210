/* tslint:disable */
/* eslint-disable */
import { UserBtCallOutType } from './user-bt-call-out-type';
import { UserBtModule } from './user-bt-module';
export interface UserSecurityFunctionDto {
  approvalStep?: null | boolean;
  autoExpand?: null | boolean;
  callOutType?: UserBtCallOutType;
  configFieldName?: null | string;
  controlList?: null | boolean;
  controlListTemplateId?: null | string;
  controlListTemplateName?: null | string;
  dropdownDisplay?: null | string;
  dropdownName?: null | string;
  dynamicFormRegisterId?: null | string;
  dynamicFormRegisterName?: null | string;
  function?: null | string;
  functionId?: null | string;
  groupName?: null | string;
  groupingSequence?: null | number;
  jsonFileName?: null | string;
  learnCallOut?: null | boolean;
  legalEntityId?: string;
  legalEntityName?: null | string;
  menuContext?: null | string;
  menuGroup?: null | string;
  menuGrouping?: null | boolean;
  menuName?: null | string;
  menuSequence?: null | number;
  module?: UserBtModule;
  powerBI?: null | boolean;
  reportId?: null | string;
  reportType?: null | string;
  technicalName?: null | string;
  useInMenu?: null | boolean;
  workspaceId?: null | string;
}
