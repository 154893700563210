/* tslint:disable */
/* eslint-disable */
import { User } from './user';
import { VerdeApprovalLogs } from './verde-approval-logs';
export interface VerdeApprovalLogGroups {
  bt_SubmittedBy?: User;
  bt_bt_verdeapprovallogs_LogGroup_bt_verdeapp?: null | Array<VerdeApprovalLogs>;
  bt_comment?: null | any;
  bt_verdeapprovallogsgroup?: null | string;
  bt_verdeapprovallogsgroupid?: null | string;
  bt_verdeapprovallogsgroupstatus?: number;
}
