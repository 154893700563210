/* tslint:disable */
/* eslint-disable */
import { QualificationsGuidExtrapolate } from './qualifications-guid-extrapolate';
import { QualificationsBtValidationType } from './qualifications-bt-validation-type';
export interface QualificationsBtEmployeeEducationalQualificationRenewal {
  bt_EmployeeQualificationRenewalid?: null | string;
  bt_employeequalification?: null | string;
  bt_proxy?: null | boolean;
  bt_proxyid?: null | string;
  bt_proxylegalentity?: null | string;
  bt_renewaldate?: null | string;
  bt_validatebyentity?: null | string;
  bt_validated?: null | boolean;
  bt_validatedby?: null | string;
  bt_validationtype?: QualificationsBtValidationType;
  bt_validationurl?: null | string;
  bthr_isonumeric?: null | string;
  employeeQualification?: QualificationsGuidExtrapolate;
  proofOfRenewal?: null | Array<Blob>;
  proofOfRenewalFileName?: null | string;
  proofOfRenewalFileString?: null | string;
  proxyId?: QualificationsGuidExtrapolate;
  proxyLegalEntity?: QualificationsGuidExtrapolate;
  validatebyentity?: QualificationsGuidExtrapolate;
  validatedby?: QualificationsGuidExtrapolate;
}
