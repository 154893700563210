/* tslint:disable */
/* eslint-disable */
export interface BtBtVerdepolicycontentPolicyBtVerdepoli {
  '_bt_childcontent_value'?: null | string;
  '_bt_legalentity_value'?: null | string;
  '_bt_policy_value'?: null | string;
  bt_childsorting?: null | number;
  bt_content?: null | string;
  bt_contentheader?: null | string;
  bt_contenttype?: null | number;
  bt_effectivedate?: null | string;
  bt_effectiveversion?: null | string;
  bt_sortingsequence?: null | number;
  bt_verdepolicycontent?: null | string;
  bt_verdepolicycontentid?: null | string;
  odataChildContent?: null | string;
  odataLegalEntity?: null | string;
  odataPolicy?: null | string;
}
