<div class="emp-exit-form">

  <form class="k-form"
        [formGroup]="registerForm"
        style="max-height: 90%; min-height: 80vh;">
    <div class="grid-container">
      <kendo-gridlayout gap="1% 10%"
                        [rows]="[
          { height: 70 },
          { height: 70 },
          { height: 70 },
          { height: 160 },
          { height: 80 },
          { height: 160 },
          { height: 70 },]"
                        [cols]="[{ width: gridColWidth }, { width: gridColWidth }]">
        <!--JOIN DATE-->
        <kendo-gridlayout-item [row]="2"
                               [col]="1">
          <kendo-formfield class="formfield">
            <label class="exit-form-label"
                   [for]="joinDate"
                   text="Join Date">Join Date:</label>
            <kendo-datepicker #joinDate
                              formControlName="joinDate"
                              readonly="true"
                              [format]="'yyyy-MM-dd'">
            </kendo-datepicker>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <!--JOIN DATE-->
        <!--NOTICE PERIOD-->
        <kendo-gridlayout-item [row]="2"
                               [col]="2">
          <kendo-formfield>
            <label class="exit-form-label"
                   [for]="noticePeriod"
                   text="Notice Period">Notice Period:</label>
            <kendo-textbox formControlName="noticePeriod"
                           #noticePeriod
                           readonly="true"></kendo-textbox>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <!--NOTICE PERIOD-->
        <!--NOTICE DATE-->
        <kendo-gridlayout-item [row]="3"
                               [col]="1">
          <kendo-formfield class="formfield">
            <label class="exit-form-label"
                   [for]="noticeDate"
                   text="Notice Date">Notice Date:</label>
            <kendo-datepicker #noticeDate
                              formControlName="noticeDate"
                              [format]="'yyyy-MM-dd'"
                              [min]="todayDate"
                              (valueChange)="onNoticeDateChange($event)">
            </kendo-datepicker>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <!--NOTICE DATE-->
        <!--EXIT DATE-->
        <kendo-gridlayout-item [row]="3"
                               [col]="2">
          <kendo-formfield class="formfield">
            <label class="exit-form-label"
                   [for]="exitDate"
                   text="Exit Date">Exit Date:</label>
            <kendo-datepicker #exitDate
                              formControlName="exitDate"
                              [format]="'yyyy-MM-dd'"
                              [min]="minExitDate">
            </kendo-datepicker>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <!--END EXIT DATE-->
        <!--RESIGNATION TYPE-->
        <kendo-gridlayout-item [row]="1"
                               [col]="1">
          <kendo-formfield class=""
                           style="margin-top:20px">
            <label class="exit-form-label"
                   [for]="resignationType"
                   text="Resignation Type">Termination Type:</label>
            <kendo-dropdownlist class="dropdowns"
                                #resignationType
                                [data]="terminationOptionsList"
                                formControlName="resignationType"
                                textField="value"
                                valueField="value"
                                id="legalEntityDropdown"
                                name="legalEntityDropdown"
                                required
                                [ngModel]
                                [disabled]="">
              <ng-template kendoDropDownListNoDataTemplate>
                <p></p>
              </ng-template>
            </kendo-dropdownlist>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <!--RESIGNATION TYPE-->
        <!--END COMMENT-->
        <kendo-gridlayout-item [row]="4"
                               [col]="1"
                               [colSpan]="2">
          <label class="exit-form-label"
                 text="comments">Comment (required)<span class="required-asterisk">*</span></label>
          <kendo-editor *ngIf="resignationNotice === 'Both' || resignationNotice === 'NoticeOnScreen'"
                        style="max-width: 100%"
                        [id]="editorId"
                        formControlName="editor"
                        (valueChange)="editorValueChange($event)"
                        required></kendo-editor>
          <div *ngIf="resignationNotice === 'Both' || resignationNotice === 'AttachNotice'"
               style="margin-top: auto;">
            <kendo-fileselect required
                              (valueChange)="onFileSelect($event)"
                              (remove)="removeFile($event)"
                              [restrictions]="restrictions"
                              formControlName="fileUpload"> </kendo-fileselect>

            <kendo-formhint>Allowed extensions are pdf (required)<span
                    class="required-asterisk">*</span></kendo-formhint>
          </div>
        </kendo-gridlayout-item>
        <!--END COMMENT-->
      </kendo-gridlayout>
    </div>
  </form>
  <div class="k-form-buttons, form-buttons">
    <button kendoButton
            themeColor="primary"
            (click)="process()">
      Submit
    </button>
  </div>
</div>

<verde-modal modalTitle="Confirmation"
             id="confirmationModal"
             [hasCancelButton]="true"
             submitButton="Continue"
             (actionButton)="confirmResignation()"
             modalMaxWidth="400">
  <div style="position: relative; height: 60px;">
    <p>{{ confirmMessage }}</p>
  </div>
</verde-modal>

<verde-modal modalTitle="Notice"
             id="requiredFieldsModal"
             [hasCancelButton]="false"
             submitButton="Continue"
             (actionButton)="closeModal()"
             modalMaxWidth="400">
  <div style="position: relative; height: 60px;">
    <p>Please complete all required fields</p>
  </div>
</verde-modal>