/* tslint:disable */
/* eslint-disable */
import { PerformanceReviewGuidExtrapolate } from './performance-review-guid-extrapolate';
import { PerformanceReviewBtPeerReviewStatus } from './performance-review-bt-peer-review-status';
import { PerformanceReviewBtResponderCategory } from './performance-review-bt-responder-category';
export interface PerformanceReviewPeerReviewFeedbackSummary {
  bt_cycle?: null | string;
  bt_duedate?: null | string;
  bt_employee?: null | string;
  bt_peerreviewfeedbacksummary?: null | string;
  bt_peerreviewfeedbacksummaryid?: null | string;
  bt_peerreviewstatus?: PerformanceReviewBtPeerReviewStatus;
  bt_peerreviewtemplate?: null | string;
  bt_period?: null | string;
  bt_respondercategory?: PerformanceReviewBtResponderCategory;
  bt_tcacceptance?: null | boolean;
  bt_tcacceptancedate?: null | string;
  cycle?: PerformanceReviewGuidExtrapolate;
  employee?: PerformanceReviewGuidExtrapolate;
  peerreviewtemplate?: PerformanceReviewGuidExtrapolate;
  period?: PerformanceReviewGuidExtrapolate;
}
