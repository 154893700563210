/* tslint:disable */
/* eslint-disable */
import { BtManager } from './bt-manager';
import { BtNominatedEmployee } from './bt-nominated-employee';
export interface VoteNominationList {
  '_bt_manager_value'?: null | string;
  '_bt_managerlegalentity_value'?: null | string;
  '_bt_nominatedemployee_value'?: null | string;
  '_bt_nomineelegalentity_value'?: null | string;
  '_bt_votegroup_value'?: null | string;
  bt_Manager?: BtManager;
  bt_NominatedEmployee?: BtNominatedEmployee;
  bt_approvalrequiredby?: null | string;
  bt_approvalstatus?: null | number;
  bt_managerapprovaldate?: null | string;
  bt_managerapproved?: null | boolean;
  bt_managerrejectionreason?: null | string;
  bt_nominationaccepted?: null | boolean;
  bt_nominationaccepteddate?: null | string;
  bt_numberofnominations?: null | number;
  bt_votenominatedlist?: null | string;
  bt_votenominatedlistid?: null | string;
  bt_weighting?: null | number;
  odataApprovalStatus?: null | string;
  odataManagerApproved?: null | string;
  odataManagerLegalEntity?: null | string;
  odataNomineeLegalEntity?: null | string;
  odataRequiredBy?: null | string;
  odataVoteGroup?: null | string;
}
