/* tslint:disable */
/* eslint-disable */
import { BthrEmployeeId } from './bthr-employee-id';
export interface EmployeeStatusFiltered {
  bt_employeestatusid?: null | string;
  bt_healthstatus?: null | boolean;
  bt_locationupdated?: null | boolean;
  bt_presencestatussource?: null | string;
  bt_status?: null | string;
  bt_temperature?: null | number;
  bthr_EmployeeID?: BthrEmployeeId;
  odataHealthStatus?: null | string;
  odataLocationUpdated?: null | string;
}
