/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BookingsBaseService } from '../bookings-base-service';
import { BookingsApiConfiguration } from '../bookings-api-configuration';
import { BookingsStrictHttpResponse } from '../bookings-strict-http-response';
import { BookingsRequestBuilder } from '../bookings-request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BookingsBookingsExample } from '../models/bookings-bookings-example';
import { BookingsGetBookingsQuery } from '../models/bookings-get-bookings-query';
import { BookingsPostBookingsCommand } from '../models/bookings-post-bookings-command';

@Injectable({
  providedIn: 'root',
})
export class BookingsApiBookingsService extends BookingsBaseService {
  constructor(config: BookingsApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getBookings
   */
  static readonly GetBookingsPath = '/api/Bookings/get/bookings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBookings()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getBookings$Response(params?: {
    tenantId?: string;
    body?: BookingsGetBookingsQuery;
  }): Observable<BookingsStrictHttpResponse<Array<BookingsBookingsExample>>> {
    const rb = new BookingsRequestBuilder(this.rootUrl, BookingsApiBookingsService.GetBookingsPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as BookingsStrictHttpResponse<Array<BookingsBookingsExample>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getBookings$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getBookings(params?: { tenantId?: string; body?: BookingsGetBookingsQuery }): Observable<Array<BookingsBookingsExample>> {
    return this.getBookings$Response(params).pipe(
      map((r: BookingsStrictHttpResponse<Array<BookingsBookingsExample>>) => r.body as Array<BookingsBookingsExample>),
    );
  }

  /**
   * Path part for operation postBookings
   */
  static readonly PostBookingsPath = '/api/Bookings/post/bookings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postBookings()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postBookings$Response(params?: { tenantId?: string; body?: BookingsPostBookingsCommand }): Observable<BookingsStrictHttpResponse<boolean>> {
    const rb = new BookingsRequestBuilder(this.rootUrl, BookingsApiBookingsService.PostBookingsPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as BookingsStrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postBookings$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postBookings(params?: { tenantId?: string; body?: BookingsPostBookingsCommand }): Observable<boolean> {
    return this.postBookings$Response(params).pipe(map((r: BookingsStrictHttpResponse<boolean>) => r.body as boolean));
  }
}
