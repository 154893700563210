/* tslint:disable */
/* eslint-disable */
export interface DriversLicenseVehicleRestrictions {
  '_bt_country_value'?: null | string;
  bt_driverslicensecountryvehiclerestrictions?: null | string;
  bt_driverslicensecountryvehiclerestrictionsid?: null | string;
  bt_restrictcode?: null | string;
  bt_restriction?: null | string;
  odataCountry?: null | string;
}
