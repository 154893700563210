<div class="container">
  <div class="list">
    <p *ngFor="let item of peopleManagementFunctionsOnboarding"
       class="quick_menu_item"
       (click)="navigateSomewhere(item)">
      {{ item.menuName }}
    </p>
  </div>

  <!--<div class="list">
    <p *ngFor="let info of peopleManagementFunctionsOnboarding"
       class="quick_menu_item">
      {{ info.useInMenu }}
    </p>
  </div>-->
</div>


<!--<div class="wrapper">
  <kendo-expansionpanel *ngFor="let item of peopleManagementFunctionsOnboarding; index as i"
                        [title]="item.menuName"
                        [subtitle]="item.useInMenu"
                        [expanded]="expandedStates[i]"
                        (action)="onAction(i)"
                        (expandedChange)="onExpandedChange($event, i)"
                        (titleClick)="onTitleClick($event, i)">
    <p>TEst</p>
  </kendo-expansionpanel>
</div>-->
