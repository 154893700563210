import { QualificationsGetQualficationInstitutionLinkQuery, QualificationsCreateQualficationInstitutionLinkCommand } from '@verde/shared';
import { Action } from '@ngrx/store';

export enum QualificationInstitutionLinkActionTypes {
  // Get All VaccinationTypes
  QUALIFICATIONINSTITUTIONLINK_GET_ALL = '[QualificationInstitutionLink] Get All',
  QUALIFICATIONINSTITUTIONLINK_GET_ALL_SUCCESS = '[QualificationInstitutionLink] Get All Success',
  QUALIFICATIONINSTITUTIONLINK_GET_ALL_ERROR = '[QualificationInstitutionLink] Get All Error',

  // Get Reduced VaccinationTypes
  QUALIFICATIONINSTITUTIONLINK_GET_REDUCED = '[QualificationInstitutionLink] Get Reduced',
  QUALIFICATIONINSTITUTIONLINK_GET_REDUCED_SUCCESS = '[QualificationInstitutionLink] Get Reduced Success',
  QUALIFICATIONINSTITUTIONLINK_GET_REDUCED_ERROR = '[QualificationInstitutionLink] Get Reduced Error',

  // QualificationInstitutionLink Add
  QUALIFICATIONINSTITUTIONLINK_UPLOAD = '[QualificationInstitutionLink] Upload',
  QUALIFICATIONINSTITUTIONLINK_UPLOAD_SUCCESS = '[QualificationInstitutionLink] Upload Success',
  QUALIFICATIONINSTITUTIONLINK_UPLOAD_ERROR = '[QualificationInstitutionLink] Upload Error',

  // Clear All VaccinationTypes
  QUALIFICATIONINSTITUTIONLINK_CLEAR = '[QualificationInstitutionLink] Clear',
}

//Get All QualificationInstitutionLink
export class QualificationInstitutionLinkGetAll implements Action {
  readonly type = QualificationInstitutionLinkActionTypes.QUALIFICATIONINSTITUTIONLINK_GET_ALL;
  constructor(public body: QualificationsGetQualficationInstitutionLinkQuery) {}
}

export class QualificationInstitutionLinkGetAllSuccess implements Action {
  readonly type = QualificationInstitutionLinkActionTypes.QUALIFICATIONINSTITUTIONLINK_GET_ALL_SUCCESS;
  constructor(public payload: any) {}
}

export class QualificationInstitutionLinkGetAllError implements Action {
  readonly type = QualificationInstitutionLinkActionTypes.QUALIFICATIONINSTITUTIONLINK_GET_ALL_ERROR;
  constructor(public payload: any) {}
}

// UPLOAD MEASURE
export class QualificationInstitutionLinkUpload implements Action {
  readonly type = QualificationInstitutionLinkActionTypes.QUALIFICATIONINSTITUTIONLINK_UPLOAD;
  constructor(public payload: QualificationsCreateQualficationInstitutionLinkCommand) {}
}

export class QualificationInstitutionLinkUploadSuccess implements Action {
  readonly type = QualificationInstitutionLinkActionTypes.QUALIFICATIONINSTITUTIONLINK_UPLOAD_SUCCESS;
  constructor(public payload: any) {}
}

export class QualificationInstitutionLinkUploadError implements Action {
  readonly type = QualificationInstitutionLinkActionTypes.QUALIFICATIONINSTITUTIONLINK_UPLOAD_ERROR;
  constructor(public payload: any) {}
}

// Clear All
export class QualificationInstitutionLinkClear implements Action {
  readonly type = QualificationInstitutionLinkActionTypes.QUALIFICATIONINSTITUTIONLINK_CLEAR;
  constructor(public payload: any) {}
}

export type QualificationInstitutionLinkActions =
  // Get All QualificationInstitutionLink
  | QualificationInstitutionLinkGetAll
  | QualificationInstitutionLinkGetAllSuccess
  | QualificationInstitutionLinkGetAllError
  // Structure Change Request Upload
  | QualificationInstitutionLinkUpload
  | QualificationInstitutionLinkUploadSuccess
  | QualificationInstitutionLinkUploadError
  // Clear All QualificationInstitutionLink
  | QualificationInstitutionLinkClear;
