/* tslint:disable */
/* eslint-disable */
import { BtEmployeeApiModel } from './bt-employee-api-model';
export interface TimeEntryApiModel {
  '_bt_activity_value'?: null | string;
  '_bt_approvaldefinitionheader_value'?: null | string;
  '_bt_approvedby_value'?: null | string;
  '_bt_approvedbyentity_value'?: null | string;
  '_bt_employee_value'?: null | string;
  '_bt_entrylegalentity_value'?: null | string;
  '_bt_group_value'?: null | string;
  '_bt_legalentity_value'?: null | string;
  '_bt_loggroup_value'?: null | string;
  '_bt_manager_value'?: null | string;
  '_bt_managerentity_value'?: null | string;
  '_bt_task_value'?: null | string;
  '_bt_taskgroup_value'?: null | string;
  bt_Employee?: BtEmployeeApiModel;
  bt_approvalcomment?: null | string;
  bt_approvaldate?: null | string;
  bt_approvalstatus?: null | number;
  bt_customperod?: null | string;
  bt_date?: null | string;
  bt_description?: null | string;
  bt_end?: null | string;
  bt_managerapproval?: null | string;
  bt_managerapprovalcomment?: null | string;
  bt_managerapprovalstatus?: null | number;
  bt_month?: null | string;
  bt_source?: null | number;
  bt_start?: null | string;
  bt_timeentryno?: null | string;
  bt_timeentrystatus?: null | number;
  bt_units?: null | number;
  bt_verdetimeentryid?: null | string;
  bt_weeknumber?: null | string;
  bt_yearmonth?: null | string;
  bt_yearweekno?: null | string;
  odataActivity?: null | string;
  odataApprovalStatus?: null | string;
  odataApprover?: null | string;
  odataApproverLegalEntity?: null | string;
  odataDate?: null | string;
  odataEmployee?: null | string;
  odataEmployeeLegalEntity?: null | string;
  odataEntryLegalEntity?: null | string;
  odataGroup?: null | string;
  odataLogGroup?: null | string;
  odataManager?: null | string;
  odataManagerApprovalStatus?: null | string;
  odataManagerLegalEntity?: null | string;
  odataPostActivity?: null | string;
  odataPostApprover?: null | string;
  odataPostApproverLegalEntity?: null | string;
  odataPostEmployee?: null | string;
  odataPostEmployeeLegalEntity?: null | string;
  odataPostEntryLegalEntity?: null | string;
  odataPostGroup?: null | string;
  odataPostManager?: null | string;
  odataPostManagerLegalEntity?: null | string;
  odataPostTask?: null | string;
  odataPostTaskGroup?: null | string;
  odataSource?: null | string;
  odataTask?: null | string;
  odataTaskGroup?: null | string;
  odataTimeEntryStatus?: null | string;
  statecode?: null | number;
}
