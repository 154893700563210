import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'verde-kendo-dropdown',
  template: `
    <kendo-dropdownlist
      [id]="field.key"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [data]="to.options"
      [textField]="'label'"
      [valueField]="'value'"
      [value]="formControl.value"
      [valuePrimitive]="true"
      [disabled]="to.disabled"
      [readonly]="to.readOnly"
      [tabIndex]="to.tabIndex || 0"
      (valueChange)="onValueChange($event)"
    ></kendo-dropdownlist>
  `,
})
export class KendoDropdownTypeComponentKendo extends FieldType {
  ngOnInit() {
    console.log('Dropdown ID: ', this.field.key, 'Values: ', this.to.options);
  }
  onValueChange(value: any) {
    //console.log(value);
  }
}
