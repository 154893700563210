import { ChangeDetectorRef, Component, EventEmitter, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  VerdePositions,
  StructureBtStructureMovementType,
  StructureBtStructureType,
  StructureBtPositionseat,
  VerdeApprovalService,
  ProcessesEmployeeType,
  ProcessesApiProcessesService,
  ProcessesLocation,
  UserAppConfigDto,
  StructureApiStructureService,
  ProcessesStructureAppConfig,
  StructureSecurityProfile,
  DelegationsApiDelegationsService,
  StructureAllowanceLink,
  SharedApiStructureService,
} from '@verde/shared';
import { HttpCancelService } from '@verde/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
/*import { MenuActionStructureType, menuActionStructureTypes } from '../../config/menu-action-structure-type.config';*/
import { MenuActionStructureType, menuActionStructureTypes } from 'apps/verde/src/app/features/structure-management/config/menu-action-structure-type.config';
import { PositionService } from 'apps/verde/src/app/features/structure-management/services/position.service';
import { StructureService } from 'apps/verde/src/app/features/structure-management/services/structure.service';
import { StructureFullExtended } from 'apps/verde/src/app/features/structure-management/store/models/structure-full-extended.model';
import { SelectableSettings, TreeListComponent } from '@progress/kendo-angular-treelist';
import { saveAs } from 'file-saver';
import { ViewSDKClient } from '@verde/core';
import { DeviceTypeService } from '@verde/core';

@Component({
  selector: 'verde-structure-change-request',
  templateUrl: './structure-change-request.component.html',
  styleUrls: ['./structure-change-request.component.scss'],
})
export class StructureChangeRequestComponent implements OnInit, OnChanges, OnDestroy {
  /*@Input() parentItem: StructureFullExtended;*/
  /*@Input() miniStructure: StructureFullExtended[];*/
  /*@Input() movementType: StructureBtStructureMovementType;*/

  @ViewChild('structureChangeTreelist') structureChangeTreelist: TreeListComponent;
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter();

  private onDestroy$ = new Subject<boolean>();
  fb: FormBuilder;
  fg: FormGroup;
  fg2: FormGroup;
  fg3: FormGroup;
  fg4: FormGroup;
  BtStructureType: typeof StructureBtStructureType = StructureBtStructureType;
  BtPositionseat: typeof StructureBtPositionseat = StructureBtPositionseat;
  structureTypes: Array<MenuActionStructureType> = [];
  positionsData: VerdePositions[];
  positionsSource: VerdePositions[];
  parentLegalEntity: StructureFullExtended | null = null;
  parentDivision: StructureFullExtended | null = null;
  parentDepartment: StructureFullExtended | null = null;
  employeeTypeObj: ProcessesEmployeeType[] | null = null;
  locationObj: ProcessesLocation[] | null = null;
  miniStructure: StructureFullExtended[] | null = null;
  parentItem: StructureFullExtended | null = null;
  movementType: StructureBtStructureMovementType | null = null;
  defaultSecurityProfile: StructureSecurityProfile[] | null = null;
  securityProfiles: StructureSecurityProfile[] | null = null;
  defaultAllowances: StructureAllowanceLink[] | null = null;
  public selected: { itemKey: string }[] = [
    {
      itemKey: '',
    },
  ];
  public settings: SelectableSettings = {
    enabled: true,
    mode: 'row',
    multiple: false,
    drag: false,
    readonly: false,
  };
  isSeatSelected = false;
  appConfigs: ProcessesStructureAppConfig[] | null = null;
  public config: UserAppConfigDto = {};
  displayBudgets = true;
  displaySP = true;
  displayFI = true;
  displayEE = true;
  displayAllowance = true;
  totalRows: any;
  currentStep = 1;
  totalSteps = 4;
  securityFrom: string | null = null;
  count: number = 0;
  selectedPosition: VerdePositions | null = null;
  base64String: any;

  constructor(
    private positionService: PositionService,
    private structureService: StructureService,
    private structureApiStructureService: StructureApiStructureService,
    private httpCancelService: HttpCancelService,
    private sidebarService: VerdeApprovalService,
    private processesApiProcessesService: ProcessesApiProcessesService,
    private cdr: ChangeDetectorRef,
    private sharedApiStructureService: SharedApiStructureService,
    private delegationsApiDelegationsService: DelegationsApiDelegationsService,
    private viewSDKClient: ViewSDKClient,
    public deviceTypeService: DeviceTypeService,
  ) {
    this.sidebarService
      .getAppConfigs()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((item: any) => {
        this.appConfigs = item;
      });

    this.sidebarService
      .getMiniStructure()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((item: any) => {
        this.miniStructure = item;
      });

    this.sidebarService
      .getParentItem()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((item: any) => {
        this.parentItem = item;
        this.selected = [{ itemKey: this.parentItem.recordID || '' }];
        if (this.parentItem.structureType === 'Department') {
          this.setDisplays(this.parentItem);
        }
      });

    this.sidebarService
      .getMovementType()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((item: any) => {
        this.movementType = item;
      });

    //this.positionService.allEntityPositions$.pipe(takeUntil(this.onDestroy$)).subscribe((positions: StructurePositionExtended[]) => {
    //  this.positions = positions.filter((position) => !position.$new && !position.$dispose);
    //});

    this.sharedApiStructureService
      .getVerdePositions({ legalEntityID: this.parentItem.leganEntityID })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (val) => {
          this.positionsSource = val;
        },
        (error) => {
          console.error(error);
        },
        () => {
          this.positionsData = this.positionsSource.slice();
        },
      );

    this.structureService.parentLegalEntity$.pipe(takeUntil(this.onDestroy$)).subscribe((parentLegalEntity: StructureFullExtended) => {
      this.parentLegalEntity = parentLegalEntity;
      if (parentLegalEntity) {
        this.positionService.getAllPositions(parentLegalEntity.recordID);
      }
    });
    this.structureService.parentDivision$.pipe(takeUntil(this.onDestroy$)).subscribe((parentDivision: StructureFullExtended) => {
      if (parentDivision) {
        this.parentDivision = parentDivision;
      }
    });
    this.structureService.parentDepartment$.pipe(takeUntil(this.onDestroy$)).subscribe((parentDepartment: StructureFullExtended) => {
      if (parentDepartment) {
        this.parentDepartment = parentDepartment;
      }
    });

    this.getEmployeeTypes(this.parentItem.leganEntityID || '');
    this.getLocations(this.parentItem.leganEntityID || '');

    this.fb = new FormBuilder();
    this.resetForm();
    this.fg
      .get('structureType')
      .valueChanges.pipe(debounceTime(500), distinctUntilChanged(), takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.fg.get('name').setValue(null);
        this.fg.get('position').setValue(null);
      });
  }

  resetForm() {
    this.fg = this.fb.group({
      selectedName: [this.combineSelected()],
      structureType: [],
      transferType: [null],
      name: [''],
      parentRecordID: [],
      position: [null],
      employeeTypes: [null],
      motivation: [''],
      isManager: [],
      budget: ['yes'],
      eeSeat: ['yes'],
      inheritSecurity: ['yes'],
      location: [null],
      inheritJobDescription: ['yes'],
      defaultAllowances: ['yes'],
      effectiveDate: [new Date()],
      defaultSPfrom: [this.securityFrom],
      securityProfile: [],
      commentSP: [],
    });

    this.fg2 = this.fb.group({
      inheritSecurity2: ['yes'],
      defaultSPfrom: [this.securityFrom],
      securityProfile: [],
      commentSP: [],
    });

    this.fg3 = this.fb.group({
      inheritJobDescription2: ['yes'],
      fileUpload: [],
    });

    this.fg4 = this.fb.group({
      defaultAllowances2: ['yes'],
      commentAllowance: [],
    });
  }

  isSelectedStructureType(includedStructureType: StructureBtStructureType[]): boolean {
    return includedStructureType.some((structureType) => structureType === this.fg.get('structureType').value);
  }

  isDisabled() {
    return this.fg ? this.fg.invalid : true;
  }

  onRowSelect(event: any) {
    this.currentStep = 1;
    this.resetForm();
    const selectedRow = event.items[0].dataItem;
    if (selectedRow.structureType === 'Seat') {
      this.isSeatSelected = true;
      this.selected.splice(0);
    } else {
      this.isSeatSelected = false;
      this.parentItem = event.items[0].dataItem;
      this.fg.get('selectedName').setValue(this.combineSelected());
      this.initialize();
      if (selectedRow.structureType === StructureBtStructureType.Department) {
        this.getEmployeeTypes(selectedRow.leganEntityID);
        this.getLocations(selectedRow.leganEntityID);
        this.setDisplays(selectedRow);
      }
    }
  }

  async add() {
    this.fg.markAllAsTouched();
    const structureType = this.fg.value.structureType;
    const position = this.positionsData.find((position) => position.bthr_positionid === this.fg.value.position);

    if (structureType === StructureBtStructureType.PositionSeat && this.fg.value.isManager) {
      const structure: StructureFullExtended = {
        ...this.parentItem,
        positionSeatID: position?.bthr_positionid,
        positionDescription: position?.bthr_description,
        seatStatus: StructureBtPositionseat.Requested,
        $motivation: this.fg.value.motivation,
        $isManager: this.fg.value.isManager,
        $effectiveDate: this.fg.value.effectiveDate,
        $parentLegalEntity: this.parentLegalEntity?.recordID,
        $parentDivision: this.parentDivision?.recordID,
        $parentDepartment: this.parentDepartment?.recordID,
      };
      this.structureService.updateStructures([structure]);
    } else if (structureType === StructureBtStructureType.PositionSeat) {
      const structure: StructureFullExtended = {
        structureType: this.convertEnumValueWithoutSpace(StructureBtStructureType.PositionSeat),
        name: this.fg.value.name,
        parentRecordID: this.parentItem.recordID,
        positionSeatID: position?.bthr_positionid,
        positionDescription: position?.bthr_description,

        $motivation: this.fg.value.motivation,
        $isManager: this.fg.value.isManager,
        $effectiveDate: this.fg.value.effectiveDate,
        $parentLegalEntity: this.parentLegalEntity?.recordID,
        $parentDivision: this.parentDivision?.recordID,
        $parentDepartment: this.parentDepartment?.recordID,

        employeeTypeID: this.fg.value.employeeTypes.bthr_employeetypeid,
        locationID: this.fg.value.location.bthr_locationid,
        eeSeat: this.fg.value.eeSeat === 'yes' ? true : false,
        inheritJobDescription: this.fg.value.inheritJobDescription == 'yes' ? true : false,
        inheritSecurityProfile: this.fg.value.inheritSecurity == 'yes' ? true : false,
        defaultAllowances: this.fg.value.defaultAllowances == 'yes' ? true : false,
        cr438_allowancecomment: this.fg4.value.commentAllowance,
        bt_securityprofilecomment: this.fg2.value.commentSP,
        positionID: this.fg.value.position,
      };
      this.structureService.addStructures([structure]);
      this.sidebarService.setShowSidebar(false);
      this.close();
    } else if ((structureType === StructureBtStructureType.Department || structureType === StructureBtStructureType.Division) && this.fg.valid == true) {
      const structure: StructureFullExtended = {
        structureType,
        name: this.fg.value.name,
        parentRecordID: this.parentItem.recordID,
        positionSeatID: position?.bthr_positionid,
        positionDescription: position?.bthr_description,

        $motivation: this.fg.value.motivation,
        $isManager: this.fg.value.isManager,
        $effectiveDate: this.fg.value.effectiveDate,
        $parentLegalEntity: this.parentLegalEntity?.recordID,
        $parentDivision: this.parentDivision?.recordID,
        $parentDepartment: this.parentDepartment?.recordID,
      };
      this.structureService.addStructures([structure]);
      this.sidebarService.setShowSidebar(false);
      this.close();
    }

    /*seatStatus: StructureBtPositionseat.NotApplicable,*/
  }

  close() {
    this.resetForm();
    this.structureService.deselectParentStructureId();
    this.closeModal.emit();
  }

  ngOnInit() {
    this.initialize();
    console.log(this.deviceTypeService.isMobile());
  }

  ngAfterViewInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      this.movementType &&
      changes.parentItem &&
      changes.parentItem.currentValue !== changes.parentItem.previousValue &&
      changes.parentItem.currentValue !== undefined
    ) {
      this.initialize();
    }

    if (
      this.parentItem &&
      changes.movementType &&
      changes.movementType.currentValue !== changes.movementType.previousValue &&
      changes.movementType.currentValue !== undefined
    ) {
      this.initialize();
    }
  }

  initialize() {
    this.structureTypes = [...menuActionStructureTypes].filter(
      (c) =>
        c.ParentStructureType === this.parentItem.structureType &&
        c.MovementType === this.movementType &&
        c.StructureType !== StructureBtStructureType.Position,
    );
    this.fg.get('structureType').setValue(this.structureTypes.length > 0 ? this.structureTypes[0].StructureType : null);
  }

  isExpanded = (dataItem: any): boolean => {
    return true;
  };

  //rowClass({ dataItem }): any {

  //  return {
  //    'background-row-new': dataItem.recordID === this.parentItem.recordID,
  //  };
  //}

  getReadableName(value: string) {
    return value ? value.replace(/([A-Z])/g, ' $1').trim() : value;
  }

  getEmployeeTypes(id: string) {
    let body: any = {
      legalEntity: id || 0,
    };
    this.processesApiProcessesService
      .getEmployeeType({ body: body })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (val) => {
          this.employeeTypeObj = val;
        },
        (error) => {
          console.error(error);
        },
      );
  }

  getLocations(id: string) {
    let body: any = {
      legalEntityID: id || 0,
    };

    this.processesApiProcessesService
      .getLocation({ body: body })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (val) => {
          this.locationObj = val;
        },
        (error) => {
          console.error(error);
        },
      );
  }

  ngOnDestroy(): void {
    this.httpCancelService.cancelPendingRequests();

    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  combineSelected(): string {
    if (this.parentItem) {
      const combined: string = this.parentItem.structureType + ' - ' + this.parentItem.name;
      return combined;
    } else {
      return '';
    }
  }

  setDisplays(item: any) {
    if (item && item.leganEntityID && this.parentItem) {
      this.appConfigs.forEach((element) => {
        if (element.legalEntity.bthr_legalentitiesid === item.leganEntityID) {
          this.displayBudgets = element.bt_budgets;
          this.displayFI = element.bt_financialinfo;
          this.displaySP = element.bt_securityprofile;
          this.securityFrom = element.bt_profilerulefrom;
          /*this.fg2.get('defaultSPfrom').setValue(this.securityFrom); */
        }
      });

      this.structureApiStructureService
        .getSecurityProfile({ body: { bt_profilerulefrom: 'Department', fromID: item.recordID } })
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          (val) => {
            this.defaultSecurityProfile = val;
          },
          (error) => {
            console.error(error);
          },
          () => {
            //if (Object.keys(this.defaultSecurityProfile[0]).length === 0) {
            //  let msg: StructureSecurityProfile = {
            //    bt_securityprofile: { id: '0', primaryName: 'No Default Available' }
            //  }
            //  this.fg.get('securityProfile').setValue(msg)
            //} else {
            //  this.fg.get('securityProfile').setValue(this.defaultSecurityProfile[0])
            //}
            if (this.defaultSecurityProfile) {
              this.fg2.get('securityProfile').setValue(this.defaultSecurityProfile[0].bt_securityprofile.primaryName);
            }
          },
        );
      this.count = this.count + 1;
    }
  }

  nextStep() {
    if (this.currentStep < this.totalSteps) {
      if (this.currentStep == 1) {
        this.fg.markAllAsTouched();
        if (this.fg.valid) {
          if (this.displaySP == true) {
            this.currentStep++;
          } else if (this.displaySP == false) {
            this.currentStep += 2;
            setTimeout(() => {
              this.viewSDKClient.ready().then(() => {
                this.viewSDKClient.previewFile('pdf-div', {}, this.base64String ?? '', this.selectedPosition.bt_JobDescriptionAttachment_Name);
              });
            }, 200);
          }
        }
      } else if (this.currentStep == 2) {
        this.fg2.markAllAsTouched();
        if (this.fg2.valid || this.fg2.value.inheritSecurity2 === 'yes') {
          this.currentStep++;
          setTimeout(() => {
            this.viewSDKClient.ready().then(() => {
              this.viewSDKClient.previewFile('pdf-div', {}, this.base64String ?? '', this.selectedPosition.bt_JobDescriptionAttachment_Name);
            });
          }, 200);
        }
      } else if (this.currentStep == 3) {
        this.fg3.markAllAsTouched();
        if (this.fg3.valid || this.fg3.value.inheritJobDescription2 === 'yes') {
          this.currentStep++;
        }
      }

      //this.structureApiStructureService.getManagerStructureLvl({ body: { managerID: "217871f6-63e6-ec11-bb3e-00224881abe4" } }).pipe(takeUntil(this.onDestroy$))
      //  .subscribe(
      //    (val) => {
      //      console.log(val)
      //    },
      //    (error) => {
      //      console.error(error);
      //    }, () => {

      //    },
      //  );

      this.cdr.detectChanges();
    }
  }

  previousStep() {
    if (this.currentStep > 1) {
      if (this.currentStep == 3) {
        if (this.displaySP == true) {
          this.currentStep--;
        } else if (this.displaySP == false) {
          this.currentStep -= 2;
        }
      } else if (this.currentStep != 3) {
        this.currentStep--;
        if (this.currentStep == 3) {
          setTimeout(() => {
            this.viewSDKClient.ready().then(() => {
              this.viewSDKClient.previewFile('pdf-div', {}, this.base64String ?? '', this.selectedPosition.bt_JobDescriptionAttachment_Name);
            });
          }, 200);
        }
      }
    }
  }

  // Radio Buttons Change Events
  onRadioSPChange(value: string) {
    this.fg2.get('inheritSecurity2').setValue(value);
    this.fg.get('inheritSecurity').setValue(value);
    if (this.parentItem) {
      this.securityProfiles = [];
      if (value === 'yes') {
        if (Object.keys(this.defaultSecurityProfile[0]).length === 0) {
          let test: StructureSecurityProfile = {
            bt_securityprofile: { id: '0', primaryName: 'No Default Available' },
          };
          this.fg.get('securityProfile').setValue(test);
        } else {
          this.fg.get('securityProfile').setValue(this.defaultSecurityProfile[0]);
        }
      }
      if (value === 'no') {
        this.fg.get('securityProfile').setValue([]);
        let from = 'LegalEntity';
        this.structureApiStructureService
          .getSecurityProfile({ body: { bt_profilerulefrom: from, fromID: this.parentItem.leganEntityID } })
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(
            (val) => {
              this.securityProfiles = val;
            },
            (error) => {
              console.error(error);
            },
            () => {},
          );
      }
    }
  }

  onRadioSPChange2(value: string) {
    this.fg.get('inheritSecurity').setValue(value);
    this.fg2.get('inheritSecurity2').setValue(value);
    if (this.parentItem) {
      this.securityProfiles = [];
      if (value === 'yes') {
        if (Object.keys(this.defaultSecurityProfile[0]).length === 0) {
          let test: StructureSecurityProfile = {
            bt_securityprofile: { id: '0', primaryName: 'No Default Available' },
          };
          this.fg.get('securityProfile').setValue(test);
        } else {
          this.fg.get('securityProfile').setValue(this.defaultSecurityProfile[0]);
        }
      }
      if (value === 'no') {
        this.fg.get('securityProfile').setValue([]);
        let from = 'LegalEntity';
        this.structureApiStructureService
          .getSecurityProfile({ body: { bt_profilerulefrom: from, fromID: this.parentItem.leganEntityID } })
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(
            (val) => {
              this.securityProfiles = val;
            },
            (error) => {
              console.error(error);
            },
            () => {},
          );
      }
    }
  }

  onRadioJDChange(value: string) {
    this.fg3.get('inheritJobDescription2').setValue(value);
    this.fg.get('inheritJobDescription').setValue(value);
  }

  onRadioJDChange2(value: string) {
    this.fg.get('inheritJobDescription').setValue(value);
    this.fg3.get('inheritJobDescription2').setValue(value);
    setTimeout(() => {
      this.viewSDKClient.ready().then(() => {
        this.viewSDKClient.previewFile('pdf-div', {}, this.base64String ?? '', this.selectedPosition.bt_JobDescriptionAttachment_Name);
      });
    }, 200);
  }

  onRadioDAChange(value: string) {
    this.fg4.get('defaultAllowances2').setValue(value);
    this.fg.get('defaultAllowances').setValue(value);
  }

  onRadioDAChange2(value: string) {
    this.fg.get('defaultAllowances').setValue(value);
    this.fg4.get('defaultAllowances2').setValue(value);
  }
  /*---------------*/

  // File Upload
  onFileSelect(e: any) {}
  /*---------------*/

  onPositionSelect(positionid) {
    this.selectedPosition = this.positionsData.find((position) => position.bthr_positionid === positionid);
    this.delegationsApiDelegationsService
      .downloadFile({ body: { columnName: 'bt_jobdescriptionattachment', entityName: 'bthr_position', recordID: positionid } })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (val) => {
          this.base64String = val;
        },
        (error) => {
          console.error(error);
        },
        () => {},
      );
    this.structureApiStructureService
      .getAllowanceLink({ body: { bt_positionallowancelinkID: positionid } })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (val) => {
          this.defaultAllowances = val;
        },
        (error) => {
          console.error(error);
        },
        () => {},
      );
  }

  //convert to blob
  downloadPdf() {
    const binaryData = atob(this.base64String);
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }
    const blob = new Blob([uint8Array], { type: 'application/pdf' });
    saveAs(blob, this.selectedPosition.bt_JobDescriptionAttachment_Name);
  }

  //Position Dropdown Filter
  handlePositionFilter(value) {
    this.positionsData = this.positionsSource.filter((s) => s.bthr_description.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  convertEnumValueWithoutSpace(value: StructureBtStructureType): StructureBtStructureType {
    switch (value) {
      case StructureBtStructureType.PositionSeat:
        return StructureBtStructureType.PositionSeat.replace(/\s+/g, '') as StructureBtStructureType;
      default:
        throw new Error('Invalid enum value');
    }
  }

  formatNumber(num: number): string {
    if (isNaN(num)) {
      return 'Invalid Number';
    }
    const roundedNum = Math.round(num * 100) / 100;
    const formatted = roundedNum.toFixed(2);

    return formatted;
  }
}
