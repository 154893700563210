/* tslint:disable */
/* eslint-disable */
export interface EmployeeTimeOffSummary {
  '_bt_employeeno_value'?: null | string;
  '_bt_legalentity_value'?: null | string;
  '_bt_timeofftype_value'?: null | string;
  bt_colour?: null | string;
  bt_cycleendlocal?: null | string;
  bt_cycletodatebalance?: null | number;
  bt_cycletodatebalancesum?: null | number;
  bt_cycletodatepending?: null | number;
  bt_employeetimeoffsummary?: null | string;
  bt_employeetimeoffsummaryid?: null | string;
  bt_showondashboard?: null | string;
  odataTimeOffType?: null | string;
}
