/* tslint:disable */
/* eslint-disable */
export interface NotificationTemplates {
  bt_accessstatus?: null | number;
  bt_comment?: null | string;
  bt_grouping?: null | string;
  bt_name?: null | string;
  bt_notificationbody?: null | string;
  bt_oneliner?: null | string;
  odataLocation?: null | string;
  odataStatus?: null | string;
}
