/* tslint:disable */
/* eslint-disable */
import { VehiclesFiltered } from './vehicles-filtered';
export interface FleetClassificationUpdate {
  '_bt_fleetitem_value'?: null | string;
  '_bt_legalentity_value'?: null | string;
  '_bt_originaltransfer_value'?: null | string;
  '_bt_requestedby_value'?: null | string;
  '_bt_requestedbyentity_value'?: null | string;
  bt_FleetItem?: VehiclesFiltered;
  bt_autoreverse?: null | boolean;
  bt_effectivedate?: null | string;
  bt_fleetclassificationupdateid?: null | string;
  bt_fleetclassificationupdateno?: null | string;
  bt_fromclassification?: null | number;
  bt_processdate?: null | string;
  bt_processed?: null | boolean;
  bt_processnow?: null | boolean;
  bt_reversedate?: null | string;
  bt_toclassification?: null | number;
  odataAutoReverse?: null | string;
  odataFleetItem?: null | string;
  odataFromClassification?: null | string;
  odataLegalEntity?: null | string;
  odataOriginalTransfer?: null | string;
  odataPostFleetItem?: null | string;
  odataPostLegalEntity?: null | string;
  odataPostOriginalTransfer?: null | string;
  odataPostRequestedBy?: null | string;
  odataPostRequestedByLegalEntity?: null | string;
  odataRequestedBy?: null | string;
  odataRequestedByLegalEntity?: null | string;
  odataToClassification?: null | string;
}
