import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { ImageCompressionService } from '@verde/core';
import { WebcamImage } from 'ngx-webcam';
import { Subject } from 'rxjs';

enum IMAGE_STATES {
  Upload = 'upload',
  Photo = 'photo',
  All = 'all',
}

enum IMAGE_OPTIONS {
  Upload = 'upload',
  Photo = 'photo',
}

enum IMAGE_TYPE {
  Base64 = 'base64',
  File = 'file',
}

@Component({
  selector: 'verde-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss'],
})
export class ImageUploadComponent implements OnInit {
  @Output() imageChanged: EventEmitter<string | SafeUrl | File | null> = new EventEmitter<string | SafeUrl | File | null>();

  @Input() state: IMAGE_STATES = IMAGE_STATES.All;
  @Input() imageType: IMAGE_TYPE = IMAGE_TYPE.Base64;
  @Input() acceptFileTypes: string = 'image/png, image/jpeg, image/jpg';
  @Input() addText: string = 'Upload photo';

  trigger: Subject<void> = new Subject<void>();
  IMAGE_OPTIONS = IMAGE_OPTIONS;
  imageOption: IMAGE_OPTIONS | null = null;
  hoverActive: boolean = false;
  uploadedImage: string | SafeResourceUrl = '';
  isPdf: boolean = false;
  alwaysBase64: string = '';

  constructor(private imageCompression: ImageCompressionService, private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    if (this.state === IMAGE_STATES.Photo) {
      this.selectOption(IMAGE_OPTIONS.Photo);
    } else if (this.state === IMAGE_STATES.Upload) {
      this.selectOption(IMAGE_OPTIONS.Upload);
    }
  }

  resetImageComponent(): void {
    this.imageOption = null;
    this.uploadedImage = '';
    this.isPdf = false;
  }

  fileDropped(newFile: File): void {
    this.imageCompression.compressBase64Image(newFile).then((image) => {
      fetch(image)
        .then((val) => val.blob())
        .then((blob) => {
          const file = new File([blob], newFile.name, { type: newFile.type });
          if (this.imageType === IMAGE_TYPE.Base64) {
            this.convertFileToBase64(file);
          } else if (this.imageType === IMAGE_TYPE.File) {
            this.convertFileToBase64(file, false);
            this.imageChanged.emit(file);
          }
        });
    });
  }

  onFileChanged(event: any): void {
    this.imageCompression.compressBase64Image(event.target.files[0]).then((image) => {
      fetch(image)
        .then((val) => val.blob())
        .then((blob) => {
          const file = new File([blob], event.target.files[0].name, { type: event.target.files[0].type });
          if (this.imageType === IMAGE_TYPE.Base64) {
            this.convertFileToBase64(file);
          } else if (this.imageType === IMAGE_TYPE.File) {
            this.convertFileToBase64(file, false);
            this.imageChanged.emit(file);
          }
        });
    });
  }

  selectOption(option: IMAGE_OPTIONS): void {
    this.imageOption = option;
  }

  setHoverState(val: boolean): void {
    this.hoverActive = val;
  }

  handleImage(webcamImage: WebcamImage): void {
    this.imageCompression.compressBase64Image(webcamImage.imageAsBase64).then(async (image) => {
      this.uploadedImage = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64,${image}`);
      this.alwaysBase64 = `data:image/png;base64,${image}`;
      if (this.imageType === IMAGE_TYPE.Base64) {
        this.sendImage();
      } else if (this.imageType === IMAGE_TYPE.File) {
        const base64Response = await fetch(`data:image/png;base64,${image}`);
        const blob: Blob = await base64Response.blob();
        this.imageChanged.emit(new File([blob], 'license', { type: 'image/png' }));
      }
    });
  }

  triggerSnapshot(): void {
    this.trigger.next();
  }

  sendImage(): void {
    if (typeof this.uploadedImage === 'object') {
      this.imageChanged.emit(this.alwaysBase64);
    } else {
      this.imageChanged.emit(this.uploadedImage);
    }
  }

  resetImage(): void {
    this.uploadedImage = '';
    this.imageChanged.emit(null);
  }

  private convertFileToBase64(file: File, isBase64: boolean = true): void {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64: string = reader.result as string;
      const type = base64.split(';')[0].split('/')[1];
      if (type === 'pdf') {
        this.isPdf = true;
      } else {
        this.isPdf = false;
      }
      this.uploadedImage = base64;
      if (isBase64) {
        this.sendImage();
      }
    };
    reader.onerror = (error) => {
      console.log('Error: ', error);
    };
  }
}
