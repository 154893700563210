import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { GroupService } from '../../../../../../../../../../apps/verde/src/app/features/profile2/services/group.service';
import { ModalService } from '../../../../../modals/modal.service';
import { VerdeApprovalService } from '../../../../../services/verde-approval/verde-approval.service';
import { RefreshServiceService } from '../../../../../../../../../../apps/verde/src/app/features/profile2/components/templates/services/refresh-service.service';
import { TemplateBtTemplateType, TemplateTemplateGroup } from '@verde/shared';

@Component({
  selector: 'verde-group-add',
  templateUrl: './group-add.component.html',
  styleUrls: ['./group-add.component.scss'],
})
export class GroupAddComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<boolean>();

  legalEntityID: string;
  legalEntityName: string;
  tempType: string;
  formValues: any;
  postTempType: any;
  groupForm: FormGroup;
  visited: boolean;
  private methodCallSubscription: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private sidebarService: VerdeApprovalService,
    private modalService: ModalService,
    private groupService: GroupService,
    private refreshServiceService: RefreshServiceService,
  ) {
    this.methodCallSubscription = this.refreshServiceService.methodCall$.subscribe(() => {
      if (this.visited === true) {
        this.loadPage();
      }
    });
  }

  ngOnInit(): void {
    this.visited = true;
    this.loadPage();
  }

  loadPage() {
    this.sidebarService
      .getSelectedLegalEntityID()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((id: any) => {
        this.legalEntityID = id;
      });
    this.sidebarService
      .getSelectedLegalEntityName()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((name: any) => {
        this.legalEntityName = name;
      });
    this.sidebarService
      .getSelectedTemplateType()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((type: any) => {
        this.tempType = type;
      });

    this.groupForm = this.formBuilder.group({
      legalEntity: [this.legalEntityName],
      templateType: [this.tempType],
      name: [, Validators.required],
      sorting: [, [Validators.required, Validators.pattern('[0-9]*')]],
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();

    console.log('destrouy');
  }

  initializeForm(): void {
    this.groupForm = this.formBuilder.group({
      legalEntity: [this.legalEntityName],
      templateType: [this.tempType],
      name: ['', Validators.required],
      sorting: ['', [Validators.required, Validators.pattern('[0-9]*')]],
    });
  }

  addGroup() {
    const formValues = this.groupForm.value;
    if (this.groupForm.valid) {
      this.formValues = formValues;
      this.modalService.open('confirmGroup');
    } else {
    }
  }

  confirm() {
    let postTempType: TemplateBtTemplateType | undefined;
    if (this.tempType === TemplateBtTemplateType.PeerReview) {
      postTempType = TemplateBtTemplateType.PeerReview;
    } else if (this.tempType === TemplateBtTemplateType.PerformanceReview) {
      postTempType = TemplateBtTemplateType.PerformanceReview;
    } else if (this.tempType === TemplateBtTemplateType.Fleet) {
      postTempType = TemplateBtTemplateType.Fleet;
    } else if (this.tempType === TemplateBtTemplateType.Equipment) {
      postTempType = TemplateBtTemplateType.Equipment;
    }

    var numberValue = Number(this.formValues.sorting);
    let body: TemplateTemplateGroup = {
      bt_sorting: numberValue,
      bt_name: this.formValues.name,
      bt_TemplateType: postTempType,
    };
    this.groupService.uploadGroup({ templateGroupBody: body });
    this.sidebarService.setShowSidebar(false);
    this.resetForm();
  }

  resetForm(): void {
    const legalEntityValue = this.groupForm.get('legalEntity')?.value;
    const templateTypeValue = this.groupForm.get('templateType')?.value;
    this.groupForm.reset();
    this.groupForm.patchValue({
      legalEntity: legalEntityValue,
      templateType: templateTypeValue,
    });
    this.refresh();
  }

  refresh() {
    this.refreshServiceService.refreshView();
  }
}
