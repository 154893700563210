/* tslint:disable */
/* eslint-disable */
export interface EquipmentItemSummary {
  '_bt_legalentity_value'?: null | string;
  '_bt_location_value'?: null | string;
  '_bt_make_value'?: null | string;
  '_bt_model_value'?: null | string;
  '_bt_requestequipmentitem_value'?: null | string;
  ageValue?: null | string;
  bt_equipmentitemsummaryid?: null | string;
  bt_equipmentno?: null | string;
  bt_onhandallocated?: null | number;
  bt_onhandallocated_date?: null | string;
  bt_onhandallocated_state?: null | number;
  bt_onhandavailable?: null | number;
  bt_onhandavailable_date?: null | string;
  bt_onhandavailable_state?: null | number;
  bt_onorder?: null | number;
  bt_onorder_date?: null | string;
  bt_onorder_state?: null | number;
  bt_onorderallocated?: null | number;
  bt_onorderallocated_date?: null | string;
  bt_onorderallocated_state?: null | number;
  bt_requested?: null | number;
  bt_requested_date?: null | string;
  bt_requested_state?: null | number;
  bt_requestedallocated?: null | number;
  bt_requestedallocated_date?: null | string;
  bt_requestedallocated_state?: null | number;
  bt_summaryid?: null | string;
  name?: null | string;
  odataLegalEntity?: null | string;
  odataLocation?: null | string;
  odataMake?: null | string;
  odataModel?: null | string;
  odataRequestEquipmentItem?: null | string;
  parentID?: null | string;
}
