/* tslint:disable */
/* eslint-disable */
export enum QualificationsBthrQualificationType {
  Doctorate = 'Doctorate',
  BachelorsDegree = 'BachelorsDegree',
  HonoursDegree = 'HonoursDegree',
  MastersDegree = 'MastersDegree',
  Diploma = 'Diploma',
  Certificate = 'Certificate',
  PostgraduateDiploma = 'PostgraduateDiploma',
  ProfesionalQualification = 'ProfesionalQualification',
  AssociateDegree = 'AssociateDegree'
}
