/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { DOC_ORIENTATION, NgxImageCompressService } from 'ngx-image-compress';

@Injectable({
  providedIn: 'root',
})
export class ImageCompressionService {
  private readonly orientation: DOC_ORIENTATION = DOC_ORIENTATION.Default;
  private readonly ratio: number = 90;
  private readonly quality: number = 50;
  private readonly maxWidth: number = 1200;
  private readonly maxHeight: number = 750;

  constructor(private imageCompress: NgxImageCompressService) {}

  async compressBase64Image(
    image: string | File,
    ratio: number = this.ratio,
    quality = this.quality,
    maxWidth = this.maxWidth,
    maxHeight = this.maxHeight,
    orientation: DOC_ORIENTATION = this.orientation,
  ): Promise<string> {
    let newImage: string = image as string;
    try {
      if (typeof image !== 'string') {
        newImage = await this.getBase64(image);
      }
      if (newImage.includes('data:image')) {
        return this.imageCompress.compressFile(newImage, orientation, ratio, quality, maxWidth, maxHeight);
      } else {
        return await (
          await this.imageCompress.compressFile('data:image/png;base64,' + newImage, orientation, ratio, quality, maxWidth, maxHeight)
        ).split(',')[1];
      }
    } catch (e) {
      return await '';
    }
  }

  public getBase64(file: Blob): Promise<string> {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise((resolve, reject) => {
      reader.onload = () => {
        resolve(reader.result as string);
      };
      reader.onerror = (error) => {
        reject('Error: ' + error);
      };
    });
  }

  dataURLtoFile(dataurl: string, filename: string) {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/);
    const type = mime !== null ? mime[1] : undefined;
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type });
  }
}
