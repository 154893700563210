/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { DocumentBaseService } from '../document-base-service';
import { DocumentApiConfiguration } from '../document-api-configuration';
import { DocumentStrictHttpResponse } from '../document-strict-http-response';
import { DocumentRequestBuilder } from '../document-request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DocumentCountryTaxYear } from '../models/document-country-tax-year';
import { DocumentDocSalaryAdviceDetail } from '../models/document-doc-salary-advice-detail';
import { DocumentDocSalaryAdviceHeader } from '../models/document-doc-salary-advice-header';
import { DocumentDocSalaryAdviceHeaderQueryResult } from '../models/document-doc-salary-advice-header-query-result';
import { DocumentDocSalaryEmployeeQueryResult } from '../models/document-doc-salary-employee-query-result';
import { DocumentDocTypeFinancial } from '../models/document-doc-type-financial';
import { DocumentDownloadEncryptedFileQuery } from '../models/document-download-encrypted-file-query';
import { DocumentEnhanced } from '../models/document-enhanced';
import { DocumentGetCountryTaxYearQuery } from '../models/document-get-country-tax-year-query';
import { DocumentGetDecryptObject } from '../models/document-get-decrypt-object';
import { DocumentGetDocSalaryAdviceDetailQuery } from '../models/document-get-doc-salary-advice-detail-query';
import { DocumentGetDocSalaryAdviceHeaderQuery } from '../models/document-get-doc-salary-advice-header-query';
import { DocumentGetDocSalaryAdviceIQuerable } from '../models/document-get-doc-salary-advice-i-querable';
import { DocumentGetDocSalaryAdviceQuery } from '../models/document-get-doc-salary-advice-query';
import { DocumentGetDocTypeFinancialQuery } from '../models/document-get-doc-type-financial-query';
import { DocumentGetEnhancedQuery } from '../models/document-get-enhanced-query';
import { DocumentGetIntegrationSourceQuery } from '../models/document-get-integration-source-query';
import { DocumentGetLegalEntityQuery } from '../models/document-get-legal-entity-query';
import { DocumentGetPayrollHeader } from '../models/document-get-payroll-header';
import { DocumentGetTaxYearAndPeriodQuery } from '../models/document-get-tax-year-and-period-query';
import { DocumentGetVerdeBulkControlTableQuery } from '../models/document-get-verde-bulk-control-table-query';
import { DocumentGetVerdeBulkImportQuery } from '../models/document-get-verde-bulk-import-query';
import { DocumentGetViewEmployeesQuery } from '../models/document-get-view-employees-query';
import { DocumentImportPayrollHeader } from '../models/document-import-payroll-header';
import { DocumentLegalEntityDto } from '../models/document-legal-entity-dto';
import { DocumentPostBtPayrollmportDetailCommand } from '../models/document-post-bt-payrollmport-detail-command';
import { DocumentPostDocTypeCommand } from '../models/document-post-doc-type-command';
import { DocumentPostVerdeBulkControlCommand } from '../models/document-post-verde-bulk-control-command';
import { DocumentPostVerdePayslipAccessControl } from '../models/document-post-verde-payslip-access-control';
import { DocumentTaxYear } from '../models/document-tax-year';
import { DocumentUploadQueuedFileResponse } from '../models/document-upload-queued-file-response';
import { DocumentVerdeBulkControlTable } from '../models/document-verde-bulk-control-table';
import { DocumentVerdeBulkImport } from '../models/document-verde-bulk-import';
import { DocumentVerdeIntegrationSource } from '../models/document-verde-integration-source';
import { DocumentBtDocumentGrouping } from '../models/document-bt-document-grouping';

@Injectable({
  providedIn: 'root',
})
export class DocumentApiDocumentService extends DocumentBaseService {
  constructor(config: DocumentApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation decrypt
   */
  static readonly DecryptPath = '/api/Document/Decrypt';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `decrypt()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  decrypt$Response(params?: { tenantId?: string; body?: DocumentGetDecryptObject }): Observable<DocumentStrictHttpResponse<DocumentDocSalaryAdviceHeader>> {
    const rb = new DocumentRequestBuilder(this.rootUrl, DocumentApiDocumentService.DecryptPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as DocumentStrictHttpResponse<DocumentDocSalaryAdviceHeader>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `decrypt$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  decrypt(params?: { tenantId?: string; body?: DocumentGetDecryptObject }): Observable<DocumentDocSalaryAdviceHeader> {
    return this.decrypt$Response(params).pipe(map((r: DocumentStrictHttpResponse<DocumentDocSalaryAdviceHeader>) => r.body as DocumentDocSalaryAdviceHeader));
  }

  /**
   * Path part for operation getCountryTaxYear
   */
  static readonly GetCountryTaxYearPath = '/api/Document/CountryTaxYear';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCountryTaxYear()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getCountryTaxYear$Response(params?: {
    tenantId?: string;
    body?: DocumentGetCountryTaxYearQuery;
  }): Observable<DocumentStrictHttpResponse<Array<DocumentCountryTaxYear>>> {
    const rb = new DocumentRequestBuilder(this.rootUrl, DocumentApiDocumentService.GetCountryTaxYearPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as DocumentStrictHttpResponse<Array<DocumentCountryTaxYear>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCountryTaxYear$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getCountryTaxYear(params?: { tenantId?: string; body?: DocumentGetCountryTaxYearQuery }): Observable<Array<DocumentCountryTaxYear>> {
    return this.getCountryTaxYear$Response(params).pipe(
      map((r: DocumentStrictHttpResponse<Array<DocumentCountryTaxYear>>) => r.body as Array<DocumentCountryTaxYear>),
    );
  }

  /**
   * Path part for operation getLegalEntity
   */
  static readonly GetLegalEntityPath = '/api/Document/GetLegalEntity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLegalEntity()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getLegalEntity$Response(params?: {
    tenantId?: string;
    body?: DocumentGetLegalEntityQuery;
  }): Observable<DocumentStrictHttpResponse<Array<DocumentLegalEntityDto>>> {
    const rb = new DocumentRequestBuilder(this.rootUrl, DocumentApiDocumentService.GetLegalEntityPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as DocumentStrictHttpResponse<Array<DocumentLegalEntityDto>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLegalEntity$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getLegalEntity(params?: { tenantId?: string; body?: DocumentGetLegalEntityQuery }): Observable<Array<DocumentLegalEntityDto>> {
    return this.getLegalEntity$Response(params).pipe(
      map((r: DocumentStrictHttpResponse<Array<DocumentLegalEntityDto>>) => r.body as Array<DocumentLegalEntityDto>),
    );
  }

  /**
   * Path part for operation getIntegrationSource
   */
  static readonly GetIntegrationSourcePath = '/api/Document/GetIntegrationSource';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getIntegrationSource()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getIntegrationSource$Response(params?: {
    tenantId?: string;
    body?: DocumentGetIntegrationSourceQuery;
  }): Observable<DocumentStrictHttpResponse<Array<DocumentVerdeIntegrationSource>>> {
    const rb = new DocumentRequestBuilder(this.rootUrl, DocumentApiDocumentService.GetIntegrationSourcePath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as DocumentStrictHttpResponse<Array<DocumentVerdeIntegrationSource>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getIntegrationSource$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getIntegrationSource(params?: { tenantId?: string; body?: DocumentGetIntegrationSourceQuery }): Observable<Array<DocumentVerdeIntegrationSource>> {
    return this.getIntegrationSource$Response(params).pipe(
      map((r: DocumentStrictHttpResponse<Array<DocumentVerdeIntegrationSource>>) => r.body as Array<DocumentVerdeIntegrationSource>),
    );
  }

  /**
   * Path part for operation getBulkUploadDocs
   */
  static readonly GetBulkUploadDocsPath = '/api/Document/GetBulkUploadDocs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBulkUploadDocs()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getBulkUploadDocs$Response(params?: {
    tenantId?: string;
    body?: DocumentGetVerdeBulkImportQuery;
  }): Observable<DocumentStrictHttpResponse<Array<DocumentVerdeBulkImport>>> {
    const rb = new DocumentRequestBuilder(this.rootUrl, DocumentApiDocumentService.GetBulkUploadDocsPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as DocumentStrictHttpResponse<Array<DocumentVerdeBulkImport>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getBulkUploadDocs$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getBulkUploadDocs(params?: { tenantId?: string; body?: DocumentGetVerdeBulkImportQuery }): Observable<Array<DocumentVerdeBulkImport>> {
    return this.getBulkUploadDocs$Response(params).pipe(
      map((r: DocumentStrictHttpResponse<Array<DocumentVerdeBulkImport>>) => r.body as Array<DocumentVerdeBulkImport>),
    );
  }

  /**
   * Path part for operation getDocSalaryAdvice
   */
  static readonly GetDocSalaryAdvicePath = '/api/Document/GetDocSalaryAdvice';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocSalaryAdvice()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getDocSalaryAdvice$Response(params?: {
    tenantId?: string;
    body?: DocumentGetDocSalaryAdviceQuery;
  }): Observable<DocumentStrictHttpResponse<Array<DocumentDocSalaryAdviceHeader>>> {
    const rb = new DocumentRequestBuilder(this.rootUrl, DocumentApiDocumentService.GetDocSalaryAdvicePath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as DocumentStrictHttpResponse<Array<DocumentDocSalaryAdviceHeader>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDocSalaryAdvice$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getDocSalaryAdvice(params?: { tenantId?: string; body?: DocumentGetDocSalaryAdviceQuery }): Observable<Array<DocumentDocSalaryAdviceHeader>> {
    return this.getDocSalaryAdvice$Response(params).pipe(
      map((r: DocumentStrictHttpResponse<Array<DocumentDocSalaryAdviceHeader>>) => r.body as Array<DocumentDocSalaryAdviceHeader>),
    );
  }

  /**
   * Path part for operation getDocSalaryAdviceIQuery
   */
  static readonly GetDocSalaryAdviceIQueryPath = '/api/Document/GetDocSalaryAdviceIQuery';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocSalaryAdviceIQuery()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getDocSalaryAdviceIQuery$Response(params?: {
    tenantId?: string;
    body?: DocumentGetDocSalaryAdviceIQuerable;
  }): Observable<DocumentStrictHttpResponse<DocumentDocSalaryAdviceHeaderQueryResult>> {
    const rb = new DocumentRequestBuilder(this.rootUrl, DocumentApiDocumentService.GetDocSalaryAdviceIQueryPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as DocumentStrictHttpResponse<DocumentDocSalaryAdviceHeaderQueryResult>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDocSalaryAdviceIQuery$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getDocSalaryAdviceIQuery(params?: { tenantId?: string; body?: DocumentGetDocSalaryAdviceIQuerable }): Observable<DocumentDocSalaryAdviceHeaderQueryResult> {
    return this.getDocSalaryAdviceIQuery$Response(params).pipe(
      map((r: DocumentStrictHttpResponse<DocumentDocSalaryAdviceHeaderQueryResult>) => r.body as DocumentDocSalaryAdviceHeaderQueryResult),
    );
  }

  /**
   * Path part for operation getDocSalaryAdviceDetail
   */
  static readonly GetDocSalaryAdviceDetailPath = '/api/Document/docSalaryAdviceDetail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocSalaryAdviceDetail()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getDocSalaryAdviceDetail$Response(params?: {
    tenantId?: string;
    body?: DocumentGetDocSalaryAdviceDetailQuery;
  }): Observable<DocumentStrictHttpResponse<Array<DocumentDocSalaryAdviceDetail>>> {
    const rb = new DocumentRequestBuilder(this.rootUrl, DocumentApiDocumentService.GetDocSalaryAdviceDetailPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as DocumentStrictHttpResponse<Array<DocumentDocSalaryAdviceDetail>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDocSalaryAdviceDetail$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getDocSalaryAdviceDetail(params?: { tenantId?: string; body?: DocumentGetDocSalaryAdviceDetailQuery }): Observable<Array<DocumentDocSalaryAdviceDetail>> {
    return this.getDocSalaryAdviceDetail$Response(params).pipe(
      map((r: DocumentStrictHttpResponse<Array<DocumentDocSalaryAdviceDetail>>) => r.body as Array<DocumentDocSalaryAdviceDetail>),
    );
  }

  /**
   * Path part for operation getDocSalaryAdviceHeader
   */
  static readonly GetDocSalaryAdviceHeaderPath = '/api/Document/docSalaryAdviceHeader';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocSalaryAdviceHeader()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getDocSalaryAdviceHeader$Response(params?: {
    tenantId?: string;
    body?: DocumentGetDocSalaryAdviceHeaderQuery;
  }): Observable<DocumentStrictHttpResponse<Array<DocumentDocSalaryAdviceHeader>>> {
    const rb = new DocumentRequestBuilder(this.rootUrl, DocumentApiDocumentService.GetDocSalaryAdviceHeaderPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as DocumentStrictHttpResponse<Array<DocumentDocSalaryAdviceHeader>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDocSalaryAdviceHeader$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getDocSalaryAdviceHeader(params?: { tenantId?: string; body?: DocumentGetDocSalaryAdviceHeaderQuery }): Observable<Array<DocumentDocSalaryAdviceHeader>> {
    return this.getDocSalaryAdviceHeader$Response(params).pipe(
      map((r: DocumentStrictHttpResponse<Array<DocumentDocSalaryAdviceHeader>>) => r.body as Array<DocumentDocSalaryAdviceHeader>),
    );
  }

  /**
   * Path part for operation getDocTypeFinancial
   */
  static readonly GetDocTypeFinancialPath = '/api/Document/docTypeFinancial';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocTypeFinancial()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getDocTypeFinancial$Response(params?: {
    tenantId?: string;
    body?: DocumentGetDocTypeFinancialQuery;
  }): Observable<DocumentStrictHttpResponse<Array<DocumentDocTypeFinancial>>> {
    const rb = new DocumentRequestBuilder(this.rootUrl, DocumentApiDocumentService.GetDocTypeFinancialPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as DocumentStrictHttpResponse<Array<DocumentDocTypeFinancial>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDocTypeFinancial$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getDocTypeFinancial(params?: { tenantId?: string; body?: DocumentGetDocTypeFinancialQuery }): Observable<Array<DocumentDocTypeFinancial>> {
    return this.getDocTypeFinancial$Response(params).pipe(
      map((r: DocumentStrictHttpResponse<Array<DocumentDocTypeFinancial>>) => r.body as Array<DocumentDocTypeFinancial>),
    );
  }

  /**
   * Path part for operation getTaxYearAndPeriod
   */
  static readonly GetTaxYearAndPeriodPath = '/api/Document/taxYearAndPeriod';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTaxYearAndPeriod()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getTaxYearAndPeriod$Response(params?: {
    tenantId?: string;
    body?: DocumentGetTaxYearAndPeriodQuery;
  }): Observable<DocumentStrictHttpResponse<Array<DocumentTaxYear>>> {
    const rb = new DocumentRequestBuilder(this.rootUrl, DocumentApiDocumentService.GetTaxYearAndPeriodPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as DocumentStrictHttpResponse<Array<DocumentTaxYear>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTaxYearAndPeriod$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getTaxYearAndPeriod(params?: { tenantId?: string; body?: DocumentGetTaxYearAndPeriodQuery }): Observable<Array<DocumentTaxYear>> {
    return this.getTaxYearAndPeriod$Response(params).pipe(map((r: DocumentStrictHttpResponse<Array<DocumentTaxYear>>) => r.body as Array<DocumentTaxYear>));
  }

  /**
   * Path part for operation uploadFiles
   */
  static readonly UploadFilesPath = '/api/Document/UploadFiles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadFiles()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadFiles$Response(params?: {
    tenantId?: string;
    body?: {
      Files?: Array<Blob>;
      Overwrite?: boolean;
      TaxYear?: string;
      Period?: string;
      PeriodYear?: string;
      LegalEntity?: string;
      PayrollImportDetailID?: string;
    };
  }): Observable<DocumentStrictHttpResponse<string>> {
    const rb = new DocumentRequestBuilder(this.rootUrl, DocumentApiDocumentService.UploadFilesPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as DocumentStrictHttpResponse<string>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `uploadFiles$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadFiles(params?: {
    tenantId?: string;
    body?: {
      Files?: Array<Blob>;
      Overwrite?: boolean;
      TaxYear?: string;
      Period?: string;
      PeriodYear?: string;
      LegalEntity?: string;
      PayrollImportDetailID?: string;
    };
  }): Observable<string> {
    return this.uploadFiles$Response(params).pipe(map((r: DocumentStrictHttpResponse<string>) => r.body as string));
  }

  /**
   * Path part for operation postBtPayrollmportDetail
   */
  static readonly PostBtPayrollmportDetailPath = '/api/Document/patch/btPayrollmportDetail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postBtPayrollmportDetail()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postBtPayrollmportDetail$Response(params?: {
    tenantId?: string;
    body?: DocumentPostBtPayrollmportDetailCommand;
  }): Observable<DocumentStrictHttpResponse<string>> {
    const rb = new DocumentRequestBuilder(this.rootUrl, DocumentApiDocumentService.PostBtPayrollmportDetailPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as DocumentStrictHttpResponse<string>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postBtPayrollmportDetail$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postBtPayrollmportDetail(params?: { tenantId?: string; body?: DocumentPostBtPayrollmportDetailCommand }): Observable<string> {
    return this.postBtPayrollmportDetail$Response(params).pipe(map((r: DocumentStrictHttpResponse<string>) => r.body as string));
  }

  /**
   * Path part for operation postVerdePayslipAccessControl
   */
  static readonly PostVerdePayslipAccessControlPath = '/api/Document/patch/VerdePayslipAccessControl';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postVerdePayslipAccessControl()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postVerdePayslipAccessControl$Response(params?: {
    tenantId?: string;
    body?: DocumentPostVerdePayslipAccessControl;
  }): Observable<DocumentStrictHttpResponse<string>> {
    const rb = new DocumentRequestBuilder(this.rootUrl, DocumentApiDocumentService.PostVerdePayslipAccessControlPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as DocumentStrictHttpResponse<string>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postVerdePayslipAccessControl$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postVerdePayslipAccessControl(params?: { tenantId?: string; body?: DocumentPostVerdePayslipAccessControl }): Observable<string> {
    return this.postVerdePayslipAccessControl$Response(params).pipe(map((r: DocumentStrictHttpResponse<string>) => r.body as string));
  }

  /**
   * Path part for operation postDocType
   */
  static readonly PostDocTypePath = '/api/Document/patch/PostDocType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postDocType()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postDocType$Response(params?: { tenantId?: string; body?: DocumentPostDocTypeCommand }): Observable<DocumentStrictHttpResponse<boolean>> {
    const rb = new DocumentRequestBuilder(this.rootUrl, DocumentApiDocumentService.PostDocTypePath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as DocumentStrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postDocType$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postDocType(params?: { tenantId?: string; body?: DocumentPostDocTypeCommand }): Observable<boolean> {
    return this.postDocType$Response(params).pipe(map((r: DocumentStrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation postVerdeBulkControl
   */
  static readonly PostVerdeBulkControlPath = '/api/Document/patch/PostVerdeBulkControl';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postVerdeBulkControl()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postVerdeBulkControl$Response(params?: { tenantId?: string; body?: DocumentPostVerdeBulkControlCommand }): Observable<DocumentStrictHttpResponse<string>> {
    const rb = new DocumentRequestBuilder(this.rootUrl, DocumentApiDocumentService.PostVerdeBulkControlPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as DocumentStrictHttpResponse<string>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postVerdeBulkControl$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postVerdeBulkControl(params?: { tenantId?: string; body?: DocumentPostVerdeBulkControlCommand }): Observable<string> {
    return this.postVerdeBulkControl$Response(params).pipe(map((r: DocumentStrictHttpResponse<string>) => r.body as string));
  }

  /**
   * Path part for operation getImportPayrollHeader
   */
  static readonly GetImportPayrollHeaderPath = '/api/Document/importPayrollHeader';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getImportPayrollHeader()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getImportPayrollHeader$Response(params?: {
    tenantId?: string;
    body?: DocumentGetPayrollHeader;
  }): Observable<DocumentStrictHttpResponse<Array<DocumentImportPayrollHeader>>> {
    const rb = new DocumentRequestBuilder(this.rootUrl, DocumentApiDocumentService.GetImportPayrollHeaderPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as DocumentStrictHttpResponse<Array<DocumentImportPayrollHeader>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getImportPayrollHeader$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getImportPayrollHeader(params?: { tenantId?: string; body?: DocumentGetPayrollHeader }): Observable<Array<DocumentImportPayrollHeader>> {
    return this.getImportPayrollHeader$Response(params).pipe(
      map((r: DocumentStrictHttpResponse<Array<DocumentImportPayrollHeader>>) => r.body as Array<DocumentImportPayrollHeader>),
    );
  }

  /**
   * Path part for operation getEnhanced
   */
  static readonly GetEnhancedPath = '/api/Document/enhanced';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEnhanced()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEnhanced$Response(params?: { tenantId?: string; body?: DocumentGetEnhancedQuery }): Observable<DocumentStrictHttpResponse<Array<DocumentEnhanced>>> {
    const rb = new DocumentRequestBuilder(this.rootUrl, DocumentApiDocumentService.GetEnhancedPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as DocumentStrictHttpResponse<Array<DocumentEnhanced>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEnhanced$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEnhanced(params?: { tenantId?: string; body?: DocumentGetEnhancedQuery }): Observable<Array<DocumentEnhanced>> {
    return this.getEnhanced$Response(params).pipe(map((r: DocumentStrictHttpResponse<Array<DocumentEnhanced>>) => r.body as Array<DocumentEnhanced>));
  }

  /**
   * Path part for operation getViewEmployees
   */
  static readonly GetViewEmployeesPath = '/api/Document/viewemployees';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getViewEmployees()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getViewEmployees$Response(params?: {
    tenantId?: string;
    body?: DocumentGetViewEmployeesQuery;
  }): Observable<DocumentStrictHttpResponse<DocumentDocSalaryEmployeeQueryResult>> {
    const rb = new DocumentRequestBuilder(this.rootUrl, DocumentApiDocumentService.GetViewEmployeesPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as DocumentStrictHttpResponse<DocumentDocSalaryEmployeeQueryResult>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getViewEmployees$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getViewEmployees(params?: { tenantId?: string; body?: DocumentGetViewEmployeesQuery }): Observable<DocumentDocSalaryEmployeeQueryResult> {
    return this.getViewEmployees$Response(params).pipe(
      map((r: DocumentStrictHttpResponse<DocumentDocSalaryEmployeeQueryResult>) => r.body as DocumentDocSalaryEmployeeQueryResult),
    );
  }

  /**
   * Path part for operation getVerdeBulkControlTable
   */
  static readonly GetVerdeBulkControlTablePath = '/api/Document/VerdeBulkControlTable';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVerdeBulkControlTable()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getVerdeBulkControlTable$Response(params?: {
    tenantId?: string;
    body?: DocumentGetVerdeBulkControlTableQuery;
  }): Observable<DocumentStrictHttpResponse<Array<DocumentVerdeBulkControlTable>>> {
    const rb = new DocumentRequestBuilder(this.rootUrl, DocumentApiDocumentService.GetVerdeBulkControlTablePath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as DocumentStrictHttpResponse<Array<DocumentVerdeBulkControlTable>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVerdeBulkControlTable$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getVerdeBulkControlTable(params?: { tenantId?: string; body?: DocumentGetVerdeBulkControlTableQuery }): Observable<Array<DocumentVerdeBulkControlTable>> {
    return this.getVerdeBulkControlTable$Response(params).pipe(
      map((r: DocumentStrictHttpResponse<Array<DocumentVerdeBulkControlTable>>) => r.body as Array<DocumentVerdeBulkControlTable>),
    );
  }

  /**
   * Path part for operation uploadQueuedFile
   */
  static readonly UploadQueuedFilePath = '/api/Document/UploadQueuedFile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadQueuedFile()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadQueuedFile$Response(params?: {
    tenantId?: string;
    body?: {
      File?: Blob;
      DocType?: string;
      ImportControl?: string;
      IntegrationSource?: string;
      Grouping?: DocumentBtDocumentGrouping;
      UploadGuid?: string;
      QueueGuid?: string;
    };
  }): Observable<DocumentStrictHttpResponse<DocumentUploadQueuedFileResponse>> {
    const rb = new DocumentRequestBuilder(this.rootUrl, DocumentApiDocumentService.UploadQueuedFilePath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as DocumentStrictHttpResponse<DocumentUploadQueuedFileResponse>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `uploadQueuedFile$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadQueuedFile(params?: {
    tenantId?: string;
    body?: {
      File?: Blob;
      DocType?: string;
      ImportControl?: string;
      IntegrationSource?: string;
      Grouping?: DocumentBtDocumentGrouping;
      UploadGuid?: string;
      QueueGuid?: string;
    };
  }): Observable<DocumentUploadQueuedFileResponse> {
    return this.uploadQueuedFile$Response(params).pipe(
      map((r: DocumentStrictHttpResponse<DocumentUploadQueuedFileResponse>) => r.body as DocumentUploadQueuedFileResponse),
    );
  }

  /**
   * Path part for operation downloadFile
   */
  static readonly DownloadFilePath = '/api/Document/DownloadFile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadFile()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  downloadFile$Response(params?: { tenantId?: string; body?: DocumentDownloadEncryptedFileQuery }): Observable<DocumentStrictHttpResponse<string>> {
    const rb = new DocumentRequestBuilder(this.rootUrl, DocumentApiDocumentService.DownloadFilePath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as DocumentStrictHttpResponse<string>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `downloadFile$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  downloadFile(params?: { tenantId?: string; body?: DocumentDownloadEncryptedFileQuery }): Observable<string> {
    return this.downloadFile$Response(params).pipe(map((r: DocumentStrictHttpResponse<string>) => r.body as string));
  }
}
