/* tslint:disable */
/* eslint-disable */
export interface DvOrganisations {
  bthr_additionalnumber?: null | string;
  bthr_contactname?: null | string;
  bthr_contactnumber?: null | string;
  bthr_description?: null | string;
  bthr_email?: null | string;
  bthr_organisationid?: null | string;
  bthr_organisationname?: null | string;
  bthr_organisationtype?: null | number;
  bthr_website?: null | string;
  odataLegalEntity?: null | string;
  odataOrganisationType?: null | string;
  odataRecordStatus?: null | string;
  odataStatus?: null | string;
}
