/* tslint:disable */
/* eslint-disable */
export enum UserVerdeErrorType {
  None = 'None',
  VerdeOffline = 'VerdeOffline',
  ConfigNotFound = 'ConfigNotFound',
  EmployeeNotFound = 'EmployeeNotFound',
  EmployeeConfigNotFound = 'EmployeeConfigNotFound',
  EmployeeTeamNotFound = 'EmployeeTeamNotFound',
  EmployeeBlocked = 'EmployeeBlocked',
  EmployeeNotFoundOrBlocked = 'EmployeeNotFoundOrBlocked',
  EmployeeFirstTimeLogin = 'EmployeeFirstTimeLogin',
  RolesNotFound = 'RolesNotFound'
}
