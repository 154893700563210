/* tslint:disable */
/* eslint-disable */
export interface EmployeeTracking {
  '_bt_employee_value'?: null | string;
  '_bt_legalentity_value'?: null | string;
  '_bt_locationverde_value'?: null | string;
  bt_employeetrackingsetupid?: null | string;
  bt_name?: null | string;
  odataLocation?: null | string;
}
