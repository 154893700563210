<verde-modal id="naMobileModal"
             [showHeader]="false"
             [showFooter]="false">
  <div class="content">
    <p>This action is not available on mobile</p>
  </div>
  <button kendoButton
          themeColor="primary"
          class="mobile-button"
          (click)="close()">
    Continue
  </button>
</verde-modal>