<form [formGroup]="measureForm"
      (ngSubmit)="editMeasure()">
  <div class="sidebar-layout">
    <div>
      <label>Legal Entity:</label>
      <br />
      <kendo-textbox [readonly]="true"
                     [value]="selectedMeasure?.bt_LegalEntity.bthr_legalentityname"
                     formControlName="legalEntity"></kendo-textbox>
      <br />
      <br />
      <label>Template Type:</label>
      <br />
      <kendo-textbox [readonly]="true"
                     [value]="selectedMeasure?.bt_templatetype"
                     formControlName="templateType"></kendo-textbox>
      <br />
      <br />
      <label>Measure:</label>
      <br />
      <kendo-textbox [readonly]="false"
                     [value]="selectedMeasure.bt_measure"
                     formControlName="measure"></kendo-textbox>
      <br />
      <br />
      <label>Sorting:</label>
      <br />
      <kendo-textbox [readonly]="false"
                     [value]=""
                     formControlName="sorting"></kendo-textbox>
      <br />
      <br />
      <label>Image Required:</label>
      <br />
      <kendo-dropdownlist class="entity-dropdown"
                          [data]="imageRequired"
                          textField="text"
                          valueField="value"
                          id="imageRequiredDropdown"
                          name="imageRequiredDropdown"
                          (valueChange)="imageRequiredDropdown($event)"
                          formControlName="imageRequired">
      </kendo-dropdownlist>
      <br />
      <br />
      <label>Allow Image:</label>
      <br />
      <kendo-dropdownlist class="entity-dropdown"
                          [data]="allowImage"
                          textField="text"
                          valueField="value"
                          id="allowImageDropdown"
                          name="allowImageDropdown"
                          (valueChange)="allowImageDropdown($event)"
                          formControlName="allowImage">
      </kendo-dropdownlist>
      <br />
      <br />
      <label>Response Type:</label>
      <br />
      <kendo-dropdownlist class="entity-dropdown"
                          [data]="responseType"
                          textField="text"
                          valueField="value"
                          id="responseTypeDropdown"
                          name="responseTypeDropdown"
                          (valueChange)="onResponseTypeChange($event)"
                          formControlName="responseType">
      </kendo-dropdownlist>
      <br />
      <br />
      <div *ngIf="selectedResponseType === 'Dropdown'">
        <input type="text"
               [(ngModel)]="newOption">
        <button kendoButton
                themeColor="primary"
                (click)="addOption()"
                style="margin-left: 10px">Add Option</button>
        <ul>
          <li *ngFor="let option of dropdownOptions">{{ option }}</li>
        </ul>
        <button kendoButton
                themeColor="primary"
                (click)="clearOptions()">Clear Options</button>
      </div>
    </div>
  </div>
  <div class="container">
    <button id="edit-button"
            style="float: right; margin-right: 30px"
            kendoButton
            themeColor="primary"
            [disabled]="!measureForm.valid">Edit</button>
  </div>
</form>

<verde-modal modalTitle="Confirmation"
             id="confirmMeasure"
             [hasCancelButton]="true"
             submitButton="Continue"
             (actionButton)="confirm()"
             modalMaxWidth="400">
  <div style="position: relative; height: 60px;">
    <p>Please confirm that you would like to edit measure</p>
  </div>
</verde-modal>