/* tslint:disable */
/* eslint-disable */
export interface DvPerformanceReviewSummaries {
  bt_average?: null | number;
  bt_name?: null | string;
  bt_noofcycles?: null | number;
  bt_noofcyclescomms?: null | number;
  bt_noofcycleskpi?: null | number;
  bt_performancereviewsummaryid?: null | string;
  odataCycle?: null | string;
  odataLegalEntity?: null | string;
  odataPeriod?: null | string;
}
