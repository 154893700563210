export enum TaskFleetItemTemplates {
  OutstandingFleetItemLicense = 'Outstanding Fleet Item License',
  FleetManagerApprove = 'Fleet Manager must Approve',
  FromFleetManagerApprove = 'From Fleet Manager must Approve',
  FleetManagerMustAssign = 'Fleet Manager must assign Fleet Item',
  FleetManagerCompleteChecklist = 'Fleet Manager to complete checklist',
  FromFleetManagerCompleteChecklist = 'From Fleet Manager to complete checklist',
  ToFleetManagerCompleteChecklist = 'To Fleet Manager to complete checklist',
  FromFleetManagerCompleteConfirmation = 'From Fleet Manager to complete Confirmation',
  ToFleetManagerCompleteConfirmation = 'To Fleet Manager to complete Confirmation',
  FromResponderCompleteChecklist = 'From Responder to complete checklist',
  FromResponderCompleteConfirmation = 'From Responder to complete Confirmation',
  ToResponderCompleteChecklist = 'To Responder to complete checklist',
  ToResponderCompleteConfirmation = 'To Responder to complete Confirmation',
  MonthlyChecklist = 'Monthly Checklist',
  // TODO: Confirm if these are needed
  ResponderCompleteMonthlyChecklist = 'Responder to Complete Monthly Checklist(s)',
  FleetManagerCompleteMonthlyChecklist = 'Fleet Manager to Complete Monthly Checklist(s)',
  MonthlyVehicleCheckList = 'Monthly Vehicle Check List',
}
