<div class="main">
  <div class="scrollable-content">
    <form class="k-form sidepanel-form"
          [formGroup]="fg"
          autocomplete="off">  
      <kendo-gridlayout gap="2% 5%"
                        [rows]="[]"
                        [cols]="[{ width: '45%' }, { width: '45%' }]">
        <!------------------------>
        <kendo-gridlayout-item *ngIf="currentStep === 1" [row]="1" [col]="1" [colSpan]="2">
          <kendo-formfield>
            <kendo-label [for]="exampleTextBox"
                         text="Textbox"></kendo-label>
            <kendo-textbox formControlName="exampleTextBox" #exampleTextBox>
            </kendo-textbox>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <!------------------------>
        <kendo-gridlayout-item *ngIf="currentStep === 1" [row]="2" [col]="1" [colSpan]="2">
          <kendo-formfield>
            <kendo-label [for]="exampleTextBox2"
                         text="Textbox"></kendo-label>
            <kendo-textbox formControlName="exampleTextBox2" #exampleTextBox2>
            </kendo-textbox>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <!------------------------>
        <kendo-gridlayout-item *ngIf="currentStep === 1" [row]="3" [col]="1">
          <kendo-formfield>
            <kendo-label [for]="exampleDropdown"
                         text="Dropdown"></kendo-label>
            <kendo-dropdownlist #exampleDropdown
                                formControlName="exampleDropdown"
                                [data]=""
                                textField=""
                                valueField=""
                                [valuePrimitive]="true"
                                required>
            </kendo-dropdownlist>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <!------------------------>
        <kendo-gridlayout-item *ngIf="currentStep === 1" [row]="3" [col]="2">
          <kendo-formfield>
            <kendo-label [for]="exampleDropdown2"
                         text="Dropdown"></kendo-label>
            <kendo-dropdownlist #exampleDropdown2
                                formControlName="exampleDropdown2"
                                [data]=""
                                textField=""
                                valueField=""
                                [valuePrimitive]="true"
                                required>
            </kendo-dropdownlist>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <!------------------------>
        <kendo-gridlayout-item *ngIf="currentStep === 1" [row]="4" [col]="1">
          <kendo-formfield>
            <kendo-label [for]="exampleDateInput"
                         text="Date Input"></kendo-label>
            <kendo-datepicker formControlName="exampleDateInput"
                              #exampleDateInput
                              format="yyyy/MM/dd"
                              required></kendo-datepicker>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <!------------------------>
        <kendo-gridlayout-item *ngIf="currentStep === 1" [row]="4" [col]="2">
          <kendo-formfield>
            <kendo-label [for]="exampleTextBox3"
                         text="Textbox"></kendo-label>
            <kendo-textbox formControlName="exampleTextBox3" #exampleTextBox3>
            </kendo-textbox>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <!------------------------>
        <kendo-gridlayout-item *ngIf="currentStep === 1" [row]="5" [col]="1" [colSpan]="2">
          <kendo-formfield>
            <kendo-label [for]="exampleDropdown3"
                         text="Dropdown"></kendo-label>
            <kendo-dropdownlist #exampleDropdown3
                                formControlName="exampleDropdown3"
                                [data]=""
                                textField=""
                                valueField=""
                                [valuePrimitive]="true"
                                required>
            </kendo-dropdownlist>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <!------------------------>
        <kendo-gridlayout-item *ngIf="currentStep === 1" [row]="6" [col]="1">
          <kendo-formfield orientation="horizontal" class="radio-buttons">
            <label class="radio-label">Radio Button</label>
            <ul class="k-radio-list k-list-horizontal">
              <li class="k-radio-item">
                <input type="radio" #yes value="yes" kendoRadioButton formControlName="radioButton" />
                <kendo-label class="k-radio-label" [for]="yes" text="Yes"></kendo-label>
              </li>

              <li class="k-radio-item">
                <input type="radio" #no value="no" kendoRadioButton formControlName="radioButton" />
                <kendo-label class="k-radio-label" [for]="no" text="No"></kendo-label>
              </li>
            </ul>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <!------------------------>
        <kendo-gridlayout-item *ngIf="currentStep === 1" [row]="7" [col]="1">
          <kendo-formfield orientation="horizontal" class="radio-buttons">
            <label class="radio-label">Radio Button</label>
            <ul class="k-radio-list k-list-horizontal">
              <li class="k-radio-item">
                <input type="radio" #yes value="yes" kendoRadioButton formControlName="radioButton2" />
                <kendo-label class="k-radio-label" [for]="yes" text="Yes"></kendo-label>
              </li>

              <li class="k-radio-item">
                <input type="radio" #no value="no" kendoRadioButton formControlName="radioButton2" />
                <kendo-label class="k-radio-label" [for]="no" text="No"></kendo-label>
              </li>
            </ul>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <!------------------------>
        <kendo-gridlayout-item *ngIf="currentStep === 1" [row]="8" [col]="1" [colSpan]="2" [rowSpan]="2">
          <kendo-formfield>
            <kendo-label [for]="exampleTextArea"
                         text="Text Area"></kendo-label>
            <kendo-textarea formControlName="exampleTextArea"
                            #exampleTextArea
                            required>
            </kendo-textarea>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <!------------------------>
        <kendo-gridlayout-item *ngIf="currentStep === 1" [row]="10" [col]="1" [colSpan]="2">
          <kendo-formfield>
            <kendo-label [for]="fileUpload" text="File Upload"></kendo-label>
            <kendo-fileselect #fileUpload
                              formControlName="fileUpload"
                              [restrictions]="restrictions">
            </kendo-fileselect>
            <kendo-formhint>Allowed extensions are jpg, jpeg, png or pdf</kendo-formhint>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <!------------------------>
        <kendo-gridlayout-item *ngIf="currentStep === 2" [row]="11" [col]="1" [colSpan]="2">
          <kendo-formfield>
            <kendo-label [for]="exampleTextBox4"
                         text="Textbox"></kendo-label>
            <kendo-textbox formControlName="exampleTextBox4" #exampleTextBox4>
            </kendo-textbox>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <!------------------------>
        <kendo-gridlayout-item *ngIf="currentStep === 3" [row]="11" [col]="1" [colSpan]="2">
          <kendo-formfield>
            <kendo-label [for]="exampleTextBox5"
                         text="Textbox"></kendo-label>
            <kendo-textbox formControlName="exampleTextBox5" #exampleTextBox5>
            </kendo-textbox>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <!------------------------>
      </kendo-gridlayout>
    </form>
  </div>
  <div #appendTo
       class="fixed-buttons">
    <span class="k-form-separator"></span>
    <br />
    <div class="k-form-buttons k-buttons-end">
      <span *ngIf="totalSteps > 1" class="page-step">Step {{ currentStep }} of {{ totalSteps }}</span>
      <div>
        <button kendoButton
                themeColor="primary"
                *ngIf="currentStep !== 1"
                (click)="prev()">
          Previous
        </button>
        <button kendoButton
                themeColor="primary"
                (click)="next()"
                *ngIf="currentStep < totalSteps">
          Next
        </button>
        <button kendoButton
                themeColor="primary"
                (click)="submit()"
                *ngIf="currentStep === totalSteps">
          Submit
        </button>
      </div>
    </div>
  </div>
</div>



