<div class="review-rating-option">
  <h6>Please select a rating:</h6>
  <div class="rating-response-container">
    <div class="rating-widget">
      <div *ngFor="let option of allOptions"
           class="rating-option">
        <div class="circle-select"
             (click)="setSelected(option)">
          <div *ngIf="option.selected"
               class="selected-dot"
               [@selected]
               [@deselected]></div>
        </div>
        <h6>{{ option.text }}</h6>
      </div>
    </div>
  </div>
</div>
