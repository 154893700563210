/* tslint:disable */
/* eslint-disable */
import { ProcessesBthrTerminationreason } from './processes-bthr-terminationreason';
export interface ProcessesExitReason {
  bt_employeenoticetype?: ProcessesBthrTerminationreason;
  bt_verdeemployeeexitreasons?: null | string;
  bt_verdeemployeeexitreasonsid?: null | string;
  employeeNotice?: null | boolean;
  statuscode?: null | boolean;
}
