import { EnhancedReviewDetail } from '@verde/shared';

export interface ReviewResult {
  _bt_performancereviewdetailsummary_value?: string;
  bt_groupsorting?: number;
  bt_subgroupsorting?: number;
  bt_measuresorting?: number;
  bt_basepoint?: number;
  odataGroup?: string;
  _bt_group_value?: string;
  odataSubGroup?: string;
  _bt_subgroup_value?: string;
  odataMeasure?: string;
  employee?: EnhancedReviewDetail;
  manager?: EnhancedReviewDetail;
  calculation?: EnhancedReviewDetail;
}
