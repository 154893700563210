/* tslint:disable */
/* eslint-disable */
import { User } from './user';
export interface DvTimeOffRequest {
  '_bt_approver_value'?: null | string;
  '_bt_approverlegalstatus_value'?: null | string;
  '_bt_linkedtransaction_value'?: null | string;
  '_bt_manager_value'?: null | string;
  '_bt_managerlegalentity_value'?: null | string;
  '_bt_timeofftype_lookup_value'?: null | string;
  '_bthr_timeoffcheck_value'?: null | string;
  '_bthr_txntype_value'?: null | string;
  bt_calculationcomment?: null | string;
  bt_cancelled?: null | boolean;
  bt_hrapprovaldate?: null | string;
  bt_linkedtransaction?: null | string;
  bt_managerapprovaldate?: null | string;
  bt_managerapprovalstatus?: null | number;
  bt_originalrequestapproved?: null | boolean;
  bt_showsupportingnote?: null | boolean;
  bt_supportingnotecomment?: null | string;
  bthr_Employee?: User;
  bthr_attachmentname?: null | string;
  bthr_calculationstatus?: null | number;
  bthr_comments?: null | string;
  bthr_enddate?: null | string;
  bthr_leavedays?: null | number;
  bthr_nonworkdays?: null | number;
  bthr_partdayrequestchoice?: null | number;
  bthr_proxy?: null | boolean;
  bthr_recallorrejected?: null | boolean;
  bthr_remainingbeforeaftertimeoff?: null | number;
  bthr_remainingbeforetimeoff?: null | number;
  bthr_requestdate?: null | string;
  bthr_requestno?: null | string;
  bthr_requestsstatus?: null | number;
  bthr_requeststatuscomment?: null | string;
  bthr_startdate?: null | string;
  bthr_supportingnote_name?: null | string;
  bthr_supportingnoterequired?: null | boolean;
  bthr_timeoffrequestid?: null | string;
  bthr_unitstest?: null | number;
  modifiedon?: null | string;
  odataApprover?: null | string;
  odataApproverLegalEntity?: null | string;
  odataCalculationStatus?: null | string;
  odataLegalEntity?: null | string;
  odataLinkedTransaction?: null | string;
  odataManager?: null | string;
  odataManagerApprovalStatus?: null | string;
  odataManagerLegalEntity?: null | string;
  odataPartDayRequest?: null | string;
  odataPostApprover?: null | string;
  odataPostApproverLegalEntity?: null | string;
  odataPostEmployee?: null | string;
  odataPostLegalEntity?: null | string;
  odataPostLinkedTransaction?: null | string;
  odataPostManager?: null | string;
  odataPostManagerLegalEntity?: null | string;
  odataPostProxy?: null | string;
  odataPostTimeOffCheck?: null | string;
  odataPostTimeOffType?: null | string;
  odataPostTxnType?: null | string;
  odataRequestStatus?: null | string;
  odataTimeOffCheck?: null | string;
  odataTimeOffType?: null | string;
  odataTxnType?: null | string;
  statecode?: null | number;
}
