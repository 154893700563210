import { AfterViewInit, Component, EventEmitter, Output } from '@angular/core';
import { ModalService } from '../modal.service';

@Component({
  selector: 'verde-na-mobile-modal',
  templateUrl: './na-mobile-modal.component.html',
  styleUrls: ['./na-mobile-modal.component.scss'],
})
export class NaMobileModalComponent implements AfterViewInit {
  @Output() modalClosed: EventEmitter<boolean> = new EventEmitter();

  constructor(private modalService: ModalService) {}

  ngAfterViewInit(): void {
    this.modalService.open('naMobileModal');
  }

  close() {
    this.modalClosed.emit(false);
  }
}
