/* tslint:disable */
/* eslint-disable */
export interface BFFStorageObjectDto {
  docType: string;
  files: Array<File>;
  grouping: string;
  importControl: string;
  integrationSource: string;
  tenantId: string;
}
