/* tslint:disable */
/* eslint-disable */
import { EquipmentEquipmentAsset } from './equipment-equipment-asset';
import { EquipmentEquipmentAssetClass } from './equipment-equipment-asset-class';
import { EquipmentEquipmentDepartment } from './equipment-equipment-department';
import { EquipmentEquipmentDivision } from './equipment-equipment-division';
import { EquipmentEquipmentEmployee } from './equipment-equipment-employee';
import { EquipmentEquipmentLegalEntity } from './equipment-equipment-legal-entity';
import { EquipmentEquipmentLocation } from './equipment-equipment-location';
import { EquipmentEquipmentMake } from './equipment-equipment-make';
import { EquipmentEquipmentModel } from './equipment-equipment-model';
import { EquipmentEquipmentRental } from './equipment-equipment-rental';
import { EquipmentEquipmentRequestItem } from './equipment-equipment-request-item';
import { EquipmentEquipmentSpec } from './equipment-equipment-spec';
import { EquipmentEquipmentSpecType } from './equipment-equipment-spec-type';
import { EquipmentEquipmentSupplier } from './equipment-equipment-supplier';
import { EquipmentEquipmentType } from './equipment-equipment-type';
import { EquipmentEquipmentTypeClass } from './equipment-equipment-type-class';
import { EquipmentMoney } from './equipment-money';
import { EquipmentBtEquipmentAging } from './equipment-bt-equipment-aging';
import { EquipmentBtEquipmentMovementStatus } from './equipment-bt-equipment-movement-status';
import { EquipmentBtEquipmentStatus } from './equipment-bt-equipment-status';
import { EquipmentBtFleetMovementStatus } from './equipment-bt-fleet-movement-status';
import { EquipmentBtItemType } from './equipment-bt-item-type';
import { EquipmentBtServiceUnit } from './equipment-bt-service-unit';
import { EquipmentBthrClassification } from './equipment-bthr-classification';
import { EquipmentBthrDisposalType } from './equipment-bthr-disposal-type';
import { EquipmentBthrFuelType } from './equipment-bthr-fuel-type';
import { EquipmentBthrRecordStatus } from './equipment-bthr-record-status';
export interface EquipmentEquipmentItem {
  bt_Asset?: EquipmentEquipmentAsset;
  bt_AssetClass?: EquipmentEquipmentAssetClass;
  bt_CreatedBy?: EquipmentEquipmentEmployee;
  bt_Department?: EquipmentEquipmentDepartment;
  bt_Division?: EquipmentEquipmentDivision;
  bt_Employee?: EquipmentEquipmentEmployee;
  bt_Employees?: EquipmentEquipmentEmployee;
  bt_EquipmentMake?: EquipmentEquipmentMake;
  bt_EquipmentModel?: EquipmentEquipmentModel;
  bt_EquipmentRental?: EquipmentEquipmentRental;
  bt_EquipmentSpec?: EquipmentEquipmentSpec;
  bt_EquipmentSpecType?: EquipmentEquipmentSpecType;
  bt_EquipmentType?: EquipmentEquipmentType;
  bt_EquipmentTypeClass?: EquipmentEquipmentTypeClass;
  bt_InspectionPerformedBy?: EquipmentEquipmentEmployee;
  bt_LegalEntity?: EquipmentEquipmentLegalEntity;
  bt_Location?: EquipmentEquipmentLocation;
  bt_NewEmployee?: EquipmentEquipmentEmployee;
  bt_NewLocation?: EquipmentEquipmentLocation;
  bt_RentalSupplier?: EquipmentEquipmentSupplier;
  bt_RequestEquipmentItem?: EquipmentEquipmentRequestItem;
  bt_Supplier?: EquipmentEquipmentSupplier;
  bt_alternateserviceunit?: EquipmentBtServiceUnit;
  bt_alternatserviceinterval?: null | number;
  bt_assetloaded?: null | boolean;
  bt_assetnumber?: null | string;
  bt_classification?: EquipmentBthrClassification;
  bt_companyasset?: null | boolean;
  bt_depreciatedamount?: EquipmentMoney;
  bt_depreciateddate?: null | string;
  bt_depreciationstartdate?: null | string;
  bt_disposaldate?: null | string;
  bt_disposaltype?: EquipmentBthrDisposalType;
  bt_disposed?: null | boolean;
  bt_equipmentaging?: EquipmentBtEquipmentAging;
  bt_equipmentitemid?: null | string;
  bt_equipmentitemno?: null | string;
  bt_equipmentmovementstatus?: EquipmentBtEquipmentMovementStatus;
  bt_equipmentno?: null | string;
  bt_equipmentunique?: null | string;
  bt_expirydate?: null | string;
  bt_fleetmovementstatus?: EquipmentBtFleetMovementStatus;
  bt_fueltype?: EquipmentBthrFuelType;
  bt_inspectionstatus?: null | string;
  bt_itemtype?: EquipmentBtItemType;
  bt_lastinspectiondate?: null | string;
  bt_lastservicedate?: null | string;
  bt_lastserviceodometerreading?: null | number;
  bt_maintenanceplan?: null | boolean;
  bt_modelyear?: null | string;
  bt_odometerreading?: null | number;
  bt_purchasedate?: null | string;
  bt_purchaseprice?: EquipmentMoney;
  bt_recordstatus?: EquipmentBthrRecordStatus;
  bt_refno?: null | string;
  bt_regno?: null | string;
  bt_rentalcost?: EquipmentMoney;
  bt_rentalend?: null | string;
  bt_rentalquoteamount?: EquipmentMoney;
  bt_rentalstart?: null | string;
  bt_serviceinterval?: null | number;
  bt_serviceplan?: null | boolean;
  bt_serviceunit?: EquipmentBtServiceUnit;
  bt_statuscomment?: null | string;
  bt_tankcapacityl?: null | number;
  bt_tare?: null | number;
  bt_todaydate?: null | string;
  bt_vehiclestatus?: EquipmentBtEquipmentStatus;
  bt_warrantyenddate?: null | string;
  bt_warrantyperiodmonths?: null | number;
  bt_warrantystartdate?: null | string;
  contents?: null | Array<EquipmentEquipmentItem>;
  name?: null | string;
  parentID?: null | string;
  quantity?: null | number;
}
