/* tslint:disable */
/* eslint-disable */
export interface BtPolicyAcceptance {
  bt_effectivedate?: null | string;
  bt_policyname?: null | string;
  bt_policyoverview?: null | string;
  bt_policystatus?: number;
  bt_verdepolicyid?: null | string;
  bt_versionnumber?: null | string;
}
