<div *ngIf="currentTask && currentStep === 1"
     class="task-view-container">
  <div class="main-content-section">
    <div class="detail-section section-container">
      <div class="content">
        <h6>Number: <span>{{ currentTask?.bt_tasklistno }}</span></h6>
        <h6>Header: <span>{{ currentTask?.bt_taskheader }}</span></h6>
        <h6 *ngIf="currentTask?.odataTaskGroup">Group: <span>{{ currentTask?.odataTaskGroup }}</span></h6>
        <h6>Assigned to: <span>{{ assignedTo }}</span></h6>
        <h6>Source: <span>{{ currentTask?.odataTaskSource }}</span></h6>
        <h6>Priority: <span>{{ taskPriority }}</span></h6>
        <h6>Due Date: <span>{{ currentTask.bt_duedatetime | date: 'yyyy/MM/dd hh:mm' || 'N/A' }}</span></h6>
        <h6>Start Date: <span>{{ currentTask.bt_startdate | date: 'yyyy/MM/dd hh:mm' || 'N/A' }}</span></h6>
        <h6 *ngIf="currentTask.bt_completeddatetime">Complete date: <span>{{ currentTask.bt_completeddatetime | date:
            'yyyy/MM/dd hh:mm' || 'N/A' }}</span></h6>
      </div>
      <div class="description-section content">
        <h6>Description:</h6>
        <p>{{ currentTask.bt_taskdescription }}</p>
      </div>
    </div>
  </div>
  <div *ngIf="currentTask.bt_taskstatus >= TaskStatus.InProgress && !currentTask.bt_approval"
       class="notes-content-section">
    <div class="notes-section">
      <div class="notes-header">
        <h3>Notes</h3>
        <button *ngIf="!followUpTasks && currentTask.bt_taskstatus < TaskStatus.Completed"
                kendoButton
                themeColor="primary"
                class="btn-sm"
                (click)="addNote()">
          Add new note
        </button>
      </div>
      <div class="all-notes-section">
        <div *ngIf="savingNote"
             class="fake-note">
          <ngx-spinner [name]="spinnerId"
                       bdColor="rgb(237, 237, 237)"
                       size="default"
                       color="#6264a7"
                       type="ball-clip-rotate"
                       [fullScreen]="false">
          </ngx-spinner>
        </div>
        <verde-task-notes [notes]="currentNotes"></verde-task-notes>
      </div>
    </div>
  </div>
</div>

<div *ngIf="currentTask && currentStep === 2"
     class="task-view-container">
  <verde-timeoff-approval-modal *ngIf="showTimeOffApprovalModal"
                                [requestID]="currentTask.bt_referenceguid"
                                [approverType]="currentTask.bt_tasksource === TaskSource.TimeOffApproval ? 'Manager' : currentTask.bt_tasksource === TaskSource.HrTimeOffApproval ? 'HR' : 'Manager'"
                                [employeeFullName]="currentTask.bt_EmployeeRelatedTo?.bt_fullname"
                                [showModal]="false"
                                (hideModalEvent)="hideApprovalModal()"
                                (onSuccess)="setTaskToComplete($event)">
  </verde-timeoff-approval-modal>
</div>

<div *ngIf="currentTask && currentStep === 2"
     class="task-view-container">
  <verde-fleet-modals [showApprovalModal]="showFleetApprovalModal"
                      [showAssignModal]="showAssignModal"
                      [showConfirmationModal]="showConfirmationModal"
                      [showLicenseModal]="showLicenseModal"
                      [confirmationType]="confirmationType"
                      [referenceGuid]="referenceGuid"
                      (approvalModalClosed)="approvalModalClosed($event)"
                      (assignModalClosed)="assignModalClosed($event)"
                      (confirmationModalClosed)="confirmationModalClosed($event)"
                      (licenseModalClosed)="licenseModalClosed($event)"
                      [showModal]="false">
  </verde-fleet-modals>
</div>

<div *ngIf="!currentTask"
     class="no-task-container">
  <p>Loading task</p>
</div>

<div #appendTo
     class="fixed-buttons"
     *ngIf="currentTask && currentStep === 1">
  <span class="k-form-separator"></span>
  <br />
  <div class="k-form-buttons k-buttons-end">
    <div class="action-section section-container"
         *ngIf="!closedTasks">
      <div class="content">
        <div class="button-container">
          <button *ngIf="currentTask.bt_approval && currentTask.bt_tasksource !== TaskSource.FleetItem; else notApprovalAndFleetSection"
                  kendoButton
                  themeColor="primary"
                  class="btn-sm"
                  (click)="approveTaskClicked(currentTask.functiontechnicalname)">
            Approve
          </button>

          <ng-template #notApprovalAndFleetSection>
            <ng-container *ngIf="!followUpTasks">
              <button *ngIf="currentTask.bt_taskstatus <= TaskStatus.Assigned"
                      kendoButton
                      themeColor="primary"
                      class="btn-sm"
                      (click)="startTask()">
                Start task
              </button>
              <button *ngIf="currentTask.bt_taskstatus === TaskStatus.InProgress"
                      kendoButton
                      themeColor="primary"
                      class="btn-sm"
                      (click)="completeTaskClicked()">
                {{ completeButton }}
              </button>
            </ng-container>

            <ng-container *ngIf="followUpTasks">
              <button *ngIf="currentTask.bt_taskstatus === TaskStatus.Completed"
                      kendoButton
                      themeColor="primary"
                      class="btn-sm"
                      (click)="acceptTaskModal()">
                Accept
              </button>
              <button *ngIf="currentTask.bt_taskstatus === TaskStatus.Completed"
                      kendoButton
                      themeColor="primary"
                      class="btn-sm"
                      (click)="rejectTaskModal()">
                Reject
              </button>
            </ng-container>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>

<verde-modal [id]="'add-task-note-' + currentTask?.bt_verdetasklistid"
             modalTitle="Add note"
             submitButton="Save note"
             [submitActive]="!submitDisabled"
             [zIndex]="3000"
             (actionButton)="saveNote()"
             (modalStateOpen)="resetForm($event)">
  <div class="add-note-section">
    <kendo-label text="Title">
      <input type="text"
             [(ngModel)]="noteTitle" />
    </kendo-label>

    <kendo-label text="Note">
      <textarea [(ngModel)]="noteText"
                rows="5"></textarea>
    </kendo-label>

    <kendo-label text="Attachment">
      <input type="file"
             [accept]="'image/png, image/jpeg, image/jpg, application/pdf'"
             (change)="onFileChanged($event)" />
    </kendo-label>
  </div>
</verde-modal>

<verde-modal [id]="'confirm-complete-task-' + currentTask?.bt_verdetasklistid"
             modalTitle="Complete task"
             submitButton="Complete"
             (actionButton)="completeTask()">
  <div class="complete-task-modal-section">
    <p>Are you sure you want to mark this task as completed?</p>
  </div>
</verde-modal>

<verde-modal [id]="'confirm-complete-task-' + currentTask?.bt_verdetasklistid"
             modalTitle="Complete task"
             submitButton="Complete"
             (actionButton)="completeTask()">
  <div class="complete-task-modal-section">
    <p>Are you sure you want to mark this task as completed?</p>
  </div>
</verde-modal>

<verde-modal [id]="'accept-task-' + currentTask?.bt_verdetasklistid"
             modalTitle="Accept task"
             submitButton="Submit"
             (actionButton)="acceptTask()"
             (modalStateOpen)="handleState($event)">
  <div class="accept-task-modal-section reject-accept-section">
    <h6>Extra notes:</h6>
    <div class="note-section">
      <textarea rows="5"
                [(ngModel)]="followUpComment"></textarea>
    </div>
  </div>
</verde-modal>

<verde-modal [id]="'reject-task-' + currentTask?.bt_verdetasklistid"
             modalTitle="Reject task"
             submitButton="Submit"
             (actionButton)="rejectTask()"
             (modalStateOpen)="handleState($event)">
  <div class="reject-task-modal-section reject-accept-section">
    <h6>Reasons for reject:</h6>
    <div class="note-section">
      <textarea rows="5"
                [(ngModel)]="followUpComment"></textarea>
    </div>
  </div>
</verde-modal>

<verde-approval-modal *ngIf="showVerdeApprovalModal"
                      [isTaskApproval]="true"
                      [taskID]="currentTask?.bt_verdetasklistid"
                      [logGroupID]="currentTask?.bt_referenceguid"
                      [approvalDefinitionHeader]="currentTask?.bt_taskheader"
                      (hideModalEvent)="hideVerdeApprovalModal()"></verde-approval-modal>
