/* tslint:disable */
/* eslint-disable */
import { UserEntity } from './user-entity';
export interface UserEntityCollection {
  entities?: null | Array<UserEntity>;
  entityName?: null | string;
  minActiveRowVersion?: null | string;
  moreRecords?: boolean;
  pagingCookie?: null | string;
  totalRecordCount?: number;
  totalRecordCountLimitExceeded?: boolean;
}
