/* tslint:disable */
/* eslint-disable */
import { QualificationsGuidExtrapolate } from './qualifications-guid-extrapolate';
import { QualificationsBtValidationType } from './qualifications-bt-validation-type';
import { QualificationsBthrQualificationType } from './qualifications-bthr-qualification-type';
export interface QualificationsBthrQualification {
  bt_expire?: null | boolean;
  bt_expireafterdays?: null | number;
  bt_level?: null | number;
  bt_points?: null | number;
  bt_validated?: null | boolean;
  bt_validatedby?: null | string;
  bt_validatedbyentity?: null | string;
  bt_validationtype?: QualificationsBtValidationType;
  bthr_Qualificationid?: null | string;
  bthr_name?: null | string;
  bthr_qualificationtype?: QualificationsBthrQualificationType;
  id?: null | string;
  validatedBy?: QualificationsGuidExtrapolate;
  validatedByEntity?: QualificationsGuidExtrapolate;
}
