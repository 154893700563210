/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { TimeTrackingBaseService } from '../time-tracking-base-service';
import { TimeTrackingApiConfiguration } from '../time-tracking-api-configuration';
import { TimeTrackingStrictHttpResponse } from '../time-tracking-strict-http-response';
import { TimeTrackingRequestBuilder } from '../time-tracking-request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { TimeTrackingGetTimeTrackingQuery } from '../models/time-tracking-get-time-tracking-query';
import { TimeTrackingPostTimeTrackingCommand } from '../models/time-tracking-post-time-tracking-command';
import { TimeTrackingTimeTrackingExample } from '../models/time-tracking-time-tracking-example';

@Injectable({
  providedIn: 'root',
})
export class TimeTrackingApiTimeTrackingService extends TimeTrackingBaseService {
  constructor(
    config: TimeTrackingApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getTimeTracking
   */
  static readonly GetTimeTrackingPath = '/api/TimeTracking/get/timeTracking';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTimeTracking()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getTimeTracking$Response(params?: {
    tenantId?: string;
    body?: TimeTrackingGetTimeTrackingQuery
  }): Observable<TimeTrackingStrictHttpResponse<Array<TimeTrackingTimeTrackingExample>>> {

    const rb = new TimeTrackingRequestBuilder(this.rootUrl, TimeTrackingApiTimeTrackingService.GetTimeTrackingPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as TimeTrackingStrictHttpResponse<Array<TimeTrackingTimeTrackingExample>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTimeTracking$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getTimeTracking(params?: {
    tenantId?: string;
    body?: TimeTrackingGetTimeTrackingQuery
  }): Observable<Array<TimeTrackingTimeTrackingExample>> {

    return this.getTimeTracking$Response(params).pipe(
      map((r: TimeTrackingStrictHttpResponse<Array<TimeTrackingTimeTrackingExample>>) => r.body as Array<TimeTrackingTimeTrackingExample>)
    );
  }

  /**
   * Path part for operation postTimeTracking
   */
  static readonly PostTimeTrackingPath = '/api/TimeTracking/post/timeTracking';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postTimeTracking()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postTimeTracking$Response(params?: {
    tenantId?: string;
    body?: TimeTrackingPostTimeTrackingCommand
  }): Observable<TimeTrackingStrictHttpResponse<boolean>> {

    const rb = new TimeTrackingRequestBuilder(this.rootUrl, TimeTrackingApiTimeTrackingService.PostTimeTrackingPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as TimeTrackingStrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postTimeTracking$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postTimeTracking(params?: {
    tenantId?: string;
    body?: TimeTrackingPostTimeTrackingCommand
  }): Observable<boolean> {

    return this.postTimeTracking$Response(params).pipe(
      map((r: TimeTrackingStrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

}
