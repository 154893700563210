import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HttpCancelService } from '../services/cancel.service';

@Injectable()
export class HttpCancelInterceptor implements HttpInterceptor {
  constructor(private httpCancelService: HttpCancelService) {}

  intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    // Only allow cancellations on GET methods
    if (req.method !== 'GET' || req.headers.get('skipcancel')) {
      return next.handle(req);
    }

    if (
      req.url.includes('/api/User/employeelegalentity/') ||
      req.url.includes('/api/Config/appconfigs/') ||
      req.url.includes('/api/User/getuser/') ||
      req.url.includes('/api/User/managerteam/') ||
      req.url.includes('/api/FleetMovement/fleetaccess/') ||
      req.url.includes('/api/FleetMovement/fleetmovements/') ||
      req.url.includes('/api/VerdeManagement/verdesuppliers/') ||
      req.url.includes('/api/Counters/yourfleetitemsemployee/') ||
      req.url.includes('/api/Counters/employeebirthdayscomingup/') ||
      req.url.includes('/api/File/legalentity/get/') ||
      req.url.includes('/api/VerdeManagement/taskbyassignedlink/') ||
      req.url.includes('/api/Config/GetTimeTrackingForWidget/') ||
      req.url.includes('/api/TimeOff/dashboardtimeoffsummary/') ||
      req.url.includes('/api/Counters/countvotingopen/') ||
      req.url.includes('/api/Config/workspaceshortcuts') ||
      req.url.includes('/api/User/employeemanagerfiltered/') ||
      req.url.includes('/api/RiskManagement/vaccinationacceptancesummary/') ||
      req.url.includes('/api/Engage/user/status/') ||
      req.url.includes('/api/Bookings/bookingsdashboardsummary/') 
    ) {
      return next.handle(req);
    }

    return next.handle(req).pipe(takeUntil(this.httpCancelService.onCancelPendingRequests()));
  }
}
