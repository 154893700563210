import { StructureChangeRequestExtended } from '../models/structure-change-request-extended.model';
import { Action } from '@ngrx/store';

export enum StructureChangeRequestActionTypes {
  // Get All Structure
  STRUCTURE_CHANGE_REQUEST_GET_ALL = '[Structure Change Request] Get All',
  STRUCTURE_CHANGE_REQUEST_GET_ALL_SUCCESS = '[Structure Change Request] Get All Start Success',
  STRUCTURE_CHANGE_REQUEST_GET_ALL_ERROR = '[Structure Change Request] Get All Start Error',
  // Set All Structure
  STRUCTURE_CHANGE_REQUEST_ADD_ALL = '[Structure Change Request] Add All',
  STRUCTURE_CHANGE_REQUEST_ADD_ALL_SUCCESS = '[Structure Change Request] Add All Success',
  // Structure Add
  STRUCTURE_CHANGE_REQUEST_UPLOAD = '[Structure Change Request] Upload',
  STRUCTURE_CHANGE_REQUEST_UPLOAD_SUCCESS = '[Structure Change Request] Upload Success',
  STRUCTURE_CHANGE_REQUEST_UPLOAD_ERROR = '[Structure Change Request] Upload Error',
}

// Get All Structure Change Requests
export class StructureChangeRequestGetAll implements Action {
  readonly type = StructureChangeRequestActionTypes.STRUCTURE_CHANGE_REQUEST_GET_ALL;
  constructor(public payload: any) {}
}

export class StructureChangeRequestGetAllSuccess implements Action {
  readonly type = StructureChangeRequestActionTypes.STRUCTURE_CHANGE_REQUEST_GET_ALL_SUCCESS;
  constructor(public payload: StructureChangeRequestExtended[]) {}
}

export class StructureChangeRequestGetAllError implements Action {
  readonly type = StructureChangeRequestActionTypes.STRUCTURE_CHANGE_REQUEST_GET_ALL_ERROR;
  constructor(public payload: any) {}
}

// Structure Set All
export class StructureChangeRequestAddAll implements Action {
  readonly type = StructureChangeRequestActionTypes.STRUCTURE_CHANGE_REQUEST_ADD_ALL;
  constructor(public payload: StructureChangeRequestExtended[]) {}
}

export class StructureChangeRequestAddAllSuccess implements Action {
  readonly type = StructureChangeRequestActionTypes.STRUCTURE_CHANGE_REQUEST_ADD_ALL_SUCCESS;
  constructor(public payload: StructureChangeRequestExtended[]) {}
}

// Structure Change Request Add
export class StructureChangeRequestUpload implements Action {
  readonly type = StructureChangeRequestActionTypes.STRUCTURE_CHANGE_REQUEST_UPLOAD;
  constructor(public payload: StructureChangeRequestExtended) {}
}

export class StructureChangeRequestUploadSuccess implements Action {
  readonly type = StructureChangeRequestActionTypes.STRUCTURE_CHANGE_REQUEST_UPLOAD_SUCCESS;
  constructor(public payload: StructureChangeRequestExtended) {}
}

export class StructureChangeRequestUploadError implements Action {
  readonly type = StructureChangeRequestActionTypes.STRUCTURE_CHANGE_REQUEST_UPLOAD_ERROR;
  constructor(public payload: any) {}
}

export type StructureChangeRequestActions =
  // Get All Structure Change Requests
  | StructureChangeRequestGetAll
  | StructureChangeRequestGetAllSuccess
  | StructureChangeRequestGetAllError
  // Set All Structure Change Requests
  | StructureChangeRequestAddAll
  | StructureChangeRequestAddAllSuccess
  // Structure Change Request Upload
  | StructureChangeRequestUpload
  | StructureChangeRequestUploadSuccess
  | StructureChangeRequestUploadError;
