/* tslint:disable */
/* eslint-disable */
export interface DvTaxRecords {
  bt_taxregistration_name?: null | string;
  bthr_certificate_name?: null | string;
  bthr_employeetaxid?: null | string;
  bthr_name?: null | string;
  bthr_taxdependents?: null | number;
  bthr_taxnumber?: null | string;
  odataCountry?: null | string;
  odataRecordStatus?: null | string;
  odataTaxNature?: null | string;
}
