/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DvEmployeeDependents } from '../models/dv-employee-dependents';
import { DvEmployeeDependentsPost } from '../models/dv-employee-dependents-post';

@Injectable({
  providedIn: 'root',
})
export class SharedApiEmployeeDependentsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAllEmployeeDependents
   */
  static readonly GetAllEmployeeDependentsPath = '/api/EmployeeDependents/{employeeGUID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllEmployeeDependents()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllEmployeeDependents$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    employeeGUID: string;
  }): Observable<StrictHttpResponse<Array<DvEmployeeDependents>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiEmployeeDependentsService.GetAllEmployeeDependentsPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('employeeGUID', params.employeeGUID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DvEmployeeDependents>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllEmployeeDependents$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllEmployeeDependents(params: {
    subscriptionKey?: string;
    APIURL?: string;
    employeeGUID: string;
  }): Observable<Array<DvEmployeeDependents>> {

    return this.getAllEmployeeDependents$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DvEmployeeDependents>>) => r.body as Array<DvEmployeeDependents>)
    );
  }

  /**
   * Path part for operation patchDependent
   */
  static readonly PatchDependentPath = '/api/EmployeeDependents/patch/{userguid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchDependent()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchDependent$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userguid: string;
    body?: DvEmployeeDependentsPost
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiEmployeeDependentsService.PatchDependentPath, 'patch');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('userguid', params.userguid, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchDependent$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchDependent(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userguid: string;
    body?: DvEmployeeDependentsPost
  }): Observable<boolean> {

    return this.patchDependent$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation postDependent
   */
  static readonly PostDependentPath = '/api/EmployeeDependents/post/{userguid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postDependent()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postDependent$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userguid: string;
    body?: DvEmployeeDependentsPost
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiEmployeeDependentsService.PostDependentPath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('userguid', params.userguid, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postDependent$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postDependent(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userguid: string;
    body?: DvEmployeeDependentsPost
  }): Observable<boolean> {

    return this.postDependent$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

}
