/* eslint-disable no-console */
import { Injectable, OnDestroy } from '@angular/core';
import { UserService } from '@verde/core';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';

// Store
import * as StructureActions from '../store/actions/structure.actions';
import * as fromStructureReducer from '../store/reducers/structure.reducer';

// Models
import { StructureBtStructureType, UserAppConfigDto } from '@verde/shared';
import { StructureFullExtended } from '../store/models/structure-full-extended.model';

@Injectable({
  providedIn: 'root',
})
export class StructureService implements OnDestroy {
  config: UserAppConfigDto = {};
  loading$: Observable<boolean>;
  private onDestroy$ = new Subject<boolean>();
  fullStructures: StructureFullExtended[];
  parentLegalEntity$: BehaviorSubject<StructureFullExtended> = new BehaviorSubject<StructureFullExtended>(null);
  parentDivision$: BehaviorSubject<StructureFullExtended> = new BehaviorSubject<StructureFullExtended>(null);
  parentDepartment$: BehaviorSubject<StructureFullExtended> = new BehaviorSubject<StructureFullExtended>(null);
  fullStructure$: Observable<StructureFullExtended[]>;
  fullStructureNew$: Observable<StructureFullExtended[]>;
  fullStructureDispose$: Observable<StructureFullExtended[]>;

  constructor(private store: Store<fromStructureReducer.StructureState>, private userService: UserService) {
    this.loading$ = this.store.pipe(select(fromStructureReducer.isStructureLoading));
    this.fullStructure$ = this.store.pipe(select(fromStructureReducer.getFullStructure));
    this.fullStructureNew$ = this.store.pipe(select(fromStructureReducer.getFullStructureNew));
    this.fullStructureDispose$ = this.store.pipe(select(fromStructureReducer.getFullStructureDispose));

    this.fullStructure$.pipe(takeUntil(this.onDestroy$)).subscribe((fullStructure: StructureFullExtended[]) => {
      this.fullStructures = fullStructure;
    });

    this.store
      .pipe(select(fromStructureReducer.getSelectedStructures))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((selectedStructures: StructureFullExtended[]) => {
        if (selectedStructures.length > 0) {
          this.parentLegalEntity$.next(this.getParentStructureType(selectedStructures[0], StructureBtStructureType.LegalEntity));
          this.parentDivision$.next(this.getParentStructureType(selectedStructures[0], StructureBtStructureType.Division));
          this.parentDepartment$.next(this.getParentStructureType(selectedStructures[0], StructureBtStructureType.Department));
        }
      });

    this.userService.config$.pipe(takeUntil(this.onDestroy$)).subscribe((config) => {
      this.config = config;
    });
  }

  // Logic

  getParentStructureType(current: StructureFullExtended, structureType: StructureBtStructureType): StructureFullExtended {
    if (current.structureType === structureType) {
      return current;
    }
    let structure = this.fullStructures.find((s) => s.recordID === current.parentRecordID);
    if (structure && structure.structureType !== structureType) {
      structure = this.getParentStructureType(structure, structureType);
    }
    return structure;
  }

  // Get

  getAllStructure(legalEntityID, structureAdmin, managerID) {
    if (this.config?.legalEntityConfig?.structureManagement) {
      this.store.dispatch(new StructureActions.StructureGetAll(legalEntityID, structureAdmin, managerID));
    }
  }

  // Add

  addStructures(structures: StructureFullExtended[]) {
    console.log('Service', structures);
    this.store.dispatch(new StructureActions.StructureAdd(structures));
  }

  // Update

  updateStructures(structures: StructureFullExtended[]) {
    this.store.dispatch(new StructureActions.StructureUpdate(structures));
  }

  // Dispose

  disposeStructures(structures: StructureFullExtended[]) {
    this.store.dispatch(new StructureActions.StructureDispose(structures.filter((p) => !p.$new)));
    this.store.dispatch(new StructureActions.StructureDelete(structures.filter((p) => p.$new)));
  }

  // Selectors

  parentStructureId(selectedStructureId: string) {
    this.store.dispatch(new StructureActions.StructureParentSelect(selectedStructureId));
  }

  deselectParentStructureId() {
    this.store.dispatch(new StructureActions.StructureParentDeselect(null));
  }

  // Undo
  structureUndoOne(structure: StructureFullExtended) {
    if (structure.$new) {
      this.store.dispatch(new StructureActions.StructureDelete([structure]));
    } else {
      this.store.dispatch(new StructureActions.StructureUndoOne(structure));
    }
  }

  structureUndo() {
    this.store.dispatch(new StructureActions.StructureUndo(null));
  }

  // Clear

  structureClear() {
    this.store.dispatch(new StructureActions.StructureClear(null));
  }

  // Destroy

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }
}
