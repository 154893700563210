/* tslint:disable */
/* eslint-disable */
export interface DvIdentityDocuments {
  bt_identityimage_name?: null | string;
  bthr_employeeidentityid?: null | string;
  bthr_employeeidentityname?: null | string;
  bthr_expirydate?: null | string;
  bthr_firstname?: null | string;
  bthr_identitynumber?: null | string;
  bthr_identitytype?: null | number;
  bthr_initials?: null | string;
  bthr_issuedate?: null | string;
  bthr_maidenorprevioussurname?: null | string;
  bthr_middlenames?: null | string;
  bthr_startdate?: null | string;
  bthr_surname?: null | string;
  odataCountryOfIssue?: null | string;
  odataIdentityType?: null | string;
  odataRecordStatus?: null | string;
  odataStatus?: null | string;
}
