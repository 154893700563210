import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { WorkspaceShortcut } from '@verde/shared';
declare global {
  interface Window {
    AdobeDC: any;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ShortcutsService {
  constructor() {}

  private _allShortcuts: Array<WorkspaceShortcut> = new Array<WorkspaceShortcut>();
  private _newAllShortcuts = new BehaviorSubject<Array<WorkspaceShortcut>>(new Array<WorkspaceShortcut>());

  currentAllShortcuts = this._newAllShortcuts.asObservable();

  set allShortcuts(val: Array<WorkspaceShortcut>) {
    this._allShortcuts = val;

    if (val.length > 0) {
      this.changeAllShortcuts(val);
    }
  }

  get allShortcuts(): Array<WorkspaceShortcut> {
    return this._allShortcuts;
  }

  public changeAllShortcuts(items: WorkspaceShortcut[]) {
    this._newAllShortcuts.next(items);
  }
}
