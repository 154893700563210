/* tslint:disable */
/* eslint-disable */
import { User } from './user';
export interface ApprovalLogsAssignedTo {
  '_bt_approvallog_value'?: null | string;
  '_bt_employee_value'?: null | string;
  '_bt_legalentity_value'?: null | string;
  '_bt_originalapprover_value'?: null | string;
  '_bt_originalentity_value'?: null | string;
  bt_Employee?: User;
  bt_delegated?: boolean;
  bt_verdeapprovallogsassignedto?: null | string;
  bt_verdeapprovallogsassignedtoid?: null | string;
}
