/* tslint:disable */
/* eslint-disable */
import { DocumentDocTypeFinancial } from './document-doc-type-financial';
import { DocumentBtDocumentGrouping } from './document-bt-document-grouping';
export interface DocumentVerdeBulkImport {
  bt_documenttype?: DocumentDocTypeFinancial;
  bt_filename?: null | string;
  documentGrouping?: DocumentBtDocumentGrouping;
  recordID?: string;
}
