/* tslint:disable */
/* eslint-disable */
export interface EquipmentType {
  '_bt_equipmenttypeclass_value'?: null | string;
  '_bt_legalentity_value'?: null | string;
  bt_checklistrequired?: null | boolean;
  bt_equipmenttype?: null | string;
  bt_equipmenttypeid?: null | string;
  bt_equipmenttypeunique?: null | string;
  odataEquipmentTypeClass?: null | string;
  odataLegalEntity?: null | string;
}
