/* tslint:disable */
/* eslint-disable */
export interface GiftSummary {
  bt_activecalendar?: null | boolean;
  bt_activefinancial?: null | boolean;
  bt_enddate?: null | string;
  bt_giftissuedapproved?: null | number;
  bt_giftissuedrejected?: null | number;
  bt_giftissuedvalue?: null | number;
  bt_giftissuesqty?: null | number;
  bt_giftreceivedapproved?: null | number;
  bt_giftreceivedqty?: null | number;
  bt_giftreceivedrejected?: null | number;
  bt_giftreceivedvalue?: null | number;
  bt_giftsummaryid?: null | string;
  bt_periodtype?: null | number;
  bt_startdate?: null | string;
  bt_year?: null | string;
  odataEndDate?: null | string;
  odataIssuedValue?: null | string;
  odataPeriodType?: null | string;
  odataReceivedValue?: null | string;
  odataStartDate?: null | string;
}
