
<div style="padding: 20px">

  <div>
    <label>Legal Entity:</label>
    <br />
    <kendo-textbox [readonly]="true"
                   [value]="selectedMeasure?.bt_LegalEntity.bthr_legalentityname"></kendo-textbox>
    <br />
    <br />
    <label>Template Type:</label>
    <br />
    <kendo-textbox [readonly]="true"
                   [value]="selectedMeasure?.bt_templatetype"></kendo-textbox>
    <br />
    <br />
    <label>Group:</label>
    <br />
    <kendo-textbox [readonly]="true"
                   [value]="selectedMeasure?.bt_Group.bt_name"></kendo-textbox>
    <br />
    <br />
    <label>Subgroup:</label>
    <br />
    <kendo-textbox [readonly]="true"
                   [value]="selectedMeasure?.bt_Subgroup.bt_peerreviewtemplatesubgroup"></kendo-textbox>
    <br />
    <br />
    <label>Measure:</label>
    <br />
    <kendo-textbox [readonly]="true"
                   [value]="selectedMeasure?.bt_measure"></kendo-textbox>
    <br />
    <br />
    <label>Image Required:</label>
    <br />
    <kendo-textbox [readonly]="true"
                   [value]="selectedMeasure?.bt_imagerequired"></kendo-textbox>
    <br />
    <br />
    <label>Allow Image:</label>
    <br />
    <kendo-textbox [readonly]="true"
                   [value]="selectedMeasure?.bt_allowimage"></kendo-textbox>
    <br />
    <br />
    <label>Response Type:</label>
    <br />
    <kendo-textbox [readonly]="true"
                   [value]="selectedMeasure?.bt_responsetype"></kendo-textbox>

  </div>

</div>
