import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { MsalGuard, MsalService } from '@azure/msal-angular';
import { AccessTokenEntity, AccountEntity, AuthenticationScheme, CredentialType } from '@azure/msal-common';
import * as microsoftTeams from '@microsoft/teams-js';
import jwtDecode from 'jwt-decode';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard implements CanActivate {
  constructor(
    private msalService: MsalService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private msalGuard: MsalGuard,
    @Inject('environment') private environment: any,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authenticationService.inTeams().then((inTeams): Promise<boolean | UrlTree> => {
      if (inTeams) {
        if (this.msalService.instance.getAllAccounts().length > 0) {
          return this.authenticationService.checkAndSetActiveAccount();
        }

        return new Promise<boolean | UrlTree>((resolve) => {
          microsoftTeams.authentication.getAuthToken({
            successCallback: (token: string) => {
              const decodedToken: { [key: string]: any } = jwtDecode(token) as { [key: string]: any };
              microsoftTeams.appInitialization.notifySuccess();
              return resolve(this.registerTeamsTokenWithMsal(decodedToken, token));
            },
            failureCallback: (message: string) => {
              microsoftTeams.appInitialization.notifyFailure({
                reason: microsoftTeams.appInitialization.FailedReason.AuthFailed,
                message,
              });

              this.authenticationService.authState$.next({
                ...this.authenticationService.authState$.getValue(),
                redirectUrl: state.url,
              });

              return resolve(this.router.parseUrl('/login'));
            },
            resources: [this.environment.msalConfig.auth.redirectUri],
          });
        });
      } else {
        if (this.msalService.instance.getAllAccounts().length === 0 && !state.url.includes('#code=') && !state.url.includes('teams.microsoft.com')) {
          this.router.navigate(['/login']);
          return Promise.resolve(true);
        }

        if (this.msalService.instance.getAllAccounts().length > 0) {
          return this.authenticationService.checkAndSetActiveAccount();
        }

        return this.msalGuard.canActivate(route, state).toPromise();
      }
    });
  }

  private registerTeamsTokenWithMsal(accessToken: { [key: string]: any }, accessTokenString: string) {
    const accountEntity = this.getAccountEntity(accessToken);
    const accessTokenEntity = this.getAccessTokenEntity(accessToken, accessTokenString);

    const browserStorage = (this.msalService.instance as any).browserStorage;
    if (browserStorage) {
      browserStorage.setAccount(accountEntity);
      browserStorage.setAccessTokenCredential(accessTokenEntity);
    }

    return this.authenticationService.checkAndSetActiveAccount();
  }

  private getAccountEntity(accessToken: { [key: string]: any }): AccountEntity {
    const account = new AccountEntity();
    Object.assign(account, {
      authorityType: 'MSSTS',
      // fixed
      environment: 'login.windows.net',
      // oid.tid
      homeAccountId: `${accessToken['oid']}.${accessToken['tid']}`,
      // oid
      localAccountId: accessToken['oid'],
      idTokenClaims: accessToken,
      // tid
      realm: accessToken['tid'],
      // upn
      username: accessToken['upn'],
    });

    return account;
  }

  private getAccessTokenEntity(accessToken: { [key: string]: any }, accessTokenString: string): AccessTokenEntity {
    return {
      cachedAt: accessToken['iat'],
      clientId: (accessToken['aud'] as string).substring((accessToken['aud'] as string).lastIndexOf('/') + 1),
      credentialType: CredentialType.ACCESS_TOKEN,
      environment: 'login.windows.net',
      expiresOn: accessToken['exp'],
      extendedExpiresOn: accessToken['exp'],
      homeAccountId: `${accessToken['oid']}.${accessToken['tid']}`,
      realm: accessToken['tid'],
      secret: accessTokenString,
      target: accessToken['scp'],
      tokenType: AuthenticationScheme.BEARER,
    };
  }
}
