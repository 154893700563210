/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BtDocSalaryAdviceHeader } from '../models/bt-doc-salary-advice-header';
import { BtPayrollImportDetail } from '../models/bt-payroll-import-detail';
import { BtPayrollInputHeader } from '../models/bt-payroll-input-header';
import { BtTaxYear } from '../models/bt-tax-year';
import { DocumentsAndForms } from '../models/documents-and-forms';
import { DocumentsAndFormsAndRelated } from '../models/documents-and-forms-and-related';
import { DocumentsAndFormsRelated } from '../models/documents-and-forms-related';
import { ImportHeader } from '../models/import-header';
import { VerdeDocumentsAll } from '../models/verde-documents-all';
import { VerdeDocumentsOne } from '../models/verde-documents-one';
import { VerdeFooterTemplate } from '../models/verde-footer-template';
import { VerdeHeaderTemplate } from '../models/verde-header-template';
import { VerdeManualDocuments } from '../models/verde-manual-documents';
import { VerdeWfhSummary } from '../models/verde-wfh-summary';

@Injectable({
  providedIn: 'root',
})
export class SharedApiVerdeDocumentsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getVerdeDocumentsAll
   */
  static readonly GetVerdeDocumentsAllPath = '/api/VerdeDocuments/get/{legalEntityGuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVerdeDocumentsAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVerdeDocumentsAll$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityGuid: string;
  }): Observable<StrictHttpResponse<Array<VerdeDocumentsAll>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiVerdeDocumentsService.GetVerdeDocumentsAllPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityGuid', params.legalEntityGuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VerdeDocumentsAll>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVerdeDocumentsAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVerdeDocumentsAll(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityGuid: string;
  }): Observable<Array<VerdeDocumentsAll>> {

    return this.getVerdeDocumentsAll$Response(params).pipe(
      map((r: StrictHttpResponse<Array<VerdeDocumentsAll>>) => r.body as Array<VerdeDocumentsAll>)
    );
  }

  /**
   * Path part for operation getVerdeDocumentsOne
   */
  static readonly GetVerdeDocumentsOnePath = '/api/VerdeDocuments/get/{legalEntityGuid}/{tempname}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVerdeDocumentsOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVerdeDocumentsOne$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityGuid: string;
    tempname: string;
  }): Observable<StrictHttpResponse<Array<VerdeDocumentsOne>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiVerdeDocumentsService.GetVerdeDocumentsOnePath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityGuid', params.legalEntityGuid, {});
      rb.path('tempname', params.tempname, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VerdeDocumentsOne>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVerdeDocumentsOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVerdeDocumentsOne(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityGuid: string;
    tempname: string;
  }): Observable<Array<VerdeDocumentsOne>> {

    return this.getVerdeDocumentsOne$Response(params).pipe(
      map((r: StrictHttpResponse<Array<VerdeDocumentsOne>>) => r.body as Array<VerdeDocumentsOne>)
    );
  }

  /**
   * Path part for operation getVerdeWfhSummary
   */
  static readonly GetVerdeWfhSummaryPath = '/api/VerdeDocuments/bt_verdewfhsummary/{LegalEntity}/{EmpID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVerdeWfhSummary()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVerdeWfhSummary$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    LegalEntity: string;
    EmpID: string;
  }): Observable<StrictHttpResponse<Array<VerdeWfhSummary>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiVerdeDocumentsService.GetVerdeWfhSummaryPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('LegalEntity', params.LegalEntity, {});
      rb.path('EmpID', params.EmpID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VerdeWfhSummary>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVerdeWfhSummary$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVerdeWfhSummary(params: {
    subscriptionKey?: string;
    APIURL?: string;
    LegalEntity: string;
    EmpID: string;
  }): Observable<Array<VerdeWfhSummary>> {

    return this.getVerdeWfhSummary$Response(params).pipe(
      map((r: StrictHttpResponse<Array<VerdeWfhSummary>>) => r.body as Array<VerdeWfhSummary>)
    );
  }

  /**
   * Path part for operation verdeHeaderTemplate
   */
  static readonly VerdeHeaderTemplatePath = '/api/VerdeDocuments/bt_verdedocumentheadertemplate/{LegalEntity}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `verdeHeaderTemplate()` instead.
   *
   * This method doesn't expect any request body.
   */
  verdeHeaderTemplate$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    LegalEntity: string;
  }): Observable<StrictHttpResponse<Array<VerdeHeaderTemplate>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiVerdeDocumentsService.VerdeHeaderTemplatePath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('LegalEntity', params.LegalEntity, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VerdeHeaderTemplate>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `verdeHeaderTemplate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  verdeHeaderTemplate(params: {
    subscriptionKey?: string;
    APIURL?: string;
    LegalEntity: string;
  }): Observable<Array<VerdeHeaderTemplate>> {

    return this.verdeHeaderTemplate$Response(params).pipe(
      map((r: StrictHttpResponse<Array<VerdeHeaderTemplate>>) => r.body as Array<VerdeHeaderTemplate>)
    );
  }

  /**
   * Path part for operation verdeFooterTemplate
   */
  static readonly VerdeFooterTemplatePath = '/api/VerdeDocuments/bt_verdedocumentfootertemplates/{LegalEntity}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `verdeFooterTemplate()` instead.
   *
   * This method doesn't expect any request body.
   */
  verdeFooterTemplate$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    LegalEntity: string;
  }): Observable<StrictHttpResponse<Array<VerdeFooterTemplate>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiVerdeDocumentsService.VerdeFooterTemplatePath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('LegalEntity', params.LegalEntity, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VerdeFooterTemplate>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `verdeFooterTemplate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  verdeFooterTemplate(params: {
    subscriptionKey?: string;
    APIURL?: string;
    LegalEntity: string;
  }): Observable<Array<VerdeFooterTemplate>> {

    return this.verdeFooterTemplate$Response(params).pipe(
      map((r: StrictHttpResponse<Array<VerdeFooterTemplate>>) => r.body as Array<VerdeFooterTemplate>)
    );
  }

  /**
   * Path part for operation docsalaryadvice
   */
  static readonly DocsalaryadvicePath = '/api/VerdeDocuments/docsalaryadvice/{LegalEntity}/{EmpID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `docsalaryadvice()` instead.
   *
   * This method doesn't expect any request body.
   */
  docsalaryadvice$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    LegalEntity: string;
    EmpID: string;
  }): Observable<StrictHttpResponse<Array<BtDocSalaryAdviceHeader>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiVerdeDocumentsService.DocsalaryadvicePath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('LegalEntity', params.LegalEntity, {});
      rb.path('EmpID', params.EmpID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BtDocSalaryAdviceHeader>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `docsalaryadvice$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  docsalaryadvice(params: {
    subscriptionKey?: string;
    APIURL?: string;
    LegalEntity: string;
    EmpID: string;
  }): Observable<Array<BtDocSalaryAdviceHeader>> {

    return this.docsalaryadvice$Response(params).pipe(
      map((r: StrictHttpResponse<Array<BtDocSalaryAdviceHeader>>) => r.body as Array<BtDocSalaryAdviceHeader>)
    );
  }

  /**
   * Path part for operation getDocumentsAndForms
   */
  static readonly GetDocumentsAndFormsPath = '/api/VerdeDocuments/documentsandforms/{LegalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocumentsAndForms()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentsAndForms$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    LegalEntityID: string;
  }): Observable<StrictHttpResponse<Array<DocumentsAndForms>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiVerdeDocumentsService.GetDocumentsAndFormsPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('LegalEntityID', params.LegalEntityID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DocumentsAndForms>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDocumentsAndForms$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentsAndForms(params: {
    subscriptionKey?: string;
    APIURL?: string;
    LegalEntityID: string;
  }): Observable<Array<DocumentsAndForms>> {

    return this.getDocumentsAndForms$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DocumentsAndForms>>) => r.body as Array<DocumentsAndForms>)
    );
  }

  /**
   * Path part for operation getDocumentsAndFormsRelated
   */
  static readonly GetDocumentsAndFormsRelatedPath = '/api/VerdeDocuments/documentsandformsrelated/{LegalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocumentsAndFormsRelated()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentsAndFormsRelated$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    LegalEntityID: string;
  }): Observable<StrictHttpResponse<Array<DocumentsAndFormsRelated>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiVerdeDocumentsService.GetDocumentsAndFormsRelatedPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('LegalEntityID', params.LegalEntityID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DocumentsAndFormsRelated>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDocumentsAndFormsRelated$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentsAndFormsRelated(params: {
    subscriptionKey?: string;
    APIURL?: string;
    LegalEntityID: string;
  }): Observable<Array<DocumentsAndFormsRelated>> {

    return this.getDocumentsAndFormsRelated$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DocumentsAndFormsRelated>>) => r.body as Array<DocumentsAndFormsRelated>)
    );
  }

  /**
   * Path part for operation getDocumentsAndFormsAndRelated
   */
  static readonly GetDocumentsAndFormsAndRelatedPath = '/api/VerdeDocuments/getDocumentsAndFormsAndRelated/{LegalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocumentsAndFormsAndRelated()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentsAndFormsAndRelated$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    LegalEntityID: string;
  }): Observable<StrictHttpResponse<Array<DocumentsAndFormsAndRelated>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiVerdeDocumentsService.GetDocumentsAndFormsAndRelatedPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('LegalEntityID', params.LegalEntityID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DocumentsAndFormsAndRelated>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDocumentsAndFormsAndRelated$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentsAndFormsAndRelated(params: {
    subscriptionKey?: string;
    APIURL?: string;
    LegalEntityID: string;
  }): Observable<Array<DocumentsAndFormsAndRelated>> {

    return this.getDocumentsAndFormsAndRelated$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DocumentsAndFormsAndRelated>>) => r.body as Array<DocumentsAndFormsAndRelated>)
    );
  }

  /**
   * Path part for operation getPayrollHeader
   */
  static readonly GetPayrollHeaderPath = '/api/VerdeDocuments/GetPayrollHeader';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPayrollHeader()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPayrollHeader$Response(params?: {
    subscriptionKey?: string;
    APIURL?: string;
  }): Observable<StrictHttpResponse<Array<BtPayrollInputHeader>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiVerdeDocumentsService.GetPayrollHeaderPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BtPayrollInputHeader>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPayrollHeader$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPayrollHeader(params?: {
    subscriptionKey?: string;
    APIURL?: string;
  }): Observable<Array<BtPayrollInputHeader>> {

    return this.getPayrollHeader$Response(params).pipe(
      map((r: StrictHttpResponse<Array<BtPayrollInputHeader>>) => r.body as Array<BtPayrollInputHeader>)
    );
  }

  /**
   * Path part for operation getImportHeaders
   */
  static readonly GetImportHeadersPath = '/api/VerdeDocuments/getImportHeaders/{environmentURL}/{slot}/{period}/{year}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getImportHeaders()` instead.
   *
   * This method doesn't expect any request body.
   */
  getImportHeaders$Response(params: {
    environmentURL: string;
    slot: string;
    period: number;
    year: number;
  }): Observable<StrictHttpResponse<Array<ImportHeader>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiVerdeDocumentsService.GetImportHeadersPath, 'get');
    if (params) {
      rb.path('environmentURL', params.environmentURL, {});
      rb.path('slot', params.slot, {});
      rb.path('period', params.period, {});
      rb.path('year', params.year, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ImportHeader>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getImportHeaders$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getImportHeaders(params: {
    environmentURL: string;
    slot: string;
    period: number;
    year: number;
  }): Observable<Array<ImportHeader>> {

    return this.getImportHeaders$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ImportHeader>>) => r.body as Array<ImportHeader>)
    );
  }

  /**
   * Path part for operation getTaxYearAndPeriod
   */
  static readonly GetTaxYearAndPeriodPath = '/api/VerdeDocuments/GetTaxYearAndPeriod';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTaxYearAndPeriod()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTaxYearAndPeriod$Response(params?: {
    subscriptionKey?: string;
    APIURL?: string;
  }): Observable<StrictHttpResponse<Array<BtTaxYear>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiVerdeDocumentsService.GetTaxYearAndPeriodPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BtTaxYear>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTaxYearAndPeriod$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTaxYearAndPeriod(params?: {
    subscriptionKey?: string;
    APIURL?: string;
  }): Observable<Array<BtTaxYear>> {

    return this.getTaxYearAndPeriod$Response(params).pipe(
      map((r: StrictHttpResponse<Array<BtTaxYear>>) => r.body as Array<BtTaxYear>)
    );
  }

  /**
   * Path part for operation postBtPayrollmportDetail
   */
  static readonly PostBtPayrollmportDetailPath = '/api/VerdeDocuments/PostBtPayrollmportDetail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postBtPayrollmportDetail()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postBtPayrollmportDetail$Response(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: BtPayrollImportDetail
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiVerdeDocumentsService.PostBtPayrollmportDetailPath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postBtPayrollmportDetail$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postBtPayrollmportDetail(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: BtPayrollImportDetail
  }): Observable<boolean> {

    return this.postBtPayrollmportDetail$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation decrypt
   */
  static readonly DecryptPath = '/api/VerdeDocuments/decrypt';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `decrypt()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  decrypt$Response(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: BtDocSalaryAdviceHeader
  }): Observable<StrictHttpResponse<BtDocSalaryAdviceHeader>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiVerdeDocumentsService.DecryptPath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BtDocSalaryAdviceHeader>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `decrypt$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  decrypt(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: BtDocSalaryAdviceHeader
  }): Observable<BtDocSalaryAdviceHeader> {

    return this.decrypt$Response(params).pipe(
      map((r: StrictHttpResponse<BtDocSalaryAdviceHeader>) => r.body as BtDocSalaryAdviceHeader)
    );
  }

  /**
   * Path part for operation getVerdeManualDocuments
   */
  static readonly GetVerdeManualDocumentsPath = '/api/VerdeDocuments/verdemanualdocuments/{userID}/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVerdeManualDocuments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVerdeManualDocuments$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userID: string;
    legalEntityID: string;
  }): Observable<StrictHttpResponse<Array<VerdeManualDocuments>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiVerdeDocumentsService.GetVerdeManualDocumentsPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('userID', params.userID, {});
      rb.path('legalEntityID', params.legalEntityID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VerdeManualDocuments>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVerdeManualDocuments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVerdeManualDocuments(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userID: string;
    legalEntityID: string;
  }): Observable<Array<VerdeManualDocuments>> {

    return this.getVerdeManualDocuments$Response(params).pipe(
      map((r: StrictHttpResponse<Array<VerdeManualDocuments>>) => r.body as Array<VerdeManualDocuments>)
    );
  }

  /**
   * Path part for operation getVerdeAllDocuments
   */
  static readonly GetVerdeAllDocumentsPath = '/api/VerdeDocuments/getVerdeAllDocuments/{userID}/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVerdeAllDocuments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVerdeAllDocuments$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userID: string;
    legalEntityID: string;
  }): Observable<StrictHttpResponse<Array<VerdeManualDocuments>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiVerdeDocumentsService.GetVerdeAllDocumentsPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('userID', params.userID, {});
      rb.path('legalEntityID', params.legalEntityID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VerdeManualDocuments>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVerdeAllDocuments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVerdeAllDocuments(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userID: string;
    legalEntityID: string;
  }): Observable<Array<VerdeManualDocuments>> {

    return this.getVerdeAllDocuments$Response(params).pipe(
      map((r: StrictHttpResponse<Array<VerdeManualDocuments>>) => r.body as Array<VerdeManualDocuments>)
    );
  }

  /**
   * Path part for operation patchVerdeManualDocuments
   */
  static readonly PatchVerdeManualDocumentsPath = '/api/VerdeDocuments/patchverdemanualdocuments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchVerdeManualDocuments()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchVerdeManualDocuments$Response(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: VerdeManualDocuments
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiVerdeDocumentsService.PatchVerdeManualDocumentsPath, 'patch');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchVerdeManualDocuments$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchVerdeManualDocuments(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: VerdeManualDocuments
  }): Observable<boolean> {

    return this.patchVerdeManualDocuments$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

}
