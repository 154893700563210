/* tslint:disable */
/* eslint-disable */
import { ProfileBtWorkflowStatus } from './profile-bt-workflow-status';
import { ProfileBthrRecordStatus } from './profile-bthr-record-status';
import { ProfileCr1F2Yesnounknown } from './profile-cr-1-f-2-yesnounknown';
export interface ProfileVulnerabilityAssessments {
  bt_age?: null | number;
  bt_covidvacince?: null | boolean;
  bt_covvacdate?: null | string;
  bt_fluvacdate?: null | string;
  bt_olderthan60?: null | boolean;
  bt_recordstatus?: ProfileBthrRecordStatus;
  bt_vulnerable?: null | boolean;
  bt_workflowstatus?: ProfileBtWorkflowStatus;
  bthr_asthma?: ProfileCr1F2Yesnounknown;
  bthr_chronickidneydisease?: ProfileCr1F2Yesnounknown;
  bthr_chronicliverdisease?: ProfileCr1F2Yesnounknown;
  bthr_chroniclungdisease?: ProfileCr1F2Yesnounknown;
  bthr_dateofbirth?: null | string;
  bthr_diabetes?: ProfileCr1F2Yesnounknown;
  bthr_hiv?: ProfileCr1F2Yesnounknown;
  bthr_hypertension?: ProfileCr1F2Yesnounknown;
  bthr_immunocompromised?: ProfileCr1F2Yesnounknown;
  bthr_obesity?: ProfileCr1F2Yesnounknown;
  bthr_pregnancyequaltogreater28weeks?: ProfileCr1F2Yesnounknown;
  bthr_receivedfluvaccine?: ProfileCr1F2Yesnounknown;
  bthr_seriousheartconditions?: ProfileCr1F2Yesnounknown;
  bthr_smokingcurrent?: ProfileCr1F2Yesnounknown;
  bthr_tuberculosiscurrent?: ProfileCr1F2Yesnounknown;
  bthr_tuberculosisprevious?: ProfileCr1F2Yesnounknown;
  bthr_vulnerabilityassessmentid?: null | string;
}
