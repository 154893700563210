/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { TimeOffBaseService } from '../time-off-base-service';
import { TimeOffApiConfiguration } from '../time-off-api-configuration';
import { TimeOffStrictHttpResponse } from '../time-off-strict-http-response';
import { TimeOffRequestBuilder } from '../time-off-request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { TimeOffGetTimeOffQuery } from '../models/time-off-get-time-off-query';
import { TimeOffPostTimeOffCommand } from '../models/time-off-post-time-off-command';
import { TimeOffTimeOffExample } from '../models/time-off-time-off-example';

@Injectable({
  providedIn: 'root',
})
export class TimeOffApiTimeOffService extends TimeOffBaseService {
  constructor(
    config: TimeOffApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getTimeOff
   */
  static readonly GetTimeOffPath = '/api/TimeOff/get/timeoff';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTimeOff()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getTimeOff$Response(params?: {
    tenantId?: string;
    body?: TimeOffGetTimeOffQuery
  }): Observable<TimeOffStrictHttpResponse<Array<TimeOffTimeOffExample>>> {

    const rb = new TimeOffRequestBuilder(this.rootUrl, TimeOffApiTimeOffService.GetTimeOffPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as TimeOffStrictHttpResponse<Array<TimeOffTimeOffExample>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTimeOff$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getTimeOff(params?: {
    tenantId?: string;
    body?: TimeOffGetTimeOffQuery
  }): Observable<Array<TimeOffTimeOffExample>> {

    return this.getTimeOff$Response(params).pipe(
      map((r: TimeOffStrictHttpResponse<Array<TimeOffTimeOffExample>>) => r.body as Array<TimeOffTimeOffExample>)
    );
  }

  /**
   * Path part for operation postTimeOff
   */
  static readonly PostTimeOffPath = '/api/TimeOff/post/timeoff';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postTimeOff()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postTimeOff$Response(params?: {
    tenantId?: string;
    body?: TimeOffPostTimeOffCommand
  }): Observable<TimeOffStrictHttpResponse<boolean>> {

    const rb = new TimeOffRequestBuilder(this.rootUrl, TimeOffApiTimeOffService.PostTimeOffPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as TimeOffStrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postTimeOff$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postTimeOff(params?: {
    tenantId?: string;
    body?: TimeOffPostTimeOffCommand
  }): Observable<boolean> {

    return this.postTimeOff$Response(params).pipe(
      map((r: TimeOffStrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

}
