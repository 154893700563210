import { createEntityAdapter, EntityState, EntityAdapter } from '@ngrx/entity';
import { EmployeeEducationalQualificationRenewalActions, EmployeeEducationalQualificationRenewalActionTypes } from '../actions/employee-educational-qualification-renewal.actions';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromProfileRelatedReducer from './profile-related.reducer';

// Models
import { QualificationsBtEmployeeEducationalQualificationRenewal } from '@verde/shared';

// Helpers
import { keyHandler } from '@verde/core';

export type EmployeeEducationalQualificationRenewalState = EntityState<QualificationsBtEmployeeEducationalQualificationRenewal>;

export interface EmployeeEducationalQualificationRenewalRelatedState {
  employeeEducationalQualificationRenewalState: EmployeeEducationalQualificationRenewalState;
  selectedIds: string[];
  loading: boolean;
  error: any;
}

export const employeeEducationalQualificationRenewalKeys = ['bt_employeequalificationrenewalid'];
export const employeeEducationalQualificationRenewalStateAdapter: EntityAdapter<QualificationsBtEmployeeEducationalQualificationRenewal> =
  createEntityAdapter<QualificationsBtEmployeeEducationalQualificationRenewal>({
    selectId: (data) => keyHandler(data, employeeEducationalQualificationRenewalKeys),
  });

export const initialEmployeeEducationalQualificationRenewalState: EmployeeEducationalQualificationRenewalState = employeeEducationalQualificationRenewalStateAdapter.getInitialState(
  {},
);

export const initialEmployeeEducationalQualificationRenewalRelatedState = {
  employeeEducationalQualificationRenewalState: initialEmployeeEducationalQualificationRenewalState,
  selectedIds: [],
  loading: false,
  error: {},
};

export function EmployeeEducationalQualificationRenewalRelatedReducer(
  state = initialEmployeeEducationalQualificationRenewalRelatedState,
  action: EmployeeEducationalQualificationRenewalActions,
): EmployeeEducationalQualificationRenewalRelatedState {
  switch (action.type) {
    // Get EmployeeEducationalQualificationRenewal
    case EmployeeEducationalQualificationRenewalActionTypes.EMPLOYEEEDUCATIONALQUALIFICATIONRENEWAL_GET_ALL:
      return { ...state, loading: true };
    case EmployeeEducationalQualificationRenewalActionTypes.EMPLOYEEEDUCATIONALQUALIFICATIONRENEWAL_GET_ALL_SUCCESS: {
      return {
        ...state,
        loading: false,
        employeeEducationalQualificationRenewalState: employeeEducationalQualificationRenewalStateAdapter.setAll(action.payload, state.employeeEducationalQualificationRenewalState),
      };
    }
    case EmployeeEducationalQualificationRenewalActionTypes.EMPLOYEEEDUCATIONALQUALIFICATIONRENEWAL_GET_ALL_ERROR:
      return { ...state, loading: false, error: action.payload };

    // Upload
    case EmployeeEducationalQualificationRenewalActionTypes.EMPLOYEEEDUCATIONALQUALIFICATIONRENEWAL_UPLOAD_SUCCESS: {
      return {
        ...state,
      };
    }
    case EmployeeEducationalQualificationRenewalActionTypes.EMPLOYEEEDUCATIONALQUALIFICATIONRENEWAL_UPLOAD_ERROR:
      return { ...state, loading: false, error: action.payload };

    // Update
    case EmployeeEducationalQualificationRenewalActionTypes.EMPLOYEEEDUCATIONALQUALIFICATIONRENEWAL_UPDATE_SUCCESS: {
      return {
        ...state,
      };
    }
    case EmployeeEducationalQualificationRenewalActionTypes.EMPLOYEEEDUCATIONALQUALIFICATIONRENEWAL_UPDATE_ERROR:
      return { ...state, loading: false, error: action.payload };

    // Clear
    case EmployeeEducationalQualificationRenewalActionTypes.EMPLOYEEEDUCATIONALQUALIFICATIONRENEWAL_CLEAR:
      return { ...initialEmployeeEducationalQualificationRenewalRelatedState };
    default:
      return state;
  }
}

export const getProfileRelatedState = createFeatureSelector<fromProfileRelatedReducer.ProfileRelatedState>('profileRelatedReducer');

export const getEmployeeEducationalQualificationRenewalRelatedState = createSelector(
  getProfileRelatedState,
  (state: fromProfileRelatedReducer.ProfileRelatedState) => state.employeeEducationalQualificationRenewalState,
);

// Current EmployeeEducationalQualificationRenewal

export const getEmployeeEducationalQualificationRenewalState = createSelector(
  getEmployeeEducationalQualificationRenewalRelatedState,
  (state) => state.employeeEducationalQualificationRenewalState,
);
export const { selectAll: getEmployeeEducationalQualificationRenewal } =
  employeeEducationalQualificationRenewalStateAdapter.getSelectors(getEmployeeEducationalQualificationRenewalState);

// Selected

export const getSelectedEmployeeEducationalQualificationRenewalIds = createSelector(getEmployeeEducationalQualificationRenewalRelatedState, (state) => state.selectedIds);

export const getSelectedProfiles = createSelector(
  getEmployeeEducationalQualificationRenewal,
  getSelectedEmployeeEducationalQualificationRenewalIds,
  (EmployeeEducationalQualificationRenewal, selectedIds: string[]) =>
    EmployeeEducationalQualificationRenewal.filter((m) => selectedIds.indexOf(keyHandler(m, employeeEducationalQualificationRenewalKeys)) !== -1),
);

// Loading

export const isEmployeeEducationalQualificationRenewalLoading = createSelector(getEmployeeEducationalQualificationRenewalRelatedState, (state) => state.loading);

// Error

export const getProfileError = createSelector(getEmployeeEducationalQualificationRenewalRelatedState, (state) => state.error);
