import { QualificationsGetCountriesQuery } from '@verde/shared';
import { Action } from '@ngrx/store';

export enum CountryActionTypes {
  // Get All VaccinationTypes
  COUNTRY_GET_ALL = '[Country] Get All',
  COUNTRY_GET_ALL_SUCCESS = '[Country] Get All Success',
  COUNTRY_GET_ALL_ERROR = '[Country] Get All Error',
  // Clear All VaccinationTypes
  COUNTRY_CLEAR = '[Country] Clear',
}

//Get All Country
export class CountryGetAll implements Action {
  readonly type = CountryActionTypes.COUNTRY_GET_ALL;
  constructor(public body: QualificationsGetCountriesQuery) {}
}

export class CountryGetAllSuccess implements Action {
  readonly type = CountryActionTypes.COUNTRY_GET_ALL_SUCCESS;
  constructor(public payload: any) {}
}

export class CountryGetAllError implements Action {
  readonly type = CountryActionTypes.COUNTRY_GET_ALL_ERROR;
  constructor(public payload: any) {}
}

// Clear All
export class CountryClear implements Action {
  readonly type = CountryActionTypes.COUNTRY_CLEAR;
  constructor(public payload: any) {}
}

export type CountryActions =
  // Get All Country
  | CountryGetAll
  | CountryGetAllSuccess
  | CountryGetAllError
  // Clear All Country
  | CountryClear;
