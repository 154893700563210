/* tslint:disable */
/* eslint-disable */
export interface EmployeeStatusEngage {
  '_bt_legalentity_value'?: null | string;
  '_bt_manageremail_value'?: null | string;
  '_bt_managerid_value'?: null | string;
  '_bt_presencestatus_value'?: null | string;
  '_bt_proxy_value'?: null | string;
  '_bt_user_value'?: null | string;
  '_bthr_employeeid_value'?: null | string;
  bt_accessstatus?: number;
  bt_bookingno?: null | string;
  bt_date?: string;
  bt_deskbooking?: boolean;
  bt_deskbookingrequired?: null | string;
  bt_employeestatusid?: null | string;
  bt_healthstatus?: boolean;
  bt_infectionrisk?: boolean;
  bt_isproxy?: boolean;
  bt_locationupdated?: boolean;
  bt_monitor?: boolean;
  bt_notes?: null | string;
  bt_oneliner?: null | string;
  bt_presencestatussource?: null | string;
  bt_recordstatus?: null | string;
  bt_risk?: boolean;
  bt_sourcecreateddate?: null | string;
  bt_sourcemodifieddate?: null | string;
  bt_status?: null | string;
  bt_statusid?: null | string;
  bt_tempcheckdone?: null | string;
  bt_tempcheckrequired?: null | string;
  bt_temperature?: null | string;
  bt_testdate?: null | string;
  bt_testedpositive?: boolean;
  bt_testresultoutstanding?: null | string;
  bt_title?: null | string;
  bthr_manageremail?: null | string;
  odatahealthstatus?: null | string;
  odatainfectionrisk?: null | string;
  odatalocationupdated?: null | string;
  odatarisk?: null | string;
  odatatestedpositive?: null | string;
  temperature?: null | number;
}
