/* tslint:disable */
/* eslint-disable */
export interface DvGifts {
  '_bt_escalate1_value'?: null | string;
  '_bt_escalate2_value'?: null | string;
  '_bt_executive_value'?: null | string;
  '_bt_manager_value'?: null | string;
  bt_accescalation?: null | number;
  bt_agreed?: null | boolean;
  bt_approval1date?: null | string;
  bt_approval2date?: null | string;
  bt_approval3date?: null | string;
  bt_approval4date?: null | string;
  bt_approval5date?: null | string;
  bt_approvalcomment?: null | string;
  bt_approvalid?: null | string;
  bt_approvalprocess?: null | string;
  bt_approvalrequired?: null | boolean;
  bt_approvedescalated1?: null | boolean;
  bt_approvedescalated1date?: null | string;
  bt_approvedescalated2?: null | boolean;
  bt_approvedescalated2date?: null | string;
  bt_approvedexecutive?: null | boolean;
  bt_approvedexecutivedate?: null | string;
  bt_approvedmanager?: null | boolean;
  bt_approvedmanagerdate?: null | string;
  bt_approver1?: null | boolean;
  bt_approver1comments?: null | string;
  bt_approver1email?: null | string;
  bt_approver1status?: null | number;
  bt_approver2?: null | boolean;
  bt_approver2comments?: null | string;
  bt_approver2email?: null | string;
  bt_approver2status?: null | number;
  bt_approver3?: null | boolean;
  bt_approver3comments?: null | string;
  bt_approver3email?: null | string;
  bt_approver3status?: null | number;
  bt_approver4?: null | boolean;
  bt_approver4comments?: null | string;
  bt_approver4email?: null | string;
  bt_approver4status?: null | number;
  bt_approver5?: null | boolean;
  bt_approver5comments?: null | string;
  bt_approver5email?: null | string;
  bt_approver5status?: null | number;
  bt_approvername?: null | string;
  bt_currentapprovalstatus?: null | number;
  bt_date?: null | string;
  bt_description?: null | string;
  bt_employeeguest?: null | boolean;
  bt_escalate1entityname?: null | string;
  bt_escalate1guest?: null | boolean;
  bt_escalate2email?: null | string;
  bt_escalate2entityname?: null | string;
  bt_escalate2guest?: null | boolean;
  bt_escalation1approvalstatus?: null | number;
  bt_escalation1comment?: null | string;
  bt_escalation2comment?: null | string;
  bt_escalation2status?: null | number;
  bt_escalationreason?: null | number;
  bt_escalationvalue?: null | number;
  bt_escalte1email?: null | string;
  bt_escalted?: null | boolean;
  bt_estimatedvalue?: null | number;
  bt_executiveapprovalstatus?: null | number;
  bt_executivecomment?: null | string;
  bt_executiveemail?: null | string;
  bt_executiveentityname?: null | string;
  bt_executiveguest?: null | boolean;
  bt_finalstatus?: null | number;
  bt_giftno?: null | string;
  bt_giftreceived?: null | boolean;
  bt_giftregisterid?: null | string;
  bt_intergroup?: null | boolean;
  bt_issuedby?: null | string;
  bt_manageentityname?: null | string;
  bt_managercomment?: null | string;
  bt_manageremail?: null | string;
  bt_managerguest?: null | boolean;
  bt_managerstatus?: null | number;
  bt_mdentityname?: null | string;
  bt_motivation?: null | string;
  bt_proofofgiftfilename?: null | string;
  bt_proofofreturnfilename?: null | string;
  bt_proxy?: null | boolean;
  bt_receivedby?: null | string;
  bt_receiveddate?: null | string;
  bt_returncomment?: null | string;
  bt_returned?: null | boolean;
  bt_returneddate?: null | string;
  bt_returnedvalidated?: null | boolean;
  bt_returnedvalidateddate?: null | string;
  bt_type?: null | number;
  bt_workflowstatus?: null | number;
  bt_ytdgiftsexcludingcurrent?: null | number;
  bt_ytdgiftsincluding?: null | number;
  createdon?: null | string;
  odataApprover1Date?: null | string;
  odataApprover1Status?: null | string;
  odataApprover2Date?: null | string;
  odataApprover2Status?: null | string;
  odataApprover3Date?: null | string;
  odataApprover3Status?: null | string;
  odataApprover4Date?: null | string;
  odataApprover4Status?: null | string;
  odataApprover5Date?: null | string;
  odataApprover5Status?: null | string;
  odataCurrentStatus?: null | string;
  odataEscalate1?: null | string;
  odataEscalate1Date?: null | string;
  odataEscalate1LegalEntity?: null | string;
  odataEscalate1Status?: null | string;
  odataEscalate2?: null | string;
  odataEscalate2Date?: null | string;
  odataEscalate2LegalEntity?: null | string;
  odataEscalate2Status?: null | string;
  odataEscalated?: null | string;
  odataEscalationReason?: null | string;
  odataExecutive?: null | string;
  odataExecutiveDate?: null | string;
  odataExecutiveLegalEntity?: null | string;
  odataExecutiveStatus?: null | string;
  odataFinalStatus?: null | string;
  odataGiftReceived?: null | string;
  odataGroupEmployee?: null | string;
  odataGroupLegalEntity?: null | string;
  odataInterGroup?: null | string;
  odataManager?: null | string;
  odataManagerDate?: null | string;
  odataManagerLegalEntity?: null | string;
  odataManagerStatus?: null | string;
  odataPostEmployee?: null | string;
  odataPostEscalate1?: null | string;
  odataPostEscalate1LegalEntity?: null | string;
  odataPostEscalate2?: null | string;
  odataPostEscalate2LegalEntity?: null | string;
  odataPostExecutive?: null | string;
  odataPostExecutiveLegalEntity?: null | string;
  odataPostGroupEmployee?: null | string;
  odataPostGroupLegalEntity?: null | string;
  odataPostLegalEntity?: null | string;
  odataPostManager?: null | string;
  odataPostManagerLegalEntity?: null | string;
  odataPostProxy?: null | string;
  odataType?: null | string;
  odataWorkflowStatus?: null | string;
}
