/* tslint:disable */
/* eslint-disable */
export enum ProcessesBtRecruitmentStatus {
  New = 'New',
  Advertising = 'Advertising',
  Shortlist = 'Shortlist',
  OfferMade = 'OfferMade',
  OfferAccepted = 'OfferAccepted',
  Onboarding = 'Onboarding',
  Completed = 'Completed'
}
