import { AfterContentInit, Component, Input, OnDestroy } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserApiUserService, SharedApiFileService, UserUserDto } from '@verde/shared';
import { UserService } from '@verde/core';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'verde-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss'],
})
export class UserCardComponent implements OnDestroy, AfterContentInit {
  private onDestroy$ = new Subject<boolean>();

  //#region Variable Declarations
  @Input() employeeID: string = '';
  legalEntityImageBase64: string | null = null;
  displayUser: UserUserDto = {};
  disableAnimation: boolean = false;
  //#endregion Variable Declarations

  constructor(
    private spinner: NgxSpinnerService,
    private userService: UserService,
    private userApiUserService: UserApiUserService,
    private sharedApiFileService: SharedApiFileService,
  ) {}

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  ngAfterContentInit() {
    if (this.employeeID !== null) {
      this.getDisplayUser();
    }

    this.userService.disableAnimation$.pipe(takeUntil(this.onDestroy$)).subscribe((data) => {
      this.disableAnimation = data;
    });
  }

  getDisplayUser() {
    this.spinner.show('user_card');
    this.userApiUserService
      .getUserById({ body: { employeeId: this.employeeID } })
      .pipe(take(1))
      .subscribe(
        (displayUser) => {
          this.displayUser = displayUser;
        },
        (error) => {
          console.error(error);
        },
        () => {
          this.getLegalEntityImage();
        },
      );
  }

  // GET Legal Entity Image
  getLegalEntityImage() {
    if (this.displayUser.legalEntityId) {
      this.sharedApiFileService
        .getLegalEntityImage({ legalEntityID: this.displayUser?.legalEntityId })
        .pipe(take(1))
        .subscribe(
          (ret) => {
            if (ret !== null) {
              1;
              this.legalEntityImageBase64 = ret;
            } else {
              this.legalEntityImageBase64 = null;
            }
          },
          (error) => {
            console.error(error);
          },
          () => {
            this.legalEntityImageBase64 = 'data:image/png;base64,' + this.legalEntityImageBase64;

            this.spinner.hide('user_card');
          },
        );
    }
  }
}
