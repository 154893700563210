/* tslint:disable */
/* eslint-disable */
import { TemplateTemplateLegalEntity } from './template-template-legal-entity';
import { TemplateBtTemplateType } from './template-bt-template-type';
export interface TemplateTemplateGroup {
  bt_LegalEntity?: TemplateTemplateLegalEntity;
  bt_TemplateType?: TemplateBtTemplateType;
  bt_name?: null | string;
  bt_peerreviewtemplategroupid?: null | string;
  bt_sorting?: null | number;
}
