/* tslint:disable */
/* eslint-disable */
import { TemplateTemplateLegalEntity } from './template-template-legal-entity';
import { TemplateBtTemplateType } from './template-bt-template-type';
export interface TemplateTemplateData {
  bt_LegalEntity?: TemplateTemplateLegalEntity;
  bt_defaultbasepoint?: null | number;
  bt_description?: null | string;
  bt_detailcount?: null | number;
  bt_displaysubgroup?: null | boolean;
  bt_name?: null | string;
  bt_peerevaluationtemplateid?: null | string;
  bt_ratingmaxvalue?: null | number;
  bt_ratingminvalue?: null | number;
  bt_templatetype?: TemplateBtTemplateType;
}
