/* tslint:disable */
/* eslint-disable */
import { EquipmentEquipmentDepartment } from './equipment-equipment-department';
import { EquipmentEquipmentDivision } from './equipment-equipment-division';
import { EquipmentEquipmentEmployee } from './equipment-equipment-employee';
import { EquipmentEquipmentGroupLocation } from './equipment-equipment-group-location';
import { EquipmentEquipmentKit } from './equipment-equipment-kit';
import { EquipmentEquipmentLegalEntity } from './equipment-equipment-legal-entity';
import { EquipmentEquipmentLocation } from './equipment-equipment-location';
import { EquipmentEquipmentPosition } from './equipment-equipment-position';
import { EquipmentEquipmentPositionSeat } from './equipment-equipment-position-seat';
import { EquipmentEquipmentRequestHeader } from './equipment-equipment-request-header';
import { EquipmentEquipmentRequestItem } from './equipment-equipment-request-item';
import { EquipmentEquipmentSpec } from './equipment-equipment-spec';
import { EquipmentEquipmentSpecType } from './equipment-equipment-spec-type';
import { EquipmentEquipmentType } from './equipment-equipment-type';
import { EquipmentEquipmentTypeClass } from './equipment-equipment-type-class';
import { EquipmentMoney } from './equipment-money';
import { EquipmentBtAllocationStatus } from './equipment-bt-allocation-status';
import { EquipmentBtApprovalStatus } from './equipment-bt-approval-status';
import { EquipmentBtEquipmentClassification } from './equipment-bt-equipment-classification';
import { EquipmentBtEquipmentRequestDetailState } from './equipment-bt-equipment-request-detail-state';
import { EquipmentBtLineType } from './equipment-bt-line-type';
import { EquipmentBtPoolType } from './equipment-bt-pool-type';
export interface EquipmentEquipmentRequestDetail {
  bt_AllocatedBy?: EquipmentEquipmentEmployee;
  bt_AllocatedLegalEntity?: EquipmentEquipmentLegalEntity;
  bt_Employee?: EquipmentEquipmentEmployee;
  bt_EmployeeLegalEntity?: EquipmentEquipmentLegalEntity;
  bt_EmployeeLocation?: EquipmentEquipmentLocation;
  bt_EmployeePosition?: EquipmentEquipmentPosition;
  bt_EquipmentKit?: EquipmentEquipmentKit;
  bt_EquipmentLocation?: EquipmentEquipmentLocation;
  bt_EquipmentRequestNo?: EquipmentEquipmentRequestHeader;
  bt_EquipmentSpec?: EquipmentEquipmentSpec;
  bt_EquipmentSpecType?: EquipmentEquipmentSpecType;
  bt_EquipmentType?: EquipmentEquipmentType;
  bt_EquipmentTypeClass?: EquipmentEquipmentTypeClass;
  bt_LegalEntity?: EquipmentEquipmentLegalEntity;
  bt_LinkedRecord?: EquipmentEquipmentRequestDetail;
  bt_Location?: EquipmentEquipmentLocation;
  bt_LocationEntity?: EquipmentEquipmentLegalEntity;
  bt_LocationGroup?: EquipmentEquipmentGroupLocation;
  bt_Originator?: EquipmentEquipmentEmployee;
  bt_OverrideSpec?: EquipmentEquipmentSpec;
  bt_PoolDepartment?: EquipmentEquipmentDepartment;
  bt_PoolDivision?: EquipmentEquipmentDivision;
  bt_PoolLegalEntity?: EquipmentEquipmentLegalEntity;
  bt_ProxyEntity?: EquipmentEquipmentLegalEntity;
  bt_ProxyUser?: EquipmentEquipmentEmployee;
  bt_RequestEquipmentItem?: EquipmentEquipmentRequestItem;
  bt_Seat?: EquipmentEquipmentPositionSeat;
  bt_allocationdate?: null | string;
  bt_allocationqty?: null | number;
  bt_allocationstatus?: EquipmentBtAllocationStatus;
  bt_approvalcomment?: null | string;
  bt_approvalstatus?: EquipmentBtApprovalStatus;
  bt_deactivatekititems?: null | boolean;
  bt_equipmentclassification?: EquipmentBtEquipmentClassification;
  bt_equipmentrequestdetailid?: null | string;
  bt_equipmenttypespecification?: null | string;
  bt_extendedlinebudget?: EquipmentMoney;
  bt_kititembudget?: EquipmentMoney;
  bt_leadtimedays?: null | number;
  bt_linetype?: EquipmentBtLineType;
  bt_motivation?: null | string;
  bt_name?: null | string;
  bt_newemployee?: null | boolean;
  bt_overrideapprovalcomment?: null | string;
  bt_overrideapproved?: null | boolean;
  bt_overridedefault?: null | boolean;
  bt_overrideprocessed?: null | boolean;
  bt_overridestatus?: EquipmentBtApprovalStatus;
  bt_pooltype?: EquipmentBtPoolType;
  bt_proxy?: null | boolean;
  bt_qty?: null | number;
  bt_reason?: null | string;
  bt_remainingqty?: null | number;
  bt_requiredbydate?: null | string;
  bt_split?: null | boolean;
  bt_stockkit?: null | boolean;
  bt_triggerdeactivation?: null | boolean;
  bt_triggeroverridereset?: null | boolean;
  statecode?: EquipmentBtEquipmentRequestDetailState;
}
