/* tslint:disable */
/* eslint-disable */
export interface SuccessionsManager {
  bt_internalavaible?: null | boolean;
  bt_lastreviewdate?: null | string;
  bt_readyin?: null | number;
  bt_successoridentified?: null | boolean;
  bt_successplanmanager?: null | string;
  bt_successplanmanagerid?: null | string;
  odataInternalAvailable?: null | string;
  odataReadyIn?: null | string;
  odataSuccessorAvailable?: null | string;
}
