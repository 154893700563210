/* tslint:disable */
/* eslint-disable */
import { ProfileEntityReferenceExtrapolate } from './profile-entity-reference-extrapolate';
import { ProfileBtDriversLicenseStatus } from './profile-bt-drivers-license-status';
import { ProfileBtEmployeeLifecycleStage } from './profile-bt-employee-lifecycle-stage';
import { ProfileBtEmployeeStatusEnum } from './profile-bt-employee-status-enum';
import { ProfileBthrJobSkill } from './profile-bthr-job-skill';
import { ProfileBthrRecordStatus } from './profile-bthr-record-status';
import { ProfileBthrTerminationreason } from './profile-bthr-terminationreason';
export interface ProfileVerdeEmployee {
  bt_azureobjectid?: null | string;
  bt_citizenshipstatus?: ProfileEntityReferenceExtrapolate;
  bt_costcentre?: ProfileEntityReferenceExtrapolate;
  bt_countryofresidence?: ProfileEntityReferenceExtrapolate;
  bt_defaultequipmentstore?: ProfileEntityReferenceExtrapolate;
  bt_defaultequipmentworkshop?: ProfileEntityReferenceExtrapolate;
  bt_defaultfleetlocation?: ProfileEntityReferenceExtrapolate;
  bt_defaultworklocation?: ProfileEntityReferenceExtrapolate;
  bt_district?: ProfileEntityReferenceExtrapolate;
  bt_driverslicense?: null | boolean;
  bt_driverslicenseexpirydate?: null | string;
  bt_driverslicensestatus?: ProfileBtDriversLicenseStatus;
  bt_employeeclassification?: ProfileEntityReferenceExtrapolate;
  bt_employeelifecyclestage?: ProfileBtEmployeeLifecycleStage;
  bt_employeestatus?: ProfileBtEmployeeStatusEnum;
  bt_enableforemployee?: null | boolean;
  bt_ethnicitylookup?: ProfileEntityReferenceExtrapolate;
  bt_fullname?: null | string;
  bt_genderlookup?: ProfileEntityReferenceExtrapolate;
  bt_geographicalregion?: ProfileEntityReferenceExtrapolate;
  bt_grouplocation?: ProfileEntityReferenceExtrapolate;
  bt_hassubordinates?: null | boolean;
  bt_ismanager?: null | boolean;
  bt_legalentityjoindate?: null | string;
  bt_nationality?: ProfileEntityReferenceExtrapolate;
  bt_neworganizationjoindate?: null | string;
  bt_noemail?: null | boolean;
  bt_occupationallevellookup?: ProfileEntityReferenceExtrapolate;
  bt_office?: ProfileEntityReferenceExtrapolate;
  bt_overridemanagerpayslipaccess?: null | boolean;
  bt_partnershipstatuslookup?: ProfileEntityReferenceExtrapolate;
  bt_paymentmethod?: ProfileEntityReferenceExtrapolate;
  bt_positionverde?: ProfileEntityReferenceExtrapolate;
  bt_probationenddate?: null | string;
  bt_probationmonths?: null | number;
  bt_probationtype?: ProfileEntityReferenceExtrapolate;
  bt_provincestate?: ProfileEntityReferenceExtrapolate;
  bt_racelookup?: ProfileEntityReferenceExtrapolate;
  bt_shifttype?: ProfileEntityReferenceExtrapolate;
  bt_skillsclassificationlookup?: ProfileEntityReferenceExtrapolate;
  bt_subordinates?: null | number;
  bt_tenantid?: null | string;
  bt_timeoffcycle1startdate?: null | string;
  bt_timeoffissues?: null | string;
  bt_title?: ProfileEntityReferenceExtrapolate;
  bt_verdesuboffice?: ProfileEntityReferenceExtrapolate;
  bthr_aduser?: null | boolean;
  bthr_crimicalrecord?: null | boolean;
  bthr_criminalrecordverified?: null | string;
  bthr_dateofbirth?: null | string;
  bthr_department?: ProfileEntityReferenceExtrapolate;
  bthr_disabilities?: null | boolean;
  bthr_division?: ProfileEntityReferenceExtrapolate;
  bthr_email?: null | string;
  bthr_employee_id?: null | string;
  bthr_employeeid?: null | string;
  bthr_employeephoto?: null | string;
  bthr_employeetype?: ProfileEntityReferenceExtrapolate;
  bthr_firstname?: null | string;
  bthr_foreignnational?: null | boolean;
  bthr_grade?: ProfileEntityReferenceExtrapolate;
  bthr_hometelephone?: null | string;
  bthr_initials?: null | string;
  bthr_jobskill?: ProfileBthrJobSkill;
  bthr_lastname?: null | string;
  bthr_lastworkday?: null | string;
  bthr_legalentity?: ProfileEntityReferenceExtrapolate;
  bthr_middlenames?: null | string;
  bthr_mobiletelephone?: null | string;
  bthr_nationalidnumber?: null | string;
  bthr_paypoint?: ProfileEntityReferenceExtrapolate;
  bthr_payrollnumber?: null | string;
  bthr_personalemail?: null | string;
  bthr_placeofbirthtext?: null | string;
  bthr_preferredname?: null | string;
  bthr_primarylocation?: ProfileEntityReferenceExtrapolate;
  bthr_recordstatus?: ProfileBthrRecordStatus;
  bthr_religioncode?: ProfileEntityReferenceExtrapolate;
  bthr_resignationdate?: null | string;
  bthr_seat?: ProfileEntityReferenceExtrapolate;
  bthr_taxnumber?: null | string;
  bthr_terminationnoticedate?: null | string;
  bthr_terminationreason?: ProfileBthrTerminationreason;
  bthr_timeoffprovisiondate?: null | string;
  bthr_triggertimeoffprovisioning?: null | boolean;
  bthr_user?: ProfileEntityReferenceExtrapolate;
}
