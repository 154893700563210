export interface UserCalendarEvents {
  value?: Array<{
    id?: string;
    subject?: string;
    start?: {
      dateTime?: Date;
      timeZone?: string;
    };
  }>;
}
