/* tslint:disable */
/* eslint-disable */
import { DocumentBtSalaryAdviceType } from './document-bt-salary-advice-type';
export interface DocumentDocSalaryAdviceDetail {
  bt_DocSalaryAdviceHeader?: null | string;
  bt_amount?: null | string;
  bt_docsalaryadvicedetail?: null | string;
  bt_docsalaryadvicedetailid?: null | string;
  bt_docsalaryadviceheader?: null | string;
  bt_salaryadvicetype?: DocumentBtSalaryAdviceType;
  bt_unit?: null | string;
}
