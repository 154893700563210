/* tslint:disable */
/* eslint-disable */
import { DocumentCountry } from './document-country';
export interface DocumentCountryTaxYear {
  bt_country?: DocumentCountry;
  bt_description?: null | string;
  bt_period?: null | number;
  bt_perioddescription?: null | string;
  bt_periodenddate?: null | string;
  bt_periodstartdate?: null | string;
  bt_taxyear?: null | string;
  bt_yearend?: null | string;
  bt_yearstart?: null | string;
}
