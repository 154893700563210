import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, AbstractControl, ValidationErrors } from '@angular/forms';
import { UserService } from '@verde/core';

import {
  VerdeApprovalService,
  ScriptLoaderService,
  CommonApiWebDynamicService,
  VerdeDynamicPageService,
  ModalService,
  //WarningModalComponent
} from '@verde/shared';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { FormlyJsonschema } from '@ngx-formly/core/json-schema';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { tap, takeUntil, take, count } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import moment from 'moment';
import * as $ from 'jquery';

@Component({
  selector: 'verde-dynamic-form2',
  templateUrl: './verde-dynamic-form.component.html',
  styleUrls: ['./verde-dynamic-form.component.scss'],
})

//Notepad :
//Unable to load the dynamic page: EntityId or FormName is not provided.
//btvsp_DynamicFormQuickCreateTest.js
//export function IpValidator(control: AbstractControl): ValidationErrors {
//  return /(\d{1,3}\.){3}\d{1,3}/.test(control.value) ? null : { 'ip': true };
//}

export class VerdeDynamicFormComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<boolean>();
  dynamicFormGroup: FormGroup;
  dynamicModel: JSON;
  additional: any;
  options: FormlyFormOptions;
  entityId: string;
  formName: string;
  dynamicFormfields: FormlyFieldConfig[];

  //Test
  formFields: FormlyFieldConfig[] = []; // Define a variable to hold Formly field configurations

  entityName: string;
  version: string;
  formType: string;
  data: [] = [];
  jsonData: JSON;
  getEntityIdValue: string = '';
  getFormNameValue: string = '';
  getFormTypeValue: string = '';

  //Spinner
  disableAnimation: boolean;

  //JSON variables
  schemaJSON: any;
  schemaGlobal: any;//  schemaGlobal: any[];//--------------------------------------------------------------------------------------------
  entityIdGlobal: string;

  modelJSON: any;
  jsonDynamicAdditionalData: any;
  globalDataTypeList: { [key: string]: { [key: string]: string } } = {};

  //Notification
  notificationMessage: string = '';
  notificationActive: boolean = false;
  notificationTitle: string = '';
  notificationButton: string = '';

  //Negate Request Data Asynch
  modelDataRequestCompleted: Boolean = false;
  lookupDataRequestCompleted: Boolean = false;

  notification(title: string, message: string, button?: string) {
    this.notificationMessage = message;
    this.notificationTitle = title;
    this.notificationButton = button == null ? 'Continue' : button;
    this.notificationActive = true;
  }

  closeNotification() {
    this.notificationActive = false;
  }

  constructor(
    public userService: UserService,
    private formlyJsonschema: FormlyJsonschema,
    private http: HttpClient,
    private commonApiCommonService: CommonApiWebDynamicService,
    private route: ActivatedRoute,
    private verdeDynamicPageService: VerdeDynamicPageService,
    private scriptLoaderService: ScriptLoaderService,
    private spinner: NgxSpinnerService,
    private sidebarService: VerdeApprovalService,
    private modalService: ModalService,
  ) {

    // Gets the Entity Id Value from where this page is called
    this.verdeDynamicPageService
      .getFormNameAndEntityId()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((item) => {
        if (Object.keys(item).length > 0) {

          if (({ ...item }[0] ?? '') != '' && ({ ...item }[1] ?? '') != '') {
            this.getEntityIdValue = { ...item }[0].toString();
            this.getFormNameValue = { ...item }[1].toString();
            this.getFormTypeValue = { ...item }[2].toString();
            //this.loadDynamicFormScript();
            //this.verdeDynamicPageService.clearFormNameAndEntityId(); //
            this.loadPage();
          } else {
            this.notificationMessage = "Unable to load the dynamic page: EntityId or FormName is not provided";
            this.modalService.open("errorModal");
            //debugger
          }
        } else {
          //debugger
          this.notificationMessage = "Failed to load data, and if the issue persists, please log a support request";
          this.modalService.open("errorModal")
        }
      },
        (complete?) => {
          //debugger
        });

    // Gets the form names value from where this page is called

    //if ((this.getEntityIdValue ?? "") != "" || (this.formName ?? "") != "") {

    //  //this.loadDynamicFormScript();
    //  this.loadPage();
    //} else {

    //  this.notification("Error", "Unable to load the dynamic page: EntityId or FormName is not provided", "Continue");
    //}
  }

  ngOnInit(): void {
  }

  loadDynamicFormScript() {
    //This loads the JS file
    this.scriptLoaderService
      .loadScript('btvsp_PositionSeatRules', 'assets/js/dynamic-forms/btvsp_PositionSeatRules.js')
      .then((data) => {
        this.loadPage();
        //console.log("JavascriptLoaded", data)
      })
      .catch((error) => console.error('Script loading error:', error));
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  closeVerdeDynamicModal() {
    if (this.getFormTypeValue == "sidepanel") {
      this.sidebarService.setShowSidebar(false);
    }
  }

  loadPage() {


    var jsonDynamicModelData: JSON;
    var filteredSchema;


    this.spinner.show('dynamicFormSpinner');

    if ((this.getFormNameValue ?? '') != '' && this.getFormNameValue != '' && this.getFormNameValue != null && this.getFormNameValue != undefined) {

      //Loads JSON data for the form layout and gets any other requred data from it
      this.http
        .get<any>('/assets/json/dynamic-forms/' + this.getFormNameValue + '.json')
        .pipe(
          tap(({ tabs }) => {
            this.verdeDynamicPageService.setFormExtraInfoGlobal(".png")

            if ((tabs ?? "") != "") {

              var tabsVar = { ...tabs } as Object;

              // This code underneath loads the first tab from the JSON and is used for testing (i.e. if data isn't available from the database)
              //this.renderForm(tabsVar["tab_1"]?.schema, tabsVar["tab_1"]?.model, tabsVar["tab_1"]?.additional);


              this.entityIdGlobal = tabsVar["tab_1"]?.additional?.entityId;


              // loops through tabs

              for (let tabLoop in { ...tabsVar }) {
                var jsonDynamicModelData: JSON;
                var filteredSchema;


                if (this.getFormTypeValue == "sidepanel") {
                  this.sidebarService.setTitleTag({ ...tabsVar }[tabLoop.toString()]["schema"]["title"]?.toString());

                }

                tabsVar[tabLoop.toString()].schema.title = null;

                this.renderForm(tabsVar[tabLoop.toString()].schema, tabsVar[tabLoop.toString()].model, tabsVar[tabLoop.toString()].additional);

                //this.renderForm(schema, {}, additional) //--------------------------------------------

                this.globalDataTypeList[tabLoop.toString()] = null;
                this.globalDataTypeList[tabLoop.toString()] = null;

                var dataTypeList: { [key: string]: string } = {};
                //var expressionsList: { [key: string]: { [key: string]: string } } = { };

                //0 = form Name, 1 = description, 2 = title, 3 =  type, 4 = data array
                //schema section in JSON file
                filteredSchema = { ...tabsVar[tabLoop.toString()].schema };
                //filteredSchema.properties = {};

                this.renderForm(tabsVar[tabLoop.toString()].schema, tabsVar[tabLoop.toString()].model, tabsVar[tabLoop.toString()].additional);


                if (this.getFormTypeValue == "sidepanel") {
                  this.sidebarService.setSidebarSize(Number(tabsVar[tabLoop.toString()].additional.formWidth ?? "50"));
                }

                var counterSectionsLoop = 0;

                if (this.getFormTypeValue != "Grid") {
                  for (let sectionsLoop in { ...tabsVar[tabLoop.toString()].schema.properties }) {
                    var counterFieldsLoop = 0;
                    counterSectionsLoop = counterSectionsLoop + 1;
                    jsonDynamicModelData = { ...jsonDynamicModelData, [sectionsLoop.toString()]: {} };
                    //loops through fields
                    for (let fieldsLoop in { ...filteredSchema.properties }[sectionsLoop].properties) {
                      counterFieldsLoop = counterFieldsLoop + 1;
                      //debugger
                      dataTypeList = this.globalDataTypeList[tabLoop.toString() ?? ""] = { ...dataTypeList, [fieldsLoop.toString()]: { ...tabsVar[tabLoop.toString()].schema?.properties }[sectionsLoop]?.properties[fieldsLoop]?.templateOptions?.type?.toString() };
                      jsonDynamicModelData[sectionsLoop][fieldsLoop] = "";

                      //expressionsList = { ...expressionsList, [k.toString()]: { ...tabsVar[tabLoop.toString()].schema?.properties }[sectionsLoop]?.properties[k].expressions };



                      if (({ ...tabsVar[tabLoop.toString()].schema?.properties }[sectionsLoop]?.properties[fieldsLoop]?.type?.toString() == "select" || { ...tabsVar[tabLoop.toString()].schema?.properties }[sectionsLoop]?.properties[fieldsLoop]?.templateOptions?.type?.toString() == "lookup") && ({ ...tabsVar[tabLoop.toString()].schema?.properties }[sectionsLoop]?.properties[fieldsLoop]?.templateOptions?.additionalProperties?.defaultViewId?.toString() ?? "") != "") {

                        this.commonApiCommonService
                          .dynamicFormLookupData(
                            {
                              body: {
                                defaultViewId: { ...tabsVar[tabLoop.toString()].schema?.properties }[sectionsLoop]?.properties[fieldsLoop]?.templateOptions?.additionalProperties?.defaultViewId?.toString(),
                              }
                            }
                          )
                          .pipe(take(1))
                          .subscribe((dynamicFormLookupResponse) => {

                            if ((dynamicFormLookupResponse ?? "") != "" && dynamicFormLookupResponse != undefined) {

                              let parsedJsonLookupResponse = JSON.parse(dynamicFormLookupResponse);

                              if (!tabsVar[tabLoop.toString()].schema.properties[sectionsLoop].properties[fieldsLoop].hasOwnProperty("oneOf")) {
                                tabsVar[tabLoop.toString()].schema.properties[sectionsLoop].properties[fieldsLoop].oneOf = [];
                                tabsVar[tabLoop.toString()].schema.properties[sectionsLoop].properties[fieldsLoop].oneOf.push(...parsedJsonLookupResponse.oneOf);
                              }
                              else {
                                tabsVar[tabLoop.toString()].schema.properties[sectionsLoop].properties[fieldsLoop].oneOf.push(...parsedJsonLookupResponse.oneOf);
                              }
                            }

                            //Only runs on on the last cycle of for schema.properties loop

                            this.renderForm(filteredSchema, jsonDynamicModelData, tabsVar[tabLoop.toString()].additional)


                            let tabLoopTemp = tabLoop;
                            let counterFieldsLoopTemp = counterFieldsLoop;
                            let sectionsLoopTemp = sectionsLoop;
                            let counterSectionsLoopTemp = counterSectionsLoop;

                            //Gets the fields default data to be loaded as default on items in front end
                            if ((Object.keys(tabsVar[tabLoopTemp.toString()].schema?.properties[sectionsLoopTemp].properties).length <= counterFieldsLoopTemp) && Object.keys(tabsVar[tabLoopTemp.toString()].schema.properties).length <= counterSectionsLoopTemp) {

                             //debugger
                              this.commonApiCommonService
                                .dynamicFormData(
                                  {
                                    body: {
                                      "entityName": Object.assign({ ...tabsVar[tabLoop.toString()].additional })?.entityName,
                                      "entityId": this.getEntityIdValue?.toString(),
                                      "requestData": {
                                        "dataTypes": dataTypeList
                                      }
                                    }
                                  }
                                )
                                .pipe(take(1))
                                .subscribe((modelResponse) => {
                                  //debugger
                                  if ((modelResponse ?? "") != "") {
                                   //debugger
                                    JSON.parse(modelResponse).ResponseModel.forEach((item) => {

                                      for (let modelResponseSectionsLoop in { ...tabsVar[tabLoop.toString()].schema.properties }) {
                                        for (let modelResponseLoop in item) {

                                          //Grabs relevant information from lookup array for front end data population

                                          if (Object.keys({ ...jsonDynamicModelData[modelResponseSectionsLoop] }).indexOf(modelResponseLoop) > -1 && (dataTypeList[modelResponseLoop.toString()].toString() == "lookup" || dataTypeList[modelResponseLoop.toString()].toString() == "picklist") && (({ ...tabsVar[tabLoop.toString()].schema?.properties }[modelResponseSectionsLoop]?.properties[modelResponseLoop.toString()]?.templateOptions?.additionalProperties?.defaultViewId?.toString()) ?? "") != "") {


                                            jsonDynamicModelData[modelResponseSectionsLoop][modelResponseLoop] = item[modelResponseLoop]["Id"];
                                          } else if (Object.keys({ ...jsonDynamicModelData[modelResponseSectionsLoop] }).indexOf(modelResponseLoop) > -1 && (dataTypeList[modelResponseLoop.toString()].toString() == "lookup" || dataTypeList[modelResponseLoop.toString()].toString() == "picklist") && (({ ...tabsVar[tabLoop.toString()].schema?.properties }[modelResponseSectionsLoop]?.properties[modelResponseLoop.toString()]?.templateOptions?.additionalProperties?.defaultViewId?.toString()) ?? "") == "") {

                                            jsonDynamicModelData[modelResponseSectionsLoop][modelResponseLoop] = item[modelResponseLoop];
                                          } else if (Object.keys({ ...jsonDynamicModelData[modelResponseSectionsLoop] }).indexOf(modelResponseLoop) > -1 && (tabsVar[tabLoop.toString()].schema?.properties[modelResponseSectionsLoop]?.properties[modelResponseLoop.toString()]?.type?.toString() == "input" && dataTypeList[modelResponseLoop.toString()].toString() == "date")) {
                                            //If a date is placed in a text field instead of a datepicker it converts it into the correct format
                                            jsonDynamicModelData[modelResponseSectionsLoop][modelResponseLoop] = moment(item[modelResponseLoop]).format('yyyy/MM/DD').toString()

                                          } else if (Object.keys({ ...jsonDynamicModelData[modelResponseSectionsLoop] }).indexOf(modelResponseLoop) > -1 && (tabsVar[tabLoop.toString()].schema?.properties[modelResponseSectionsLoop]?.properties[modelResponseLoop.toString()]?.type?.toString() == "date" && dataTypeList[modelResponseLoop.toString()].toString() == "date")) {
                                            //Formats for datepicker, adds date type object instead of string
                                            jsonDynamicModelData[modelResponseSectionsLoop][modelResponseLoop] = new Date(item[modelResponseLoop]) ?? null

                                          } else if (Object.keys({ ...jsonDynamicModelData[modelResponseSectionsLoop] }).indexOf(modelResponseLoop) > -1) {

                                            jsonDynamicModelData[modelResponseSectionsLoop][modelResponseLoop] = item[modelResponseLoop];
                                          }

                                          //for (let i in { ...tabsVar[tabLoop.toString()].schema.properties }) {
                                          //  console.log(i)
                                          //  jsonDynamicModelData[i] = { ...jsonDynamicModelData[sectionsLoop] }
                                          //}

                                          // Insures that only items in database gets loaded
                                          //filteredSchema.properties[j.toString()] = schema?.properties[j.toString()]


                                        }
                                      }
                                      this.spinner.hide('dynamicFormSpinner');
                                      //debugger

                                    },
                                      (error) => {
                                        //debugger
                                        this.spinner.hide('dynamicFormSpinner');
                                        this.notificationMessage = "Failed to load data, and if the issue persists, please log a support request";
                                        this.modalService.open("errorModal");
                                      },
                                      (complete) => {
                                        //debugger
                                        this.spinner.hide('dynamicFormSpinner');
                                      }

                                    );
                                  }
                                  else {
                                    this.spinner.hide('dynamicFormSpinner');
                                    //debugger
                                  }
                                  filteredSchema.properties[sectionsLoop].properties[fieldsLoop] = { ...filteredSchema?.properties[sectionsLoop].properties[fieldsLoop], ...tabsVar[tabLoop.toString()].schema?.properties[sectionsLoop].properties[fieldsLoop] }

                                  this.modelDataRequestCompleted = true;

                                  this.renderForm(filteredSchema, jsonDynamicModelData, tabsVar[tabLoop.toString()].additional)
                                  //debugger
                                },

                                  (error) => {
                                    //debugger
                                    this.spinner.hide('dynamicFormSpinner');
                                    this.notificationMessage = "Failed to load data, and if the issue persists, please log a support request";
                                    this.modalService.open("errorModal");
                                  },
                                  (complete?) => {
                                    //debugger
                                    this.spinner.hide('dynamicFormSpinner');
                                  }
                                )
                            } else {
                              //debugger
                            }
                          },

                            (error) => {
                              //debugger
                              this.spinner.hide('dynamicFormSpinner');
                              this.notificationMessage = "Failed to load data, and if the issue persists, please log a support request";
                              this.modalService.open("errorModal");
                            });

                      } else {
                        if ((Object.keys(tabsVar[tabLoop.toString()].schema?.properties[sectionsLoop].properties).length <= counterFieldsLoop) && Object.keys(tabsVar[tabLoop.toString()].schema.properties).length <= counterSectionsLoop) {

                         //debugger
                          this.commonApiCommonService
                            .dynamicFormData(
                              {
                                body: {
                                  "entityName": Object.assign({ ...tabsVar[tabLoop.toString()].additional })?.entityName,
                                  "entityId": this.getEntityIdValue?.toString(),
                                  "requestData": {
                                    "dataTypes": dataTypeList
                                  }
                                }
                              }
                            )
                            .pipe(take(1))
                            .subscribe((modelResponse) => {
                             //debugger

                              //debugger
                              if ((modelResponse ?? "") != "") {

                                JSON.parse(modelResponse).ResponseModel.forEach((item) => {

                                  for (let modelResponseSectionsLoop in { ...tabsVar[tabLoop.toString()].schema.properties }) {
                                    for (let modelResponseLoop in item) {

                                      //Grabs relevant information from lookup array for front end data population

                                      if (Object.keys({ ...jsonDynamicModelData[modelResponseSectionsLoop] }).indexOf(modelResponseLoop) > -1 && (dataTypeList[modelResponseLoop.toString()].toString() == "lookup" || dataTypeList[modelResponseLoop.toString()].toString() == "picklist") && (({ ...tabsVar[tabLoop.toString()].schema?.properties }[modelResponseSectionsLoop]?.properties[modelResponseLoop.toString()]?.templateOptions?.additionalProperties?.defaultViewId?.toString()) ?? "") != "") {


                                        jsonDynamicModelData[modelResponseSectionsLoop][modelResponseLoop] = item[modelResponseLoop]["Id"];
                                      } else if (Object.keys({ ...jsonDynamicModelData[modelResponseSectionsLoop] }).indexOf(modelResponseLoop) > -1 && (dataTypeList[modelResponseLoop.toString()].toString() == "lookup" || dataTypeList[modelResponseLoop.toString()].toString() == "picklist") && (({ ...tabsVar[tabLoop.toString()].schema?.properties }[modelResponseSectionsLoop]?.properties[modelResponseLoop.toString()]?.templateOptions?.additionalProperties?.defaultViewId?.toString()) ?? "") == "") {

                                        jsonDynamicModelData[modelResponseSectionsLoop][modelResponseLoop] = item[modelResponseLoop];
                                      } else if (Object.keys({ ...jsonDynamicModelData[modelResponseSectionsLoop] }).indexOf(modelResponseLoop) > -1 && (tabsVar[tabLoop.toString()].schema?.properties[modelResponseSectionsLoop]?.properties[modelResponseLoop.toString()]?.type?.toString() == "input" && dataTypeList[modelResponseLoop.toString()].toString() == "date")) {
                                        //If a date is placed in a text field instead of a datepicker it converts it into the correct format
                                        jsonDynamicModelData[modelResponseSectionsLoop][modelResponseLoop] = moment(item[modelResponseLoop]).format('yyyy/MM/DD').toString()

                                      } else if (Object.keys({ ...jsonDynamicModelData[modelResponseSectionsLoop] }).indexOf(modelResponseLoop) > -1 && (tabsVar[tabLoop.toString()].schema?.properties[modelResponseSectionsLoop]?.properties[modelResponseLoop.toString()]?.type?.toString() == "date" && dataTypeList[modelResponseLoop.toString()].toString() == "date")) {
                                        //Formats for datepicker, adds date type object instead of string
                                        jsonDynamicModelData[modelResponseSectionsLoop][modelResponseLoop] = new Date(item[modelResponseLoop]) ?? null

                                      } else if (Object.keys({ ...jsonDynamicModelData[modelResponseSectionsLoop] }).indexOf(modelResponseLoop) > -1) {

                                        jsonDynamicModelData[modelResponseSectionsLoop][modelResponseLoop] = item[modelResponseLoop];
                                      }

                                      //for (let i in { ...tabsVar[tabLoop.toString()].schema.properties }) {
                                      //  console.log(i)
                                      //  jsonDynamicModelData[i] = { ...jsonDynamicModelData[sectionsLoop] }
                                      //}

                                      // Insures that only items in database gets loaded
                                      //filteredSchema.properties[j.toString()] = schema?.properties[j.toString()]


                                    }
                                  }
                                  this.spinner.hide('dynamicFormSpinner');
                                  //debugger

                                },
                                  (error) => {
                                    //debugger
                                    this.spinner.hide('dynamicFormSpinner');
                                    this.notificationMessage = "Failed to load data, and if the issue persists, please log a support request";
                                    this.modalService.open("errorModal");
                                  },
                                  (complete) => {
                                    //debugger
                                    this.spinner.hide('dynamicFormSpinner');
                                  }

                                );
                              }
                              else {
                                this.spinner.hide('dynamicFormSpinner');
                                //debugger
                              }
                              filteredSchema.properties[sectionsLoop].properties[fieldsLoop] = { ...filteredSchema?.properties[sectionsLoop].properties[fieldsLoop], ...tabsVar[tabLoop.toString()].schema?.properties[sectionsLoop].properties[fieldsLoop] }

                              this.modelDataRequestCompleted = true;

                              this.renderForm(filteredSchema, jsonDynamicModelData, tabsVar[tabLoop.toString()].additional)
                              //debugger
                            },

                              (error) => {
                                //debugger
                                this.spinner.hide('dynamicFormSpinner');
                                this.notificationMessage = "Failed to load data, and if the issue persists, please log a support request";
                                this.modalService.open("errorModal");
                              },
                              (complete?) => {
                                //debugger
                                this.spinner.hide('dynamicFormSpinner');
                              }
                            )
                        } else {
                          //debugger
                        }
                        //debugger
                      }



                    }
                    //debugger
                    if (((filteredSchema.properties[sectionsLoop].properties ?? "") == "" || Object.keys(filteredSchema.properties[sectionsLoop].properties).length == 0) && Object.keys(tabsVar[tabLoop.toString()].schema.properties).length <= counterSectionsLoop) {

                     //debugger
                      this.commonApiCommonService
                        .dynamicFormData(
                          {
                            body: {
                              "entityName": Object.assign({ ...tabsVar[tabLoop.toString()].additional })?.entityName,
                              "entityId": this.getEntityIdValue?.toString(),
                              "requestData": {
                                "dataTypes": dataTypeList
                              }
                            }
                          }
                        )
                        .pipe(take(1))
                        .subscribe((modelResponse) => {

                         //debugger
                          //debugger
                          if ((modelResponse ?? "") != "") {

                            JSON.parse(modelResponse).ResponseModel.forEach((item) => {

                              for (let modelResponseSectionsLoop in { ...tabsVar[tabLoop.toString()].schema.properties }) {
                                for (let modelResponseLoop in item) {

                                  //Grabs relevant information from lookup array for front end data population

                                  if (Object.keys({ ...jsonDynamicModelData[modelResponseSectionsLoop] }).indexOf(modelResponseLoop) > -1 && (dataTypeList[modelResponseLoop.toString()].toString() == "lookup" || dataTypeList[modelResponseLoop.toString()].toString() == "picklist") && (({ ...tabsVar[tabLoop.toString()].schema?.properties }[modelResponseSectionsLoop]?.properties[modelResponseLoop.toString()]?.templateOptions?.additionalProperties?.defaultViewId?.toString()) ?? "") != "") {


                                    jsonDynamicModelData[modelResponseSectionsLoop][modelResponseLoop] = item[modelResponseLoop]["Id"];
                                  } else if (Object.keys({ ...jsonDynamicModelData[modelResponseSectionsLoop] }).indexOf(modelResponseLoop) > -1 && (dataTypeList[modelResponseLoop.toString()].toString() == "lookup" || dataTypeList[modelResponseLoop.toString()].toString() == "picklist") && (({ ...tabsVar[tabLoop.toString()].schema?.properties }[modelResponseSectionsLoop]?.properties[modelResponseLoop.toString()]?.templateOptions?.additionalProperties?.defaultViewId?.toString()) ?? "") == "") {

                                    jsonDynamicModelData[modelResponseSectionsLoop][modelResponseLoop] = item[modelResponseLoop];
                                  } else if (Object.keys({ ...jsonDynamicModelData[modelResponseSectionsLoop] }).indexOf(modelResponseLoop) > -1 && (tabsVar[tabLoop.toString()].schema?.properties[modelResponseSectionsLoop]?.properties[modelResponseLoop.toString()]?.type?.toString() == "input" && dataTypeList[modelResponseLoop.toString()].toString() == "date")) {
                                    //If a date is placed in a text field instead of a datepicker it converts it into the correct format
                                    jsonDynamicModelData[modelResponseSectionsLoop][modelResponseLoop] = moment(item[modelResponseLoop]).format('yyyy/MM/DD').toString()

                                  } else if (Object.keys({ ...jsonDynamicModelData[modelResponseSectionsLoop] }).indexOf(modelResponseLoop) > -1 && (tabsVar[tabLoop.toString()].schema?.properties[modelResponseSectionsLoop]?.properties[modelResponseLoop.toString()]?.type?.toString() == "date" && dataTypeList[modelResponseLoop.toString()].toString() == "date")) {
                                    //Formats for datepicker, adds date type object instead of string
                                    jsonDynamicModelData[modelResponseSectionsLoop][modelResponseLoop] = new Date(item[modelResponseLoop]) ?? null

                                  } else if (Object.keys({ ...jsonDynamicModelData[modelResponseSectionsLoop] }).indexOf(modelResponseLoop) > -1) {

                                    jsonDynamicModelData[modelResponseSectionsLoop][modelResponseLoop] = item[modelResponseLoop];
                                  }

                                  //for (let i in { ...tabsVar[tabLoop.toString()].schema.properties }) {
                                  //  console.log(i)
                                  //  jsonDynamicModelData[i] = { ...jsonDynamicModelData[sectionsLoop] }
                                  //}

                                  // Insures that only items in database gets loaded
                                  //filteredSchema.properties[j.toString()] = schema?.properties[j.toString()]


                                }
                              }
                              this.spinner.hide('dynamicFormSpinner');
                              //debugger

                            },
                              (error) => {
                                //debugger
                                this.spinner.hide('dynamicFormSpinner');
                                this.notificationMessage = "Failed to load data, and if the issue persists, please log a support request";
                                this.modalService.open("errorModal");
                              },
                              (complete) => {
                                //debugger
                                this.spinner.hide('dynamicFormSpinner');
                              }

                            );
                          }
                          else {
                            this.spinner.hide('dynamicFormSpinner');
                            //debugger
                          }
                          //filteredSchema.properties[sectionsLoop].properties[fieldsLoop] = { ...filteredSchema?.properties[sectionsLoop].properties[fieldsLoop], ...tabsVar[tabLoop.toString()].schema?.properties[sectionsLoop].properties[fieldsLoop] }


                          this.modelDataRequestCompleted = true;

                          this.renderForm(filteredSchema, jsonDynamicModelData, tabsVar[tabLoop.toString()].additional)
                          //debugger
                        },

                          (error) => {
                            //debugger
                            this.spinner.hide('dynamicFormSpinner');
                            this.notificationMessage = "Failed to load data, and if the issue persists, please log a support request";
                            this.modalService.open("errorModal");
                          },
                          (complete?) => {
                            //debugger
                            this.spinner.hide('dynamicFormSpinner');
                          }
                        )

                      //debugger
                      this.spinner.hide('dynamicFormSpinner');
                    }
                  }
                }
                //loops through sections

              }

            }
            else {
              this.spinner.hide('dynamicFormSpinner');
              this.notificationMessage = "Failed to load data, and if the issue persists, please log a support request";
              this.modalService.open("errorModal");
            }

          },
            (error) => {
              //debugger
              this.spinner.hide('dynamicFormSpinner');
              this.notificationMessage = "Failed to load data, and if the issue persists, please log a support request";
              this.modalService.open("errorModal");
            }
          ),
          takeUntil(this.onDestroy$),
        )
        .subscribe();
    }
    else {
      //debugger
      this.notificationMessage = "Failed to load data, and if the issue persists, please log a support request";
      this.spinner.hide('dynamicFormSpinner');
      this.modalService.open("errorModal")
    }

    scripts: ['./btvsp_DynamicFormQuickCreateTest.js'];
  }

  //Test method
  private transformJsonToFormlyFields(jsonData: any): FormlyFieldConfig[] {
    const formlyFields: FormlyFieldConfig[] = [];

    // Implement transformation logic based on your JSON structure
    // Example transformation logic for Kendo UI components
    for (const tab of jsonData.schema.tabs) {
      for (const section of tab.sections) {
        for (const field of section.fields) {
          let formlyField: FormlyFieldConfig = {
            key: field.key,
            type: 'input', // Default type, adjust based on field.type or other conditions
            templateOptions: {
              label: field.title,
              placeholder: field.templateOptions.placeholder,
              appearance: 'outline', // Example: Use Kendo UI appearance
            },
          };

          // Adjust formlyField based on field.type and other properties

          // Example: Use Kendo UI components for specific field types
          if (field.type === 'select') {
            formlyField.type = 'kendo-dropdown';
            formlyField.templateOptions.options = field.templateOptions.additionalProperties.entityLocalName;
          }

          formlyFields.push(formlyField);
        }
      }
    }

    return formlyFields;
  }

  renderForm(schema?, model?, additional?) {



    if ((this.modelDataRequestCompleted == true || 1 == 1 && this.lookupDataRequestCompleted) || 1 == 1) {
      //debugger
      this.options = {};
      this.options.formState = []


      //Populates dynamic form html
      this.dynamicFormGroup = new FormGroup({});
      (schema ?? '') == '' ? (this.dynamicFormfields = this.dynamicFormfields) : (this.dynamicFormfields = [this.formlyJsonschema.toFieldConfig(schema)]);

      // Changes data where specific datatype is needed:
      for (let i in this.globalDataTypeList["tab_1"]) {//dynamicModel
        for (let j in model) {
          //debugger
          if (this.globalDataTypeList["tab_1"][i] == 'file' && Object.keys(model[j]).indexOf(i) > -1) {
            //debugger //----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
            (this.options ?? '') == '' ? null : (this.options.formState).push(j);
            this.options.formState[j] = [];
            (this.options[j] ?? '') == '' ? null : (this.options.formState[j]).push(i);
            this.options.formState[j][i] = this.schemaGlobal?.properties[j?.toString()]?.properties[i?.toString()]?.templateOptions;
            //debugger
          }


        }
      }

      //debugger;
      //console.log("dynamicModel", (this.dynamicModel ?? "") != '' ? this.dynamicModel['section_02DECBA0-3AE2-484D-A19F-C5353AF4F4F2']?.bt_newseat : "" ); // for expressions if wanted
      (model ?? '') == '' ? (this.dynamicModel = this.dynamicModel) : (this.dynamicModel = model);
      (additional ?? '') == '' ? (this.data = this.data) : (this.data = Object.assign([], additional));
      this.schemaGlobal = schema;
      //console.log('schema', this.formlyJsonschema.toFieldConfig(schema))

      //this.loadDynamicFormScript();
    }
  }

  openSubmitModal() {
    this.notificationMessage = "Please confirm that you would like to submit";
    this.modalService.open("confirmModal");
  }

  //convert string to blob
  stringToBlob(base64String: string) {
    const binaryData = atob(base64String ?? '');
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }

    const blob = new Blob([uint8Array], { type: 'application/pdf' });

    return blob;
  }

  public fileToBase64String(file: File) {
    return file.toString().replace('data:', '').replace(/^.+,/, '').replace('on/pdf;base64,', '');
  }

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject('');
    });
  }

  submit() {
    let listTypes: { [key: string]: string } = {};
    let listData: { [key: string]: string } = {};
    let listEntityLocalNames: { [key: string]: string } = {};
    var tempDynamicModel: { [key: string]: string } = {};
    //debugger
    for (let j in this.dynamicModel) {
      for (let i in this.dynamicModel[j]) {
        if (({ ... { ...this.schemaGlobal.properties }[j]?.properties }[i]?.readOnly ?? true) == false) {
          tempDynamicModel[i.toString()] = this.dynamicModel[j][i]?.toString();

          if ({ ...this.schemaGlobal.properties[j?.toString()] }.properties[i?.toString()]?.templateOptions?.type?.toString() == 'lookup' ||
            { ...this.schemaGlobal.properties[j?.toString()] }.properties[i?.toString()]?.templateOptions?.type?.toString() == 'picklist') {
            listEntityLocalNames = {
              ...listEntityLocalNames,
              [i.toString()]: { ...this.schemaGlobal.properties[j?.toString()] }.properties[i?.toString()]?.templateOptions?.additionalProperties?.entityLocalName,
            };

          }

          //if ({ ...this.schemaGlobal.properties[j?.toString()] }.properties[i?.toString()]?.type?.toString() == 'file') {


          //  console.log("uploadDynamicFormFile", {
          //    AttributeID: this.getEntityIdValue?.toString(),
          //    AttributeName: i?.toString(),
          //    EncryptionTypes: { ...this.schemaGlobal.properties[j?.toString()] }.properties[i?.toString()]?.templateOptions?.additionalProperties?.encryptionTypes,
          //    EntityName: "bthr_employee",
          //    Files: [this.dynamicFormGroup?.value[j?.toString()][i?.toString()]],
          //    Overwrite: { ...this.schemaGlobal.properties[j?.toString()] }.properties[i?.toString()]?.templateOptions?.additionalProperties?.overwrite,
          //  })

          //  this.commonApiCommonService
          //    .uploadDynamicFormFile({
          //      body: {
          //        AttributeID: this.getEntityIdValue?.toString(),
          //        AttributeName: i?.toString(),
          //        EncryptionTypes: { ...this.schemaGlobal.properties[j?.toString()] }.properties[i?.toString()]?.templateOptions?.additionalProperties?.encryptionTypes,
          //        EntityName: "bthr_employee",
          //        Files: [this.dynamicFormGroup?.value[j?.toString()][i?.toString()]],
          //        Overwrite: { ...this.schemaGlobal.properties[j?.toString()] }.properties[i?.toString()]?.templateOptions?.additionalProperties?.overwrite,
          //      },
          //    })
          //    .pipe()
          //    .subscribe(
          //      (value) => {
          //        if (value == 'Successfully') {
          //        } else {
          //          this.notificationMessage = "Please try again, and if the issue persists, please log a support request";
          //          this.modalService.open("errorModal");
          //        }
          //      },

          //      (error) => {
          //        this.notificationMessage = "Please try again, and if the issue persists, please log a support request";
          //        console.log("ERROR_uploadDynamicFormFile", error);
          //        this.modalService.open("errorModal");
          //      },
          //    );

          //}

          listTypes = {
            ...listTypes, [i.toString()]: { ...this.schemaGlobal.properties[j] }.properties[i.toString()]?.templateOptions?.type?.toString(),
          };

        }
      };
    }







    ////this.dynamicModel.forEach(field => { console.log(`Key:${field.key}, Value:${this.model[field.key]}`); }); --x--x--x


    //tempDynamicModel.forEach((element) => {
    //  //schemaGlobal

    //  console.log('tempArrayDynamicModel element', element);

    //  this.dynamicFormfields[0]?.fieldGroup?.forEach((element2) => {
    //    console.log('tempArrayDynamicModel element2', element2);

    //    if ({ ...this.schemaGlobal.properties }[element[0]?.toString()]?.templateOptions?.type?.toString() == 'lookup' ||
    //      { ...this.schemaGlobal.properties }[element[0]?.toString()]?.templateOptions?.type?.toString() == 'picklist') {
    //      listEntityLocalNames = {
    //        ...listEntityLocalNames,
    //        [element[0]?.toString()]: { ...this.schemaGlobal.properties }[element[0]?.toString()]?.templateOptions?.additionalProperties?.entityLocalName,
    //      };

    //      console.log('dropdown');
    //    }

    //    if (element2?.key == element[0] && (element[0]?.toString ?? '') != '' && (element2?.type?.toString() ?? '') != '') {
    //      listTypes = {
    //        ...listTypes, [element[0]?.toString()]: { ...this.schemaGlobal.properties }[element[0]?.toString()]?.templateOptions?.type?.toString(),
    //      };
    //      console.log('listData element[0]', element[0]);
    //    }
    //  });
    //});

    if (this.dynamicFormGroup.valid) {

      // Check FormGroup validity
      this.commonApiCommonService
        .submitDynamicForm({
          body: {
            entityId: this.getEntityIdValue?.toString(),
            entityName: this.data['entityName'],
            version: this.data['version'],
            formType: this.data['formType'],
            requestData: {
              data: tempDynamicModel,
              dataTypes: listTypes,
              entityLocalNames: listEntityLocalNames,
            },
          },
        })
        .pipe()
        .subscribe(
          (value) => {

            if (value.toLowerCase() == 'true') {
              if (Object.values({ ...this.globalDataTypeList["tab_1"] }).indexOf("file") > -1) {


                for (let j in this.dynamicModel) {
                  for (let i in this.dynamicModel[j]) {
                    if (({ ... { ...this.schemaGlobal.properties }[j]?.properties }[i]?.readOnly ?? true) == false) {

                      if ({ ...this.schemaGlobal.properties[j?.toString()] }.properties[i?.toString()]?.type?.toString() == 'file') {
                        this.commonApiCommonService
                          .uploadDynamicFormFile({
                            body: {
                              AttributeID: this.getEntityIdValue?.toString(),
                              AttributeName: i?.toString(),
                              EncryptionTypes: { ...this.schemaGlobal.properties[j?.toString()] }.properties[i?.toString()]?.templateOptions?.additionalProperties?.encryptionTypes,
                              EntityName: "bthr_employee",
                              Files: [this.dynamicFormGroup?.value[j?.toString()][i?.toString()]],
                              Overwrite: { ...this.schemaGlobal.properties[j?.toString()] }.properties[i?.toString()]?.templateOptions?.additionalProperties?.overwrite,
                              ImportByEntityId: this.entityIdGlobal,
                              ImportedById: this.userService?.user?.employeeId,
                              LegalEntityId: this.userService?.user?.legalEntityId
                            },
                          })
                          .pipe()
                          .subscribe(
                            (value) => {
                              if (value == 'Successfully') {
                                this.closeVerdeDynamicModal()
                              } else {
                                this.notificationMessage = "Please try again, and if the issue persists, please log a support request";
                                this.modalService.open("errorModal");
                              }
                            },

                            (error) => {
                              this.notificationMessage = "Please try again, and if the issue persists, please log a support request";
                              console.log("ERROR_uploadDynamicFormFile", error);
                              this.modalService.open("errorModal");
                            },
                          );

                      }
                    }

                  }
                };
              }
              else {

                this.closeVerdeDynamicModal()
              }

            } else {
              this.notificationMessage = "Please try again, and if the issue persists, please log a support request";
              this.modalService.open("errorModal");
            }
          },

          (error) => {
           //debugger
            this.notificationMessage = "Please try again, and if the issue persists, please log a support request";
            this.modalService.open("errorModal");
          },
        );

    }
  }
}

