/* tslint:disable */
/* eslint-disable */
export interface VerdeWfhSummary {
  '_bt_employee_value'?: null | string;
  '_bt_legalentity_value'?: null | string;
  bt_attheoffice?: null | string;
  bt_enddate?: null | string;
  bt_leavedays?: null | string;
  bt_numberofdays?: null | string;
  bt_outofoffice?: null | string;
  bt_sickleave?: null | string;
  bt_startdate?: null | string;
  bt_verdewfhsummary?: null | string;
  bt_wfh?: null | string;
  bt_wfhpercentage?: null | string;
  bt_year?: null | string;
}
