/* tslint:disable */
/* eslint-disable */
export interface DvPermitRequestsPost {
  bt_attachmentfilename?: null | string;
  bt_permitend?: null | string;
  bt_permitrequestid?: null | string;
  bt_permitstart?: null | string;
  bt_reason?: null | string;
  bt_requestno?: null | string;
  bt_workflowstatus?: null | number;
  legalEntityID?: null | string;
  odataEmployee?: null | string;
  odataLegalEntity?: null | string;
  odataPermitType?: null | string;
  permitID?: null | string;
}
