import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControlDirective, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { FleetMovement, SharedApiFleetMovementService, SharedApiVehiclesService, VehiclesFiltered } from '@verde/shared';
import { UserService } from '@verde/core';
import { ModalService } from '@verde/shared';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import moment from 'moment';
import { FleetAccessService } from '../../../../../../../../apps/verde/src/app/features/fleet/services/fleet-access/fleet-access.service';

@Component({
  selector: 'verde-fleet-approval-modal',
  templateUrl: './fleet-approval-modal.component.html',
  styleUrls: ['./fleet-approval-modal.component.scss'],
})
export class FleetApprovalModalComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<boolean>();

  @Input() requestID: string = '';
  @Input() showModal: boolean = false;

  @Output() hideModalEvent = new EventEmitter<boolean>();
  @Output() transactionSuccessful = new EventEmitter<boolean>();

  @ViewChild('fleetTransferApprovalForm') fleetTransferApprovalForm: FormGroup | undefined;
  //selected
  selectedTransfer: FleetMovement = {};
  selectedTransferItem: VehiclesFiltered = {};

  //body
  patchTransferBody: FleetMovement = {};

  confirmationMessage: string = '';
  approveButtonText: string = 'Approve';
  confirmationAction: string = 'approve';

  agreedYes: boolean = false;
  agreedNo: boolean = false;
  locationToEmployeeTransfer: boolean = false;
  disableAnimation: boolean = false;
  actionAlreadyCompletedWarning: boolean = false;

  confirmFormDefaultState = {};
  enableSubmit: boolean;

  constructor(
    private spinner: NgxSpinnerService,
    private modalService: ModalService,
    public userService: UserService,
    private sharedApiVehiclesService: SharedApiVehiclesService,
    private sharedApiFleetMovementService: SharedApiFleetMovementService,
    public fleetAccess: FleetAccessService,
  ) {}

  ngOnInit(): void {
    this.userService.disableAnimation$.pipe(takeUntil(this.onDestroy$)).subscribe((data) => {
      this.disableAnimation = data;
    });

    setTimeout(() => {
      document.getElementById('showApprovalModal')?.click();
    }, 5);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  hideModal() {
    this.hideModalEvent.emit(false);
  }

  onConfirmClick(): void {
    this.transactionSuccessful.emit(true);
    this.hideModalEvent.emit(false);
  }

  openConfirmModal() {
    this.modalService.open('confirmFleetTransferApprovalModal');
  }

  getFleetMovement(myForm: FormGroup | undefined = this.fleetTransferApprovalForm) {
    this.spinner.show('fleettransferapproval');
    if (this.showModal == true) {
      this.modalService.open('newFleetTransferApprovalModal');
    }

    this.sharedApiFleetMovementService
      .getFleetMovementById({ movementID: this.requestID })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          this.selectedTransfer = ret;
        },
        (error) => {
          console.error(error);
        },
        () => {
          if (this.selectedTransfer?.bt_approvalstatus === 692360001) {
            if (this.selectedTransfer?._bt_regno_value !== null) {
              this.sharedApiVehiclesService
                .getFleetItemById({ itemID: this.selectedTransfer?._bt_regno_value! })
                .pipe(take(1))
                .subscribe(
                  (ret) => {
                    this.selectedTransferItem = ret;
                  },
                  (error) => {
                    console.error(error);
                  },
                  () => {
                    this.agreedYes = false;
                    this.agreedNo = false;

                    if (this.selectedTransfer?.bt_transfertype === 692360001) {
                      this.locationToEmployeeTransfer = true;
                    } else {
                      this.locationToEmployeeTransfer = false;
                    }
                    this.confirmFormDefaultState = {
                      agreedYes: this.agreedYes,
                      agreedNo: this.agreedNo,
                      confirmFromEmployee: this.selectedTransfer?.bt_FromEmployee?.bt_fullname,
                      confirmFromLocation: this.selectedTransfer?.odataFromLocation,
                      confirmToEmployee: this.selectedTransfer?.bt_ToEmployee?.bt_fullname,
                      confirmToLocation: this.selectedTransfer?.odataToLocation,
                      confirmModel: this.selectedTransferItem.odataMake + ' - ' + this.selectedTransferItem.odataModel,
                      confirmLicenseNo: this.selectedTransferItem.bthr_licensenumber,
                      confirmRequestReason: this.selectedTransfer?.bt_comment,
                    };
                    myForm.reset(this.confirmFormDefaultState);
                    this.spinner.hide('fleettransferapproval');
                  },
                );
            } else {
              this.agreedYes = false;
              this.agreedNo = false;

              if (this.selectedTransfer?.bt_transfertype === 692360001) {
                this.locationToEmployeeTransfer = true;
              } else {
                this.locationToEmployeeTransfer = false;
              }

              this.confirmFormDefaultState = {
                agreedYes: this.agreedYes,
                agreedNo: this.agreedNo,
                confirmFromEmployee: this.selectedTransfer?.bt_FromEmployee?.bt_fullname,
                confirmFromLocation: this.selectedTransfer?.odataFromLocation,
                confirmToEmployee: this.selectedTransfer?.bt_ToEmployee?.bt_fullname,
                confirmToLocation: this.selectedTransfer?.odataToLocation,
                confirmModel: null,
                confirmLicenseNo: null,
                confirmRequestReason: this.selectedTransfer?.bt_comment,
              };

              myForm.reset(this.confirmFormDefaultState);

              this.spinner.hide('fleettransferapproval');
            }
          } else {
            this.actionAlreadyCompletedWarning = true;
            this.spinner.hide('fleettransferapproval');
          }
        },
      );
  }

  //PATCH Fleet Movement (Fleet Manager Approval)
  patchTransferApproval(myForm: FormGroup) {
    this.spinner.show('confirmfleettransferapproval');
    this.modalService.open('confirmFleetTransferApprovalModal');

    if (this.agreedYes) {
      if (!this.locationToEmployeeTransfer) {
        this.patchTransferBody.bt_movementstatus = 692360001;
        this.patchTransferBody.bt_approvalstatus = 692360003;
      } else {
        this.patchTransferBody.bt_approvalstatus = 692360003;
      }
    } else {
      this.patchTransferBody.bt_approvalcomment = myForm.value.confirmRequestComment;
      this.patchTransferBody.bt_movementstatus = 692360003;
      this.patchTransferBody.bt_approvalstatus = 692360002;
    }

    this.patchTransferBody.bt_nextmovementstep = '';

    //setting approver
    this.patchTransferBody.odataPostApprovalEmployee = '/bthr_employees(' + this.userService.user?.employeeId + ')';
    this.patchTransferBody.odataPostApprovalLegalEntity = '/bthr_legalentitieses(' + this.userService.user?.legalEntityId + ')';
    this.patchTransferBody.bt_approvaldate = moment().format('YYYY-MM-DD') + 'T' + moment().format('HH:mm:ss') + 'Z';

    this.patchTransferBody.bt_fleetmovementid = this.selectedTransfer?.bt_fleetmovementid;
    this.sharedApiFleetMovementService
      .patchFleetMovement({ body: this.patchTransferBody })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          console.log(ret);
        },
        (error) => {
          console.error(error);
        },
        () => {
          this.patchTransferBody = {};

          if (this.confirmationAction === 'approve') {
            this.confirmationMessage = 'You have successfully approved this transfer!';
          } else {
            this.confirmationMessage = 'You have successfully rejected this transfer!';
          }

          this.spinner.hide('confirmfleettransferapproval');

          this.transactionSuccessful.emit(true);
          this.hideModalEvent.emit(false);
        },
      );

    if (this.agreedNo && this.selectedTransfer?._bt_regno_value !== null) {
      if (this.selectedTransferItem._bthr_employee_value !== null) {
        this.fleetAccess.patchFleetItemMovementStatusAssigned(
          this.selectedTransferItem?.bthr_vehiclesid!,
          this.selectedTransfer?.bt_FromEmployee?.bt_fullname!,
        );
      } else if (this.selectedTransferItem._bt_location_value !== null) {
        this.fleetAccess.patchFleetItemMovementStatusAtLocation(this.selectedTransferItem?.bthr_vehiclesid!, this.selectedTransfer?.odataFromLocation!);
      } else if (this.selectedTransferItem._bt_supplier_value !== null) {
        this.fleetAccess.patchFleetItemMovementStatusAtSupplier(this.selectedTransferItem?.bthr_vehiclesid!, this.selectedTransfer?.odataFromSupplier!);
      }
    }
  }

  // agreed
  agreedYesChange(e: any, element: FormControlDirective) {
    this.agreedYes = e;
    this.agreedNo = !e;
    element.reset();

    this.enableSubmit = true;
    if (this.agreedYes) {
      this.confirmationAction = 'approve';
      this.approveButtonText = 'Approve';
    } else {
      this.confirmationAction = 'reject';
      this.approveButtonText = 'Reject';
    }
  }

  agreedNoChange(e: any, element: FormControlDirective) {
    this.agreedNo = e;
    this.agreedYes = !e;
    element.reset();

    this.enableSubmit = true;
    if (this.agreedNo) {
      this.confirmationAction = 'reject';
      this.approveButtonText = 'Reject';
    } else {
      this.confirmationAction = 'approve';
      this.approveButtonText = 'Approve';
    }
  }
}
