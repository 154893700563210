<form #UploadPayslipData="ngForm" novalidate autocomplete="off">
  <div style="position: relative; min-height: 90vh; margin-top: 8px">
    <!-- Spinner for upload progress -->
    <ngx-spinner [disableAnimation]="disableAnimation" name="uploadpayroll" bdColor="rgb(255, 255, 255)"
                 size="default" color="#6264a7" type="timer" [fullScreen]="false">
      <p style="font-size: 20px; color: black; margin-top: 35px; text-align: center">
        Please be patient. Importing data...
      </p>
    </ngx-spinner>

    <!-- Displaying information about the selected item -->
    <p>
      {{ transform(selectedItem.legalEntityID.name) }} for Tax Year: {{ selectedItem.taxYear }} and Period: {{
      selectedItem.period }}
    </p>

    <!-- Displaying header image -->
    <img style="width: 100%;" src="{{ selectedItem.bt_headerurl }}" />

    <!-- File selection for Header File -->
    <div>
      <label>Header File:</label>
      <br />
      <kendo-fileselect id="file1" name="file1" #varfile1="ngModel" [(ngModel)]="file1"
                        [restrictions]="myRestrictions" accept=".csv" [multiple]="false"
                        (valueChange)="onFileChange($event, 'file1')">
      </kendo-fileselect>
    </div>

    <br />

    <!-- File selection for Detail File -->
    <div>
      <label>Detail File:</label>
      <br />
      <kendo-fileselect id="file2" name="file2" #varfile2="ngModel" [(ngModel)]="file2"
                        [restrictions]="myRestrictions" accept=".csv" [multiple]="false"
                        (valueChange)="onFileChange($event, 'file2')">
      </kendo-fileselect>
    </div>

    <br />

    <!-- Display error message if incorrectFlag is true -->
    <div *ngIf="incorrectFlag">
      <label style="color: red">Please confirm you are uploading the correct file into the correct place.</label>
    </div>

    <!-- Upload Files button -->
    <button style="position: absolute; bottom: 20px; right: 20px" kendoButton themeColor="primary"
            (click)="uploadFiles(UploadPayslipData)" [disabled]="file1 == null || file2 == null">
      Upload Files
    </button>
  </div>
</form>

<!-- Confirmation Modal -->
<kendo-dialog *ngIf="showConfirmationModal" title="Confirmation" [width]="500">
  <div style="height: 60px">
    <p>
      {{ resultMessage }}
    </p>
  </div>
  <div class="confirmation-modal-footer">
    <button kendoButton themeColor="primary" style="margin-left: auto" (click)="closeConfirmationModal()">
      Continue
    </button>
  </div>
</kendo-dialog>
