import { Component, OnInit, ViewChild } from '@angular/core';
import { Subject, take } from 'rxjs';
import { VerdeApprovalService } from '../../../../services/verde-approval/verde-approval.service';
import { DeviceTypeService } from '@verde/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DocumentApiDocumentService, DocumentBtDocumentGrouping } from '@verde/shared';
import { NgxSpinnerService } from 'ngx-spinner';
import { State, process, GroupResult } from '@progress/kendo-data-query';
import { DataBindingDirective, DataStateChangeEvent, GridDataResult, RowClassArgs, SelectableSettings } from '@progress/kendo-angular-grid';

@Component({
  selector: 'verde-bulk-upload-admin',
  templateUrl: './bulk-upload-admin.component.html',
  styleUrls: ['./bulk-upload-admin.component.scss'],
})
export class BulkUploadAdminComponent implements OnInit {
  @ViewChild('myGrid') private grid;
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  onDestroy$: Subject<any> = new Subject();
  checked: boolean = false;
  agreedYes: boolean;
  agreedNo: boolean;
  showConfirmationModal: boolean = false;
  groupingData: any;
  menuOptions: any[] = Object.values(DocumentBtDocumentGrouping);
  currentStep: number = 1;
  fb: FormBuilder;
  fg: FormGroup;
  allSkeletonData: Array<any> = new Array<any>();

  public skeletonGridData: GroupResult[] | any[];

  skeletonGridView: GridDataResult;

  skeletonState: State = {
    skip: 0,
    take: 5,
  };

  skeletonSelectedCallback = (args) => args.dataItem;

  skeletonSelection: any[] = [];

  public selectableSettings: SelectableSettings = {
    enabled: true,
    checkboxOnly: true,
  };

  constructor(
    private sidebarService: VerdeApprovalService,
    public deviceTypeService: DeviceTypeService,
    private documentApiDocumentService: DocumentApiDocumentService,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit(): void {
    this.fb = new FormBuilder();
    this.resetForm();
    this.GetDocType();
  }

  GetDocType() {
    this.documentApiDocumentService
      .getDocTypeFinancial({ body: { dataverseUrl: '' } })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          this.allSkeletonData = ret;
        },
        (error) => {
          console.error(error);
        },
        () => {},
      );
  }
  resetForm() {
    this.fg = this.fb.group({
      DocTypeName: [, Validators.required],
      groupingDropdown: [, Validators.required],
      MaskName: [, Validators.required],
      radioButton: [, Validators.required],
    });
  }

  isFormValid(): boolean {
    return this.fg.valid;
  }

  Create() {
    this.showConfirmationModal = true;
    this.spinner.show('uploadSpinner');
    let bulkBool = false;
    if (this.fg.value.radioButton == 'true') {
      bulkBool = true;
    }
    this.documentApiDocumentService
      .postDocType({
        body: {
          bt_allowbulkupload: bulkBool,
          bt_doctypefinancial: this.fg.value.DocTypeName,
          gropuing: this.fg.value.groupingDropdown,
          fileMask: this.fg.value.MaskName,
        },
      })
      .pipe(take(1))
      .subscribe(
        () => {},
        (error) => {
          console.error(error);
        },
        () => {
          this.spinner.hide('uploadSpinner');
        },
      );
  }

  rowCallback(context: RowClassArgs) {
    const isEven = context.index % 2 === 0;
    return {
      even: isEven,
      odd: !isEven,
    };
  }

  skeletonDataStateChange(state: DataStateChangeEvent): void {
    this.skeletonState = state;
    this.skeletonGridView = process(this.allSkeletonData, this.skeletonState);
  }

  closeConfirmationModal() {
    this.showConfirmationModal = false;
    this.sidebarService.setShowSidebar(false);
  }
}
