/* tslint:disable */
/* eslint-disable */
export interface DvAccreditationAuthoritiesPost {
  bthr_accreditationauthority?: null | string;
  bthr_authoritytype?: null | number;
  bthr_enddate?: null | string;
  bthr_registrationnumber?: null | string;
  bthr_startdate?: null | string;
  countryID?: null | string;
  legalEntityID?: null | string;
  odataCountry?: null | string;
  odataLegalEntity?: null | string;
}
