/* tslint:disable */
/* eslint-disable */
import { ProfileMyProfileGroups } from './profile-my-profile-groups';
import { ProfileProfileLegalEntity } from './profile-profile-legal-entity';
import { ProfileBtDynamicRule } from './profile-bt-dynamic-rule';
import { ProfileBtDynamicsFieldType } from './profile-bt-dynamics-field-type';
import { ProfileBtPageOption } from './profile-bt-page-option';
import { ProfileBtDynamiccomponent } from './profile-bt-dynamiccomponent';
export interface ProfileMyProfileFields {
  bt_Group?: ProfileMyProfileGroups;
  bt_LegalEntity?: ProfileProfileLegalEntity;
  bt_component?: ProfileBtDynamiccomponent;
  bt_display?: null | boolean;
  bt_editable?: null | boolean;
  bt_fieldname?: null | string;
  bt_fieldtype?: ProfileBtDynamicsFieldType;
  bt_lookupchoicename?: null | string;
  bt_lookupfields?: null | string;
  bt_max?: null | number;
  bt_min?: null | number;
  bt_option?: ProfileBtPageOption;
  bt_pattern?: null | string;
  bt_required?: boolean;
  bt_rule?: ProfileBtDynamicRule;
  bt_seqno?: null | number;
  bt_tablename?: null | string;
  bt_title?: null | string;
  bt_verdemyprofilefieldsid?: null | string;
}
