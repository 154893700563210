/* tslint:disable */
/* eslint-disable */
export enum ProfileBtVaccinationStatus {
  NotVaccinated = 'NotVaccinated',
  PartialVaccination = 'PartialVaccination',
  VaccinationCompleted = 'VaccinationCompleted',
  Booster1 = 'Booster1',
  Booster2 = 'Booster2',
  Booster3 = 'Booster3',
  Booster4 = 'Booster4'
}
