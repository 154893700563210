/* tslint:disable */
/* eslint-disable */
import { DocumentsAndForms } from './documents-and-forms';
export interface DocumentsAndFormsAndRelated {
  '_bt_group_value'?: null | string;
  '_bt_legalentity_value'?: null | string;
  '_bt_type_value'?: null | string;
  bt_DocumentandForm?: null | Array<DocumentsAndForms>;
  bt_attachment?: null | string;
  bt_attachment_name?: null | string;
  bt_description?: null | string;
  bt_documentname?: null | string;
  bt_documentsandformsid?: null | string;
  odataGroup?: null | string;
  odataType?: null | string;
}
