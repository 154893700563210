/* tslint:disable */
/* eslint-disable */
import { User } from './user';
export interface VerdeApprovalsDelegations {
  '_bt_delegateto_value'?: null | string;
  '_bt_delegatetoentity_value'?: null | string;
  '_bt_employee_value'?: null | string;
  '_bt_function_value'?: null | string;
  '_bt_legalentity_value'?: null | string;
  '_bt_proxyemployee_value'?: null | string;
  '_bt_proxyentity_value'?: null | string;
  bt_DelegateTo?: User;
  bt_enddate?: null | string;
  bt_module?: null | number;
  bt_proxy?: null | boolean;
  bt_startdate?: null | string;
  bt_verdeapprovaldelegationid?: null | string;
  bt_verdeapprovaldelegationno?: null | string;
  odataDelegateto?: null | string;
  odataDelegatetoentity?: null | string;
  odataEmployee?: null | string;
  odataFunction?: null | string;
  odataLegalentity?: null | string;
  odataModule?: null | string;
  odataPostDelegateTo?: null | string;
  odataPostDelegateToEntity?: null | string;
  odataPostEmployee?: null | string;
  odataPostFunction?: null | string;
  odataPostLegalEntity?: null | string;
  odataPostProxyEmployee?: null | string;
  odataPostProxyEntity?: null | string;
  odataProxyemployee?: null | string;
  odataProxyentity?: null | string;
  statecode?: null | number;
}
