import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ScriptLoaderService {
  private scripts: { [key: string]: { loaded: boolean; src: string } } = {};

  constructor() {}

  loadScript(name: string, src: string): Promise<any> {
    return new Promise((resolve, reject) => {
      // Check if the script is already registered
      if (!this.scripts[name]) {
        this.scripts[name] = { loaded: false, src: src };
      }

      // If script is already loaded, resolve the promise
      if (this.scripts[name].loaded) {
        resolve({ script: name, loaded: true, status: 'Already Loaded' });
      } else {
        // Create a new script element
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts[name].src;

        // Set up onload and onerror callbacks
        script.onload = () => {
          this.scripts[name].loaded = true;
          resolve({ script: name, loaded: true, status: 'Loaded' });
        };
        script.onerror = (error: any) => {
          reject({ script: name, loaded: false, status: 'Failed to Load' });
        };

        // Append the script to the head or body
        document.getElementsByTagName('formly-form')[0].appendChild(script);
      }
    });
  }
}
