<kendo-grid id="viewPayslipGrid"
            [data]="allPayslips"
            [resizable]="true"
            [scrollable]="true"
            [pageable]="true"
            [pageSize]="state.take"
            [skip]="state.skip"
            [sortable]="false"
            [groupable]="false"
            [loading]="payslipLoading"
            (dataStateChange)="dataStateChange($event)">
  <!--Columns-->
  <ng-template kendoGridToolbarTemplate
               style="height: 57px">
    <kendo-grid-spacer></kendo-grid-spacer>
    <button *ngIf="!(deviceTypeService.isMobile$ | async)"
            style="height: 36px; margin-left: auto"
            kendoGridExcelCommand
            icon="file-excel">
      Export to Excel
    </button>
    <img title="Refresh"
         class="refresh_icon"
         src="https://engageimages.z1.web.core.windows.net/Images/Verde/Other/reload.svg"
         alt=""
         (click)="refresh()" />
  </ng-template>
  <kendo-grid-column field="bt_doctypetext"
                     title="Type"
                     [width]="250"> </kendo-grid-column>
  <kendo-grid-column field="bt_periodenddate"
                     title="Period End Date"
                     [width]="270">
    <ng-template kendoGridCellTemplate
                 let-ListTable>
      {{ convertStringToDate(ListTable.bt_periodenddate) | date : 'yyyy/MM/dd' }}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="createdon"
                     title="Date Uploaded"
                     [width]="270">
    <ng-template kendoGridCellTemplate
                 let-ListTable>
      {{ ListTable.createdon | date : 'yyyy/MM/dd' }}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="bt_taxyear"
                     title="Tax Year"
                     [width]="250"> </kendo-grid-column>
  <kendo-grid-column field="bt_periodno"
                     title="Period No"
                     [width]="250"> </kendo-grid-column>
  <kendo-grid-command-column title=""
                             [width]="40"
                             [sticky]="true"
                             [minResizableWidth]="40"
                             [maxResizableWidth]="40">
    <ng-template kendoGridCellTemplate
                 let-dataItem>
      <verde-dots-menu-button [items]="dotMenuItems"
                              (menuClicked)="menuClicked(dataItem)"
                              (menuItemClicked)="menuItemClicked($event)"> </verde-dots-menu-button>
    </ng-template>
  </kendo-grid-command-column>
  <kendo-grid-excel fileName="FinancialDocs.xlsx"></kendo-grid-excel>
</kendo-grid>

<verde-payslip-dialog *ngIf="showDialog"
                      [selectedItem]="selectedItemNew"
                      (hideModalEvent)="hideDialogModal($event)"> </verde-payslip-dialog>

<!-- Modals -->
<verde-id-validation *ngIf="showIDValidationModal"
                     (modalClosed)="showIDValidationModal = $event"
                     (modalSuccess)="validationSuccess($event)">
</verde-id-validation>
<!-- Something -->
<verde-modal id="navToWebApp"
             modalTitle="Please Note"
             submitButton="Continue"
             (actionButton)="goToWeb()">
  <p>This functionality is temporarily only available on the web version of Verde.</p>
  <p>Please select Continue to be navigated to the web version else, Cancel.</p>
</verde-modal>