/* tslint:disable */
/* eslint-disable */
import { PerformanceReviewGuidExtrapolate } from './performance-review-guid-extrapolate';
import { PerformanceReviewBtDetailType } from './performance-review-bt-detail-type';
import { PerformanceReviewBtResponseType } from './performance-review-bt-response-type';
export interface PerformanceReviewEnhancedReviewDetail {
  bt_Employee?: null | string;
  bt_Group?: null | string;
  bt_PerformanceReviewDetailId?: null | string;
  bt_PerformanceReviewDetailSummary?: null | string;
  bt_Responder?: null | string;
  bt_Subgroup?: null | string;
  bt_actualamount?: null | number;
  bt_basepoint?: null | number;
  bt_basepointpercentage?: null | number;
  bt_benchmark?: null | string;
  bt_completed?: null | boolean;
  bt_detailtype?: PerformanceReviewBtDetailType;
  bt_displaysubgroup?: null | boolean;
  bt_groupsorting?: null | number;
  bt_maxvalue?: null | number;
  bt_measuresorting?: null | number;
  bt_minvalue?: null | number;
  bt_performancereviewdetail?: null | string;
  bt_rating?: null | number;
  bt_ratingfloat?: null | number;
  bt_response?: null | string;
  bt_responsetype?: PerformanceReviewBtResponseType;
  bt_subgroupsorting?: null | number;
  bt_targetamount?: null | number;
  employee?: PerformanceReviewGuidExtrapolate;
  group?: PerformanceReviewGuidExtrapolate;
  measure?: PerformanceReviewGuidExtrapolate;
  performanceReviewDetailSummary?: PerformanceReviewGuidExtrapolate;
  responder?: PerformanceReviewGuidExtrapolate;
  subgroup?: PerformanceReviewGuidExtrapolate;
}
