/* tslint:disable */
/* eslint-disable */
export interface VerdeOnlineTrainingSteps {
  '_bt_legalentity_value'?: null | string;
  '_bt_verdeonlinetrainingconfig_value'?: null | string;
  bt_background?: null | string;
  bt_html?: null | string;
  bt_htmlcontent?: null | string;
  bt_lottie?: null | string;
  bt_stepno?: null | number;
  bt_verdeonlinetrainingstepname?: null | string;
  bt_verdeonlinetrainingstepsid?: null | string;
  odataLegalEntity?: null | string;
  odataOnlineTrainingConfig?: null | string;
}
