import { DvPerformanceReview } from '../../api/_api_legacy/models/dv-performance-review';
import { DvPerformanceReviewPatch } from '../../api/_api_legacy/models/dv-performance-review-patch';
import { EnhancedReviewDetail } from '../../api/_api_legacy/models/enhanced-review-detail';

export interface PerformanceReview extends DvPerformanceReview, DvPerformanceReviewPatch {}

export interface PerformanceReviewSummaryExtended {
  id: number;
  expanded: boolean;
  group: string;
  groupId: string;
  groupSorting: number;
  subgroup: {
    name: string;
    subGroupId: string;
    subgroupSorting: number;
    questions: {
      question: string;
      measureSorting: number;
      basepoint: any;
      manager: EnhancedReviewDetail;
      employee: EnhancedReviewDetail;
      average: EnhancedReviewDetail;
    }[];
  }[];
}
