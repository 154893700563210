import { QualificationsGetQualificationQuery, QualificationsGetReducedQualificationQuery, QualificationsCreateQualificationCommand } from '@verde/shared';
import { Action } from '@ngrx/store';

export enum QualificationActionTypes {
  // Get All VaccinationTypes
  QUALIFICATION_GET_ALL = '[Qualification] Get All',
  QUALIFICATION_GET_ALL_SUCCESS = '[Qualification] Get All Success',
  QUALIFICATION_GET_ALL_ERROR = '[Qualification] Get All Error',

  // Get Reduced VaccinationTypes
  QUALIFICATION_GET_REDUCED = '[Qualification] Get Reduced',
  QUALIFICATION_GET_REDUCED_SUCCESS = '[Qualification] Get Reduced Success',
  QUALIFICATION_GET_REDUCED_ERROR = '[Qualification] Get Reduced Error',

  // Qualification Add
  QUALIFICATION_UPLOAD = '[Qualification] Upload',
  QUALIFICATION_UPLOAD_SUCCESS = '[Qualification] Upload Success',
  QUALIFICATION_UPLOAD_ERROR = '[Qualification] Upload Error',

  // Clear All VaccinationTypes
  QUALIFICATION_CLEAR = '[Qualification] Clear',
}

//Get All Qualification
export class QualificationGetAll implements Action {
  readonly type = QualificationActionTypes.QUALIFICATION_GET_ALL;
  constructor(public body: QualificationsGetQualificationQuery) {}
}

export class QualificationGetAllSuccess implements Action {
  readonly type = QualificationActionTypes.QUALIFICATION_GET_ALL_SUCCESS;
  constructor(public payload: any) {}
}

export class QualificationGetAllError implements Action {
  readonly type = QualificationActionTypes.QUALIFICATION_GET_ALL_ERROR;
  constructor(public payload: any) {}
}

//Get Reduced Qualification
export class QualificationGetReduced implements Action {
  readonly type = QualificationActionTypes.QUALIFICATION_GET_REDUCED;
  constructor(public body: QualificationsGetReducedQualificationQuery) {}
}

export class QualificationGetReducedSuccess implements Action {
  readonly type = QualificationActionTypes.QUALIFICATION_GET_REDUCED_SUCCESS;
  constructor(public payload: any) {}
}

export class QualificationGetReducedError implements Action {
  readonly type = QualificationActionTypes.QUALIFICATION_GET_REDUCED_ERROR;
  constructor(public payload: any) {}
}

// UPLOAD
export class QualificationUpload implements Action {
  readonly type = QualificationActionTypes.QUALIFICATION_UPLOAD;
  constructor(public payload: QualificationsCreateQualificationCommand) {}
}

export class QualificationUploadSuccess implements Action {
  readonly type = QualificationActionTypes.QUALIFICATION_UPLOAD_SUCCESS;
  constructor(public payload: any) {}
}

export class QualificationUploadError implements Action {
  readonly type = QualificationActionTypes.QUALIFICATION_UPLOAD_ERROR;
  constructor(public payload: any) {}
}

// Clear All
export class QualificationClear implements Action {
  readonly type = QualificationActionTypes.QUALIFICATION_CLEAR;
  constructor(public payload: any) {}
}

export type QualificationActions =
  // Get All Qualification
  | QualificationGetAll
  | QualificationGetAllSuccess
  | QualificationGetAllError
  // Get Reduced Qualification
  | QualificationGetReduced
  | QualificationGetReducedSuccess
  | QualificationGetReducedError
  // Structure Change Request Upload
  | QualificationUpload
  | QualificationUploadSuccess
  | QualificationUploadError
  // Clear All Qualification
  | QualificationClear;
