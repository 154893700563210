/* tslint:disable */
/* eslint-disable */
export interface DvTimeOffTypes {
  bt_earliestrequestdate?: null | string;
  bt_widgetdisplay?: null | boolean;
  bthr_allemployeesdefault?: null | boolean;
  bthr_allowoverlapwithothertypes?: null | boolean;
  bthr_appliestononstandardworkdays?: null | boolean;
  bthr_colour?: null | string;
  bthr_consecutiveworkdaysbeforenoterequired?: null | number;
  bthr_description?: null | string;
  bthr_displaymonthstoholdtoemployees?: null | boolean;
  bthr_genderspecific?: null | boolean;
  bthr_maxunitspayableontermination?: null | number;
  bthr_minimumrequestincrement?: null | number;
  bthr_monthstoholdunitsfrompreviousyear?: null | number;
  bthr_negativeunitsallowed?: null | number;
  bthr_notealwaysrequired?: null | boolean;
  bthr_noterequiredfrequentdays?: null | number;
  bthr_noterequiredfrequentdaysperiod?: null | number;
  bthr_noterequiredifadjacenttoholidaywe?: null | number;
  bthr_proratetimeoffcalculationsforparttime?: null | boolean;
  bthr_requestlatestfutureend?: null | number;
  bthr_requestmaxfuturestart?: null | number;
  bthr_requestminfuturestart?: null | number;
  bthr_showondashboard?: null | boolean;
  bthr_timeofftype?: null | string;
  bthr_timeofftypeid?: null | string;
  odataDefaultTimeOffUnit?: null | string;
  odataGender?: null | string;
  odataNoteRequiredIfAdjacentToHoliday?: null | string;
  odataRecordStatus?: null | string;
  odataRemunerationApplicable?: null | string;
}
