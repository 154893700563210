export const environment = {
  production: true,
  urlOrigin: window.location.origin,
  redirectUri: 'https://app.verde.work',
  envConfig: 'prod',
  slot: 'prod',
  imports: [],
  powerBiUrl: 'https://verdepowerbi.azurewebsites.net',
  azureFunctionCode: 'En5ghkgfyHTZ3FhI8keWNa9R79UbPsZDPmjLqBApQTWGAzFu4H01Ew==',
  features: {
    structureApiUrl: 'proxy/structure',
    equipmentApiUrl: 'proxy/equipment',
    delegationsApiUrl: 'proxy/delegations',
    profileApiUrl: 'proxy/profile',
    templateApiUrl: 'proxy/template',
    documentApiUrl: 'proxy/document',
    fleetApiUrl: 'proxy/fleet',
    performanceReviewApiUrl: 'proxy/performanceReview',
    qualificationsApiUrl: 'proxy/qualifications',
    policyApiUrl: 'proxy/policy',
    timeoffApiUrl: 'proxy/timeoff',
    giftsApiUrl: 'proxy/gifts',
    learnApiUrl: 'proxy/learn',
    engageApiUrl: 'proxy/engage',
    bookingsApiUrl: 'proxy/bookings',
    timetrackingApiUrl: 'proxy/timetracking',
    tasksApiUrl: 'proxy/tasks',
    supportApiUrl: 'proxy/support',
    communicationApiUrl: 'proxy/communication',
    processesApiUrl: 'proxy/processes',
    legacyApiUrl: 'proxy/legacy',
    commonApiUrl: 'proxy/common',
    userApiUrl: 'proxy/user',
    approvalsApiUrl: 'proxy/approvals',
  },
  msalConfig: {
    auth: {
      clientId: '07c123a0-b391-422c-a417-c3c297c2f434',
      authority: 'https://login.microsoftonline.com/common',
      redirectUri: 'https://app.verde.work',
    },
  },
  graphConfig: {
    scopes: ['User.ReadBasic.All', 'MailboxSettings.Read', 'Calendars.ReadWrite'],
  },
};
