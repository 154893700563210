import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject, take, takeUntil } from 'rxjs';
import { RefreshService } from '../../../services/refresh-service';
import { DelegationsApiDelegationsService, ProcessesApiProcessesService, ProcessesPositionSeats, ProcessesProcessesEmployee, ProcessesValidateTaxNumberQuery } from '@verde/shared';
import { FileRestrictions } from '@progress/kendo-angular-upload';
import { saveAs } from 'file-saver';

@Component({
  selector: 'verde-passport-info-edit',
  templateUrl: './onboarding-passport-info-edit.component.html',
  styleUrls: ['./onboarding-passport-info-edit.component.scss'],
})
export class OnboardingpassportInfoEditComponent implements OnInit {
  private onDestroy$ = new Subject<boolean>();
  @Input() public passportInfo: FormGroup | null = null;
  @Input() positionSeat: ProcessesPositionSeats | null = null;
  @Input() public personalDetails: FormGroup | null = null;
  @Input() onboardingEmployee: ProcessesProcessesEmployee;

  public restrictions: FileRestrictions = {
    allowedExtensions: ['jpg', 'jpeg', 'png', 'pdf'],
  };
  docBase64: any;

  constructor(private refreshService: RefreshService, private processesApiProcessesService: ProcessesApiProcessesService, private delegationApi: DelegationsApiDelegationsService) {}

  ngOnInit(): void {
   
    if (this.personalDetails) {
      this.passportInfo?.get('passportCountry')?.setValue(this.personalDetails.value.primaryNationality.bthr_nationality); 
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();

  }

  onFileSelect(event: any): void {
    
  }

  onRadioChange(value) {

  }

  donwloadDoc() {
    if (this.onboardingEmployee.employeeID) {
      this.delegationApi.downloadFile({
        body: { columnName: 'bt_passport', entityName: 'bthr_employee', recordID: this.onboardingEmployee.employeeID }
      })
        .pipe(take(1))
        .subscribe(
          (val) => {
            this.docBase64 = val
          },
          (error) => {
            console.error(error);
          },
          () => {
            this.downloadFile(this.docBase64)
          },
        );
    }
  }

  //convert to blob
  downloadFile(base64String: string) {
    const mimeType = this.getMimeType(this.onboardingEmployee.bthr_nationalidpassport_Name);
    const binaryData = atob(base64String);
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }
    const blob = new Blob([uint8Array], { type: mimeType });
    saveAs(blob, this.onboardingEmployee.bthr_nationalidpassport_Name);
  }

  getMimeType(fileName: string): string {
    const extension = fileName.split('.').pop().toLowerCase();
    switch (extension) {
      case 'pdf':
        return 'application/pdf';
      case 'jpeg':
      case 'jpg':
        return 'image/jpeg';
      case 'png':
        return 'image/png';
      default:
        throw new Error('Unsupported file type');
    }
  }
}
