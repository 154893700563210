/* tslint:disable */
/* eslint-disable */
export interface FleetRental {
  bt_description?: null | string;
  bt_enddate?: null | string;
  bt_fleetrentalid?: null | string;
  bt_name?: null | string;
  bt_ponumber?: null | string;
  bt_proxy?: null | boolean;
  bt_referencenumber?: null | string;
  bt_rentalfor?: null | number;
  bt_rentalinvoiceamount?: null | number;
  bt_rentalquote?: null | number;
  bt_rentalquoteattachmentname?: null | string;
  bt_rentalstatus?: null | number;
  bt_startdate?: null | string;
  odataPostCreatedBy?: null | string;
  odataPostEmployee?: null | string;
  odataPostLegalEntity?: null | string;
  odataPostProxy?: null | string;
  odataPostRentalReason?: null | string;
  odataRentalFor?: null | string;
  odataRentalReason?: null | string;
  odataRentalStatus?: null | string;
  odataRentalSupplier?: null | string;
}
