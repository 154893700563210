/* tslint:disable */
/* eslint-disable */
import { BtBtVerdepolicycontentPolicyBtVerdepoli } from './bt-bt-verdepolicycontent-policy-bt-verdepoli';
export interface VerdePolicy {
  '_bt_legalentity_value'?: null | string;
  '_bt_owner_value'?: null | string;
  '_bt_ownerlegalentity_value'?: null | string;
  bt_bt_verdepolicycontent_Policy_bt_verdepoli?: null | Array<BtBtVerdepolicycontentPolicyBtVerdepoli>;
  bt_effectivedate?: null | string;
  bt_latestversiondate?: null | string;
  bt_policyname?: null | string;
  bt_policyoverview?: null | string;
  bt_policystatus?: null | string;
  bt_verdepolicy?: null | string;
  bt_verdepolicyid?: null | string;
  bt_versionnumber?: null | string;
  odataLegalEntity?: null | string;
  odataOwner?: null | string;
  odataOwnerLegalEntity?: null | string;
}
