/* tslint:disable */
/* eslint-disable */
export interface VerdeSupplier {
  '_bt_legalentity_value'?: null | string;
  bt_supplierid?: null | string;
  bt_suppliername?: null | string;
  bt_suppliertype?: null | number;
  bt_verdesupplierid?: null | string;
  odatPostLegalEntity?: null | string;
  odataLegalEntity?: null | string;
  odataStatus?: null | string;
  odataSupplierType?: null | string;
  statecode?: null | number;
}
