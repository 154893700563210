/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TemplateApiConfiguration, TemplateApiConfigurationParams } from './template-api-configuration';

import { TemplateApiTemplatesService } from './services/template-api-templates.service';
import { TemplateApiVersionService } from './services/template-api-version.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    TemplateApiTemplatesService,
    TemplateApiVersionService,
    TemplateApiConfiguration
  ],
})
export class TemplateApiModule {
  static forRoot(params: TemplateApiConfigurationParams): ModuleWithProviders<TemplateApiModule> {
    return {
      ngModule: TemplateApiModule,
      providers: [
        {
          provide: TemplateApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: TemplateApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('TemplateApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
