/* tslint:disable */
/* eslint-disable */
export interface DvBankAccounts {
  bthr_accountholdername?: null | string;
  bthr_accountholderrealtionshipreason?: null | string;
  bthr_accountholderrelationship?: null | string;
  bthr_accountnumber?: null | string;
  bthr_accounttype?: null | number;
  bthr_employeebankaccountid?: null | string;
  bthr_name?: null | string;
  bthr_paymentmethod?: null | number;
  bthr_proofofbankaccount_name?: null | string;
  odataAccountType?: null | string;
  odataBankBranch?: null | string;
  odataBankValue?: null | string;
  odataPaymentMethod?: null | string;
  odataRecordStatus?: null | string;
  odataStatus?: null | string;
}
