/* tslint:disable */
/* eslint-disable */
import { EquipmentEquipmentKit } from './equipment-equipment-kit';
import { EquipmentEquipmentLegalEntity } from './equipment-equipment-legal-entity';
import { EquipmentEquipmentRequestItem } from './equipment-equipment-request-item';
import { EquipmentEquipmentSpec } from './equipment-equipment-spec';
import { EquipmentEquipmentSpecType } from './equipment-equipment-spec-type';
import { EquipmentEquipmentType } from './equipment-equipment-type';
import { EquipmentEquipmentTypeClass } from './equipment-equipment-type-class';
export interface EquipmentEquipmentKitItem {
  bt_EquipmentKit?: EquipmentEquipmentKit;
  bt_EquipmentSpec?: EquipmentEquipmentSpec;
  bt_EquipmentSpecType?: EquipmentEquipmentSpecType;
  bt_EquipmentType?: EquipmentEquipmentType;
  bt_EquipmentTypeClass?: EquipmentEquipmentTypeClass;
  bt_LegalEntity?: EquipmentEquipmentLegalEntity;
  bt_RequestEquipmentItem?: EquipmentEquipmentRequestItem;
  bt_equipmentkititemid?: string;
  bt_equipmentkititemno?: null | string;
  bt_qty?: null | number;
}
