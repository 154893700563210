<ng-container [formGroup]="paymentDetails">

  <kendo-formfield orientation="horizontal">
    <kendo-label text="Tax No Outstanding"> </kendo-label>
    <ul class="k-radio-list k-list-horizontal">
      <li class="k-radio-item">
        <input type="radio" #yes value="yes" kendoRadioButton formControlName="taxNoOutstanding" [checked]="true" (change)="onRadioChange()"/>
        <kendo-label class="k-radio-label" [for]="yes" text="Yes"></kendo-label>
      </li>
      <li class="k-radio-item">
        <input type="radio" #no value="no" kendoRadioButton formControlName="taxNoOutstanding" (change)="onRadioChange()"/>
        <kendo-label class="k-radio-label" [for]="no" text="No"></kendo-label>
      </li>
    </ul>
    <kendo-formerror>This field is required</kendo-formerror>
  </kendo-formfield>

  <!-- Second kendo-formfield with textbox (conditionally displayed) -->
  <kendo-formfield *ngIf="paymentDetails.controls.taxNoOutstanding.value === 'no'">
    <kendo-label [for]="taxNo" text="Tax No"> </kendo-label>
    <div class="input-container">
      <kendo-textbox formControlName="taxNo" #taxNo></kendo-textbox>
      <kendo-buttongroup>
        <button kendoButton themeColor="primary" (click)="validateTaxNumber()">Validate</button>
      </kendo-buttongroup>
    </div>
    <kendo-formhint>{{ taxValidationMessage }}</kendo-formhint>
  </kendo-formfield>

</ng-container>
