/* tslint:disable */
/* eslint-disable */
import { BtNominatorEmployee } from './bt-nominator-employee';
import { BtNomineeEmployee } from './bt-nominee-employee';
export interface VoteNomination {
  '_bt_nominatoremployee_value'?: null | string;
  '_bt_nominatorlegalentity_value'?: null | string;
  '_bt_nomineeemployee_value'?: null | string;
  '_bt_nomineelegalentity_value'?: null | string;
  '_bt_votegroup_value'?: null | string;
  bt_NominatorEmployee?: BtNominatorEmployee;
  bt_NomineeEmployee?: BtNomineeEmployee;
  bt_nominationreason?: null | string;
  bt_votenomination?: null | string;
  bt_votenominationid?: null | string;
  odataNominatorLegalEntity?: null | string;
  odataPostNominatorEmployee?: null | string;
  odataPostNominatorLegalEntity?: null | string;
  odataPostNomineeEmployee?: null | string;
  odataPostNomineeLegalEntity?: null | string;
  odataPostVoteGroup?: null | string;
}
