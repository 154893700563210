/* tslint:disable */
/* eslint-disable */
export interface EmployeeStatus {
  bt_date?: null | string;
  bt_employeestatusid?: null | string;
  bt_healthstatus?: null | boolean;
  bt_isproxy?: null | boolean;
  bt_locationupdated?: null | boolean;
  bt_notes?: null | string;
  bt_oneliner?: null | string;
  bt_presencestatussource?: null | string;
  bt_recordstatus?: null | string;
  bt_risk?: null | boolean;
  bt_sourcecreateddate?: null | string;
  bt_sourcemodifieddate?: null | string;
  bt_status?: null | string;
  bt_temperature?: null | number;
  bt_title?: null | string;
  modifiedon?: null | string;
  odataEmployee?: null | string;
  odataLegalEntity?: null | string;
  odataProxy?: null | string;
  odataRisk?: null | string;
}
