/* tslint:disable */
/* eslint-disable */
export interface FleetModel {
  '_bt_legalentity_value'?: null | string;
  '_bt_type_value'?: null | string;
  '_bthr_make_value'?: null | string;
  bt_fueltype?: null | number;
  bt_modelname?: null | string;
  bt_modelunique?: null | string;
  bt_modelyear?: null | string;
  bt_tankcapacityl?: null | number;
  bt_tare?: null | number;
  bt_transmissiontype?: null | number;
  bthr_model?: null | string;
  bthr_recordstatus?: null | number;
  bthr_vehiclemodelid?: null | string;
  odataFleetType?: null | string;
  odataFuelType?: null | string;
  odataLegalEntity?: null | string;
  odataMake?: null | string;
  odataPostFleetType?: null | string;
  odataPostLegalEntity?: null | string;
  odataPostMake?: null | string;
  odataRecordStatus?: null | string;
  odataTransmissionType?: null | string;
}
