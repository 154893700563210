/* tslint:disable */
/* eslint-disable */
export interface DvRiskManagement {
  bt_accepteddate?: null | string;
  bt_approvalcomment?: null | string;
  bt_approvername?: null | string;
  bt_attachment_name?: null | string;
  bt_employeeriskmanagementid?: null | string;
  bt_name?: null | string;
  bt_reasonfornotaccepting?: null | string;
  bt_responded?: null | string;
  bt_vaccinationdate?: null | string;
  odataHighRisk?: null | string;
  odataObjectionReason?: null | string;
  odataVaccinationAccepted?: null | string;
  odataVaccinationRequired?: null | string;
  odataVaccinationStatus?: null | string;
  odataVulnerable?: null | string;
  odataWorkflowStatus?: null | string;
}
