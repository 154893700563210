/* tslint:disable */
/* eslint-disable */
import { ProcessesLookupExtrapolate } from './processes-lookup-extrapolate';
import { ProcessesVerdeAnnotations } from './processes-verde-annotations';
import { ProcessesBtEmployeeLifecycleStage } from './processes-bt-employee-lifecycle-stage';
import { ProcessesBtEmployeeStatusEnum } from './processes-bt-employee-status-enum';
export interface ProcessesProcessesEmployee {
  annotations?: null | Array<ProcessesVerdeAnnotations>;
  bt_EmployeeLifecycleStage?: ProcessesBtEmployeeLifecycleStage;
  bt_citizenshipstatus?: ProcessesLookupExtrapolate;
  bt_countryofresidence?: ProcessesLookupExtrapolate;
  bt_cvdocument_Name?: null | string;
  bt_employeestatus?: ProcessesBtEmployeeStatusEnum;
  bt_firstname?: null | string;
  bt_lastname?: null | string;
  bt_localresidencystatus?: ProcessesLookupExtrapolate;
  bt_nationality?: ProcessesLookupExtrapolate;
  bt_neworganizationjoindate?: null | string;
  bt_probationtype?: ProcessesLookupExtrapolate;
  bt_shifttype?: ProcessesLookupExtrapolate;
  bt_taxnoouts?: null | boolean;
  bthr_employeetype?: ProcessesLookupExtrapolate;
  bthr_foreignnational?: null | boolean;
  bthr_middlenames?: null | string;
  bthr_nationalidnumber?: null | string;
  bthr_nationalidpassport_Name?: null | string;
  bthr_passport_Name?: null | string;
  bthr_preferredname?: null | string;
  bthr_taxnumber?: null | string;
  employeeID?: string;
  employeeName?: null | string;
  employeeNumber?: null | string;
  fullName?: null | string;
  hasPassport?: null | boolean;
  passportCountry?: ProcessesLookupExtrapolate;
  passportExpDate?: null | string;
  passportNumber?: null | string;
}
