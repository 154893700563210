import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { TemplateGetGroupsQuery, TemplatePatchTemplateGroupCommand, TemplateUploadGroupCommand } from '@verde/shared';

// Store
//Related
import * as fromProfileRelatedReducer from '../store/reducers/profile-related.reducer';
// Group
import * as GroupActions from '../store/actions/group.actions';
import * as fromGroupReducer from '../store/reducers/group.reducer';

@Injectable({
  providedIn: 'root',
})
export class GroupService {
  // Group
  groupLoading$: Observable<boolean>;
  groupObjects$: Observable<any[]>;

  constructor(private store: Store<fromProfileRelatedReducer.ProfileRelatedState>) {
    // Group
    this.groupLoading$ = this.store.pipe(select(fromGroupReducer.isGroupLoading));
    this.groupObjects$ = this.store.pipe(select(fromGroupReducer.getAllGroup));
  }

  // Equipment Item
  // Get Equipment Items By Location
  public getGroup(body: TemplateGetGroupsQuery) {
    this.store.dispatch(new GroupActions.GroupsGet(body));
  }

  // Clear
  groupClear() {
    this.store.dispatch(new GroupActions.GroupsClear(null));
  }

  // Post
  uploadGroup(payload: TemplateUploadGroupCommand) {
    this.store.dispatch(new GroupActions.GroupsUpload(payload));
  }

  // Group update
  updateGroup(payload: TemplatePatchTemplateGroupCommand) {
    this.store.dispatch(new GroupActions.GroupUpdate(payload));
  }
}
