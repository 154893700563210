/* tslint:disable */
/* eslint-disable */
export interface TasksCreateTaskFromTaskTemplateCommand {
  employeeId?: null | string;
  employeeLegalEntityId?: null | string;
  referenceColumn?: null | string;
  referenceGuid?: null | string;
  referenceTable?: null | string;
  taskTemplateId?: string;
}
