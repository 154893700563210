/* tslint:disable */
/* eslint-disable */
import { EquipmentEquipmentEmployee } from './equipment-equipment-employee';
import { EquipmentEquipmentLegalEntity } from './equipment-equipment-legal-entity';
import { EquipmentEquipmentTakeOnControl } from './equipment-equipment-take-on-control';
import { EquipmentEquipmentTakeOnGroup } from './equipment-equipment-take-on-group';
import { EquipmentEquipmentTakeOnMake } from './equipment-equipment-take-on-make';
import { EquipmentEquipmentTakeOnType } from './equipment-equipment-take-on-type';
import { EquipmentBtEquipmentTakeOnClassification } from './equipment-bt-equipment-take-on-classification';
import { EquipmentBtEquipmentTakeOnState } from './equipment-bt-equipment-take-on-state';
import { EquipmentBtEquipmentTakeonCondition } from './equipment-bt-equipment-takeon-condition';
export interface EquipmentEquipmentTakeOn {
  bt_Employee?: EquipmentEquipmentEmployee;
  bt_EquipmentType?: EquipmentEquipmentTakeOnType;
  bt_Group?: EquipmentEquipmentTakeOnGroup;
  bt_LegalEntity?: EquipmentEquipmentLegalEntity;
  bt_Make?: EquipmentEquipmentTakeOnMake;
  bt_TakeOnControl?: EquipmentEquipmentTakeOnControl;
  bt_assetno?: null | string;
  bt_classification?: EquipmentBtEquipmentTakeOnClassification;
  bt_condition?: EquipmentBtEquipmentTakeonCondition;
  bt_conditioncomment?: null | string;
  bt_deletereason?: null | string;
  bt_devicename?: null | string;
  bt_equipmentdescription?: null | string;
  bt_equipmentphoto?: null | string;
  bt_equipmentphotoname?: null | string;
  bt_equipmentphotosize?: null | number;
  bt_equipmenttakeonid?: string;
  bt_equipmenttakeonno?: null | string;
  bt_equipmenttypeother?: null | string;
  bt_makeother?: null | string;
  bt_model?: null | string;
  bt_quantity?: null | number;
  bt_receivedas?: null | boolean;
  bt_receiveddate?: null | string;
  bt_serialnumber?: null | string;
  bt_serialnumberphoto?: null | string;
  bt_serialnumberphotoname?: null | string;
  bt_serialnumberphotosize?: null | number;
  statecode?: EquipmentBtEquipmentTakeOnState;
}
