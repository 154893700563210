import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { ModalService } from '../../../../../modals/modal.service';
import { VerdeApprovalService } from '../../../../../services/verde-approval/verde-approval.service';
import { SubgroupService } from '../../../../../../../../../../apps/verde/src/app/features/profile2/services/subgroup.service';
import { RefreshServiceService } from '../../../../../../../../../../apps/verde/src/app/features/profile2/components/templates/services/refresh-service.service';
import { TemplateTemplateSubgroup } from '@verde/shared';

@Component({
  selector: 'verde-subgroup-edit',
  templateUrl: './subgroup-edit.component.html',
  styleUrls: ['./subgroup-edit.component.scss'],
})
export class SubgroupEditComponent implements OnInit {
  private onDestroy$ = new Subject<boolean>();

  legalEntityID: string;
  legalEntityName: string;
  tempType: string;
  selectedSubgroup: any;
  subgroupForm: FormGroup;
  formValues: any;
  visited: boolean;
  private methodCallSubscription: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private sidebarService: VerdeApprovalService,
    private modalService: ModalService,
    private subgroupService: SubgroupService,
    private refreshServiceService: RefreshServiceService,
  ) {
    this.methodCallSubscription = this.refreshServiceService.methodCall$.subscribe(() => {
      if (this.visited === true) {
        this.loadPage();
      }
    });
  }

  ngOnInit(): void {
    this.visited = true;
    this.loadPage();
  }

  loadPage() {
    this.sidebarService
      .getSelectedSubgroup()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((subgroup: any) => {
        this.selectedSubgroup = subgroup;
      });
    this.sidebarService
      .getSelectedLegalEntityID()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((id: any) => {
        this.legalEntityID = id;
      });
    this.sidebarService
      .getSelectedLegalEntityName()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((name: any) => {
        this.legalEntityName = name;
      });
    this.sidebarService
      .getSelectedTemplateType()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((type: any) => {
        this.tempType = type;
      });

    this.subgroupForm = this.formBuilder.group({
      legalEntity: [this.selectedSubgroup.bt_LegalEntity.bthr_legalentityname],
      templateType: [this.selectedSubgroup.bt_TemplateType],
      name: [this.selectedSubgroup.bt_peerreviewtemplatesubgroup, Validators.required],
      sorting: [this.selectedSubgroup.bt_sorting, [Validators.required, Validators.pattern('[0-9]*')]],
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  editSubgroup() {
    const formValues = this.subgroupForm.value;
    if (this.subgroupForm.valid) {
      this.formValues = formValues;
      this.modalService.open('confirmSubgroup');
    } else {
    }
  }

  confirm() {
    var numberValue = Number(this.formValues.sorting);
    let body: TemplateTemplateSubgroup = {
      bt_peerreviewtemplatesubgroupid: this.selectedSubgroup.bt_peerreviewtemplatesubgroupid,
      bt_peerreviewtemplatesubgroup: this.formValues.name,
      bt_sorting: numberValue,
    };
    this.subgroupService.updateSubgroup({ templateSubgroupBody: body });
    this.sidebarService.setShowSidebar(false);
    this.resetForm();
  }

  resetForm() {
    const legalEntityValue = this.subgroupForm.get('legalEntity')?.value;
    const templateTypeValue = this.subgroupForm.get('templateType')?.value;
    const nameValue = this.subgroupForm.get('name')?.value;
    this.subgroupForm.reset();
    this.subgroupForm.patchValue({
      legalEntity: legalEntityValue,
      templateType: templateTypeValue,
      name: nameValue,
    });

    this.refresh();
  }

  refresh() {
    this.refreshServiceService.refreshView();
  }
}
