import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService, UserService } from '@verde/core';
import {
  ProcessesCustomerDetail,
  ProcessesVerdeCalculationStatus,
  ProcessesVerdeCustomerType,
  ProcessesVerdeProduct,
  VerdeApprovalService,
} from '@verde/shared';
import { Subject, takeUntil } from 'rxjs';
import { OpportunitiesService } from '../../services/opportunities-service';

@Component({
  selector: 'verde-opportunities',
  templateUrl: './opportunities.component.html',
  styleUrls: ['./opportunities.component.scss'],
})
export class OpportunitiesComponent implements OnInit, OnDestroy {
  onDestroy$: Subject<any> = new Subject();

  // Navigation
  createItemSidepanel: string = '';
  selectedNavigationOption: string;

  // OPPORTUNITY
  opportunitiesName: string = '';
  noOfEmployees: number = 0;
  optionOne: boolean = true;
  optionTwo: boolean = false;
  optionThree: boolean = false;

  pricePerUser: number = 0;
  numberOfEmployees: number = 0;
  monthly: number = 0;
  annual: number = 0;

  includedNoSystemUsers: number = 0;
  includedNoAdminUsers: number = 0;
  includedDatabaseCapacity: number = 0;
  includedFileCapacity: number = 0;
  includedLogCapacity: number = 0;

  addNoSystemUsers: number = 0;
  addNoAdminUsers: number = 0;
  addDatabaseCapacity: number = 0;
  addFileCapacity: number = 0;
  addLogCapacity: number = 0;

  costNoSystemUsers: number = 0;
  costNoAdminUsers: number = 0;
  costDatabaseCapacity: number = 0;
  costFileCapacity: number = 0;
  costLogCapacity: number = 0;

  resultMessage: string = '';
  showConfirmationModal: boolean = false;

  // Products
  allVerdeProducts: ProcessesVerdeProduct[] = [];
  selectedVerdeProduct: ProcessesVerdeProduct = {};
  customerTypes: ProcessesVerdeCustomerType[] = Object.values(ProcessesVerdeCustomerType);
  selectedCustomerType: ProcessesVerdeCustomerType = ProcessesVerdeCustomerType.Commercial;

  newCustomer: boolean = true;

  // Selected Item
  selectedOpportunity: ProcessesCustomerDetail;

  prodcutsDataLoaded: boolean = false;
  opportunityDataLoaded: boolean = false;

  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private sidebarService: VerdeApprovalService,
    private opportunitiesService: OpportunitiesService,
  ) {}

  ngOnInit(): void {
    this.opportunitiesService.fetchVerdeProducts();

    this.opportunitiesService
      .getVerdeProducts()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((prods) => {
        if (prods.length > 0) {
          this.allVerdeProducts = prods;
          this.selectedVerdeProduct = prods[0];

          this.prodcutsDataLoaded = true;
          this.loadOpportunityForm();
        }
      });

    // Get selected item
    this.sidebarService
      .getSelectedOpportunity()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((item) => {
        if (Object.keys(item).length > 0) {
          this.selectedOpportunity = item.body;
          this.selectedNavigationOption = item.type;

          this.opportunityDataLoaded = true;
          this.loadOpportunityForm();
        }
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  loadOpportunityForm() {
    if (this.prodcutsDataLoaded && this.opportunityDataLoaded) {
      console.log('selectedOpportunity', this.selectedOpportunity);
      // Populate front end when editing
      if (this.selectedNavigationOption == 'edit') {
        //verde_customercompanyname
        //this.editEQValidationURL = this.selectedEmployeeEducationalQualification?.bt_validationurl;

        console.log(this.selectedOpportunity);

        this.selectedOpportunity?.verde_customercompanyname != null ? (this.opportunitiesName = this.selectedOpportunity?.verde_customercompanyname) : ''; //Double check
        this.selectedOpportunity?.verde_peoplebasic != null ? (this.optionOne = this.selectedOpportunity.verde_peoplebasic) : '';
        this.selectedOpportunity?.verde_peoplefull != null ? (this.optionTwo = this.selectedOpportunity.verde_peoplefull) : '';
        this.selectedOpportunity?.verde_fleet != null ? (this.optionThree = this.selectedOpportunity.verde_fleet) : '';
        this.selectedOpportunity?.verde_customertype != null ? (this.selectedCustomerType = this.selectedOpportunity.verde_customertype) : '';

        this.selectedOpportunity?.verde_peruser != null ? (this.pricePerUser = this.selectedOpportunity?.verde_peruser) : '';
        this.selectedOpportunity?.verde_noofemployees != null ? (this.noOfEmployees = this.selectedOpportunity?.verde_noofemployees) : '';
        this.selectedOpportunity?.verde_monthly != null ? (this.monthly = this.selectedOpportunity?.verde_monthly) : '';
        this.selectedOpportunity?.verde_annual != null ? (this.annual = this.selectedOpportunity?.verde_annual) : '';

        this.selectedOpportunity?.verde_noofemployees != null ? (this.numberOfEmployees = this.selectedOpportunity?.verde_noofemployees) : ''; //DOUBLE CHECK

        this.selectedOpportunity?.verde_noofsystemusers != null ? (this.includedNoSystemUsers = this.selectedOpportunity?.verde_noofsystemusers) : '';
        this.selectedOpportunity?.verde_noofadmin != null ? (this.includedNoAdminUsers = this.selectedOpportunity?.verde_noofadmin) : '';
        this.selectedOpportunity?.verde_databasecapacity != null ? (this.includedDatabaseCapacity = this.selectedOpportunity?.verde_databasecapacity) : '';
        this.selectedOpportunity?.verde_filecapacity != null ? (this.includedFileCapacity = this.selectedOpportunity?.verde_filecapacity) : '';
        this.selectedOpportunity?.verde_logcapacity != null ? (this.includedLogCapacity = this.selectedOpportunity?.verde_logcapacity) : '';

        this.selectedOpportunity?.verde_systemusers != null ? (this.addNoSystemUsers = this.selectedOpportunity?.verde_systemusers) : '';
        this.selectedOpportunity?.verde_additionaladminusers != null ? (this.addNoAdminUsers = this.selectedOpportunity?.verde_additionaladminusers) : '';
        this.selectedOpportunity?.verde_additionaldatabase != null ? (this.addDatabaseCapacity = this.selectedOpportunity?.verde_additionaldatabase) : '';
        this.selectedOpportunity?.verde_additionalfile != null ? (this.addFileCapacity = this.selectedOpportunity?.verde_additionalfile) : '';
        this.selectedOpportunity?.verde_additionallog != null ? (this.addLogCapacity = this.selectedOpportunity?.verde_additionallog) : '';

        this.selectedOpportunity?.verde_systemextended?.value != null ? (this.costNoSystemUsers = this.selectedOpportunity?.verde_systemextended?.value) : '';
        this.selectedOpportunity?.verde_additionaladminextended?.value != null
          ? (this.costNoAdminUsers = this.selectedOpportunity?.verde_additionaladminextended?.value)
          : '';
        this.selectedOpportunity?.verde_additionaldataextended?.value != null
          ? (this.costDatabaseCapacity = this.selectedOpportunity?.verde_additionaldataextended?.value)
          : '';
        this.selectedOpportunity?.verde_additionalfileextended?.value != null
          ? (this.costFileCapacity = this.selectedOpportunity?.verde_additionalfileextended?.value)
          : '';
        this.selectedOpportunity?.verde_additionallogextended?.value != null
          ? (this.costLogCapacity = this.selectedOpportunity?.verde_additionallogextended?.value)
          : '';
      } else {
        this.noOfEmployees = this.selectedVerdeProduct.verde_minimumemployees;
      }
    }
  }

  createOrUpdateCustomer() {
    let body: ProcessesCustomerDetail = {
      verde_customerdetailid: this.selectedNavigationOption === 'edit' ? this.selectedOpportunity.verde_customerdetailid : null,

      verde_systemusers: this.selectedNavigationOption === 'edit' ? this.addNoSystemUsers : 0,
      verde_additionaladminusers: this.selectedNavigationOption === 'edit' ? this.addNoAdminUsers : 0,
      verde_additionaldatabase: this.selectedNavigationOption === 'edit' ? this.addDatabaseCapacity : 0,
      verde_additionalfile: this.selectedNavigationOption === 'edit' ? this.addFileCapacity : 0,
      verde_additionallog: this.selectedNavigationOption === 'edit' ? this.addLogCapacity : 0,

      verde_legalentity: this.userService.user.legalEntityName,
      verde_fullname: this.userService.user.fullName,
      verde_tenantid: this.authenticationService.authState$.getValue().tenantId,
      verde_email: this.userService.user.email,
      verde_newcustomer: this.newCustomer,
      verde_peoplebasic: this.optionOne,
      verde_peoplefull: this.optionTwo,
      verde_fleet: this.optionThree,
      verde_noofemployees: this.noOfEmployees,
      verde_customercompanyname: this.opportunitiesName,
      verde_calculationstatus: ProcessesVerdeCalculationStatus.Calculating,
      verde_customertype: this.selectedCustomerType,
      verde_product: { id: 'f98ca1b5-2464-ee11-8df0-00224865b68d', primaryName: 'Verde People' },
    };

    this.opportunitiesService.createOrUpdateCustomerDetails(body);

    if (this.selectedNavigationOption === 'add') {
      this.resultMessage = 'Your opportunity has been submitted successfully!';
    } else {
      this.resultMessage = 'Your opportunity has been updated successfully!';
    }

    setTimeout(() => {
      this.showConfirmationModal = true;
    }, 1000);
  }

  closeConfirmationModal() {
    this.showConfirmationModal = false;
    this.sidebarService.setShowSidebar(false);
  }

  enableAddCustomerPanel() {}
}
