/* tslint:disable */
/* eslint-disable */
import { PerformanceReviewBtCycleType } from './performance-review-bt-cycle-type';
import { PerformanceReviewBtPeriod } from './performance-review-bt-period';
import { PerformanceReviewEmployeeReviewFiltered } from './performance-review-employee-review-filtered';
import { PerformanceReviewUser } from './performance-review-user';
export interface PerformanceReviewReviewSummariesFiltered {
  bt_Cycle?: null | string;
  bt_EmployeeId?: null | string;
  bt_Period?: null | string;
  bt_average?: null | number;
  bt_noofcycles?: null | number;
  bt_noofcyclescomms?: null | number;
  bt_noofcycleskpi?: null | number;
  bt_performancereviewsummaryid?: null | string;
  cycle?: PerformanceReviewBtCycleType;
  employee?: PerformanceReviewUser;
  employeePerformanceReview?: PerformanceReviewEmployeeReviewFiltered;
  period?: PerformanceReviewBtPeriod;
}
