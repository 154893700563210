/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DocumentApiConfiguration, DocumentApiConfigurationParams } from './document-api-configuration';

import { DocumentApiDocumentService } from './services/document-api-document.service';
import { DocumentApiVersionService } from './services/document-api-version.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    DocumentApiDocumentService,
    DocumentApiVersionService,
    DocumentApiConfiguration
  ],
})
export class DocumentApiModule {
  static forRoot(params: DocumentApiConfigurationParams): ModuleWithProviders<DocumentApiModule> {
    return {
      ngModule: DocumentApiModule,
      providers: [
        {
          provide: DocumentApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: DocumentApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('DocumentApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
