<div *ngIf="showModal"
     class="verde-modal"
     [style.z-index]="zIndex + 1"
     [style.overflow]="overflowValue">
  <div [ngStyle]="{
      'min-width': modalMinWidth + 'px',
      'max-width': modalMaxWidth + 'px'
    }"
       [@modalEnter]
       [@modalLeave]
       class="verde-modal-content">
    <div *ngIf="showHeader"
         class="verde-modal-header">
      <h6>{{ modalTitle }}</h6>
      <div *ngIf="hasCancelButton"
           class="close-button"
           (click)="mainModalCancel()">
        <mat-icon>close</mat-icon>
      </div>
    </div>
    <div *ngIf="showModal"
         class="verde-modal-body"
         [ngStyle]="{ padding: modalHasPadding ? '20px' : 'unset' }">
      <ng-content></ng-content>
    </div>
    <div *ngIf="showFooter"
         class="verde-modal-footer">
      <div class="button-section">
        <div class="left-section">
          <button *ngIf="linkedToPreviousModal && previousModalName"
                  kendoButton
                  class="new_button close-button"
                  (click)="this.navigateToPreviousModal(previousModalName)">
            Back
          </button>
          <button class="new_button close-button"
                  kendoButton
                  *ngIf="hasCancelButton"
                  (click)="mainModalCancel()">{{ cancelText }}</button>
        </div>
        <div class="right-section">
          <button class="new_button close-button"
                  style="background-color: #cc4a31"
                  kendoButton
                  [disabled]="secondCancelButtonDisabled"
                  *ngIf="hasSecondCancelButton"
                  (click)="mainModalSecondCancel()">
            {{ secondCancelText }}
          </button>
          <button kendoButton
                  class="new_button close-button"
                  *ngIf="saveActionAvailable"
                  [disabled]="!submitActive"
                  (click)="mainModalSave()">
            {{ saveActionButtonText }}
          </button>
          <button *ngIf="submitButton"
                  kendoButton
                  themeColor="primary"
                  class="new_button"
                  [disabled]="!submitActive"
                  (click)="mainModalSubmit()">
            {{ submitButton }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="showModal"
     class="verde-modal-background"
     [style.z-index]="zIndex"
     [@modalBackgroundIn]
     [@modalBackgroundOut]></div>

<div *ngIf="showModal && confirmation"
     class="confirmations-background"
     [style.z-index]="zIndex + 2"
     [@modalBackgroundIn]
     [@modalBackgroundOut]>
  <div class="confirmation-modal verde-modal-content">
    <div class="verde-modal-header">
      <h6>{{ confirmationHeader }}</h6>
      <div class="close-button"
           (click)="cancelConfirmation()">
        <mat-icon>close</mat-icon>
      </div>
    </div>
    <div class="verde-modal-body">
      <p>{{ confirmationMessage }}</p>
      <p *ngIf="submitConfirmationMessageLine2 !== ''"
         style="color: #cc4a31">{{ confirmationMessageLine2 }}</p>
    </div>
    <div class="verde-modal-footer">
      <div class="button-section">
        <button kendoButton
                class="new_button close-button"
                (click)="cancelConfirmation()">{{ confirmationModalCancel }}</button>
        <button kendoButton
                themeColor="primary"
                class="new_button"
                (click)="confirmAction()">{{ confirmationModalSubmit }}</button>
      </div>
    </div>
  </div>
</div>
