/* tslint:disable */
/* eslint-disable */
export interface DvIdentityDocumentsPost {
  bthr_employeeidentityid?: null | string;
  bthr_employeeidentityname?: null | string;
  bthr_expirydate?: null | string;
  bthr_firstname?: null | string;
  bthr_identitynumber?: null | string;
  bthr_identitytype?: null | number;
  bthr_initials?: null | string;
  bthr_issuedate?: null | string;
  bthr_maidenorprevioussurname?: null | string;
  bthr_middlenames?: null | string;
  bthr_recordstatus?: null | number;
  bthr_startdate?: null | string;
  bthr_surname?: null | string;
  countryID?: null | string;
  odataCountryOfIssue?: null | string;
  odataEmployee?: null | string;
}
