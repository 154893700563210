/* tslint:disable */
/* eslint-disable */
export interface TimeOffActivation {
  '_bt_employee_value'?: null | string;
  '_bt_legalentity_value'?: null | string;
  '_bt_timeofftype_value'?: null | string;
  bt_employeetimeofftypeactivationid?: null | string;
  bt_fromdate?: string;
  bt_hours?: number;
  bt_motivation?: null | string;
  bt_timeoffrequesttype?: number;
  bt_timeofftypeactivationno?: null | string;
  bt_todate?: string;
  odataEmployee?: null | string;
  odataLegalEntity?: null | string;
  odataPostEmployee?: null | string;
  odataPostLegalEntity?: null | string;
  odataPostTimeOffType?: null | string;
  odataTimeOffType?: null | string;
}
