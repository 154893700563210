/* tslint:disable */
/* eslint-disable */
import { DocumentDocSalaryAdviceDetail } from './document-doc-salary-advice-detail';
import { DocumentDocSalaryDocType } from './document-doc-salary-doc-type';
import { DocumentDocSalaryEmployee } from './document-doc-salary-employee';
import { DocumentDocSalaryLegalEntity } from './document-doc-salary-legal-entity';
export interface DocumentDocSalaryAdviceHeader {
  base64Image?: null | string;
  bt_DocSalaryAdviceDetail_DocSalaryAdviceH?: null | Array<DocumentDocSalaryAdviceDetail>;
  bt_DocType?: DocumentDocSalaryDocType;
  bt_Doctype?: null | string;
  bt_Employee?: null | string;
  bt_ctccash?: null | string;
  bt_date?: null | string;
  bt_dateengaged?: null | string;
  bt_department?: null | string;
  bt_docsalaryadviceheader?: null | string;
  bt_docsalaryadviceheaderid?: null | string;
  bt_doctypetext?: null | string;
  bt_employee?: DocumentDocSalaryEmployee;
  bt_employeefullname?: null | string;
  bt_employeeidtext?: null | string;
  bt_idnumber?: null | string;
  bt_jobtitle?: null | string;
  bt_legalentity?: DocumentDocSalaryLegalEntity;
  bt_legalentitytext?: null | string;
  bt_netsalary?: null | string;
  bt_paymentmethod?: null | string;
  bt_paypoint?: null | string;
  bt_periodenddate?: null | string;
  bt_periodno?: null | string;
  bt_rateperhour?: null | string;
  bt_taxyear?: null | string;
  bt_totalcc?: null | string;
  bt_totalcompanycontributions?: null | string;
  bt_totaldeductions?: null | string;
  bt_totalearnings?: null | string;
  bt_totalperks?: null | string;
  bt_ytdearnings?: null | string;
  bt_ytdtax?: null | string;
  createdon?: null | string;
  headerURL?: null | string;
}
