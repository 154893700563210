/* tslint:disable */
/* eslint-disable */
export interface DvMembershipsPost {
  bthr_enddate?: null | string;
  bthr_membernumber?: null | string;
  bthr_membershipid?: null | string;
  bthr_organisationtype?: null | number;
  bthr_recordstatus?: null | number;
  bthr_startdate?: null | string;
  odataEmployee?: null | string;
  odataOrganisation?: null | string;
  orgId?: null | string;
}
