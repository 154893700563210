<div class="emp-exit-form">

  <form class="k-form"
        [formGroup]="registerForm"
        style="max-height: 90%; min-height: 80vh;">
    <div class="grid-container">
      <kendo-gridlayout gap="1% 10%"
                        [rows]="[
          { height: 70 },
          { height: 70 },
          { height: 70 },
          { height: 160 },
          { height: 80 },
          { height: 160 },
          { height: 70 },]"
                        [cols]="[{ width: gridColWidth }, { width: gridColWidth }]">

        <!--NOTICE DATE-->
        <kendo-gridlayout-item [row]="2"
                               [col]="1">
          <kendo-formfield class="formfield">
            <label class="exit-form-label"
                   [for]="noticeDate"
                   text="Notice Date">Notice Date:</label>
            <kendo-datepicker #noticeDate
                              formControlName="noticeDate"
                              [format]="'yyyy-MM-dd'"
                              readonly="true">
            </kendo-datepicker>

          </kendo-formfield>
        </kendo-gridlayout-item>
        <!--NOTICE DATE-->
        <!--EXIT DATE-->
        <kendo-gridlayout-item [row]="2"
                               [col]="2">
          <kendo-formfield class="formfield">
            <label class="exit-form-label"
                   [for]="exitDate"
                   text="Exit Date">Exit Date (Last working day):</label>
            <kendo-datepicker #exitDate
                              formControlName="exitDate"
                              [format]="'yyyy-MM-dd'"
                              readonly="true">
            </kendo-datepicker>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <!--END EXIT DATE-->
        <!--TERMINATION TYPE-->
        <kendo-gridlayout-item [row]="1"
                               [col]="1">
          <kendo-formfield class=""
                           style="margin-top:20px">
            <label class="exit-form-label"
                   [for]="resignationType"
                   text="Resignation Type">Termination Type:</label>
            <kendo-textbox class="dropdowns"
                           #resignationType
                           formControlName="resignationType"
                           readonly="true">
            </kendo-textbox>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <!--RESIGNATION TYPE-->
        <!--END COMMENTS-->
        <kendo-gridlayout-item [row]="3"
                               [col]="1"
                               [colSpan]="2">
          <label class="exit-form-label"
                 text="comments">Comment:</label>
          <kendo-editor *ngIf="resignationNotice === 'Both' || resignationNotice === 'NoticeOnScreen'"
                        [resizable]="resizable"
                        style="height: 400px; max-width: 100%"
                        [id]="editorId"
                        formControlName="editor"
                        (valueChange)="editorValueChange($event)"
                        readonly="true"></kendo-editor>
          <div *ngIf="resignationNotice === 'Both' || resignationNotice === 'AttachNotice'"
               style="margin-top: auto;">
            <!--<kendo-fileselect (valueChange)="onFileSelect($event)"> </kendo-fileselect>-->
            <kendo-gridlayout-item [row]="5"
                                   [col]="1">

              <div *ngIf="listofFiles.length > 0">
                <label class="exit-form-label"
                       text="comments">Uploaded Files:</label>
                <ul>
                  <ng-container *ngFor="let file of resignationEdit.annotations; let i = index">
                    <ng-container *ngIf="file.filesize > 0">
                      <li>
                        <a href="javascript:void(0);"
                           (click)="downloadPdf(file.documentbody, file.filename)"
                           style="color: blue">
                          {{ file.filename }}
                        </a>
                      </li>
                    </ng-container>
                  </ng-container>
                </ul>
              </div>

            </kendo-gridlayout-item>
          </div>
        </kendo-gridlayout-item>
        <!--END COMMENTS-->
      </kendo-gridlayout>
    </div>
  </form>
</div>

<div class="k-form-buttons, form-buttons">
  <button style="float: left"
          kendoButton
          themeColor="primary"
          (click)="reversal()">
    Cancel
  </button>
</div>

<verde-modal modalTitle="Confirmation"
             id="reversalModal"
             [hasCancelButton]="true"
             submitButton="Continue"
             (actionButton)="confirmReversal()"
             modalMaxWidth="400">
  <div style="position: relative; height: 60px;">
    <p>{{ confirmMessage }}</p>
  </div>
</verde-modal>