/* tslint:disable */
/* eslint-disable */
import { UserPositionSeatNumberingDto } from './user-position-seat-numbering-dto';
import { UserBtProbation } from './user-bt-probation';
import { UserBtResignationNotice } from './user-bt-resignation-notice';
import { UserBtShiftType } from './user-bt-shift-type';
import { UserBthrEmployeeType } from './user-bthr-employee-type';
export interface UserLegalEntityConfigDto {
  additionalRequired?: null | boolean;
  additionalVisible?: null | boolean;
  address?: null | boolean;
  advancedPolicyManagement?: null | boolean;
  allProxy?: null | boolean;
  allowBookings?: null | boolean;
  allowEmployeePhotoUpload?: null | boolean;
  allowEquipmentRequests?: null | boolean;
  allowManagerDelegation?: null | boolean;
  allowSharing?: null | boolean;
  allowTempCapture?: null | boolean;
  allowances?: null | boolean;
  appConfigId?: null | string;
  autoLoadTraining?: null | boolean;
  bankAccounts?: null | boolean;
  bookingHelp?: null | string;
  bookingPolicy?: null | string;
  bookingProxy?: null | boolean;
  bookings?: null | boolean;
  cards?: null | boolean;
  communication?: null | boolean;
  covid19Test?: null | boolean;
  criminalRecords?: null | boolean;
  defaultEmployeeType?: UserBthrEmployeeType;
  defaultFeelingFineToYes?: null | boolean;
  defaultIsolationDays?: null | number;
  defaultProbationType?: UserBtProbation;
  defaultShiftType?: UserBtShiftType;
  delegationInterGroup?: null | boolean;
  delegationProxy?: null | boolean;
  dependents?: null | boolean;
  detail?: null | boolean;
  detailedPerformanceReviews?: null | boolean;
  disciplinary?: null | boolean;
  displayBirthdays?: null | boolean;
  displayCapping?: null | boolean;
  displayChecklistTab?: null | boolean;
  displayCovid19Test?: null | boolean;
  displayCycleEndDate?: null | boolean;
  displayDocManagement?: null | boolean;
  displayFleetClassifications?: null | boolean;
  displayForfeit?: null | boolean;
  displayIsolation?: null | boolean;
  displayManager?: null | boolean;
  displayOutstandingTasks?: null | boolean;
  displayReturnToWork?: null | boolean;
  displayTakeOn?: null | boolean;
  displayTimeOffPending?: null | boolean;
  documentManagement?: null | boolean;
  documentsAndForms?: null | boolean;
  domain?: null | string;
  driversLicense?: null | boolean;
  employeeAppConfigId?: null | string;
  employeeLifecycle?: null | boolean;
  employeeMask?: null | string;
  employeeNextNumber?: null | string;
  employeeTemporaryMask?: null | string;
  employeeTemporaryNextNumber?: null | string;
  employmentInfo?: null | boolean;
  emppayslipOverrideProxy?: null | boolean;
  enableBuckets?: null | boolean;
  enableCovidRisk?: null | boolean;
  enableGrouping?: null | boolean;
  enableLicenseDiskScanning?: null | boolean;
  enablePolicies?: null | boolean;
  enableRequests?: null | boolean;
  enableSymptoms?: null | boolean;
  enableVoting?: null | boolean;
  engage?: null | boolean;
  engageAka?: null | string;
  equipment?: null | boolean;
  equipmentNewEmployeeApprovedSeat?: null | boolean;
  equipmentTakeOn?: null | boolean;
  financialDocs?: null | boolean;
  fleetBypassScanning?: null | boolean;
  fleetHelp?: null | string;
  fleetNewRequestLeadTime?: null | number;
  fleetPolicy?: null | string;
  fleetProxy?: null | boolean;
  funds?: null | boolean;
  giftAcceptanceMessage?: null | string;
  giftHelp?: null | string;
  giftPolicy?: null | string;
  gifts?: null | boolean;
  giftsProxy?: null | boolean;
  graphIntegration?: null | boolean;
  healthCheckinOnlyAtOffice?: null | boolean;
  healthChecking?: null | boolean;
  healthStatusProxy?: null | boolean;
  helpAddress?: null | string;
  helpAllowances?: null | string;
  helpBankAccounts?: null | string;
  helpBookings?: null | string;
  helpCards?: null | string;
  helpContent?: null | string;
  helpCovid19Test?: null | string;
  helpCovid19VaccinationAcceptance?: null | string;
  helpCriminalRecords?: null | string;
  helpDependents?: null | string;
  helpDisciplinary?: null | string;
  helpDocManagement?: null | string;
  helpDriversLicense?: null | string;
  helpEmploymentInfo?: null | string;
  helpEngageLocation?: null | string;
  helpFunds?: null | string;
  helpHealthCheckin?: null | string;
  helpIdentityDocuments?: null | string;
  helpIncidents?: null | string;
  helpLanguageProficiencies?: null | string;
  helpMedicalInsurance?: null | string;
  helpMedicalRecords?: null | string;
  helpMemberships?: null | string;
  helpMyDetails?: null | string;
  helpMyTeam?: null | string;
  helpPayroll?: null | string;
  helpPerformanceReview?: null | string;
  helpQualifications?: null | string;
  helpReturnToWork?: null | string;
  helpTaxRecords?: null | string;
  helpTravelPermit?: null | string;
  helpVaccination?: null | string;
  helpVoting?: null | string;
  helpVulnerability?: null | string;
  identityDocuments?: null | boolean;
  incidents?: null | boolean;
  intergroupGifts?: null | boolean;
  invoiceRequired?: null | boolean;
  invoiceVisible?: null | boolean;
  isEmployeeAutoNumbering?: null | boolean;
  isEmployeeTemporaryNumber?: null | boolean;
  isEmployeeTypeSpecific?: null | boolean;
  isGroupNumbering?: null | boolean;
  isolationHelp?: null | string;
  isolationPolicy?: null | string;
  issueGift?: null | boolean;
  issuer?: null | string;
  languageProficiencies?: null | boolean;
  learning?: null | boolean;
  legalEntityId?: null | string;
  legalEntityName?: null | string;
  locationCheckin?: null | boolean;
  locationProxy?: null | boolean;
  maintainFleetSuppliers?: null | boolean;
  managerAssignment?: null | boolean;
  managerProxy?: null | boolean;
  manualDocs?: null | boolean;
  maxUnitsVariance?: null | number;
  medicalInsurance?: null | boolean;
  medicalRecords?: null | boolean;
  memberships?: null | boolean;
  menuAutoCollapse?: null | boolean;
  menuDefaultExpand?: null | boolean;
  minMonthlyHours?: null | number;
  minWeeklyHours?: null | number;
  myDetails?: null | boolean;
  myProfile?: null | boolean;
  myTeam?: null | boolean;
  newEmployeePosition?: null | boolean;
  newsFeed?: null | string;
  other?: null | boolean;
  payroll?: null | boolean;
  payslipProxy?: null | boolean;
  peerReviewTcs?: null | string;
  peerReviews?: null | boolean;
  people?: null | boolean;
  performanceReview?: null | boolean;
  policyAddress?: null | string;
  policyAllowances?: null | string;
  policyBankAccounts?: null | string;
  policyBookings?: null | string;
  policyCards?: null | string;
  policyCovid19Test?: null | string;
  policyCovid19VaccinationAcceptance?: null | string;
  policyCriminalRecords?: null | string;
  policyDependents?: null | string;
  policyDisciplinary?: null | string;
  policyDocManagement?: null | string;
  policyDriversLicense?: null | string;
  policyEmploymentInfo?: null | string;
  policyEngageLocation?: null | string;
  policyFunds?: null | string;
  policyHealthCheckin?: null | string;
  policyIdentityDocuments?: null | string;
  policyIncidents?: null | string;
  policyLanguageProficiencies?: null | string;
  policyMedicalInsurance?: null | string;
  policyMedicalRecords?: null | string;
  policyMemberships?: null | string;
  policyMyDetails?: null | string;
  policyMyTeam?: null | string;
  policyPayroll?: null | string;
  policyPerformanceReview?: null | string;
  policyQualifications?: null | string;
  policyReturnToWork?: null | string;
  policyTaxRecords?: null | string;
  policyTravelPermit?: null | string;
  policyUrl?: null | string;
  policyVaccination?: null | string;
  policyVoting?: null | string;
  policyVulnerability?: null | string;
  positionSeatNumbering?: UserPositionSeatNumberingDto;
  profile?: null | boolean;
  proofOfVaccination?: null | boolean;
  qualifications?: null | boolean;
  reassignTaskToManager?: null | boolean;
  reassignToTeam?: null | boolean;
  receivingGift?: null | boolean;
  registrationDocRequired?: null | boolean;
  registrationDocVisible?: null | boolean;
  reportIssue?: null | boolean;
  resignationNotice?: UserBtResignationNotice;
  returnToWork?: null | boolean;
  reviewLabel?: null | string;
  reviewShortLabel?: null | string;
  reviews?: null | boolean;
  reviewsEnhancement?: null | boolean;
  riskDisplayHighRiskPosition?: null | boolean;
  riskDisplayVulnerable?: null | boolean;
  selfAssignTask?: null | boolean;
  showCompanyLogo?: null | boolean;
  socialMediaFeed?: null | string;
  structureManagement?: null | boolean;
  successionAllowGroupEntities?: null | boolean;
  successorManagement?: null | boolean;
  summary?: null | boolean;
  support?: null | boolean;
  takeOnEquipmentQty?: null | boolean;
  taskHelp?: null | string;
  taskProxy?: null | boolean;
  tasks?: null | boolean;
  taxRecords?: null | boolean;
  teamAssignment?: null | boolean;
  timeOff?: null | boolean;
  timeOffBalance?: null | boolean;
  timeOffCapturing?: null | boolean;
  timeOffProxy?: null | boolean;
  timeTracker?: null | boolean;
  timeTrackerHelp?: null | string;
  timeTrackerPolicy?: null | string;
  timeTrackerProxy?: null | boolean;
  trackMe?: null | boolean;
  travelPermit?: null | boolean;
  updateAddress?: null | boolean;
  updateBankAccounts?: null | boolean;
  updateCovid19Test?: null | boolean;
  updateDependents?: null | boolean;
  updateDriversLicense?: null | boolean;
  updateIdentityDocs?: null | boolean;
  updateIsolation?: null | boolean;
  updateLanguage?: null | boolean;
  updateMedicalRecords?: null | boolean;
  updateMemberships?: null | boolean;
  updatePerformanceReview?: null | boolean;
  updatePermits?: null | boolean;
  updatePersonalInfo?: null | boolean;
  updateQualifications?: null | boolean;
  updateReturnToWork?: null | boolean;
  updateRiskManagement?: null | boolean;
  updateTaxRecords?: null | boolean;
  updateVaccinations?: null | boolean;
  updateVulnerability?: null | boolean;
  updatedEmploymentInfo?: null | boolean;
  useMonitor?: null | boolean;
  vaccination?: null | boolean;
  vaccinationAcceptance?: null | boolean;
  vaccinationPassport?: null | boolean;
  vaccinationRequirement?: null | boolean;
  vehicles?: null | boolean;
  verdeConfigName?: null | string;
  votingInterGroup?: null | boolean;
  votingLabel?: null | string;
  voxWeather?: null | boolean;
  vulnerability?: null | boolean;
  vulnerabilityAssessment?: null | boolean;
}
