/* tslint:disable */
/* eslint-disable */
import { PerformanceReviewGuidExtrapolate } from './performance-review-guid-extrapolate';
import { PerformanceReviewBtPeerReviewStatus } from './performance-review-bt-peer-review-status';
import { PerformanceReviewBthrRecordStatus } from './performance-review-bthr-record-status';
export interface PerformanceReviewDvPerformanceReview {
  bt_Period?: null | string;
  bt_ReviewTemplate?: null | string;
  bt_Summary?: null | string;
  bt_commission?: null | boolean;
  bt_duedate?: null | string;
  bt_employeeapprovaldate?: null | string;
  bt_employeecompleted?: null | boolean;
  bt_employeerecallaccepted?: null | boolean;
  bt_employeerecallaccepteddate?: null | string;
  bt_employeereviewstatus?: PerformanceReviewBtPeerReviewStatus;
  bt_kpi?: null | boolean;
  bt_managerapprovaldate?: null | string;
  bt_managercompleted?: null | boolean;
  bt_managerrecallrequest?: null | boolean;
  bt_managerrecallrequestdate?: null | string;
  bt_managerreviewstatus?: PerformanceReviewBtPeerReviewStatus;
  bt_reviewenhancement?: null | boolean;
  bt_totalscore?: null | number;
  bthr_Employee?: null | string;
  bthr_Performancereviewcycle?: null | string;
  bthr_employeeperformancereviewid?: null | string;
  bthr_name?: null | string;
  bthr_performancereviewdocument_name?: null | string;
  bthr_recordstatus?: PerformanceReviewBthrRecordStatus;
  bthr_reviewdate?: null | string;
  bthr_reviewperiodenddate?: null | string;
  bthr_reviewperiodstartdate?: null | string;
  bthr_summaryofresults?: null | string;
  cycle?: PerformanceReviewGuidExtrapolate;
  employee?: PerformanceReviewGuidExtrapolate;
  performancereviewcycle?: PerformanceReviewGuidExtrapolate;
  period?: PerformanceReviewGuidExtrapolate;
  reviewTemplate?: PerformanceReviewGuidExtrapolate;
  summary?: PerformanceReviewGuidExtrapolate;
}
