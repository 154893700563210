/* tslint:disable */
/* eslint-disable */
import { ProcessesDataQuery } from './processes-data-query';
import { ProcessesBtEmployeeLifecycleStage } from './processes-bt-employee-lifecycle-stage';
import { ProcessesBtPositionseat } from './processes-bt-positionseat';
export interface ProcessesGetPositionSeatsQuery {
  employeeLifeCycleStage?: ProcessesBtEmployeeLifecycleStage;
  legalEntity?: null | string;
  query?: ProcessesDataQuery;
  seatStatus?: ProcessesBtPositionseat;
}
