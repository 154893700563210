import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { UserAppConfigDto, UserUserDto, IssueModalComponent } from '@verde/shared';
import { UserService, WindowService } from '@verde/core';
import { PageContainerCustomButton } from '../../models/custom-buttons';

@Component({
  selector: 'verde-page-container',
  templateUrl: './page-container.component.html',
  styleUrls: ['./page-container.component.scss'],
})
export class PageContainerComponent implements OnInit, OnDestroy {
  onDestroy$ = new Subject<boolean>();

  @ViewChild('issueModal') issueModal: IssueModalComponent | undefined;
  @Input() customButtons: PageContainerCustomButton[] = [];
  @Input() spinnerID: string = '';
  @Input() loading: boolean = false;
  @Input() showProxy: boolean = true;
  @Input() issueLocation: string = '';
  @Input() policyURL: string = '';
  @Input() helpURL: string = '';
  @Input() activeproxyUser: UserUserDto = {};

  @Output() userChanged: EventEmitter<UserUserDto> = new EventEmitter<UserUserDto>();

  user: UserUserDto;
  proxyUser: UserUserDto;
  showIssueModal: boolean = false;
  currentSelectedId: string = '';

  constructor(private spinner: NgxSpinnerService, private userService: UserService, private windowService: WindowService) {}

  get config(): UserAppConfigDto {
    return this.userService.config$.getValue();
  }

  get windowWidth(): number {
    return this.windowService.windowWidth;
  }

  ngOnInit(): void {
    this.user = this.userService.user;
    if (this.activeproxyUser) {
      this.proxyUser = this.activeproxyUser;
    } else {
      this.proxyUser = this.userService.user;
    }

    this.currentSelectedId = this.proxyUser.employeeId as string;
    this.userService.changeProxyUser(this.proxyUser);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  refresh() {
    this.loadTeam();
  }

  loadTeam(): void {
    this.spinner.show(this.spinnerID);
    // this.userService
    //   .getTeam()
    //   .pipe(take(1))
    //   .subscribe(
    //     () => this.spinner.hide(this.spinnerID),
    //     () => this.spinner.hide(this.spinnerID),
    //     () => this.spinner.hide(this.spinnerID),
    //   );
  }

  applyNewUser(e: UserUserDto) {
    //this.proxyUser = this.userService.team.find((o) => this.currentSelectedId === o.bthr_employeeid) as User;
    if (e.employeeId === this.user?.employeeId) {
      this.proxyUser = this.user;
    } else {
      this.userService.team.forEach((t) => {
        if (t.employeeId === e.employeeId) {
          this.proxyUser = t;
        }
      });
    }

    this.userChanged.emit(this.proxyUser);
    this.userService.changeProxyUser(this.proxyUser);
  }

  // #region Help, Policy and Issue Methods
  // Open Help or Policy
  openHelpOrPolicy(url: string | null | undefined) {
    if (url) {
      window.open(url, '_blank');
    }
  }

  // Report Issue
  reportIssue(loc: string) {
    this.issueLocation = loc;
    this.showIssueModal = true;
    setTimeout(() => {
      if (this.issueModal) {
        this.issueModal.openReportIssueModal();
      }
    }, 50);
  }

  hideIssueModal(e: boolean) {
    this.showIssueModal = e;
  }
}
