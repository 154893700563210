/* tslint:disable */
/* eslint-disable */
import { QualificationsGuidExtrapolate } from './qualifications-guid-extrapolate';
import { QualificationsBtValidationType } from './qualifications-bt-validation-type';
import { QualificationsBthrInstitutiontype } from './qualifications-bthr-institutiontype';
export interface QualificationsBthrEducationalInstitution {
  accreditationAuthorityLookup?: QualificationsGuidExtrapolate;
  bt_validated?: null | boolean;
  bt_validatedby?: null | string;
  bt_validatedbyentity?: null | string;
  bt_validationtype?: QualificationsBtValidationType;
  bthr_EducationalInstitutionid?: null | string;
  bthr_accreditationauthoritylookup?: null | string;
  bthr_country?: null | string;
  bthr_id?: null | string;
  bthr_institutiontype?: QualificationsBthrInstitutiontype;
  bthr_name?: null | string;
  country?: QualificationsGuidExtrapolate;
  validatedBy?: QualificationsGuidExtrapolate;
  validatedByEntity?: QualificationsGuidExtrapolate;
}
