/* tslint:disable */
/* eslint-disable */
export interface DvMedicalSchemes {
  bthr_employeemedicalschemeid?: null | string;
  bthr_entrydate?: null | string;
  bthr_exitdate?: null | string;
  bthr_membershipnumber?: null | string;
  bthr_name?: null | string;
  bthr_numberofdependents?: null | number;
  odataRecordStatus?: null | string;
  odataSchemeName?: null | string;
  odataStatus?: null | string;
}
