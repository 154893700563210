/* tslint:disable */
/* eslint-disable */
export interface VehicleAccessoryLink {
  '_bthr_vehicle_value'?: null | string;
  bt_value?: null | number;
  bthr_description?: null | string;
  bthr_linkno?: null | string;
  bthr_serial?: null | string;
  bthr_vehicleattachmentlinkid?: null | string;
  odataAccessory?: null | string;
  odataPostAccessory?: null | string;
  odataPostLegalEntity?: null | string;
  odataPostVehicle?: null | string;
  odataValue?: null | string;
  odataVehicle?: null | string;
  statecode?: null | number;
}
