/* tslint:disable */
/* eslint-disable */
import { DocumentBtProcessStatus } from './document-bt-process-status';
export interface DocumentPostVerdeBulkControlCommand {
  bt_documenttype?: null | string;
  bt_importbyentity?: null | string;
  bt_importedby?: null | string;
  bt_legalentity?: null | string;
  bt_processcomment?: null | string;
  bt_status?: DocumentBtProcessStatus;
  recordId?: null | string;
}
