/* tslint:disable */
/* eslint-disable */
export interface VerdeWorkflowLog {
  bt_actiondate?: null | string;
  bt_approveremail?: null | string;
  bt_approverno?: null | number;
  bt_completed?: null | boolean;
  bt_escalation?: null | boolean;
  bt_finalstatus?: null | string;
  bt_response?: null | string;
  bt_sourcetransactionno?: null | string;
  bt_totalapprovers?: null | number;
  bt_transaction?: null | string;
}
