/* tslint:disable */
/* eslint-disable */
export interface TrainingLinks {
  '_bt_appconfig_value'?: null | string;
  '_bt_legalentity_value'?: null | string;
  '_bt_role_value'?: null | string;
  bt_external?: null | boolean;
  bt_link?: null | string;
  bt_module?: null | number;
  bt_sequenceno?: null | number;
  bt_trainingdescription?: null | string;
  bt_trainingname?: null | string;
  bt_verdetraininglinkno?: null | string;
  bt_verdetraininglinksid?: null | string;
  odataAppConfig?: null | string;
  odataLegalEntity?: null | string;
  odataModule?: null | string;
  odataRole?: null | string;
}
