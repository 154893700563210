/* tslint:disable */
/* eslint-disable */
export interface VerdePolicyAcceptance {
  '_bt_employee_value'?: null | string;
  '_bt_legalentity_value'?: null | string;
  '_bt_policy_value'?: null | string;
  '_bt_task_value'?: null | string;
  bt_accepted?: boolean;
  bt_accepteddate?: null | string;
  bt_policyreleasedate?: null | string;
  bt_verdepolicyacceptanceid?: null | string;
  bt_verdepolicyacceptanceno?: null | string;
  bt_version?: null | string;
  odataPostEmployee?: null | string;
  odataPostLegalEntity?: null | string;
  odataPostPolicy?: null | string;
  odataPostTask?: null | string;
}
