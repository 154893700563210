import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Subject, takeUntil } from 'rxjs';
import { UserService } from '@verde/core';
import {
  DotsMenuItem,
  UserSecurityFunctionDto,
  UserAppConfigDto,
  CommonPositionSeatRequest,
  CommonApiWebDynamicService,
  VerdeDynamicPageService,
  SidePanelWidth,
} from '@verde/shared';

@Component({
  selector: 'formly-grid-input',
  template: `
    <div style="height: 80vh">
      <kendo-grid [data]="gridData" [resizable]="true" [scrollable]="'scrollable'" [sortable]="true" [groupable]="true">
        <ng-template kendoGridToolbarTemplate style="height: 57px">
          <kendo-grid-spacer></kendo-grid-spacer>

          <button style="height: 36px; margin-left: auto" kendoGridExcelCommand icon="file-excel">Export to Excel</button>
          <img
            title="Refresh"
            class="refresh_icon"
            src="https://engageimages.z1.web.core.windows.net/Images/Verde/Other/reload.svg"
            alt=""
            (click)="refresh()"
          />
        </ng-template>
        <kendo-grid-column *ngFor="let column of columns" [field]="column.field" [title]="column.title" [width]="column.width"> </kendo-grid-column>
        <kendo-grid-command-column title="" [width]="40" [sticky]="true">
          <ng-template kendoGridCellTemplate let-ListTable>
            <verde-dots-menu-button
              [items]="dotMenuItems"
              (menuClicked)="menuClicked(ListTable)"
              (menuItemClicked)="menuItemClicked($event)"
            ></verde-dots-menu-button>
          </ng-template>
        </kendo-grid-command-column>
      </kendo-grid>
    </div>
  `,
})
export class KendoGridFormlyComponentKendo extends FieldType implements OnInit {
  private onDestroy$ = new Subject<boolean>();

  //three dot menu
  dotMenuItems: DotsMenuItem[] = [{}];
  peopleManagementThreeDotMenuFunctions: Array<UserSecurityFunctionDto> = new Array<UserSecurityFunctionDto>();
  config: UserAppConfigDto = {};
  gridData: any[];
  columns: any[];
  selectedrequest: CommonPositionSeatRequest;

  constructor(
    private commonApiCommonService: CommonApiWebDynamicService,
    private userService: UserService,
    private verdeDynamicPageService: VerdeDynamicPageService,
  ) {
    super();
  }

  ngOnInit() {
    this.gridData = this.field.formControl.value['gridData'] || [];
    this.columns = this.field.formControl.value['columns'] || [];
    //console.log("AAAAAAA", this.field, this.model)

    this.commonApiCommonService
      .getPositionSeatRequests({ body: { processStatus: '532525252', verdeStandardProcessName: 'Seat' } })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (val) => {
          this.gridData = val;
        },
        () => {},
        () => {
          this.refresh();
        },
      );
    this.userService.config$.pipe(takeUntil(this.onDestroy$)).subscribe((config) => {
      if (config !== null && config !== undefined && Object.keys(config).length > 0) {
        this.config = config;
      }
    });
    this.getThreeDotMenuFunctions();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  getThreeDotMenuFunctions() {
    //People Management Three Dot Menu
    this.peopleManagementThreeDotMenuFunctions = [];
    const initialFunctions = this.userService.filterUserPermissions('People', 'People Management Three Dot Menu', false).sort((a, b) => {
      if (a.menuName && b.menuName) {
        const nameA = a.menuName.toLowerCase();
        const nameB = b.menuName.toLowerCase();
        if (nameA < nameB) {
          //sort string ascending
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
      }

      return 0;
    });

    initialFunctions.forEach((t) => {
      if (this.config?.legalEntityConfig[t.configFieldName] || t.configFieldName === 'null') {
        this.peopleManagementThreeDotMenuFunctions.push(t);
      }
    });
  }

  refresh() {}

  menuClicked(e) {
    this.selectedrequest = e;
    this.dotMenuItems = [];
    const tempItems: DotsMenuItem[] = [];
    this.peopleManagementThreeDotMenuFunctions.forEach((t) => {
      if (t.technicalName === 'PEOPLE-GRID-LINE-MENU-EDIT-POSITION-SEATS') {
        tempItems.push({ id: t.technicalName, text: t.menuName });
      }
    });
    this.dotMenuItems = [...tempItems];
  }

  menuItemClicked(e: DotsMenuItem) {
    if (e.id === 'PEOPLE-GRID-LINE-MENU-EDIT-POSITION-SEATS') {
      this.verdeDynamicPageService.setDynamicForm(
        [this.selectedrequest.bt_verdestructurechangerequestid, 'SidePanel-e355075c-fb48-ef11-accd-00224899d283-192554303'],
        'sidepanel',
        [true, SidePanelWidth.Half, 'verdeDynamicForm'],
      );
    }
  }
}
