/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { GiftsBaseService } from '../gifts-base-service';
import { GiftsApiConfiguration } from '../gifts-api-configuration';
import { GiftsStrictHttpResponse } from '../gifts-strict-http-response';
import { GiftsRequestBuilder } from '../gifts-request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { GiftsVerdeVersion } from '../models/gifts-verde-version';

@Injectable({
  providedIn: 'root',
})
export class GiftsApiVersionService extends GiftsBaseService {
  constructor(
    config: GiftsApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getVersionNumber
   */
  static readonly GetVersionNumberPath = '/api/Version/GetVersionNumber';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVersionNumber()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVersionNumber$Response(params?: {
  }): Observable<GiftsStrictHttpResponse<GiftsVerdeVersion>> {

    const rb = new GiftsRequestBuilder(this.rootUrl, GiftsApiVersionService.GetVersionNumberPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as GiftsStrictHttpResponse<GiftsVerdeVersion>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVersionNumber$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVersionNumber(params?: {
  }): Observable<GiftsVerdeVersion> {

    return this.getVersionNumber$Response(params).pipe(
      map((r: GiftsStrictHttpResponse<GiftsVerdeVersion>) => r.body as GiftsVerdeVersion)
    );
  }

}
