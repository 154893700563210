/* tslint:disable */
/* eslint-disable */
export interface DvBankAccountsPost {
  bankID?: null | string;
  branchID?: null | string;
  bthr_accountholdername?: null | string;
  bthr_accountholderrealtionshipreason?: null | string;
  bthr_accountholderrelationship?: null | string;
  bthr_accountnumber?: null | string;
  bthr_accounttype?: null | number;
  bthr_employeebankaccountid?: null | string;
  bthr_name?: null | string;
  bthr_paymentmethod?: null | number;
  bthr_recordstatus?: null | number;
  odataBank?: null | string;
  odataBankBranch?: null | string;
  odataEmployee?: null | string;
}
