/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { StructureBaseService } from '../structure-base-service';
import { StructureApiConfiguration } from '../structure-api-configuration';
import { StructureStrictHttpResponse } from '../structure-strict-http-response';
import { StructureRequestBuilder } from '../structure-request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { StructureAllowanceLink } from '../models/structure-allowance-link';
import { StructureCreateStructureChangeRequestsCommand } from '../models/structure-create-structure-change-requests-command';
import { StructureGetAllowanceLinkQuery } from '../models/structure-get-allowance-link-query';
import { StructureGetDepartmentsQuery } from '../models/structure-get-departments-query';
import { StructureGetDivisionsQuery } from '../models/structure-get-divisions-query';
import { StructureGetFullStructureQuery } from '../models/structure-get-full-structure-query';
import { StructureGetLegalEntitiesQuery } from '../models/structure-get-legal-entities-query';
import { StructureGetManagerStructureLvlQuery } from '../models/structure-get-manager-structure-lvl-query';
import { StructureGetPositionSeatsQuery } from '../models/structure-get-position-seats-query';
import { StructureGetSecurityProfileQuery } from '../models/structure-get-security-profile-query';
import { StructureGetStructureChangeRequestsQuery } from '../models/structure-get-structure-change-requests-query';
import { StructureSecurityProfile } from '../models/structure-security-profile';
import { StructureStructureChangeRequest } from '../models/structure-structure-change-request';
import { StructureStructureDepartment } from '../models/structure-structure-department';
import { StructureStructureDivision } from '../models/structure-structure-division';
import { StructureStructureFull } from '../models/structure-structure-full';
import { StructureStructureLegalEntity } from '../models/structure-structure-legal-entity';
import { StructureStructureLvl } from '../models/structure-structure-lvl';
import { StructureStructurePositionSeat } from '../models/structure-structure-position-seat';

@Injectable({
  providedIn: 'root',
})
export class StructureApiStructureService extends StructureBaseService {
  constructor(
    config: StructureApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation structureGetAllSeatsExcludingManager
   */
  static readonly StructureGetAllSeatsExcludingManagerPath = '/api/Structure/structure_position_seats';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `structureGetAllSeatsExcludingManager()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  structureGetAllSeatsExcludingManager$Response(params?: {
    tenantId?: string;
    body?: StructureGetPositionSeatsQuery
  }): Observable<StructureStrictHttpResponse<Array<StructureStructurePositionSeat>>> {

    const rb = new StructureRequestBuilder(this.rootUrl, StructureApiStructureService.StructureGetAllSeatsExcludingManagerPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StructureStrictHttpResponse<Array<StructureStructurePositionSeat>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `structureGetAllSeatsExcludingManager$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  structureGetAllSeatsExcludingManager(params?: {
    tenantId?: string;
    body?: StructureGetPositionSeatsQuery
  }): Observable<Array<StructureStructurePositionSeat>> {

    return this.structureGetAllSeatsExcludingManager$Response(params).pipe(
      map((r: StructureStrictHttpResponse<Array<StructureStructurePositionSeat>>) => r.body as Array<StructureStructurePositionSeat>)
    );
  }

  /**
   * Path part for operation structureGetAllDepartments
   */
  static readonly StructureGetAllDepartmentsPath = '/api/Structure/structure_departments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `structureGetAllDepartments()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  structureGetAllDepartments$Response(params?: {
    tenantId?: string;
    body?: StructureGetDepartmentsQuery
  }): Observable<StructureStrictHttpResponse<Array<StructureStructureDepartment>>> {

    const rb = new StructureRequestBuilder(this.rootUrl, StructureApiStructureService.StructureGetAllDepartmentsPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StructureStrictHttpResponse<Array<StructureStructureDepartment>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `structureGetAllDepartments$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  structureGetAllDepartments(params?: {
    tenantId?: string;
    body?: StructureGetDepartmentsQuery
  }): Observable<Array<StructureStructureDepartment>> {

    return this.structureGetAllDepartments$Response(params).pipe(
      map((r: StructureStrictHttpResponse<Array<StructureStructureDepartment>>) => r.body as Array<StructureStructureDepartment>)
    );
  }

  /**
   * Path part for operation structureGetAllDivisions
   */
  static readonly StructureGetAllDivisionsPath = '/api/Structure/structure_divisions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `structureGetAllDivisions()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  structureGetAllDivisions$Response(params?: {
    tenantId?: string;
    body?: StructureGetDivisionsQuery
  }): Observable<StructureStrictHttpResponse<Array<StructureStructureDivision>>> {

    const rb = new StructureRequestBuilder(this.rootUrl, StructureApiStructureService.StructureGetAllDivisionsPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StructureStrictHttpResponse<Array<StructureStructureDivision>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `structureGetAllDivisions$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  structureGetAllDivisions(params?: {
    tenantId?: string;
    body?: StructureGetDivisionsQuery
  }): Observable<Array<StructureStructureDivision>> {

    return this.structureGetAllDivisions$Response(params).pipe(
      map((r: StructureStrictHttpResponse<Array<StructureStructureDivision>>) => r.body as Array<StructureStructureDivision>)
    );
  }

  /**
   * Path part for operation structureGetAllLegalEntities
   */
  static readonly StructureGetAllLegalEntitiesPath = '/api/Structure/structure_legal_entities';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `structureGetAllLegalEntities()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  structureGetAllLegalEntities$Response(params?: {
    tenantId?: string;
    body?: StructureGetLegalEntitiesQuery
  }): Observable<StructureStrictHttpResponse<Array<StructureStructureLegalEntity>>> {

    const rb = new StructureRequestBuilder(this.rootUrl, StructureApiStructureService.StructureGetAllLegalEntitiesPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StructureStrictHttpResponse<Array<StructureStructureLegalEntity>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `structureGetAllLegalEntities$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  structureGetAllLegalEntities(params?: {
    tenantId?: string;
    body?: StructureGetLegalEntitiesQuery
  }): Observable<Array<StructureStructureLegalEntity>> {

    return this.structureGetAllLegalEntities$Response(params).pipe(
      map((r: StructureStrictHttpResponse<Array<StructureStructureLegalEntity>>) => r.body as Array<StructureStructureLegalEntity>)
    );
  }

  /**
   * Path part for operation getFullStructure
   */
  static readonly GetFullStructurePath = '/api/Structure/get_full_structure';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFullStructure()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getFullStructure$Response(params?: {
    tenantId?: string;
    body?: StructureGetFullStructureQuery
  }): Observable<StructureStrictHttpResponse<Array<StructureStructureFull>>> {

    const rb = new StructureRequestBuilder(this.rootUrl, StructureApiStructureService.GetFullStructurePath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StructureStrictHttpResponse<Array<StructureStructureFull>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFullStructure$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getFullStructure(params?: {
    tenantId?: string;
    body?: StructureGetFullStructureQuery
  }): Observable<Array<StructureStructureFull>> {

    return this.getFullStructure$Response(params).pipe(
      map((r: StructureStrictHttpResponse<Array<StructureStructureFull>>) => r.body as Array<StructureStructureFull>)
    );
  }

  /**
   * Path part for operation getSecurityProfile
   */
  static readonly GetSecurityProfilePath = '/api/Structure/get_SecurityProfile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSecurityProfile()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getSecurityProfile$Response(params?: {
    tenantId?: string;
    body?: StructureGetSecurityProfileQuery
  }): Observable<StructureStrictHttpResponse<Array<StructureSecurityProfile>>> {

    const rb = new StructureRequestBuilder(this.rootUrl, StructureApiStructureService.GetSecurityProfilePath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StructureStrictHttpResponse<Array<StructureSecurityProfile>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSecurityProfile$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getSecurityProfile(params?: {
    tenantId?: string;
    body?: StructureGetSecurityProfileQuery
  }): Observable<Array<StructureSecurityProfile>> {

    return this.getSecurityProfile$Response(params).pipe(
      map((r: StructureStrictHttpResponse<Array<StructureSecurityProfile>>) => r.body as Array<StructureSecurityProfile>)
    );
  }

  /**
   * Path part for operation getAllowanceLink
   */
  static readonly GetAllowanceLinkPath = '/api/Structure/get_AllowanceLink';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllowanceLink()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAllowanceLink$Response(params?: {
    tenantId?: string;
    body?: StructureGetAllowanceLinkQuery
  }): Observable<StructureStrictHttpResponse<Array<StructureAllowanceLink>>> {

    const rb = new StructureRequestBuilder(this.rootUrl, StructureApiStructureService.GetAllowanceLinkPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StructureStrictHttpResponse<Array<StructureAllowanceLink>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllowanceLink$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAllowanceLink(params?: {
    tenantId?: string;
    body?: StructureGetAllowanceLinkQuery
  }): Observable<Array<StructureAllowanceLink>> {

    return this.getAllowanceLink$Response(params).pipe(
      map((r: StructureStrictHttpResponse<Array<StructureAllowanceLink>>) => r.body as Array<StructureAllowanceLink>)
    );
  }

  /**
   * Path part for operation createStructureChangeRequests
   */
  static readonly CreateStructureChangeRequestsPath = '/api/Structure/create_changerequest';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createStructureChangeRequests()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createStructureChangeRequests$Response(params?: {
    tenantId?: string;
    body?: StructureCreateStructureChangeRequestsCommand
  }): Observable<StructureStrictHttpResponse<boolean>> {

    const rb = new StructureRequestBuilder(this.rootUrl, StructureApiStructureService.CreateStructureChangeRequestsPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StructureStrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createStructureChangeRequests$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createStructureChangeRequests(params?: {
    tenantId?: string;
    body?: StructureCreateStructureChangeRequestsCommand
  }): Observable<boolean> {

    return this.createStructureChangeRequests$Response(params).pipe(
      map((r: StructureStrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation getStructureChangeRequests
   */
  static readonly GetStructureChangeRequestsPath = '/api/Structure/get_changerequest';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getStructureChangeRequests()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getStructureChangeRequests$Response(params?: {
    tenantId?: string;
    body?: StructureGetStructureChangeRequestsQuery
  }): Observable<StructureStrictHttpResponse<Array<StructureStructureChangeRequest>>> {

    const rb = new StructureRequestBuilder(this.rootUrl, StructureApiStructureService.GetStructureChangeRequestsPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StructureStrictHttpResponse<Array<StructureStructureChangeRequest>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getStructureChangeRequests$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getStructureChangeRequests(params?: {
    tenantId?: string;
    body?: StructureGetStructureChangeRequestsQuery
  }): Observable<Array<StructureStructureChangeRequest>> {

    return this.getStructureChangeRequests$Response(params).pipe(
      map((r: StructureStrictHttpResponse<Array<StructureStructureChangeRequest>>) => r.body as Array<StructureStructureChangeRequest>)
    );
  }

  /**
   * Path part for operation getManagerStructureLvl
   */
  static readonly GetManagerStructureLvlPath = '/api/Structure/getManagerStructureLvl';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getManagerStructureLvl()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getManagerStructureLvl$Response(params?: {
    tenantId?: string;
    body?: StructureGetManagerStructureLvlQuery
  }): Observable<StructureStrictHttpResponse<Array<StructureStructureLvl>>> {

    const rb = new StructureRequestBuilder(this.rootUrl, StructureApiStructureService.GetManagerStructureLvlPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StructureStrictHttpResponse<Array<StructureStructureLvl>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getManagerStructureLvl$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getManagerStructureLvl(params?: {
    tenantId?: string;
    body?: StructureGetManagerStructureLvlQuery
  }): Observable<Array<StructureStructureLvl>> {

    return this.getManagerStructureLvl$Response(params).pipe(
      map((r: StructureStrictHttpResponse<Array<StructureStructureLvl>>) => r.body as Array<StructureStructureLvl>)
    );
  }

}
