/* tslint:disable */
/* eslint-disable */
export enum ProcessesBtVerdeStandardProcess {
  QualificationEmployeeAddModify = 'QualificationEmployeeAddModify',
  QualificationEmployeeRenewAddModify = 'QualificationEmployeeRenewAddModify',
  QualificationRequestnewQualification = 'QualificationRequestnewQualification',
  QualificationRequestnewInstitution = 'QualificationRequestnewInstitution',
  StructureRequestPositionSeat = 'StructureRequestPositionSeat',
  StructureRequestPosition = 'StructureRequestPosition',
  StructureRequestDepartment = 'StructureRequestDepartment',
  StructureRequestDivision = 'StructureRequestDivision',
  StructureRequestLegalEnity = 'StructureRequestLegalEnity',
  OffboardNotice = 'OffboardNotice',
  OffboardExit = 'OffboardExit',
  StructureTransfertoEntity = 'StructureTransfertoEntity',
  StructureTransfertoDepartment = 'StructureTransfertoDepartment',
  StructureTransfertoPositionSeat = 'StructureTransfertoPositionSeat',
  StructureTransferSeattoPosition = 'StructureTransferSeattoPosition',
  StructureReplacePositionSeat = 'StructureReplacePositionSeat',
  FleetInfringementRedirect = 'FleetInfringementRedirect'
}
