import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject, take, takeUntil } from 'rxjs';
import { StepperComponent } from '@progress/kendo-angular-layout';
import { HrPortalService } from '../../../../../../../../../apps/verde/src/app/features/hr-portal/services/hr-portal.service';
import { forkJoin } from 'rxjs';
import { NotificationService } from '@progress/kendo-angular-notification';
import {
  ProcessesProcessNationality,
  ProcessesLocalResidencyStatus,
  ProcessesProcessCountry,
  ProcessesCitizenshipStatus,
  ProcessesEmployeeType,
  ProcessesProbation,
  ProcessesShiftType,
  ProcessesApiProcessesService,
  ProcessesGetLocalResidencyStatusQuery,
  ProcessesValidateIdNumberQuery,
  ProcessesValidateTaxNumberQuery,
  ProcessesEmployeeOnboarding,
  ProcessesBtEmployeeStatusEnum,
  ProcessesGetCitizenshipStatusQuery,
  ProcessesPositionSeats,
  ProcessesProcessesEmployee,
  DelegationsApiDelegationsService,
  UserAppConfigDto,
} from '@verde/shared';
import { ModalService } from '../../../../modals/modal.service';
import { VerdeApprovalService } from '../../../../services/verde-approval/verde-approval.service';
import { saveAs } from 'file-saver';
import { UserService, ViewSDKClient } from '@verde/core';
import moment from 'moment';
declare var AdobeDC: any;

@Component({
  selector: 'verde-employee-onboarding-edit',
  templateUrl: './employee-onboarding-edit.component.html',
  styleUrls: ['./employee-onboarding-edit.component.scss'],
})
export class EmployeeOnboardingEditComponent implements OnInit {
  private onDestroy$ = new Subject<boolean>();

  @ViewChild('appendTo', { read: ViewContainerRef, static: false })
  public appendTo: ViewContainerRef;

  public loadingPanelVisible = true;

  @ViewChild('stepper', { static: true })
  public stepper: StepperComponent;
  public currentStep = 0;
  private sumbitted = false;

  private isStepValid = (index: number): boolean => {
    return this.getGroupAt(index).valid;
  };

  private shouldValidate = (): boolean => {
    return this.sumbitted === true;
  };

  public steps = [
    {
      label: 'Structure',
      isValid: this.isStepValid,
      validate: this.shouldValidate,
    },
    {
      label: 'Required Info',
      isValid: this.isStepValid,
      validate: this.shouldValidate,
    },
    {
      label: 'Nationality',
      isValid: this.isStepValid,
      validate: this.shouldValidate,
    },
    {
      label: 'Tax Info',
      isValid: this.isStepValid,
      validate: this.shouldValidate,
    },
  ];

  nationalities: any[] = [];
  countries: any[] = [];
  localResidencyStatus: any[] = [];
  citizenStatus: any[] = [];
  employeeType: any[] = [];
  probation: any[] = [];
  shiftType: any[] = [];
  onboardingEmployeeObj: any[] = [];

  cvBase64: any;


  public form = new FormGroup({
    structure: new FormGroup({
      description: new FormControl(),
      position: new FormControl(),
      division: new FormControl(),
      department: new FormControl(),
      legalEntity: new FormControl(),
      cvDoc: new FormControl(null),
    }),
    accountDetails: new FormGroup({
      startDate: new FormControl(null, Validators.required),
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      middleNames: new FormControl(''),
      preferredName: new FormControl(''),
      empNumber: new FormControl(''),
      employeeTypes: new FormControl(null, Validators.required),
      probationType: new FormControl(),
      shiftTypes: new FormControl(),
    }),
    personalDetails: new FormGroup({
      countryResidence: new FormControl(null, Validators.required),
      localResidency: new FormControl(null, Validators.required),
      primaryNationality: new FormControl(null, Validators.required),
      citizenshipStatus: new FormControl(null, Validators.required),
      foreignNational: new FormControl(),
      idNumber: new FormControl('', [Validators.required]),
      idUpload: new FormControl(null),
    }),
    passportInfo: new FormGroup({
      hasPassport: new FormControl('yes',),
      passportCountry: new FormControl(null,),
      passportNumber: new FormControl('',),
      passportExpDate: new FormControl(null,),
      passportUpload: new FormControl(null,),
    }),
    paymentDetails: new FormGroup({
      taxNoOutstanding: new FormControl(null, Validators.required),
      taxNo: new FormControl(''),
    }),
  });

  positionSeat: ProcessesPositionSeats;
  taxValidation: string = '';

  newEmpGuid: string;

  testCountry: string = '';
  validationMessage: string;
  taxValidationMessage: string;
  nationalitiesObj: ProcessesProcessNationality;
  localResidencyStatusObj: ProcessesLocalResidencyStatus;
  CountryObj: ProcessesProcessCountry;
  validateYesNo: boolean;
  citizenStatusObj: ProcessesCitizenshipStatus;
  employeeTypeObj: ProcessesEmployeeType;
  probationObj: ProcessesProbation;
  shiftTypeObj: ProcessesShiftType;
  confirmMessage: string;
  onboardingEmployee: ProcessesProcessesEmployee;
  listofFiles: any[];
  public config: UserAppConfigDto;
  totalSteps: number = 4;
  count: number = 3;
  passportStep: number = 10;
  taxStep: number = 3;
  foreignNational: boolean;

  paymentDetails: any
  personalDetails: any;

  constructor(
    private processesApiProcessesService: ProcessesApiProcessesService,
    private sidebarService: VerdeApprovalService,
    private refreshService: HrPortalService,
    private modalService: ModalService,
    private notificationService: NotificationService,
    private viewSDKClient: ViewSDKClient,
    private userService: UserService,
  ) {}

  ngOnInit(): void {

    this.paymentDetails = this.form.controls.paymentDetails;
    this.personalDetails = this.form.controls.personalDetails;

    this.userService.config$.pipe(takeUntil(this.onDestroy$)).subscribe((data) => {
      this.config = data;
    });

    this.sidebarService
      .getPositionSeat()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((data: any) => {
        this.positionSeat = data;
        this.testCountry = this.positionSeat.bt_legalentityCountryText;
      });

    
    let body: any = {
      stateCode: true,
    };
    let body2: any = {
      legalEntity: this.positionSeat.cr1f2_legalentity.id,
    };   

    const observables = [
      this.processesApiProcessesService.getProcessCountry({ body: body }),
      this.processesApiProcessesService.getProcessNationality({ body: body2 }),
      this.processesApiProcessesService.getEmployeeType({ body: body2 }),
      this.processesApiProcessesService.getProbation({ body: body2 }),
      this.processesApiProcessesService.getShiftType({ body: body2 }),
      this.processesApiProcessesService.getEmployeeOnboarding({ body: {employeeID: this.positionSeat.employee.id} })
    ];

    forkJoin(observables)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (results) => {
          this.countries = results[0];
          this.nationalities = results[1];
          this.employeeType = results[2];
          this.probation = results[3];
          this.shiftType = results[4];
          this.onboardingEmployeeObj = results[5];
          this.onboardingEmployee = this.onboardingEmployeeObj[0];
          console.log('dsfsdfsfsfsfsfsffs', this.onboardingEmployee)
          this.countries.sort((a, b) => {
            if (a.bthr_country < b.bthr_country) {
              return -1;
            } else if (a.bthr_country > b.bthr_country) {
              return 1;
            } else {
              return 0;
            }
          })

          this.citizenStatus.sort((a, b) => {
            if (a.bt_statusname < b.bt_statusname) {
              return -1;
            } else if (a.bt_statusname > b.bt_statusname) {
              return 1;
            } else {
              return 0;
            }
          })

          this.nationalities.sort((a, b) => {
            if (a.bthr_nationality < b.bthr_nationality) {
              return -1;
            } else if (a.bthr_nationality > b.bthr_nationality) {
              return 1;
            } else {
              return 0;
            }
          })

          this.employeeType.sort((a, b) => {
            if (a.bthr_employeetype < b.bthr_employeetype) {
              return -1;
            } else if (a.bthr_employeetype > b.bthr_employeetype) {
              return 1;
            } else {
              return 0;
            }
          })

          this.probation.sort((a, b) => {
            if (a.bt_probationname < b.bt_probationname) {
              return -1;
            } else if (a.bt_probationname > b.bt_probationname) {
              return 1;
            } else {
              return 0;
            }
          })

          this.shiftType.sort((a, b) => {
            if (a.bt_shifttype < b.bt_shifttype) {
              return -1;
            } else if (a.bt_shifttype > b.bt_shifttype) {
              return 1;
            } else {
              return 0;
            }
          })

          if (this.onboardingEmployee.bt_countryofresidence) {
            this.CountryObj = this.countries.find((c) => c.bthr_country === String(this.onboardingEmployee.bt_countryofresidence.primaryName));
            if (this.CountryObj) {
              this.form.get('personalDetails.countryResidence').setValue(this.CountryObj);
              this.setResidency(this.CountryObj);
            }
          }

          //if (this.onboardingEmployee.bt_citizenshipstatus) {
          //  this.citizenStatusObj = this.citizenStatus.find(
          //    (c) => c.bt_statusname === String(this.onboardingEmployee.bt_citizenshipstatus.primaryName),
          //  );
          //  if (this.citizenStatusObj) {
          //    /*this.form.get('personalDetails.citizenshipStatus').setValue(this.citizenStatusObj);*/
          //  }
          //}

          if (this.onboardingEmployee.bt_nationality) {
            this.nationalitiesObj = this.nationalities.find((c) => c.bthr_nationality === String(this.onboardingEmployee.bt_nationality.primaryName));
            if (this.nationalitiesObj) {
              this.form.get('personalDetails.primaryNationality').setValue(this.nationalitiesObj);
              this.setDefaultCitizenStatus(this.nationalitiesObj);
            }
          }

          if (this.onboardingEmployee.bthr_employeetype) {
            this.employeeTypeObj = this.employeeType.find((c) => c.bthr_employeetype === String(this.onboardingEmployee.bthr_employeetype.primaryName));
            if (this.employeeTypeObj) {
              this.form.get('accountDetails.employeeTypes').setValue(this.employeeTypeObj);
            }
          }

          if (this.onboardingEmployee.bt_probationtype) {
            this.probationObj = this.probation.find((c) => c.bt_probationname === String(this.onboardingEmployee.bt_probationtype.primaryName));
            if (this.probationObj) {
              this.form.get('accountDetails.probationType').setValue(this.probationObj);
            }
          }

          if (this.onboardingEmployee.bt_shifttype) {
            this.shiftTypeObj = this.shiftType.find((c) => c.bt_shifttype === String(this.onboardingEmployee.bt_shifttype.primaryName));
            if (this.shiftTypeObj) {
              this.form.get('accountDetails.shiftTypes').setValue(this.shiftTypeObj);
            }
          }
        },
        (error) => {
          console.error(error);
        },
        () => {
          if (this.positionSeat && this.onboardingEmployee) {
            this.form.get('accountDetails.firstName').setValue(this.onboardingEmployee.bt_firstname);
            this.form.get('accountDetails.lastName').setValue(this.onboardingEmployee.bt_lastname);
            if (this.onboardingEmployee.bthr_middlenames) {
              this.form.get('accountDetails.middleNames').setValue(this.onboardingEmployee.bthr_middlenames);
            } else {
              this.form.get('accountDetails.middleNames').setValue('');
            }
            if (this.onboardingEmployee.bthr_preferredname) {
              this.form.get('accountDetails.preferredName').setValue(this.onboardingEmployee.bthr_preferredname);
            } else {
              this.form.get('accountDetails.preferredName').setValue('');
            }
            if (this.onboardingEmployee.employeeNumber) {
              this.form.get('accountDetails.empNumber').setValue(this.onboardingEmployee.employeeNumber);
            } else {
              this.form.get('accountDetails.empNumber').setValue('');
            }
            let dateConvert = new Date(this.onboardingEmployee.bt_neworganizationjoindate);
            this.form.get('accountDetails.startDate').setValue(dateConvert);
            if (this.onboardingEmployee.passportExpDate) {
              let passExpDate = new Date(this.onboardingEmployee.passportExpDate);
              this.form.get('passportInfo.passportExpDate').setValue(passExpDate);
            } else {
              let todayDate = new Date();
              this.form.get('passportInfo.passportExpDate').setValue(todayDate);
            }
            
            if (this.onboardingEmployee.passportNumber) {
              this.form.get('passportInfo.passportNumber').setValue(this.onboardingEmployee.passportNumber);
            } else {
              this.form.get('passportInfo.passportNumber').setValue('');
            }
            
            this.form.get('personalDetails.idNumber').setValue(this.onboardingEmployee.bthr_nationalidnumber);
            if (this.onboardingEmployee.bt_taxnoouts != null) {
              this.form.get('paymentDetails.taxNoOutstanding').setValue(this.onboardingEmployee.bt_taxnoouts ? 'yes' : 'no');
              if (!this.onboardingEmployee.bt_taxnoouts) {
                this.form.get('paymentDetails.taxNo').setValue(this.onboardingEmployee.bthr_taxnumber);
              }
            }
            if (this.onboardingEmployee.hasPassport) {
              this.form.get('passportInfo.hasPassport').setValue(this.onboardingEmployee.hasPassport ? 'yes' : 'no');
            }
          }
          this.loadingPanelVisible = false;
        },
      );
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  setDefaultCitizenStatus(nationality: ProcessesProcessNationality) {
    let body: ProcessesGetCitizenshipStatusQuery
    if (nationality.bthr_nationalityid) {
      body = {
        nationalityID: nationality.bthr_nationalityid
      }
    } else {
      body = {
        nationalityID: '00000000-0000-0000-0000-000000000000'
      }
    }
    this.processesApiProcessesService
      .getCitizenshipStatus({ body: body })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (val) => {
          this.citizenStatus = val;
        },
        (error) => {
          console.error(error);
        },
        () => {
          this.citizenStatusObj = this.citizenStatus.find((c) => c.bt_statusname === String(this.onboardingEmployee.bt_citizenshipstatus.primaryName));
          if (this.citizenStatusObj) {
            this.form.get('personalDetails.citizenshipStatus').setValue(this.citizenStatusObj);
          }
        },
      );
  }

  setResidency(country) {
    if (country) {
      let body: ProcessesGetLocalResidencyStatusQuery = {
        countryID: country.bthr_countryid,
      };
      this.processesApiProcessesService
        .getLocalResidencyStatus({ body: body })
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          (val) => {
            this.localResidencyStatus = val;
          },
          (error) => {
            console.error(error);
          },
          () => {
            this.localResidencyStatusObj = this.localResidencyStatus.find(
              (c) => c.bt_localresidencystatus === String(this.onboardingEmployee.bt_localresidencystatus?.primaryName),
            );
            if (this.localResidencyStatusObj) {
              this.form.get('personalDetails.localResidency').setValue(this.localResidencyStatusObj);
              this.validateYesNo = this.localResidencyStatusObj.bt_validateid;
              this.validateIDNumber();
            }
          },
        );
    }
  }

  validateIDNumber() {
    if (this.positionSeat && this.onboardingEmployee) {
      let body: ProcessesValidateIdNumberQuery = {
        countryName: this.onboardingEmployee.bt_nationality?.primaryName,
        idNumber: this.onboardingEmployee.bthr_nationalidnumber,
        validateId: this.validateYesNo,
      };

      this.processesApiProcessesService
        .validateIdNumber({ body: body })
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          (val) => {
            this.validationMessage = val;
          },
          (error) => {
            console.error(error);
          },
          () => {},
        );
    }
  }

  public get currentGroup(): FormGroup {
    return this.getGroupAt(this.currentStep);
  }

  public next(): void {
    this.currentStep += 1;
  }

  public prev(): void {
    this.currentStep -= 1;
  }

  public submit() {
    this.sumbitted = true;
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      this.stepper.validateSteps();
    } else {
      this.modalService.open('confirmModal');
      this.confirmMessage = 'Please confirm employee onboarding edit';
    }
  }

  confirmOnboarding() {
    let validation: ProcessesValidateIdNumberQuery = {
      countryName: this.form.value.personalDetails.countryResidence.bthr_country,
      idNumber: this.form.value.personalDetails.idNumber,
      validateId: this.form.value.personalDetails.localResidency.bt_validateid,
    };
    this.processesApiProcessesService
      .validateIdNumber({ body: validation })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (val) => {
          this.validationMessage = val;
        },
        (error) => {
          console.error(error);
        },
        () => {
          this.taxNumberValidation();
        },
      );
  }

  taxNumberValidation() {
    if (this.form.value.paymentDetails.taxNoOutstanding === 'no') {
      let validation: ProcessesValidateTaxNumberQuery = {
        countryName: this.form.value.personalDetails.primaryNationality.bthr_nationality,
        taxNumber: this.form.value.paymentDetails.taxNo,
      };
      this.processesApiProcessesService
        .validateTaxNumber({ body: validation })
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          (val) => {
            this.taxValidationMessage = val;
          },
          (error) => {
            console.error(error);
          },
          () => {
            this.patchEmployeeOnboarding();
          },
        );
    } else {
      this.patchEmployeeOnboardingNoTaxNo();
    }
  }

  patchEmployeeOnboarding() {
    if (
      (this.validationMessage === 'Cannot be validated' || this.validationMessage === 'Valid - South African ID number') &&
      (this.taxValidationMessage === 'Valid - ' + this.form.value.personalDetails.primaryNationality.bthr_nationality + ' Tax number' ||
        this.taxValidationMessage ===
          "Invalid - Can't be validated as " + this.form.value.personalDetails.primaryNationality.bthr_nationality + ' is not catered for.')
    ) {
      let forNational: boolean;
      if (this.form.value.personalDetails.foreignNational === 'yes') {
        forNational = true;
      } else {
        forNational = false;
      }

      let outTax: boolean;
      if (this.form.value.paymentDetails.taxNoOutstanding === 'yes') {
        outTax = true;
      } else {
        outTax = false;
      }
      let passportYN: boolean;
      if (this.form.value.passportInfo.hasPassport === 'yes') {
        passportYN = true;
      } else {
        passportYN = false;
      }

      let empStatus: ProcessesBtEmployeeStatusEnum;
      if (new Date(this.form.value.accountDetails.startDate) > new Date) {
        empStatus = ProcessesBtEmployeeStatusEnum.PreEmployment
      } else {
        empStatus = ProcessesBtEmployeeStatusEnum.Employed
      }

      let body: ProcessesEmployeeOnboarding = {
        bthr_employeeid: this.onboardingEmployee.employeeID,
        bthr_firstname: this.form.value.accountDetails.firstName,
        bthr_lastname: this.form.value.accountDetails.lastName,
        bthr_legalentity: this.positionSeat.cr1f2_legalentity.id,
        bthr_foreignnational: forNational,
        bthr_nationalidnumber: this.form.value.personalDetails.idNumber,
        bthr_employeetype: this.form.value.accountDetails.employeeTypes.bthr_employeetypeid,
        bthr_taxnumber: this.form.value.paymentDetails.taxNo,
        bt_countryofresidence: this.form.value.personalDetails.countryResidence.bthr_countryid,
        bt_nationality: this.form.value.personalDetails.primaryNationality.bthr_nationalityid,
        bt_taxnoouts: outTax,
        bt_citizenshipstatus: this.form.value.personalDetails.citizenshipStatus.bt_citizenshipstatusid,
        bt_localresidencystatus: this.form.value.personalDetails.localResidency.bt_localresidencystatusid,
        bt_shifttype: this.form.value.accountDetails.shiftTypes.bt_shifttypeid,
        bt_probationtype: this.form.value.accountDetails.probationType.bt_probationid,
        seat: this.positionSeat.bthr_positionseatid,
        bt_neworganizationjoindate: this.form.value.accountDetails.startDate,
        bt_EmployeeStatus: empStatus,
        bthr_middlenames: this.form.value.accountDetails.middleNames,
        bthr_preferredname: this.form.value.accountDetails.preferredName,
        bthr_employee_id: this.form.value.accountDetails.empNumber,
      };
      if (forNational == true) {
        body.hasPassport = passportYN,
          body.passportCountry = this.form.value.personalDetails.countryResidence.bthr_countryid,
          body.passportExpDate = this.form.value.passportInfo.passportExpDate,
          body.passportNumber = this.form.value.passportInfo.passportNumber
      }

      this.loadingPanelVisible = true;
      this.processesApiProcessesService
        .patchEmployeeOnboarding({
          body: {
            body: body,
          },
        })
        .pipe(take(1))
        .subscribe(
          () => {
            //
          },
          (error) => {
            console.error(error);
          },
          () => {
            this.loadingPanelVisible = false;
            this.sidebarService.setShowSidebar(false);
            this.refreshService.emitParameter(this.positionSeat.cr1f2_legalentity.id);
            if (this.form.value.personalDetails.idUpload != null) {
              this.uploadIddoc();
            } else {
              this.uploadCVDoc();
            }
          },
        );
    } else {
      if (this.validationMessage === 'Cannot be validated' || this.validationMessage === 'Valid - South African ID number') {
        this.showError(this.taxValidationMessage);
      } else if (
        this.taxValidationMessage === 'Valid - ' + this.form.value.personalDetails.primaryNationality.bthr_nationality + ' Tax number' ||
        this.taxValidationMessage ===
          "Invalid - Can't be validated as " + this.form.value.personalDetails.primaryNationality.bthr_nationality + ' is not catered for.'
      ) {
        this.showError(this.validationMessage);
      } else {
        this.showError(this.validationMessage);
        this.showError(this.taxValidationMessage);
      }
    }
  }

  patchEmployeeOnboardingNoTaxNo() {
    if (this.validationMessage === 'Cannot be validated' || this.validationMessage === 'Valid - South African ID number') {
      let forNational: boolean;
      if (this.form.value.personalDetails.foreignNational === 'yes') {
        forNational = true;
      } else {
        forNational = false;
      }

      let outTax: boolean;
      if (this.form.value.paymentDetails.taxNoOutstanding === 'yes') {
        outTax = true;
      } else {
        outTax = false;
      }
      let passportYN: boolean;
      if (this.form.value.passportInfo.hasPassport === 'yes') {
        passportYN = true;
      } else {
        passportYN = false;
      }

      let empStatus: ProcessesBtEmployeeStatusEnum;
      if (new Date(this.form.value.accountDetails.startDate) > new Date) {
        empStatus = ProcessesBtEmployeeStatusEnum.PreEmployment
      } else {
        empStatus = ProcessesBtEmployeeStatusEnum.Employed
      }

      let body: ProcessesEmployeeOnboarding = {
        bthr_employeeid: this.onboardingEmployee.employeeID,
        bthr_firstname: this.form.value.accountDetails.firstName,
        bthr_lastname: this.form.value.accountDetails.lastName,
        bthr_legalentity: this.positionSeat.cr1f2_legalentity.id,
        bthr_foreignnational: forNational,
        bthr_nationalidnumber: this.form.value.personalDetails.idNumber,
        bthr_employeetype: this.form.value.accountDetails.employeeTypes.bthr_employeetypeid,
        bthr_taxnumber: '',
        bt_countryofresidence: this.form.value.personalDetails.countryResidence.bthr_countryid,
        bt_nationality: this.form.value.personalDetails.primaryNationality.bthr_nationalityid,
        bt_taxnoouts: outTax,
        bt_citizenshipstatus: this.form.value.personalDetails.citizenshipStatus.bt_citizenshipstatusid,
        bt_localresidencystatus: this.form.value.personalDetails.localResidency.bt_localresidencystatusid,
        bt_shifttype: this.form.value.accountDetails.shiftTypes.bt_shifttypeid,
        bt_probationtype: this.form.value.accountDetails.probationType.bt_probationid,
        seat: this.positionSeat.bthr_positionseatid,
        bt_neworganizationjoindate: this.form.value.accountDetails.startDate,
        bt_EmployeeStatus: empStatus,
        bthr_middlenames: this.form.value.accountDetails.middleNames,
        bthr_preferredname: this.form.value.accountDetails.preferredName,
        bthr_employee_id: this.form.value.accountDetails.empNumber,
      };
      if (forNational == true) {
        body.hasPassport = passportYN,
          body.passportCountry = this.form.value.personalDetails.countryResidence.bthr_countryid,
          body.passportExpDate = this.form.value.passportInfo.passportExpDate,
          body.passportNumber = this.form.value.passportInfo.passportNumber
      }
      this.loadingPanelVisible = true;
      this.processesApiProcessesService
        .patchEmployeeOnboarding({
          body: {
            body: body,
          },
        })
        .pipe(take(1))
        .subscribe(
          () => {
            //
          },
          (error) => {
            console.error(error);
          },
          () => {
            this.loadingPanelVisible = false;
            this.sidebarService.setShowSidebar(false);
            this.refreshService.emitParameter(this.positionSeat.cr1f2_legalentity.id);
            if (this.form.value.personalDetails.idUpload != null) {
              this.uploadIddoc();
            } else {
              this.uploadCVDoc();
            }
          },
        );
    } else {
      this.showError(this.validationMessage);
    }
  }

  public showError(errorMsg: string): void {
    this.notificationService.show({
      appendTo: this.appendTo,
      content: errorMsg,
      position: { horizontal: 'right', vertical: 'top' },
      animation: { type: 'fade', duration: 600 },
      type: { style: 'error', icon: true },
      hideAfter: 2000,
    });
  }

  uploadIddoc() {
    if (this.form.value.personalDetails.idUpload != null) {
      let empFullName = this.onboardingEmployee.fullName = this.onboardingEmployee.fullName.replace(' ', '');
      const idFile = this.renameFile(this.form.value.personalDetails.idUpload[0], 'ID_' + empFullName + '_' + moment().format('YYYY-MM-DD, h:mm:ss a'))
      let uploadBody: any = {
        file: idFile,
        recordID: this.onboardingEmployee.employeeID,
        entityName: 'bthr_employee',
        columnName: 'bthr_nationalidpassport',
      };

      this.processesApiProcessesService
        .uploadOnboardingFile({
          body: uploadBody,
        })
        .pipe(take(1))
        .subscribe(
          () => {
            //
          },
          (error) => {
            console.error(error);
          },
          () => {
            if (this.form.value.structure.cvDoc != null) {
              this.uploadCVDoc();
            }
          },
        );
    } else {

    }
  }

  uploadCVDoc() {
    if (this.form.value.structure.cvDoc != null) {
      let empFullName = this.onboardingEmployee.fullName = this.onboardingEmployee.fullName.replace(' ', '');
      const cvFile = this.renameFile(this.form.value.structure.cvDoc[0], 'CV_' + empFullName + '_' + moment().format('YYYY-MM-DD, h:mm:ss a'))
      let uploadBody: any = {
        file: cvFile,
        recordID: this.onboardingEmployee.employeeID,
        entityName: 'bthr_employee',
        columnName: 'bt_cvdocument',
      };

      this.processesApiProcessesService
        .uploadOnboardingFile({
          body: uploadBody,
        })
        .pipe(take(1))
        .subscribe(
          () => {
            //
          },
          (error) => {
            console.error(error);
          },
          () => {
            if(this.form.value.passportInfo.passportUpload != null) {
              this.uploadPassport();
            }
          },
        );
    }
  }

  uploadPassport() {
    let empFullName = this.onboardingEmployee.fullName = this.onboardingEmployee.fullName.replace(' ', '');
    const passportFile = this.renameFile(this.form.value.passportInfo.passportUpload[0], 'PASSPORT_' + empFullName + '_' + moment().format('YYYY-MM-DD, h:mm:ss a'))
    let uploadBody: any = {
      file: passportFile,
      recordID: this.newEmpGuid,
      entityName: 'bthr_employee',
      columnName: 'bt_passport',
    };

    this.processesApiProcessesService
      .uploadOnboardingFile({
        body: uploadBody,
      })
      .pipe(take(1))
      .subscribe(
        () => {
          //
        },
        (error) => {
          console.error(error);
        },
        () => {

        },
      );
  }

  private getGroupAt(index: number): FormGroup {
    const groups = Object.keys(this.form.controls).map((groupName) => this.form.get(groupName)) as FormGroup[];

    return groups[index];
  }

  onValidateTaxNumber() {
    if (this.form.value.personalDetails.countryResidence != null) {
      this.validateTaxNumber();
    } else {
      alert('Country cant be empty');
    }
  }

  validateTaxNumber() {
    let validateTax: ProcessesValidateTaxNumberQuery = {
      countryName: this.form.value.personalDetails.primaryNationality.bthr_nationality,
      taxNumber: this.form.value.paymentDetails.taxNo,
    };
    this.processesApiProcessesService
      .validateTaxNumber({ body: validateTax })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (val) => {
          this.taxValidation = val;
        },
        () => {},
      );
  }


  renameFile(file: File, newBaseName: string): File {
    const originalFileName = file.name;
    const fileExtension = originalFileName.substring(originalFileName.lastIndexOf('.'));
    const newFileName = `${newBaseName}${fileExtension}`;
    return new File([file], newFileName, { type: file.type });
  }
  handleChildEvent(data: boolean) {

    if (data == true) {
      this.steps = [
        {
          label: 'Structure',
          isValid: this.isStepValid,
          validate: this.shouldValidate,
        },
        {
          label: 'Required Info',
          isValid: this.isStepValid,
          validate: this.shouldValidate,
        },
        {
          label: 'Nationality',
          isValid: this.isStepValid,
          validate: this.shouldValidate,
        },
        {
          label: 'Passport',
          isValid: this.isStepValid,
          validate: this.shouldValidate,
        },
        {
          label: 'Tax Info',
          isValid: this.isStepValid,
          validate: this.shouldValidate,
        },
      ];
      this.totalSteps = 5;
      this.count = 4;
      this.passportStep = 3;
      this.taxStep = 4;
      this.foreignNational = true;
    } else if (data == false) {
      this.steps = [
        {
          label: 'Structure',
          isValid: this.isStepValid,
          validate: this.shouldValidate,
        },
        {
          label: 'Required Info',
          isValid: this.isStepValid,
          validate: this.shouldValidate,
        },
        {
          label: 'Nationality',
          isValid: this.isStepValid,
          validate: this.shouldValidate,
        },
        {
          label: 'Tax Info',
          isValid: this.isStepValid,
          validate: this.shouldValidate,
        },
      ];
      this.totalSteps = 4;
      this.count = 3;
      this.taxStep = 3;
      this.passportStep = 10;
      this.foreignNational = false;
    }
  }
}
