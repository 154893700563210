/* tslint:disable */
/* eslint-disable */
export interface UserPostUserControlQueryCommand {
  employeeId?: null | string;
  employeeTypeId?: null | string;
  fromStage?: null | string;
  fromStatus?: null | string;
  legalEntityId?: null | string;
  processLegalEntityId?: null | string;
  processedByEmployeeId?: null | string;
  requireEmployeeNumber?: null | boolean;
  toStage?: null | string;
  toStatus?: null | string;
}
