/* tslint:disable */
/* eslint-disable */
import { ProcessesExitReason } from './processes-exit-reason';
import { ProcessesLookupExtrapolate } from './processes-lookup-extrapolate';
import { ProcessesProcessesLegalEntity } from './processes-processes-legal-entity';
import { ProcessesVerdeAnnotations } from './processes-verde-annotations';
import { ProcessesBtProcessStatus } from './processes-bt-process-status';
import { ProcessesBthrTerminationreason } from './processes-bthr-terminationreason';
export interface ProcessesEmployeeExit {
  annotations?: null | Array<ProcessesVerdeAnnotations>;
  bt_ProcessStatus?: ProcessesBtProcessStatus;
  bt_comment?: null | string;
  bt_employee?: ProcessesLookupExtrapolate;
  bt_employeeexitdetailsid?: string;
  bt_exitdate?: null | string;
  bt_exitreason?: ProcessesExitReason;
  bt_fullname?: null | string;
  bt_legalentity?: ProcessesProcessesLegalEntity;
  bt_noticedate?: null | string;
  bt_processLegalEntity?: string;
  bt_processedBy?: string;
  employeeID?: string;
  exitReasonID?: string;
  filesToUpload?: null | Array<Blob>;
  legalEntityID?: string;
  noticeType?: ProcessesBthrTerminationreason;
}
