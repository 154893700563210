/* tslint:disable */
/* eslint-disable */
import { EquipmentEquipmentLegalEntity } from './equipment-equipment-legal-entity';
import { EquipmentEquipmentSpecType } from './equipment-equipment-spec-type';
import { EquipmentEquipmentType } from './equipment-equipment-type';
export interface EquipmentEquipmentSpec {
  bt_EquipmentSpecType?: EquipmentEquipmentSpecType;
  bt_EquipmentType?: EquipmentEquipmentType;
  bt_LegalEntity?: EquipmentEquipmentLegalEntity;
  bt_estimatedvalue?: null | number;
  bt_verdeequipmentspec?: null | string;
  bt_verdeequipmentspecid?: string;
}
