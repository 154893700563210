import { Component, Input } from '@angular/core';
import { PeerReviewFeedback } from '@verde/shared';

@Component({
  selector: 'verde-response-option',
  templateUrl: './response-option.component.html',
  styleUrls: ['./response-option.component.scss'],
})
export class ResponseOptionComponent {
  @Input() set setReviewOption(val: PeerReviewFeedback) {
    this.reviewOption = val;
  }

  reviewOption: PeerReviewFeedback = {};
}
