/* tslint:disable */
/* eslint-disable */
import { PerformanceReviewBthrPerformanceReviewCycle } from './performance-review-bthr-performance-review-cycle';
import { PerformanceReviewGuidExtrapolate } from './performance-review-guid-extrapolate';
import { PerformanceReviewUser } from './performance-review-user';
export interface PerformanceReviewPerformanceReviewsFiltered {
  bt_attachmentfilename?: null | string;
  bt_commission?: null | boolean;
  bt_employeeapprovaldate?: null | string;
  bt_employeecompleted?: null | boolean;
  bt_employeerecallaccepted?: null | boolean;
  bt_employeerecallaccepteddate?: null | string;
  bt_kpi?: null | boolean;
  bt_managerapprovaldate?: null | string;
  bt_managercompleted?: null | boolean;
  bt_managerrecallrequest?: null | boolean;
  bt_managerrecallrequestdate?: null | string;
  bt_reviewenhancement?: null | boolean;
  bt_totalscore?: null | number;
  bthr_Employee?: null | string;
  bthr_Period?: null | string;
  bthr_employeeperformancereviewid?: null | string;
  bthr_performancereviewcycle?: null | string;
  bthr_reviewdate?: null | string;
  bthr_summaryofresults?: null | string;
  employee?: PerformanceReviewUser;
  performanceReviewCycle?: PerformanceReviewBthrPerformanceReviewCycle;
  period?: PerformanceReviewGuidExtrapolate;
}
