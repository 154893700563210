<div style="overflow-x: hidden; max-width:inherit">
  <br>
  <form #dynamicSubmissionForm="ngForm"
        novalidate
        autocomplete="off"
        class="dynamic-form"
        [formGroup]="dynamicData.columnOneForm">
    <formly-form class="form-group dynamic-formly-form"
                 [model]="dynamicData.columnOneModel"
                 [fields]="dynamicData.columnOneFormfields"
                 [form]="dynamicData.columnOneForm">
    </formly-form>
  </form>

  <table>
    <tr>
      <th style="width:100%">

      </th>
      <th style=" width: fit-content; height:fit-content;">
        <button id="submit-button"
                kendoButton
                style="margin-bottom: 20px;"
                themeColor="primary"
                [disabled]="!dynamicSubmissionForm.valid"
                (click)="submit(dynamicData)">
          Submit
        </button>
      </th>
    </tr>
  </table>


  <!-- #region Confirmation Modal -->
  <kendo-dialog *ngIf="showConfirmationModal"
                title="Confirmation"
                [width]="500">
    <div style="height: 60px">
      <p>
        {{ resultMessage }}
      </p>
    </div>
    <div class="confirmation-modal-footer">
      <button kendoButton
              themeColor="primary"
              style="margin-left: auto"
              (click)="closeConfirmationModal()">
        Continue
      </button>
    </div>
  </kendo-dialog>
  <!-- #endregion Confirmation Modal -->

</div>
