/* tslint:disable */
/* eslint-disable */
export interface DvAddresses {
  bthr_address1?: null | string;
  bthr_address2?: null | string;
  bthr_address3?: null | string;
  bthr_addressid?: null | string;
  bthr_addressidid?: null | string;
  bthr_addressidtype?: null | number;
  bthr_city?: null | string;
  bthr_code?: null | string;
  bthr_contactname?: null | string;
  bthr_email?: null | string;
  bthr_email2?: null | string;
  bthr_latitude?: null | string;
  bthr_longitude?: null | string;
  bthr_phone1?: null | string;
  bthr_phone2?: null | string;
  bthr_phone3?: null | string;
  bthr_stateprovince?: null | string;
  bthr_website?: null | string;
  odataAddressType?: null | string;
  odataCountry?: null | string;
  odataLegalEntity?: null | string;
  odataRecordStatus?: null | string;
  odataStatus?: null | string;
}
