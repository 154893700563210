export abstract class LocalStorageHelpers {
  public static getItem(key: string): any {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : undefined;
  }

  public static storeItem(key: string, item: any): void {
    localStorage.setItem(key, JSON.stringify(item));
  }
}
