/* tslint:disable */
/* eslint-disable */
export interface DvIncidents {
  bthr_datereported?: null | string;
  bthr_incidentdescription?: null | string;
  odataIncidentStatus?: null | string;
  odataIncidentType?: null | string;
  odataRecordStatus?: null | string;
  odataStatus?: null | string;
}
