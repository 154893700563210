import { Action } from '@ngrx/store';
import { VerdePositions } from '@verde/shared';
import { StructurePositionExtended } from '../models/structure-position-extended.model';

export enum PositionActionTypes {
  // Get All Position
  POSITION_GET_ALL = '[Position] Get All',
  POSITION_GET_ALL_SUCCESS = '[Position] Get All Success',
  POSITION_GET_ALL_ERROR = '[Position] Get All Error',
  // Position Add
  POSITION_ADD = '[Position] Add',
  POSITION_ADD_SUCCESS = '[Position] Add Success',
  // Position Dispose
  POSITION_DISPOSE = '[Position] Dispose',
  POSITION_DISPOSE_SUCCESS = '[Position] Dispose Success',
  // Position Delete
  POSITION_DELETE = '[Position] Delete',
  POSITION_DELETE_SUCCESS = '[Position] Delete Success',
  // Position Undo
  POSITION_UNDO = '[Position] Undo',
  POSITION_UNDO_SUCCESS = '[Position] Undo Success',
  // Structure Selected
  POSITION_ENTITY_SELECT = '[Position] Entity Select',
  POSITION_ENTITY_DESELECT = '[Position] Entity Deselect',
  // Clear All Position
  POSITION_CLEAR = '[Position] Clear',
}

// Get All Position
export class PositionGetAll implements Action {
  readonly type = PositionActionTypes.POSITION_GET_ALL;
  constructor(public payload: string) {}
}

export class PositionGetAllSuccess implements Action {
  readonly type = PositionActionTypes.POSITION_GET_ALL_SUCCESS;
  constructor(public payload: VerdePositions[]) {}
}

export class PositionGetAllError implements Action {
  readonly type = PositionActionTypes.POSITION_GET_ALL_ERROR;
  constructor(public payload: any) {}
}

// Position Add
export class PositionAdd implements Action {
  readonly type = PositionActionTypes.POSITION_ADD;
  constructor(public payload: StructurePositionExtended[]) {}
}

export class PositionAddSuccess implements Action {
  readonly type = PositionActionTypes.POSITION_ADD_SUCCESS;
  constructor(public payload: StructurePositionExtended[]) {}
}

// Position Dispose
export class PositionDispose implements Action {
  readonly type = PositionActionTypes.POSITION_DISPOSE;
  constructor(public payload: StructurePositionExtended[]) {}
}

export class PositionDisposeSuccess implements Action {
  readonly type = PositionActionTypes.POSITION_DISPOSE_SUCCESS;
  constructor(public payload: StructurePositionExtended[]) {}
}

// Position Delete
export class PositionDelete implements Action {
  readonly type = PositionActionTypes.POSITION_DELETE;
  constructor(public payload: StructurePositionExtended[]) {}
}

export class PositionDeleteSuccess implements Action {
  readonly type = PositionActionTypes.POSITION_DELETE_SUCCESS;
  constructor(public payload: StructurePositionExtended[]) {}
}

// Position Entity Selected
export class PositionEntitySelect implements Action {
  readonly type = PositionActionTypes.POSITION_ENTITY_SELECT;
  constructor(public payload: string) {}
}

export class PositionEntityDeselect implements Action {
  readonly type = PositionActionTypes.POSITION_ENTITY_DESELECT;
  constructor(public payload: any) {}
}

// Position Undo
export class PositionUndo implements Action {
  readonly type = PositionActionTypes.POSITION_UNDO;
  constructor(public payload: any) {}
}

export class PositionUndoSuccess implements Action {
  readonly type = PositionActionTypes.POSITION_UNDO_SUCCESS;
  constructor(public payload: any) {}
}

// Clear All Positions
export class PositionClear implements Action {
  readonly type = PositionActionTypes.POSITION_CLEAR;
  constructor(public payload: any) {}
}

export type PositionActions =
  // Get All Positions
  | PositionGetAll
  | PositionGetAllSuccess
  | PositionGetAllError
  // Position Add
  | PositionAdd
  | PositionAddSuccess
  // Position Dispose
  | PositionDispose
  | PositionDisposeSuccess
  // Position Delete
  | PositionDelete
  | PositionDeleteSuccess
  // Position Entity Select
  | PositionEntitySelect
  | PositionEntityDeselect
  // Position Undo
  | PositionUndo
  | PositionUndoSuccess
  // Clear All Positions
  | PositionClear;
