import { Action } from '@ngrx/store';
import { PerformanceReviewPatchEnhancedReviewDetailCommand } from '@verde/shared';

export enum EnhancedReviewDetailActionTypes {
  // Get ENHANCEDREVIEWDETAIL
  ENHANCEDREVIEWDETAIL_GET_ALL = '[ENHANCEDREVIEWDETAIL] Get All Start',
  ENHANCEDREVIEWDETAIL_GET_ALL_SUCCESS = '[ENHANCEDREVIEWDETAIL] Get All Start Success',
  ENHANCEDREVIEWDETAIL_GET_ALL_ERROR = '[ENHANCEDREVIEWDETAIL] Get All Start Error',

  // UPDATE ENHANCEDREVIEWDETAIL
  ENHANCEDREVIEWDETAIL_UPDATE = '[ENHANCEDREVIEWDETAIL] UPDATE',
  ENHANCEDREVIEWDETAIL_UPDATE_SUCCESS = '[ENHANCEDREVIEWDETAIL] UPDATE Success',
  ENHANCEDREVIEWDETAIL_UPDATE_ERROR = '[ENHANCEDREVIEWDETAIL] UPDATE Error',

  // ENHANCEDREVIEWDETAIL Add
  ENHANCEDREVIEWDETAIL_UPLOAD = '[ENHANCEDREVIEWDETAIL] Upload',
  ENHANCEDREVIEWDETAIL_UPLOAD_SUCCESS = '[ENHANCEDREVIEWDETAIL] Upload Success',
  ENHANCEDREVIEWDETAIL_UPLOAD_ERROR = '[ENHANCEDREVIEWDETAIL] Upload Error',

  // Clear All ENHANCEDREVIEWDETAIL
  ENHANCEDREVIEWDETAIL_ITEM_CLEAR = '[ENHANCEDREVIEWDETAIL] Clear',
}

// Get EnhancedReviewDetail
//export class EnhancedReviewDetailGet implements Action {
//  readonly type = EnhancedReviewDetailActionTypes.ENHANCEDREVIEWDETAIL_GET_ALL;
//  constructor(public body: TemplateGetEnhancedReviewDetailQuery) {}
//}

//export class EnhancedReviewDetailGetSuccess implements Action {
//  readonly type = EnhancedReviewDetailActionTypes.ENHANCEDREVIEWDETAIL_GET_ALL_SUCCESS;
//  constructor(public payload: any[]) {}
//}

//export class EnhancedReviewDetailGetError implements Action {
//  readonly type = EnhancedReviewDetailActionTypes.ENHANCEDREVIEWDETAIL_GET_ALL_ERROR;
//  constructor(public payload: any) {}
//}

// UPDATE ENHANCEDREVIEWDETAIL
export class EnhancedReviewDetailUpdate implements Action {
  readonly type = EnhancedReviewDetailActionTypes.ENHANCEDREVIEWDETAIL_UPDATE;
  constructor(public payload: PerformanceReviewPatchEnhancedReviewDetailCommand) {}
}

export class EnhancedReviewDetailUpdateSuccess implements Action {
  readonly type = EnhancedReviewDetailActionTypes.ENHANCEDREVIEWDETAIL_UPDATE_SUCCESS;
  constructor(public payload: any) {}
}

export class EnhancedReviewDetailUpdateError implements Action {
  readonly type = EnhancedReviewDetailActionTypes.ENHANCEDREVIEWDETAIL_UPDATE_ERROR;
  constructor(public payload: any) {}
}

// UPLOAD MEASURE
//export class EnhancedReviewDetailUpload implements Action {
//  readonly type = EnhancedReviewDetailActionTypes.ENHANCEDREVIEWDETAIL_UPLOAD;
//  constructor(public payload: TemplateUploadEnhancedReviewDetailCommand) { }
//}

//export class EnhancedReviewDetailUploadSuccess implements Action {
//  readonly type = EnhancedReviewDetailActionTypes.ENHANCEDREVIEWDETAIL_UPLOAD_SUCCESS;
//  constructor(public payload: any) { }
//}

//export class EnhancedReviewDetailUploadError implements Action {
//  readonly type = EnhancedReviewDetailActionTypes.ENHANCEDREVIEWDETAIL_UPLOAD_ERROR;
//  constructor(public payload: any) { }
//}

// Clear All EnhancedReviewDetail
export class EnhancedReviewDetailClear implements Action {
  readonly type = EnhancedReviewDetailActionTypes.ENHANCEDREVIEWDETAIL_ITEM_CLEAR;
  constructor(public payload: any) {}
}

export type EnhancedReviewDetailActions =
  // Get EnhancedReviewDetail
  //| EnhancedReviewDetailGet
  //| EnhancedReviewDetailGetSuccess
  //| EnhancedReviewDetailGetError
  // Set All Structure Change Requests
  | EnhancedReviewDetailUpdate
  | EnhancedReviewDetailUpdateSuccess
  | EnhancedReviewDetailUpdateError
  // Structure Change Request Upload
  //| EnhancedReviewDetailUpload
  //| EnhancedReviewDetailUploadSuccess
  //| EnhancedReviewDetailUploadError
  // Clear All EnhancedReviewDetail
  | EnhancedReviewDetailClear;
