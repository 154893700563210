/* tslint:disable */
/* eslint-disable */
export interface DvDisciplinaries {
  bthr_dateofmisconduct?: null | string;
  bthr_dateofwarning?: null | string;
  bthr_detailsofcharges?: null | string;
  bthr_interpreter?: null | string;
  bthr_warningenddate?: null | string;
  odataDisciplinaryType?: null | string;
  odataStatus?: null | string;
}
