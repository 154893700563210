import { CommonModule, TitleCasePipe } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MatMenuModule } from '@angular/material/menu';

// Spinner
import { NgxSpinnerModule } from 'ngx-spinner';

// Material
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';

// Kendo
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { EditorModule } from '@progress/kendo-angular-editor';
import { FilterModule } from '@progress/kendo-angular-filter';
import { GaugesModule } from '@progress/kendo-angular-gauges';
import { ExcelModule, GridModule, PDFModule } from '@progress/kendo-angular-grid';
import { IconsModule } from '@progress/kendo-angular-icons';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { DrawerModule, ExpansionPanelModule, LayoutModule, PanelBarModule, SplitterModule, TabStripModule } from '@progress/kendo-angular-layout';
import { ListBoxModule } from '@progress/kendo-angular-listbox';
import { MenusModule } from '@progress/kendo-angular-menu';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { PivotGridModule } from '@progress/kendo-angular-pivotgrid';
import { ScrollViewModule } from '@progress/kendo-angular-scrollview';
import { ToolBarModule } from '@progress/kendo-angular-toolbar';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { TreeListModule } from '@progress/kendo-angular-treelist';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { NavigationModule } from '@progress/kendo-angular-navigation';

// Shared Components
import { DotsMenuButtonComponent } from './components/dots-menu-button/dots-menu-button.component';
import { IdValidationComponent } from './components/id-validation/id-validation.component';
import { ImageUploadComponent } from './components/image-upload/image-upload.component';
import { ImageUploadDirective } from './components/image-upload/image-upload.directive';
import { MainHeaderComponent } from './components/main-header/main-header.component';
import { PageContainerComponent } from './components/page-container/page-container.component';
import { SuccessionsTabComponent } from './components/successions-tab/successions-tab.component';
import { UserCardComponent } from './components/user-card/user-card.component';
import { StructureActivityComponent } from './components/verde-approval-panel/components/structure-activity/structure-activity.component';
import { SuccessorDetailsComponent } from './components/verde-approval-panel/components/successor-details/successor-details.component';
import { UserAccessGridComponent } from './components/verde-approval-panel/components/user-access-grid/user-access-grid.component';
import { VerdeApprovalPanelComponent } from './components/verde-approval-panel/verde-approval-panel.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { ADummySidepanelComponent } from './components/verde-approval-panel/components/a-dummy-sidepanel/a-dummy-sidepanel.component';
import { BulkUploadAdminComponent } from './components/verde-approval-panel/components/bulk-upload-admin/bulk-upload-admin.component';
import { BulkUploadComponent } from './components/verde-approval-panel/components/bulk-upload/bulk-upload.component';
import { DynamicFormComponent } from './components/verde-approval-panel/components/dynamic-form/dynamic-form.component';
import { FleetEntityTransferComponent } from './components/verde-approval-panel/components/fleet-entity-transfer/fleet-entity-transfer.component';
import { FleetOdoUpdateComponent } from './components/verde-approval-panel/components/fleet-odo-update/fleet-odo-update.component';
import { FleetRequestItemComponent } from './components/verde-approval-panel/components/fleet-request-item/fleet-request-item.component';
import { FleetReturnItemComponent } from './components/verde-approval-panel/components/fleet-return-item/fleet-return-item.component';
import { OpportunitiesComponent } from './components/verde-approval-panel/components/opportunities/opportunities.component';
import { PayrollUploadComponent } from './components/verde-approval-panel/components/payroll-upload/payroll-upload.component';
import { EnhancedRatingOptionComponent } from './components/verde-approval-panel/components/perfromance-review/enhanced-review/enhanced-rating-option/enhanced-rating-option.component';
import { EnhancedReviewComponent } from './components/verde-approval-panel/components/perfromance-review/enhanced-review/enhanced-review.component';
import { RatingOptionComponent } from './components/verde-approval-panel/components/perfromance-review/review/rating-option/rating-option.component';
import { ResponseOptionComponent } from './components/verde-approval-panel/components/perfromance-review/review/response-option/response-option.component';
import { ReviewComponent } from './components/verde-approval-panel/components/perfromance-review/review/review.component';
import { QualificationsComponent } from './components/verde-approval-panel/components/qualifications/qualifications.component';
import { PositionChangeRequestComponent } from './components/verde-approval-panel/components/structure/position-change-request/position-change-request.component';
import { PositionListComponent } from './components/verde-approval-panel/components/structure/position-list/position-list.component';
import { StructureChangeRequestComponent } from './components/verde-approval-panel/components/structure/structure-change-request/structure-change-request.component';
import { StructureDisposeRequestComponent } from './components/verde-approval-panel/components/structure/structure-dipose-request/structure-dispose-request.component';
import { VerdeDynamicFormComponentSidePanel } from './components/verde-approval-panel/components/verde-dynamic-form-side-panel/verde-dynamic-form-side-panel.component';
import { ViewTaskComponent } from './components/verde-approval-panel/components/view-task/view-task.component';
import { BenchmarkAddComponent } from './components/verde-approval-panel/components/benchmark/benchmark-add/benchmark-add.component';
import { BenchmarkEditComponent } from './components/verde-approval-panel/components/benchmark/benchmark-edit/benchmark-edit.component';
import { BenchmarkViewComponent } from './components/verde-approval-panel/components/benchmark/benchmark-view/benchmark-view.component';
import { EmployeeExitEditComponent } from './components/verde-approval-panel/components/employee-exit-edit/employee-exit-edit.component';
import { EmployeeExitComponent } from './components/verde-approval-panel/components/employee-exit/employee-exit.component';
import { EmployeeFundsComponent } from './components/verde-approval-panel/components/employee-funds/employee-funds.component';
import { EmployeeOnboardingAdditionalComponent } from './components/verde-approval-panel/components/employee-onboarding-additional/employee-onboarding-additional.component';
import { EmployeeOnboardingEditComponent } from './components/verde-approval-panel/components/employee-onboarding-edit/employee-onboarding-edit.component';
import { OnboardingNationalityEditComponent } from './components/verde-approval-panel/components/employee-onboarding-edit/onboarding-nationality-edit/onboarding-nationality-edit.component';
import { OnboardingRequiredInfoEditComponent } from './components/verde-approval-panel/components/employee-onboarding-edit/onboarding-required-info-edit/onboarding-required-info-edit.component';
import { OnboardingStructureEditComponent } from './components/verde-approval-panel/components/employee-onboarding-edit/onboarding-structure-edit/onboarding-structure-edit.component';
import { OnboardingTaxInfoComponentEdit } from './components/verde-approval-panel/components/employee-onboarding-edit/onboarding-tax-info-edit/onboarding-tax-info-edit.component';
import { OnboardingpassportInfoEditComponent } from './components/verde-approval-panel/components/employee-onboarding-edit/passport-info-edit/onboarding-passport-info-edit.component';
import { EmployeeOnboardingComponent } from './components/verde-approval-panel/components/employee-onboarding/employee-onboarding.component';
import { OnboardingNationalityComponent } from './components/verde-approval-panel/components/employee-onboarding/onboarding-nationality/onboarding-nationality.component';
import { OnboardingRequiredInfoComponent } from './components/verde-approval-panel/components/employee-onboarding/onboarding-required-info/onboarding-required-info.component';
import { OnboardingStructureComponent } from './components/verde-approval-panel/components/employee-onboarding/onboarding-structure/onboarding-structure.component';
import { OnboardingTaxInfoComponent } from './components/verde-approval-panel/components/employee-onboarding/onboarding-tax-info/onboarding-tax-info.component';
import { OnboardingpassportInfoComponent } from './components/verde-approval-panel/components/employee-onboarding/passport-info/onboarding-passport-info.component';
import { EmployeeResignationEditComponent } from './components/verde-approval-panel/components/employee-terminations/employee-resignation-edit/employee-resignation-edit.component';
import { EmployeeResignationViewComponent } from './components/verde-approval-panel/components/employee-terminations/employee-resignation-view/employee-resignation-view.component';
import { EmployeeResignationComponent } from './components/verde-approval-panel/components/employee-terminations/employee-resignation/employee-resignation.component';
import { PersonalDetailsComponent } from './components/verde-approval-panel/components/personal-details/personal-details.component';
import { GroupAddComponent } from './components/verde-approval-panel/components/template-group/group-add/group-add.component';
import { GroupEditComponent } from './components/verde-approval-panel/components/template-group/group-edit/group-edit.component';
import { GroupViewComponent } from './components/verde-approval-panel/components/template-group/group-view/group-view.component';
import { TemplateMeasureAddComponent } from './components/verde-approval-panel/components/template-measure-add/template-measure-add.component';
import { TemplateMeasureEditComponent } from './components/verde-approval-panel/components/template-measure-edit/template-measure-edit.component';
import { TemplateMeasureViewComponent } from './components/verde-approval-panel/components/template-measure-view/template-measure-view.component';
import { SubgroupAddComponent } from './components/verde-approval-panel/components/template-subgroup/subgroup-add/subgroup-add.component';
import { SubgroupEditComponent } from './components/verde-approval-panel/components/template-subgroup/subgroup-edit/subgroup-edit.component';
import { SubgroupViewComponent } from './components/verde-approval-panel/components/template-subgroup/subgroup-view/subgroup-view.component';
import { TemplateAddComponent } from './components/verde-approval-panel/components/template/template-add/template-add.component';
import { TemplateEditComponent } from './components/verde-approval-panel/components/template/template-edit/template-edit.component';
import { TemplateViewComponent } from './components/verde-approval-panel/components/template/template-view/template-view.component';
import { ProvidentFundComponent } from './services/manual-documents/components/provident-fund/provident-fund.component';
import { VerdeDynamicFormComponent } from './components/verde-dynamic-forms/dynamic-form/verde-dynamic-form.component';
import { VerdeDynamicFrontPageComponent } from './components/verde-dynamic-forms/dynamic-form-frontPage/verde-dynamic-frontPage.component';

// Shared Modals
import { FleetApprovalModalComponent } from './modals/fleet/fleet-approval-modal/fleet-approval-modal.component';
import { FleetAssignModalComponent } from './modals/fleet/fleet-assign-modal/fleet-assign-modal.component';
import { FleetClassificationModalComponent } from './modals/fleet/fleet-classification-modal/fleet-classification-modal.component';
import { FleetConfirmationModalComponent } from './modals/fleet/fleet-confirmation-modal/fleet-confirmation-modal.component';
import { FleetLicenseModalComponent } from './modals/fleet/fleet-license-modal/fleet-license-modal.component';
import { FleetModelsModalComponent } from './modals/fleet/fleet-models-modal/fleet-models-modal.component';
import { IssueModalComponent } from './modals/issue-modal/issue-modal.component';
import { ModalComponent } from './modals/modal/modal.component';
import { NaMobileModalComponent } from './modals/na-mobile-modal/na-mobile-modal.component';
import { OnlineTrainingModalComponent } from './modals/online-training-modal/online-training-modal.component';
import { PayslipDialogComponent } from './modals/payslip-dialog/payslip-dialog.component';
import { NewRequestModalComponent } from './modals/requests/new-request-modal/new-request-modal.component';
import { TimeoffApprovalModalComponent } from './modals/timeoff-approval-modal/timeoff-approval-modal.component';
import { VerdeApprovalModalComponent } from './modals/verde-approval-modal/verde-approval-modal.component';

// Pipes
import { DatePipe } from '@angular/common';
import { SafePipe } from './pipes/safe.pipe';
import { YesNoPipe } from './pipes/yesno.pipe';

// QR Codes
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { QRCodeModule } from 'angularx-qrcode';

// Web-Cam
import { WebcamModule } from 'ngx-webcam';

// Permissions
import { NgxPermissionsModule } from 'ngx-permissions';

// Lottie
import { LottieModule } from 'ngx-lottie';

export function playerFactory(): any {
  return import('lottie-web');
}

//Formly
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormlyKendoModule } from '@ngx-formly/kendo';
import { FormlyDateInputComponentKendo } from './formly/datepicker-type';
import { DividerTypeComponentKendo } from './formly/divider.type';
import { KendoDropdownTypeComponentKendo } from './formly/dropdown-type';
import { FormlyFieldFileComponentKendo } from './formly/file-type';
import { FileValueAccessorComponentKendo } from './formly/file-value-accessor';
import { FormlyInputComponentKendo } from './formly/input-type';
import { KendoGridFormlyComponentKendo } from './formly/kendogrid-type';
import { FormlyNumberInputComponentKendo } from './formly/number-type';
import { ObjectTypeComponentKendo } from './formly/object.type';
import { TabTypeComponentKendo } from './formly/tab-strip.type';
import { FormlyTextAreaComponentKendo } from './formly/textarea-type';
import { ArrayTypeComponentKendo } from './formly/array.type';
import { MultiSchemaTypeComponentKendo } from './formly/multischema.type';
import { NullTypeComponentKendo } from './formly/null.type';

export function minItemsValidationMessage(error: any, field: FormlyFieldConfig) {
  return `should NOT have fewer than ${field.props.minItems} items`;
}

export function maxItemsValidationMessage(error: any, field: FormlyFieldConfig) {
  return `should NOT have more than ${field.props.maxItems} items`;
}

export function minLengthValidationMessage(error: any, field: FormlyFieldConfig) {
  return `should NOT be shorter than ${field.props.minLength} characters`;
}

export function maxLengthValidationMessage(error: any, field: FormlyFieldConfig) {
  return `should NOT be longer than ${field.props.maxLength} characters`;
}

export function minValidationMessage(error: any, field: FormlyFieldConfig) {
  return `should be >= ${field.props.min}`;
}

export function maxValidationMessage(error: any, field: FormlyFieldConfig) {
  return `should be <= ${field.props.max}`;
}

export function multipleOfValidationMessage(error: any, field: FormlyFieldConfig) {
  return `should be multiple of ${field.props.step}`;
}

export function exclusiveMinimumValidationMessage(error: any, field: FormlyFieldConfig) {
  return `should be > ${field.props.step}`;
}

export function exclusiveMaximumValidationMessage(error: any, field: FormlyFieldConfig) {
  return `should be < ${field.props.step}`;
}

export function constValidationMessage(error: any, field: FormlyFieldConfig) {
  return `should be equal to constant "${field.props.const}"`;
}

export function typeValidationMessage({ schemaType }: any) {
  return `should be "${schemaType[0]}".`;
}

export const SHARED_COMPONENT_EXPORT = [
  ImageUploadComponent,
  ImageUploadDirective,
  MainHeaderComponent,
  PageContainerComponent,
  UserCardComponent,
  DotsMenuButtonComponent,
  IdValidationComponent,
  VerdeApprovalPanelComponent,
  UserAccessGridComponent,
  StructureActivityComponent,
  SuccessionsTabComponent,
  SuccessorDetailsComponent,
  BreadcrumbComponent,
  PayslipDialogComponent,
  IssueModalComponent,
  ModalComponent,
  TimeoffApprovalModalComponent,
  FleetAssignModalComponent,
  FleetConfirmationModalComponent,
  FleetApprovalModalComponent,
  FleetLicenseModalComponent,
  FleetModelsModalComponent,
  FleetClassificationModalComponent,
  NewRequestModalComponent,
  NaMobileModalComponent,
  VerdeApprovalModalComponent,
  OnlineTrainingModalComponent,
  FileValueAccessorComponentKendo,
  EmployeeFundsComponent,
  ProvidentFundComponent,
  TemplateMeasureViewComponent,
  TemplateMeasureEditComponent,
  TemplateMeasureAddComponent,
  GroupViewComponent,
  GroupEditComponent,
  GroupAddComponent,
  SubgroupAddComponent,
  SubgroupEditComponent,
  SubgroupViewComponent,
  TemplateAddComponent,
  TemplateViewComponent,
  TemplateEditComponent,
  BenchmarkViewComponent,
  BenchmarkEditComponent,
  BenchmarkAddComponent,
  PersonalDetailsComponent,
  PayrollUploadComponent,
  BulkUploadComponent,
  BulkUploadAdminComponent,
  ViewTaskComponent,
  EnhancedReviewComponent,
  EnhancedRatingOptionComponent,
  FleetRequestItemComponent,
  FleetEntityTransferComponent,
  FleetOdoUpdateComponent,
  ADummySidepanelComponent,
  FleetReturnItemComponent,
  QualificationsComponent,
  VerdeDynamicFormComponent,
  VerdeDynamicFormComponentSidePanel,
  DynamicFormComponent,
  OpportunitiesComponent,
  EnhancedRatingOptionComponent,
  ReviewComponent,
  RatingOptionComponent,
  ResponseOptionComponent,
  EmployeeExitComponent,
  EmployeeExitEditComponent,
  StructureChangeRequestComponent,
  StructureDisposeRequestComponent,
  PositionListComponent,
  PositionChangeRequestComponent,
  EmployeeResignationComponent,
  EmployeeExitEditComponent,
  EmployeeOnboardingComponent,
  EmployeeOnboardingAdditionalComponent,
  OnboardingRequiredInfoComponent,
  OnboardingNationalityComponent,
  OnboardingTaxInfoComponent,
  OnboardingStructureComponent,
  EmployeeResignationEditComponent,
  EmployeeResignationViewComponent,
  EmployeeOnboardingEditComponent,
  OnboardingNationalityEditComponent,
  OnboardingRequiredInfoEditComponent,
  OnboardingStructureEditComponent,
  OnboardingpassportInfoComponent,
  OnboardingTaxInfoComponentEdit,
  OnboardingpassportInfoEditComponent,
  VerdeDynamicFrontPageComponent,
];

export const FORMLY_COMPONENT_EXPORT = [
  FormlyDateInputComponentKendo,
  DividerTypeComponentKendo,
  KendoDropdownTypeComponentKendo,
  FormlyFieldFileComponentKendo,
  FileValueAccessorComponentKendo,
  FormlyInputComponentKendo,
  KendoGridFormlyComponentKendo,
  FormlyNumberInputComponentKendo,
  ObjectTypeComponentKendo,
  TabTypeComponentKendo,
  FormlyTextAreaComponentKendo,
  ArrayTypeComponentKendo,
  MultiSchemaTypeComponentKendo,
  NullTypeComponentKendo,
];

export const SHARED_PIPES = [SafePipe, YesNoPipe];

export const SHARED_MODULE_EXPORT = [
  // General
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  // Spinner
  NgxSpinnerModule,
  // Material
  MatIconModule,
  MatDividerModule,
  // Kendo
  ListBoxModule,
  ButtonsModule,
  ChartsModule,
  DateInputsModule,
  DialogsModule,
  DrawerModule,
  DropDownsModule,
  EditorModule,
  ExcelModule,
  ExpansionPanelModule,
  FilterModule,
  GridModule,
  IndicatorsModule,
  InputsModule,
  LabelModule,
  LayoutModule,
  MenusModule,
  PDFExportModule,
  PDFModule,
  PanelBarModule,
  SplitterModule,
  TabStripModule,
  ToolBarModule,
  TooltipModule,
  TreeListModule,
  UploadsModule,
  IconsModule,
  GaugesModule,
  ScrollViewModule,
  PivotGridModule,
  NotificationModule,
  NavigationModule,
  // QR Codes
  QRCodeModule,
  ZXingScannerModule,
  // Web-Cam
  WebcamModule,
  // Permissions
  NgxPermissionsModule,
  HttpClientModule,
  MatMenuModule,
  //Formly
  FormlyKendoModule,
  FormlyModule.forRoot({
    validationMessages: [
      { name: 'required', message: 'This field is required' },
      { name: 'type', message: typeValidationMessage },
      { name: 'minLength', message: minLengthValidationMessage },
      { name: 'maxLength', message: maxLengthValidationMessage },
      { name: 'min', message: minValidationMessage },
      { name: 'max', message: maxValidationMessage },
      { name: 'multipleOf', message: multipleOfValidationMessage },
      { name: 'exclusiveMinimum', message: exclusiveMinimumValidationMessage },
      { name: 'exclusiveMaximum', message: exclusiveMaximumValidationMessage },
      { name: 'minItems', message: minItemsValidationMessage },
      { name: 'maxItems', message: maxItemsValidationMessage },
      { name: 'uniqueItems', message: 'should NOT have duplicate items' },
      { name: 'const', message: constValidationMessage },
      { name: 'enum', message: `must be equal to one of the allowed values` },
    ],
    types: [
      { name: 'null', component: NullTypeComponentKendo, wrappers: ['form-field'] },
      { name: 'array', component: ArrayTypeComponentKendo },
      { name: 'object', component: ObjectTypeComponentKendo },
      { name: 'multischema', component: MultiSchemaTypeComponentKendo },
      { name: 'file', component: FormlyFieldFileComponentKendo, wrappers: ['form-field'] },
      { name: 'date', component: FormlyDateInputComponentKendo },
      { name: 'input', component: FormlyInputComponentKendo },
      { name: 'textarea', component: FormlyTextAreaComponentKendo },
      { name: 'select', component: KendoDropdownTypeComponentKendo },
      { name: 'picklist', component: KendoDropdownTypeComponentKendo },
      //{ name: 'enum', component: KendoDropdownTypeComponentKendo },
      { name: 'numberInput', component: FormlyNumberInputComponentKendo },
      { name: 'grid', component: KendoGridFormlyComponentKendo },
      { name: 'divider', component: DividerTypeComponentKendo },
      { name: 'tab', component: TabTypeComponentKendo },
    ],
  }) as any,
  // Lottie
  LottieModule.forRoot({ player: playerFactory }) as any,
];

@NgModule({
  declarations: [...SHARED_COMPONENT_EXPORT, ...FORMLY_COMPONENT_EXPORT, ...SHARED_PIPES],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [...SHARED_MODULE_EXPORT],
  exports: [...SHARED_MODULE_EXPORT, ...SHARED_COMPONENT_EXPORT, ...FORMLY_COMPONENT_EXPORT, ...SHARED_PIPES],
  providers: [DatePipe, TitleCasePipe],
})
export class SharedModule {}
