/* tslint:disable */
/* eslint-disable */
import { ProcessesStringObjectKeyValuePair } from './processes-string-object-key-value-pair';
export interface ProcessesEntityReference {
  id?: string;
  keyAttributes?: null | Array<ProcessesStringObjectKeyValuePair>;
  logicalName?: null | string;
  name?: null | string;
  rowVersion?: null | string;
}
