/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { TimeOffBaseService } from '../time-off-base-service';
import { TimeOffApiConfiguration } from '../time-off-api-configuration';
import { TimeOffStrictHttpResponse } from '../time-off-strict-http-response';
import { TimeOffRequestBuilder } from '../time-off-request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { TimeOffBooleanStringValueTuple } from '../models/time-off-boolean-string-value-tuple';
import { TimeOffGetTimeOffQuery } from '../models/time-off-get-time-off-query';
import { TimeOffPostTimeOffCommand } from '../models/time-off-post-time-off-command';
import { TimeOffTimeOffExample } from '../models/time-off-time-off-example';
import { TimeOffTimeOffRequestDateCheckerCommand } from '../models/time-off-time-off-request-date-checker-command';

@Injectable({
  providedIn: 'root',
})
export class TimeOffApiTimeOffService extends TimeOffBaseService {
  constructor(config: TimeOffApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getTimeOff
   */
  static readonly GetTimeOffPath = '/api/TimeOff/get/timeoff';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTimeOff()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getTimeOff$Response(params?: { body?: TimeOffGetTimeOffQuery }): Observable<TimeOffStrictHttpResponse<Array<TimeOffTimeOffExample>>> {
    const rb = new TimeOffRequestBuilder(this.rootUrl, TimeOffApiTimeOffService.GetTimeOffPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as TimeOffStrictHttpResponse<Array<TimeOffTimeOffExample>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTimeOff$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getTimeOff(params?: { body?: TimeOffGetTimeOffQuery }): Observable<Array<TimeOffTimeOffExample>> {
    return this.getTimeOff$Response(params).pipe(map((r: TimeOffStrictHttpResponse<Array<TimeOffTimeOffExample>>) => r.body as Array<TimeOffTimeOffExample>));
  }

  /**
   * Path part for operation postTimeOff
   */
  static readonly PostTimeOffPath = '/api/TimeOff/post/timeoff';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postTimeOff()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postTimeOff$Response(params?: { body?: TimeOffPostTimeOffCommand }): Observable<TimeOffStrictHttpResponse<boolean>> {
    const rb = new TimeOffRequestBuilder(this.rootUrl, TimeOffApiTimeOffService.PostTimeOffPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as TimeOffStrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postTimeOff$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postTimeOff(params?: { body?: TimeOffPostTimeOffCommand }): Observable<boolean> {
    return this.postTimeOff$Response(params).pipe(map((r: TimeOffStrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation timeOffRequestBalances
   */
  static readonly TimeOffRequestBalancesPath = '/api/TimeOff/get/timeoffrequestbalances';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `timeOffRequestBalances()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  timeOffRequestBalances$Response(params?: {
    body?: TimeOffTimeOffRequestDateCheckerCommand;
  }): Observable<TimeOffStrictHttpResponse<TimeOffBooleanStringValueTuple>> {
    const rb = new TimeOffRequestBuilder(this.rootUrl, TimeOffApiTimeOffService.TimeOffRequestBalancesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as TimeOffStrictHttpResponse<TimeOffBooleanStringValueTuple>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `timeOffRequestBalances$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  timeOffRequestBalances(params?: { body?: TimeOffTimeOffRequestDateCheckerCommand }): Observable<TimeOffBooleanStringValueTuple> {
    return this.timeOffRequestBalances$Response(params).pipe(
      map((r: TimeOffStrictHttpResponse<TimeOffBooleanStringValueTuple>) => r.body as TimeOffBooleanStringValueTuple),
    );
  }
}
