/* tslint:disable */
/* eslint-disable */
import { User } from './user';
export interface EquipmentRequestHeader {
  '_bt_legalentity_value'?: null | string;
  '_bt_location_value'?: null | string;
  '_bt_verderequest_value'?: null | string;
  bt_Requestby?: User;
  bt_alloverridesprocessed?: null | boolean;
  bt_approvalrequired?: null | boolean;
  bt_approvalstatus?: null | number;
  bt_budgettotal?: null | number;
  bt_budgettotal_date?: null | string;
  bt_budgettotal_state?: null | number;
  bt_definitiontechnicalname?: null | string;
  bt_equipmentrequestheaderid?: null | string;
  bt_equipmentrequestno?: null | string;
  bt_lineoverride?: null | boolean;
  bt_requestitem?: null | string;
  bt_summarizebyequipmenttype?: null | boolean;
  bt_uniquename?: null | string;
  odataAllOverridesProcessed?: null | string;
  odataApprovalStatus?: null | string;
  odataLegalEntity?: null | string;
  odataLineOverride?: null | string;
  odataLocation?: null | string;
  odataPostLegalEntity?: null | string;
  odataPostLocation?: null | string;
  odataPostRequest?: null | string;
  odataPostRequestedBy?: null | string;
  odataPostRequestedByLegalEntity?: null | string;
  odataRequest?: null | string;
}
