<kendo-grid #GroupMyGrid
            style="max-height: 540px;"
            [rowClass]="groupRowCallback"
            [kendoGridBinding]="GroupGridData"
            [resizable]="true"
            [scrollable]="true"
            [groupable]="true"
            [group]="GroupGroups"
            (groupChange)="GroupGroupChange($event)"
            (dataStateChange)="GroupDataStateChange($event)"
            [selectable]="selectableSettings"
            [kendoGridSelectBy]="GroupSelectedCallback"
            [selectedKeys]="GroupSelection"
            [sortable]="true"
            [sort]="GroupSort"
            (sortChange)="GroupSortChange($event)"
            [filterable]="GroupFilterMode"
            [loading]="groupDocsLoading">

  <ng-template kendoGridToolbarTemplate
    style="height: 57px">
    <kendo-grid-spacer></kendo-grid-spacer>
    <img title="Refresh"
          class="refresh_icon"
          src="https://engageimages.z1.web.core.windows.net/Images/Verde/Other/reload.svg"
          alt=""
          (click)="refresh()" />
  </ng-template>
  <kendo-grid-column field="bt_VerdeManualDocumentsControl.btGroupValueODataCommunityDisplayV1FormattedValue"
                     title="Group"
                     [filterable]="false"
                     [width]="150"></kendo-grid-column>
  <kendo-grid-column field="bt_VerdeManualDocumentsControl.bt_description"
                     title="Description"
                     [filterable]="false"
                     [width]="300"></kendo-grid-column>
  <kendo-grid-column field="bt_referenceno"
                     title="Ref No"
                     [width]="150"></kendo-grid-column>
  <kendo-grid-column field="bt_attached"
                     title="Completed"
                     filter="boolean"
                     [width]="110">
    <ng-template kendoGridCellTemplate
                 let-dataItem>
      <span *ngIf="dataItem.bt_attached === true; else displayNo">Yes</span>
      <ng-template #displayNo>
        No
      </ng-template>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="bt_duedate"
                     title="Due Date"
                     width="150"
                     [filterable]="false">
    <ng-template kendoGridCellTemplate
                 let-ListTable>
      {{ListTable.bt_duedate | date: 'yyyy/MM/dd HH:mm a':'UTC'}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-command-column title=""
                             [width]="40"
                             [sticky]="true">
    <ng-template kendoGridCellTemplate
                 let-dataItem>
      <verde-dots-menu-button [items]="groupDotMenuItems"
                              (menuClicked)="groupMenuClicked(dataItem)"
                              (menuItemClicked)="GroupMenuItemClicked($event)"></verde-dots-menu-button>
    </ng-template>
  </kendo-grid-command-column>
  <ng-template kendoGridNoRecordsTemplate>
    There are no documents available at this time.
  </ng-template>
</kendo-grid>

<!-- MOdals -->
<verde-modal id="manualDocumentsModalGroup"
             modalTitle="{{manualDocument?.bt_VerdeManualDocumentsControl?.bt_description}}"
             submitButton="{{manualDocumentsSubmit}}"
             (actionButton)="patchManualDocument()"
             [submitActive]="manualDocumentForm.valid"
             [hasCancelButton]="false"
             modalMinWidth="600"
             modalMaxWidth="600"
             [showHeader]="true"
             [showFooter]="true"
             [modalHasPadding]="true"
             [zIndex]="10">
  <form #manualDocumentForm="ngForm"
        novalidate
        autocomplete="off">
    <div class="manual_docs_modal_grid">
      <div class="form-group">
        <label for="documentDueDate">Due Date:</label>
        <input readonly
               type="text"
               class="form-control"
               id="documentDueDate"
               name="documentDueDate"
               [ngModel]="manualDocument?.odataDueDate">
      </div>

      <br />

      <div class="form-group">
        <label for="documentEmpName">Name:</label>
        <input readonly
               type="text"
               class="form-control"
               id="documentEmpName"
               name="documentEmpName"
               [ngModel]="manualDocument?.bt_Employee?.bt_fullname">
      </div>

      <div class="form-group">
        <label for="documentRefNo">Reference No:</label>
        <input readonly
               type="text"
               class="form-control"
               id="documentRefNo"
               name="documentRefNo"
               [ngModel]="manualDocument?.bt_referenceno">
      </div>

    </div>

    <div class="form-group">
      <label for="documentDownload">Download Orginal Form:</label>
      <p class="link_class"
         title="Click to download form"
         (click)="downloadFile(manualDocumentFile, manualDocument?.bt_VerdeManualDocumentsControl?.bt_attachment_name)">
        {{ manualDocument?.bt_VerdeManualDocumentsControl?.bt_attachment_name}}
      </p>
    </div>

    <div class="form-group"
         *ngIf="manualDocument?.bt_attached == true && manualDocument?.bt_documentname != null">
      <label for="documentDownload">Download Uploaded Form:</label>
      <p class="link_class"
         title="Click to download form"
         (click)="downloadFile(manualDocumentFileUserUpload, manualDocument?.bt_documentname)">
        {{ manualDocument?.bt_documentname}}
      </p>
    </div>

    <div *ngIf="(manualDocument?.bt_attached == true && manualDocument?.bt_VerdeManualDocumentsControl?.bt_allowedit == true) ||
                (manualDocument?.bt_attached == false) ||
                (manualDocument?.bt_VerdeManualDocumentsControl?.bt_acknowledge)">
      <!--OG-->
      <div *ngIf="!manualDocument?.bt_VerdeManualDocumentsControl?.bt_acknowledge"
           class="form-group">
        <label for="documentUpload">Upload Form:</label>
        <br />
        <input type="file"
               title="Upload form"
               id="documentUpload"
               name="documentUpload"
               #varDocumentUpload="ngModel"
               ngModel
               (change)="manualDocumentSelected($event, varDocumentUpload)" />
      </div>

      <!--New-->
      <div *ngIf="manualDocument?.bt_VerdeManualDocumentsControl?.bt_acknowledge">
        <div class="manual_docs_modal_grid">
          <div class="form-group"
               style="grid-column: 1/3">
            <div style="display: inline-flex; margin-bottom: unset">
              <label
                     style="margin-bottom: unset">{{manualDocument?.bt_VerdeManualDocumentsControl?.bt_message}}:</label>

              <div style="display: inline-flex; margin-bottom: unset; margin-left: 5px; float: right">
                <label style="margin-bottom: unset"
                       for="acknowledgeYes">Yes:</label>
                <input style="width: 25px; height: 20px; margin: 3px 0 0 5px; vertical-align: middle"
                       (change)="acknowledgeYesChange($event.target.checked, varAcknowledgeNo)"
                       type="checkbox"
                       kendoCheckBox
                       class="form-control"
                       id="acknowledgeYes"
                       name="acknowledgeYes"
                       #varAcknowledgeYes="ngModel"
                       [ngModel]="acknowledgeYes"
                       [disabled]="(!manualDocument?.bt_VerdeManualDocumentsControl?.bt_allowedit && manualDocument?.bt_attached)" />
                <label for="acknowledgeNo"
                       style="margin-left: 5px; margin-bottom: unset">No:</label>
                <input style="width: 25px; height: 20px; margin: 3px 0 0 5px; vertical-align: middle"
                       (change)="acknowledgeNoChange($event.target.checked, varAcknowledgeYes)"
                       type="checkbox"
                       kendoCheckBox
                       class="form-control"
                       id="acknowledgeNo"
                       name="acknowledgeNo"
                       #varAcknowledgeNo="ngModel"
                       [ngModel]="acknowledgeNo"
                       [disabled]="(!manualDocument?.bt_VerdeManualDocumentsControl?.bt_allowedit && manualDocument?.bt_attached)" />
              </div>
            </div>
          </div>

          <div class="form-group">
            <div style="display: inline-flex; margin-bottom: unset">
              <label style="margin-bottom: unset">Object:</label>

              <div style="display: inline-flex; margin-bottom: unset; margin-left: 5px; float: right">
                <label style="margin-bottom: unset"
                       for="objectYes">Yes:</label>
                <input style="width: 25px; height: 20px; margin: 3px 0 0 5px; vertical-align: middle"
                       (change)="objectYesChange($event.target.checked, varObjectNo)"
                       type="checkbox"
                       kendoCheckBox
                       class="form-control"
                       id="objectYes"
                       name="objectYes"
                       #varObjectYes="ngModel"
                       [ngModel]="objectYes"
                       [disabled]="acknowledgeNo == true || (!manualDocument?.bt_VerdeManualDocumentsControl?.bt_allowedit && manualDocument?.bt_attached)" />
                <label for="objectNo"
                       style="margin-left: 5px; margin-bottom: unset">No:</label>
                <input style="width: 25px; height: 20px; margin: 3px 0 0 5px; vertical-align: middle"
                       (change)="objectNoChange($event.target.checked, varObjectYes)"
                       type="checkbox"
                       kendoCheckBox
                       class="form-control"
                       id="objectNo"
                       name="objectNo"
                       #varObjectNo="ngModel"
                       [ngModel]="objectNo"
                       [disabled]="acknowledgeNo == true || (!manualDocument?.bt_VerdeManualDocumentsControl?.bt_allowedit && manualDocument?.bt_attached)" />
              </div>
            </div>
          </div>

          <div *ngIf="!objectYes"></div>

          <div *ngIf="objectYes"
               class="form-group">
            <label for="documentUpload">Objection Reason:</label>
            <br />
            <input type="file"
                   title="Objection Reason"
                   id="documentUpload"
                   name="documentUpload"
                   required
                   #varDocumentUpload="ngModel"
                   ngModel
                   (change)="manualDocumentSelected($event, varDocumentUpload)"
                   [disabled]="(!manualDocument?.bt_VerdeManualDocumentsControl?.bt_allowedit)" />
          </div>

        </div>
      </div>
    </div>
  </form>
</verde-modal>

<verde-modal modalTitle="Confirmation"
             id="confirmDocumentManualModal"
             modalMaxWidth="400"
             [hasCancelButton]="false"
             submitButton="Continue">

  <div style="position: relative; height: 60px;">
    <ngx-spinner [disableAnimation]="disableAnimation"
                 name="confirmmanualdocumentupload"
                 bdColor="rgb(237, 237, 237)"
                 size="default"
                 color="#6264a7"
                 type="ball-clip-rotate"
                 [fullScreen]="false">

    </ngx-spinner>

    <p>{{confirmationMessage}}</p>

  </div>
</verde-modal>