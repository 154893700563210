/* tslint:disable */
/* eslint-disable */
import { ProfileMyProfileFields } from './profile-my-profile-fields';
import { ProfileBtGridActions } from './profile-bt-grid-actions';
import { ProfileBtMyProfilePages } from './profile-bt-my-profile-pages';
import { ProfileBtPageOption } from './profile-bt-page-option';
export interface ProfileMyProfileGroups {
  bt_columnno?: null | number;
  bt_displayname?: null | string;
  bt_gridactions?: null | Array<ProfileBtGridActions>;
  bt_option?: ProfileBtPageOption;
  bt_page?: ProfileBtMyProfilePages;
  bt_relatedtablename?: null | string;
  bt_seqnoincolumn?: null | number;
  bt_verdemyprofilegroup?: null | string;
  bt_verdemyprofilegroupsid?: null | string;
  fields?: null | Array<ProfileMyProfileFields>;
}
