import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments-verde/environment';
import { AuthenticationService, StorageService, ViewSDKClient } from '@verde/core';
import { UserApiConfigService, UserAppConfigDto, UserSecurityFunctionDto, UserUserDto, UserVerdeErrorType } from '@verde/shared';
import { uniqBy } from 'lodash';
import { NgxPermissionsService } from 'ngx-permissions';
import { BehaviorSubject, debounceTime, distinctUntilChanged } from 'rxjs';
import { AuthState } from '../models/auth-state';

export interface UserSecurityLegalEntity {
  legalEntityId?: string;
  legalEntityName?: null | string;
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  config$: BehaviorSubject<UserAppConfigDto | undefined> = new BehaviorSubject<UserAppConfigDto | undefined>(undefined);
  disableAnimation$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  proxyUser$: BehaviorSubject<UserUserDto | undefined> = new BehaviorSubject<UserUserDto | undefined>(undefined);
  loaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  permissionsLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private authenticationService: AuthenticationService,
    private userApiConfigService: UserApiConfigService,
    private storageService: StorageService,
    private router: Router,
    private permissionsService: NgxPermissionsService,
    private viewSDKClient: ViewSDKClient,
  ) {
    this.authenticationService.authState$.pipe(debounceTime(1000), distinctUntilChanged()).subscribe((authState) => {
      this.getUserAppConfig(authState);
    });
  }

  private async getUserAppConfig(authState: AuthState | undefined) {
    if (authState) {
      this.loaded$.next(false);
      if (authState.isMicrosoftAuthed || authState.isTeamsAuthed) {
        try {
          const config = await this.userApiConfigService
            .getUserAppConfig({
              body: {
                userEmail: authState.userEmail ?? '',
              },
            })
            .toPromise();

          if (config) {
            this.config$.next(config);
            this.storageService.sessionSaveKey('subKey', config?.verdeEnvironmentConfig?.subscriptionKey ?? '');
            this.storageService.sessionSaveKey('APIURL', config?.verdeEnvironmentConfig?.apiManagement ?? '');
            this.proxyUser$.next(config?.user);
            this.loadPermissions(config?.security?.functions);
            (window as any).tenantId = authState.tenantId;
            if (environment.envConfig.includes('local')) {
              this.viewSDKClient.credentialKey = 'f54a9017a2714dcfa2f453c7fb952d07';
            } else {
              this.viewSDKClient.credentialKey = config?.verdeEnvironmentConfig?.adobeEmbedKey ?? '';
            }
            this.loaded$.next(true);
          } else {
            this.clear(undefined);
          }
        } catch (err) {
          this.clear({
            verdeEnvironmentConfig: {
              verdeError: true,
              verdeErrorType: UserVerdeErrorType.ConfigNotFound,
              verdeErrorMessage: 'Config Load Failed',
              verdeErrorDetail: 'Verde Platform Config not found due to connection timeout. Please try again later.',
            },
          });
          this.loaded$.next(true);
        }
      } else {
        this.clear(undefined);
      }
    }
  }

  clear(config?: UserAppConfigDto) {
    this.config$.next(config);
    this.loaded$.next(false);
    this.storageService.sessionDeleteKey('subKey');
    this.storageService.sessionDeleteKey('APIURL');
    this.proxyUser$.next(undefined);
    this.clearPermissions();
    this.router.navigate(['/login']);
  }

  // Proxy Functionality

  changeProxyUser(user: UserUserDto) {
    this.proxyUser$.next(user);
  }

  applyNewProxyUser(user: UserUserDto) {
    if (user.employeeId === this.user?.employeeId) {
      this.proxyUser$.next(this.user);
    } else {
      this.team?.forEach((t: UserUserDto) => {
        if (t.employeeId === user.employeeId) {
          this.proxyUser$.next(t);
        }
      });
    }
  }

  // Getters

  get verdeEnvironmentConfig() {
    return this.config$.getValue()?.verdeEnvironmentConfig;
  }

  get legalEntityConfig() {
    return this.config$.getValue()?.legalEntityConfig;
  }

  get proxyUser() {
    return this.proxyUser$.getValue();
  }

  get user() {
    return this.config$.getValue()?.user;
  }

  get managerTeam() {
    return this.config$.getValue()?.managerTeam ?? [];
  }

  get team() {
    return this.config$.getValue()?.team ?? [];
  }

  // Permissions

  get functions() {
    return this.config$.getValue()?.security?.functions ?? [];
  }

  loadPermissions(functions: null | undefined | Array<UserSecurityFunctionDto>) {
    this.permissionsLoaded$.next(false);

    if (functions && Array.isArray(functions)) {
      const permissions = functions.map((f) => f.technicalName?.toString() ?? '').filter((f) => f !== '');
      this.permissionsService.loadPermissions([...new Set([...permissions])]);
      this.permissionsLoaded$.next(true);
    }
  }

  clearPermissions() {
    this.permissionsLoaded$.next(false);
    this.permissionsService.flushPermissions();
  }

  // Permissions Filter
  //public filterUserPermissions(module: string, grouping: string, isDropdown: boolean): Array<UserSecurityFunctionDto> {
  //  let functionsFiltered: Array<UserSecurityFunctionDto> = new Array<UserSecurityFunctionDto>();

  //  if (this.user) {
  //    if (isDropdown) {
  //      this.functions?.forEach((t: UserSecurityFunctionDto) => {
  //        if (t.module === module && t.dropdownName === grouping && t.legalEntityId === this.user?.legalEntityId) {
  //          functionsFiltered.push(t);
  //        }
  //      });
  //    } else {
  //      this.functions?.forEach((t: UserSecurityFunctionDto) => {
  //        if (t.module === module && t.menuContext === grouping && t.legalEntityId === this.user?.legalEntityId) {
  //          functionsFiltered.push(t);
  //        }
  //      });
  //    }
  //  }
  //  return functionsFiltered;
  //}

  // Permissions Filter
  public filterUserPermissions(module: string, grouping: string, isDropdown: boolean): Array<UserSecurityFunctionDto> {
    let functionsFiltered: Array<UserSecurityFunctionDto> = new Array<UserSecurityFunctionDto>();
    let uniqueKeys = new Set<string>();

    if (this.user) {
      if (isDropdown) {
        this.functions?.forEach((t: UserSecurityFunctionDto) => {
          if (t.module === module && t.dropdownName === grouping && t.legalEntityId === this.user?.legalEntityId) {
            const uniqueKey = `${t.module}-${t.dropdownName}-${t.legalEntityId}-${t.functionId}`;
            if (!uniqueKeys.has(uniqueKey)) {
              uniqueKeys.add(uniqueKey);
              functionsFiltered.push(t);
            }
          }
        });
      } else {
        this.functions?.forEach((t: UserSecurityFunctionDto) => {
          if (t.module === module && t.menuContext === grouping && t.legalEntityId === this.user?.legalEntityId) {
            const uniqueKey = `${t.module}-${t.menuContext}-${t.legalEntityId}-${t.functionId}`;
            if (!uniqueKeys.has(uniqueKey)) {
              uniqueKeys.add(uniqueKey);
              functionsFiltered.push(t);
            }
          }
        });
      }
    }
    console.log('Filtered Functions:', functionsFiltered);
    return functionsFiltered;
  }
  public sortGroupMenuItems(functionList: Array<UserSecurityFunctionDto>): any {
    interface GroupedMenu {
      groupName: string;
      groupingSequence: number;
      items: UserSecurityFunctionDto[];
      expanded?: boolean;
    }

    const groupedMenu: GroupedMenu[] = Array.from(
      functionList
        .reduce((map, item) => {
          if (!map.has(item.groupName)) {
            map.set(item.groupName, { groupName: item.groupName, groupingSequence: item.groupingSequence, items: [] });
          }
          map.get(item.groupName).items.push(item);
          return map;
        }, new Map<string, GroupedMenu>())
        .values(),
    ).sort((a, b) => a.groupingSequence - b.groupingSequence);

    groupedMenu.forEach((group) => {
      group.items.sort((a, b) => a.menuSequence - b.menuSequence);
      group.expanded = group.items.some((item) => item.autoExpand);
    });

    console.log('Groups', groupedMenu);
    return groupedMenu;
  }

  public get isPermissionsLoaded() {
    return this.permissionsLoaded$.getValue();
  }

  public userHasPermission(technicalName: string) {
    const func = this.getPermission(technicalName);
    return func !== undefined && func !== null;
  }

  public getPermission(technicalName: string) {
    return this.functions.find((func) => func.technicalName === technicalName && func.legalEntityId === this.user?.legalEntityId);
  }

  public getPermissionApprovalSteps() {
    return this.functions.filter((func) => func.approvalStep);
  }

  public getPermissionModules() {
    const entries = this.functions.filter((func) => func.module);
    return uniqBy(entries, 'module');
  }

  public getLegalEntities(): UserSecurityLegalEntity[] {
    const entries = this.functions.map((f) => {
      return { legalEntityName: f.legalEntityName, legalEntityId: f.legalEntityId };
    });

    return uniqBy(entries, 'legalEntityId');
  }

  public getLegalEntityIds(): string[] {
    return this.getLegalEntities()
      .map((e) => e.legalEntityId ?? '')
      .filter(Boolean);
  }

  public getLegalEntitiesWithPermission(technicalName: string): UserSecurityLegalEntity[] {
    const entries = this.functions
      .filter((func) => func.technicalName === technicalName)
      .map((f) => {
        return { legalEntityName: f.legalEntityName, legalEntityId: f.legalEntityId };
      });

    return uniqBy(entries, 'legalEntityId');
  }

  // getVersionNumber() {
  //   this.sharedApiVersionService
  //     .getVersionNumber()
  //     .pipe(take(1))
  //     .subscribe(
  //       (ret) => {
  //         this.backendVersionNumber = ret.versionNumber;
  //       },
  //       (error) => {
  //         console.error(error);
  //       },
  //       () => {
  //         this.browserIncorrectVersionFlag = false;

  //         console.log('Backend v.' + this.backendVersionNumber);
  //         console.log('Frontend v.' + this.versionService.versionNumber);

  //         if (this.versionService.versionNumber !== this.backendVersionNumber) {
  //           this.versionAllowLoad = false;

  //           this.spinner.hide('apploadingbutton');

  //           this.versionMessageLine1 = 'A new version of Verde is available.';
  //           if (this.authenticationService.isTeamsAuthed) {
  //             this.versionMessageLine2 = 'Please Sign Out of Microsoft Teams to load the latest version.';
  //           } else {
  //             this.browserIncorrectVersionFlag = true;
  //             this.versionAllowLoad = true;
  //             this.allowContinue = true;
  //             this.modalButtonText = 'Logout';
  //             this.versionMessageLine2 = 'Please select Log Out and reload.';
  //           }
  //         } else {
  //           this.versionAllowLoad = true;
  //         }

  //         this.UserAuthedAndContextCheck();
  //       },
  //     );
  // }
}
