/* tslint:disable */
/* eslint-disable */
import { User } from './user';
export interface DvPerformanceReview {
  '_bt_period_value'?: null | string;
  '_bt_reviewtemplate_value'?: null | string;
  '_bt_summary_value'?: null | string;
  '_bthr_employee_value'?: null | string;
  '_bthr_performancereviewcycle_value'?: null | string;
  bt_commission?: null | boolean;
  bt_duedate?: null | string;
  bt_employeeapprovaldate?: null | string;
  bt_employeecompleted?: null | boolean;
  bt_employeerecallaccepted?: null | boolean;
  bt_employeerecallaccepteddate?: null | string;
  bt_employeereviewstatus?: null | number;
  bt_kpi?: null | boolean;
  bt_managerapprovaldate?: null | string;
  bt_managercompleted?: null | boolean;
  bt_managerrecallrequest?: null | boolean;
  bt_managerrecallrequestdate?: null | string;
  bt_managerreviewstatus?: null | number;
  bt_reviewenhancement?: null | boolean;
  bt_totalscore?: null | number;
  bthr_Employee?: User;
  bthr_employeeperformancereviewid?: null | string;
  bthr_name?: null | string;
  bthr_performancereviewdocument_name?: null | string;
  bthr_recordstatus?: null | number;
  bthr_reviewdate?: null | string;
  bthr_reviewperiodenddate?: null | string;
  bthr_reviewperiodstartdate?: null | string;
  bthr_summaryofresults?: null | string;
  odataCommission?: null | string;
  odataEmployee?: null | string;
  odataEmployeeCompleted?: null | string;
  odataEmployeeStatus?: null | string;
  odataKPI?: null | string;
  odataManager?: null | string;
  odataManagerCompleted?: null | string;
  odataManagerStatus?: null | string;
  odataPeriod?: null | string;
  odataRecordStatus?: null | string;
  odataReviewCycle?: null | string;
  odataSummary?: null | string;
  odataTemplate?: null | string;
}
