/* tslint:disable */
/* eslint-disable */
import { EquipmentBtAllocationStatus } from './equipment-bt-allocation-status';
import { EquipmentBtApprovalStatus } from './equipment-bt-approval-status';
import { EquipmentBtLineType } from './equipment-bt-line-type';
export interface EquipmentGetEquipmentRequestDetailAllocationCountQuery {
  allocationStatus?: null | Array<EquipmentBtAllocationStatus>;
  approvalStatus?: null | Array<EquipmentBtApprovalStatus>;
  dataverseUrl?: null | string;
  legalEntityID?: null | Array<string>;
  lineType?: null | Array<EquipmentBtLineType>;
  locationID?: null | Array<string>;
}
