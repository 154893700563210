<!-- Rendom Button -->
<button id="showApprovalModal" hidden (click)="getFleetMovement(fleetTransferApprovalForm)"></button>
<!-- #region Approve Transfer Modal -->
<div *ngIf="showModal == false">
  <form #fleetTransferApprovalForm="ngForm" novalidate autocomplete="off" style="position: relative; margin-top: 20px">
    <ngx-spinner
      [disableAnimation]="disableAnimation"
      name="fleettransferapproval"
      bdColor="rgb(237, 237, 237)"
      size="default"
      color="#6264a7"
      type="ball-clip-rotate"
      [fullScreen]="false"
    >
    </ngx-spinner>

    <div *ngIf="!actionAlreadyCompletedWarning">
      <div class="modal_grid">
        <div *ngIf="selectedTransfer?.odataFromEmployee != null && !locationToEmployeeTransfer" class="form-group">
          <label for="confirmFromEmployee">From Employee:</label>
          <kendo-textbox [readonly]="true" id="confirmFromEmployee" name="confirmFromEmployee" [ngModel]="selectedTransfer?.bt_FromEmployee?.bt_fullname">
          </kendo-textbox>
        </div>

        <div *ngIf="selectedTransfer?.odataFromLocation != null && !locationToEmployeeTransfer" class="form-group">
          <label for="confirmFromLocation">From Location:</label>
          <kendo-textbox [readonly]="true" id="confirmFromLocation" name="confirmFromLocation" [ngModel]="selectedTransfer?.odataFromLocation"> </kendo-textbox>
        </div>

        <div *ngIf="selectedTransfer?.odataToEmployee != null" class="form-group">
          <label for="confirmToEmployee">To Employee:</label>
          <kendo-textbox [readonly]="true" id="confirmToEmployee" name="confirmToEmployee" [ngModel]="selectedTransfer?.bt_ToEmployee?.bt_fullname">
          </kendo-textbox>
        </div>

        <div *ngIf="selectedTransfer?.odataToLocation != null && !locationToEmployeeTransfer" class="form-group">
          <label for="confirmToLocation">To Location:</label>
          <kendo-textbox [readonly]="true" id="confirmToLocation" name="confirmToLocation" [ngModel]="selectedTransfer?.odataToLocation"> </kendo-textbox>
        </div>

        <div *ngIf="!locationToEmployeeTransfer" class="form-group">
          <label for="confirmModel">Make - Model:</label>
          <kendo-textbox [readonly]="true" id="confirmModel" name="confirmModel" [ngModel]="confirmFormDefaultState?.confirmModel"> </kendo-textbox>
        </div>

        <div *ngIf="!locationToEmployeeTransfer" class="form-group">
          <label for="confirmLicenseNo">License No:</label>
          <kendo-textbox [readonly]="true" id="confirmLicenseNo" name="confirmLicenseNo" [ngModel]="confirmFormDefaultState?.confirmLicenseNo"> </kendo-textbox>
        </div>

        <div class="form-group wide">
          <label for="confirmRequestReason">Reason For Request:</label>
          <kendo-textarea
            id="confirmRequestReason"
            name="confirmRequestReason"
            resizable="vertical"
            [readonly]="true"
            [ngModel]="confirmFormDefaultState?.confirmRequestReason"
          >
          </kendo-textarea>
        </div>

        <div *ngIf="agreedNo" class="form-group wide">
          <label for="confirmRequestComment">Reason for not approving:</label>
          <kendo-textarea
            id="confirmRequestComment"
            name="confirmRequestComment"
            #varConfirmRequestComment="ngModel"
            maxlength="300"
            resizable="vertical"
            required
            [ngModel]
          >
          </kendo-textarea>
          <div class="alert alert-danger" *ngIf="varConfirmRequestComment.touched && !varConfirmRequestComment.valid">
            <div *ngIf="varConfirmRequestComment.errors?.required">Field is required!</div>
            <div *ngIf="varConfirmRequestComment.errors?.minlength">Enter a detailed reason.</div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div style="float: right; display: inline-flex; margin-bottom: unset">
          <label style="margin-bottom: unset">Approve this transfer:</label>

          <div style="display: inline-flex; margin-bottom: unset; margin-left: 5px; float: right">
            <label style="margin-bottom: unset" for="agreedYes">Yes:</label>
            <input
              style="width: 25px; height: 20px; margin: 3px 0 0 5px; vertical-align: middle"
              (change)="agreedYesChange($event.target.checked, varAgreedNo)"
              type="checkbox"
              kendoCheckBox
              class="form-control"
              id="agreedYes"
              name="agreedYes"
              #varAgreedYes="ngModel"
              [ngModel]="agreedYes"
            />

            <label for="agreedNo" style="margin-left: 5px; margin-bottom: unset">No:</label>
            <input
              style="width: 25px; height: 20px; margin: 3px 0 0 5px; vertical-align: middle"
              (change)="agreedNoChange($event.target.checked, varAgreedYes)"
              type="checkbox"
              kendoCheckBox
              class="form-control"
              id="agreedNo"
              name="agreedNo"
              #varAgreedNo="ngModel"
              [ngModel]="agreedNo"
            />
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="actionAlreadyCompletedWarning">
      <p>This approval has already been processed.</p>
    </div>
  </form>

  <button style="margin-top: 30px; position: absolute; right: 0; margin-right: 10px" [disabled]="!enableSubmit" kendoButton (click)="openConfirmModal()">
    Submit
  </button>
</div>

<verde-modal
  id="newFleetTransferApprovalModal"
  modalTitle="Approve / Reject Transfer Request"
  modalMinWidth="750"
  submitButton="{{ approveButtonText }}"
  (actionButton)="patchTransferApproval(fleetTransferApprovalForm)"
  (modalCancelled)="hideModal()"
  [submitActive]="fleetTransferApprovalForm.valid && (agreedYes || agreedNo)"
  submitConfirmationMessage="Please confirm that you would like to {{ confirmationAction }} this fleet transfer"
  cancelConfirmationMessage="Please confirm that you would like to cancel"
  [hasSubmitConfirmation]="true"
  [hasCancelConfirmation]="fleetTransferApprovalForm.touched && !fleetTransferApprovalForm.pristine"
>
  <form #fleetTransferApprovalForm="ngForm" novalidate autocomplete="off" style="position: relative">
    <ngx-spinner
      [disableAnimation]="disableAnimation"
      name="fleettransferapproval"
      bdColor="rgb(237, 237, 237)"
      size="default"
      color="#6264a7"
      type="ball-clip-rotate"
      [fullScreen]="false"
    >
    </ngx-spinner>

    <div *ngIf="!actionAlreadyCompletedWarning">
      <div class="modal_grid">
        <div *ngIf="selectedTransfer?.odataFromEmployee != null && !locationToEmployeeTransfer" class="form-group">
          <label for="confirmFromEmployee">From Employee:</label>
          <kendo-textbox [readonly]="true" id="confirmFromEmployee" name="confirmFromEmployee" [ngModel]="selectedTransfer?.bt_FromEmployee?.bt_fullname">
          </kendo-textbox>
        </div>

        <div *ngIf="selectedTransfer?.odataFromLocation != null && !locationToEmployeeTransfer" class="form-group">
          <label for="confirmFromLocation">From Location:</label>
          <kendo-textbox [readonly]="true" id="confirmFromLocation" name="confirmFromLocation" [ngModel]="selectedTransfer?.odataFromLocation"> </kendo-textbox>
        </div>

        <div *ngIf="selectedTransfer?.odataToEmployee != null" class="form-group">
          <label for="confirmToEmployee">To Employee:</label>
          <kendo-textbox [readonly]="true" id="confirmToEmployee" name="confirmToEmployee" [ngModel]="selectedTransfer?.bt_ToEmployee?.bt_fullname">
          </kendo-textbox>
        </div>

        <div *ngIf="selectedTransfer?.odataToLocation != null && !locationToEmployeeTransfer" class="form-group">
          <label for="confirmToLocation">To Location:</label>
          <kendo-textbox [readonly]="true" id="confirmToLocation" name="confirmToLocation" [ngModel]="selectedTransfer?.odataToLocation"> </kendo-textbox>
        </div>

        <div *ngIf="!locationToEmployeeTransfer" class="form-group">
          <label for="confirmModel">Make - Model:</label>
          <kendo-textbox [readonly]="true" id="confirmModel" name="confirmModel" [ngModel]> </kendo-textbox>
        </div>

        <div *ngIf="!locationToEmployeeTransfer" class="form-group">
          <label for="confirmLicenseNo">License No:</label>
          <kendo-textbox [readonly]="true" id="confirmLicenseNo" name="confirmLicenseNo" [ngModel]> </kendo-textbox>
        </div>

        <div class="form-group wide">
          <label for="confirmRequestReason">Reason For Request:</label>
          <kendo-textarea id="confirmRequestReason" name="confirmRequestReason" resizable="vertical" [readonly]="true" [ngModel]> </kendo-textarea>
        </div>

        <div *ngIf="agreedNo" class="form-group wide">
          <label for="confirmRequestComment">Reason for not approving:</label>
          <kendo-textarea
            id="confirmRequestComment"
            name="confirmRequestComment"
            #varConfirmRequestComment="ngModel"
            maxlength="300"
            resizable="vertical"
            required
            [ngModel]
          >
          </kendo-textarea>
          <div class="alert alert-danger" *ngIf="varConfirmRequestComment.touched && !varConfirmRequestComment.valid">
            <div *ngIf="varConfirmRequestComment.errors?.required">Field is required!</div>
            <div *ngIf="varConfirmRequestComment.errors?.minlength">Enter a detailed reason.</div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div style="float: right; display: inline-flex; margin-bottom: unset">
          <label style="margin-bottom: unset">Approve this transfer:</label>

          <div style="display: inline-flex; margin-bottom: unset; margin-left: 5px; float: right">
            <label style="margin-bottom: unset" for="agreedYes">Yes:</label>
            <input
              style="width: 25px; height: 20px; margin: 3px 0 0 5px; vertical-align: middle"
              (change)="agreedYesChange($event.target.checked, varAgreedNo)"
              type="checkbox"
              kendoCheckBox
              class="form-control"
              id="agreedYes"
              name="agreedYes"
              #varAgreedYes="ngModel"
              [ngModel]="agreedYes"
            />

            <label for="agreedNo" style="margin-left: 5px; margin-bottom: unset">No:</label>
            <input
              style="width: 25px; height: 20px; margin: 3px 0 0 5px; vertical-align: middle"
              (change)="agreedNoChange($event.target.checked, varAgreedYes)"
              type="checkbox"
              kendoCheckBox
              class="form-control"
              id="agreedNo"
              name="agreedNo"
              #varAgreedNo="ngModel"
              [ngModel]="agreedNo"
            />
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="actionAlreadyCompletedWarning">
      <p>This approval has already been processed.</p>
    </div>
  </form>
</verde-modal>

<!-- #endregion Approve Transfer Modal -->
<!-- #region Confirmation Modal -->
<verde-modal
  modalTitle="Confirmation"
  id="confirmFleetTransferApprovalModal"
  modalMaxWidth="400"
  [hasCancelButton]="true"
  submitButton="Continue"
  (actionButton)="patchTransferApproval(fleetTransferApprovalForm)"
>
  <div style="position: relative; height: 60px">
    <ngx-spinner
      [disableAnimation]="disableAnimation"
      name="confirmfleettransferapproval"
      bdColor="rgb(237, 237, 237)"
      size="default"
      color="#6264a7"
      type="ball-clip-rotate"
      [fullScreen]="false"
    >
    </ngx-spinner>

    <p>Please confirm that you would like to {{ confirmationAction }} this fleet transfer</p>
  </div>
</verde-modal>
<!-- #endregion Confirmation Modal -->
