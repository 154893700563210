/* tslint:disable */
/* eslint-disable */
import { BtRegNo } from './bt-reg-no';
import { BtVehicleInspectionChecklistDetailVehic } from './bt-vehicle-inspection-checklist-detail-vehic';
import { User } from './user';
export interface FleetChecklist {
  '_bt_checklisttemplate_value'?: null | string;
  '_bt_fleetmovement_value'?: null | string;
  '_bt_location_value'?: null | string;
  '_bt_make_value'?: null | string;
  '_bt_model_value'?: null | string;
  '_bt_regno_value'?: null | string;
  '_bt_responder_value'?: null | string;
  bt_RegNo?: BtRegNo;
  bt_Responder?: User;
  bt_VehicleInspectionChecklistDetail_Vehic?: null | Array<BtVehicleInspectionChecklistDetailVehic>;
  bt_calendarmonth?: null | string;
  bt_checklistavailable?: null | boolean;
  bt_duehours?: null | number;
  bt_feedbackcompleted?: null | boolean;
  bt_frequency?: null | number;
  bt_licensenumber?: null | string;
  bt_movementtype?: null | number;
  bt_odo?: null | number;
  bt_peerreviewstatus?: null | number;
  bt_transferdate?: null | string;
  bt_transferdirection?: null | number;
  bt_vehicleinspectionchecklistsummary?: null | string;
  bt_vehicleinspectionchecklistsummaryid?: null | string;
  createdon?: null | string;
  odataChecklistAvailable?: null | string;
  odataChecklistStatus?: null | string;
  odataFleetItem?: null | string;
  odataFrequency?: null | string;
  odataMake?: null | string;
  odataModel?: null | string;
  odataMovementType?: null | string;
  odataPostResponder?: null | string;
  odataPostResponderLegalEntity?: null | string;
  odataTemplate?: null | string;
  odataTransferDirection?: null | string;
}
