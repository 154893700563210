/* tslint:disable */
/* eslint-disable */
import { TemplateTemplateData } from './template-template-data';
import { TemplateTemplateGroup } from './template-template-group';
import { TemplateTemplateLegalEntity } from './template-template-legal-entity';
import { TemplateTemplateMeasure } from './template-template-measure';
import { TemplateTemplateSubgroup } from './template-template-subgroup';
import { TemplateBtResponseType } from './template-bt-response-type';
import { TemplateBtTemplateType } from './template-bt-template-type';
export interface TemplateTemplateLink {
  bt_Group?: TemplateTemplateGroup;
  bt_LegalEntity?: TemplateTemplateLegalEntity;
  bt_Measure?: TemplateTemplateMeasure;
  bt_Subgroup?: TemplateTemplateSubgroup;
  bt_Template?: TemplateTemplateData;
  bt_TemplateType?: TemplateBtTemplateType;
  bt_allowimage?: null | boolean;
  bt_imagerequired?: null | boolean;
  bt_name?: null | string;
  bt_peerreviewtemplatelinksid?: null | string;
  bt_responsetype?: TemplateBtResponseType;
}
