import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
// Store
//Related
import * as fromProfileRelatedReducer from '../store/reducers/profile-related.reducer';
// Equipment Request Detail
import * as CountryActions from '../store/actions/country.actions';
import * as fromCountryReducer from '../store/reducers/country.reducer';
import { QualificationsGetCountriesQuery } from '@verde/shared';

@Injectable({
  providedIn: 'root',
})
export class CountryService {
  // Country
  countryLoading$: Observable<boolean>;
  countryObjects$: Observable<any[]>;

  constructor(private store: Store<fromProfileRelatedReducer.ProfileRelatedState>) {
    // Country
    this.countryLoading$ = this.store.pipe(select(fromCountryReducer.isCountryLoading));
    this.countryObjects$ = this.store.pipe(select(fromCountryReducer.getCountry));
  }

  // Country Detail
  // Get
  getCountry(body: QualificationsGetCountriesQuery) {
    this.store.dispatch(new CountryActions.CountryGetAll(body));
  }

  // Clear
  countryClear() {
    this.store.dispatch(new CountryActions.CountryClear(null));
  }
}
