/* tslint:disable */
/* eslint-disable */
export interface DvEmployeeDependents {
  bthr_ageyears?: null | number;
  bthr_dateofbirth?: null | string;
  bthr_employeedependentid?: null | string;
  bthr_fullname?: null | string;
  bthr_relationship?: null | number;
  odataMedicalInsurance?: null | string;
  odataRecordStatus?: null | string;
  odataRelationship?: null | string;
  odataStatus?: null | string;
}
