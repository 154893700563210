import { QualificationsGetEmployeeEducationalQualificationRenewalQuery } from '@verde/shared';
import { Action } from '@ngrx/store';

export enum EmployeeEducationalQualificationRenewalActionTypes {
  // Get All VaccinationTypes
  EMPLOYEEEDUCATIONALQUALIFICATIONRENEWAL_GET_ALL = '[EmployeeEducationalQualificationRenewal] Get All',
  EMPLOYEEEDUCATIONALQUALIFICATIONRENEWAL_GET_ALL_SUCCESS = '[EmployeeEducationalQualificationRenewal] Get All Success',
  EMPLOYEEEDUCATIONALQUALIFICATIONRENEWAL_GET_ALL_ERROR = '[EmployeeEducationalQualificationRenewal] Get All Error',

  // UPDATE EmployeeEducationalQualificationRenewal
  EMPLOYEEEDUCATIONALQUALIFICATIONRENEWAL_UPDATE = '[EmployeeEducationalQualificationRenewal] UPDATE',
  EMPLOYEEEDUCATIONALQUALIFICATIONRENEWAL_UPDATE_SUCCESS = '[EmployeeEducationalQualificationRenewal] UPDATE Success',
  EMPLOYEEEDUCATIONALQUALIFICATIONRENEWAL_UPDATE_ERROR = '[EmployeeEducationalQualificationRenewal] UPDATE Error',

  // Add EmployeeEducationalQualificationRenewal
  EMPLOYEEEDUCATIONALQUALIFICATIONRENEWAL_UPLOAD = '[EmployeeEducationalQualificationRenewal] Upload',
  EMPLOYEEEDUCATIONALQUALIFICATIONRENEWAL_UPLOAD_SUCCESS = '[EmployeeEducationalQualificationRenewal] Upload Success',
  EMPLOYEEEDUCATIONALQUALIFICATIONRENEWAL_UPLOAD_ERROR = '[EmployeeEducationalQualificationRenewal] Upload Error',

  // Clear All VaccinationTypes
  EMPLOYEEEDUCATIONALQUALIFICATIONRENEWAL_CLEAR = '[EmployeeEducationalQualificationRenewal] Clear',
}

//Get All EmployeeEducationalQualificationRenewal
export class EmployeeEducationalQualificationRenewalGetAll implements Action {
  readonly type = EmployeeEducationalQualificationRenewalActionTypes.EMPLOYEEEDUCATIONALQUALIFICATIONRENEWAL_GET_ALL;
  constructor(public body: QualificationsGetEmployeeEducationalQualificationRenewalQuery) {}
}

export class EmployeeEducationalQualificationRenewalGetAllSuccess implements Action {
  readonly type = EmployeeEducationalQualificationRenewalActionTypes.EMPLOYEEEDUCATIONALQUALIFICATIONRENEWAL_GET_ALL_SUCCESS;
  constructor(public payload: any) {}
}

export class EmployeeEducationalQualificationRenewalGetAllError implements Action {
  readonly type = EmployeeEducationalQualificationRenewalActionTypes.EMPLOYEEEDUCATIONALQUALIFICATIONRENEWAL_GET_ALL_ERROR;
  constructor(public payload: any) {}
}

// UPDATE EMPLOYEEEDUCATIONALQUALIFICATIONRENEWAL
export class EmployeeEducationalQualificationRenewalUpdate implements Action {
  readonly type = EmployeeEducationalQualificationRenewalActionTypes.EMPLOYEEEDUCATIONALQUALIFICATIONRENEWAL_UPDATE;
  constructor(public payload: any) {}
}

export class EmployeeEducationalQualificationRenewalUpdateSuccess implements Action {
  readonly type = EmployeeEducationalQualificationRenewalActionTypes.EMPLOYEEEDUCATIONALQUALIFICATIONRENEWAL_UPDATE_SUCCESS;
  constructor(public payload: any) {}
}

export class EmployeeEducationalQualificationRenewalUpdateError implements Action {
  readonly type = EmployeeEducationalQualificationRenewalActionTypes.EMPLOYEEEDUCATIONALQUALIFICATIONRENEWAL_UPDATE_ERROR;
  constructor(public payload: any) {}
}

// UPLOAD MEASURE
export class EmployeeEducationalQualificationRenewalsUpload implements Action {
  readonly type = EmployeeEducationalQualificationRenewalActionTypes.EMPLOYEEEDUCATIONALQUALIFICATIONRENEWAL_UPLOAD;
  constructor(public payload: any) {}
}

export class EmployeeEducationalQualificationRenewalsUploadSuccess implements Action {
  readonly type = EmployeeEducationalQualificationRenewalActionTypes.EMPLOYEEEDUCATIONALQUALIFICATIONRENEWAL_UPLOAD_SUCCESS;
  constructor(public payload: any) {}
}

export class EmployeeEducationalQualificationRenewalsUploadError implements Action {
  readonly type = EmployeeEducationalQualificationRenewalActionTypes.EMPLOYEEEDUCATIONALQUALIFICATIONRENEWAL_UPLOAD_ERROR;
  constructor(public payload: any) {}
}

// Clear All
export class EmployeeEducationalQualificationRenewalClear implements Action {
  readonly type = EmployeeEducationalQualificationRenewalActionTypes.EMPLOYEEEDUCATIONALQUALIFICATIONRENEWAL_CLEAR;
  constructor(public payload: any) {}
}

export type EmployeeEducationalQualificationRenewalActions =
  // Get All EmployeeEducationalQualificationRenewal
  | EmployeeEducationalQualificationRenewalGetAll
  | EmployeeEducationalQualificationRenewalGetAllSuccess
  | EmployeeEducationalQualificationRenewalGetAllError
  // Set All Structure Change Requests
  | EmployeeEducationalQualificationRenewalUpdate
  | EmployeeEducationalQualificationRenewalUpdateSuccess
  | EmployeeEducationalQualificationRenewalUpdateError
  // Structure Change Request Upload
  | EmployeeEducationalQualificationRenewalsUpload
  | EmployeeEducationalQualificationRenewalsUploadSuccess
  | EmployeeEducationalQualificationRenewalsUploadError
  // Clear All EmployeeEducationalQualificationRenewal
  | EmployeeEducationalQualificationRenewalClear;
