/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BFFBaseService } from '../bff-base-service';
import { BFFApiConfiguration } from '../bff-api-configuration';
import { BFFStrictHttpResponse } from '../bff-strict-http-response';
import { BFFRequestBuilder } from '../bff-request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BFFStorageObjectDto } from '../models/bff-storage-object-dto';

@Injectable({
  providedIn: 'root',
})
export class BFFApiDocumentQueueService extends BFFBaseService {
  constructor(
    config: BFFApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation documentQueueControllerUploadFiles
   */
  static readonly DocumentQueueControllerUploadFilesPath = '/api/Document-Queue/Upload';

  /**
   * Upload Multiple Documents.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentQueueControllerUploadFiles()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  documentQueueControllerUploadFiles$Response(params: {
    body: BFFStorageObjectDto
  }): Observable<BFFStrictHttpResponse<void>> {

    const rb = new BFFRequestBuilder(this.rootUrl, BFFApiDocumentQueueService.DocumentQueueControllerUploadFilesPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as BFFStrictHttpResponse<void>;
      })
    );
  }

  /**
   * Upload Multiple Documents.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `documentQueueControllerUploadFiles$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  documentQueueControllerUploadFiles(params: {
    body: BFFStorageObjectDto
  }): Observable<void> {

    return this.documentQueueControllerUploadFiles$Response(params).pipe(
      map((r: BFFStrictHttpResponse<void>) => r.body as void)
    );
  }

}
