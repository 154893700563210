import { BtVehicleInspectionChecklistDetailVehic } from '../../api/_api_legacy/models/bt-vehicle-inspection-checklist-detail-vehic';

export interface ChecklistGroup {
  groupSortingId: number;
  groupName: string;
  subgroups: ChecklistSubgroup[];
}

export interface ChecklistSubgroup {
  showSubgroup: boolean;
  subgroupSortingId: number;
  subgroupName: string;
  measures: BtVehicleInspectionChecklistDetailVehic[];
}
