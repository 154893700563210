import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControlDirective, FormGroup } from '@angular/forms';
import { UserService } from '@verde/core';
import {
  UserAppConfigDto,
  UserUserDto,
  FleetMovement,
  FleetRental,
  RentalReason,
  SharedApiFleetMovementService,
  VehiclesFiltered,
  VerdeApprovalService,
} from '@verde/shared';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { GroupDescriptor, SortDescriptor, State, orderBy, process } from '@progress/kendo-data-query';
import moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, take, takeUntil } from 'rxjs';

@Component({
  selector: 'verde-fleet-request-item',
  templateUrl: './fleet-request-item.component.html',
  styleUrls: ['./fleet-request-item.component.scss'],
})
export class FleetRequestItemComponent implements OnInit, AfterViewInit, OnDestroy {
  onDestroy$: Subject<any> = new Subject();
  @ViewChild('requestNewForm') myForm: FormGroup;

  allClassifications = [
    { text: 'Company', value: 'Company' },
    { text: 'Pool', value: 'Pool' },
  ];
  // Extra option now removed: { text: 'Rental', value: 'Rental' }
  disableAnimation: boolean;

  requestEmployeeDropdownValue: string = null;

  newEmployeeNo = false;
  newEmployeeYes = true;
  showConfirmationModal: boolean = false;

  requestClassificationDropdownValue: string = 'Company';
  rentalReasonDropdownValue: string;
  confirmationMessage: string = '';

  requestNewValid: boolean = false;

  proxyUser: UserUserDto;
  selectedEmployeeForRequest: UserUserDto = {};

  allRequestEmployeeItems: Array<VehiclesFiltered> = new Array<VehiclesFiltered>();
  allRequestEmployeeFleetMovements: Array<FleetMovement> = new Array<FleetMovement>();
  allRequestNewEmployeeFleetMovements: Array<FleetMovement> = new Array<FleetMovement>();
  allVehiclesCombined: Array<VehiclesFiltered> = new Array<VehiclesFiltered>();
  allTeamMembers: Array<UserUserDto> = new Array<UserUserDto>();
  allTeamMembersFleetItems: Array<UserUserDto> = new Array<UserUserDto>();
  allRentalReasons: Array<RentalReason> = new Array<RentalReason>();

  requestValidationDate: any;
  maxDate = new Date(moment().add(100, 'years').format('YYYY-MM-DD'));
  todaysDate: string = moment().format('YYYY-MM-DD');
  todaysDateNew = new Date();
  timeNow: string = moment().format('HH:mm');
  rentalEndValidationDate: any;

  config: UserAppConfigDto;

  //Post Bodies
  postRequestBody: FleetMovement = {};
  postRentalBody: FleetRental = {};

  requestNewDefaultState = {
    requestClassification: null,
    newEmployeeYes: null,
    newEmployeeNo: null,
    newRequireDate: null,
    rentalEndDate: null,
    requestEmployeeDropdown: null,
    rentalReasonDropdown: null,
    newRequestReason: null,
  };

  checkDefaultPattern: RegExp = /^(?!default$).*/;

  // Kendo
  //request employee movements
  public requestEmployeeMovementsGridView: GridDataResult[] | VehiclesFiltered[];

  requestEmployeeMovementsGridData: GridDataResult;

  requestEmployeeMovementsState: State = {
    skip: 0,
    take: 5,
  };

  requestEmployeeMovementsSort: SortDescriptor[] = [
    {
      field: 'bthr_licensenumber',
      dir: 'asc',
    },
  ];

  requestEmployeeMovementsGroups: GroupDescriptor[];

  requestEmployeeMovementsSelection: number[] = [];

  requestEmployeeMovementsSelectedCallback = (args) => args.dataItem;

  //request employee items
  public requestEmployeeItemsGridView: GridDataResult[] | VehiclesFiltered[];

  requestEmployeeItemsGridData: GridDataResult;

  requestEmployeeItemsState: State = {
    skip: 0,
    take: 5,
  };

  requestEmployeeItemsSort: SortDescriptor[] = [
    {
      field: 'bthr_licensenumber',
      dir: 'asc',
    },
  ];

  requestEmployeeItemsGroups: GroupDescriptor[];

  requestEmployeeItemsSelection: number[] = [];

  requestEmployeeItemsSelectedCallback = (args) => args.dataItem;

  constructor(
    private spinner: NgxSpinnerService,
    private sharedApiFleetMovementService: SharedApiFleetMovementService,
    private userService: UserService,
    private cdr: ChangeDetectorRef,
    private sidebarService: VerdeApprovalService,
  ) {}

  ngOnInit(): void {
    this.userService.config$.pipe(takeUntil(this.onDestroy$)).subscribe((data) => {
      this.config = data;
    });

    this.userService.disableAnimation$.pipe(takeUntil(this.onDestroy$)).subscribe((data) => {
      this.disableAnimation = data;
    });

    this.userService.proxyUser$.pipe(takeUntil(this.onDestroy$)).subscribe((data) => {
      this.proxyUser = data;

      if (Object.keys(this.proxyUser).length > 0) {
        //this.getRentalReasons();
        // this.getMyTeam();
      }
    });
  }

  ngAfterViewInit(): void {
    this.requestNewValid = false;

    this.newEmployeeYes = false;
    this.newEmployeeNo = true;

    this.requestEmployeeDropdownValue = null;

    this.requestEmployeeMovementsGridView = [];
    this.requestEmployeeItemsGridView = [];

    this.rentalEndValidationDate = new Date(this.todaysDate + 'T' + this.timeNow);
    this.requestClassificationDropdownValue = 'Company';

    if (this.config?.legalEntityConfig.fleetNewRequestLeadTime != null) {
      this.requestValidationDate = new Date(moment().add(this.config?.legalEntityConfig.fleetNewRequestLeadTime, 'days').format('YYYY-MM-DD'));
    } else {
      this.requestValidationDate = new Date(this.todaysDate);
    }

    this.requestNewDefaultState = {
      requestClassification: this.allClassifications[0],
      newEmployeeYes: this.newEmployeeYes,
      newEmployeeNo: this.newEmployeeNo,
      newRequireDate: this.requestValidationDate,
      rentalEndDate: this.rentalEndValidationDate,
      requestEmployeeDropdown: {},
      rentalReasonDropdown: {},
      newRequestReason: null,
    };

    this.cdr.detectChanges();
    setTimeout(() => {
      this.myForm.reset(this.requestNewDefaultState);
    }, 100);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  setRequestEmployeeDropdownValue(e: UserUserDto) {
    this.requestEmployeeDropdownValue = e.employeeId;
    this.selectedEmployeeForRequest = e;

    this.requestNewValid = true;

    this.getRequestEmployeeFleetMovements(this.selectedEmployeeForRequest.employeeId, this.selectedEmployeeForRequest.legalEntityId);
    this.getRequestEmployeeItems(this.selectedEmployeeForRequest.employeeId, this.selectedEmployeeForRequest.legalEntityId);
  }

  setRequestClassificationDropdownValue(e) {
    this.requestClassificationDropdownValue = e.value;

    if (this.requestClassificationDropdownValue === 'Pool') {
      this.requestNewValid = true;
      this.requestEmployeeDropdownValue = null;
    } else {
      this.requestNewValid = false;
    }
  }

  setRentalEndValidationDate(e) {
    this.rentalEndValidationDate = e;
  }

  setRentalReasonDropdownValue(e: RentalReason) {
    this.rentalReasonDropdownValue = e.bt_fleetrentalreasonid;
    this.requestNewValid = true;
  }

  // #region Yes/No Change methods

  // New Employee
  public newEmployeeYesChange(e, element: FormControlDirective) {
    this.newEmployeeYes = e;
    this.newEmployeeNo = !e;
    element.reset();

    this.getRequestNewEmployeeFleetMovements(this.proxyUser.legalEntityId);
  }

  public newEmployeeNoChange(e, element: FormControlDirective) {
    this.newEmployeeNo = e;
    this.newEmployeeYes = !e;
    element.reset();

    this.requestEmployeeDropdownValue = null;
    this.selectedEmployeeForRequest = {};
  }

  // #endregion Yes/No Change methods

  getRentalReasons() {
    this.sharedApiFleetMovementService
      .getRentalReasons({ legalEntityID: this.proxyUser.legalEntityId })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          this.allRentalReasons = ret;
        },
        (error) => {
          console.error(error);
        },
      );
  }

  getRequestNewEmployeeFleetMovements(legalEntityID: string) {
    this.spinner.show('requestemployeemovementsgrid');

    this.sharedApiFleetMovementService
      .getRequestNewEmployeeFleetMovements({ legalEntityID: legalEntityID })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          this.allRequestNewEmployeeFleetMovements = ret;
        },
        (error) => {
          console.error(error);
        },
        () => {
          this.requestEmployeeMovementsGridView = this.allRequestNewEmployeeFleetMovements;

          this.loadRequestEmployeeMovements();

          this.spinner.hide('requestemployeemovementsgrid');
        },
      );
  }

  getRequestEmployeeFleetMovements(userID: string, legalEntityID: string) {
    this.spinner.show('requestemployeemovementsgrid');

    this.sharedApiFleetMovementService
      .getRequestEmployeeFleetMovements({ userID: userID, legalEntityID: legalEntityID })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          this.allRequestEmployeeFleetMovements = ret;
        },
        (error) => {
          console.error(error);
        },
        () => {
          this.requestEmployeeMovementsGridView = this.allRequestEmployeeFleetMovements;

          this.loadRequestEmployeeMovements();

          this.spinner.hide('requestemployeemovementsgrid');
        },
      );
  }

  getRequestEmployeeItems(userID: string, legalEntityID: string) {
    this.spinner.show('requestemployeeitemsgrid');

    this.sharedApiFleetMovementService
      .getRequestEmployeeItems({ userID: userID, legalEntityID: legalEntityID })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          this.allRequestEmployeeItems = ret;
        },
        (error) => {
          console.error(error);
        },
        () => {
          this.requestEmployeeItemsGridView = this.allRequestEmployeeItems;

          this.loadRequestEmployeeItems();

          this.spinner.hide('requestemployeeitemsgrid');
        },
      );
  }

  postRequestNew(myForm: FormGroup) {
    //this.spinner.show('confirmation');

    if (this.requestClassificationDropdownValue != 'Rental') {
      //NEW MOVEMENT TRANSFER REQUEST (Location To Employee)

      // checking if proxy
      if (this.proxyUser.employeeId === this.userService.user?.employeeId && this.proxyUser.employeeId === this.requestEmployeeDropdownValue) {
        this.postRequestBody.bt_proxy = false;
      } else {
        this.postRequestBody.bt_proxy = true;
        this.postRequestBody.odataPostProxy = '/bthr_employees(' + this.userService.user?.employeeId + ')';
      }

      //setting originator
      this.postRequestBody.odataPostOriginatorLegalEntity = '/bthr_legalentitieses(' + this.userService.user?.legalEntityId + ')';
      this.postRequestBody.odataPostOriginator = '/bthr_employees(' + this.userService.user?.employeeId + ')';

      this.postRequestBody.bt_purchaseprice = 0;
      this.postRequestBody.bt_purchasedate = moment().format('YYYY-MM-DD');

      this.postRequestBody.bt_movementtype = 692360001;
      this.postRequestBody.bt_transfertype = 692360001;

      this.postRequestBody.bt_effectivedate = myForm.value.newRequireDate;
      this.postRequestBody.bt_comment = myForm.value.newRequestReason;

      if (this.newEmployeeNo) {
        this.postRequestBody.odataPostToEmployee = '/bthr_employees(' + this.requestEmployeeDropdownValue + ')';
        this.postRequestBody.odataPostLegalEntity = '/bthr_legalentitieses(' + this.selectedEmployeeForRequest.legalEntityId + ')';
        this.postRequestBody.bt_verdetransaction = 'New Fleet Item Request - Existing Employee';
      } else {
        this.postRequestBody.bt_verdetransaction = 'New Fleet Item Request - New Employee';
        this.postRequestBody.odataPostLegalEntity = '/bthr_legalentitieses(' + this.proxyUser.legalEntityId + ')';
      }

      if (this.requestClassificationDropdownValue === 'Pool') {
        this.postRequestBody.odataPostToEmployee = '/bthr_employees(' + this.proxyUser.employeeId + ')';
        this.postRequestBody.odataPostLegalEntity = '/bthr_legalentitieses(' + this.proxyUser.legalEntityId + ')';
        this.postRequestBody.bt_verdetransaction = 'New Pool Request';
        this.postRequestBody.bt_classification = 365580001;
      } else {
        this.postRequestBody.bt_classification = 365580000;
      }

      this.postRequestBody.bt_approvalstatus = 692360001;
      this.postRequestBody.bt_movementstatus = 692360000;

      this.postRequestBody.bt_movementno = this.proxyUser.employeeId + '_Request_' + moment().format('YYYY-MM-DD, h:mm:ss a');

      this.sharedApiFleetMovementService
        .postFleetMovement({ body: this.postRequestBody })
        .pipe(take(1))
        .subscribe(
          (ret) => {
            console.log(ret);
          },
          (error) => {
            console.error(error);
          },
          () => {
            this.postRequestBody = {};
            this.confirmationMessage = 'The request has been submitted successfully!';

            this.spinner.hide('confirmation');
            this.showConfirmationModal = true;
          },
        );
    } else {
      //NEW MOVEMENT RENTAL REQUEST

      // checking if proxy
      if (this.proxyUser.employeeId === this.userService.user?.employeeId && this.proxyUser.employeeId === this.requestEmployeeDropdownValue) {
        this.postRentalBody.bt_proxy = false;
      } else {
        this.postRentalBody.bt_proxy = true;
        this.postRentalBody.odataPostProxy = '/bthr_employees(' + this.userService.user?.employeeId + ')';
      }

      this.postRentalBody.odataPostLegalEntity = '/bthr_legalentitieses(' + this.proxyUser.legalEntityId + ')';
      this.postRentalBody.bt_startdate = myForm.value.newRequireDate;
      this.postRentalBody.bt_enddate = myForm.value.rentalEndDate;

      if (this.newEmployeeNo) {
        this.postRentalBody.bt_rentalfor = 692360000;

        this.postRentalBody.odataPostEmployee = '/bthr_employees(' + this.requestEmployeeDropdownValue + ')';
      } else {
        this.postRentalBody.bt_rentalfor = 692360001;
      }

      this.postRentalBody.odataPostRentalReason = '/bt_fleetrentalreasons(' + this.rentalReasonDropdownValue + ')';
      this.postRentalBody.bt_description = myForm.value.newRequestReason;

      this.postRentalBody.bt_rentalstatus = 692360000;

      this.sharedApiFleetMovementService
        .postFleetRental({ body: this.postRentalBody })
        .pipe(take(1))
        .subscribe(
          (ret) => {
            console.log(ret);
          },
          (error) => {
            console.error(error);
          },
          () => {
            this.postRentalBody = {};
            this.confirmationMessage = 'The rental request has been submitted successfully!';

            this.spinner.hide('confirmation');
            this.showConfirmationModal = true;
          },
        );
    }
  }

  //Kendo
  closeConfirmationModal() {
    this.showConfirmationModal = false;
    this.sidebarService.setShowSidebar(false);
  }

  // Request Employee Movements
  private loadRequestEmployeeMovements(): void {
    this.requestEmployeeMovementsGridData = {
      data: orderBy(this.allVehiclesCombined, this.requestEmployeeMovementsSort),
      total: this.requestEmployeeMovementsGridView.length,
    };
  }

  requestEmployeeMovementsGroupChange(groups: GroupDescriptor[]): void {
    this.requestEmployeeMovementsGroups = groups;
    this.requestEmployeeMovementsGridData = process(this.allVehiclesCombined, { group: this.requestEmployeeMovementsGroups });
  }

  requestEmployeeMovementsDataStateChange(state: DataStateChangeEvent): void {
    this.requestEmployeeMovementsState = state;
    this.requestEmployeeMovementsGridData = process(this.allVehiclesCombined, this.requestEmployeeMovementsState);
  }

  requestEmployeeMovementsSortChange(sort: SortDescriptor[]): void {
    this.requestEmployeeMovementsSort = sort;
    this.loadRequestEmployeeMovements();
  }

  // Request Employee Items
  private loadRequestEmployeeItems(): void {
    this.requestEmployeeItemsGridData = {
      data: orderBy(this.allVehiclesCombined, this.requestEmployeeItemsSort),
      total: this.requestEmployeeItemsGridView.length,
    };
  }

  requestEmployeeItemsGroupChange(groups: GroupDescriptor[]): void {
    this.requestEmployeeItemsGroups = groups;
    this.requestEmployeeItemsGridData = process(this.allVehiclesCombined, { group: this.requestEmployeeItemsGroups });
  }

  requestEmployeeItemsDataStateChange(state: DataStateChangeEvent): void {
    this.requestEmployeeItemsState = state;
    this.requestEmployeeItemsGridData = process(this.allVehiclesCombined, this.requestEmployeeItemsState);
  }

  requestEmployeeItemsSortChange(sort: SortDescriptor[]): void {
    this.requestEmployeeItemsSort = sort;
    this.loadRequestEmployeeItems();
  }
}
