/* tslint:disable */
/* eslint-disable */
export interface UserSecurity {
  bt_approvalstep?: null | boolean;
  bt_callouttype?: null | string;
  bt_configfieldname?: null | string;
  bt_dropdowndisplay?: null | string;
  bt_dropdownname?: null | string;
  bt_learncallout?: null | boolean;
  bt_menucontext?: null | string;
  bt_menugroup?: null | string;
  bt_menuname?: null | string;
  bt_powerbi?: null | boolean;
  bt_reportid?: null | string;
  bt_reporttype?: null | string;
  bt_rolename4_bt_rolenameid?: null | string;
  bt_rolename4_bt_technicalname?: null | string;
  bt_technicalname?: null | string;
  bt_useinmenu?: null | boolean;
  bt_verdefunction?: null | string;
  bt_verdefunctionid?: null | string;
  bt_verdemodule?: null | string;
  bt_workspaceid?: null | string;
  bthr_employee7_bthr_employeeid?: null | string;
  bthr_legalentities6_bthr_legalentitiesid?: null | string;
}
