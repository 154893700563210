/* tslint:disable */
/* eslint-disable */
export interface ProcessesVerdeProduct {
  verde_databasecapacity?: null | number;
  verde_maximumemployees?: null | number;
  verde_minimumemployees?: null | number;
  verde_noofadminusers?: null | number;
  verde_noofsystemusers?: null | number;
  verde_product?: null | string;
  verde_productid?: string;
}
