import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FileRestrictions } from '@progress/kendo-angular-upload';
import { ProcessesPositionSeats } from '@verde/shared';
import { Subject } from 'rxjs';

@Component({
  selector: 'verde-onboarding-structure',
  templateUrl: './onboarding-structure.component.html',
  styleUrls: ['./onboarding-structure.component.scss'],
})
export class OnboardingStructureComponent implements OnInit {
  private onDestroy$ = new Subject<boolean>();

  @Input() public structure: FormGroup;
  @Input() positionSeat: ProcessesPositionSeats;

  public restrictions: FileRestrictions = {
    allowedExtensions: ['pdf'],
  };

  constructor() {}

  ngOnInit(): void {
    if (!this.structure) {
      console.error('Structure FormGroup is null.');
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  onFileSelect(event: any): void {
    if (event && event.files && event.files.length > 0) {
      const file = event.files[0];
      if (this.structure) {
        this.structure.get('structure.cvDoc').setValue(file);
      } else {
        console.error('Structure FormGroup is null.');
      }
    } else {
      console.error('Invalid file selection event.');
    }
  }
}
