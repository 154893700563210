import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
//components
import { EmployeeDocumentsComponent } from './components/employee-documents/employee-documents.component';

const routes: Routes = [
  //{
  //  path: '',
  //  children: [{
  //    path: '',
  //    component: EmployeeDocumentsComponent
  //  }]
  //}
  {
    path: '',
    component: EmployeeDocumentsComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ManualDocumentsRoutingModule {}
