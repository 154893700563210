/* tslint:disable */
/* eslint-disable */
import { DelegationsEntityReferenceExtrapolate } from './delegations-entity-reference-extrapolate';
import { DelegationsBtDriversLicenseStatus } from './delegations-bt-drivers-license-status';
import { DelegationsBtEmployeeLifecycleStage } from './delegations-bt-employee-lifecycle-stage';
import { DelegationsBtEmployeeStatusEnum } from './delegations-bt-employee-status-enum';
import { DelegationsBthrJobSkill } from './delegations-bthr-job-skill';
import { DelegationsBthrRecordStatus } from './delegations-bthr-record-status';
import { DelegationsBthrTerminationreason } from './delegations-bthr-terminationreason';
export interface DelegationsVerdeEmployee {
  bt_azureobjectid?: null | string;
  bt_citizenshipstatus?: DelegationsEntityReferenceExtrapolate;
  bt_costcentre?: DelegationsEntityReferenceExtrapolate;
  bt_countryofresidence?: DelegationsEntityReferenceExtrapolate;
  bt_defaultequipmentstore?: DelegationsEntityReferenceExtrapolate;
  bt_defaultequipmentworkshop?: DelegationsEntityReferenceExtrapolate;
  bt_defaultfleetlocation?: DelegationsEntityReferenceExtrapolate;
  bt_defaultworklocation?: DelegationsEntityReferenceExtrapolate;
  bt_district?: DelegationsEntityReferenceExtrapolate;
  bt_driverslicense?: null | boolean;
  bt_driverslicenseexpirydate?: null | string;
  bt_driverslicensestatus?: DelegationsBtDriversLicenseStatus;
  bt_employeeclassification?: DelegationsEntityReferenceExtrapolate;
  bt_employeelifecyclestage?: DelegationsBtEmployeeLifecycleStage;
  bt_employeestatus?: DelegationsBtEmployeeStatusEnum;
  bt_enableforemployee?: null | boolean;
  bt_ethnicitylookup?: DelegationsEntityReferenceExtrapolate;
  bt_fullname?: null | string;
  bt_genderlookup?: DelegationsEntityReferenceExtrapolate;
  bt_geographicalregion?: DelegationsEntityReferenceExtrapolate;
  bt_grouplocation?: DelegationsEntityReferenceExtrapolate;
  bt_hassubordinates?: null | boolean;
  bt_ismanager?: null | boolean;
  bt_legalentityjoindate?: null | string;
  bt_nationality?: DelegationsEntityReferenceExtrapolate;
  bt_neworganizationjoindate?: null | string;
  bt_noemail?: null | boolean;
  bt_occupationallevellookup?: DelegationsEntityReferenceExtrapolate;
  bt_office?: DelegationsEntityReferenceExtrapolate;
  bt_overridemanagerpayslipaccess?: null | boolean;
  bt_partnershipstatuslookup?: DelegationsEntityReferenceExtrapolate;
  bt_paymentmethod?: DelegationsEntityReferenceExtrapolate;
  bt_positionverde?: DelegationsEntityReferenceExtrapolate;
  bt_probationenddate?: null | string;
  bt_probationmonths?: null | number;
  bt_probationtype?: DelegationsEntityReferenceExtrapolate;
  bt_provincestate?: DelegationsEntityReferenceExtrapolate;
  bt_racelookup?: DelegationsEntityReferenceExtrapolate;
  bt_shifttype?: DelegationsEntityReferenceExtrapolate;
  bt_skillsclassificationlookup?: DelegationsEntityReferenceExtrapolate;
  bt_subordinates?: null | number;
  bt_tenantid?: null | string;
  bt_timeoffcycle1startdate?: null | string;
  bt_timeoffissues?: null | string;
  bt_title?: DelegationsEntityReferenceExtrapolate;
  bt_verdesuboffice?: DelegationsEntityReferenceExtrapolate;
  bthr_aduser?: null | boolean;
  bthr_crimicalrecord?: null | boolean;
  bthr_criminalrecordverified?: null | string;
  bthr_dateofbirth?: null | string;
  bthr_department?: DelegationsEntityReferenceExtrapolate;
  bthr_disabilities?: null | boolean;
  bthr_division?: DelegationsEntityReferenceExtrapolate;
  bthr_email?: null | string;
  bthr_employee_id?: null | string;
  bthr_employeeid?: null | string;
  bthr_employeephoto?: null | string;
  bthr_employeetype?: DelegationsEntityReferenceExtrapolate;
  bthr_firstname?: null | string;
  bthr_foreignnational?: null | boolean;
  bthr_grade?: DelegationsEntityReferenceExtrapolate;
  bthr_hometelephone?: null | string;
  bthr_initials?: null | string;
  bthr_jobskill?: DelegationsBthrJobSkill;
  bthr_lastname?: null | string;
  bthr_lastworkday?: null | string;
  bthr_legalentity?: DelegationsEntityReferenceExtrapolate;
  bthr_middlenames?: null | string;
  bthr_mobiletelephone?: null | string;
  bthr_nationalidnumber?: null | string;
  bthr_paypoint?: DelegationsEntityReferenceExtrapolate;
  bthr_payrollnumber?: null | string;
  bthr_personalemail?: null | string;
  bthr_placeofbirthtext?: null | string;
  bthr_preferredname?: null | string;
  bthr_primarylocation?: DelegationsEntityReferenceExtrapolate;
  bthr_recordstatus?: DelegationsBthrRecordStatus;
  bthr_religioncode?: DelegationsEntityReferenceExtrapolate;
  bthr_resignationdate?: null | string;
  bthr_seat?: DelegationsEntityReferenceExtrapolate;
  bthr_taxnumber?: null | string;
  bthr_terminationnoticedate?: null | string;
  bthr_terminationreason?: DelegationsBthrTerminationreason;
  bthr_timeoffprovisiondate?: null | string;
  bthr_triggertimeoffprovisioning?: null | boolean;
  bthr_user?: DelegationsEntityReferenceExtrapolate;
}
