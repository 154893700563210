/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DvAllLanguages } from '../models/dv-all-languages';

@Injectable({
  providedIn: 'root',
})
export class SharedApiAllLanguagesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAllLanguages
   */
  static readonly GetAllLanguagesPath = '/api/AllLanguages/all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllLanguages()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllLanguages$Response(params?: {
    subscriptionKey?: string;
    APIURL?: string;
  }): Observable<StrictHttpResponse<Array<DvAllLanguages>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiAllLanguagesService.GetAllLanguagesPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DvAllLanguages>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllLanguages$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllLanguages(params?: {
    subscriptionKey?: string;
    APIURL?: string;
  }): Observable<Array<DvAllLanguages>> {

    return this.getAllLanguages$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DvAllLanguages>>) => r.body as Array<DvAllLanguages>)
    );
  }

}
