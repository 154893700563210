/* tslint:disable */
/* eslint-disable */
export interface FundOptionChanges {
  '_bt_employee_value'?: null | string;
  '_bt_employeefund_value'?: null | string;
  '_bt_existingoption_value'?: null | string;
  '_bt_fund_value'?: null | string;
  '_bt_legalentity_value'?: null | string;
  '_bt_newoption_value'?: null | string;
  bt_attachment?: null | string;
  bt_effectivedate?: string;
  bt_esignaturerequired?: boolean;
  bt_existing?: number;
  bt_fundoptionchangeno?: null | string;
  bt_fundoptionchangesid?: null | string;
  bt_new?: number;
  bt_sendnotification?: boolean;
  bt_signed?: boolean;
  odataEmployee?: null | string;
  odataEmployeeFund?: null | string;
  odataExistingOption?: null | string;
  odataLegalEntity?: null | string;
  odataNewOption?: null | string;
  odataPostEmployee?: null | string;
  odataPostEmployeeFund?: null | string;
  odataPostExistingOption?: null | string;
  odataPostFund?: null | string;
  odataPostLegalEntity?: null | string;
  odataPostNewOption?: null | string;
  odatafund?: null | string;
}
