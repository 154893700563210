<form #successorDetailsForm="ngForm"
      novalidate
      autocomplete="off"
      class="k-form">
  <ngx-spinner [disableAnimation]="true"
               name="successorchange"
               bdColor="rgb(255, 255, 255)"
               size="default"
               color="#6264a7"
               type="ball-clip-rotate"
               [fullScreen]="false">
  </ngx-spinner>

  <div *ngIf="showFirstPage">
    <div class="modal_grid">
      <div class="form-group">
        <label for="newSuccessorType">Successor Type:</label>
        <kendo-dropdownlist [data]="allSuccessorTypeMapped"
                            textField="text"
                            valueField="value"
                            id="newSuccessorType"
                            name="newSuccessorType"
                            [ngModel]="defaultSuccessorType"
                            (ngModelChange)="onSuccessorTypeChange($event)"
                            #varNewSuccessorType="ngModel"
                            required>
        </kendo-dropdownlist>

        <div class="alert alert-danger"
             *ngIf="varNewSuccessorType.touched && !varNewSuccessorType.valid">
          <div *ngIf="varNewSuccessorType?.errors?.required">Field is required!</div>
        </div>
      </div>

      <div class="form-group">
        <label for="newReviewDate">Last Review Date</label>
        <kendo-textbox id="newReviewDate"
                       name="newReviewDate"
                       #varNewReviewDate="ngModel"
                       [ngModel]="todaysDate"
                       [readonly]="true">
        </kendo-textbox>
      </div>

      <div *ngIf="successorDetails.bt_successortype === SuccessorType.Internal"
           class="form-group">
        <label for="newLegalEntityDropdown">Legal Entity:</label>
        <kendo-dropdownlist [data]="allLegalEntities"
                            textField="bthr_legalentityname"
                            valueField="bthr_legalentitiesid"
                            id="newLegalEntityDropdown"
                            name="newLegalEntityDropdown"
                            required
                            [ngModel]="defaultLegalEntity"
                            (ngModelChange)="onLegalEntityChange($event)"
                            #varNewLegalEntityDropdown="ngModel"
                            [disabled]="!config?.legalEntityConfig?.successionAllowGroupEntities">
        </kendo-dropdownlist>

        <div class="alert alert-danger"
             *ngIf="varNewLegalEntityDropdown.touched && !varNewLegalEntityDropdown.valid">
          <div *ngIf="varNewLegalEntityDropdown?.errors?.required">Field is required!</div>
        </div>
      </div>

      <div *ngIf="successorDetails.bt_successortype === SuccessorType.Internal"
           class="form-group">
        <label for="newSuccessor">Successor:</label>
        <kendo-dropdownlist [data]="allEmployeesFilteredData"
                            textField="fullNameWithEmpId"
                            valueField="employeeId"
                            [filterable]="true"
                            (filterChange)="handleSuccessorFilter($event)"
                            id="newSuccessor"
                            name="newSuccessor"
                            #varNewSuccessor="ngModel"
                            required
                            [ngModel]
                            (ngModelChange)="onSuccessorChange($event)">
          <ng-template kendoDropDownListNoDataTemplate>
            <p>{{ employeeDropdownMessage }}</p>
          </ng-template>
        </kendo-dropdownlist>

        <div class="alert alert-danger"
             *ngIf="varNewSuccessor.touched && !varNewSuccessor.valid">
          <div *ngIf="varNewSuccessor.errors?.required">Field is required!</div>
        </div>
      </div>

      <div class="form-group">
        <label for="newPriority">Priority:</label>
        <kendo-dropdownlist [data]="allPriorities"
                            textField="text"
                            valueField="value"
                            id="newPriority"
                            name="newPriority"
                            required
                            [ngModel]
                            #varNewPriority="ngModel">
        </kendo-dropdownlist>

        <div class="alert alert-danger"
             *ngIf="varNewPriority.touched && !varNewPriority.valid">
          <div *ngIf="varNewPriority.errors.required">Field is required!</div>
          <div *ngIf="varNewPriority.errors.pattern">Field is required!</div>
        </div>
      </div>
    </div>
    <div class="form-group wide">
      <label for="newSuccessorComment">Comment:</label>
      <kendo-textarea maxlength="500"
                      id="newSuccessorComment"
                      name="newSuccessorComment"
                      required
                      #varNewSuccessorComment="ngModel"
                      resizable="vertical"
                      [ngModel]>
      </kendo-textarea>

      <div class="alert alert-danger"
           *ngIf="varNewSuccessorComment.touched && !varNewSuccessorComment.valid">
        <div *ngIf="varNewSuccessorComment.errors.required">Field is required!</div>
      </div>
    </div>
    <button *ngIf="showFirstPage && !readOnlyForm"
            kendoButton
            themeColor="primary"
            style="position: absolute; bottom: 20px; right: 20px"
            (click)="createNew(successorDetailsForm)"
            [disabled]="!successorDetailsForm.valid">
      Create
    </button>
  </div>
  <!-- SECOND PAGE -->
  <div *ngIf="showSecondPage">
    <div class="modal_grid">
      <div class="form-group">
        <label for="newRetentionPotential">Retention Potential:</label>
        <kendo-dropdownlist [data]="allRetentionPotentialMapped"
                            textField="text"
                            valueField="value"
                            id="newRetentionPotential"
                            name="newRetentionPotential"
                            required
                            [ngModel]="selectedRetentionPotential"
                            [disabled]="readOnlyForm"
                            #varNewRetentionPotential="ngModel">
        </kendo-dropdownlist>

        <div class="alert alert-danger"
             *ngIf="varNewRetentionPotential.touched && !varNewRetentionPotential.valid">
          <div *ngIf="varNewRetentionPotential?.errors?.required">Field is required!</div>
        </div>
      </div>

      <div class="form-group ">
        <label for="newRetirementStatus">Retirement Status:</label>
        <kendo-dropdownlist [data]="allRetirementStatusMapped"
                            textField="text"
                            valueField="value"
                            id="newRetirementStatus"
                            name="newRetirementStatus"
                            required
                            [ngModel]="selectedRetirementStatus"
                            [disabled]="readOnlyForm"
                            #varNewRetirementStatus="ngModel">
        </kendo-dropdownlist>

        <div class="alert alert-danger"
             *ngIf="varNewRetirementStatus.touched && !varNewRetirementStatus.valid">
          <div *ngIf="varNewRetirementStatus?.errors?.required">Field is required!</div>
        </div>
      </div>

      <div class="form-group ">
        <label for="newTransferPotential">Mobility/Transfer Potential:</label>
        <kendo-dropdownlist [data]="allTransferPotentialMapped"
                            textField="text"
                            valueField="value"
                            id="newTransferPotential"
                            name="newTransferPotential"
                            required
                            [ngModel]="selectedTransferPotential"
                            [disabled]="readOnlyForm"
                            #varNewTransferPotential="ngModel">
        </kendo-dropdownlist>

        <div class="alert alert-danger"
             *ngIf="varNewTransferPotential.touched && !varNewTransferPotential.valid">
          <div *ngIf="varNewTransferPotential?.errors?.required">Field is required!</div>
        </div>
      </div>

      <div class="form-group ">
        <label for="newReadyNow">Ready Now:</label>
        <kendo-dropdownlist [data]="allYesNoOptions"
                            textField="text"
                            valueField="value"
                            id="newReadyNow"
                            name="newReadyNow"
                            required
                            [ngModel]="getSelectedYesNoOptions('bt_readynow')"
                            (ngModelChange)="onReadyNowChange($event)"
                            [disabled]="readOnlyForm"
                            #varNewReadyNow="ngModel">
        </kendo-dropdownlist>

        <div class="alert alert-danger"
             *ngIf="varNewReadyNow.touched && !varNewReadyNow.valid">
          <div *ngIf="varNewReadyNow?.errors?.required">Field is required!</div>
        </div>
      </div>

      <div class="form-group ">
        <label for="newReadyIn">Ready In:</label>
        <kendo-dropdownlist [data]="allReadyInMapped"
                            textField="text"
                            valueField="value"
                            id="newReadyIn"
                            name="newReadyIn"
                            required
                            [ngModel]="selectedReadyIn"
                            [disabled]="readOnlyForm || successorDetails.bt_readynow"
                            #varNewReadyIn="ngModel">
        </kendo-dropdownlist>

        <div class="alert alert-danger"
             *ngIf="varNewReadyIn.touched && !varNewReadyIn.valid">
          <div *ngIf="varNewReadyIn?.errors?.required">Field is required!</div>
        </div>
      </div>

      <div class="form-group ">
        <label for="newGapStatus">Gap Analysis Status</label>
        <kendo-dropdownlist [data]="allGapAnalysisStatusMapped"
                            textField="text"
                            valueField="value"
                            id="newGapStatus"
                            name="newGapStatus"
                            required
                            [ngModel]="selectedGapAnalysisStatus"
                            [disabled]="readOnlyForm || successorDetails.bt_readynow"
                            #varNewGapStatus="ngModel">
        </kendo-dropdownlist>

        <div class="alert alert-danger"
             *ngIf="varNewGapStatus.touched && !varNewGapStatus.valid">
          <div *ngIf="varNewGapStatus?.errors?.required">Field is required!</div>
        </div>
      </div>

      <div class="form-group ">
        <label for="newInternalRequired">Internal Training Required:</label>
        <kendo-dropdownlist [data]="allYesNoOptions"
                            textField="text"
                            valueField="value"
                            id="newInternalRequired"
                            name="newInternalRequired"
                            required
                            [ngModel]="getSelectedYesNoOptions('bt_internaltrainingrequired')"
                            (ngModelChange)="onInternalRequiredChange($event)"
                            [disabled]="readOnlyForm || successorDetails.bt_readynow"
                            #varNewInternalRequired="ngModel">
        </kendo-dropdownlist>

        <div class="alert alert-danger"
             *ngIf="varNewInternalRequired.touched && !varNewInternalRequired.valid">
          <div *ngIf="varNewInternalRequired?.errors?.required">Field is required!</div>
        </div>
      </div>

      <div class="form-group ">
        <label for="newExternalRequired">External Training Required:</label>
        <kendo-dropdownlist [data]="allYesNoOptions"
                            textField="text"
                            valueField="value"
                            id="newExternalRequired"
                            name="newExternalRequired"
                            required
                            [ngModel]="getSelectedYesNoOptions('bt_externaltrainingrequired')"
                            (ngModelChange)="onExternalRequiredChange($event)"
                            [disabled]="readOnlyForm || successorDetails.bt_readynow"
                            #varNewExternalRequired="ngModel">
        </kendo-dropdownlist>

        <div class="alert alert-danger"
             *ngIf="varNewExternalRequired.touched && !varNewExternalRequired.valid">
          <div *ngIf="varNewExternalRequired?.errors?.required">Field is required!</div>
        </div>
      </div>

      <div class="form-group ">
        <label for="newTrainingPlan">Training Plan Developed</label>
        <kendo-dropdownlist [data]="allTrainingPlanDevelopedMapped"
                            textField="text"
                            valueField="value"
                            id="newTrainingPlan"
                            name="newTrainingPlan"
                            required
                            [ngModel]="selectedTrainingPlanDeveloped"
                            (ngModelChange)="onTrainingPlanChange($event)"
                            [disabled]="readOnlyForm || successorDetails.bt_readynow"
                            #varNewTrainingPlan="ngModel">
        </kendo-dropdownlist>

        <div class="alert alert-danger"
             *ngIf="varNewTrainingPlan.touched && !varNewTrainingPlan.valid">
          <div *ngIf="varNewTrainingPlan?.errors?.required">Field is required!</div>
        </div>
      </div>
    </div>
    <div>
      <div *ngIf="successorDetails.bt_internaltrainingrequired === true"
           class="form-group">
        <label for="newInternalComment">Internal Training Comment:</label>
        <kendo-textarea maxlength="500"
                        id="newInternalComment"
                        name="newInternalComment"
                        required
                        #varNewInternalComment="ngModel"
                        resizable="vertical"
                        [ngModel]="successorDetails?.bt_internaltrainingcomments"
                        [disabled]="readOnlyForm">
        </kendo-textarea>

        <div class="alert alert-danger"
             *ngIf="varNewInternalComment.touched && !varNewInternalComment.valid">
          <div *ngIf="varNewInternalComment.errors.required">Field is required!</div>
          <div *ngIf="varNewInternalComment.errors.minlength">Enter a detailed comment</div>
        </div>
      </div>

      <div *ngIf="successorDetails.bt_externaltrainingrequired === true"
           class="form-group">
        <label for="newExternalComment">External Training Comment:</label>
        <kendo-textarea maxlength="500"
                        id="newExternalComment"
                        name="newExternalComment"
                        required
                        #varNewExternalComment="ngModel"
                        resizable="vertical"
                        [ngModel]="successorDetails?.bt_externaltrainingcomments"
                        [disabled]="readOnlyForm">
        </kendo-textarea>

        <div class="alert alert-danger"
             *ngIf="varNewExternalComment.touched && !varNewExternalComment.valid">
          <div *ngIf="varNewExternalComment.errors.required">Field is required!</div>
          <div *ngIf="varNewExternalComment.errors.minlength">Enter a detailed comment</div>
        </div>
      </div>

      <div *ngIf="successorDetails.bt_trainingplandeveloped === TrainingPlanDeveloped.Completed && !readOnlyForm"
           class="form-group ">
        <label for="trainingPlanFile">Upload Training Plan:</label>
        <kendo-fileselect id="trainingPlanFile"
                          name="trainingPlanFile"
                          [(ngModel)]="trainingPlanFile"
                          #varTrainingPlanFile="ngModel"
                          [multiple]="false">
        </kendo-fileselect>
      </div>

      <div class="form-group"
           style="position: relative;width: 50%;">
        <ngx-spinner [disableAnimation]="true"
                     name="successortrainingplanfile"
                     bdColor="rgb(255, 255, 255)"
                     size="default"
                     color="#6264a7"
                     type="ball-clip-rotate"
                     [fullScreen]="false">

        </ngx-spinner>
        <div *ngIf="successorDetails.bt_trainingplanattachment_name">
          <label for="downloadTrainingPlan">Current Training Plan:</label>
          <p class="link_class"
             id="downloadTrainingPlan"
             title="Click to download attachment"
             (click)="downloadFile()">
            {{ successorDetails.bt_trainingplanattachment_name }}
          </p>
        </div>
      </div>


    </div>
    <button *ngIf="showSecondPage && !readOnlyForm"
            kendoButton
            style="position: absolute; bottom: 20px; right: 20px"
            themeColor="primary"
            (click)="submitUpdate(successorDetailsForm)"
            [disabled]="!successorDetailsForm.valid">
      Submit
    </button>
  </div>
</form>


<!-- Confirmation Modal -->
<kendo-dialog *ngIf="showConfirmationModal"
              title="Confirmation"
              [width]="500">
  <div style="height: 60px">
    <p>
      {{ resultMessage }}
    </p>
  </div>
  <div class="confirmation-modal-footer">
    <button kendoButton
            themeColor="primary"
            style="margin-left: auto"
            (click)="closeConfirmationModal()">
      Continue
    </button>
  </div>
</kendo-dialog>