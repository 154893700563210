import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControlDirective, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { FleetAccessService } from '../../../../../../../../apps/verde/src/app/features/fleet/services/fleet-access/fleet-access.service';
import { UserService } from '@verde/core';
import { FleetMake, FleetModel, FleetType, SharedApiVehiclesService } from '@verde/shared';
import { ModalService } from '../../modal.service';

@Component({
  selector: 'verde-fleet-models-modal',
  templateUrl: './fleet-models-modal.component.html',
  styleUrls: ['./fleet-models-modal.component.scss'],
})
export class FleetModelsModalComponent implements OnInit, AfterViewInit, OnDestroy {
  private onDestroy$ = new Subject<boolean>();

  @Input() modelID: string = '';
  @Input() modelFormAction: string = '';
  @Output() hideModalEvent = new EventEmitter<boolean>();

  disableAnimation: boolean = false;

  //Bodies
  sendFleetModelBody: FleetModel = {};

  //arrays
  allTransmissionTypes = [
    { text: 'Auto', value: 365580000 },
    { text: 'Manual', value: 365580001 },
  ];
  allFuelTypes = [
    { text: 'Petrol', value: 365580000 },
    { text: 'Diesel', value: 365580001 },
    { text: 'Hybrid', value: 365580002 },
    { text: 'Electric', value: 365580003 },
    { text: 'Not Applicable', value: 692360001 },
  ];

  allFleetMakesSource: Array<FleetMake> = new Array<FleetMake>();
  allFleetMakesData: Array<FleetMake> = new Array<FleetMake>();
  allFleetTypesSource: Array<FleetType> = new Array<FleetType>();
  allFleetTypesData: Array<FleetType> = new Array<FleetType>();

  selectedModel: FleetModel = {};

  checkDefaultPattern: RegExp = /^(?!default$).*/;

  confirmationMessage: string = '';

  modelFormDefaultState = {};

  constructor(
    private spinner: NgxSpinnerService,
    private modalService: ModalService,
    public userService: UserService,
    private fleetAccess: FleetAccessService,
    private sharedApiVehiclesService: SharedApiVehiclesService,
  ) {}

  ngOnInit(): void {
    this.userService.disableAnimation$.pipe(takeUntil(this.onDestroy$)).subscribe((data) => {
      this.disableAnimation = data;
    });

    this.getFleetMakes();
    this.getFleetTypes();
  }

  ngAfterViewInit(): void {
    document.getElementById('showModelsModal')?.click();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  hideModal() {
    this.hideModalEvent.emit(false);
  }

  openModelsModal(myForm: FormGroup) {
    this.modalService.open('fleetModelsModal');

    if (this.modelFormAction === 'Add') {
      this.modelFormDefaultState = {
        addModelName: null,
        addModelYear: null,
        addModelMake: {},
        addModelType: {},
        addModelTransmission: {},
        addModelTare: null,
        addModelFuelType: {},
        addModelTankCapacity: null,
      };

      myForm.reset(this.modelFormDefaultState);
    } else {
      this.spinner.show('fleetmodelsmodal');

      this.sharedApiVehiclesService
        .getFleetModelById({ modelID: this.modelID })
        .pipe(take(1))
        .subscribe(
          (ret) => {
            this.selectedModel = ret;
          },
          (error) => {
            console.error(error);
          },
          () => {
            let make: FleetMake = {};
            this.allFleetMakesSource.forEach((t) => {
              if (t.bthr_vehiclemakeid === this.selectedModel._bthr_make_value) {
                make = t;
              }
            });

            let type: FleetType = {};
            this.allFleetTypesSource.forEach((t) => {
              if (t.bt_fleettypeid === this.selectedModel._bt_type_value) {
                type = t;
              }
            });

            let transmission = { text: this.selectedModel.odataTransmissionType, value: this.selectedModel.bt_transmissiontype };
            let fuelType = { text: this.selectedModel.odataFuelType, value: this.selectedModel.bt_fueltype };

            this.modelFormDefaultState = {
              addModelName: this.selectedModel.bt_modelname,
              addModelYear: this.selectedModel.bt_modelyear,
              addModelMake: make,
              addModelType: type,
              addModelTransmission: transmission,
              addModelTare: this.selectedModel.bt_tare,
              addModelFuelType: fuelType,
              addModelTankCapacity: this.selectedModel.bt_tankcapacityl,
            };

            myForm.reset(this.modelFormDefaultState);
            //this.modalService.open('fleetModelsModal');
            this.spinner.hide('fleetmodelsmodal');
          },
        );
    }
  }

  getFleetMakes() {
    this.sharedApiVehiclesService
      .getFleetMakes({ legalEntityID: this.fleetAccess.selectedFleetAccess._bt_locationlegalentity_value! })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          this.allFleetMakesSource = ret;
          this.allFleetMakesData = ret;
        },
        (error) => {
          console.error(error);
        },
      );
  }

  getFleetTypes() {
    this.sharedApiVehiclesService
      .getFleetTypes({ legalEntityID: this.fleetAccess.selectedFleetAccess._bt_locationlegalentity_value! })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          this.allFleetTypesSource = ret;
          this.allFleetTypesData = ret;
        },
        (error) => {
          console.error(error);
        },
      );
  }

  //#region POST

  postFleetModel(myForm: FormGroup) {
    this.spinner.show('confirmfleetmodels');
    this.modalService.open('confirmFleetModelModal');

    if (this.modelFormAction === 'Add') {
      //POST

      this.sendFleetModelBody.odataPostLegalEntity = '/bthr_legalentitieses(' + this.fleetAccess.selectedFleetAccess._bt_locationlegalentity_value + ')';
      this.sendFleetModelBody.odataPostMake = '/bthr_vehiclemakes(' + myForm.value.addModelMake.bthr_vehiclemakeid + ')';
      this.sendFleetModelBody.odataPostFleetType = '/bt_fleettypes(' + myForm.value.addModelType.bt_fleettypeid + ')';
      this.sendFleetModelBody.bt_modelname = myForm.value.addModelName;
      this.sendFleetModelBody.bt_modelyear = myForm.value.addModelYear;
      this.sendFleetModelBody.bt_transmissiontype = Number(myForm.value.addModelTransmission.value);
      this.sendFleetModelBody.bt_tare = myForm.value.addModelTare;
      this.sendFleetModelBody.bt_fueltype = Number(myForm.value.addModelFuelType.value);
      this.sendFleetModelBody.bt_tankcapacityl = myForm.value.addModelTankCapacity;
      this.sendFleetModelBody.bthr_recordstatus = 365580002;

      this.sharedApiVehiclesService
        .postFleetModel({ body: this.sendFleetModelBody })
        .pipe(take(1))
        .subscribe(
          (ret) => {
            console.log(ret);
          },
          (error) => {
            console.error(error);
          },
          () => {
            this.sendFleetModelBody = {};

            this.confirmationMessage = 'You have sucessfully added a fleet model!';
            this.spinner.hide('confirmfleetmodels');
          },
        );
    } else {
      //PATCH

      if (JSON.stringify(myForm.value) !== JSON.stringify(this.modelFormDefaultState)) {
        //Form is different from previous state

        this.sendFleetModelBody.odataPostMake = '/bthr_vehiclemakes(' + myForm.value.addModelMake.bthr_vehiclemakeid + ')';
        this.sendFleetModelBody.odataPostFleetType = '/bt_fleettypes(' + myForm.value.addModelType.bt_fleettypeid + ')';
        this.sendFleetModelBody.bt_modelname = myForm.value.addModelName;
        this.sendFleetModelBody.bt_modelyear = myForm.value.addModelYear;
        this.sendFleetModelBody.bt_transmissiontype = Number(myForm.value.addModelTransmission.value);
        this.sendFleetModelBody.bt_tare = myForm.value.addModelTare;
        this.sendFleetModelBody.bt_fueltype = Number(myForm.value.addModelFuelType.value);
        this.sendFleetModelBody.bt_tankcapacityl = myForm.value.addModelTankCapacity;
        this.sendFleetModelBody.bthr_recordstatus = 365580002;

        this.sendFleetModelBody.bthr_vehiclemodelid = this.selectedModel.bthr_vehiclemodelid;

        this.sharedApiVehiclesService
          .patchFleetModel({ body: this.sendFleetModelBody })
          .pipe(take(1))
          .subscribe(
            (ret) => {
              console.log(ret);
            },
            (error) => {
              console.error(error);
            },
            () => {
              this.sendFleetModelBody = {};

              this.confirmationMessage = 'You have sucessfully edited a fleet model!';
              this.spinner.hide('confirmfleetmodels');
            },
          );
      } else {
        //Form is same as previous state

        this.confirmationMessage = 'You have chosen not to make any changes to this model';
        this.spinner.hide('confirmfleetmodels');
      }
    }
  }

  //#endregion POST

  //Dropdown filters
  handleMakesFilter(value: string) {
    this.allFleetMakesData = this.allFleetMakesSource.filter((s) => s.bthr_make?.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  handleTypesFilter(value: string) {
    this.allFleetTypesData = this.allFleetTypesSource.filter((s) => s.bt_fleettype?.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  //Validation
  validateModelTare(element: FormControlDirective) {
    if (element.value < 0) {
      element.reset();
    } else if (element.value % 1 != 0) {
      const num = parseFloat(element.value).toFixed(2);
      element.reset(parseFloat(num));
    }
  }

  validateModelTankCapacity(element: FormControlDirective) {
    if (element.value < 0) {
      element.reset();
    } else if (element.value % 1 != 0) {
      const num = parseFloat(element.value).toFixed(2);
      element.reset(parseFloat(num));
    }
  }
}
