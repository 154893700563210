import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Output } from '@angular/core';
import { UserService } from '@verde/core';

@Component({
  selector: 'verde-id-validation',
  templateUrl: './id-validation.component.html',
  styleUrls: ['./id-validation.component.scss'],
})
export class IdValidationComponent implements AfterViewInit {
  @Output() modalClosed: EventEmitter<boolean> = new EventEmitter();
  @Output() modalSuccess: EventEmitter<boolean> = new EventEmitter();

  opened = true;

  arrayTest: any;
  arrayOfID: string[] = [];
  map = new Map<string, string>();

  openedIncorrect = false;

  constructor(private userService: UserService, private cdr: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.IdCheckMethod();
    //this.opened = true;

    let number = this.arrayOfID.findIndex((x) => x === '*');

    let string = 'TestID' + number;
    setTimeout(() => {
      const focusEle = document.getElementById(string);
      focusEle?.focus();
      focusEle?.click();
    }, 50);
  }

  closeModal() {
    this.modalClosed.emit(false);
  }

  closeIncorrect(): void {
    this.openedIncorrect = false;
    this.modalClosed.emit(false);
  }

  openIncorrect(): void {
    this.openedIncorrect = true;
  }

  IdCheckMethod() {
    let stringOfID = '';

    for (var i = 0; i < this.userService.user.idNumber!.length; i++) {
      stringOfID = stringOfID + this.userService.user.idNumber!.substring(i, i + 1) + ',';
    }
    stringOfID = stringOfID.substring(0, stringOfID.length - 1);
    this.arrayOfID = stringOfID.split(',');

    this.cdr.detectChanges();

    let numberOfElements = this.arrayOfID.length;

    let numberToHide = Math.floor(numberOfElements * 0.4);

    let RandomNumbers: number[] = [];

    while (RandomNumbers.length < numberToHide) {
      let randomlyGenNumber = this.randomInteger(1, numberOfElements);
      RandomNumbers.push(randomlyGenNumber);
      RandomNumbers = RandomNumbers.filter((item: any, index: any, self: string | any[]) => self.indexOf(item) === index);
    }

    RandomNumbers.forEach((t) => {
      this.arrayOfID[t - 1] = '*';
    });
  }

  randomInteger(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  CheckIDs() {
    this.map.forEach((t, a) => {
      this.arrayOfID[Number(a)] = String(t);
    });

    let IDToCheck: string = this.arrayOfID.toString();
    IDToCheck = IDToCheck.replaceAll(',', '');

    //if (this.userService.user.bthr_nationalidnumber! === IDToCheck && this.validateIDNumber(IDToCheck)) {
    if (this.userService.user.idNumber! === IDToCheck) {
      this.opened = false;
      this.modalSuccess.emit(true);
    } else {
      this.openIncorrect();
      this.opened = false;
    }
  }

  selectText(idName: string, idNumber: any, e: any) {
    this.map.set(idNumber, e);
    let index = -1;
    for (var i = idNumber + 1; i < this.arrayOfID.length; i++) {
      if (this.arrayOfID[i] == '*') {
        index = i;
        break;
      }
    }

    if (index != -1) {
      let ID = idName + index;
      const a = document.getElementById(ID);
    }
  }

  // code that doesnt work
  validateIDNumber(idNumber: string): boolean {
    if (idNumber.length !== 13 || !/^\d+$/.test(idNumber)) return false;

    const digits: number[] = Array.from(idNumber, (c) => parseInt(c, 10));

    const year: number = digits[0] * 10 + digits[1];
    const month: number = digits[2] * 10 + digits[3];
    const day: number = digits[4] * 10 + digits[5];

    if (month < 1 || month > 12) return false;

    if (day < 1 || day > 31) return false;

    if ([4, 6, 9, 11].includes(month) && day > 30) return false;

    if (month === 2 && (day > 29 || (day === 29 && year % 4 !== 0))) return false;

    const checksum: number = digits
      .slice(0, 12)
      .map((digit, index) => (2 - (index % 2)) * digit)
      .reduce((acc, val) => acc + val, 0);
    const calculatedChecksum: number = (10 - (checksum % 10)) % 10;

    return calculatedChecksum === digits[12];
  }
}
