/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { StringArrayParamaters } from '../models/string-array-paramaters';
import { TimeEntryApiModel } from '../models/time-entry-api-model';
import { WorkspaceShortcut } from '../models/workspace-shortcut';
import { DvApprovalConfig } from '../models/dv-approval-config';

@Injectable({
  providedIn: 'root',
})
export class SharedApiConfigService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getApprovalConfig
   */
  static readonly GetApprovalConfigPath = '/api/Config/approval/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApprovalConfig()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApprovalConfig$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
  }): Observable<StrictHttpResponse<DvApprovalConfig>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiConfigService.GetApprovalConfigPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityID', params.legalEntityID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DvApprovalConfig>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getApprovalConfig$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApprovalConfig(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
  }): Observable<DvApprovalConfig> {

    return this.getApprovalConfig$Response(params).pipe(
      map((r: StrictHttpResponse<DvApprovalConfig>) => r.body as DvApprovalConfig)
    );
  }

  /**
   * Path part for operation getWorkspaceShortcuts
   */
  static readonly GetWorkspaceShortcutsPath = '/api/Config/workspaceshortcuts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWorkspaceShortcuts()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getWorkspaceShortcuts$Response(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: StringArrayParamaters
  }): Observable<StrictHttpResponse<Array<WorkspaceShortcut>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiConfigService.GetWorkspaceShortcutsPath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<WorkspaceShortcut>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getWorkspaceShortcuts$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getWorkspaceShortcuts(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: StringArrayParamaters
  }): Observable<Array<WorkspaceShortcut>> {

    return this.getWorkspaceShortcuts$Response(params).pipe(
      map((r: StrictHttpResponse<Array<WorkspaceShortcut>>) => r.body as Array<WorkspaceShortcut>)
    );
  }

  /**
   * Path part for operation getTimeTrackingForWidget
   */
  static readonly GetTimeTrackingForWidgetPath = '/api/Config/GetTimeTrackingForWidget/{EmpId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTimeTrackingForWidget()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTimeTrackingForWidget$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    EmpId: string;
  }): Observable<StrictHttpResponse<Array<TimeEntryApiModel>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiConfigService.GetTimeTrackingForWidgetPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('EmpId', params.EmpId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TimeEntryApiModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTimeTrackingForWidget$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTimeTrackingForWidget(params: {
    subscriptionKey?: string;
    APIURL?: string;
    EmpId: string;
  }): Observable<Array<TimeEntryApiModel>> {

    return this.getTimeTrackingForWidget$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TimeEntryApiModel>>) => r.body as Array<TimeEntryApiModel>)
    );
  }

}
