/* tslint:disable */
/* eslint-disable */
export interface Annotation {
  '_createdby_value'?: null | string;
  '_createdonbehalfby_value'?: null | string;
  '_objectid_value'?: null | string;
  annotationid?: null | string;
  createdon?: null | string;
  documentbody?: null | string;
  filename?: null | string;
  filesize?: null | number;
  isdocument?: null | boolean;
  mimetype?: null | string;
  notetext?: null | string;
  objecttypecode?: null | string;
  odatPostIssueObjectID?: null | string;
  odatPostObjectID?: null | string;
  odataObjectID?: null | string;
  prefix?: null | string;
  stepid?: null | string;
  subject?: null | string;
}
