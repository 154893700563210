/* tslint:disable */
/* eslint-disable */
import { TemplateTemplateGroup } from './template-template-group';
import { TemplateTemplateLegalEntity } from './template-template-legal-entity';
import { TemplateTemplateSubgroup } from './template-template-subgroup';
import { TemplateBtResponseType } from './template-bt-response-type';
import { TemplateBtTemplateType } from './template-bt-template-type';
export interface TemplateTemplateMeasure {
  bt_Group?: TemplateTemplateGroup;
  bt_LegalEntity?: TemplateTemplateLegalEntity;
  bt_Subgroup?: TemplateTemplateSubgroup;
  bt_allowimage?: null | boolean;
  bt_dropdownresponselist?: null | string;
  bt_imagerequired?: null | boolean;
  bt_measure?: null | string;
  bt_name?: null | string;
  bt_peerreviewtemplatemeasureid?: null | string;
  bt_responsetype?: TemplateBtResponseType;
  bt_sorting?: null | number;
  bt_templatetype?: TemplateBtTemplateType;
  bthr_legalentity?: null | string;
}
