/* tslint:disable */
/* eslint-disable */
export interface CommonRequestDataCommand {
  data?: null | {
[key: string]: string;
};
  dataTypes?: null | {
[key: string]: string;
};
  entityLocalNames?: null | {
[key: string]: string;
};
}
