/* tslint:disable */
/* eslint-disable */
export interface FleetAccess {
  '_bt_grouplocation_value'?: null | string;
  '_bt_location_value'?: null | string;
  '_bt_locationlegalentity_value'?: null | string;
  bt_fleetaccessid?: null | string;
  odataLocation?: null | string;
  odataLocationGroup?: null | string;
  odataLocationLegalEntity?: null | string;
}
