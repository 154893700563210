<!--<div class="emp-exit-form">
  <form class="" [formGroup]="registerForm">-->
<!--LEGAL ENTITY-->
<!--<kendo-formfield class="" style="margin-top:20px">
  <label class="exit-form-label"
         [for]="legalEntity"
         text="Legal Entity">Legal Entity:</label>
  <kendo-dropdownlist class="dropdowns"
                      #legalEntity
                      formControlName="legalEntity"
                      [data]="employeeExitAccess"
                      textField="legalEntityName"
                      valueField="legalEntityId"
                      id="legalEntityDropdown"
                      name="legalEntityDropdown"
                      (valueChange)="setLegalEntityDropdownValue($event)"
                      required
                      [ngModel]
                      [disabled]="">
    <ng-template kendoDropDownListNoDataTemplate>
      <p></p>
    </ng-template>
  </kendo-dropdownlist>

  <kendo-formhint>Employee Legal Entity</kendo-formhint>
  <kendo-formerror>Error: Legal entity is required</kendo-formerror>
</kendo-formfield>-->
<!--END LEGAL ENTITY
EMPLOYEE-->
<!--<kendo-formfield class="formfield">
  <label class="exit-form-label"
         [for]="employee"
         text="Last Name">Employee:</label>
  <kendo-dropdownlist class="dropdowns"
                      [data]="allEmployeesFilteredData"
                      textField="fullNameWithEmpId"
                      valueField="employeeId"
                      formControlName="employee"
                      #employee
                      [filterable]="true"
                      (filterChange)="handleToEmployeeFilter($event)"
                      (valueChange)="setEmployeeDropdownValue($event)"
                      id="employeeDropdown"
                      name="employeeDropdown"
                      required
                      [ngModel]
                      [disabled]="!selectedLegalEntity">
    <ng-template kendoDropDownListNoDataTemplate>
      <p></p>
    </ng-template>
  </kendo-dropdownlist>
  <kendo-formhint>Enter at least three characters</kendo-formhint>
  <kendo-formerror>Error: Employee is required</kendo-formerror>
</kendo-formfield>-->
<!--END EMPLOYEE
EXIT REASON-->
<!--<kendo-formfield class="formfield">
  <label class="exit-form-label"
         [for]="exitReason"
         text="Last Name">Exit Reason:</label>
  <kendo-dropdownlist class="dropdowns"
                      [data]="exitReasons"
                      textField="bt_verdeemployeeexitreasons"
                      valueField="bt_verdeemployeeexitreasonsid"
                      formControlName="exitReason"
                      #exitReason
                      (valueChange)="setExitReasonValue($event)"
                      id="exitReasonDropdown"
                      name="exitReasonDropdown"
                      required
                      [ngModel]
                      [disabled]="">
    <ng-template kendoDropDownListNoDataTemplate>
      <p></p>
    </ng-template>
  </kendo-dropdownlist>
  <kendo-formhint>Reason for exit</kendo-formhint>
  <kendo-formerror>Error: Exit reason is required</kendo-formerror>
</kendo-formfield>-->
<!--END EXIT REASON
EXIT DATE-->
<!--<kendo-formfield class="formfield">
  <label class="exit-form-label"
         [for]="exitDate"
         text="Exit Date">Exit Date:</label>
  <kendo-datepicker #exitDate
                    required
                    formControlName="exitDate"
                    [format]="'yyyy-MM-dd'">
  </kendo-datepicker>
  <kendo-formhint>Employee exit date</kendo-formhint>
  <kendo-formerror>Error: Exit date is required</kendo-formerror>
</kendo-formfield>-->
<!--END EXIT DATE
COMMENTS-->
<!--<kendo-formfield>
  <label class="exit-form-label"
         [for]="comments"
         text="comments">Comments:</label>
  <kendo-textbox style="height: 100px" formControlName="comments" #comments></kendo-textbox>

  <kendo-formhint>Any additional comments</kendo-formhint>

</kendo-formfield>-->
<!--END COMMENTS
FILE UPLOAD-->
<!--<kendo-formfield>
  <label class="exit-form-label" for="fileUpload">Upload Files:</label>
  <input type="file" id="fileUpload" name="fileUpload" multiple (change)="onFileChange($event)" formControlName="fileUpload" #fileUpload>
  <kendo-formhint>Upload one or more files</kendo-formhint>
</kendo-formfield>

 List to display uploaded file names
<div *ngIf="uploadedFiles.length > 0">
  <p>Uploaded Files:</p>
  <ul>
    <li *ngFor="let file of uploadedFiles">{{ file.name }}</li>
  </ul>
</div>-->
<!--END FILE UPLOAD-->
<!--</form>
</div>
<div class="k-form-buttons, form-buttons">
  <button kendoButton themeColor="primary" (click)="submitForm()">
    Submit
  </button>
  <button kendoButton (click)="clearForm()">Clear</button>
</div>-->


<div class="emp-exit-form">
  <form class="form-container k-form"
        [formGroup]="registerForm">


    <div class="grid-layout-container">
      <kendo-gridlayout gap="1% 10%"
                        [rows]="[
          { height: 80 },
          { height: 80 },
          { height: 80 },
          { height: 200 },
          { height: 120 },]"
                        [cols]="[{ width: gridColWidth }, { width: gridColWidth }]">

        <!--LEGAL ENTITY-->
        <kendo-gridlayout-item [row]="1"
                               [col]="1">
          <div class="">
            <label class="exit-form-label">Legal Entity:</label>
            <kendo-textbox class="dropdowns"
                           value="{{ employeeExit.bt_legalentity.bthr_legalentityname }}"
                           readonly="true">
            </kendo-textbox>
          </div>
        </kendo-gridlayout-item>
        <!--END LEGAL ENTITY
  EMPLOYEE-->
        <kendo-gridlayout-item [row]="2"
                               [col]="1">
          <kendo-formfield class="formfield">
            <label class="exit-form-label"
                   text="Last Name">Employee:</label>
            <kendo-textbox class="dropdowns"
                           value="{{ employeeExit.bt_fullname }}"
                           readonly="true">
            </kendo-textbox>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <!--END EMPLOYEE
  EXIT REASON-->
        <kendo-gridlayout-item [row]="1"
                               [col]="2">
          <kendo-formfield class="formfield">
            <label class="exit-form-label"
                   [for]="exitReason"
                   text="Last Name">Exit Reason:</label>
            <kendo-dropdownlist class="dropdowns"
                                [data]="exitReasons"
                                textField="bt_verdeemployeeexitreasons"
                                valueField="bt_verdeemployeeexitreasonsid"
                                formControlName="exitReason"
                                #exitReason
                                (valueChange)="setExitReasonValue($event)"
                                id="exitReasonDropdown"
                                name="exitReasonDropdown"
                                required
                                [ngModel]
                                [disabled]="">
              <ng-template kendoDropDownListNoDataTemplate>
                <p></p>
              </ng-template>
            </kendo-dropdownlist>
            <kendo-formerror>Error: Exit reason is required</kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <!--END EXIT REASON
  EXIT DATE-->
        <kendo-gridlayout-item [row]="2"
                               [col]="2">
          <kendo-formfield class="formfield">
            <label class="exit-form-label"
                   [for]="exitDate"
                   text="Exit Date">Exit Date (Last working day):</label>
            <kendo-datepicker #exitDate
                              required
                              formControlName="exitDate"
                              [format]="'yyyy-MM-dd'">
            </kendo-datepicker>
            <kendo-formerror>Error: Exit date is required</kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <!--END EXIT DATE
    NOTICE DATE-->
        <kendo-gridlayout-item [row]="3"
                               [col]="2">
          <kendo-formfield class="formfield">
            <label class="exit-form-label"
                   [for]="noticeDate"
                   text="Notice Date">Notice Date:</label>
            <kendo-datepicker #noticeDate
                              required
                              formControlName="noticeDate"
                              [format]="'yyyy-MM-dd'">
            </kendo-datepicker>
            <kendo-formerror>Error: Exit date is required</kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <!--END NOTICE DATE
  COMMENTS-->
        <kendo-gridlayout-item [row]="4"
                               [col]="1"
                               [colSpan]="2">
          <kendo-formfield>
            <label class="exit-form-label"
                   [for]="comments"
                   text="comments">Comments:</label>
            <kendo-textarea [rows]="8"
                            formControlName="comments"
                            #comments></kendo-textarea>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <!--END COMMENTS
  FILE UPLOAD-->
        <kendo-gridlayout-item [row]="5"
                               [col]="1">
          <div *ngIf="listofFiles.length > 0">
            <label class="exit-form-label"
                   text="comments">Uploaded Files:</label>
            <ul>
              <li *ngFor="let file of listofFiles; let i = index">{{ file.name }}</li>
            </ul>
          </div>
        </kendo-gridlayout-item>
        <!--END FILE UPLOAD-->
      </kendo-gridlayout>
    </div>
  </form>
</div>
<div class="k-form-buttons, form-buttons">

</div>


<div class="k-form-buttons, form-buttons">
  <button *ngIf="employeeExit.bt_ProcessStatus != 'Processing'"
          style="float: right"
          kendoButton
          themeColor="primary"
          (click)="process()">
    Process
  </button>
  <button style="float: left"
          kendoButton
          themeColor="primary"
          (click)="reversal()">
    Reversal
  </button>
</div>

<verde-modal modalTitle="Confirmation"
             id="reversalModal"
             [hasCancelButton]="true"
             submitButton="Continue"
             (actionButton)="confirmReversal()"
             modalMaxWidth="400">
  <div style="position: relative; height: 60px;">
    <p>{{ confirmMessage }}</p>
  </div>
</verde-modal>

<verde-modal modalTitle="Confirmation"
             id="processModal"
             [hasCancelButton]="true"
             submitButton="Continue"
             (actionButton)="confirmProcess()"
             modalMaxWidth="400">
  <div style="position: relative; height: 60px;">
    <p>{{ confirmMessage }}</p>
  </div>
</verde-modal>