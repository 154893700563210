import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UserService } from '@verde/core';
import { FleetAccess, FleetApiFleetService, FleetFleetEntityTransfer, VehiclesFiltered, VerdeApprovalService } from '@verde/shared';
import { Subject, take, takeUntil } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'verde-fleet-entity-transfer',
  templateUrl: './fleet-entity-transfer.component.html',
  styleUrls: ['./fleet-entity-transfer.component.scss'],
})
export class FleetEntityTransferComponent implements OnInit, AfterViewInit, OnDestroy {
  onDestroy$: Subject<any> = new Subject();

  selectedFleetItem: VehiclesFiltered = {};

  availableFleetAccess: FleetAccess[] = [];

  selectedFleetAccess: FleetAccess = { odataLocation: '' };

  showConfirmationModal: boolean = false;
  showCheckModal: boolean = false;
  isButtonDisabled: boolean = false;
  constructor(
    private userService: UserService,
    private sidebarService: VerdeApprovalService,
    private fleetApiFleetService: FleetApiFleetService,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit(): void {
    this.sidebarService
      .getSelectedFleetItem()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((item) => {
        if (Object.keys(item).length > 0) {
          this.selectedFleetItem = item;
          console.log('selectedFleetItem', this.selectedFleetItem);
        }
      });

    this.sidebarService
      .getAvailableFleetAccess()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((access) => {
        this.availableFleetAccess = access;
        console.log('availableFleetAccess', this.availableFleetAccess);
      });
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  postEntityTransfer(myForm: FormGroup) {
    this.showCheckModal = false;
    this.spinner.show('entityTransfer');
    this.isButtonDisabled = true;

    let body: FleetFleetEntityTransfer = {
      bt_processedby: { id: this.userService.user.employeeId },
      bt_processedbyentity: { id: this.userService.user.legalEntityId },
      bt_toentity: { id: this.selectedFleetAccess._bt_locationlegalentity_value },
      bt_fromentity: { id: this.selectedFleetItem._bt_legalentity_value },
      bt_tofleetlocation: { id: this.selectedFleetAccess._bt_location_value },
      bt_fromfleetlocation: { id: this.selectedFleetItem._bt_location_value },
      bt_fleetitem: { id: this.selectedFleetItem.bthr_vehiclesid },
      bt_transferreason: myForm.value.transferReason,
    };

    this.fleetApiFleetService
      .createUpdateFleetEntityTransfer({ body: { body: body } })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          console.log(ret);
        },
        (error) => {
          console.error(error);
        },
        () => {
          this.spinner.hide('entityTransfer');
          this.showConfirmationModal = true;
        },
      );
  }

  closeConfirmationModal() {
    this.showConfirmationModal = false;
    this.sidebarService.setShowSidebar(false);
  }
}
