/* tslint:disable */
/* eslint-disable */
export interface VehicleAccessory {
  bt_value?: null | boolean;
  bthr_description?: null | string;
  bthr_issuetoall?: null | boolean;
  bthr_name?: null | string;
  bthr_numberrequired?: null | boolean;
  bthr_recordstatus?: null | number;
  bthr_vehicleattachmentsid?: null | string;
  odataRecordStatus?: null | string;
}
