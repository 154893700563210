/* tslint:disable */
/* eslint-disable */
import { PerformanceReviewBtPeerReviewStatus } from './performance-review-bt-peer-review-status';
export interface PerformanceReviewDvPerformanceReviewPatch {
  bt_attachmentfilename?: null | string;
  bt_employeeapprovaldate?: null | string;
  bt_employeecompleted?: null | boolean;
  bt_employeerecallaccepted?: null | boolean;
  bt_employeerecallaccepteddate?: null | string;
  bt_employeereviewstatus?: PerformanceReviewBtPeerReviewStatus;
  bt_managerapprovaldate?: null | string;
  bt_managercompleted?: null | boolean;
  bt_managerrecallrequest?: null | boolean;
  bt_managerrecallrequestdate?: null | string;
  bt_managerreviewstatus?: PerformanceReviewBtPeerReviewStatus;
  bt_totalscore?: null | number;
  bthr_employeeperformancereviewid?: null | string;
  bthr_reviewdate?: null | string;
  bthr_summaryofresults?: null | string;
}
