/* eslint-disable @typescript-eslint/no-empty-interface */
import { createEntityAdapter, EntityState, EntityAdapter } from '@ngrx/entity';
import { SubgroupsActions, SubgroupsActionTypes } from '../actions/subgroup.actions';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromProfileRelatedReducer from './profile-related.reducer';

// Models
import { TemplateTemplateSubgroup } from '@verde/shared';
// Helpers
import { keyHandler } from '@verde/core';

export interface SubgroupState extends EntityState<TemplateTemplateSubgroup> {}

export interface SubgroupRelatedState {
  subgroupState: SubgroupState;
  loading: boolean;
  error: any;
}

export const subgroupKeys = ['bt_peerreviewtemplatesubgroupid'];
export const subgroupStateAdapter: EntityAdapter<TemplateTemplateSubgroup> = createEntityAdapter<TemplateTemplateSubgroup>({
  selectId: (data) => keyHandler(data, subgroupKeys),
});

export const initialSubgroupState: SubgroupState = subgroupStateAdapter.getInitialState({});

export const initialSubgroupRelatedState = {
  subgroupState: initialSubgroupState,
  loading: false,
  error: {},
};

export function subgroupRelatedReducer(state = initialSubgroupRelatedState, action: SubgroupsActions): SubgroupRelatedState {
  switch (action.type) {
    // Get Subgroup
    case SubgroupsActionTypes.SUBGROUPS_GET_ALL:
      return { ...state, loading: true };
    case SubgroupsActionTypes.SUBGROUPS_GET_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        subgroupState: subgroupStateAdapter.upsertMany(action.payload, state.subgroupState),
      };
    case SubgroupsActionTypes.SUBGROUPS_GET_ALL_ERROR:
      return { ...state, loading: false, error: action.payload };

    // Upload Subgroups
    case SubgroupsActionTypes.SUBGROUPS_UPLOAD_SUCCESS: {
      return {
        ...state,
      };
    }

    // Subgroup Update
    case SubgroupsActionTypes.SUBGROUPS_UPDATE_SUCCESS: {
      return {
        ...state,
      };
    }

    // Clear
    case SubgroupsActionTypes.SUBGROUPS_ITEM_CLEAR:
      return { ...initialSubgroupRelatedState };
    default:
      return state;
  }
}

export const getProfileRelatedState = createFeatureSelector<fromProfileRelatedReducer.ProfileRelatedState>('profileRelatedReducer');

export const getSubgroupRelatedState = createSelector(getProfileRelatedState, (state: fromProfileRelatedReducer.ProfileRelatedState) => state.subgroupsState);

// Current Subgroup

export const getSubgroupState = createSelector(getSubgroupRelatedState, (state) => state.subgroupState);
export const { selectAll: getAllSubgroup } = subgroupStateAdapter.getSelectors(getSubgroupState);

// Loading

export const isSubgroupLoading = createSelector(getSubgroupRelatedState, (state) => state.loading);

// Error

export const getSubgroupError = createSelector(getSubgroupRelatedState, (state) => state.error);
