/* tslint:disable */
/* eslint-disable */
import { QualificationsGuidExtrapolate } from './qualifications-guid-extrapolate';
import { QualificationsBtQualificationStatus } from './qualifications-bt-qualification-status';
import { QualificationsBtValidationType } from './qualifications-bt-validation-type';
import { QualificationsBthrQualificationType } from './qualifications-bthr-qualification-type';
import { QualificationsBthrRecordStatus } from './qualifications-bthr-record-status';
export interface QualificationsBthrEmployeeEducationalQualification {
  bt_expire?: null | boolean;
  bt_expirydate?: null | string;
  bt_legalentity?: null | string;
  bt_newinstitution?: null | string;
  bt_newinstitutionurl?: null | string;
  bt_newinstitutionyn?: null | boolean;
  bt_newqualificationname?: null | string;
  bt_newqualificationyn?: null | boolean;
  bt_proxy?: null | boolean;
  bt_proxyid?: null | string;
  bt_proxylegalentity?: null | string;
  bt_qualificationstatus?: QualificationsBtQualificationStatus;
  bt_validated?: null | boolean;
  bt_validatedby?: null | string;
  bt_validatedbyentity?: null | string;
  bt_validationtype?: QualificationsBtValidationType;
  bt_validationurl?: null | string;
  bthrQualification_bt_level?: null | number;
  bthrQualification_bt_points?: null | number;
  bthrQualification_bthr_qualificationtype?: QualificationsBthrQualificationType;
  bthr_dateawarded?: null | string;
  bthr_educationalinstitution?: null | string;
  bthr_employee?: null | string;
  bthr_employeeeducationalqualificationid?: string;
  bthr_qualification?: null | string;
  bthr_recordstatus?: QualificationsBthrRecordStatus;
  educationalInstitution?: QualificationsGuidExtrapolate;
  employee?: QualificationsGuidExtrapolate;
  legalEntity?: QualificationsGuidExtrapolate;
  proxyId?: QualificationsGuidExtrapolate;
  proxyLegalEntity?: QualificationsGuidExtrapolate;
  qualification?: QualificationsGuidExtrapolate;
  qualificationsFile?: null | Array<Blob>;
  qualificationsFileName?: null | string;
  qualificationsFileString?: null | string;
  validatedBy?: QualificationsGuidExtrapolate;
  validatedByEntity?: QualificationsGuidExtrapolate;
}
