/* tslint:disable */
/* eslint-disable */
import { DocumentStringObjectKeyValuePair } from './document-string-object-key-value-pair';
export interface DocumentEntityReference {
  id?: string;
  keyAttributes?: null | Array<DocumentStringObjectKeyValuePair>;
  logicalName?: null | string;
  name?: null | string;
  rowVersion?: null | string;
}
