import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'verde-benchmark-add',
  templateUrl: './benchmark-add.component.html',
  styleUrls: ['./benchmark-add.component.scss'],
})
export class BenchmarkAddComponent implements OnInit {
  benchmarkForm: FormGroup;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.benchmarkForm = this.formBuilder.group({
      benchmark: ['', Validators.required],
      minValue: ['', [Validators.required, Validators.pattern('[0-9]*')]],
      maxValue: ['', [Validators.required, Validators.pattern('[0-9]*')]],
      description: ['', Validators.required],
    });
  }

  addBenchmark() {
    const formValues = this.benchmarkForm.value;
    if (this.benchmarkForm.valid) {
      console.log(formValues);
    } else {
    }
  }
}
