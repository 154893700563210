/* tslint:disable */
/* eslint-disable */
import { BtRequestedBy } from './bt-requested-by';
export interface VerdeRequest {
  '_bt_manager_value'?: null | string;
  '_bt_managerentity_value'?: null | string;
  '_bt_requestedby_value'?: null | string;
  '_bt_requestedbylegalentity_value'?: null | string;
  '_bt_requesttype_value'?: null | string;
  '_bt_taskno_value'?: null | string;
  bt_RequestedBy?: BtRequestedBy;
  bt_description?: null | string;
  bt_employeerole?: null | boolean;
  bt_erporderno?: null | string;
  bt_erprequestno?: null | string;
  bt_managercomment?: null | string;
  bt_managerrole?: null | boolean;
  bt_motivation?: null | string;
  bt_quote1name?: null | string;
  bt_quote1value?: null | number;
  bt_quote2name?: null | string;
  bt_quote3name?: null | string;
  bt_quotevalue2?: null | number;
  bt_quotevalue3?: null | number;
  bt_requestdate?: null | string;
  bt_requestno?: null | string;
  bt_requireddate?: null | string;
  bt_requisitiondate?: null | string;
  bt_requisitionstatus?: null | number;
  bt_specificationdocumentname?: null | string;
  bt_uniquename?: null | string;
  bt_verderequestid?: null | string;
  odataPostManager?: null | string;
  odataPostManagerEntity?: null | string;
  odataPostRequestType?: null | string;
  odataPostRequestedBy?: null | string;
  odataPostRequestedByLegalEntity?: null | string;
  odataPostTaskNo?: null | string;
  odataRequestType?: null | string;
  odataRequestedBy?: null | string;
  odataRequestedByLegalEntity?: null | string;
  odataRequisitionStatus?: null | string;
  odataTaskNo?: null | string;
  statecode?: null | number;
}
