/* tslint:disable */
/* eslint-disable */
export interface CovidTest {
  bt_isolated?: null | boolean;
  bt_resultfilename?: null | string;
  bthr_c19no?: null | string;
  bthr_covid19testid?: null | string;
  bthr_exposurecontacttype?: null | number;
  bthr_numberofhighriskworkplacecontacts?: null | number;
  bthr_posttestresultaction?: null | number;
  bthr_reasonfortest?: null | number;
  bthr_symptomspresent?: null | boolean;
  bthr_testdate?: null | string;
  bthr_testresult?: null | number;
  bthr_testtype?: null | number;
  bthr_vulnerabilitypresent?: null | boolean;
  odataEmployee?: null | string;
  odataExposureType?: null | string;
  odataIsolatedYesNo?: null | string;
  odataIsolation?: null | string;
  odataIsolationPost?: null | string;
  odataLegalEntity?: null | string;
  odataPostTestResult?: null | string;
  odataReasonForTest?: null | string;
  odataSymptomsPresent?: null | string;
  odataTestResult?: null | string;
  odataTestType?: null | string;
  odataVulnerabilityPresent?: null | string;
}
