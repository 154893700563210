/* tslint:disable */
/* eslint-disable */
export interface DvVaccinationsPost {
  bt_attachmentfilename?: null | string;
  bt_date?: null | string;
  bt_doseno?: null | number;
  bt_employeevaccinationid?: null | string;
  bt_isbooster?: null | boolean;
  bt_referencenumber?: null | string;
  bt_referencenumber2?: null | string;
  bt_sequenceno?: null | number;
  bt_vacno?: null | string;
  bt_workflowstatus?: null | number;
  odataEmployee?: null | string;
  odataPostLegalEntity?: null | string;
  odataVaccine?: null | string;
  vaccineID?: null | string;
}
