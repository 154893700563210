export interface BreadcrumbData {
  /**
   * Specifies the text content rendered inside the breadcrumb item.
   */
  title: string;
  /**
   * Specifies the enabled route levels, inclusive to route it is sitting on(include parent).
   * Parent is the the first level.
   */
  enabledLevels: number | 'all';
  /**
   * Specifies the displayed route levels, inclusive to route it is sitting on(include parent).
   * Parent is the the first level.
   */
  displayedLevels: number | 'all';
}
