/* tslint:disable */
/* eslint-disable */
import { EquipmentEquipmentEmployee } from './equipment-equipment-employee';
import { EquipmentEquipmentGroupLocation } from './equipment-equipment-group-location';
import { EquipmentEquipmentLegalEntity } from './equipment-equipment-legal-entity';
import { EquipmentEquipmentLocation } from './equipment-equipment-location';
export interface EquipmentEquipmentAccess {
  bt_Employee?: EquipmentEquipmentEmployee;
  bt_GroupLocation?: EquipmentEquipmentGroupLocation;
  bt_LegalEntity?: EquipmentEquipmentLegalEntity;
  bt_Location?: EquipmentEquipmentLocation;
  bt_LocationLegalEntity?: EquipmentEquipmentLegalEntity;
  bt_equipmentaccess?: null | string;
  bt_equipmentaccessid?: string;
}
