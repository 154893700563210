/* tslint:disable */
/* eslint-disable */
import { BtTaxPeriod } from './bt-tax-period';
export interface BtTaxYear {
  '_bt_legalentity_value'?: null | string;
  bt_bt_taxyearperiod_TaxYear_bt_taxyear?: null | Array<BtTaxPeriod>;
  bt_enddate?: null | string;
  bt_noofholidays?: number;
  bt_noofworkdays?: number;
  bt_startdate?: null | string;
  bt_taxyearid?: null | string;
  bt_year?: null | string;
}
