/* tslint:disable */
/* eslint-disable */
export interface DailyAccess {
  bt_accessgranted?: null | boolean;
  bt_covidrisk?: null | boolean;
  bt_date?: null | string;
  bt_fullname?: null | string;
  bt_guest?: null | boolean;
  bt_isproxy?: null | boolean;
  bt_symptoms?: null | boolean;
  bt_temperature?: null | number;
  bt_usermonitorid?: null | string;
  odataEmployee?: null | string;
  odataLegalEntity?: null | string;
  odataLocation?: null | string;
  odataProxy?: null | string;
}
