import { Inject, Injectable } from '@angular/core';
import { BrowserCacheLocation, PublicClientApplication } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { UserService } from '@verde/core';

@Injectable({
  providedIn: 'root',
})
export class MfAuthService {
  userData: any;
  userAgentApplication: PublicClientApplication = null;

  _signInSuccess: Subject<{ success: boolean; data: string }> = new Subject<{ success: boolean; data: string }>();

  getSignInSuccess() {
    return this._signInSuccess.asObservable();
  }

  setSignInSuccess(item: { success: boolean; data: string }) {
    this._signInSuccess.next(item);
  }

  _signInError: Subject<{ fail: boolean; data: string }> = new Subject<{ fail: boolean; data: string }>();

  getSignInError() {
    return this._signInError.asObservable();
  }

  setSignInError(item: { fail: boolean; data: string }) {
    this._signInError.next(item);
  }

  constructor(private userService: UserService, @Inject('environment') private environment: any) {
    this.userAgentApplication = new PublicClientApplication({
      auth: {
        clientId: environment.msalConfig.auth.clientId,
        redirectUri: environment.msalConfig.auth.redirectUri,
        authority: 'https://login.microsoftonline.com/common',
        postLogoutRedirectUri: environment.msalConfig.auth.redirectUri,
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
      },
    });
  }

  public async microsoftSignIn() {
    let that = this;

    let LoginDetails = {
      appId: '07c123a0-b391-422c-a417-c3c297c2f434',
      redirectUri: this.environment.redirectUri,
      scopes: ['user.read'],
      prompt: 'login',
    };

    await that.userAgentApplication.initialize();
    that.userAgentApplication.acquireTokenPopup(LoginDetails).then(
      function (accessToken) {
        var headers = new Headers();
        var bearer = 'Bearer ' + accessToken.accessToken;
        headers.append('Authorization', bearer);

        var options = {
          method: 'GET',
          headers: headers,
        };
        var graphEndpoint = 'https://graph.microsoft.com/v1.0/me';

        fetch(graphEndpoint, options).then(function (response) {
          response.json().then(function (data) {
            that.userData = data;
            if (
              that.userData.mail.toLowerCase() === that.userService.user.email.toLowerCase() ||
              that.userData.userPrincipalName.toLowerCase() === that.userService.user.email.toLowerCase()
            ) {
              that.setSignInSuccess({ success: true, data: data });
            } else {
              that.setSignInError({ fail: true, data: data });
            }
          });
        });
      },
      function (error) {
        console.error(error);
        that.setSignInError({ fail: true, data: error });
      },
    );
  }
}
