/* tslint:disable */
/* eslint-disable */
export interface FleetConfirmationParameters {
  comment?: null | string;
  confirmationEmployee?: null | string;
  employeeLegalEntity?: null | string;
  isSupplier?: null | boolean;
  movementID?: null | string;
  movementStatus?: null | number;
  rejectionReason?: null | string;
  transferType?: null | number;
  type?: null | string;
  vehicleID?: null | string;
}
