/* tslint:disable */
/* eslint-disable */
export interface BthrPerformanceReviewCycle {
  bt_maxvalue?: null | number;
  bt_minvalue?: null | number;
  bthr_businesscycleid?: null | string;
  bthr_cycleenddate?: null | string;
  bthr_cyclename?: null | string;
  bthr_cyclestartdate?: null | string;
}
