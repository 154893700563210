/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { VoteControl } from '../models/vote-control';
import { VoteGroupOptions } from '../models/vote-group-options';
import { VoteGroupVoting } from '../models/vote-group-voting';
import { VoteManagerNominationControl } from '../models/vote-manager-nomination-control';
import { VoteNomination } from '../models/vote-nomination';
import { VoteNominationList } from '../models/vote-nomination-list';
import { VotesCast } from '../models/votes-cast';

@Injectable({
  providedIn: 'root',
})
export class SharedApiVotingService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getVoteGroupVoting
   */
  static readonly GetVoteGroupVotingPath = '/api/Voting/votegroupvoting/{voteStage}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVoteGroupVoting()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVoteGroupVoting$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    voteStage: string;
  }): Observable<StrictHttpResponse<Array<VoteGroupVoting>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiVotingService.GetVoteGroupVotingPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('voteStage', params.voteStage, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VoteGroupVoting>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVoteGroupVoting$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVoteGroupVoting(params: {
    subscriptionKey?: string;
    APIURL?: string;
    voteStage: string;
  }): Observable<Array<VoteGroupVoting>> {

    return this.getVoteGroupVoting$Response(params).pipe(
      map((r: StrictHttpResponse<Array<VoteGroupVoting>>) => r.body as Array<VoteGroupVoting>)
    );
  }

  /**
   * Path part for operation getVoteGroupOptions
   */
  static readonly GetVoteGroupOptionsPath = '/api/Voting/votegroupoptions/{voteGroupID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVoteGroupOptions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVoteGroupOptions$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    voteGroupID: string;
  }): Observable<StrictHttpResponse<Array<VoteGroupOptions>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiVotingService.GetVoteGroupOptionsPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('voteGroupID', params.voteGroupID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VoteGroupOptions>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVoteGroupOptions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVoteGroupOptions(params: {
    subscriptionKey?: string;
    APIURL?: string;
    voteGroupID: string;
  }): Observable<Array<VoteGroupOptions>> {

    return this.getVoteGroupOptions$Response(params).pipe(
      map((r: StrictHttpResponse<Array<VoteGroupOptions>>) => r.body as Array<VoteGroupOptions>)
    );
  }

  /**
   * Path part for operation getVoteNominationControl
   */
  static readonly GetVoteNominationControlPath = '/api/Voting/votenominationcontrol/{legalEntityID}/{employeeID}/{voteGroupID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVoteNominationControl()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVoteNominationControl$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
    employeeID: string;
    voteGroupID: string;
  }): Observable<StrictHttpResponse<Array<VoteControl>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiVotingService.GetVoteNominationControlPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityID', params.legalEntityID, {});
      rb.path('employeeID', params.employeeID, {});
      rb.path('voteGroupID', params.voteGroupID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VoteControl>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVoteNominationControl$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVoteNominationControl(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
    employeeID: string;
    voteGroupID: string;
  }): Observable<Array<VoteControl>> {

    return this.getVoteNominationControl$Response(params).pipe(
      map((r: StrictHttpResponse<Array<VoteControl>>) => r.body as Array<VoteControl>)
    );
  }

  /**
   * Path part for operation getVoteVotingControl
   */
  static readonly GetVoteVotingControlPath = '/api/Voting/votevotingcontrol/{legalEntityID}/{employeeID}/{voteGroupID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVoteVotingControl()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVoteVotingControl$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
    employeeID: string;
    voteGroupID: string;
  }): Observable<StrictHttpResponse<Array<VoteControl>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiVotingService.GetVoteVotingControlPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityID', params.legalEntityID, {});
      rb.path('employeeID', params.employeeID, {});
      rb.path('voteGroupID', params.voteGroupID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VoteControl>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVoteVotingControl$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVoteVotingControl(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
    employeeID: string;
    voteGroupID: string;
  }): Observable<Array<VoteControl>> {

    return this.getVoteVotingControl$Response(params).pipe(
      map((r: StrictHttpResponse<Array<VoteControl>>) => r.body as Array<VoteControl>)
    );
  }

  /**
   * Path part for operation getVoteNominatedLists
   */
  static readonly GetVoteNominatedListsPath = '/api/Voting/votenominatedlists/{legalEntityID}/{managerID}/{voteID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVoteNominatedLists()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVoteNominatedLists$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
    managerID: string;
    voteID: string;
  }): Observable<StrictHttpResponse<Array<VoteNominationList>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiVotingService.GetVoteNominatedListsPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityID', params.legalEntityID, {});
      rb.path('managerID', params.managerID, {});
      rb.path('voteID', params.voteID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VoteNominationList>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVoteNominatedLists$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVoteNominatedLists(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
    managerID: string;
    voteID: string;
  }): Observable<Array<VoteNominationList>> {

    return this.getVoteNominatedLists$Response(params).pipe(
      map((r: StrictHttpResponse<Array<VoteNominationList>>) => r.body as Array<VoteNominationList>)
    );
  }

  /**
   * Path part for operation getVoteManagerNominationControlByVote
   */
  static readonly GetVoteManagerNominationControlByVotePath = '/api/Voting/votemanagernominationcontrolbyvote/{managerID}/{legalEntityID}/{voteID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVoteManagerNominationControlByVote()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVoteManagerNominationControlByVote$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    managerID: string;
    legalEntityID: string;
    voteID: string;
  }): Observable<StrictHttpResponse<Array<VoteManagerNominationControl>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiVotingService.GetVoteManagerNominationControlByVotePath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('managerID', params.managerID, {});
      rb.path('legalEntityID', params.legalEntityID, {});
      rb.path('voteID', params.voteID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VoteManagerNominationControl>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVoteManagerNominationControlByVote$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVoteManagerNominationControlByVote(params: {
    subscriptionKey?: string;
    APIURL?: string;
    managerID: string;
    legalEntityID: string;
    voteID: string;
  }): Observable<Array<VoteManagerNominationControl>> {

    return this.getVoteManagerNominationControlByVote$Response(params).pipe(
      map((r: StrictHttpResponse<Array<VoteManagerNominationControl>>) => r.body as Array<VoteManagerNominationControl>)
    );
  }

  /**
   * Path part for operation getAllVoteManagerNominationControl
   */
  static readonly GetAllVoteManagerNominationControlPath = '/api/Voting/allvotemanagernominationcontrol/{managerID}/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllVoteManagerNominationControl()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllVoteManagerNominationControl$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    managerID: string;
    legalEntityID: string;
  }): Observable<StrictHttpResponse<Array<VoteManagerNominationControl>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiVotingService.GetAllVoteManagerNominationControlPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('managerID', params.managerID, {});
      rb.path('legalEntityID', params.legalEntityID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VoteManagerNominationControl>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllVoteManagerNominationControl$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllVoteManagerNominationControl(params: {
    subscriptionKey?: string;
    APIURL?: string;
    managerID: string;
    legalEntityID: string;
  }): Observable<Array<VoteManagerNominationControl>> {

    return this.getAllVoteManagerNominationControl$Response(params).pipe(
      map((r: StrictHttpResponse<Array<VoteManagerNominationControl>>) => r.body as Array<VoteManagerNominationControl>)
    );
  }

  /**
   * Path part for operation getVoteNominationsPerNominee
   */
  static readonly GetVoteNominationsPerNomineePath = '/api/Voting/allnominationspernominee/{userID}/{legalEntityID}/{voteID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVoteNominationsPerNominee()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVoteNominationsPerNominee$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userID: string;
    legalEntityID: string;
    voteID: string;
  }): Observable<StrictHttpResponse<Array<VoteNomination>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiVotingService.GetVoteNominationsPerNomineePath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('userID', params.userID, {});
      rb.path('legalEntityID', params.legalEntityID, {});
      rb.path('voteID', params.voteID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VoteNomination>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVoteNominationsPerNominee$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVoteNominationsPerNominee(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userID: string;
    legalEntityID: string;
    voteID: string;
  }): Observable<Array<VoteNomination>> {

    return this.getVoteNominationsPerNominee$Response(params).pipe(
      map((r: StrictHttpResponse<Array<VoteNomination>>) => r.body as Array<VoteNomination>)
    );
  }

  /**
   * Path part for operation getVoteManagerNominatedLists
   */
  static readonly GetVoteManagerNominatedListsPath = '/api/Voting/votemanagernominatedlists/{voteGroupID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVoteManagerNominatedLists()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVoteManagerNominatedLists$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    voteGroupID: string;
  }): Observable<StrictHttpResponse<Array<VoteNominationList>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiVotingService.GetVoteManagerNominatedListsPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('voteGroupID', params.voteGroupID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VoteNominationList>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVoteManagerNominatedLists$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVoteManagerNominatedLists(params: {
    subscriptionKey?: string;
    APIURL?: string;
    voteGroupID: string;
  }): Observable<Array<VoteNominationList>> {

    return this.getVoteManagerNominatedLists$Response(params).pipe(
      map((r: StrictHttpResponse<Array<VoteNominationList>>) => r.body as Array<VoteNominationList>)
    );
  }

  /**
   * Path part for operation getVoteManagerManagerNominationControl
   */
  static readonly GetVoteManagerManagerNominationControlPath = '/api/Voting/votemanagermanagernominationcontrol/{voteGroupID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVoteManagerManagerNominationControl()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVoteManagerManagerNominationControl$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    voteGroupID: string;
  }): Observable<StrictHttpResponse<Array<VoteManagerNominationControl>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiVotingService.GetVoteManagerManagerNominationControlPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('voteGroupID', params.voteGroupID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VoteManagerNominationControl>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVoteManagerManagerNominationControl$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVoteManagerManagerNominationControl(params: {
    subscriptionKey?: string;
    APIURL?: string;
    voteGroupID: string;
  }): Observable<Array<VoteManagerNominationControl>> {

    return this.getVoteManagerManagerNominationControl$Response(params).pipe(
      map((r: StrictHttpResponse<Array<VoteManagerNominationControl>>) => r.body as Array<VoteManagerNominationControl>)
    );
  }

  /**
   * Path part for operation getVoteManagerVoteGroup
   */
  static readonly GetVoteManagerVoteGroupPath = '/api/Voting/votemanagervotegroup';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVoteManagerVoteGroup()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVoteManagerVoteGroup$Response(params?: {
    subscriptionKey?: string;
    APIURL?: string;
  }): Observable<StrictHttpResponse<Array<VoteGroupVoting>>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiVotingService.GetVoteManagerVoteGroupPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VoteGroupVoting>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVoteManagerVoteGroup$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVoteManagerVoteGroup(params?: {
    subscriptionKey?: string;
    APIURL?: string;
  }): Observable<Array<VoteGroupVoting>> {

    return this.getVoteManagerVoteGroup$Response(params).pipe(
      map((r: StrictHttpResponse<Array<VoteGroupVoting>>) => r.body as Array<VoteGroupVoting>)
    );
  }

  /**
   * Path part for operation patchVoteControl
   */
  static readonly PatchVoteControlPath = '/api/Voting/patch/votecontrol';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchVoteControl()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchVoteControl$Response(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: VoteControl
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiVotingService.PatchVoteControlPath, 'patch');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchVoteControl$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchVoteControl(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: VoteControl
  }): Observable<boolean> {

    return this.patchVoteControl$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation patchVoteNominatedLists
   */
  static readonly PatchVoteNominatedListsPath = '/api/Voting/patch/votenominatedlists';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchVoteNominatedLists()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchVoteNominatedLists$Response(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: VoteNominationList
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiVotingService.PatchVoteNominatedListsPath, 'patch');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchVoteNominatedLists$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchVoteNominatedLists(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: VoteNominationList
  }): Observable<boolean> {

    return this.patchVoteNominatedLists$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation patchVoteManagerNominationControl
   */
  static readonly PatchVoteManagerNominationControlPath = '/api/Voting/patch/votemanagernominationcontrol';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchVoteManagerNominationControl()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchVoteManagerNominationControl$Response(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: VoteManagerNominationControl
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiVotingService.PatchVoteManagerNominationControlPath, 'patch');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchVoteManagerNominationControl$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchVoteManagerNominationControl(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: VoteManagerNominationControl
  }): Observable<boolean> {

    return this.patchVoteManagerNominationControl$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation postVoteNomination
   */
  static readonly PostVoteNominationPath = '/api/Voting/post/votenomination';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postVoteNomination()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postVoteNomination$Response(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: VoteNomination
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiVotingService.PostVoteNominationPath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postVoteNomination$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postVoteNomination(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: VoteNomination
  }): Observable<boolean> {

    return this.postVoteNomination$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation postVoteCast
   */
  static readonly PostVoteCastPath = '/api/Voting/post/votecast';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postVoteCast()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postVoteCast$Response(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: VotesCast
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiVotingService.PostVoteCastPath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postVoteCast$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postVoteCast(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: VotesCast
  }): Observable<boolean> {

    return this.postVoteCast$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

}
