import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ActivationStart, Router } from '@angular/router';
import { BreadCrumbItem } from '@progress/kendo-angular-navigation';
import { NavigationService, StringHelpers } from '@verde/core';
import { BreadcrumbData } from '@verde/shared';
import { Subscription } from 'rxjs';

const dashboard = 'Dashboard';

@Component({
  selector: 'verde-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
  public items: BreadCrumbItem[] = [];

  private routesData: Subscription;
  private routesBreadcrumb: Subscription;

  private routeBreadcrumbData: BreadcrumbData;

  get showBreadcrumb(): boolean {
    return !(this.items?.length === 1 && this.items[0].title.toLowerCase() === dashboard.toLowerCase());
  }

  constructor(private router: Router, private route: ActivatedRoute, private navigationService: NavigationService) {}

  public ngOnInit(): void {
    this.initRoutes();
  }

  public ngOnDestroy(): void {
    this.routesData.unsubscribe();
    this.routesBreadcrumb.unsubscribe();
  }

  public onItemClick(item: BreadCrumbItem): void {
    const selectedItemIndex = this.items?.findIndex((i) => i.title === item.title);
    let url = this.items?.slice(0, selectedItemIndex + 1).map((i) => i.title.toLowerCase());

    url = url.filter((item, index) => !(item.toLowerCase() === dashboard.toLowerCase() && index === 0 && url.length > 1));

    this.router.navigate(url);
    if (item.title.toLowerCase() === dashboard.toLowerCase()) {
      this.navigationService.onSelect({ text: item.title });
    } else {
      this.navigationService.selected = StringHelpers.toTitleCase(item.title.replace(/-/g, ' '));
    }
  }

  private initRoutes(): void {
    this.routesBreadcrumb = this.route.firstChild.data.subscribe((data) => {
      if (data) this.routeBreadcrumbData = data?.breadcrumb;
    });

    this.routesData = this.router.events.subscribe((event) => {
      if (event instanceof ActivationStart) {
        this.routeBreadcrumbData = event?.snapshot?.data?.breadcrumb;
      }

      const routeUrl = this.router.url;

      // Exclude query parameters from URL and dashboard segment as it is configured as the home route
      this.items = routeUrl
        .substring(0, routeUrl.indexOf('?') !== -1 ? routeUrl.indexOf('?') : routeUrl.length)
        .split('/')
        .filter(Boolean)
        .map((segment, index) => {
          return {
            text: this.getBreadcrumbName(segment, this.routeBreadcrumbData?.title, index),
            title: segment,
            disabled: this.routeBreadcrumbData.enabledLevels !== 'all' ? (index + 1 <= this.routeBreadcrumbData.enabledLevels ? false : true) : false, // logic to conditionally disable breadcrumb items based on "this.routerData.enabledLevels"
          } as BreadCrumbItem;
        })
        .filter((item, index) => !(item.title.toLowerCase() === dashboard.toLowerCase() && index === 0))
        .filter((_item, index) => (this.routeBreadcrumbData.displayedLevels !== 'all' ? index + 1 <= this.routeBreadcrumbData.displayedLevels : true)); // logic to conditionally display breadcrumb items based on "this.routerData.displayedLevels"

      this.items = [
        {
          text: 'My Workspace',
          title: dashboard,
        },
        ...this.items,
      ];
    });
  }

  private getBreadcrumbName(routerSegment: string, breadcrumbTitle: string | null, index: number): string {
    let breadcrumbName = routerSegment.charAt(0).toUpperCase() + routerSegment.slice(1);

    if (breadcrumbTitle) breadcrumbName = index === 0 ? breadcrumbTitle : breadcrumbName;

    return breadcrumbName.replace(/-/g, ' ');
  }
}
