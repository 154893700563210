/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CarDetails } from '../models/car-details';
import { DriversLicense } from '../models/drivers-license';

@Injectable({
  providedIn: 'root',
})
export class SharedApiFileService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation driversLicenseScan
   */
  static readonly DriversLicenseScanPath = '/api/File/driverslicensescan/{userIDNumber}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `driversLicenseScan()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  driversLicenseScan$Response(params: {
    userIDNumber: string;
    body?: {
'file'?: Blob;
}
  }): Observable<StrictHttpResponse<DriversLicense>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiFileService.DriversLicenseScanPath, 'post');
    if (params) {
      rb.path('userIDNumber', params.userIDNumber, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DriversLicense>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `driversLicenseScan$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  driversLicenseScan(params: {
    userIDNumber: string;
    body?: {
'file'?: Blob;
}
  }): Observable<DriversLicense> {

    return this.driversLicenseScan$Response(params).pipe(
      map((r: StrictHttpResponse<DriversLicense>) => r.body as DriversLicense)
    );
  }

  /**
   * Path part for operation patchChecklistLicenseScan
   */
  static readonly PatchChecklistLicenseScanPath = '/api/File/fleetchecklistlicense';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchChecklistLicenseScan()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  patchChecklistLicenseScan$Response(params?: {
    body?: {
'file'?: Blob;
}
  }): Observable<StrictHttpResponse<CarDetails>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiFileService.PatchChecklistLicenseScanPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CarDetails>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchChecklistLicenseScan$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  patchChecklistLicenseScan(params?: {
    body?: {
'file'?: Blob;
}
  }): Observable<CarDetails> {

    return this.patchChecklistLicenseScan$Response(params).pipe(
      map((r: StrictHttpResponse<CarDetails>) => r.body as CarDetails)
    );
  }

  /**
   * Path part for operation patchUserImage
   */
  static readonly PatchUserImagePath = '/api/File/user/{userID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchUserImage()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  patchUserImage$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userID: string;
    body?: {
'file'?: Blob;
}
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiFileService.PatchUserImagePath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('userID', params.userID, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchUserImage$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  patchUserImage(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userID: string;
    body?: {
'file'?: Blob;
}
  }): Observable<boolean> {

    return this.patchUserImage$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation patchLicenseFrontImage
   */
  static readonly PatchLicenseFrontImagePath = '/api/File/licensefront/{licenseID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchLicenseFrontImage()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  patchLicenseFrontImage$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    licenseID: string;
    body?: {
'file'?: Blob;
}
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiFileService.PatchLicenseFrontImagePath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('licenseID', params.licenseID, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchLicenseFrontImage$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  patchLicenseFrontImage(params: {
    subscriptionKey?: string;
    APIURL?: string;
    licenseID: string;
    body?: {
'file'?: Blob;
}
  }): Observable<boolean> {

    return this.patchLicenseFrontImage$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation patchLicenseBackImage
   */
  static readonly PatchLicenseBackImagePath = '/api/File/licenseback/{licenseID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchLicenseBackImage()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  patchLicenseBackImage$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    licenseID: string;
    body?: {
'file'?: Blob;
}
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiFileService.PatchLicenseBackImagePath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('licenseID', params.licenseID, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchLicenseBackImage$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  patchLicenseBackImage(params: {
    subscriptionKey?: string;
    APIURL?: string;
    licenseID: string;
    body?: {
'file'?: Blob;
}
  }): Observable<boolean> {

    return this.patchLicenseBackImage$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation patchIdentityFile
   */
  static readonly PatchIdentityFilePath = '/api/File/identity/{identityguid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchIdentityFile()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  patchIdentityFile$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    identityguid: string;
    body?: {
'file'?: Blob;
}
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiFileService.PatchIdentityFilePath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('identityguid', params.identityguid, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchIdentityFile$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  patchIdentityFile(params: {
    subscriptionKey?: string;
    APIURL?: string;
    identityguid: string;
    body?: {
'file'?: Blob;
}
  }): Observable<boolean> {

    return this.patchIdentityFile$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation patchVaccinationFile
   */
  static readonly PatchVaccinationFilePath = '/api/File/vaccination/{vaccinationguid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchVaccinationFile()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  patchVaccinationFile$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    vaccinationguid: string;
    body?: {
'file'?: Blob;
}
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiFileService.PatchVaccinationFilePath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('vaccinationguid', params.vaccinationguid, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchVaccinationFile$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  patchVaccinationFile(params: {
    subscriptionKey?: string;
    APIURL?: string;
    vaccinationguid: string;
    body?: {
'file'?: Blob;
}
  }): Observable<boolean> {

    return this.patchVaccinationFile$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation patchBankAccountFile
   */
  static readonly PatchBankAccountFilePath = '/api/File/bankaccounts/{bankaccountguid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchBankAccountFile()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  patchBankAccountFile$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    bankaccountguid: string;
    body?: {
'file'?: Blob;
}
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiFileService.PatchBankAccountFilePath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('bankaccountguid', params.bankaccountguid, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchBankAccountFile$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  patchBankAccountFile(params: {
    subscriptionKey?: string;
    APIURL?: string;
    bankaccountguid: string;
    body?: {
'file'?: Blob;
}
  }): Observable<boolean> {

    return this.patchBankAccountFile$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation patchRiskFile
   */
  static readonly PatchRiskFilePath = '/api/File/risk/{riskguid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchRiskFile()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  patchRiskFile$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    riskguid: string;
    body?: {
'file'?: Blob;
}
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiFileService.PatchRiskFilePath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('riskguid', params.riskguid, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchRiskFile$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  patchRiskFile(params: {
    subscriptionKey?: string;
    APIURL?: string;
    riskguid: string;
    body?: {
'file'?: Blob;
}
  }): Observable<boolean> {

    return this.patchRiskFile$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation patchTaxRegistrationFile
   */
  static readonly PatchTaxRegistrationFilePath = '/api/File/taxrecords/{taxrecordguid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchTaxRegistrationFile()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  patchTaxRegistrationFile$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    taxrecordguid: string;
    body?: {
'file'?: Blob;
}
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiFileService.PatchTaxRegistrationFilePath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('taxrecordguid', params.taxrecordguid, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchTaxRegistrationFile$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  patchTaxRegistrationFile(params: {
    subscriptionKey?: string;
    APIURL?: string;
    taxrecordguid: string;
    body?: {
'file'?: Blob;
}
  }): Observable<boolean> {

    return this.patchTaxRegistrationFile$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation patchTaxCertificateFile
   */
  static readonly PatchTaxCertificateFilePath = '/api/File/taxcertificate/{taxcertificateguid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchTaxCertificateFile()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  patchTaxCertificateFile$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    taxcertificateguid: string;
    body?: {
'file'?: Blob;
}
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiFileService.PatchTaxCertificateFilePath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('taxcertificateguid', params.taxcertificateguid, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchTaxCertificateFile$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  patchTaxCertificateFile(params: {
    subscriptionKey?: string;
    APIURL?: string;
    taxcertificateguid: string;
    body?: {
'file'?: Blob;
}
  }): Observable<boolean> {

    return this.patchTaxCertificateFile$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation patchReviewFile
   */
  static readonly PatchReviewFilePath = '/api/File/review/{reviewguid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchReviewFile()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  patchReviewFile$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    reviewguid: string;
    body?: {
'file'?: Blob;
}
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiFileService.PatchReviewFilePath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('reviewguid', params.reviewguid, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchReviewFile$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  patchReviewFile(params: {
    subscriptionKey?: string;
    APIURL?: string;
    reviewguid: string;
    body?: {
'file'?: Blob;
}
  }): Observable<boolean> {

    return this.patchReviewFile$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation patchGiftFile
   */
  static readonly PatchGiftFilePath = '/api/File/gift/{giftid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchGiftFile()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  patchGiftFile$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    giftid: string;
    body?: {
'file'?: Blob;
}
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiFileService.PatchGiftFilePath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('giftid', params.giftid, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchGiftFile$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  patchGiftFile(params: {
    subscriptionKey?: string;
    APIURL?: string;
    giftid: string;
    body?: {
'file'?: Blob;
}
  }): Observable<boolean> {

    return this.patchGiftFile$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation patchGiftReturnFile
   */
  static readonly PatchGiftReturnFilePath = '/api/File/giftreturn/{giftid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchGiftReturnFile()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  patchGiftReturnFile$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    giftid: string;
    body?: {
'file'?: Blob;
}
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiFileService.PatchGiftReturnFilePath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('giftid', params.giftid, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchGiftReturnFile$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  patchGiftReturnFile(params: {
    subscriptionKey?: string;
    APIURL?: string;
    giftid: string;
    body?: {
'file'?: Blob;
}
  }): Observable<boolean> {

    return this.patchGiftReturnFile$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation patchSupportingNote
   */
  static readonly PatchSupportingNotePath = '/api/File/supportingnote/{requestid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchSupportingNote()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  patchSupportingNote$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    requestid: string;
    body?: {
'file'?: Blob;
}
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiFileService.PatchSupportingNotePath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('requestid', params.requestid, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchSupportingNote$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  patchSupportingNote(params: {
    subscriptionKey?: string;
    APIURL?: string;
    requestid: string;
    body?: {
'file'?: Blob;
}
  }): Observable<boolean> {

    return this.patchSupportingNote$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation patchCovidTest
   */
  static readonly PatchCovidTestPath = '/api/File/covidtest/{testid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchCovidTest()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  patchCovidTest$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    testid: string;
    body?: {
'file'?: Blob;
}
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiFileService.PatchCovidTestPath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('testid', params.testid, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchCovidTest$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  patchCovidTest(params: {
    subscriptionKey?: string;
    APIURL?: string;
    testid: string;
    body?: {
'file'?: Blob;
}
  }): Observable<boolean> {

    return this.patchCovidTest$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation patchTrainingPlan
   */
  static readonly PatchTrainingPlanPath = '/api/File/trainingplan/{planid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchTrainingPlan()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  patchTrainingPlan$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    planid: string;
    body?: {
'file'?: Blob;
}
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiFileService.PatchTrainingPlanPath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('planid', params.planid, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchTrainingPlan$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  patchTrainingPlan(params: {
    subscriptionKey?: string;
    APIURL?: string;
    planid: string;
    body?: {
'file'?: Blob;
}
  }): Observable<boolean> {

    return this.patchTrainingPlan$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation patchToChecklist
   */
  static readonly PatchToChecklistPath = '/api/File/tochecklist/{movementid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchToChecklist()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  patchToChecklist$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    movementid: string;
    body?: {
'file'?: Blob;
}
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiFileService.PatchToChecklistPath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('movementid', params.movementid, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchToChecklist$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  patchToChecklist(params: {
    subscriptionKey?: string;
    APIURL?: string;
    movementid: string;
    body?: {
'file'?: Blob;
}
  }): Observable<boolean> {

    return this.patchToChecklist$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation patchFromChecklist
   */
  static readonly PatchFromChecklistPath = '/api/File/fromchecklist/{movementid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchFromChecklist()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  patchFromChecklist$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    movementid: string;
    body?: {
'file'?: Blob;
}
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiFileService.PatchFromChecklistPath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('movementid', params.movementid, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchFromChecklist$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  patchFromChecklist(params: {
    subscriptionKey?: string;
    APIURL?: string;
    movementid: string;
    body?: {
'file'?: Blob;
}
  }): Observable<boolean> {

    return this.patchFromChecklist$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation patchRegistrationDocument
   */
  static readonly PatchRegistrationDocumentPath = '/api/File/registrationdocument/{movementid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchRegistrationDocument()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  patchRegistrationDocument$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    movementid: string;
    body?: {
'file'?: Blob;
}
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiFileService.PatchRegistrationDocumentPath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('movementid', params.movementid, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchRegistrationDocument$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  patchRegistrationDocument(params: {
    subscriptionKey?: string;
    APIURL?: string;
    movementid: string;
    body?: {
'file'?: Blob;
}
  }): Observable<boolean> {

    return this.patchRegistrationDocument$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation patchPurchaseInvoice
   */
  static readonly PatchPurchaseInvoicePath = '/api/File/purchaseinvoice/{movementid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchPurchaseInvoice()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  patchPurchaseInvoice$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    movementid: string;
    body?: {
'file'?: Blob;
}
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiFileService.PatchPurchaseInvoicePath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('movementid', params.movementid, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchPurchaseInvoice$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  patchPurchaseInvoice(params: {
    subscriptionKey?: string;
    APIURL?: string;
    movementid: string;
    body?: {
'file'?: Blob;
}
  }): Observable<boolean> {

    return this.patchPurchaseInvoice$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation patchAdditionalDocs
   */
  static readonly PatchAdditionalDocsPath = '/api/File/additionaldocs/{movementid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchAdditionalDocs()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  patchAdditionalDocs$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    movementid: string;
    body?: {
'file'?: Blob;
}
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiFileService.PatchAdditionalDocsPath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('movementid', params.movementid, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchAdditionalDocs$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  patchAdditionalDocs(params: {
    subscriptionKey?: string;
    APIURL?: string;
    movementid: string;
    body?: {
'file'?: Blob;
}
  }): Observable<boolean> {

    return this.patchAdditionalDocs$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation patchLicenseRenewalFile
   */
  static readonly PatchLicenseRenewalFilePath = '/api/File/licenserenewal/{recordID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchLicenseRenewalFile()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  patchLicenseRenewalFile$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    recordID: string;
    body?: {
'file'?: Blob;
}
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiFileService.PatchLicenseRenewalFilePath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('recordID', params.recordID, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchLicenseRenewalFile$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  patchLicenseRenewalFile(params: {
    subscriptionKey?: string;
    APIURL?: string;
    recordID: string;
    body?: {
'file'?: Blob;
}
  }): Observable<boolean> {

    return this.patchLicenseRenewalFile$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation patchRequestSpecificationDocument
   */
  static readonly PatchRequestSpecificationDocumentPath = '/api/File/request/specificationdocument/{recordID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchRequestSpecificationDocument()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  patchRequestSpecificationDocument$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    recordID: string;
    body?: {
'file'?: Blob;
}
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiFileService.PatchRequestSpecificationDocumentPath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('recordID', params.recordID, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchRequestSpecificationDocument$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  patchRequestSpecificationDocument(params: {
    subscriptionKey?: string;
    APIURL?: string;
    recordID: string;
    body?: {
'file'?: Blob;
}
  }): Observable<boolean> {

    return this.patchRequestSpecificationDocument$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation patchRequestQuote1
   */
  static readonly PatchRequestQuote1Path = '/api/File/request/quote1/{recordID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchRequestQuote1()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  patchRequestQuote1$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    recordID: string;
    body?: {
'file'?: Blob;
}
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiFileService.PatchRequestQuote1Path, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('recordID', params.recordID, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchRequestQuote1$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  patchRequestQuote1(params: {
    subscriptionKey?: string;
    APIURL?: string;
    recordID: string;
    body?: {
'file'?: Blob;
}
  }): Observable<boolean> {

    return this.patchRequestQuote1$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation patchRequestQuote2
   */
  static readonly PatchRequestQuote2Path = '/api/File/request/quote2/{recordID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchRequestQuote2()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  patchRequestQuote2$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    recordID: string;
    body?: {
'file'?: Blob;
}
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiFileService.PatchRequestQuote2Path, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('recordID', params.recordID, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchRequestQuote2$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  patchRequestQuote2(params: {
    subscriptionKey?: string;
    APIURL?: string;
    recordID: string;
    body?: {
'file'?: Blob;
}
  }): Observable<boolean> {

    return this.patchRequestQuote2$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation patchRequestQuote3
   */
  static readonly PatchRequestQuote3Path = '/api/File/request/quote3/{recordID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchRequestQuote3()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  patchRequestQuote3$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    recordID: string;
    body?: {
'file'?: Blob;
}
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiFileService.PatchRequestQuote3Path, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('recordID', params.recordID, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchRequestQuote3$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  patchRequestQuote3(params: {
    subscriptionKey?: string;
    APIURL?: string;
    recordID: string;
    body?: {
'file'?: Blob;
}
  }): Observable<boolean> {

    return this.patchRequestQuote3$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation patchVerdeManualDocument
   */
  static readonly PatchVerdeManualDocumentPath = '/api/File/verdemanualdocuments/{recordID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchVerdeManualDocument()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  patchVerdeManualDocument$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    recordID: string;
    body?: {
'file'?: Blob;
}
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiFileService.PatchVerdeManualDocumentPath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('recordID', params.recordID, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchVerdeManualDocument$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  patchVerdeManualDocument(params: {
    subscriptionKey?: string;
    APIURL?: string;
    recordID: string;
    body?: {
'file'?: Blob;
}
  }): Observable<boolean> {

    return this.patchVerdeManualDocument$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation patchVerdeIssueFile
   */
  static readonly PatchVerdeIssueFilePath = '/api/File/issuefile/{recordID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchVerdeIssueFile()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  patchVerdeIssueFile$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    recordID: string;
    body?: {
'file'?: Blob;
}
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiFileService.PatchVerdeIssueFilePath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('recordID', params.recordID, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchVerdeIssueFile$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  patchVerdeIssueFile(params: {
    subscriptionKey?: string;
    APIURL?: string;
    recordID: string;
    body?: {
'file'?: Blob;
}
  }): Observable<boolean> {

    return this.patchVerdeIssueFile$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation patchFundsFile
   */
  static readonly PatchFundsFilePath = '/api/File/fundsFile/{recordID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchFundsFile()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  patchFundsFile$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    recordID: string;
    body?: {
'file'?: Blob;
}
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiFileService.PatchFundsFilePath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('recordID', params.recordID, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchFundsFile$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  patchFundsFile(params: {
    subscriptionKey?: string;
    APIURL?: string;
    recordID: string;
    body?: {
'file'?: Blob;
}
  }): Observable<boolean> {

    return this.patchFundsFile$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation getUserImage
   */
  static readonly GetUserImagePath = '/api/File/user/get/{userguid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserImage()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserImage$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userguid: string;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiFileService.GetUserImagePath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('userguid', params.userguid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserImage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserImage(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userguid: string;
  }): Observable<string> {

    return this.getUserImage$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getLegalEntityImage
   */
  static readonly GetLegalEntityImagePath = '/api/File/legalentity/get/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLegalEntityImage()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLegalEntityImage$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiFileService.GetLegalEntityImagePath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityID', params.legalEntityID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLegalEntityImage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLegalEntityImage(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
  }): Observable<string> {

    return this.getLegalEntityImage$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getIdentityFile
   */
  static readonly GetIdentityFilePath = '/api/File/identity/get/{identityguid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getIdentityFile()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIdentityFile$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    identityguid: string;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiFileService.GetIdentityFilePath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('identityguid', params.identityguid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getIdentityFile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIdentityFile(params: {
    subscriptionKey?: string;
    APIURL?: string;
    identityguid: string;
  }): Observable<string> {

    return this.getIdentityFile$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getVaccinationFile
   */
  static readonly GetVaccinationFilePath = '/api/File/vaccination/get/{vaccinationguid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVaccinationFile()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVaccinationFile$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    vaccinationguid: string;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiFileService.GetVaccinationFilePath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('vaccinationguid', params.vaccinationguid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVaccinationFile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVaccinationFile(params: {
    subscriptionKey?: string;
    APIURL?: string;
    vaccinationguid: string;
  }): Observable<string> {

    return this.getVaccinationFile$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getBankAccountFile
   */
  static readonly GetBankAccountFilePath = '/api/File/bankaccounts/get/{bankaccountguid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBankAccountFile()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBankAccountFile$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    bankaccountguid: string;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiFileService.GetBankAccountFilePath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('bankaccountguid', params.bankaccountguid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getBankAccountFile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBankAccountFile(params: {
    subscriptionKey?: string;
    APIURL?: string;
    bankaccountguid: string;
  }): Observable<string> {

    return this.getBankAccountFile$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getRiskFile
   */
  static readonly GetRiskFilePath = '/api/File/risk/get/{riskguid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRiskFile()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRiskFile$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    riskguid: string;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiFileService.GetRiskFilePath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('riskguid', params.riskguid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRiskFile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRiskFile(params: {
    subscriptionKey?: string;
    APIURL?: string;
    riskguid: string;
  }): Observable<string> {

    return this.getRiskFile$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getTaxRegistrationFile
   */
  static readonly GetTaxRegistrationFilePath = '/api/File/taxrecords/get/{taxrecordguid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTaxRegistrationFile()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTaxRegistrationFile$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    taxrecordguid: string;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiFileService.GetTaxRegistrationFilePath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('taxrecordguid', params.taxrecordguid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTaxRegistrationFile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTaxRegistrationFile(params: {
    subscriptionKey?: string;
    APIURL?: string;
    taxrecordguid: string;
  }): Observable<string> {

    return this.getTaxRegistrationFile$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getTaxCertificateFile
   */
  static readonly GetTaxCertificateFilePath = '/api/File/taxcertificate/get/{taxcertificateguid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTaxCertificateFile()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTaxCertificateFile$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    taxcertificateguid: string;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiFileService.GetTaxCertificateFilePath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('taxcertificateguid', params.taxcertificateguid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTaxCertificateFile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTaxCertificateFile(params: {
    subscriptionKey?: string;
    APIURL?: string;
    taxcertificateguid: string;
  }): Observable<string> {

    return this.getTaxCertificateFile$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getPermitFile
   */
  static readonly GetPermitFilePath = '/api/File/permit/get/{permitguid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPermitFile()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPermitFile$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    permitguid: string;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiFileService.GetPermitFilePath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('permitguid', params.permitguid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPermitFile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPermitFile(params: {
    subscriptionKey?: string;
    APIURL?: string;
    permitguid: string;
  }): Observable<string> {

    return this.getPermitFile$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getReviewFile
   */
  static readonly GetReviewFilePath = '/api/File/review/get/{reviewguid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getReviewFile()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReviewFile$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    reviewguid: string;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiFileService.GetReviewFilePath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('reviewguid', params.reviewguid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getReviewFile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReviewFile(params: {
    subscriptionKey?: string;
    APIURL?: string;
    reviewguid: string;
  }): Observable<string> {

    return this.getReviewFile$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getCovidTest
   */
  static readonly GetCovidTestPath = '/api/File/covidtest/get/{testid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCovidTest()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCovidTest$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    testid: string;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiFileService.GetCovidTestPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('testid', params.testid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCovidTest$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCovidTest(params: {
    subscriptionKey?: string;
    APIURL?: string;
    testid: string;
  }): Observable<string> {

    return this.getCovidTest$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getTrainingPlan
   */
  static readonly GetTrainingPlanPath = '/api/File/trainingplan/get/{planid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTrainingPlan()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTrainingPlan$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    planid: string;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiFileService.GetTrainingPlanPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('planid', params.planid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTrainingPlan$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTrainingPlan(params: {
    subscriptionKey?: string;
    APIURL?: string;
    planid: string;
  }): Observable<string> {

    return this.getTrainingPlan$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getRegistrationDocument
   */
  static readonly GetRegistrationDocumentPath = '/api/File/registrationdocument/get/{movementid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRegistrationDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRegistrationDocument$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    movementid: string;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiFileService.GetRegistrationDocumentPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('movementid', params.movementid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRegistrationDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRegistrationDocument(params: {
    subscriptionKey?: string;
    APIURL?: string;
    movementid: string;
  }): Observable<string> {

    return this.getRegistrationDocument$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getRequestTypeTemplate
   */
  static readonly GetRequestTypeTemplatePath = '/api/File/requesttypetemplate/get/{requestid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRequestTypeTemplate()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRequestTypeTemplate$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    requestid: string;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiFileService.GetRequestTypeTemplatePath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('requestid', params.requestid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRequestTypeTemplate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRequestTypeTemplate(params: {
    subscriptionKey?: string;
    APIURL?: string;
    requestid: string;
  }): Observable<string> {

    return this.getRequestTypeTemplate$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getRequestSpecificationDocument
   */
  static readonly GetRequestSpecificationDocumentPath = '/api/File/requestspecificationdocument/get/{requestid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRequestSpecificationDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRequestSpecificationDocument$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    requestid: string;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiFileService.GetRequestSpecificationDocumentPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('requestid', params.requestid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRequestSpecificationDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRequestSpecificationDocument(params: {
    subscriptionKey?: string;
    APIURL?: string;
    requestid: string;
  }): Observable<string> {

    return this.getRequestSpecificationDocument$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getRequestQuote1
   */
  static readonly GetRequestQuote1Path = '/api/File/requestquote1/get/{requestid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRequestQuote1()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRequestQuote1$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    requestid: string;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiFileService.GetRequestQuote1Path, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('requestid', params.requestid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRequestQuote1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRequestQuote1(params: {
    subscriptionKey?: string;
    APIURL?: string;
    requestid: string;
  }): Observable<string> {

    return this.getRequestQuote1$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getRequestQuote2
   */
  static readonly GetRequestQuote2Path = '/api/File/requestquote2/get/{requestid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRequestQuote2()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRequestQuote2$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    requestid: string;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiFileService.GetRequestQuote2Path, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('requestid', params.requestid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRequestQuote2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRequestQuote2(params: {
    subscriptionKey?: string;
    APIURL?: string;
    requestid: string;
  }): Observable<string> {

    return this.getRequestQuote2$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getRequestQuote3
   */
  static readonly GetRequestQuote3Path = '/api/File/requestquote3/get/{requestid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRequestQuote3()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRequestQuote3$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    requestid: string;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiFileService.GetRequestQuote3Path, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('requestid', params.requestid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRequestQuote3$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRequestQuote3(params: {
    subscriptionKey?: string;
    APIURL?: string;
    requestid: string;
  }): Observable<string> {

    return this.getRequestQuote3$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getVerdeManualDocument
   */
  static readonly GetVerdeManualDocumentPath = '/api/File/verdemanualdocuments/get/{recordid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVerdeManualDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVerdeManualDocument$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    recordid: string;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiFileService.GetVerdeManualDocumentPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('recordid', params.recordid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVerdeManualDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVerdeManualDocument(params: {
    subscriptionKey?: string;
    APIURL?: string;
    recordid: string;
  }): Observable<string> {

    return this.getVerdeManualDocument$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getVerdeManualDocumentUserUpload
   */
  static readonly GetVerdeManualDocumentUserUploadPath = '/api/File/verdemanualdocumentsuserupload/get/{recordid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVerdeManualDocumentUserUpload()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVerdeManualDocumentUserUpload$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    recordid: string;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiFileService.GetVerdeManualDocumentUserUploadPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('recordid', params.recordid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVerdeManualDocumentUserUpload$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVerdeManualDocumentUserUpload(params: {
    subscriptionKey?: string;
    APIURL?: string;
    recordid: string;
  }): Observable<string> {

    return this.getVerdeManualDocumentUserUpload$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getSupportingNote
   */
  static readonly GetSupportingNotePath = '/api/File/supportingnote/get/{recordid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSupportingNote()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSupportingNote$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    recordid: string;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiFileService.GetSupportingNotePath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('recordid', params.recordid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSupportingNote$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSupportingNote(params: {
    subscriptionKey?: string;
    APIURL?: string;
    recordid: string;
  }): Observable<string> {

    return this.getSupportingNote$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getDocumentAndFormsFile
   */
  static readonly GetDocumentAndFormsFilePath = '/api/File/attachment/get/{recordID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocumentAndFormsFile()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentAndFormsFile$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    recordID: string;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiFileService.GetDocumentAndFormsFilePath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('recordID', params.recordID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDocumentAndFormsFile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentAndFormsFile(params: {
    subscriptionKey?: string;
    APIURL?: string;
    recordID: string;
  }): Observable<string> {

    return this.getDocumentAndFormsFile$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getVerdeIssueFile
   */
  static readonly GetVerdeIssueFilePath = '/api/File/issuefile/get/{recordID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVerdeIssueFile()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVerdeIssueFile$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    recordID: string;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiFileService.GetVerdeIssueFilePath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('recordID', params.recordID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVerdeIssueFile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVerdeIssueFile(params: {
    subscriptionKey?: string;
    APIURL?: string;
    recordID: string;
  }): Observable<string> {

    return this.getVerdeIssueFile$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getEmployeeFundOptionChangeFile
   */
  static readonly GetEmployeeFundOptionChangeFilePath = '/api/File/getEmployeeFundOptionChangeFile/get/{recordID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEmployeeFundOptionChangeFile()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployeeFundOptionChangeFile$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    recordID: string;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, SharedApiFileService.GetEmployeeFundOptionChangeFilePath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('recordID', params.recordID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEmployeeFundOptionChangeFile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployeeFundOptionChangeFile(params: {
    subscriptionKey?: string;
    APIURL?: string;
    recordID: string;
  }): Observable<string> {

    return this.getEmployeeFundOptionChangeFile$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

}
