/* eslint-disable @typescript-eslint/no-empty-interface */
import { createEntityAdapter, EntityState, EntityAdapter } from '@ngrx/entity';
import { MeasuresActions, MeasuresActionTypes } from '../actions/measure.actions';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromProfileRelatedReducer from './profile-related.reducer';

// Models
import { TemplateTemplateMeasure } from '@verde/shared';

// Helpers
import { keyHandler } from '@verde/core';

export interface MeasureState extends EntityState<TemplateTemplateMeasure> {}

export interface MeasureRelatedState {
  measureState: MeasureState;
  loading: boolean;
  error: any;
}

export const measureKeys = ['bt_peerreviewtemplatemeasureid'];
export const measureStateAdapter: EntityAdapter<TemplateTemplateMeasure> = createEntityAdapter<TemplateTemplateMeasure>({
  selectId: (data) => keyHandler(data, measureKeys),
});

export const initialMeasureState: MeasureState = measureStateAdapter.getInitialState({});

export const initialMeasureRelatedState = {
  measureState: initialMeasureState,
  measureSavedState: initialMeasureState,
  loading: false,
  error: {},
};

export function measureRelatedReducer(state = initialMeasureRelatedState, action: MeasuresActions): MeasureRelatedState {
  switch (action.type) {
    // Get Measure
    case MeasuresActionTypes.MEASURES_GET_ALL:
      return { ...state, loading: true };
    case MeasuresActionTypes.MEASURES_GET_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        measureState: measureStateAdapter.upsertMany(action.payload, state.measureState),
      };
    case MeasuresActionTypes.MEASURES_GET_ALL_ERROR:
      return { ...state, loading: false, error: action.payload };

    // Measure Upload
    case MeasuresActionTypes.MEASURES_UPLOAD_SUCCESS: {
      return {
        ...state,
      };
    }

    // Measure Update
    case MeasuresActionTypes.MEASURES_UPDATE_SUCCESS: {
      return {
        ...state,
      };
    }

    // Clear
    case MeasuresActionTypes.MEASURES_ITEM_CLEAR:
      return { ...initialMeasureRelatedState };

    default:
      return state;
  }
}

export const getProfileRelatedState = createFeatureSelector<fromProfileRelatedReducer.ProfileRelatedState>('profileRelatedReducer');

export const getMeasureRelatedState = createSelector(getProfileRelatedState, (state: fromProfileRelatedReducer.ProfileRelatedState) => state.measuresState);

// Current Measure

export const getMeasureState = createSelector(getMeasureRelatedState, (state) => state.measureState);
export const { selectAll: getAllMeasure } = measureStateAdapter.getSelectors(getMeasureState);

// Loading

export const isMeasureLoading = createSelector(getMeasureRelatedState, (state) => state.loading);

// Error

export const getMeasureError = createSelector(getMeasureRelatedState, (state) => state.error);
