/* tslint:disable */
/* eslint-disable */
import { DocumentsAndForms } from './documents-and-forms';
export interface DocumentsAndFormsRelated {
  '_bt_documentandform_value'?: null | string;
  '_bt_legalentity_value'?: null | string;
  '_bt_relateddocumentandform_value'?: null | string;
  bt_DocumentandForm?: DocumentsAndForms;
  bt_documentandformrelated?: null | string;
  bt_documentandformrelatedid?: null | string;
  odataDocumentandForm?: null | string;
  odataRelatedDocumentandForm?: null | string;
}
