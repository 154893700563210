/* tslint:disable */
/* eslint-disable */
import { StructureStructureDepartment } from './structure-structure-department';
import { StructureStructureDivision } from './structure-structure-division';
import { StructureStructureEmployee } from './structure-structure-employee';
import { StructureStructureLegalEntity } from './structure-structure-legal-entity';
import { StructureBtPositionseat } from './structure-bt-positionseat';
export interface StructureStructurePositionSeat {
  department?: StructureStructureDepartment;
  description?: null | string;
  division?: StructureStructureDivision;
  employee?: StructureStructureEmployee;
  hasChildren?: null | boolean;
  id?: null | string;
  legalEntity?: StructureStructureLegalEntity;
  name?: null | string;
  seatStatus?: StructureBtPositionseat;
}
