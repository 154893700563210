/* tslint:disable */
/* eslint-disable */
import { EquipmentEquipmentEmployee } from './equipment-equipment-employee';
import { EquipmentEquipmentLegalEntity } from './equipment-equipment-legal-entity';
import { EquipmentEquipmentLocation } from './equipment-equipment-location';
import { EquipmentEquipmentVerdeRequest } from './equipment-equipment-verde-request';
import { EquipmentBtApprovalStatus } from './equipment-bt-approval-status';
export interface EquipmentEquipmentRequestHeader {
  bt_LegalEntity?: EquipmentEquipmentLegalEntity;
  bt_Location?: EquipmentEquipmentLocation;
  bt_Requestby?: EquipmentEquipmentEmployee;
  bt_VerdeRequest?: EquipmentEquipmentVerdeRequest;
  bt_alloverridesprocessed?: null | boolean;
  bt_approvalrequired?: null | boolean;
  bt_approvalstatus?: EquipmentBtApprovalStatus;
  bt_budgettotal?: null | number;
  bt_budgettotal_date?: null | string;
  bt_budgettotal_state?: null | number;
  bt_definitiontechnicalname?: null | string;
  bt_equipmentrequestheaderid?: null | string;
  bt_equipmentrequestno?: null | string;
  bt_lineoverride?: null | boolean;
  bt_summarizebyequipmenttype?: null | boolean;
  bt_uniquename?: null | string;
}
