<!-- Rendom Button -->
<button id="showModelsModal"
        hidden
        (click)="openModelsModal(fleetModelForm)"></button>
<!-- #region Fleet Models Modal -->
<verde-modal id="fleetModelsModal"
             modalTitle="{{ modelFormAction }} Model"
             submitButton="Submit"
             (actionButton)="postFleetModel(fleetModelForm)"
             (modalCancelled)="hideModal()"
             [submitActive]="fleetModelForm.valid"
             submitConfirmationMessage="Please confirm that you would like to submit this model"
             cancelConfirmationMessage="Please confirm that you would like to cancel"
             [hasSubmitConfirmation]="true"
             [hasCancelConfirmation]="fleetModelForm.touched && !fleetModelForm.pristine">
  <form #fleetModelForm="ngForm"
        novalidate
        autocomplete="off"
        style="position: relative">
    <ngx-spinner [disableAnimation]="disableAnimation"
                 name="fleetmodelsmodal"
                 bdColor="rgb(237, 237, 237)"
                 size="default"
                 color="#6264a7"
                 type="ball-clip-rotate"
                 [fullScreen]="false">

    </ngx-spinner>

    <div class="modal_grid">
      <div class="form-group">
        <label for="addModelName">Model Name:</label>
        <kendo-textbox id="addModelName"
                       name="addModelName"
                       maxlength="70"
                       #varAddModelName="ngModel"
                       required
                       [ngModel]
                       [clearButton]="true">
        </kendo-textbox>

        <!--Validation-->
        <div class="alert alert-danger"
             *ngIf="varAddModelName.touched && !varAddModelName.valid">
          <div *ngIf="varAddModelName.errors?.required">Field is required!</div>
        </div>
      </div>

      <div class="form-group">
        <label for="addModelYear">Model Year:</label>
        <kendo-textbox id="addModelYear"
                       name="addModelYear"
                       maxlength="4"
                       #varAddModelYear="ngModel"
                       required
                       [ngModel]
                       [clearButton]="true">
        </kendo-textbox>

        <!--Validation-->
        <div class="alert alert-danger"
             *ngIf="varAddModelYear.touched && !varAddModelYear.valid">
          <div *ngIf="varAddModelYear.errors?.required">Field is required!</div>
          <div *ngIf="varAddModelYear.errors?.minlength">Enter a valid year.</div>
        </div>
      </div>

      <div class="form-group">
        <label for="addModelMake">Make:</label>
        <kendo-dropdownlist [data]="allFleetMakesData"
                            textField="bthr_make"
                            valueField="bthr_vehiclemakeid"
                            [filterable]="true"
                            (filterChange)="handleMakesFilter($event)"
                            id="addModelMake"
                            name="addModelMake"
                            #varAddModelMake="ngModel"
                            required
                            [ngModel]
                            [pattern]="checkDefaultPattern">
        </kendo-dropdownlist>
        <!-- Validation -->
        <div class="alert alert-danger"
             *ngIf="varAddModelMake.touched && !varAddModelMake.valid">
          <div *ngIf="varAddModelMake.errors?.required">Field is required!</div>
        </div>
      </div>

      <div class="form-group">
        <label for="addModelType">Fleet Type:</label>
        <kendo-dropdownlist [data]="allFleetTypesData"
                            textField="bt_fleettype"
                            valueField="bt_fleettypeid"
                            [filterable]="true"
                            (filterChange)="handleTypesFilter($event)"
                            id="addModelType"
                            name="addModelType"
                            #varAddModelType="ngModel"
                            required
                            [ngModel]>
        </kendo-dropdownlist>
        <!-- Validation -->
        <div class="alert alert-danger"
             *ngIf="varAddModelType.touched && !varAddModelType.valid">
          <div *ngIf="varAddModelType.errors?.required">Field is required!</div>
        </div>
      </div>

      <div class="form-group">
        <label for="addModelTransmission">Transmission Type:</label>
        <kendo-dropdownlist [data]="allTransmissionTypes"
                            textField="text"
                            valueField="value"
                            id="addModelTransmission"
                            name="addModelTransmission"
                            #varAddModelTransmission="ngModel"
                            required
                            [ngModel]>
        </kendo-dropdownlist>

        <!-- Validation -->
        <div class="alert alert-danger"
             *ngIf="varAddModelTransmission.touched && !varAddModelTransmission.valid">
          <div *ngIf="varAddModelTransmission.errors?.pattern">Field is required!</div>
        </div>
      </div>

      <div class="form-group">
        <label for="addModelTare">Tare:</label>
        <kendo-numerictextbox id="addModelTare"
                              name="addModelTare"
                              #varAddModelTare="ngModel"
                              required
                              placeholder="0.00"
                              step="0.01"
                              (valueChange)="validateModelTare(varAddModelTare)"
                              [ngModel]>
        </kendo-numerictextbox>

        <!-- Validation -->
        <div class="alert alert-danger"
             *ngIf="varAddModelTare.touched && !varAddModelTare.valid">
          <div *ngIf="varAddModelTare.errors.required">Field is required!</div>
        </div>
      </div>

      <div class="form-group">
        <label for="addModelFuelType">Fuel Type:</label>
        <kendo-dropdownlist [data]="allFuelTypes"
                            textField="text"
                            valueField="value"
                            id="addModelFuelType"
                            name="addModelFuelType"
                            #varAddModelFuelType="ngModel"
                            required
                            [ngModel]>
        </kendo-dropdownlist>

        <!-- Validation -->
        <div class="alert alert-danger"
             *ngIf="varAddModelFuelType.touched && !varAddModelFuelType.valid">
          <div *ngIf="varAddModelFuelType.errors?.pattern">Field is required!</div>
        </div>
      </div>

      <div class="form-group">
        <label for="addModelTankCapacity">Tank Capacity:</label>
        <kendo-numerictextbox id="addModelTankCapacity"
                              name="addModelTankCapacity"
                              #varAddModelTankCapacity="ngModel"
                              required
                              placeholder="0"
                              step="0"
                              (valueChange)="validateModelTankCapacity(varAddModelTankCapacity)"
                              [ngModel]>
        </kendo-numerictextbox>


        <!-- Validation -->
        <div class="alert alert-danger"
             *ngIf="varAddModelTankCapacity.touched && !varAddModelTankCapacity.valid">
          <div *ngIf="varAddModelTankCapacity.errors.required">Field is required!</div>
        </div>
      </div>
    </div>
  </form>
</verde-modal>
<!-- #endregion Assign Fleet Modal -->
<!-- #region Confirmation Modal -->
<verde-modal modalTitle="Confirmation"
             id="confirmFleetModelModal"
             modalMaxWidth="400"
             [hasCancelButton]="false"
             submitButton="Continue"
             (actionButton)="hideModal()">
  <div style="position: relative; height: 60px">
    <ngx-spinner [disableAnimation]="disableAnimation"
                 name="confirmfleetmodels"
                 bdColor="rgb(237, 237, 237)"
                 size="default"
                 color="#6264a7"
                 type="ball-clip-rotate"
                 [fullScreen]="false">

    </ngx-spinner>

    <p>{{ confirmationMessage }}</p>
  </div>
</verde-modal>
<!-- #endregion Confirmation Modal -->