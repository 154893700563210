/* tslint:disable */
/* eslint-disable */
export interface WorkspaceShortcut {
  '_bt_appconfig_value'?: null | string;
  '_bt_legalentity_value'?: null | string;
  '_bt_role_value'?: null | string;
  bt_external?: null | boolean;
  bt_sortcutlink?: null | string;
  bt_verdemyworkspaceshortcut?: null | string;
  bt_verdemyworkspaceshortcutid?: null | string;
  odataAppConfig?: null | string;
  odataLegalEntity?: null | string;
  odataRole?: null | string;
}
