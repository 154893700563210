/* tslint:disable */
/* eslint-disable */
import { ProcessesSharedLookupExtrapolate } from './processes-shared-lookup-extrapolate';
export interface ProcessesVerdeAnnotations {
  annotationid?: string;
  createdby?: ProcessesSharedLookupExtrapolate;
  createdon?: null | string;
  createdonbehalfby?: ProcessesSharedLookupExtrapolate;
  documentbody?: null | string;
  filename?: null | string;
  filesize?: null | number;
  isdocument?: null | boolean;
  mimetype?: null | string;
  notetext?: null | string;
  objectid?: ProcessesSharedLookupExtrapolate;
  objecttypecode?: null | string;
  prefix?: null | string;
  stepid?: null | string;
  subject?: null | string;
}
