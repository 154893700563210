import { NgModule } from '@angular/core';
import { ManualDocumentsRoutingModule } from './manual-documents-routing.module';
import { SharedModule } from '@verde/shared';

// Components
import { EmployeeDocumentsComponent } from './components/employee-documents/employee-documents.component';
import { FinancialDocumentsComponent } from './components/financial-documents/financial-documents.component';
import { PersonalTaxDocumentsComponent } from './components/personal-tax-documents/personal-tax-documents.component';
import { WfhDocumentsComponent } from './components/wfh-documents/wfh-documents.component';
import { PayrollDocumentsComponent } from './components/payroll-documents/payroll-documents.component';
import { OtherDocumentsComponent } from './components/other-documents/other-documents.component';
import { GroupDocumentsComponent } from './components/group-documents/group-documents.component';
import { DropdownlistFilterComponent } from './components/dropdownlist-filter/dropdownlist-filter.component';
import { BulkDocsComponent } from './components/bulk-docs/bulk-docs.component';


@NgModule({
  declarations: [
    EmployeeDocumentsComponent,
    FinancialDocumentsComponent,
    PersonalTaxDocumentsComponent,
    WfhDocumentsComponent,
    PayrollDocumentsComponent,
    OtherDocumentsComponent,
    GroupDocumentsComponent,
    DropdownlistFilterComponent,
    BulkDocsComponent
  ],
  imports: [ManualDocumentsRoutingModule, SharedModule],
})
export class ManualDocumentsModule {}
