import { Component, Input, Output, EventEmitter } from '@angular/core';
import { PeerReviewFeedback } from '@verde/shared';
import { zoomInOnEnterAnimation, zoomOutOnLeaveAnimation } from 'angular-animations';

interface SelectOption {
  value: number;
  text: string;
  selected: boolean;
}

@Component({
  selector: 'verde-rating-option',
  templateUrl: './rating-option.component.html',
  styleUrls: ['./rating-option.component.scss'],
  animations: [zoomInOnEnterAnimation({ anchor: 'selected', duration: 300, delay: 100 }), zoomOutOnLeaveAnimation({ anchor: 'deselected', duration: 300 })],
})
export class RatingOptionComponent {
  @Input() set setReviewOption(val: PeerReviewFeedback) {
    this.reviewOption = val;
    this.setOptions();
  }

  @Output() answerSelected: EventEmitter<PeerReviewFeedback> = new EventEmitter();

  reviewOption: PeerReviewFeedback = {};
  allOptions: SelectOption[] = [];
  selectedValue: number;

  setOptions(): void {
    this.selectedValue = this.reviewOption.bt_rating || this.reviewOption.bt_rating === 0 ? this.reviewOption.bt_rating : null;
    this.reviewOption.bt_minvalue = this.reviewOption.bt_minvalue < 0 ? 0 : this.reviewOption.bt_minvalue;

    for (let i = this.reviewOption.bt_minvalue; i <= this.reviewOption.bt_maxvalue; i++) {
      this.allOptions.push({
        value: i,
        selected: this.selectedValue === i,
        text: `${i}`,
      });
    }
  }

  setSelected(passOption: SelectOption): void {
    for (const option of this.allOptions) {
      option.selected = false;
    }

    passOption.selected = true;
    this.selectedValue = passOption.value;
    this.answerSelected.emit({ ...this.reviewOption, bt_rating: this.selectedValue });
  }
}
