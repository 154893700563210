import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
// Store
//Related
import * as fromProfileRelatedReducer from '../store/reducers/profile-related.reducer';
// Equipment Request Detail
import * as QualificationInstitutionLinkActions from '../store/actions/qualification-institution-link.actions';
import * as fromQualificationInstitutionLinkReducer from '../store/reducers/qualification-institution-link.reducer';
import { QualificationsGetQualficationInstitutionLinkQuery, QualificationsCreateQualficationInstitutionLinkCommand } from '@verde/shared';

@Injectable({
  providedIn: 'root',
})
export class QualificationInstitutionLinkService {
  // QualificationInstitutionLink
  qualificationInstitutionLinkLoading$: Observable<boolean>;
  qualificationInstitutionLinkObjects$: Observable<any[]>;

  constructor(private store: Store<fromProfileRelatedReducer.ProfileRelatedState>) {
    // QualificationInstitutionLink
    this.qualificationInstitutionLinkLoading$ = this.store.pipe(select(fromQualificationInstitutionLinkReducer.isQualificationInstitutionLinkLoading));
    this.qualificationInstitutionLinkObjects$ = this.store.pipe(select(fromQualificationInstitutionLinkReducer.getQualificationInstitutionLink));
  }

  // QualificationInstitutionLink Detail
  // Get
  getQualificationInstitutionLink(body: QualificationsGetQualficationInstitutionLinkQuery) {
    this.store.dispatch(new QualificationInstitutionLinkActions.QualificationInstitutionLinkGetAll(body));
  }

  // Clear
  qualificationClear() {
    this.store.dispatch(new QualificationInstitutionLinkActions.QualificationInstitutionLinkClear(null));
  }

  // Create
  uploadQualificationInstitutionLink(payload: QualificationsCreateQualficationInstitutionLinkCommand) {
    this.store.dispatch(new QualificationInstitutionLinkActions.QualificationInstitutionLinkUpload(payload));
  }
}
