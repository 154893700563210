/* tslint:disable */
/* eslint-disable */
export enum BtPositionseat {
  New = 'New',
  Requested = 'Requested',
  Vacant = 'Vacant',
  Filled = 'Filled',
  NotApplicable = 'NotApplicable',
  Resigned = 'Resigned'
}
