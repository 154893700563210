/* tslint:disable */
/* eslint-disable */
export interface BtFleetItemMovementHistory {
  '_bthr_make_value'?: null | string;
  '_bthr_model_value'?: null | string;
  bt_assetno?: null | string;
  bt_modelyear?: null | string;
  bthr_licensenumber?: null | string;
  bthr_vehiclesid?: null | string;
  odataMake?: null | string;
  odataModel?: null | string;
}
