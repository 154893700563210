import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-object-type',
  template: `<div class="mb-3">
    <h6 *ngIf="props.label">{{ props.label }}</h6>
    <hr *ngIf="props.label" />
    <kendo-label *ngIf="props.description">{{ props.description }}</kendo-label>
    <div class="alert alert-danger" role="alert" *ngIf="showError && formControl.errors"></div>
    <formly-field *ngFor="let f of field.fieldGroup" [field]="f"></formly-field>
  </div>`,
})
export class ObjectTypeComponentKendo extends FieldType {}
