/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserApiConfiguration, UserApiConfigurationParams } from './user-api-configuration';

import { UserApiConfigService } from './services/user-api-config.service';
import { UserApiUserService } from './services/user-api-user.service';
import { UserApiVersionService } from './services/user-api-version.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    UserApiConfigService,
    UserApiUserService,
    UserApiVersionService,
    UserApiConfiguration
  ],
})
export class UserApiModule {
  static forRoot(params: UserApiConfigurationParams): ModuleWithProviders<UserApiModule> {
    return {
      ngModule: UserApiModule,
      providers: [
        {
          provide: UserApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: UserApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('UserApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
