/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { SupportBaseService } from '../support-base-service';
import { SupportApiConfiguration } from '../support-api-configuration';
import { SupportStrictHttpResponse } from '../support-strict-http-response';
import { SupportRequestBuilder } from '../support-request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { SupportGetSupportQuery } from '../models/support-get-support-query';
import { SupportPostSupportCommand } from '../models/support-post-support-command';
import { SupportSupportExample } from '../models/support-support-example';

@Injectable({
  providedIn: 'root',
})
export class SupportApiSupportService extends SupportBaseService {
  constructor(
    config: SupportApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getSupport
   */
  static readonly GetSupportPath = '/api/Support/get/support';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSupport()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getSupport$Response(params?: {
    tenantId?: string;
    body?: SupportGetSupportQuery
  }): Observable<SupportStrictHttpResponse<Array<SupportSupportExample>>> {

    const rb = new SupportRequestBuilder(this.rootUrl, SupportApiSupportService.GetSupportPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as SupportStrictHttpResponse<Array<SupportSupportExample>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSupport$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getSupport(params?: {
    tenantId?: string;
    body?: SupportGetSupportQuery
  }): Observable<Array<SupportSupportExample>> {

    return this.getSupport$Response(params).pipe(
      map((r: SupportStrictHttpResponse<Array<SupportSupportExample>>) => r.body as Array<SupportSupportExample>)
    );
  }

  /**
   * Path part for operation postSupport
   */
  static readonly PostSupportPath = '/api/Support/post/support';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postSupport()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postSupport$Response(params?: {
    tenantId?: string;
    body?: SupportPostSupportCommand
  }): Observable<SupportStrictHttpResponse<boolean>> {

    const rb = new SupportRequestBuilder(this.rootUrl, SupportApiSupportService.PostSupportPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as SupportStrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postSupport$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postSupport(params?: {
    tenantId?: string;
    body?: SupportPostSupportCommand
  }): Observable<boolean> {

    return this.postSupport$Response(params).pipe(
      map((r: SupportStrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

}
