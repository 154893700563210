/* tslint:disable */
/* eslint-disable */
export interface VoteControl {
  '_bt_employee_value'?: null | string;
  '_bt_groupvote_value'?: null | string;
  bt_closingdate?: null | string;
  bt_groupvotingstage?: null | number;
  bt_groupvotingstatus?: null | number;
  bt_nominated?: null | boolean;
  bt_votecontrol?: null | string;
  bt_votecontrolid?: null | string;
  bt_voted?: null | boolean;
  odataVoteGroup?: null | string;
}
