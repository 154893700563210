import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyJsonschema } from '@ngx-formly/core/json-schema';
import { Subject, take, takeUntil } from 'rxjs';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { EmployeeFunds, FundOptionChanges, FundOptions, SharedApiFundsService, VerdeApprovalService } from '@verde/shared';

@Component({
  selector: 'verde-employee-funds',
  templateUrl: './employee-funds.component.html',
  styleUrls: ['./employee-funds.component.scss'],
})
export class EmployeeFundsComponent implements OnInit {
  private onDestroy$ = new Subject<boolean>();
  //Employee funds
  options: FormlyFormOptions;
  columnOneForm: FormGroup;
  columnOneFormfields: FormlyFieldConfig[];
  columnOneModel: any;
  fundOptions: FundOptions[];
  fundOptionPercentage: any;
  hideDropdown: boolean = false;
  selectedFund: EmployeeFunds;
  fundOptionChanges: FundOptionChanges;
  newFundOption: any;
  newFundOptionID: string;
  existingOptionInt: number;
  isDisabled = false;
  schemaString = '{ ... }';
  schemaObject: any;
  showPDFpreview = false;

  constructor(
    private formlyJsonschema: FormlyJsonschema,
    private http: HttpClient,
    private sharedApiFundsService: SharedApiFundsService,
    private sidebarService: VerdeApprovalService,
  ) {}

  ngOnInit(): void {
    this.columnOneModel = {};
    this.sidebarService
      .getSelectedFund()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((fund: EmployeeFunds) => {
        this.selectedFund = fund;
      });
    this.getFundOptions();
    this.isDisabled = !this.selectedFund.bthr_Fund?.bt_updatedallowed;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  getFundOptions() {
    this.sharedApiFundsService
      .getFundOptions({ fundID: this.selectedFund._bthr_fund_value })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          let filteredFunds = ret.filter((t) => {
            if (t.bt_description?.toLowerCase() !== 'not available') {
              return t;
            }
          });

          this.fundOptions = filteredFunds.sort((a, b) => {
            if (a.bt_fundoption && b.bt_fundoption) {
              const nameA = a.bt_fundoption.toLowerCase();
              const nameB = b.bt_fundoption.toLowerCase();
              if (nameA < nameB) {
                //sort string ascending
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
            }

            return 0;
          });

          this.fundOptionPercentage = this.fundOptions.map((amount) => amount.bt_fundoption);
        },
        (error) => {
          console.error(error);
        },
        () => {
          this.loadJson();
        },
      );
  }

  //employee funds
  loadJson() {
    this.schemaString = this.selectedFund.bthr_Fund?.bt_updatejson;
    const schemaObject = JSON.parse(this.schemaString);

    schemaObject.schemaColumnOne.properties.changeOption.properties.options.enum = this.fundOptionPercentage;
    schemaObject.schemaColumnOne.properties.FundName.default = this.selectedFund.odatafund;
    schemaObject.schemaColumnOne.properties.FundOption.default = this.selectedFund.odataFundOption;

    if (this.selectedFund.bthr_Fund?.bt_updatedallowed === false) {
      schemaObject.schemaColumnOne.properties.changeOption.properties.options.readOnly = true;
    } else {
      schemaObject.schemaColumnOne.properties.changeOption.properties.options.readOnly = false;
    }

    this.columnOneForm = new FormGroup({});
    this.columnOneFormfields = [this.formlyJsonschema.toFieldConfig(schemaObject.schemaColumnOne)];
    this.columnOneModel = schemaObject.columnOneModel;

    this.options = {};

    //this.http
    //  .get<any>('/assets/json-templates/employee-funds.json')
    //  .pipe(
    //    tap(({ schemaColumnOne, columnOneModel, MenuOptions }) => {

    //      schemaColumnOne.properties.changeOption.properties.options.enum = this.fundOptionPercentage
    //      schemaColumnOne.properties.FundName.default = this.selectedFund.odatafund;
    //      schemaColumnOne.properties.FundOption.default = this.selectedFund.odataFundOption;

    //      // Makes the Options disablable
    //      if (this.selectedFund.bthr_Fund?.bt_updatedallowed === false) {
    //        schemaColumnOne.properties.changeOption.properties.options.readOnly = true;
    //      } else {
    //        schemaColumnOne.properties.changeOption.properties.options.readOnly = false;
    //      }

    //      this.columnOneForm = new FormGroup({});
    //      this.columnOneFormfields = [this.formlyJsonschema.toFieldConfig(schemaColumnOne)];
    //      this.columnOneModel = columnOneModel;

    //      this.options = {};

    //    }),
    //    takeUntil(this.onDestroy$),
    //  )
    //  .subscribe();
  }

  //postFundOptionChange(postBody: any) {

  //  this.existingOptionInt = parseFloat(postBody.FundOption.replace(/[^\d.]/g, ''));

  //  this.fundOptions.forEach(element => {
  //    if (element.bt_percentage === postBody.changeOption.options) {
  //      this.newFundOptionID = element.bt_fundoptionsid;
  //    }
  //  })

  //  let todayDate = new Date;
  //  let changes: FundOptionChanges = {
  //    odataPostLegalEntity: '/bthr_legalentitieses(' + this.userService.user.legalEntityId + ')',
  //    odataPostEmployee: '/bthr_employees(' + this.userService.user.employeeId + ')',
  //    odataPostFund: '/bthr_funds(' + this.selectedFund._bthr_fund_value + ')',
  //    odataPostExistingOption: '/bt_fundoptionses(' + this.selectedFund._bt_fundoption_value + ')',
  //    odataPostNewOption: '/bt_fundoptionses(' + this.newFundOptionID + ')',
  //    bt_existing: this.existingOptionInt,
  //    bt_new: postBody.changeOption.options,
  //    bt_effectivedate: moment(todayDate).format('YYYY-MM-DDTHH:mm:ss[Z]'),
  //    odataPostEmployeeFund: '/bthr_employeefunds(' + this.selectedFund.bthr_employeefundid + ')',
  //  }

  //  this.sharedApiFundsService.postFundOptionChanges({ body: changes })
  //    .pipe(take(1))
  //    .subscribe(
  //      (ret) => {

  //      },
  //      (error) => {
  //        console.error(error);
  //      },
  //      () => {

  //       this.patchEmployeeFund();

  //      },
  //    );
  //}

  //patchEmployeeFund() {

  //  let patchBody: EmployeeFunds = {};
  //  let recordID = this.selectedFund.bthr_employeefundid
  //  patchBody.bt_contribution = 10;
  //  patchBody.odataPostFundOption = '/bt_fundoptionses(' + this.newFundOptionID + ')';

  //  this.sharedApiFundsService.patchEmployeeFunds({ body: patchBody, recordID: recordID })
  //    .pipe(take(1))
  //    .subscribe(
  //      (ret) => {

  //      },
  //      (error) => {
  //        console.error(error);
  //      },
  //      () => {
  //        this.refreshService.notifyApiCallCompleted();
  //      },
  //    );
  //}

  submit() {
    this.existingOptionInt = parseFloat(this.columnOneModel.FundOption.replace(/[^\d.]/g, ''));

    this.fundOptions.forEach((element) => {
      if (element.bt_fundoption === this.columnOneModel.changeOption.options) {
        this.newFundOptionID = element.bt_fundoptionsid;
      }
    });
    /* this.modalService.open("confirmFundOptionChange");*/
    this.newFundOption = this.columnOneModel.changeOption.options;
    //alert(JSON.stringify(this.columnOneModel));
    this.showPDFpreview = true;
  }

  //confirm() {
  //  this.sidebarService.setShowSidebar(false);
  //  this.postFundOptionChange(this.columnOneModel);
  //}
}
