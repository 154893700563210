/* tslint:disable */
/* eslint-disable */
export interface ApprovalDataList {
  approvalHeader?: null | string;
  group?: null | string;
  legalEntity?: null | string;
  requestor?: null | string;
  rowID?: null | string;
  tableField?: null | string;
  tableName?: null | string;
}
