import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-input',
  template: `
              <kendo-label *ngIf="props.description">{{ props.description }}</kendo-label>
  <kendo-numerictextbox [id]="field.key"
        [min]="0"
        [max]="100">
  </kendo-numerictextbox> `,
})
export class FormlyNumberInputComponentKendo extends FieldType {}
