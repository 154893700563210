import { Component, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { VerdeApprovalService } from '../../../../../services/verde-approval/verde-approval.service';

import { BenchmarkService } from '../../../../../../../../../../apps/verde/src/app/features/profile2/services/benchmark.service';
import { DotsMenuItem } from '../../../../../models/dots-action-item';

@Component({
  selector: 'verde-template-view',
  templateUrl: './template-view.component.html',
  styleUrls: ['./template-view.component.scss'],
})
export class TemplateViewComponent implements OnInit {
  //Three Dot Menus
  dotMenuItems: DotsMenuItem[] = [];
  treelistMenuItems: DotsMenuItem[] = [];

  public selectedTemplateLink: any;
  public selectedTemplateType: string;
  public selectedTemplateName: string;
  public selectedLegalEntityID: string;
  public benchmarks: any;

  private onDestroy$ = new Subject<boolean>();

  constructor(private sidebarService: VerdeApprovalService, private benchmarkService: BenchmarkService) {}

  ngOnInit(): void {
    this.selectedTemplateLink = [];

    this.sidebarService
      .getSelectedTemplate()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((template: any) => {
        this.selectedTemplateLink = template;
      });

    this.sidebarService
      .getSelectedTemplateName()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((templateName: any) => {
        this.selectedTemplateName = templateName;
      });

    this.sidebarService
      .getSelectedTemplateType()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((templateType: any) => {
        this.selectedTemplateType = templateType;
      });

    this.sidebarService
      .getSelectedLegalEntityID()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((LegalEntityID: any) => {
        this.selectedLegalEntityID = LegalEntityID;
      });

    console.log(this.selectedTemplateLink);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  treelistMenuClicked(groupID: any) {
    console.log(groupID);
    this.treelistMenuItems = [];
    if (groupID.benchmarks.lenght === 0) {
      this.treelistMenuItems.push({
        text: 'No Available Benchmarks',
      });
    } else {
      groupID.benchmarks.forEach((item) => {
        this.treelistMenuItems.push({
          text: item.bt_reviewbenchmark,
        });
      });
    }
  }
}
