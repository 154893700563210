
<div style="margin-top: 20px">

  <kendo-treelist kendoTreeListExpandable
                  [kendoTreeListHierarchyBinding]="selectedTemplateLink"
                  childrenField="contents"
                  [sortable]="true"
                  [height]="850"

                  >

    
    <kendo-treelist-column [expandable]="true"
                           field="name"
                           title="Groups / Subgroups / Measures"
                           [width]="300">
    </kendo-treelist-column>
    <kendo-treelist-column field="type" title="Type" [width]="100">
    </kendo-treelist-column>
  </kendo-treelist>


  <!--<kendo-treelist kendoTreeListExpandable
                  [kendoTreeListHierarchyBinding]="selectedTemplateLink"
                  childrenField="measures"
                  [sortable]="true"
                  [height]="850">
    <kendo-treelist-column [expandable]="true"
                           field="group"
                           title="Groups / Subgroups / Measures"
                           [width]="300">
    </kendo-treelist-column>
    <kendo-treelist-column field="type" title="Type" [width]="100">
    </kendo-treelist-column>
  </kendo-treelist>-->
  <!--<kendo-treelist kendoTreeListExpandable
                  [kendoTreeListHierarchyBinding]="selectedTemplateLink"
                  childrenField="subgroups"
                  [sortable]="true"
                  [height]="850">
    <kendo-treelist-column [expandable]="true"
                           field="group"
                           title="Groups / Subgroups / Measures"
                           [width]="300">
    </kendo-treelist-column>
    <kendo-treelist-column [expandable]="true"
                           field="subgroups"
                           title="Subgroups / Measures"
                           [width]="300">
    </kendo-treelist-column>
    <kendo-treelist-column field="measures" title="Measures" [width]="100">
    </kendo-treelist-column>
  </kendo-treelist>-->

</div>

