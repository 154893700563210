/* tslint:disable */
/* eslint-disable */
import { DocumentBtPayrollmportDetail } from './document-bt-payrollmport-detail';
import { DocumentEntityReference } from './document-entity-reference';
export interface DocumentImportPayrollHeader {
  bt_entitymapping?: null | string;
  bt_headerurl?: null | string;
  detailFile?: boolean;
  details?: null | Array<DocumentBtPayrollmportDetail>;
  integrationSource?: DocumentEntityReference;
  legalEntityID?: DocumentEntityReference;
  overwrite?: boolean;
  period?: number;
  periodYear?: null | string;
  recordID?: string;
  taxYear?: null | string;
}
