import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { RefreshService } from '../../../services/refresh-service';
import { ProcessesPositionSeats, ProcessesApiProcessesService, ProcessesValidateTaxNumberQuery } from '@verde/shared';

@Component({
  selector: 'verde-onboarding-tax-info-edit',
  templateUrl: './onboarding-tax-info-edit.component.html',
  styleUrls: ['./onboarding-tax-info-edit.component.scss'],
})
export class OnboardingTaxInfoComponentEdit implements OnInit {
  private onDestroy$ = new Subject<boolean>();

  @Output() validateTaxNumberEvent = new EventEmitter<void>();
  @Input() positionSeat: ProcessesPositionSeats | null = null;
  @Input() public paymentDetails: FormGroup;
  @Input() taxValidation: string;
  taxValidationMessage: string = '';
  validateEnabled: boolean = true;

  constructor(private refreshService: RefreshService, private processesApiProcessesService: ProcessesApiProcessesService) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  public onIDChange(value: string): void {
    if (value != this.positionSeat.bthr_Employee.bthr_nationalidnumber) {
      this.validateEnabled = false;
    } else this.validateEnabled = true;
  }

  validateTaxNumber() {
    this.refreshService.sharedValue$.subscribe((value) => {
      this.taxValidation = value;
    });
    let validation: ProcessesValidateTaxNumberQuery = {
      countryName: this.taxValidation,
      taxNumber: this.paymentDetails.value.taxNo,
    };

    this.processesApiProcessesService
      .validateTaxNumber({ body: validation })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (val) => {
          this.taxValidationMessage = val;
        },
        () => {},
      );
  }
}
