import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TabStripComponent } from '@progress/kendo-angular-layout';
import { Subject, take, takeUntil } from 'rxjs';
import { UserService } from '@verde/core';

@Component({
  selector: 'verde-financial-documents',
  templateUrl: './financial-documents.component.html',
  styleUrls: ['./financial-documents.component.scss'],
})
export class FinancialDocumentsComponent implements OnInit, AfterViewInit, OnDestroy {
  private onDestroy$ = new Subject<boolean>();

  @ViewChild('financialDocumentTabstrip') public financialDocumentTabstrip: TabStripComponent;

  disableAnimation: boolean = false;
  constructor(private userService: UserService, private route: ActivatedRoute, private router: Router, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.userService.disableAnimation$.pipe(takeUntil(this.onDestroy$)).subscribe((data) => {
      this.disableAnimation = data;
    });
  }

  ngAfterViewInit(): void {
    this.retrieveParams();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  onTabSelect(e) {
    if (e.index === 0) {
      this.router.navigate(['/document', 'financial/personal-tax']);
    } else if (e.index === 1) {
      this.router.navigate(['/document', 'financial/other']);
    }
  }

  private retrieveParams(): void {
    this.route.params.pipe(take(1)).subscribe(
      (params: { index: string }) => {
        if (params.index === 'financial/personal-tax') {
          this.financialDocumentTabstrip.selectTab(0);
        } else if (params.index === 'financial/other') {
          this.financialDocumentTabstrip.selectTab(1);
        }

        this.cdr.detectChanges();
      },
      (error) => {
        console.error(error);
      },
    );
  }
}
