/* tslint:disable */
/* eslint-disable */
import { User } from './user';
export interface ManagerVaccinationInfo {
  bt_EmployeeID?: User;
  bt_accepteddate?: null | string;
  bt_employeeriskmanagementid?: null | string;
  bt_responded?: null | string;
  bt_vaccinationdate?: null | string;
  bt_vaccinationrequestaccepted?: null | boolean;
  bt_vaccinationrequired?: null | boolean;
  bt_vaccinationstatus?: null | number;
  odataRequestAccepted?: null | string;
  odataVaccinationRequired?: null | string;
  odataVaccinationStatus?: null | string;
}
