/* eslint-disable no-console */
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, takeUntil } from 'rxjs';
import { UserAppConfigDto, SharedApiVerdeRequestsService, VerdeRequest, VerdeRequestType } from '@verde/shared';
import { UserService } from '@verde/core';
import { environment } from '@environments-verde/environment';

@Injectable({
  providedIn: 'root',
})
export class RequestsService implements OnDestroy {
  private onDestroy$ = new Subject<boolean>();
  config: UserAppConfigDto = {};

  constructor(private userService: UserService, private sharedApiVerdeRequestsService: SharedApiVerdeRequestsService) {
    this.userService.config$.pipe(takeUntil(this.onDestroy$)).subscribe((config) => {
      this.config = config;
    });
  }

  // Testing out new requests
  fetchAllEmployeeRequests(): Observable<VerdeRequest[] | null> {
    try {
      const { employeeId, legalEntityId } = this.userService.user;
      return this.sharedApiVerdeRequestsService.getAllVerdeRequestsForEmployee({ userID: employeeId, legalEntityID: legalEntityId });
    } catch (error) {
      console.error('Error with fetchAllEmployeeRequests: ', error);
    }
  }

  fetchAllRequestTypes(): Observable<VerdeRequestType[] | null> {
    try {
      if (this.config?.legalEntityConfig?.enableRequests) {
        return this.sharedApiVerdeRequestsService.getAllRequestTypes({
          email: this.userService.user.email,
          environmentURL: this.userService.verdeEnvironmentConfig?.dataverseUrl,
          slot: environment.slot,
        });
      }
      return null;
    } catch (error) {
      console.error('Error with fetchAllEmployeeRequests: ', error);
    }
  }

  fetchAllNonGenericRequestTypes(nonGenericType: string, roleID: string, legalEntityID: string): Observable<VerdeRequestType[] | null> {
    try {
      return this.sharedApiVerdeRequestsService.getVerdeRequestTypesNonGeneric({
        roleID: roleID,
        legalEntityID: legalEntityID,
        nonGenericType: nonGenericType,
      });
    } catch (error) {
      console.error('Error with fetchAllNonGenericRequestTypes: ', error);
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
