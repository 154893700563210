/* tslint:disable */
/* eslint-disable */
import { ProcessesBtEmployeeLifecycleStage } from './processes-bt-employee-lifecycle-stage';
import { ProcessesBtEmployeeStatusEnum } from './processes-bt-employee-status-enum';
export interface ProcessesEmployeeOnboarding {
  bt_EmployeeLifecycleStage?: ProcessesBtEmployeeLifecycleStage;
  bt_EmployeeStatus?: ProcessesBtEmployeeStatusEnum;
  bt_citizenshipstatus?: string;
  bt_countryofresidence?: string;
  bt_localresidencystatus?: string;
  bt_nationality?: string;
  bt_neworganizationjoindate?: null | string;
  bt_probationtype?: string;
  bt_shifttype?: string;
  bt_taxnoouts?: null | boolean;
  bthr_employee_id?: null | string;
  bthr_employeeid?: string;
  bthr_employeetype?: string;
  bthr_firstname?: null | string;
  bthr_foreignnational?: null | boolean;
  bthr_lastname?: null | string;
  bthr_legalentity?: string;
  bthr_middlenames?: null | string;
  bthr_nationalidnumber?: null | string;
  bthr_preferredname?: null | string;
  bthr_taxnumber?: null | string;
  hasPassport?: null | boolean;
  passportCountry?: string;
  passportExpDate?: null | string;
  passportNumber?: null | string;
  seat?: string;
}
