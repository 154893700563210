import { Action } from '@ngrx/store';
import { TemplateGetGroupsQuery, TemplateUploadGroupCommand, TemplatePatchTemplateGroupCommand } from '@verde/shared';

export enum GroupsActionTypes {
  // Get GROUPS
  GROUPS_GET_ALL = '[GROUPS] Get All Start',
  GROUPS_GET_ALL_SUCCESS = '[GROUPS] Get All Start Success',
  GROUPS_GET_ALL_ERROR = '[GROUPS] Get All Start Error',

  // UPDATE GROUPS
  GROUPS_UPDATE = '[GROUPS] UPDATE',
  GROUPS_UPDATE_SUCCESS = '[GROUPS] UPDATE Success',
  GROUPS_UPDATE_ERROR = '[GROUPS] UPDATE Error',

  // GROUPS Add
  GROUPS_UPLOAD = '[GROUPS] Upload',
  GROUPS_UPLOAD_SUCCESS = '[GROUPS] Upload Success',
  GROUPS_UPLOAD_ERROR = '[GROUPS] Upload Error',

  // Clear All GROUPS Items
  GROUPS_ITEM_CLEAR = '[GROUPS] Clear',
}

// Get Group
export class GroupsGet implements Action {
  readonly type = GroupsActionTypes.GROUPS_GET_ALL;
  constructor(public body: TemplateGetGroupsQuery) {}
}

export class GroupsGetSuccess implements Action {
  readonly type = GroupsActionTypes.GROUPS_GET_ALL_SUCCESS;
  constructor(public payload: any[]) {}
}

export class GroupsGetError implements Action {
  readonly type = GroupsActionTypes.GROUPS_GET_ALL_ERROR;
  constructor(public payload: any) {}
}

// UPDATE GROUPS
export class GroupUpdate implements Action {
  readonly type = GroupsActionTypes.GROUPS_UPDATE;
  constructor(public payload: TemplatePatchTemplateGroupCommand) {}
}

export class GroupUpdateSuccess implements Action {
  readonly type = GroupsActionTypes.GROUPS_UPDATE_SUCCESS;
  constructor(public payload: any) {}
}

export class GroupUpdateError implements Action {
  readonly type = GroupsActionTypes.GROUPS_UPDATE_ERROR;
  constructor(public payload: any) {}
}

// GROUPS Add
export class GroupsUpload implements Action {
  readonly type = GroupsActionTypes.GROUPS_UPLOAD;
  constructor(public payload: TemplateUploadGroupCommand) {}
}

export class GroupsUploadSuccess implements Action {
  readonly type = GroupsActionTypes.GROUPS_UPLOAD_SUCCESS;
  constructor(public payload: TemplateUploadGroupCommand) {}
}

export class GroupsUploadError implements Action {
  readonly type = GroupsActionTypes.GROUPS_UPLOAD_ERROR;
  constructor(public payload: any) {}
}

// Clear All Group
export class GroupsClear implements Action {
  readonly type = GroupsActionTypes.GROUPS_ITEM_CLEAR;
  constructor(public payload: any) {}
}

export type GroupsActions =
  // Get Group
  | GroupsGet
  | GroupsGetSuccess
  | GroupsGetError
  // Set All Groups
  | GroupUpdate
  | GroupUpdateSuccess
  | GroupUpdateError
  // Groups Upload
  | GroupsUpload
  | GroupsUploadSuccess
  | GroupsUploadError
  // Clear All Group
  | GroupsClear;
