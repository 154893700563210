<kendo-grid [kendoGridBinding]="allTemplatesGridData" [loading]="wfhDocsLoading">
  <ng-template kendoGridToolbarTemplate
    style="height: 57px">
    <kendo-grid-spacer></kendo-grid-spacer>
    <img title="Refresh"
          class="refresh_icon"
          src="https://engageimages.z1.web.core.windows.net/Images/Verde/Other/reload.svg"
          alt=""
          (click)="refresh()" />
  </ng-template>

  <kendo-grid-column field="bt_templatename"
                     title="Name"></kendo-grid-column>
  <kendo-grid-column field="bt_templatename"
                     title="Action">
    <ng-template kendoGridCellTemplate
                 let-ListTable>
      <button kendoButton
              (click)="GetTemplateType(ListTable)">Select Year</button>
    </ng-template>
  </kendo-grid-column>
  <ng-template kendoGridNoRecordsTemplate> There are no documents available at this time. </ng-template>
</kendo-grid>

<!--Modals-->
<verde-modal id="DisplayYearData"
             modalTitle="{{ selectedDocument?.bt_templatename }}"
             [hasCancelButton]="false"
             [submitButton]="!inMicrosoftSignIn ? 'Continue' : ''"
             [modalMinWidth]="550">
  <div style="position: relative">
    <ngx-spinner [disableAnimation]="disableAnimation"
                 name="displayYearSpinnyBoi"
                 bdColor="rgb(237, 237, 237)"
                 size="default"
                 color="#6264a7"
                 type="ball-clip-rotate"
                 [fullScreen]="false">
    </ngx-spinner>
    <kendo-grid [kendoGridBinding]="allYearGridData"
                [ngStyle]="{ 'pointer-events': inMicrosoftSignIn ? 'none' : 'all' }">
      <kendo-grid-column field="bt_year"
                         title="Name"></kendo-grid-column>
      <kendo-grid-column field="bt_year"
                         title="Action">
        <ng-template kendoGridCellTemplate
                     let-YearValue>
          <button kendoButton
                  (click)="PopulateDocumentData(YearValue)">Open Doc</button>
        </ng-template>
      </kendo-grid-column>
      <ng-template kendoGridNoRecordsTemplate> There are no documents available at this time. </ng-template>
    </kendo-grid>
  </div>
</verde-modal>

<verde-modal id="DisplayDocModal"
             modalTitle="{{ selectedDocument?.bt_templatename }}"
             [hasCancelButton]="false"
             submitButton="Continue"
             [modalMinWidth]="550">
  <div style="position: relative">
    <ngx-spinner [disableAnimation]="disableAnimation"
                 name="displayTemplateSpinnyBoi"
                 bdColor="rgb(237, 237, 237)"
                 size="default"
                 color="#6264a7"
                 type="ball-clip-rotate"
                 [fullScreen]="false">
    </ngx-spinner>

    <div class="example-config">
      <button themeColor="primary"
              kendoButton
              (click)="SaveThePdf(pdf)"
              [disabled]="htmlHeader == null && htmlFooter == null">Save As PDF...</button>
    </div>
    <div style="height: 500px; overflow-y: auto; background-color: white">
      <kendo-pdf-export #pdf
                        paperSize="A4"
                        margin="0.5cm"
                        [scale]="0.75"
                        style="height: 100%; display: block">
        <div *ngIf="htmlHeader != null">
          <img src="{{ htmlHeader }}"
               width="80%"
               height="80%" />
        </div>
        <div id="HtmlTemplateContent"
             style="padding: 20px"></div>
        <div *ngIf="htmlFooter != null">
          <img src="{{ htmlFooter }}"
               width="100%"
               height="80%" />
        </div>
      </kendo-pdf-export>
    </div>
  </div>
</verde-modal>

<verde-id-validation *ngIf="showIDValidationModal"
                     (modalClosed)="showIDValidationModal = $event"
                     (modalSuccess)="validationSuccess($event)">
</verde-id-validation>