<div class="left-section">
  <div class="logo-container">
    <img src="assets/logo/logo.svg"
         alt="" />
    <span class="verde-logo-name">Verde</span>
  </div>
</div>

<div class="right-section">
  <mgt-person id="person"
              *ngIf="userId"
              [userId]="userId"
              is-expanded="true"
              person-card="hover">
    <template data-type="person-card">
      <mgt-person-card is-expanded="true"
                       inherit-details>
        <template data-type="additional-details">
          <div id="logoutButton"
               class="logout-button"
               (click)="logoutUser()">
            <p>Logout</p>
            <span class="k-icon k-i-logout"></span>
          </div>
        </template>
      </mgt-person-card>
    </template>
  </mgt-person>
  <div *ngIf="!userId"
       class="logout-button"
       (click)="logoutUser()">
    <p>Logout</p>
    <span class="k-icon k-i-logout"></span>
  </div>
</div>