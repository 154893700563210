/* tslint:disable */
/* eslint-disable */
import { DelegationsVerdeEmployee } from './delegations-verde-employee';
import { DelegationsBtReadyin } from './delegations-bt-readyin';
export interface DelegationsSuccessPlanManager {
  bt_internalavaible?: null | boolean;
  bt_lastreviewdate?: null | string;
  bt_readyin?: DelegationsBtReadyin;
  bt_successoridentified?: null | boolean;
  bt_successplanmanager?: null | string;
  bt_successplanmanagerid?: null | string;
  successManager?: DelegationsVerdeEmployee;
}
