<div class="request_new_grid">
  <!-- Grid Column 1 -->
  <form #requestNewForm="ngForm"
        novalidate
        autocomplete="off">

    <div class="modal_grid">

      <div class="form-group">
        <label for="requestClassification">Classification:</label>
        <kendo-dropdownlist [data]="allClassifications"
                            textField="text"
                            valueField="value"
                            id="requestClassification"
                            name="requestClassification"
                            #varRequestClassification="ngModel"
                            (valueChange)="setRequestClassificationDropdownValue($event)"
                            required
                            [ngModel]>
        </kendo-dropdownlist>

        <!-- Validation -->
        <div class="alert alert-danger"
             *ngIf="varRequestClassification.touched && !varRequestClassification.valid">
          <div *ngIf="varRequestClassification.errors?.required">Field is required!</div>
        </div>
      </div>

      <div *ngIf="requestClassificationDropdownValue === 'Pool'"
           class="form-group">
        <label for="newRequireDate">Require Date:</label>
        <kendo-datetimepicker format="yyyy/MM/dd HH:mm"
                              id="newRequireDate"
                              name="newRequireDate"
                              #varNewRequireDate="ngModel"
                              [min]="requestValidationDate"
                              [max]="maxDate"
                              required
                              [ngModel]="todaysDateNew">
        </kendo-datetimepicker>

        <!-- Validation -->
        <div class="alert alert-danger"
             *ngIf="varNewRequireDate.touched && !varNewRequireDate.valid">
          <div *ngIf="!varNewRequireDate.valid">Field is required!</div>
        </div>
      </div>

      <div class="form-group">
        <label *ngIf="requestEmployeeDropdownValue !== null || newEmployeeYes"
               style="font-size: 15px; color: #cc4a31;">Please ensure there are no duplicates:</label>
      </div>

      <div *ngIf="requestClassificationDropdownValue !== 'Pool'"
           class="form-group"
           style="grid-column: 1/3">
        <div style="display: inline-flex; margin-bottom: unset;">
          <label style="margin-bottom: unset;">For New Employee:</label>

          <div style="display: inline-flex; margin-bottom: unset; margin-left: 5px; float: right">

            <label style="margin-bottom: unset;"
                   for="newEmployeeYes">Yes:</label>
            <input style="width: 25px; height: 20px; margin: 3px 0 0 5px; vertical-align:middle;"
                   (change)="newEmployeeYesChange($event.target.checked, varNewEmployeeNo)"
                   type="checkbox"
                   kendoCheckBox
                   class="form-control"
                   id="newEmployeeYes"
                   name="newEmployeeYes"
                   #varNewEmployeeYes="ngModel"
                   [ngModel]="newEmployeeYes">

            <label for="newEmployeeNo"
                   style="margin-left: 5px; margin-bottom: unset;">No:</label>
            <input style="width: 25px; height: 20px; margin: 3px 0 0 5px; vertical-align:middle;"
                   (change)="newEmployeeNoChange($event.target.checked, varNewEmployeeYes)"
                   type="checkbox"
                   kendoCheckBox
                   class="form-control"
                   id="newEmployeeNo"
                   name="newEmployeeNo"
                   #varNewEmployeeNo="ngModel"
                   [ngModel]="newEmployeeNo">

          </div>
        </div>
      </div>

      <!-- #region Request Date Logic -->

      <div *ngIf="requestClassificationDropdownValue === 'Company'"
           class="form-group">
        <label for="newRequireDate">Require Date:</label>
        <kendo-datepicker id="newRequireDate"
                          name="newRequireDate"
                          #varNewRequireDate="ngModel"
                          [min]="requestValidationDate"
                          [max]="maxDate"
                          required
                          [ngModel]="requestValidationDate">
        </kendo-datepicker>
        <!-- Validation -->
        <div class="alert alert-danger"
             *ngIf="varNewRequireDate.touched && !varNewRequireDate.valid">
          <div *ngIf="!varNewRequireDate.valid">Field is required!</div>
        </div>
      </div>

      <div *ngIf="requestClassificationDropdownValue === 'Rental'"
           class="form-group">
        <label for="newRequireDate">Require Date:</label>
        <kendo-datetimepicker format="yyyy/MM/dd HH:mm"
                              id="newRequireDate"
                              name="newRequireDate"
                              #varNewRequireDate="ngModel"
                              [min]="todaysDateNew"
                              [max]="maxDate"
                              required
                              (valueChange)="setRentalEndValidationDate($event)"
                              [ngModel]="todaysDateNew">
        </kendo-datetimepicker>

        <!-- Validation -->
        <div class="alert alert-danger"
             *ngIf="varNewRequireDate.touched && !varNewRequireDate.valid">
          <div *ngIf="!varNewRequireDate.valid">Field is required!</div>
        </div>
      </div>

      <!-- #endregion Request Date Logic -->

      <div *ngIf="requestClassificationDropdownValue === 'Rental'"
           class="form-group">
        <label for="rentalEndDate">End Date:</label>
        <kendo-datetimepicker format="yyyy/MM/dd HH:mm"
                              id="rentalEndDate"
                              name="rentalEndDate"
                              #varRentalEndDate="ngModel"
                              [min]="rentalEndValidationDate"
                              [max]="maxDate"
                              required
                              [ngModel]>
        </kendo-datetimepicker>

        <!-- Validation -->
        <div class="alert alert-danger"
             *ngIf="varRentalEndDate.touched && !varRentalEndDate.valid">
          <div *ngIf="varRentalEndDate.errors?.required">Field is required!</div>
        </div>
      </div>

      <div *ngIf="newEmployeeNo && requestClassificationDropdownValue !== 'Pool'"
           class="form-group">
        <label for="requestEmployeeDropdown">Employee:</label>
        <kendo-dropdownlist [data]="allTeamMembers"
                            textField="fullNameWithEmpId"
                            valueField="employeeId"
                            id="requestEmployeeDropdown"
                            name="requestEmployeeDropdown"
                            #varRequestEmployeeDropdown="ngModel"
                            (valueChange)="setRequestEmployeeDropdownValue($event)"
                            required
                            [ngModel]>
        </kendo-dropdownlist>

        <!-- Validation -->
        <div class="alert alert-danger"
             *ngIf="varRequestEmployeeDropdown.touched && !varRequestEmployeeDropdown.valid">
          <div *ngIf="varRequestEmployeeDropdown.errors?.required">Field is required!</div>
          <div *ngIf="varRequestEmployeeDropdown.errors?.pattern">Field is required!</div>
        </div>
      </div>

      <div *ngIf="requestClassificationDropdownValue === 'Rental'"
           class="form-group">
        <label for="rentalReasonDropdown">Rental Reason:</label>
        <kendo-dropdownlist [data]="allRentalReasons"
                            textField="bt_rentalreason"
                            valueField="bt_fleetrentalreasonid"
                            id="rentalReasonDropdown"
                            name="rentalReasonDropdown"
                            #varRentalReasonDropdown="ngModel"
                            (valueChange)="setRentalReasonDropdownValue($event)"
                            required
                            [ngModel]>
        </kendo-dropdownlist>

        <!-- Validation -->
        <div class="alert alert-danger"
             *ngIf="varRentalReasonDropdown.touched && !varRentalReasonDropdown.valid">
          <div *ngIf="varRentalReasonDropdown.errors?.required">Field is required!</div>
        </div>
      </div>

      <div class="form-group"
           style="grid-column: 1/3">
        <label for="newRequestReason">Reason for Request:</label>
        <kendo-textarea id="newRequestReason"
                        name="newRequestReason"
                        #varNewRequestReason="ngModel"
                        maxlength="300"
                        resizable="vertical"
                        required
                        [ngModel]>
        </kendo-textarea>

        <!--Validation-->
        <div class="alert alert-danger"
             *ngIf="varNewRequestReason.touched && !varNewRequestReason.valid"
             style="width: 250px">
          <div *ngIf="varNewRequestReason.errors?.required">Field is required!</div>
          <div *ngIf="varNewRequestReason.errors?.minlength">Enter a detailed reason.</div>
        </div>
      </div>
    </div>
  </form>
  <!-- Grid Column 2 -->
  <div>
    <kendo-tabstrip style="height: 95.4%;">
      <!-- #region Movements Tab  -->
      <kendo-tabstrip-tab title="Movements"
                          [selected]="true">
        <ng-template kendoTabContent>
          <div style="position: relative; height: 100%;">
            <ngx-spinner [disableAnimation]="disableAnimation"
                         name="requestemployeemovementsgrid"
                         bdColor="rgb(237, 237, 237)"
                         size="default"
                         color="#6264a7"
                         type="ball-clip-rotate"
                         [fullScreen]="false">

            </ngx-spinner>

            <div *ngIf="requestClassificationDropdownValue !== 'Pool' && (requestEmployeeDropdownValue !== null || newEmployeeYes)"
                 class="reqest_new_grid_wrapper">
              <kendo-grid [kendoGridBinding]="requestEmployeeMovementsGridView"
                          [resizable]="true"
                          [groupable]="false"
                          [group]="requestEmployeeMovementsGroups"
                          (groupChange)="requestEmployeeMovementsGroupChange($event)"
                          (dataStateChange)="requestEmployeeMovementsDataStateChange($event)"
                          [selectable]="true"
                          [kendoGridSelectBy]="requestEmployeeMovementsSelectedCallback"
                          [selectedKeys]="requestEmployeeMovementsSelection"
                          [sortable]="true"
                          [sort]="requestEmployeeMovementsSort"
                          (sortChange)="requestEmployeeMovementsSortChange($event)">

                <!-- columns for larger devices -->
                <kendo-grid-column media="(min-width: 700px)"
                                   field="bt_effectivedate"
                                   title="Effective Date"
                                   width="100">
                  <ng-template kendoGridCellTemplate
                               let-ListTable>
                    {{ListTable.bt_effectivedate | date: 'yyyy/MM/dd'}}
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column media="(min-width: 700px)"
                                   field="bt_comment"
                                   title="Reason">
                </kendo-grid-column>
                <kendo-grid-column media="(min-width: 700px)"
                                   field="odataApprovalStatus"
                                   title="Status"
                                   width="90">
                </kendo-grid-column>
                <kendo-grid-column media="(min-width: 700px)"
                                   field="bt_nextmovementstep"
                                   title="Next Step">
                </kendo-grid-column>
              </kendo-grid>
            </div>
            <div>
              <div
                   *ngIf="requestClassificationDropdownValue !== 'Pool' && requestEmployeeDropdownValue === null && !newEmployeeYes">
                <p>Please select 'New Employee' or a specific employee to view this data.</p>
              </div>
              <div *ngIf="requestClassificationDropdownValue === 'Pool'">
                <p>Please select 'Company' or 'Rental' classification to view this data.</p>
              </div>
            </div>

          </div>
        </ng-template>
      </kendo-tabstrip-tab>
      <!-- #endregion Movements Tab  -->
      <!-- #region Assigned Items Tab  -->
      <kendo-tabstrip-tab *ngIf="requestClassificationDropdownValue !== 'Pool' && newEmployeeNo"
                          title="Assigned Items">
        <ng-template kendoTabContent>
          <div style="position: relative; height: 100%;">
            <ngx-spinner [disableAnimation]="disableAnimation"
                         name="requestemployeeitemsgrid"
                         bdColor="rgb(237, 237, 237)"
                         size="default"
                         color="#6264a7"
                         type="ball-clip-rotate"
                         [fullScreen]="false">

            </ngx-spinner>

            <div *ngIf="requestEmployeeDropdownValue !== null || newEmployeeYes"
                 class="reqest_new_grid_wrapper">
              <kendo-grid [kendoGridBinding]="requestEmployeeItemsGridView"
                          [resizable]="true"
                          [groupable]="false"
                          [group]="requestEmployeeItemsGroups"
                          (groupChange)="requestEmployeeItemsGroupChange($event)"
                          (dataStateChange)="requestEmployeeItemsDataStateChange($event)"
                          [selectable]="true"
                          [kendoGridSelectBy]="requestEmployeeItemsSelectedCallback"
                          [selectedKeys]="requestEmployeeItemsSelection"
                          [sortable]="true"
                          [sort]="requestEmployeeItemsSort"
                          (sortChange)="requestEmployeeItemsSortChange($event)">

                <!-- columns for larger devices -->
                <kendo-grid-column media="(min-width: 700px)"
                                   field="bthr_licensenumber"
                                   title="License No">
                </kendo-grid-column>
                <kendo-grid-column media="(min-width: 700px)"
                                   field="odataMake"
                                   title="Fleet Item">
                  <ng-template kendoGridCellTemplate
                               let-ListTable>
                    {{ListTable.odataMake}} {{ListTable.odataModel}}
                  </ng-template>
                </kendo-grid-column>
              </kendo-grid>
            </div>
            <div *ngIf="requestEmployeeDropdownValue === null">
              <p>Please select a specific employee to view this data.</p>
            </div>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
      <!-- #endregion Assigned Items Tab  -->
    </kendo-tabstrip>
  </div>
</div>
<div>
  <button [disabled]="!requestNewForm.valid"
          kendoButton
          themeColor="primary"
          style="float: right;"
          (click)="postRequestNew(requestNewForm)">
    Submit
  </button>
</div>

<!-- #region Confirmation Modal -->
<kendo-dialog *ngIf="showConfirmationModal"
              title="Confirmation"
              [width]="500">
  <div style="position: relative; height: 60px;">
    <ngx-spinner [disableAnimation]="disableAnimation"
                 name="confirmation"
                 bdColor="rgb(237, 237, 237)"
                 size="default"
                 color="#6264a7"
                 type="ball-clip-rotate"
                 [fullScreen]="false">

    </ngx-spinner>

    <p>
      {{confirmationMessage}}
    </p>
  </div>
  <div class="confirmation-modal-footer">
    <button kendoButton
            themeColor="primary"
            style="margin-left: auto;"
            (click)="closeConfirmationModal()">
      Continue
    </button>
  </div>
</kendo-dialog>
<!-- #endregion Confirmation Modal -->