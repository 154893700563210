/* tslint:disable */
/* eslint-disable */
import { PerformanceReviewGuidExtrapolate } from './performance-review-guid-extrapolate';
import { PerformanceReviewBthrBusinessCycleState } from './performance-review-bthr-business-cycle-state';
export interface PerformanceReviewDvBusinessCycles {
  bt_Period?: null | string;
  bt_businesscycleunique?: null | string;
  bt_maxvalue?: null | number;
  bt_minvalue?: null | number;
  bthr_BusinessCycleId?: null | string;
  bthr_BusinessCycleType?: null | string;
  bthr_cycleenddate?: null | string;
  bthr_cyclename?: null | string;
  bthr_cyclestartdate?: null | string;
  businessCycleId?: PerformanceReviewGuidExtrapolate;
  businessCycleType?: PerformanceReviewGuidExtrapolate;
  period?: PerformanceReviewGuidExtrapolate;
  statecode?: PerformanceReviewBthrBusinessCycleState;
}
