import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RefreshService {
  private apiCallCompleted = new Subject<void>();
  employeeFunds: any;

  constructor() {}

  public notifyApiCallCompleted(): void {
    this.apiCallCompleted.next();
  }

  public onApiCallCompleted(): Observable<void> {
    return this.apiCallCompleted.asObservable();
  }

  private sharedValueSubject = new BehaviorSubject<string>(null);
  sharedValue$ = this.sharedValueSubject.asObservable();

  updateSharedValue(value: string) {
    this.sharedValueSubject.next(value);
  }
}
