/* tslint:disable */
/* eslint-disable */
export interface EmployeePresence {
  bt_date?: null | string;
  bt_employeepresencestatusid?: null | string;
  bt_isproxy?: null | boolean;
  bt_latitude?: null | string;
  bt_longitude?: null | string;
  bt_prescencestatus?: null | string;
  bt_title?: null | string;
  endDate?: null | string;
  odataEmployee?: null | string;
  odataLegalEntity?: null | string;
  odataProxy?: null | string;
  odataSite?: null | string;
  odataSiteForPost?: null | string;
  startDate?: null | string;
  statecode?: null | number;
}
