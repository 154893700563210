/* tslint:disable */
/* eslint-disable */
export interface DvEmployeeDependentsPost {
  bthr_MedicalInsurance?: null | string;
  bthr_ageyears?: null | number;
  bthr_dateofbirth?: null | string;
  bthr_employeedependentid?: null | string;
  bthr_fullname?: null | string;
  bthr_recordstatus?: null | number;
  bthr_relationship?: null | number;
  insuranceID?: null | string;
  odataEmployee?: null | string;
  odataMedicalInsurance?: null | string;
}
