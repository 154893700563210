/* tslint:disable */
/* eslint-disable */
export interface BtVerdeTaskListAssignToTaskListBtVerd {
  '_bt_employee_value'?: null | string;
  '_bt_legalentity_value'?: null | string;
  '_bt_tasklist_value'?: null | string;
  '_bt_tasklistentity_value'?: null | string;
  bt_verdetasklistassignto?: null | string;
  bt_verdetasklistassigntoid?: null | string;
  odataEmployee?: null | string;
  odataEmployeeLegalEntity?: null | string;
  odataTaskList?: null | string;
  odataTaskListLegalEntity?: null | string;
}
