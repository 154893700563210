/* tslint:disable */
/* eslint-disable */
export interface Locations {
  bt_allowedemployeescalc?: null | number;
  bt_location_newid?: null | string;
  bt_name?: null | string;
  bt_percentageallowed?: null | number;
  bt_totalemployees?: null | number;
  odataOffice?: null | string;
}
