/* tslint:disable */
/* eslint-disable */
import { FleetSharedLookupExtrapolate } from './fleet-shared-lookup-extrapolate';
import { FleetBtProcessStatus } from './fleet-bt-process-status';
export interface FleetFleetServiceManualUpdate {
  bt_fleetitem?: FleetSharedLookupExtrapolate;
  bt_fleetservicemanualupdate?: null | string;
  bt_fleetservicemanualupdateid?: null | string;
  bt_lastservicedate?: null | string;
  bt_legalentity?: FleetSharedLookupExtrapolate;
  bt_odoreading?: null | number;
  bt_processcomment?: null | string;
  bt_processdate?: null | string;
  bt_processed?: null | boolean;
  bt_processstatus?: FleetBtProcessStatus;
  bt_updateby?: FleetSharedLookupExtrapolate;
  bt_updatedlegalentity?: FleetSharedLookupExtrapolate;
}
