/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { CommunicationBaseService } from '../communication-base-service';
import { CommunicationApiConfiguration } from '../communication-api-configuration';
import { CommunicationStrictHttpResponse } from '../communication-strict-http-response';
import { CommunicationRequestBuilder } from '../communication-request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CommunicationCommunicationExample } from '../models/communication-communication-example';
import { CommunicationGetCommunicationQuery } from '../models/communication-get-communication-query';
import { CommunicationPostCommunicationCommand } from '../models/communication-post-communication-command';

@Injectable({
  providedIn: 'root',
})
export class CommunicationApiCommunicationService extends CommunicationBaseService {
  constructor(
    config: CommunicationApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getCommunication
   */
  static readonly GetCommunicationPath = '/api/Communication/get/communication';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCommunication()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getCommunication$Response(params?: {
    tenantId?: string;
    body?: CommunicationGetCommunicationQuery
  }): Observable<CommunicationStrictHttpResponse<Array<CommunicationCommunicationExample>>> {

    const rb = new CommunicationRequestBuilder(this.rootUrl, CommunicationApiCommunicationService.GetCommunicationPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as CommunicationStrictHttpResponse<Array<CommunicationCommunicationExample>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCommunication$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getCommunication(params?: {
    tenantId?: string;
    body?: CommunicationGetCommunicationQuery
  }): Observable<Array<CommunicationCommunicationExample>> {

    return this.getCommunication$Response(params).pipe(
      map((r: CommunicationStrictHttpResponse<Array<CommunicationCommunicationExample>>) => r.body as Array<CommunicationCommunicationExample>)
    );
  }

  /**
   * Path part for operation postCommunication
   */
  static readonly PostCommunicationPath = '/api/Communication/post/communication';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postCommunication()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postCommunication$Response(params?: {
    tenantId?: string;
    body?: CommunicationPostCommunicationCommand
  }): Observable<CommunicationStrictHttpResponse<boolean>> {

    const rb = new CommunicationRequestBuilder(this.rootUrl, CommunicationApiCommunicationService.PostCommunicationPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as CommunicationStrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postCommunication$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postCommunication(params?: {
    tenantId?: string;
    body?: CommunicationPostCommunicationCommand
  }): Observable<boolean> {

    return this.postCommunication$Response(params).pipe(
      map((r: CommunicationStrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

}
