import { Action } from '@ngrx/store';
import { BookingsFiltered } from '@verde/api';

export enum BookingsActionTypes {
  // Get All Bookings
  BOOKINGS_GET_ALL = '[BOOKINGS] Get All',
  BOOKINGS_GET_ALL_SUCCESS = '[BOOKINGS] Get All Success',
  BOOKINGS_GET_ALL_ERROR = '[BOOKINGS] Get All Error',

  // Clear Bookings
  BOOKINGS_CLEAR = '[BOOKINGS] Clear',
}

// Get All Bookings
export class BookingsGetAll implements Action {
  readonly type = BookingsActionTypes.BOOKINGS_GET_ALL;
  constructor(public employeeID: string, public legalEntityID: string) {}
}

export class BookingsGetAllSuccess implements Action {
  readonly type = BookingsActionTypes.BOOKINGS_GET_ALL_SUCCESS;
  constructor(public payload: BookingsFiltered[]) {}
}

export class BookingsGetAllError implements Action {
  readonly type = BookingsActionTypes.BOOKINGS_GET_ALL_ERROR;
  constructor(public payload: any) {}
}

// Clear Bookings
export class BookingsClear implements Action {
  readonly type = BookingsActionTypes.BOOKINGS_CLEAR;
  constructor(public payload: any) {}
}

export type BookingsActions =
  // Get All Bookings
  | BookingsGetAll
  | BookingsGetAllSuccess
  | BookingsGetAllError
  // Clear Bookings
  | BookingsClear;
